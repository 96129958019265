import React, { lazy } from 'react';

const SupplierProfileListing = lazy(() =>
  import('./SupplierProfileListing').then(module => ({
    default: module.SupplierProfileListing,
  })),
);

const SupplierProfileDetail = lazy(() =>
  import('./SupplierProfileDetail').then(module => ({
    default: module.SupplierProfileDetail,
  })),
);

const SupplierProfileForm = lazy(() =>
  import('./SupplierProfileForm').then(module => ({
    default: module.SupplierProfileForm,
  })),
);

const SupplierProfileAssignment = lazy(() =>
  import('./SupplierProfileAssignment').then(module => ({
    default: module.SupplierProfileAssignment,
  })),
);

const supplierProfileRoutes = [
  {
    props: {
      exact: true,
      path: '/general-setting/supplier-profile',
    },
    component: <SupplierProfileListing />,
  },
  {
    props: {
      exact: true,
      path: '/general-setting/supplier-profile/add',
    },
    component: <SupplierProfileForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/general-setting/supplier-profile/:CreditorAccountID/edit',
    },
    component: <SupplierProfileForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/general-setting/supplier-profile/:CreditorAccountID/detail',
    },
    component: <SupplierProfileDetail mode="view" />,
  },
  {
    props: {
      exact: true,
      path: '/general-setting/supplier-profile/:CreditorAccountID/assign',
    },
    component: <SupplierProfileAssignment mode="assign" />,
  },
];

export default supplierProfileRoutes;
