import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import Fuse from 'fuse.js';
import { useGetCompanyNameQuery } from 'generated/graphql';
import { useFuseSearch } from 'helpers/Hooks/useSearch';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

export const CompanyListing = (props: any) => {
  const { createAccess }: any = props;
  const getSearch = localStorage?.getItem('companyFilter');
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  let history = useHistory();
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch();
  const { globalState, dispatch }: any = useContext(AppContext as any);
  const [parentCompany, setParentCompany] = useState<string>('000-000-000');
  const { mode }: any = useParams();
  const CompanyID: any = localStorage?.getItem('latestCompany');
  const handleDrawer = () => {
    dispatch({
      type: 'drawerOpen',
      payload: !globalState.drawerOpen,
    });
    history.push({ state: CompanyID });
  };

  const setLocalAndPath = data => {
    history.push({
      pathname: `/company-settings/${data}`,
    });
    localStorage.setItem('latestCompany', data);
  };

  const {
    loading,
    error,
    data: { getCompany: getCompanyName } = { getCompany: [] },
  } = useGetCompanyNameQuery({ fetchPolicy: 'network-only' });

  useEffect(() => {
    if (getCompanyName && getCompanyName?.length > 0) {
      let activeList = getCompanyName?.filter(
        el => el?.RecordStatus === 'ACTIVE',
      );

      setOriginalListing([...activeList]);
    }
  }, [getCompanyName]);

  // const {
  //   data: { getParentCompany } = { getParentCompany: [] },
  // } = useGetParentCompanyQuery({
  //   onCompleted: () => {
  //     if (getParentCompany[0].CompanyID !== '000-000-000') {
  //       getParentCompany?.unshift({
  //         CompanyID: '000-000-000',
  //         Name: 'All',
  //       })
  //     }
  //   },
  // })

  useEffect(() => {
    if (getSearch && !!originalList) {
      const keys = ['Name', 'CompanyRegNo'];
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      };

      const myFuse = new Fuse(originalList, options);
      const result = myFuse.search(getSearch);
      const val = result?.map(x => x.item);
      if (val.length !== 0) {
        handleSearch(getSearch + '', keys);
      }
    }
  }, [getSearch, originalList]);

  return (
    <>
      {loading && <Loading />}
      <MainHeader
        // IF BURGER OPTION
        mainBtn="menu"
        onClick={handleDrawer}
        smTitle={smTitle.COMPANY_SETTINGS}
        title={user?.companyName}
        routeSegments={[{ name: 'Main Menu', current: true }]}
      />
      <div className="search-filter with-advanced-filter">
        <SearchHeader
          title="Search here.."
          search
          onChangeAction={e => {
            handleSearch(e?.target?.value, ['Name', 'CompanyRegNo']);
            localStorage.setItem('companyFilter', e.target.value);
          }}
          onCloseAction={() => handleSearch('', [])}
          isDefaultValue={!!getSearch}
          defaultValue={getSearch ? getSearch : ''}
        />
      </div>
      <ContentWrapper float>
        <List className="core-list">
          {filteredList === undefined ||
          filteredList?.length === 0 ||
          filteredList.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            filteredList.map((el, index) => {
              return (
                <ListItem
                  key={index}
                  onClick={() => setLocalAndPath(el?.CompanyID)}
                >
                  <ListItemText
                    primary={
                      <>
                        <span className="desc">
                          <span
                            className="xsTitle"
                            style={{
                              color:
                                el?.RecordStatus === 'INACTIVE'
                                  ? '#BDBDBD'
                                  : null,
                            }}
                          >
                            {el?.Name}
                          </span>
                        </span>
                      </>
                    }
                    secondary={
                      <span className="desc">
                        <span
                          className="desc"
                          style={{
                            color:
                              el?.RecordStatus === 'INACTIVE'
                                ? '#BDBDBD'
                                : null,
                          }}
                        >
                          {el?.Code}
                        </span>
                      </span>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="arrow">
                      {((el?.RecordStatus === 'ACTIVE' && !createAccess) ||
                        createAccess) && (
                        <KeyboardArrowRight
                          onClick={() => setLocalAndPath(el.CompanyID)}
                        />
                      )}
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
          )}
        </List>
      </ContentWrapper>
    </>
  );
};
