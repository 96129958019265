import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import {
  RecordStatus,
  GetUomDocument,
  //   useGetUomExchangeDataQuery,
  GetUomExchangeDataDocument,
  useCreateUomExchangeMutation,
  useDeleteUomExchangeMutation,
  useGetUomExchangeDataQuery,
  // useCreateUomExchangeMutation,
  // useDeleteUomExchangeMutation,
  // useGetUomExchangeDataQuery,
  useGetUomExchangeQuery,
  useGetUomLazyQuery,
  useGetUomQuery,
  useUpdateUomExchangeMutation,
  //   useUpdateUomExchangeMutation,
} from 'generated/graphql';
import { useFuseSearch } from 'helpers/Hooks/useSearch';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Fuse from 'fuse.js';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
} from '@material-ui/core';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import RepeatIcon from '@material-ui/icons/Repeat';
import { MoreVert } from '@material-ui/icons';
import { useMenuOption } from 'helpers/Hooks/useMenuOption';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { SystemMsgs } from 'helpers/Messages/SystemMsg';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import './UOM.scss';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { Autocomplete } from '@material-ui/lab';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';

export interface ExchangeForm {
  FromUomID: {
    ID: string;
    Name: string;
  };
  ToUomID: {
    ID: string;
    Name: string;
  };
  FromAmt: number;
  ToAmt: number;
}

export const ConversionRateListing = props => {
  const { page } = props;
  let history = useHistory();
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const getSearch = localStorage?.getItem('searchFilter');
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [errorDia, setErrorDia] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');
  const [hardDeleteForm, setHardDeleteForm] = useState(false);

  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any,
  );

  // QUERY //

  const {
    loading: UomExchangeDataLoading,
    error: UomExchangeDataError,
    data: { getUomExchangeData } = { getUomExchangeData: [] },
  } = useGetUomExchangeDataQuery({
    fetchPolicy: 'network-only',
  });

  const {
    loading: UOMLoading,
    error: UOMError,
    data: { getUOM } = { getUOM: [] },
  } = useGetUomQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
      RecordStatus: RecordStatus.Active,
    },
  });

  const [
    fetchListingUOM,
    {
      loading: uomListLoading,
      error: uomListError,
      data: { getUOM: displayUomList } = { getUOM: [] },
    },
  ] = useGetUomLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      orderByAsc: 'Name',
    },
  });

  // MUTATION //

  const [
    createUOMExchange,
    { loading: mutationLoading, error: mutationError },
  ] = useCreateUomExchangeMutation({
    onError: error => {
      setErrMsg(error.message.substr(15, error.message.length - 1));
      setErrorDia(true);
      setOpen(true);
    },
    onCompleted: () => {
      setErrorDia(false);
      setOpen(false);
      setErrMsg('none');
      setOpenSnackBar(true);
      setSnackBarMsg(SystemMsgs.createNewRecord());
    },
    refetchQueries: [
      {
        query: GetUomDocument,
        variables: {
          orderByAsc: 'Name',
          RecordStatus: RecordStatus.Active,
        },
      },
      {
        query: GetUomExchangeDataDocument,
      },
    ],
  });

  const [
    updateUOMExchange,
    { loading: updateMutationLoading, error: updateMutationError },
  ] = useUpdateUomExchangeMutation({
    onError: error => {},
    onCompleted: () => {
      setOpenSnackBar(true);
      setOpen(false);
      setSnackBarMsg(SystemMsgs.updateRecord());
    },
    refetchQueries: [
      {
        query: GetUomDocument,
        variables: {
          orderByAsc: 'Name',
          RecordStatus: RecordStatus.Active,
        },
      },
      {
        query: GetUomExchangeDataDocument,
      },
    ],
  });

  const [
    deleteUOMExchange,
    {
      loading: mutationDeleteUOMExchangeLoading,
      error: deleteUOMExchangeError,
    },
  ] = useDeleteUomExchangeMutation({
    onError: error => {},
    onCompleted: data => {
      setHardDeleteForm(false);
      setOpenSnackBar(true);
      setSnackBarMsg(SystemMsgs.deleteRecord());
    },
    refetchQueries: [
      {
        query: GetUomDocument,
        variables: {
          orderByAsc: 'Name',
          RecordStatus: RecordStatus.Active,
        },
      },
      {
        query: GetUomExchangeDataDocument,
      },
    ],
  });

  const {
    handleSubmit,
    register,
    errors,
    control,
    reset,
    setValue,
    watch,
  } = useForm<ExchangeForm>({
    mode: 'all',
  });

  useEffect(() => {
    if (isEdit) fetchListingUOM();
  }, [isEdit]);

  const onSubmit = data => {
    if (isEdit) {
      let fAmt = data.FromAmt ?? menu?.obj?.UomDetails?.FromAmt;
      let tAmt = data.ToAmt ?? menu?.obj?.UomDetails?.ToAmt;
      updateUOMExchange({
        variables: {
          input: {
            ID: menu?.obj?.ID,
            FromUomID: data.FromUomID?.ID,
            ToUomID: data.ToUomID?.ID,
            FromAmt: parseFloat(fAmt),
            ToAmt: parseFloat(tAmt),
            ExchangeRate: parseFloat(tAmt) / parseFloat(fAmt),
          },
        },
      });
    } else {
      createUOMExchange({
        variables: {
          input: {
            FromUomID: data.FromUomID?.ID,
            ToUomID: data.ToUomID?.ID,
            FromAmt: parseFloat(data.FromAmt),
            ToAmt: parseFloat(data.ToAmt),
            ExchangeRate: parseFloat(data.ToAmt) / parseFloat(data.FromAmt),
          },
        },
      });
    }
  };

  const onHardDelete = () => {
    deleteUOMExchange({
      variables: { ID: menu?.obj?.UomDetails?.ID },
      refetchQueries: [
        {
          query: GetUomDocument,
          variables: {
            orderByAsc: 'Name',
            RecordStatus: RecordStatus.Active,
          },
        },
      ],
    });
  };

  // FILTER //

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch();

  useEffect(() => {
    if (getUomExchangeData && getUomExchangeData?.length > 0) {
      setOriginalListing(getUomExchangeData);
    }
  }, [getUomExchangeData]);

  useEffect(() => {
    if (getSearch && !!originalList) {
      const keys = [
        'Code',
        'AccTaxClass',
        'AccTaxCategory',
        'sortedTaxDateFormatted',
      ];
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      };

      const myFuse = new Fuse(originalList, options);
      const result = myFuse.search(getSearch);
      const val = result?.map(x => x.item);
      if (val.length !== 0) {
        handleSearch(getSearch + '', keys);
      }
    }
  }, [getSearch, originalList]);

  // FUNCTION //

  const fromAmount = props => {
    return (
      <Grid item xs={4}>
        <Controller
          name="FromAmt"
          className="conversion-input"
          variant="outlined"
          control={control}
          ref={register}
          render={({ onChange, onBlur, value }) => (
            <NumberFormat
              id="standard-select-customer"
              className="conversion-input"
              variant="outlined"
              thousandSeparator
              customInput={TextField}
              fullWidth
              allowNegative={false}
              fixedDecimalScale
              decimalScale={4}
              defaultValue={
                isEdit ? parseFloat(menu?.obj?.UomDetails?.FromAmt) : 0
              }
              isNumericString
              onValueChange={values => {
                let { floatValue } = values;
                onChange(
                  (value = values.floatValue),
                  register(`FromAmt`),
                  setValue(`FromAmt`, floatValue),
                );
              }}
            />
          )}
        />
      </Grid>
    );
  };

  const toAmount = props => {
    return (
      <Grid item xs={4}>
        <Controller
          className="conversion-input"
          variant="outlined"
          name="ToAmt"
          control={control}
          ref={register}
          render={({ onChange, onBlur, value }) => (
            <NumberFormat
              id="standard-select-customer"
              className="conversion-input"
              variant="outlined"
              thousandSeparator
              customInput={TextField}
              fullWidth
              fixedDecimalScale
              decimalScale={4}
              allowNegative={false}
              defaultValue={
                isEdit ? parseFloat(menu?.obj?.UomDetails?.ToAmt) : 0
              }
              isNumericString
              onValueChange={values => {
                let { floatValue } = values;
                onChange(
                  (value = values.floatValue),
                  register(`ToAmt`),
                  setValue(`ToAmt`, floatValue),
                );
              }}
            />
          )}
        />
      </Grid>
    );
  };

  const fromUom = (data, props) => {
    return (
      <Grid item xs={7}>
        <Controller
          render={({ value, onChange }) => {
            return (
              <Autocomplete
                options={data
                  ?.filter(cr => cr?.UOMID !== watch('ToUomID')?.ID)
                  ?.map(e => {
                    return {
                      ID: e?.UOMID,
                      Name: e?.Name,
                    };
                  })}
                getOptionLabel={option => `${option?.Name}`}
                fullWidth
                disabled={isEdit}
                style={{
                  paddingRight: '0px',
                }}
                value={value}
                onChange={(value, newValue: any) => {
                  onChange(newValue);
                }}
                renderInput={params => {
                  return (
                    <TextField
                      {...params}
                      style={{
                        width: '100%',
                        marginTop: '0px',
                        paddingRight: '0px',
                      }}
                      margin="normal"
                      variant="standard"
                    />
                  );
                }}
                className="conversion-dropdown-input"
              />
            );
          }}
          name="FromUomID"
          defaultValue={{
            ID: isEdit
              ? menu?.obj?.FromUomID ?? menu?.obj?.FromUomName?.UOMID
              : '',
            Name: isEdit ? menu?.obj?.FromUomName?.Name : '',
          }}
          control={control}
        />
      </Grid>
    );
  };

  const toUom = (data, props) => {
    return (
      <>
        <Grid item xs={7}>
          <Controller
            render={({ value, onChange }) => {
              return (
                <Autocomplete
                  options={data
                    ?.filter(cr => cr?.UOMID !== watch('FromUomID')?.ID)
                    ?.map(e => {
                      return {
                        ID: e?.UOMID,
                        Name: e?.Name,
                      };
                    })}
                  getOptionLabel={option => `${option?.Name}`}
                  fullWidth
                  disabled={isEdit}
                  value={value}
                  onChange={(value, newValue: any) => {
                    onChange(newValue);
                  }}
                  renderInput={params => {
                    return (
                      <TextField
                        {...params}
                        style={{ width: '100%', marginTop: '0px' }}
                        margin="normal"
                        variant="standard"
                      />
                    );
                  }}
                  className="conversion-dropdown-input"
                />
              );
            }}
            name="ToUomID"
            defaultValue={{
              ID: isEdit
                ? menu?.obj?.ToUomID ?? menu?.obj?.ToUomName?.UOMID
                : '',
              Name: isEdit ? menu?.obj?.ToUomName?.Name : '',
            }}
            control={control}
          />

          {errorDia === true && errMsg ? (
            <span style={{ color: 'red' }} className="mdDesc">
              Duplicate record found
            </span>
          ) : null}
        </Grid>
      </>
    );
  };

  useEffect(() => {
    setErrorDia(false);
  }, [watch('FromUomID')]);

  useEffect(() => {
    setErrorDia(false);
  }, [watch('ToUomID')]);

  return (
    <>
      {UomExchangeDataLoading && <Loading />}
      {UOMLoading && <Loading />}
      {uomListLoading && <Loading />}
      {mutationDeleteUOMExchangeLoading && <Loading />}
      {mutationLoading && <Loading />}

      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/general-setting`);
        }}
        smTitle={'General Settings'}
        title={user?.companyName}
        routeSegments={[
          { name: 'System Admin' },
          { name: 'UOM Conversion', current: true },
        ]}
      />

      <SearchHeader
        title={`UOM Conversion Listing`}
        value={`${getUomExchangeData?.length}`}
        search
        fixed
        onChangeAction={e => {
          handleSearch(e?.target?.value, [
            'FromUomName.Name',
            'ToUomName.Name',
          ]);
          localStorage.setItem('searchFilter', e.target.value);
        }}
        defaultValue={getSearch ? getSearch : ''}
        isDefaultValue={!!getSearch}
        onCloseAction={() => handleSearch('', [])}
      />

      <ContentWrapper search>
        <List className="core-list">
          {filteredList === undefined || filteredList?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            filteredList?.map((el, index) => (
              <>
                <ListItem key={index}>
                  <ListItemText
                    primary={
                      <>
                        <span className="xsTitle">{el?.FromUomName?.Name}</span>
                        <RepeatIcon
                          className="repeat-icon"
                          style={{ fontSize: '0.8rem' }}
                        />
                        <span className="xsTitle">{el?.ToUomName?.Name}</span>
                      </>
                    }
                    secondary={
                      <>
                        <span className="desc flex-space">
                          {`${el?.UomDetails?.FromAmt} ${el?.FromUomName?.Code} = ${el.UomDetails?.ToAmt} ${el.ToUomName?.Code}`}
                        </span>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      aria-controls="menu-list"
                      aria-haspopup="true"
                      onClick={e => handleClick(e, el?.ID, index, el)}
                    >
                      <MoreVert />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </>
            ))
          )}
        </List>
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            setOpen(true);
            setIsEdit(true);
          }}
        >
          <span className="">Edit</span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setHardDeleteForm(true);
          }}
        >
          <span className="">Delete</span>
        </MenuItem>
      </Menu>

      {/* create / delete dialog */}
      <CommonDialog
        open={open}
        fullWidth={true}
        onClose={() => setOpen(false)}
        sections={{
          header: {
            title: 'UOM Conversion',
            rightText: isEdit ? 'Edit' : 'New',
          },
          body: () => (
            <>
              <form onSubmit={handleSubmit(onSubmit)} id="conversion-form">
                <br></br>
                <Grid container justify="flex-start">
                  {fromAmount('')}
                  <Grid item xs={1}></Grid>
                  {fromUom(isEdit ? displayUomList : getUOM, '')}
                </Grid>

                <div className="conversion-icon">
                  <ImportExportIcon />
                </div>

                <Grid container justify="flex-start">
                  {toAmount('')}
                  <Grid item xs={1}></Grid>
                  {toUom(isEdit ? displayUomList : getUOM, '')}
                </Grid>
              </form>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setOpen(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    handleSubmit(onSubmit);
                  },
                  variant: 'contained',
                  disabled: errorDia === true ? true : false,
                  color: 'primary',
                  type: 'submit',
                  form: 'conversion-form',
                },
              },
            ],
          },
        }}
      />

      {/* delete dialog */}
      <CommonDialog
        fullWidth={true}
        open={hardDeleteForm}
        onClose={() => setHardDeleteForm(false)}
        sections={{
          header: {
            title: 'UOM Conversion',
            rightText: 'Delete',
          },

          body: () => (
            <>
              <Grid container justify="flex-start">
                <Grid item xs={5}>
                  <div className="content-wrap ">
                    <div className="desc">From UOM</div>
                    <div className="xsTitle">
                      {menu?.obj?.UomDetails?.FromAmt}{' '}
                      {menu?.obj?.FromUomName?.Code}
                    </div>
                    <div className="xxTitle">
                      {'('}
                      {menu?.obj?.FromUomName?.Name}
                      {')'}
                    </div>
                  </div>
                </Grid>

                <Grid item xs={2}>
                  <RepeatIcon className="repeat-icon" />
                </Grid>

                <Grid item xs={5}>
                  <div className="content-wrap">
                    <div className="desc">To UOM</div>
                    <div className="xsTitle">
                      {menu?.obj?.UomDetails?.ToAmt}{' '}
                      {menu?.obj?.ToUomName?.Code}
                    </div>
                    <div className="xxTitle">
                      {'('}
                      {menu?.obj?.ToUomName?.Name}
                      {')'}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => setHardDeleteForm(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => onHardDelete(),
                  variant: 'contained',
                  color: 'primary',
                  form: 'hardDeleteUOM-form',
                  type: 'submit',
                },
              },
            ],
          },
        }}
      />

      <FloatButton
        data-qa="addButton"
        onClick={() => {
          setOpen(true);
          setIsEdit(false);
        }}
      />
    </>
  );
};
