import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { InMemoryCache as UploadInMemoryCache } from 'apollo-cache-inmemory';

const apolloCache = new UploadInMemoryCache({
  addTypename: false,
});

let accountNodeUrl, contractNodeUrl, subscriptionNodeUrl;
switch (process.env.REACT_APP_LOCAL_DB) {
  case 'local':
    accountNodeUrl = 'https://accountx-dev-api.ifca.io/account-graphql';
    // accountNodeUrl = 'http://localhost:10000/account-graphql';
    contractNodeUrl = 'https://contractx-dev-api.ifca.io/contract-graphql';
    subscriptionNodeUrl = 'https://subscriptionx-dev-api.ifca.io/subscriptionx';
    break;
  case 'report':
    accountNodeUrl = 'https://accountx-dev-api.ifca.io/account-graphql';
    contractNodeUrl = 'https://contractx-dev-api.ifca.io/contract-graphql';
    subscriptionNodeUrl = 'https://subscriptionx-dev-api.ifca.io/subscriptionx';
    break;
  case 'server':
    accountNodeUrl = 'https://accountx-dev-api.ifca.io/account-graphql';
    contractNodeUrl = 'https://contractx-dev-api.ifca.io/contract-graphql';
    subscriptionNodeUrl = 'https://subscriptionx-dev-api.ifca.io/subscriptionx';
    break;
  case 'azure':
    accountNodeUrl = 'https://accountx-dev-api.ifca.io/account-graphql';
    contractNodeUrl = 'https://contractx-dev-api.ifca.io/contract-graphql';
    subscriptionNodeUrl = 'https://subscriptionx-dev-api.ifca.io/subscriptionx';
    break;
  default:
    accountNodeUrl = 'https://accountx-api.ifca.asia/account-graphql';
    contractNodeUrl = 'https://home-api.contractx.asia/contract-graphql';
    subscriptionNodeUrl = 'https://subscriptionx-api.ifca.asia/subscriptionx';
    break;
}

export const AccountClient = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  credentials: 'include',
  uri: accountNodeUrl,
  onError: error => {
    error.graphQLErrors?.map(({ message }) => {
      console.log('message', message);
      if (message === 'session can not be established') {
        // browserHistory.push('/login', {})
      }
    });
  },
});

export const ContractClient = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  credentials: 'include',
  uri: contractNodeUrl,
  onError: error => {
    error.graphQLErrors?.map(({ message }) => {
      console.log('message', message);
      if (message === 'session can not be established') {
        // browserHistory.push('/login', {})
      }
    });
  },
});

export const SubscriptionClient = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  credentials: 'include',
  uri: subscriptionNodeUrl,
  onError: error => {
    error.graphQLErrors?.map(({ message }) => {
      console.log('message', message);
      if (message === 'session can not be established') {
        // browserHistory.push('/login', {})
      }
    });
  },
});

/////////////////////////////////////////////////////////////////////////////////////////
// @access-control-node
export const accessControlUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:5020/access-control'
    : 'https://ifca.io/access-control';

export const AccessControlClient = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  credentials: 'omit',
  uri: accessControlUrl,
});
