import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import React from 'react'

export const FooterPR = (props: any) => {
  const [width] = useMediaQuery()

  let fontSize
  if (width < 769) {
    fontSize = 6
  } else {
    fontSize = 10
  }
  return (
    <>
      <div style={style.center}>
        <table style={{ width: '100%', fontSize, borderCollapse: 'collapse' }}>
          <div style={{ display: 'flex' }}>
            <span style={{ width: '70%' }}>
              <tr>Prepared By ifcaadmin.RR</tr>
            </span>
            <tr
              style={{
                width: '30%',
                borderTop: '0.5px  solid',
                textAlign: 'center' as 'center',
              }}
            >
              Authorised Signatory
            </tr>
          </div>
        </table>
      </div>
    </>
  )
}

export default FooterPR

const style = {
  center: {
    fontSize: 5,
    right: 150,
    bottom: '10px',
    padding: '185px 10px 10px 10px',
  },
}
