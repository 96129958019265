import React from 'react'
import { format } from 'date-fns'
import { Grid, useMediaQuery } from '@material-ui/core'
import theme from '@ifca-root/react-component/src/assets/theme'

export const TablePO = (props: any) => {
  const { data, name } = props

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  })


  // let fontSize
  // if (width < 769) {
  //   fontSize = 7
  // } else {
  //   fontSize = 10
  // }

  return (
    <>
      <div style={{   
        flexGrow: 1, 
        fontSize: isDesktop ? '11px' : '7px',
        marginLeft: isDesktop ? '60px' : '-140px' }}>
          <Grid container style={{ marginTop: '30px', marginBottom: '30px' }}>
            <Grid item xs style={{ marginLeft: '150px' }}>
                  <tr>
                    <td>
                      <b>{name}</b>
                    </td>
                  </tr>
                  {data?.DeliveryLocation?.Address?.country === 'Singapore' ? (
                    <>
                      <tr>
                      <td style={{ width: '100px' }}>{data?.DeliveryLocation?.Address?.address}</td>  
                      </tr>
                      <tr>{data?.DeliveryLocation?.Address?.country}</tr>
                      <tr>{data?.Delivery?.Address?.postCode}</tr>
                    </>
                  ) : (
                    <>
                      <tr>
                        <td style={{ width: '160px' }}>{data?.DeliveryLocation?.Address?.address}</td>  
                      </tr>
                      <tr>{data?.DeliveryLocation?.Address?.city}</tr>
                      <tr>
                        {data?.DeliveryLocation?.Address?.postCode},{' '}
                        {data?.DeliveryLocation?.Address?.state}
                      </tr>
                      <tr></tr>
                      <tr>{data?.DeliveryLocation?.Address?.country}</tr>
                    </>
                  )}
          </Grid>
          <Grid item xs >
                <tr>
                  <td>Document No. </td>
                  <td>:</td>
                  <td>{data?.DocNo}</td>
                </tr>
                <tr>
                  <td>Expected Date</td>
                  <td>:</td>
                  <td>{data?.ExpectedDate}</td>
                </tr>
                  <tr>
                    <td>Date </td>
                    <td>:</td>
                    <td>{format(new Date(data?.DocDate), 'dd/MM/yyyy')}</td>
                  </tr>
                  <tr>
                    <td>Contact Person </td>
                    <td>:</td>
                    <td>{data?.ContactPerson?.name}</td>
                  </tr>
                  <tr>  
                    <td>Contact No </td>
                    <td>:</td>
                    <td>{data?.ContactPerson.contactNo}</td>
                  </tr>
          </Grid>
          </Grid>
      </div>
    </>
  )
}

export default TablePO
