import approvalPolicyRoutes from './ApprovalPolicy/ApprovalPolicyRoutes';
import { CompanyListing } from './CompanyListing';
import { CompanySubmenu } from './CompanySubmenu';
import deliveryLocationRoutes from './DeliveryLocation/DeliveryLocationRoutes';
import numberingStructureRoutes from './NumberStructureModule/NumberStructureRoutes';
import React from 'react';

const companyRoutes = [
  {
    props: { exact: true, path: '/company-settings' },
    component: <CompanyListing />,
  },

  {
    props: { exact: true, path: '/company-settings/:CompanyID/' },
    component: <CompanySubmenu />,
  },
  ...numberingStructureRoutes,
  ...approvalPolicyRoutes,
  ...deliveryLocationRoutes,
];

export default companyRoutes;
