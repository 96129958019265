import React, { lazy } from 'react';

const GeneralDOMain = lazy(() =>
  import('./GeneralDOMain').then(module => ({
    default: module.GeneralDOMain,
  })),
);
// const GeneralDOListing = lazy(() =>
//   import('./GeneralDOListing').then(module => ({
//     default: module.GeneralDOListing,
//   })),
// );

const GeneralDOForm = lazy(() =>
  import('./GeneralDOForm').then(module => ({
    default: module.GeneralDOForm,
  })),
);

const GeneralDODetail = lazy(() =>
  import('./GeneralDODetail').then(module => ({
    default: module.GeneralDODetail,
  })),
);

const generalDORoutes = [
  {
    props: { exact: true, path: '/:CompanyID/delivery-order' },
    component: <GeneralDOMain />,
  },
  // {
  //   props: { exact: true, path: '/:CompanyID/delivery-order' },
  //   component: <GeneralDOListing />,
  // },
  {
    props: {
      exact: true,
      path: '/:CompanyID/delivery-order/add',
    },
    component: <GeneralDOForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/delivery-order/edit/:DOHeaderID',
    },
    component: <GeneralDOForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/delivery-order/resubmit/:DOHeaderID',
    },
    component: <GeneralDOForm mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/delivery-order/detail/:DOHeaderID',
    },
    component: <GeneralDODetail />,
  },
];

export default generalDORoutes;
