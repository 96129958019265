import React, { lazy } from 'react';
import { PDFPreview } from '../../../components/PDFTemplate/PDFPreview';

const PurchaseOrderListing = lazy(() =>
  import('./PurchaseOrderListing').then(module => ({
    default: module.PurchaseOrderListing,
  })),
);

const GeneratePOFromPRFormV2 = lazy(() =>
  import('./GeneratePOFromPRFormV2').then(module => ({
    default: module.GeneratePOFromPRFormV2,
  })),
);

const GeneratePOFromPRFormV4 = lazy(() =>
  import('./GeneratePOFromPRFormV4').then(module => ({
    default: module.GeneratePOFromPRFormV4,
  })),
);

const PurchaseOrderDetail = lazy(() =>
  import('./PurchaseOrderDetail').then(module => ({
    default: module.PurchaseOrderDetail,
  })),
);

const DirectPoForm = lazy(() =>
  import('../DirectPOModule/DirectPOForm').then(module => ({
    default: module.DirectPoForm,
  })),
);

const POForm = lazy(() =>
  import('../DirectPOModule/POForm').then(module => ({
    default: module.PurchaseOrderForm,
  })),
);

const POItemForm = lazy(() =>
  import('../DirectPOModule/POItemForm').then(module => ({
    default: module.PurchaseOrderItemForm,
  })),
);

const generalPORoutes = [
  {
    props: {
      exact: true,
      path: `/:CompanyID/generate-po-from-pr`,
    },
    component: <PurchaseOrderListing type="generate-po-from-pr" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/generate-po-from-pr/add',
    },
    component: <GeneratePOFromPRFormV4 mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/generate-po-from-pr/draft/:poID',
    },
    component: <GeneratePOFromPRFormV4 mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `/:CompanyID/generate-po-from-pr/:dpoID/:formMode`,
    },
    component: <POForm detailMode={'edit'} type="generate-po-from-pr" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/generate-po-from-pr/resubmit',
    },
    component: <GeneratePOFromPRFormV4 mode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/generate-po-from-pr/detail/:dpoID/view',
    },
    component: <PurchaseOrderDetail type="generate-po-from-pr" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/generate-po-from-pr/generate-po-from-pr-pr-list',
    },
    component: <PurchaseOrderDetail />,
  },
  {
    props: {
      exact: true,
      path: `/:CompanyID/generate-po-from-pr/preview-po`,
    },
    component: (
      <PDFPreview
        accountType="/"
        docType="preview-po"
        backPath="generate-po-from-pr"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: `/:CompanyID/generate-po-from-pr/:formMode/po-item/:detailMode`,
    },
    component: <POItemForm type="generate-po-from-pr" />,
  },
  {
    props: {
      exact: true,
      path: `/:CompanyID/generate-po-from-pr/:dpoID/:formMode/po-item/:detailMode`,
    },
    component: <POItemForm type="generate-po-from-pr" />,
  },
  {
    props: {
      exact: true,
      path: `/:CompanyID/generate-po-from-pr/:formMode/po-item/:dpoItemID/:detailMode`,
    },
    component: <POItemForm type="generate-po-from-pr" />,
  },
  {
    props: {
      exact: true,
      path: `/:CompanyID/generate-po-from-pr/:dpoID/:formMode/po-item/:dpoItemID/:detailMode`,
    },
    component: <POItemForm type="generate-po-from-pr" />,
  },
];

export default generalPORoutes;
