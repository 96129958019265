import React, { lazy } from 'react';
import { ItemDetail } from './styles/ItemDetail';

const ItemCategoryItem = lazy(() =>
  import('./ItemCategoryItem').then(module => ({
    default: module.ItemCategoryItem,
  })),
);

const ItemListing = lazy(() =>
  import('./ItemListing').then(module => ({
    default: module.ItemListing,
  })),
);

const ItemForm = lazy(() =>
  import('./ItemForm').then(module => ({
    default: module.ItemForm,
  })),
);

const ItemValidation = lazy(() =>
  import('./ItemExcel/ItemExcelValidation').then(module => ({
    default: module.ItemExcelValidation,
  })),
);

const itemRoutes = [
  {
    props: {
      exact: true,
      path: '/general-setting/item-category',
    },
    component: <ItemCategoryItem />,
  },
  {
    props: {
      exact: true,
      path: '/general-setting/item-category/:ItemCategoryID',
    },
    component: <ItemListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/general-setting/item-category/:ItemCategoryID/item/:ItemID/detail',
    },
    component: <ItemDetail />,
  },
  {
    props: {
      exact: true,
      path: '/general-setting/item-category/:ItemCategoryID/item/:ItemID/edit',
    },
    component: <ItemForm formMode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/general-setting/item-category/:ItemCategoryID/add',
    },
    component: <ItemForm formMode="Add" />,
  },
  {
    props: {
      exact: true,
      path: '/general-setting/item-category/validation',
    },
    component: <ItemValidation />,
  },
];

export default itemRoutes;
