import React from 'react';
// import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize'
import { format } from 'date-fns';
import { useMediaQuery } from '@material-ui/core';
import theme from '@ifca-root/react-component/src/assets/theme';

export const TablePR = (props: any) => {
  const { data, name } = props;
  // const [width] = useMediaQuery()

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  // let fontSize
  // if (width < 769) {
  //   fontSize = 7
  // } else {
  //   fontSize = 10
  // }

  return (
    <>
      <div style={{ fontSize: isDesktop ? '11px' : '5px' }}>
        <div style={{ display: 'flex' }}>
          <span
            style={{
              width: isDesktop ? '30%' : '40%',
              marginLeft: isDesktop ? '20px' : '23px',
              marginRight: 'auto',
            }}
          >
            <table style={{ marginTop: '36px' }}>
              <tr>
                <td>
                  <b>{name}</b>
                </td>
              </tr>
            </table>
            <table>
              {data?.DeliveryLocation?.Address?.country === 'Singapore' ? (
                <>
                  <tr>{data?.DeliveryLocation?.Address?.address}</tr>
                  <tr>{data?.DeliveryLocation?.Address?.country}</tr>
                  <tr>{data?.Delivery?.Address?.postCode}</tr>
                </>
              ) : (
                <>
                  <tr>{data?.DeliveryLocation?.Address?.address}</tr>
                  <tr>{data?.DeliveryLocation?.Address?.city}</tr>
                  <tr>
                    {data?.DeliveryLocation?.Address?.postCode},{' '}
                    {data?.DeliveryLocation?.Address?.state}
                  </tr>
                  <tr></tr>
                  <tr>{data?.DeliveryLocation?.Address?.country}</tr>
                </>
              )}
            </table>
          </span>

          <span
            style={{
              paddingTop: isDesktop ? '40px' : '36px',
              width: isDesktop ? '40%' : '50%',
              paddingBottom: '40px',
              marginLeft: isDesktop ? '225px' : '-10px',
              paddingRight: '10px',
            }}
          >
            <table>
              <>
                <tr>
                  <td style={{ paddingRight: '20px' }}>Document No. </td>
                  <td>:</td>
                  <td>{data?.DocNo}</td>
                </tr>
                <tr>
                  <td style={{ paddingRight: '20px' }}>Expected Date</td>
                  <td>:</td>
                  <td>{format(new Date(data?.ExpectedDate), 'dd/MM/yyyy')}</td>
                </tr>
                <tr>
                  <td style={{ paddingRight: '20px' }}>Date </td>
                  <td>:</td>
                  <td>{format(new Date(data?.DocDate), 'dd/MM/yyyy')}</td>
                </tr>
                <tr>
                  <td style={{ paddingRight: '20px' }}>Contact Person </td>
                  <td>:</td>
                  <td>{data?.ContactPerson}</td>
                </tr>
                <tr>
                  <td style={{ paddingRight: '20px' }}>Contact No </td>
                  <td>:</td>
                  <td>{data?.ContactNo}</td>
                </tr>
              </>
            </table>
          </span>
        </div>
      </div>
    </>
  );
};

export default TablePR;
