import GeneralSettingActive from '@ifca-root/react-component/src/assets/icons/general-setting-w.svg';
import { navigateReactUrl } from '@ifca-root/react-component/src/utils/hooks/navigateReactUrl';
import {
  Avatar,
  IconButton,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ExpandMore from '@material-ui/icons/ExpandMore';
import GeneralSetting from 'assets/icons/SideMenu/general_settings.svg';
import Home from 'assets/icons/SideMenu/home.svg';
import ContractSetup from 'assets/icons/SideMenu/project-setup.svg';
import Profile from 'assets/icons/SideMenu/user-profile.svg';
import HomeActive from 'assets/icons/home-w.svg';
import Logout from 'assets/icons/logout.svg';
import { useBroadcastChannel } from 'helpers/Hooks/useBroadcastChannel';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
export const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    drawerPaper: {
      width: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        top: 64,
        height: 'calc(100% - 64px)',
      },
      zIndex: 100,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    activeListItem: {
      borderLeft: '4px solid red',
      boxShadow: '0 3px 2px -3px gray',
      backgroundColor: 'blue',
      '& $listItemText': {
        color: 'white',
        marginTop: 0,
        marginBottom: 0,
        fontWeight: 500,
      },
      '& $listItemIcon': {
        color: 'white',
        marginLeft: '-4px',
        minWidth: '36px',
      },
    },
    listItemText: {
      fontSize: '1rem',
      fontWeight: 400,
      color: 'black',
      marginTop: 0,
      marginBottom: 0,
    },
  }),
);

interface Props {
  window?: () => Window;
  open?: boolean;
  variant?: any;
  onCloseDrawer?: any;
}
interface NavigationProps {
  name: string;
  path: string;
  icon: any;
  iconSelected: any;
  reroute: boolean;
  reroutePath: string;
  deployed: boolean;
  permission: any;
}

export default function LayoutDrawer(props: Props) {
  const { window, open, onCloseDrawer, variant, ...rest } = props;
  const classes = useStyles();
  const container =
    window !== undefined ? () => window().document.body : undefined;
  let history = useHistory();
  let location = useLocation();
  const { signOut } = useBroadcastChannel();
  const userData: any = location?.state;
  const mode: any = history?.location?.pathname;
  const CompanyID: any = localStorage.getItem('latestCompany');
  const user =
    userData?.__typename === 'UserResponse'
      ? userData
      : JSON.parse(localStorage.getItem('loggedInUser'));

  let isHome = false;

  // const { data: pic } = useLogoViewQuery({
  //   variables: {
  //     refID: user?.ID,
  //   },
  // });

  if (mode === '/' || mode === '/home' || mode === '/alternate') {
    isHome = true;
  }

  const pageMode = reconsRoute(mode);

  let isPathForGenSett = ['/general-purchase', '/supplier'];

  const checkPathConSet = mode => {
    let k = isPathForGenSett.filter(k => {
      return mode?.includes(k);
    });
    return k.length > 0;
  };

  const isSysAdmin = ['/system-admin'];
  const checkSysAdmin = mode => {
    let k = isSysAdmin.filter(k => {
      return mode?.includes(k);
    });
    return k.length > 0;
  };

  const navigations: any = isHome
    ? [
        {
          name: 'Home Page',
          path: !!CompanyID ? `/${CompanyID}` : '/',
          icon: Home,
          iconSelected: HomeActive,
          reroute: false,
          reroutePath: '',
          deployed: true,
          // permission: undefined,
        },
        {
          name: 'General Settings',
          path: `/general-setting`,
          icon: GeneralSetting,
          iconSelected: GeneralSettingActive,
          reroute: false,
          reroutePath: '',
          deployed: true,
          // permission: ContractPermission.GeneralSettingExecSummaryView,
        },
        {
          name: 'Company Settings',
          path: `/company-settings`,
          icon: ContractSetup,
          iconSelected: ContractSetup,
          reroute: false,
          reroutePath: '',
          deployed: true,
          // permission: null,
        },
        {
          name: 'Logout',
          path: '/logout',
          icon: Logout,
          iconSelected: Logout,
          reroute: false,
          reroutePath: '',
          deployed: true,
          // permission: undefined,
        },
      ]
    : checkPathConSet(mode) && !checkSysAdmin(mode)
    ? [
        {
          name: 'Home Page',
          path: !!CompanyID ? `/${CompanyID}` : '/',
          icon: Home,
          iconSelected: HomeActive,
          reroute: false,
          reroutePath: '',
          deployed: true,
          // permission: ContractPermission.HomeExecSummaryView,
        },
        {
          name: 'General Settings',
          path: `/general-setting`,
          icon: GeneralSetting,
          iconSelected: GeneralSettingActive,
          reroute: false,
          reroutePath: '',
          deployed: true,
          // permission: ContractPermission.GeneralSettingExecSummaryView,
        },
        {
          name: 'Company Settings',
          path: `/company-settings`,
          icon: ContractSetup,
          iconSelected: ContractSetup,
          reroute: false,
          reroutePath: '',
          deployed: true,
          // permission: null,
        },
        {
          name: 'Logout',
          path: '/logout',
          icon: Logout,
          iconSelected: Logout,
          reroute: false,
          reroutePath: '',
          deployed: true,
          // permission: undefined,
        },
      ]
    : checkSysAdmin(mode) || mode === '/profile'
    ? [
        {
          name: 'Home Page',
          path: !!CompanyID ? `/${CompanyID}` : '/',
          icon: Home,
          iconSelected: HomeActive,
          reroute: false,
          reroutePath: '',
          deployed: true,
          // permission: ContractPermission.HomeExecSummaryView,
        },
        {
          name: 'Logout',
          path: '/logout',
          icon: Logout,
          iconSelected: Logout,
          reroute: false,
          reroutePath: '',
          deployed: true,
          // permission: undefined,
        },
      ]
    : [
        {
          name: 'Home Page',
          path: !!CompanyID ? `/${CompanyID}` : '/',
          icon: Home,
          iconSelected: HomeActive,
          reroute: false,
          reroutePath: '',
          deployed: true,
          // permission: ContractPermission.HomeExecSummaryView,
        },
        {
          name: 'General Settings',
          path: `/general-setting`,
          icon: GeneralSetting,
          iconSelected: GeneralSettingActive,
          reroute: false,
          reroutePath: '',
          deployed: true,
          // permission: ContractPermission.GeneralSettingExecSummaryView,
        },
        {
          name: 'Company Settings',
          path: `/company-settings`,
          icon: ContractSetup,
          iconSelected: ContractSetup,
          reroute: false,
          reroutePath: '',
          deployed: true,
          // permission: ContractPermission.GeneralSettingExecSummaryView,
        },
        {
          name: 'Logout',
          path: '/logout',
          icon: Logout,
          iconSelected: Logout,
          reroute: false,
          reroutePath: '',
          deployed: true,
          // permission: undefined,
        },
      ];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleProfileClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleRedirectProfile = () => {
    if (!!onCloseDrawer) {
      onCloseDrawer();
    }
    setAnchorEl(null);
    history.push('/profile');
  };

  /*----------------------------------------------*/
  /*                CONSOLE LOG                   */
  /*----------------------------------------------*/

  const drawer = (
    <div className="route-container">
      <List disablePadding>
        {navigations?.map((v, index) => {
          if (!!v?.deployed)
            return (
              <ListItem
                button
                key={index}
                dense
                activeClassName={'active'}
                divider
                component={NavLink}
                to={v?.path}
                onClick={() => {
                  // if (v?.reroute) {

                  //   navigateReactUrl(v?.reroutePath, v?.path);
                  // }
                  // if (v?.path === `/${pageMode}/contract-settings`) {

                  //   localStorage.setItem('backPath', mode);
                  // } else {

                  //   localStorage.removeItem('backPath');
                  // }
                  if (!!onCloseDrawer) {
                    onCloseDrawer();
                  }
                  if (v?.name === 'Logout') {
                    signOut(user?.ID, CompanyID);
                    sessionStorage.clear();
                    sessionStorage.removeItem('tokenKey');
                  }
                  // localStorage.removeItem('searchDropdown');
                  // localStorage.removeItem('searchFilter');
                }}
              >
                <ListItemIcon>
                  <img className="icon-svg" src={v?.icon} alt="drawer-icon" />
                </ListItemIcon>
                <ListItemText primary={v?.name} />
              </ListItem>
            );
        })}
      </List>
    </div>
  );

  // User Profile Wrapper
  const userProfile = (
    <div className="profile-container">
      <div className="profile-avatar">
        {!!user?.avatar ? (
          <img src={user?.avatar} alt="" className="avatar" />
        ) : (
          <Avatar alt="avatar" className="avatar" />
        )}
      </div>
      <List className="profile-list" disablePadding>
        <ListItem>
          <ListItemText
            primary={<span className="smTitle">{user?.userName}</span>}
            secondary={<span className="desc">{user?.email}</span>}
          />
          {isHome ? null : (
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="simple-menu"
                onClick={handleProfileClick}
              >
                <ExpandMore />
              </IconButton>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </List>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="drawer-submenu"
      >
        <MenuItem className="drawer-dropdown" onClick={handleRedirectProfile}>
          <img className="icon-dropdown-svg" src={Profile} alt="" />
          Profile
        </MenuItem>
        <MenuItem
          className="drawer-dropdown"
          onClick={() => {
            signOut(user?.ID, CompanyID);
            sessionStorage.clear();
            sessionStorage.removeItem('tokenKey');
          }}
        >
          <img className="icon-dropdown-svg" src={Logout} alt="" />
          Logout
        </MenuItem>
      </Menu>
    </div>
  );

  return (
    <>
      {/* {loading && <Loading />} */}
      {/* {sAccLoading && <Loading />} */}
      <nav className={classes.drawer} aria-label="main menu">
        <Drawer
          {...rest}
          container={container}
          variant={variant}
          anchor={'left'}
          open={open}
          onClose={onCloseDrawer}
          className="drawer"
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {userProfile}
          {drawer}
        </Drawer>
      </nav>
    </>
  );
}

// order of this is important
const mode = [
  'general-purchase',
  'supplier',
  // 'contract',
  // 'client-account',
  // 'project-costing',
  // 'profit-recognition',
  // 'system-admin',
  // 'gl-interface',
  // 'client-contract',
  // 'plant-machinery',
  // 'subcon-tender',
  // 'subcontract',
  // 'subcontractor',
  // 'supplier',
];

export const isIn = (route: string, module: string) => {
  return route.match(module);
};

export const reconsRoute = (route: string) => {
  let genRoute = mode.filter(x => isIn(route, x));
  return genRoute.length > 0 ? genRoute[genRoute.length - 1] : genRoute;
};
