import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { useGetCompanyNameQuery, useGetPrHeaderQuery } from 'generated/graphql';
import { useMobilePDFZoom } from 'helpers/Hooks/useMobilePDFZoom';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import HeaderPR from './PRTemplate/HeaderPR';
import TablePR from './PRTemplate/TablePR';
import Table2PR from './PRTemplate/Table2PR';
import FooterPR from './FooterPR';
import TablePO from './POTemplate/TablePO';
import Table2PO from './POTemplate/Table2PO';
import HeaderPO from './POTemplate/HeaderPO';
import { useMediaQuery } from '@material-ui/core';
import theme from '@ifca-root/react-component/src/assets/theme';

export const PDFPreview = (_props: any) => {
  const location: any = useLocation();
  const previewDetail: any = location?.state;

  let history = useHistory();
  const { styles } = useMobilePDFZoom();
  const [visibled, setVisibility] = useState(null);
  const { CompanyID, PRHeaderID }: any = useParams();

  const { accountType, docType, backPath } = _props;

  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const amount = previewDetail?.POItem?.reduce(
    (total, amountValue) => (total = total + amountValue.BaseAmt),
    0,
  );

  const taxAmount = previewDetail?.POItem?.reduce(
    (total, amountValue) => (total = total + amountValue.TaxAmt),
    0,
  );

  const totalAmount = previewDetail?.POItem?.reduce(
    (total, amountValue) => (total = total + amountValue.TotalAmt),
    0,
  );

  let routeSegments;
  let title;
  let smTitle;
  switch (accountType) {
    case '/':
      smTitle = 'General Purchases';
      switch (docType) {
        case 'preview-po':
          routeSegments = 'Purchase Order';
          title = 'Purchases Order';
          break;
        case 'preview-pr':
          routeSegments = 'Purchases Requisition';
          title = 'Purchases Requisition';
          break;
        default:
          break;
      }
      switch (backPath) {
        case 'purchase-requisition':
          break;
        case 'direct-po':
          break;
      }
      break;
  }

  interface InfoTemplateRight {
    title: string;
    data: string;
  }
  const InfoTemplateRight = ({ title, data }: InfoTemplateRight) => {
    // let fontSize, customWidth;
    // if (width < 769) {
    //   fontSize = 5;
    //   customWidth = '1px';
    // } else {
    //   fontSize = 10;
    //   customWidth = '5px';
    // }
    return (
      <div style={{ paddingLeft: '20%' }}>
        <div style={{ width: '40%', display: 'inline-flex' }}>{`${title}`}</div>
        <div style={{ width: '10%', display: 'inline-flex' }}>:</div>
        <div style={{ width: '50%', display: 'inline-flex' }}>{data}</div>
      </div>
    );
  };

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany } = {
      getCompany: [],
    },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  });

  let watemarkStyle;

  // if (screenWidth < 769) {
  //   watemarkStyle = {
  //     watermark: {
  //       position: 'absolute' as 'absolute',
  //       top: '20%',
  //       width: '80%',
  //       textAlign: 'center',
  //       opacity: `${visibled}`,
  //       fontSize: '18px',
  //       userSelect: 'none',
  //       transform: 'rotate(-45deg)',
  //     },
  //   }
  // } else {
  //   watemarkStyle = {
  //     watermark: {
  //       position: 'absolute' as 'absolute',
  //       top: '40%',
  //       width: '80%',
  //       textAlign: 'center',
  //       opacity: `${visibled}`,
  //       fontSize: '18px',
  //       userSelect: 'none',
  //       transform: 'rotate(-40deg)',
  //     },
  //   }
  // }

  const {
    loading,
    error,
    data: { getPRHeader } = { getPRHeader: [] },
  } = useGetPrHeaderQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      PRHeaderID: PRHeaderID,
    },
  });

  const onPrint = () => {
    getPRHeader
      ?.filter(x => x?.CompanyID === previewDetail?.CompanyID)
      ?.map((v, i) => {
        let element = document.getElementById('prList');
        html2pdf()
          .set({
            filename: `${
              getPRHeader?.filter(x => x?.CompanyID === v?.CompanyID)[0]
                ?.Company?.Name
            }).pdf`,
          })
          .from(element)
          .save();
      });
  };

  const borderWith = {
    borderBottom: '4px solid black',
  };

  let logoStyle, containerStyle, fontStyle;
  fontStyle = {
    padding: '10px',
    fontSize: isDesktop ? '12px' : '7px',
    textAlign: 'center',
  };

  containerStyle = {
    paddingLeft: '10px',
    paddingRight: '10px',
  };

  return (
    <>
      {companyLoading && <Loading />}
      {loading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push(`/${CompanyID}/${backPath}`);
        }}
        smTitle={'General Purchases'}
        title={getCompany[0]?.Name}
        routeSegments={[
          { name: `${smTitle}` },
          { name: `${routeSegments}` },
          { name: `${routeSegments}`, current: true },
        ]}
      />

      <ContentWrapper footer>
        <div
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
          id={'prList'}
        >
          <div style={styles.supercontainer}>
            <div style={styles.container}>
              {accountType === '/' && docType === 'preview-pr' && (
                <>
                  <HeaderPR />
                  <div style={fontStyle}>
                    <b>{title}</b>
                  </div>

                  <TablePR
                    name={previewDetail?.DeliveryLocation?.Name}
                    data={previewDetail}
                  />
                  <Table2PR data={previewDetail} />

                  <FooterPR />
                </>
              )}

              {accountType === '/' && docType === 'preview-po' && (
                <>
                  <HeaderPO />
                  <div style={fontStyle}>
                    <b>{title}</b>
                  </div>

                  <TablePO
                    name={previewDetail?.DeliveryLocation?.Name}
                    data={previewDetail}
                  />

                  <Table2PO data={previewDetail} />

                  <FooterPR />
                </>
              )}
            </div>
          </div>
        </div>

        <Footer
          options={[
            {
              name: 'Download',
              onClick: () => {
                onPrint();
              },

              color: 'primary',
            },
          ]}
        />
      </ContentWrapper>
    </>
  );
};
