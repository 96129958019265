import React, { lazy } from 'react';

const GeneralRFQListing = lazy(() =>
  import('./GeneralRFQListing').then(module => ({
    default: module.RFQListing,
  })),
);

const GeneralRFQMain = lazy(() =>
  import('./GeneralRFQMain').then(module => ({
    default: module.GeneralRFQMain,
  })),
);

const GeneralRFQForm = lazy(() =>
  import('./GeneralRFQForm').then(module => ({
    default: module.GeneralRFQForm,
  })),
);

const GeneralRFQDetails = lazy(() =>
  import('./GeneralRFQDetails').then(module => ({
    default: module.GeneralRFQDetails,
  })),
);

// RFQInvitess Submenu
const GeneralRFQInviteesListing = lazy(() =>
  import('./GeneralRFQInvitees/GeneralRFQInviteesListing').then(module => ({
    default: module.RFQInviteesListing,
  })),
);

const GeneralRFQInviteesForm = lazy(() =>
  import('./GeneralRFQInvitees/GeneralRFQInviteesForm').then(module => ({
    default: module.RFQInviteesForm,
  })),
);

const GeneralRFQQuotedListing = lazy(() =>
  import('./GeneralRFQInvitees/QuotedRFQ/QuotedRFQListing').then(module => ({
    default: module.QuotedRFQListing,
  })),
);

const GeneralRFQQuotedDetails = lazy(() =>
  import('./GeneralRFQInvitees/QuotedRFQ/QuotedRFQDetails').then(module => ({
    default: module.QuotedRFQDetails,
  })),
);

const GeneralRFQQuotedForm = lazy(() =>
  import('./GeneralRFQInvitees/QuotedRFQ/QuotedRFQForm').then(module => ({
    default: module.QuotedRFQForm,
  })),
);

const GeneralRFQEvaluationSelection = lazy(() =>
  import('./GeneralRFQEvaluationSelection').then(module => ({
    default: module.GeneralRFQEvaluationSelection,
  })),
);

const generalRFQRoutes = [
  {
    props: { exact: true, path: '/:CompanyID/rfq' },
    component: <GeneralRFQMain />,
  },
  {
    props: { exact: true, path: '/:CompanyID/rfq' },
    component: <GeneralRFQListing />,
  },
  {
    props: { exact: true, path: '/:CompanyID/rfq/add' },
    component: <GeneralRFQForm mode="add" />,
  },
  {
    props: { exact: true, path: '/:CompanyID/rfq/edit/:RFQHeaderID' },
    component: <GeneralRFQForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `/:CompanyID/rfq/:RFQHeaderID`,
    },
    component: <GeneralRFQDetails />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/rfq/:RFQHeaderID/invitees',
    },
    component: <GeneralRFQInviteesListing />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/rfq/:RFQHeaderID/invitees/add',
    },
    component: <GeneralRFQInviteesForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/:CompanyID/rfq/:RFQHeaderID/invitees/:SupplierID/quoted-rfq/:RFQSupplierInvitationID',
    },
    component: <GeneralRFQQuotedListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/:CompanyID/rfq/:RFQHeaderID/invitees/:SupplierID/quoted-rfq/:RFQSupplierInvitationID/view/:RFQSupplierSubmissionID',
    },
    component: <GeneralRFQQuotedDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/:CompanyID/rfq/:RFQHeaderID/invitees/:SupplierID/quoted-rfq/:RFQSupplierInvitationID/add',
    },
    component: <GeneralRFQQuotedForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/:CompanyID/rfq/:RFQHeaderID/invitees/:SupplierID/quoted-rfq/:RFQSupplierInvitationID/edit/:RFQSupplierSubmissionID',
    },
    component: <GeneralRFQQuotedForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/rfq/:RFQHeaderID/Evaluation-&-Selection',
    },
    component: <GeneralRFQEvaluationSelection mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/rfq/:RFQHeaderID/Evaluation-&-Selection/resubmit',
    },
    component: <GeneralRFQEvaluationSelection mode="resubmit" />,
  },
];

export default generalRFQRoutes;
