import React, { lazy } from 'react';

const AutoNumberingForm = lazy(() =>
  import('./AutoNumberingForm').then(module => ({
    default: module.AutoNumberingForm,
  })),
);

const autoNumberingRoutes = [
  {
    props: {
      exact: true,
      path:
        '/company-settings/:CompanyID/numbering-structure/general-purchases/purchase-requisition',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="purchase-requisition"
        pageMode="general-purchases"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/company-settings/:CompanyID/numbering-structure/general-purchases/request-quotation',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="request-quotation"
        pageMode="general-purchases"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/company-settings/:CompanyID/numbering-structure/general-purchases/purchase-order',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="purchase-order"
        pageMode="general-purchases"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/company-settings/:CompanyID/numbering-structure/general-purchases/delivery-order',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="delivery-order"
        pageMode="general-purchases"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/company-settings/:CompanyID/numbering-structure/general-purchases/good-return-note',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="good-return-note"
        pageMode="general-purchases"
      />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/company-settings/:CompanyID/numbering-structure/general-purchases/refund',
    },
    component: (
      <AutoNumberingForm
        mode="add"
        DocType="refund"
        pageMode="general-purchases"
      />
    ),
  },
];

export default autoNumberingRoutes;
