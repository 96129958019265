import React, { lazy } from 'react';
import { ConversionRateListing } from './ConversionRateListing';

const UOMRateListing = lazy(() =>
  import('./UOMRateListing').then(module => ({
    default: module.UOMRateListing,
  })),
);

// const UOMExchangeListing = lazy(() =>
//   import(
//     '@ifca-root//UOMExchange/UOMRateListing'
//   ).then(module => ({
//     default: module.UOMRateListing,
//   })),
// );

const uomRateRoutes = [
  {
    props: {
      exact: true,
      path: '/general-setting/uom-conversion',
    },
    component: <ConversionRateListing />,
  },
];

export default uomRateRoutes;
