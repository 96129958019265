import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { advancedFilterList } from '@ifca-root/react-component/src/components/Filter/AdvancedFilter';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter';
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons';
import SubmitterLog from 'assets/icons/submitter-log.svg';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { DeleteDialog } from 'containers/GeneralSettingModule/Item/components/DeleteDialog';
import {
  useDeleteGrtnMutation,
  useGetDocNumTitleQuery,
} from 'generated/graphql';
import { useColorStatusList } from 'helpers/Hooks/useColorStatusList';
import { useMenuOption } from 'helpers/Hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/Hooks/usePermissionChecker';
import { SystemMsgs } from 'helpers/Messages/SystemMsg';
import {
  formatDate,
  formatTime,
} from 'helpers/StringNumberFunction/formatDate';
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router';

export const GeneralGRTNEntryList = (props: any) => {
  const {
    filteredList,
    loadRfq,
    userList,
    listStatus,
    createPermissionCheck,
    updatePermissionCheck,
    deletePermissionCheck,
    labelState,
  } = props;

  const companyStatus = localStorage?.getItem('companyStatus');
  let history = useHistory();
  const { CompanyID }: any = useParams();
  const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
  const { handleStatusColor, handleStatusRename } = useColorStatusList();
  const [openDelete, setOpenDelete] = useState<boolean>(false);

  const { setOpenSnackBar, setSnackBarMsg } = useContext(
    SnackBarContext,
  ) as any;

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = {
      getDocumentNumberHeader: [],
    },
  } = useGetDocNumTitleQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      RefTable: 'GP_GRTNHeader',
    },
  });

  const docNumChecker = getDocumentNumberHeader?.length === 0;
  /** This is for permission purposes */
  const { handlePermDisabled } = usePermissionChecker();

  const [deleteGRTN, { loading: deleteGRTNLoading }] = useDeleteGrtnMutation({
    onError: error => {},
    onCompleted: data => {
      setOpenSnackBar(true);
      setSnackBarMsg(SystemMsgs.deleteRecord());
      setTimeout(() => {
        history.push({
          pathname: `/${CompanyID}/good-return-note`,
          state: { success: true, msgMode: 'delete' },
        });
      }, 500);
      loadRfq({ variables: { ApprovalStatus: listStatus } });
    },
  });

  const handleDelete = data => {
    deleteGRTN({ variables: { GRTNHeaderID: data } });
  };

  return (
    <>
      {deleteGRTNLoading && <Loading />}
      {docNumHeaderLoading && <Loading />}{' '}
      <ContentWrapper float>
        <List className="core-list">
          {advancedFilterList(filteredList) === undefined ||
          advancedFilterList(filteredList)?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            advancedFilterList(filteredList).map((el, index) => {
              const rfqsubSupID = el?.RFQSupplierInvitation?.map(
                v => v?.SupplierID,
              );

              const returnedAmtArray =
                el?.GRTNHeaderItem?.map(x => x?.ReturnedAmt) || [];

              const TotalAmt = returnedAmtArray.reduce(
                (accumulator, currentValue) =>
                  (accumulator || 0) + (currentValue || 0),
                0,
              );
              return (
                <ListItem>
                  <ListItemText
                    primary={
                      <>
                        <span className="xsTitle flex-space">
                          {el?.DocNo || el?.RefNo}
                        </span>
                        <span className="desc">
                          {`${formatDate(el?.DocDate)}` ?? 'N/A'}
                        </span>
                      </>
                    }
                    secondary={
                      <Grid container className="desc flex-space text-noflow">
                        {/* {listStatus === 'COMPLETED' && ( */}
                        <Grid spacing={1} container className="table-content">
                          <Grid item xs={8}>
                            <span style={{ fontSize: '10px' }}>
                              {el?.Supplier?.CompanyName}
                            </span>
                          </Grid>
                          <Grid item xs={4}>
                            <IconText
                              parentStyle={{ justifyContent: 'end' }}
                              font="xxTitle c-orange"
                              children={amtStr(TotalAmt)}
                            />
                          </Grid>
                        </Grid>
                        {/* )} */}

                        <Grid spacing={1} container className="table-content">
                          <Grid item xs={12}>
                            <div className="icon-text">
                              <Tooltip
                                disableFocusListener
                                title={
                                  <React.Fragment>
                                    <Grid container wrap="nowrap" spacing={2}>
                                      <Grid item>
                                        <IconText
                                          icon={
                                            <img
                                              src={SubmitterLog}
                                              style={{
                                                width: '12px',
                                                marginRight: '3px',
                                              }}
                                            />
                                          }
                                          font="desc"
                                        ></IconText>
                                      </Grid>
                                      <Grid item xs zeroMinWidth>
                                        <div style={{ fontSize: '12px' }}>
                                          {userList?.find(
                                            x => x?.ID === el?.createdBy,
                                          )?.name ??
                                            userList?.find(
                                              x =>
                                                x?.ID === el?.createdBy ??
                                                el?.modBy,
                                            )?.name}
                                        </div>
                                        <div style={{ fontSize: '10px' }}>
                                          {formatDate(
                                            el?.modTs ?? el?.createdTs,
                                          )}
                                          &nbsp;
                                          {formatTime(
                                            el?.modTs ?? el?.createdTs,
                                          )}
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </React.Fragment>
                                }
                                arrow
                                enterTouchDelay={0}
                              >
                                <div>
                                  <IconText
                                    icon={
                                      <img
                                        src={SubmitterLog}
                                        style={{
                                          width: '12px',
                                          marginRight: '3px',
                                        }}
                                      />
                                    }
                                    font="desc"
                                  ></IconText>
                                </div>
                              </Tooltip>
                              <span className="desc text-noflow">
                                {el?.Description}
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    }
                  />
                  <ListItemSecondaryAction>
                    {listStatus === 'ACTIVE' || listStatus === 'REJECTED' ? (
                      <>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          aria-controls="menu-list"
                          aria-haspopup="true"
                          onClick={e =>
                            handleClick(e, el?.GRTNHeaderID, index, el)
                          }
                          style={{ verticalAlign: '35px', marginTop: '40px' }}
                        >
                          <MoreVert />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton edge="end" aria-label="arrow">
                        <KeyboardArrowRight
                          onClick={() => {
                            history.push({
                              pathname: `/${CompanyID}/good-return-note/${el?.GRTNHeaderID}/detail`,
                              state: el,
                            });
                          }}
                        />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
          )}
        </List>
      </ContentWrapper>
      {listStatus === 'ACTIVE' && (
        <FloatButton
          // disabled={handlePermDisabled({
          //   companyID: CompanyID, // later change to CompanyID
          //   permEnum: createPermissionCheck,
          // })}

          onClick={() => {
            if (docNumChecker) {
              setOpenSnackBar(true);
              setSnackBarMsg(SystemMsgs.errorNumberingStructure());
            } else {
              history.push(`/${CompanyID}/good-return-note/add`);
            }
          }}
          disabled={
            companyStatus === 'INACTIVE' ||
            handlePermDisabled({
              companyID: CompanyID, /// later change to CompanyID
              permEnum: createPermissionCheck,
            })
              ? true
              : false
          }
        />
      )}
      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        {listStatus === 'ACTIVE' && (
          <>
            <MenuItem
              // disabled={handlePermDisabled({
              //   companyID: '',
              //   permEnum: updatePermissionCheck,
              // })}
              disabled={
                companyStatus === 'INACTIVE' ||
                handlePermDisabled({
                  companyID: CompanyID, // later change to CompanyID
                  permEnum: updatePermissionCheck,
                })
                  ? true
                  : false
              }
              onClick={() =>
                history.push({
                  pathname: `/${CompanyID}/good-return-note/edit/${menu?.ID}`,
                  state: menu?.obj,
                })
              }
            >
              Edit
            </MenuItem>
            <MenuItem
              // disabled={handlePermDisabled({
              //   companyID: '',
              //   permEnum: deletePermissionCheck,
              // })}
              disabled={
                companyStatus === 'INACTIVE' ||
                handlePermDisabled({
                  companyID: CompanyID,
                  permEnum: deletePermissionCheck,
                })
                  ? true
                  : false
              }
              onClick={() => {
                setOpenDelete(true);
              }}
            >
              <span className="">Delete</span>
            </MenuItem>
          </>
        )}
        {listStatus === 'REJECTED' && (
          <MenuItem
            // disabled={handlePermDisabled({
            //   companyID: '',
            //   permEnum: updatePermissionCheck,
            // })}
            disabled={
              companyStatus === 'INACTIVE' ||
              handlePermDisabled({
                companyID: CompanyID, // later change to CompanyID
                permEnum: updatePermissionCheck,
              })
                ? true
                : false
            }
            onClick={() =>
              history.push({
                pathname: `/${CompanyID}/good-return-note/edit/${menu?.ID}`,
                state: menu?.obj,
              })
            }
          >
            Resubmit
          </MenuItem>
        )}
        {listStatus === 'COMPLETED' && (
          <>
            <MenuItem
              // disabled={handlePermDisabled({
              //   companyID: CompanyID, // later change to CompanyID
              //   permEnum: updatePermissionCheck,
              // })}
              onClick={() => {
                history.push({
                  pathname: `/${CompanyID}/rfq/${menu?.ID}`,
                  state: menu?.obj,
                });
              }}
            >
              <span className="">View</span>
            </MenuItem>
            <MenuItem
              // disabled={handlePermDisabled({
              //   companyID: CompanyID, // later change to CompanyID
              //   permEnum: updatePermissionCheck,
              // })}
              onClick={() => {
                history.push({
                  pathname: `/${CompanyID}/rfq/${menu?.ID}/Evaluation-&-Selection`,
                  state: menu?.obj,
                });
              }}
            >
              <span className="">Review Selection</span>
            </MenuItem>
          </>
        )}
      </Menu>
      <DeleteDialog
        ID={menu?.ID}
        openDialog={openDelete}
        setOpenDialog={setOpenDelete}
        title={'Good Return Note'}
        menu={menu?.obj}
        handleDelete={handleDelete}
        action={'Delete'}
      />
    </>
  );
};
