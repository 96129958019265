import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import GeneralSetting from 'assets/icons/SideMenu/general_settings.svg';
import ContractSetup from 'assets/icons/SideMenu/project-setup.svg';
import NumberingStructure from 'assets/icons/numbering.svg';
import {
  useGetCompanyNameQuery,
  useGetCompanySettingSummaryQuery,
} from 'generated/graphql';
import { formatDate } from 'helpers/StringNumberFunction/formatDate';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

export const CompanySubmenu = (props: any) => {
  useEffect(() => {
    document.title = `AX - ${smTitle.GENERAL_PURCHASES}`;
  }, []);
  const { mode: pageMode, CompanyID }: any = useParams();
  let history = useHistory();
  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  const {
    loading,
    error,
    data: {
      lastApprovalPolicy,
      lastNumberingStructure,
      activeDeliveryLocationCount,
    } = {
      lastApprovalPolicy: null,
      lastNumberingStructure: null,
      activeDeliveryLocationCount: 0,
    },
  } = useGetCompanySettingSummaryQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  });

  const {
    loading: companyLoading,
    error: companyError,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  });

  const approvalPolicy = {
    path: !!pageMode
      ? `/${pageMode}/${CompanyID}/company-settings/approval-policy`
      : `/company-settings/${CompanyID}/approval-policy`,
    icon: ContractSetup,
    labelType: `label-data`,
    disabled: false,
    deployed: true,
    title: 'Approval Policy',
    label: `Last Updated`,
    data: formatDate(lastApprovalPolicy),
  };

  const numberingStructure = {
    path: !!pageMode
      ? `/${pageMode}/${CompanyID}/company-settings/numbering-structure`
      : `/company-settings/${CompanyID}/numbering-structure`,
    icon: NumberingStructure,
    labelType: `label-data`,
    disabled: false,
    deployed: true,
    title: 'Document Numbering',
    label: `Last Updated`,
    data: formatDate(lastNumberingStructure),
  };

  const companyLocation = {
    path: !!pageMode
      ? `/${pageMode}/company-settings/delivery-location`
      : `/company-settings/${CompanyID}/delivery-location`,
    icon: GeneralSetting,
    labelType: `data-label`,
    disabled: false,
    deployed: true,
    title: 'Delivery Location',
    label: `Location(s)`,
    data: activeDeliveryLocationCount,
  };

  const glAccountSetup = {
    path: !!pageMode
      ? `/${pageMode}/company-settings/gl-account`
      : `/company-settings/gl-account`,
    icon: GeneralSetting,
    labelType: `label-data`,
    disabled: true,
    deployed: true,
    title: 'GL Account Setup',
    label: `Last Updated `,
    data: formatDate(new Date()?.toISOString()),
  };

  const companySettingSubmenu = [
    approvalPolicy,
    numberingStructure,
    companyLocation,
    glAccountSetup,
  ];
  return (
    <>
      {loading && <Loading />}
      {companyLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/company-settings`)}
        smTitle={smTitle.COMPANY_SETTINGS}
        title={user?.companyName}
        routeSegments={[{ name: 'Main Menu' }, { name: 'Menu', current: true }]}
      />
      <DynamicSubHeader title={getCompany[0]?.Name} />
      <ContentWrapper search>
        <List className="core-list">
          {companySettingSubmenu?.map((el, index) => {
            return (
              <ListItem
                key={index}
                disabled={el?.disabled}
                onClick={() => history.push({ pathname: el?.path })}
              >
                <IconItem
                  image={el?.icon}
                  imageType="svg"
                  badgeType="default"
                />
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle flex-space">{el?.title} </span>
                    </>
                  }
                  secondary={
                    el?.labelType == 'label-data' ? (
                      <>
                        <span className="desc">{el?.label}</span>
                        <span className="desc fw-medium p-l-3">{el?.data}</span>
                      </>
                    ) : el?.labelType == 'label-only' ? (
                      <span className="desc p-r-3">{el?.label}</span>
                    ) : (
                      <>
                        <span className="desc fw-semibold p-r-3">
                          {el?.data}
                        </span>
                        <span className="desc">{el?.label}</span>
                      </>
                    )
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => history.push({ pathname: el?.path })}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </ContentWrapper>
    </>
  );
};
