import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
  Checkbox,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { useGetGeneralDoQuery, useGetItemQuery } from 'generated/graphql';
import { formatDate } from 'helpers/StringNumberFunction/formatDate';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';

export const GeneralGRTNContent = props => {
  const { doArr, GRTNLoading }: any = props;
  const [itemHasChanges, setItemChanges] = useState([]);

  return (
    <>
      {!GRTNLoading && doArr?.length ? (
        <InputContentDetails
          doMap={doArr}
          {...props}
          headerTitle="GRTN Item"
          setItemChanges={setItemChanges}
          itemHasChanges={itemHasChanges}
        />
      ) : null}
    </>
  );
};

export const InputContentDetails = props => {
  const {
    state,
    rpaResult,
    GRTNLoading,
    doMap,
    doArr,
    headerTitle,
    mode,
    editData,
    setPO,
    itemHasChanges,
    setItemChanges,
    setNoError,
    DOHeaderID,
    useFormProps: {
      clearErrors,
      setError,
      errors,
      watch,
      register,
      control,
      setValue,
    },
    itemHasValueQty,
    itemHasValueAmt,
    CompanyID,
    selectedDate,
  } = props;

  const {
    loading: DOLoading,
    error,
    data: { getGeneralDO: getGeneralDO } = { getGeneralDO: [] },
  } = useGetGeneralDoQuery({
    fetchPolicy: 'network-only',
    variables: { DOHeaderID: DOHeaderID },
  });

  const {
    loading: ItemLoading,
    error: ItemError,
    data: { getItem: getItem } = { getItem: [] },
  } = useGetItemQuery({
    fetchPolicy: 'network-only',
  });

  const checkItemHasValueQty = () => {
    const hasValue = Object.values(
      watch(`getGeneralPOs`) || {},
    )?.map((v: any) =>
      v?.POItem?.map(s =>
        s?.AcceptedQty
          ? parseFloat(s?.AcceptedQty)
          : editData?.poItem?.find(a => a?.poItemID === s?.ID)?.AcceptedQty,
      ),
    );

    itemHasValueQty(hasValue?.map(v => v?.some(s => s > 0)));
  };

  useEffect(() => {
    if (editData || itemHasChanges) {
      checkItemHasValueQty();
    }
  }, [editData, itemHasChanges]);

  const sortingFormatDate = dateString => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const formattedDate = sortingFormatDate(selectedDate);
  return (
    <>
      {ItemLoading && <Loading />}
      {DOLoading && <Loading />}
      <CardContents
        contentStyle={{ display: 'block' }}
        section={{
          header: {
            title: headerTitle,
          },
        }}
      >
        <List className="core-list">
          {doMap
            ?.filter(
              m => m?.DOItem?.filter(q => !(q?.UpToDateQty === 0))?.length > 0,
            )
            ?.filter(dateFil => dateFil?.DocDate <= formattedDate)
            ?.sort((a, b) => {
              return a.DocNo.localeCompare(b.DocNo);
            })
            ?.map((el, i) => {
              const defaultDOHeaderIDs = editData?.GRTNHeaderItem?.map(
                x => x?.DOItem?.DOHeaderID,
              );

              return (
                <CardExpansion
                  id={`${el?.DOHeaderID}`}
                  expansion={
                    mode === 'edit'
                      ? !!defaultDOHeaderIDs.includes(el?.DOHeaderID)
                      : false
                  }
                  key={`${el?.DOHeaderID}`}
                  summary={
                    <Grid item xs={12}>
                      <ListItemText
                        primary={
                          <>
                            <Grid container className="fw-medium desc">
                              <input
                                name={`getDO[${el?.DOHeaderID}].DOHeaderID`}
                                ref={register}
                                defaultValue={el?.DOHeaderID}
                                style={{ display: 'none' }}
                              />
                              <Grid item xs={9} className="mdLabel">
                                <span>{`${el?.DocNo}  | ${el?.DoNo}`}</span>
                              </Grid>
                              <Grid item xs={3} className="right-text">
                                <span>{formatDate(el?.DocDate)}</span>
                              </Grid>
                            </Grid>
                          </>
                        }
                      />
                    </Grid>
                  }
                >
                  <div className="rm-padding table-wrap">
                    <List
                      className="core-list content-wrap ;"
                      style={{ display: 'contents' }}
                    >
                      {el?.DOItem?.map((inel, initem) => {
                        return (
                          <ListItem
                            key={initem}
                            className="table-listItem"
                            divider={initem > 1 ? true : false}
                            style={{ padding: '0px' }}
                          >
                            <ListItemText
                              primary={
                                <Grid container className="desc">
                                  <Grid
                                    item
                                    xs={1}
                                    component="span"
                                    className="desc"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <span className="xxTitle">{`${initem +
                                      1}.`}</span>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={11}
                                    component="span"
                                    className="desc fw-medium"
                                    style={{ paddingTop: '10px' }}
                                  >
                                    <div
                                      className="xxTitle text-noflow"
                                      style={{
                                        width: '100%',
                                        wordWrap: 'break-word',
                                      }}
                                    >
                                      {inel?.POItem?.Item?.Name}
                                    </div>
                                  </Grid>
                                </Grid>
                              }
                              secondary={
                                <>
                                  <Grid container className="desc">
                                    <Grid item xs={1}></Grid>
                                    <Grid
                                      item
                                      xs={2}
                                      component="span"
                                      className="desc"
                                    >
                                      <td style={{ paddingTop: '10px' }}>
                                        {' '}
                                        <div
                                          className="desc"
                                          style={{ color: 'lightslategrey' }}
                                        >
                                          {' '}
                                          <span className="desc fw-medium">
                                            {inel?.POItem?.UOM?.Code}
                                          </span>
                                        </div>
                                      </td>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      component="span"
                                      className="desc"
                                    >
                                      <td
                                        style={{
                                          paddingTop: '10px',
                                          width: '100px',
                                        }}
                                      >
                                        <input
                                          name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].doItemID`}
                                          ref={register}
                                          defaultValue={inel?.DOItemID}
                                          style={{ display: 'none' }}
                                        />
                                        <input
                                          name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].UOMID`}
                                          ref={register}
                                          defaultValue={inel?.UOMID}
                                          style={{ display: 'none' }}
                                        />
                                        <Controller
                                          as={<NumberFormat />}
                                          thousandSeparator
                                          disabled
                                          variant="outlined"
                                          label="DO Qty"
                                          className="disabled-input"
                                          autoComplete="off"
                                          customInput={TextField}
                                          allowNegative={false}
                                          fixedDecimalScale
                                          name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].UpToDateQty`}
                                          ref={register}
                                          control={control}
                                          decimalScale={4}
                                          defaultValue={inel?.UpToDateQty}
                                        />
                                      </td>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      component="span"
                                      className="desc"
                                    >
                                      <td
                                        style={{
                                          paddingTop: '10px',
                                          width: '100px',
                                        }}
                                      >
                                        <Controller
                                          control={control}
                                          ref={register}
                                          label="Rtn Qty"
                                          defaultValue={
                                            mode !== 'add'
                                              ? editData?.GRTNHeaderItem?.filter(
                                                  v =>
                                                    v?.DOItemID ===
                                                    inel?.DOItemID,
                                                )?.length > 0
                                                ? editData?.GRTNHeaderItem?.filter(
                                                    v =>
                                                      v?.DOItemID ===
                                                      inel?.DOItemID,
                                                  )?.map(x => x?.ReturnedQty)[0]
                                                : '.0000'
                                              : '.0000'
                                          }
                                          name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`}
                                          render={({ onChange, value }) => {
                                            return (
                                              <NumberFormat
                                                thousandSeparator
                                                label="Rtn Qty"
                                                allowNegative={false}
                                                customInput={TextField}
                                                className="disabled-input"
                                                name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`}
                                                variant="outlined"
                                                decimalScale={4}
                                                fixedDecimalScale
                                                defaultValue={
                                                  mode !== 'add'
                                                    ? editData?.GRTNHeaderItem?.filter(
                                                        v =>
                                                          v?.DOItemID ===
                                                          inel?.DOItemID,
                                                      )?.length > 0
                                                      ? editData?.GRTNHeaderItem.find(
                                                          x =>
                                                            x?.DOItem
                                                              ?.DOHeaderID ===
                                                              inel?.DOHeaderID &&
                                                            x?.DOItem
                                                              ?.DOItemID ===
                                                              inel?.DOItemID,
                                                        )?.ReturnedQty ||
                                                        '.0000'
                                                      : '.0000'
                                                    : '.0000'
                                                }
                                                onValueChange={({
                                                  floatValue,
                                                }) => {
                                                  const val = floatValue;
                                                  let displayValue;
                                                  if (
                                                    val === 0 ||
                                                    val === null ||
                                                    val === undefined
                                                  ) {
                                                    displayValue = '.0000';
                                                  } else {
                                                    displayValue = val.toString();
                                                  }

                                                  const unitPrice =
                                                    inel?.POItem?.UnitPrice ||
                                                    0;

                                                  const calculatedAmt =
                                                    (val ?? 0) * unitPrice;
                                                  setValue(
                                                    `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`,
                                                    calculatedAmt,
                                                  );

                                                  let temp = itemHasChanges;

                                                  if (
                                                    temp.find(
                                                      s =>
                                                        s?.DOHeaderID ===
                                                        inel?.DOHeaderID,
                                                    ) !== false
                                                  ) {
                                                    temp = temp?.filter(
                                                      d =>
                                                        d?.DOHeaderID !==
                                                        inel?.DOHeaderID,
                                                    );

                                                    setItemChanges([
                                                      {
                                                        DOHeaderID:
                                                          inel?.DOHeaderID,
                                                        value: floatValue,
                                                      },
                                                      ...temp,
                                                    ]);
                                                  }

                                                  setItemChanges([
                                                    {
                                                      DOHeaderID:
                                                        inel?.DOHeaderID,
                                                      value: floatValue,
                                                    },
                                                    ...temp,
                                                  ]);

                                                  if (
                                                    value !== '0.00' &&
                                                    value !== ''
                                                  ) {
                                                    if (
                                                      new BigNumber(value).gt(
                                                        inel?.UpToDateQty,
                                                      )
                                                    ) {
                                                      setNoError(false);
                                                      setError(
                                                        `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                        {
                                                          type: 'manual',

                                                          message:
                                                            'Quantity exceeded delivered',
                                                        },
                                                      );
                                                    } else if (
                                                      new BigNumber(value).lt(0)
                                                    ) {
                                                      setNoError(false);
                                                      setError(
                                                        `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                        {
                                                          type: 'manual',
                                                          message:
                                                            'Quantity must be positive',
                                                        },
                                                      );
                                                    } else {
                                                      setNoError(true);
                                                      clearErrors(
                                                        `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                      );
                                                    }
                                                    onChange(displayValue);
                                                  } else if (
                                                    value === '0.00' ||
                                                    value === ''
                                                  ) {
                                                  }
                                                }}
                                              />
                                            );
                                          }}
                                        />
                                      </td>
                                    </Grid>
                                    <Grid
                                      item
                                      xs={3}
                                      component="span"
                                      className="desc"
                                    >
                                      <td
                                        style={{
                                          paddingTop: '10px',
                                          width: '100px',
                                        }}
                                      >
                                        <Controller
                                          control={control}
                                          ref={register}
                                          label="Rtn Amt"
                                          defaultValue={
                                            mode !== 'add'
                                              ? editData?.GRTNHeaderItem?.filter(
                                                  v =>
                                                    v?.DOItemID ===
                                                    inel?.DOItemID,
                                                )?.length > 0
                                                ? editData?.GRTNHeaderItem.find(
                                                    x =>
                                                      x?.DOItem?.DOHeaderID ===
                                                        inel?.DOHeaderID &&
                                                      x?.DOItem?.DOItemID ===
                                                        inel?.DOItemID,
                                                  )?.ReturnedAmt || '.0000'
                                                : '.0000'
                                              : '.0000'
                                          }
                                          name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`}
                                          render={({ onChange, value }) => {
                                            return (
                                              <NumberFormat
                                                thousandSeparator
                                                label="Rtn Amt"
                                                allowNegative={false}
                                                customInput={TextField}
                                                className="disabled-input"
                                                name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`}
                                                variant="outlined"
                                                value={value}
                                                decimalScale={2}
                                                fixedDecimalScale
                                                onValueChange={({
                                                  floatValue,
                                                }) => {
                                                  const val = floatValue;
                                                  let displayValue;
                                                  if (
                                                    val === 0 ||
                                                    val === null ||
                                                    val === undefined
                                                  ) {
                                                    displayValue = '.00';
                                                  } else {
                                                    displayValue = val.toString();
                                                  }

                                                  const unitPrice =
                                                    inel?.POItem?.UnitPrice ||
                                                    0;

                                                  const calculatedQty =
                                                    (val ?? 0) / unitPrice;

                                                  setValue(
                                                    `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
                                                    calculatedQty,
                                                  );

                                                  let temp = itemHasChanges;

                                                  if (
                                                    temp.find(
                                                      s =>
                                                        s?.DOHeaderID ===
                                                        inel?.DOHeaderID,
                                                    ) !== false
                                                  ) {
                                                    temp = temp?.filter(
                                                      d =>
                                                        d?.DOHeaderID !==
                                                        inel?.DOHeaderID,
                                                    );

                                                    setItemChanges([
                                                      {
                                                        DOHeaderID:
                                                          inel?.DOHeaderID,
                                                        value: floatValue,
                                                      },
                                                      ...temp,
                                                    ]);
                                                  }

                                                  setItemChanges([
                                                    {
                                                      DOHeaderID:
                                                        inel?.DOHeaderID,
                                                      value: floatValue,
                                                    },
                                                    ...temp,
                                                  ]);

                                                  if (
                                                    value !== '0.00' &&
                                                    value !== ''
                                                  ) {
                                                    if (
                                                      new BigNumber(value).gt(
                                                        inel?.ReturnedAmt,
                                                      )
                                                    ) {
                                                      setNoError(false);
                                                      setError(
                                                        `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                        {
                                                          type: 'manual',

                                                          message:
                                                            'Quantity exceeded delivered',
                                                        },
                                                      );
                                                    } else if (
                                                      new BigNumber(value).lt(0)
                                                    ) {
                                                      setNoError(false);
                                                      setError(
                                                        `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                        {
                                                          type: 'manual',
                                                          message:
                                                            'Quantity must be positive',
                                                        },
                                                      );
                                                    } else {
                                                      setNoError(true);
                                                      clearErrors(
                                                        `getDO[${el?.DOHeaderID}].DOItem[${initem}]`,
                                                      );
                                                    }
                                                    onChange(displayValue);
                                                  } else if (
                                                    value === '0.00' ||
                                                    value === ''
                                                  ) {
                                                  }
                                                }}
                                              />
                                            );
                                          }}
                                        />
                                      </td>
                                    </Grid>
                                  </Grid>
                                  <Grid container>
                                    <Grid item xs={1} component="span" />
                                    <Grid item xs={11} component="div">
                                      <div className="desc text-noflow fw-medium">
                                        {!!inel?.POItem?.Remarks
                                          ? inel?.POItem?.POHeader?.DocNo +
                                            ` | ` +
                                            inel?.POItem?.Remarks
                                          : inel?.POItem?.POHeader?.DocNo +
                                            ` | ` +
                                            'No Remarks'}
                                      </div>
                                    </Grid>
                                  </Grid>
                                  <Grid container className="desc">
                                    <Grid
                                      item
                                      style={{
                                        marginLeft: '30px',
                                        marginTop: '10px',
                                      }}
                                    >
                                      <Controller
                                        render={({ onChange, value }) => (
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                style={{ marginLeft: '12px' }}
                                                color="primary"
                                                onChange={e => {
                                                  onChange(e.target.checked);
                                                }}
                                                checked={value}
                                              />
                                            }
                                            label={
                                              <div
                                                className="desc"
                                                style={{
                                                  color: 'lightslategray',
                                                }}
                                              >
                                                Replacement
                                              </div>
                                            }
                                            ref={register}
                                          />
                                        )}
                                        name={`getDO[${el?.DOHeaderID}].DOItem[${initem}].replacement`}
                                        control={control}
                                        defaultValue={
                                          mode !== 'add'
                                            ? editData?.GRTNHeaderItem?.filter(
                                                v =>
                                                  v?.DOItemID ===
                                                  inel?.DOItemID,
                                              )?.map(x => x?.Replacement)[0]
                                            : true
                                        }
                                      />
                                    </Grid>
                                  </Grid>
                                </>
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </div>
                </CardExpansion>
              );
            })}
        </List>
      </CardContents>
    </>
  );
};
