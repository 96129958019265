import theme from '@ifca-root/react-component/src/assets/theme'
import { useMediaQuery } from '@material-ui/core'
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter'
import { Grid } from '@material-ui/core'
import React from 'react'

export const Table2PO = (props: any) => {
  const { data } = props

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
  }

  const thright = {
    width: '15%',
    textAlign: 'center' as 'center',
  }

  const thcenter = {
    width: '15%',
    borderBottom: '0.5px solid',
    textAlign: 'center' as 'center',
  }

  const tdright = {
    width: '15%',
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'center' as 'center',
    fontWeight: 'bold' as 'bold',
  }


  let totalAmount = data?.POItem?.reduce(
    (total, currentValue) => (total = total + currentValue.BaseAmt),
    0
  )

  return (
    <>
      <div>
        <table style={{ width: '100%', fontSize: isDesktop ? '11px' : '7px', borderCollapse: 'collapse' }}>
          <Grid container style={{ marginLeft: isDesktop ? '122px'  :  '-85px' }}>
            <Grid item xs={5} style={{ paddingLeft: isDesktop ? '90px' :  '97px', fontWeight: 'bold' }}>
                  Description
              </Grid>
                <Grid item 
                 xs={1} 
                 style={{ textAlign: 'right', fontWeight: 'bold', 
                 paddingLeft: isDesktop ? 0 : '105px'
                 }}>
                   Amount
                </Grid>
                <Grid item xs={1} 
                 style={{ 
                  textAlign: 'right', 
                  fontWeight: 'bold', 
                  marginTop: isDesktop ? 0  : '-10px',
                  paddingLeft: !isDesktop ? '255px' : '20px' 
                }}>
                   UOM
                </Grid>
                <Grid item xs={1} style={{ textAlign: 'right', fontWeight: 'bold', paddingLeft: isDesktop ? '585px' : '290px', marginTop: isDesktop ? '-18px' : '-10px' }}>
                   Qty
                </Grid>
            </Grid>
            <Grid container style={{ border: '0.5px solid black', width: isDesktop ? '57%' : '93%', marginLeft: isDesktop ? '212px' : '12px' }}></Grid>
          {data?.POItem?.map((v, i) => (
            <>
              <Grid container style={{ marginBottom: '10px', marginLeft: isDesktop ? '50px' : '-78px', display: 'flex' }}>
                        <Grid item xs={6} style={{ paddingTop: '10px', paddingLeft: isDesktop ? '163px': '90px' }}>
                          {  v?.Item?.Description }
                        </Grid>
                        <Grid item xs={ isDesktop ? 1 : 3} style={{ textAlign: 'right', marginTop: '10px', paddingLeft: isDesktop ? '0px' : '84px' }}>
                         {amtStr(v?.BaseAmt)}
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: 'right', marginTop: isDesktop ? '10px' : '-10px', paddingLeft: isDesktop ? '0px' : '250px', paddingRight: isDesktop ? '-200px' : 0  }}>
                         {v?.UOM?.Name}
                        </Grid>
                        <Grid item xs={1} style={{ textAlign: 'right', paddingLeft: isDesktop ? '0px' : '287px', marginTop: isDesktop ? '10px' : '-9px' }}>
                         { v?.OrderedQty }
                        </Grid>
               </Grid>
            </>
          ))}
        </table>
        {data?.POItem?.length && (
             <Grid spacing={7} container style={{ marginTop: isDesktop ? '30px' : '-30px', fontWeight: 'bold', fontSize: isDesktop ? '11px' : '7px', marginLeft: isDesktop ? '-48px' : '-40px' }}>
               <React.Fragment>
                <Grid item xs={6} sm={7}>
                   <Grid style={{ textAlign: 'right' }}>
                    <span>Total</span> 
                    </Grid>
                </Grid>
                <Grid item xs={6} sm={1} style={{ marginLeft: '-10px' }}>
                    <Grid style={{ borderTop: '1px solid black', borderBottom: '1px solid black', width: isDesktop ? '50px' : '33px'}}>
                    <span >{amtStr(totalAmount)}</span>
                    </Grid>
                </Grid>
              </React.Fragment>
            </Grid>
        )}
      </div>
    </>
  )
}

export default Table2PO
