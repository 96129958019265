import React, { lazy } from 'react';

const PurchaseItemVariancePolicyForm = lazy(() =>
  import('./PurchaseItemVariancePolicyForm').then(module => ({
    default: module.PurchaseItemVariancePolicyForm,
  })),
);

const purchaseVarianceRoutes = [
  {
    props: {
      exact: true,
      path: '/general-setting/purchase-variance-policy',
    },
    component: <PurchaseItemVariancePolicyForm />,
  },
];

export default purchaseVarianceRoutes;
