import { yupResolver } from '@hookform/resolvers';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { TextField } from '@material-ui/core';
import { CommonYupValidation } from 'helpers/Form/YupValidation';
import { SystemMsgs } from 'helpers/Messages/SystemMsg';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import React from 'react';

export const RejectDialog = (props: any) => {
  const {
    data,
    openRejectDialog,
    setOpenRejectDialog,
    setRejectDialogInput,
    setIsReject,
  } = props;

  const rejectionRemarkSchema = yup.object().shape({
    RejectionRemark: CommonYupValidation.requireField(SystemMsgs.remark()),
  });
  const { handleSubmit, register, errors, control, watch } = useForm({
    mode: 'onSubmit',
    resolver: yupResolver(rejectionRemarkSchema),
  });

  const onSubmitReject = data => {
    setRejectDialogInput({
      RejectionRemark: data?.RejectionRemark,
    });
    setOpenRejectDialog(false);
    setIsReject(true);
  };

  return (
    <CommonDialog
      fullWidth={true}
      open={openRejectDialog}
      onClose={() => setOpenRejectDialog(false)}
      sections={{
        header: {
          dynamic: (
            <div className="">
              <div className="dialog-dynamic-content">
                <div className="session">
                  <div className="flex session">
                    <div className="title flex-space">To Do</div>
                  </div>
                </div>
              </div>
              <div className="infoline-content">
                <div className="xsTitle flex-space">{data?.DocNo}</div>
                <div className=" xsTitle rightText">Reject </div>{' '}
              </div>
            </div>
          ),
        },
        body: () => (
          <Controller
            as={TextField}
            id="standard-basic"
            name="RejectionRemark"
            label="Remark"
            autoComplete="off"
            control={control}
            fullWidth
            margin="dense"
            ref={register}
            helperText={errors?.RejectionRemark?.message}
            error={errors?.RejectionRemark ? true : false}
            required
          />
        ),
        footer: {
          actions: [
            {
              displayText: 'Cancel',
              props: {
                onClick: () => setOpenRejectDialog(false),
                variant: 'contained',
                color: 'primary',
              },
            },
            {
              displayText: 'Confirm',
              props: {
                onClick: () => handleSubmit(obj => onSubmitReject(obj))(),
                variant: 'contained',
                color: 'primary',
                disabled: watch('RejectionRemark') ? false : true,
              },
            },
          ],
        },
      }}
    />
  );
};
