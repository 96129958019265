import React, { lazy } from 'react';
import { ApprovalPolicySubmenu } from './ApprovalPolicySubmenu';
import { PRForm } from './PRForm';
import { RFQForm } from './RFQForm';
import { POListing } from './POListing';
import { POForm } from './POForm1';

const approvalPolicyRoutes = [
  {
    props: {
      exact: true,
      path: '/company-settings/:CompanyID/approval-policy',
    },
    component: <ApprovalPolicySubmenu />,
  },
  {
    props: {
      exact: true,
      path: '/company-settings/:CompanyID/approval-policy/pr',
    },
    component: <PRForm />,
  },
  {
    props: {
      exact: true,
      path: '/company-settings/:CompanyID/approval-policy/rfq',
    },
    component: <RFQForm />,
  },
  {
    props: {
      exact: true,
      path: '/company-settings/:CompanyID/approval-policy/po',
    },
    component: <POListing />,
  },
  {
    props: {
      exact: true,
      path: '/company-settings/:CompanyID/approval-policy/po/add',
    },
    component: <POForm modePage="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/company-settings/:CompanyID/approval-policy/po/edit/:ApprovalPolicyAssignmentID',
    },
    component: <POForm modePage="edit" />,
  },
];

export default approvalPolicyRoutes;
