import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { useQuery } from '@apollo/react-hooks';
import { GET_COMPANY_NAME } from 'containers/ExternalAPIModule/AccountXQuery';
import { AccountClient } from 'IFCAXClient';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  TextField,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useParams } from 'react-router';
import theme from '@ifca-root/react-component/src/assets/theme';
import AddIcon from '@material-ui/icons/Add';
import {
  GetApprovalPolicyAssignmentDocument,
  useGetApprovalPolicyAssignmentLazyQuery,
  useGetRoleWithAccountQuery,
  useCreateApprovalPolicyAssignmentMutation,
  useUpdateApprovalPolicyAssignmentMutation,
} from '../../../generated/graphql';
import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem';
import Empty from '@ifca-root/react-component/src/assets/hrIcons/no-records.svg';
import { Delete } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { SystemMsgs } from 'helpers/Messages/SystemMsg';
import { ErrorDialog } from 'components/Dialog/ErrorDialog';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import { ApprovalPolicyProps } from './PRForm';
import { yupResolver } from '@hookform/resolvers';
import { CommonYupValidation } from 'helpers/YupSchema/yup';
import * as yup from 'yup';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';

const styles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },

  item: {
    padding: '0',
    paddingRight: '10px',
    borderBottom: '2px solid rgb(224,224,224)',
  },
  lastItem: {
    padding: '0',
    paddingRight: '10px',
  },
  deleteIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  list: {
    width: '100%',
    // paddingBottom: '25px',
    [theme.breakpoints.up('md')]: {
      width: '80%',
      margin: 'Auto',
    },
  },
}));

export const POForm = (props: any) => {
  const { modePage } = props;
  let user = JSON.parse(localStorage.getItem('loggedInUser'));

  const [getApprovalList, setApprovalList] = useState(null);
  let refName = 'GP_PurchaseOrder';
  let history = useHistory();
  const classesVaria = styles();
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any,
  );
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { CompanyID, mode, ApprovalPolicyAssignmentID }: any = useParams();

  const mode1 = !!!getApprovalList ? 'new' : 'edit';

  const {
    called,
    loading,
    error,
    data: { getCompanyEntityWithAccount } = { getCompanyEntityWithAccount: [] },
  } = useQuery(GET_COMPANY_NAME, {
    fetchPolicy: 'network-only',
    client: AccountClient,
    variables: {
      CompanyID: CompanyID,
      userID: user?.ID,
      AccountID: user?.accountID,
      isSuperUser: user?.superUser,
    },
    onCompleted: ({ getCompanyEntityWithAccount }) => {},
  });

  const [
    loadApprovalPolicyAssignment,
    {
      loading: loadingApprovalAssignment,
      error: errorApprovalAssignment,
      data: { getApprovalPolicyAssignment } = {
        getApprovalPolicyAssignment: [],
      },
    },
  ] = useGetApprovalPolicyAssignmentLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getApprovalPolicyAssignment }: any) => {
      if (getApprovalPolicyAssignment?.length > 0) {
        if (!!ApprovalPolicyAssignmentID && modePage === 'edit') {
          setApprovalList(getApprovalPolicyAssignment[0]);
          register('lowerLimit');
          setValue('lowerLimit', getApprovalPolicyAssignment[0]?.lowerLimit);
          register('upperLimit');
          setValue('upperLimit', getApprovalPolicyAssignment[0]?.upperLimit);
        } else {
          register('lowerLimit');
          setValue(
            'lowerLimit',
            Number(getApprovalPolicyAssignment[0]?.upperLimit) + 1,
          );
        }
      }
    },
  });

  useEffect(() => {
    if (!!ApprovalPolicyAssignmentID && modePage === 'edit') {
      loadApprovalPolicyAssignment({
        variables: {
          Category: refName,
          CompanyID: CompanyID,
          ApprovalPolicyAssignmentID: ApprovalPolicyAssignmentID,
        },
      });
    } else {
      loadApprovalPolicyAssignment({
        variables: {
          Category: refName,
          CompanyID: CompanyID,
          orderByDesc: 'upperLimit',
        },
      });
    }
  }, [ApprovalPolicyAssignmentID, modePage]);

  const {
    data: { getRoleWithAccount } = { getRoleWithAccount: [] },
  } = useGetRoleWithAccountQuery({
    fetchPolicy: 'network-only',
    variables: {
      DifferentConnection: 'default',
    },
  });

  const formValidation = yup.object().shape({
    approvalList: yup.array().of(
      yup.object().shape({
        roleID: CommonYupValidation?.requireField(
          `Role is required`,
        ).nullable(),
      }),
    ),
    lowerLimit: yup
      .number()
      .label('test')
      .required('Lower Limit is required'),
    upperLimit: yup
      .number()
      .label('test')
      .required('Lower Limit is required'),
  });

  const { register, handleSubmit, errors, control, setValue, watch } = useForm<
    ApprovalPolicyProps
  >({
    shouldUnregister: false,
    resolver: yupResolver(formValidation),
  });

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'approvalList',
  });

  const checkRemainingRole = index => {
    let selectedRole = [];
    if (watch(`approvalList[${0}].roleID`) !== undefined) {
      for (var i = index - 1; i >= 0; i--) {
        selectedRole.push(watch(`approvalList[${i}].roleID`));
      }
    }
    return getRoleWithAccount?.filter(x => !selectedRole.includes(x?.ID));
  };

  useEffect(() => {
    if (!!getApprovalList) {
      getApprovalList?.ApprovalList?.map((v, index) => {
        append({
          stepNo: v?.StepNo,
          RoleID: v?.RoleID,
          Category: v?.Category,
        });
        setValue(`approvalList[${index}]roleID`, v?.RoleID);
      });
    }
  }, [getApprovalList]);

  const [
    createApprovalPolicyAssignment,
    { loading: loadingCreateApprovalPolicyAssignment },
  ] = useCreateApprovalPolicyAssignmentMutation({
    onError: err => {
      let errorMessage = err.message.substring(15);

      if (errorMessage) {
        setOpenSnackBar(true);
        setSnackBarMsg(errorMessage);
      }
    },
    onCompleted: data => {
      setSnackBarMsg(SystemMsgs.createNewRecord());
      setOpenSnackBar(true);
      history.push(`/company-settings/${CompanyID}/approval-policy/po`);
    },
  });

  const [
    updateApprovalPolicyAssignment,
    { loading: loadingUpdateApprovalAssignment },
  ] = useUpdateApprovalPolicyAssignmentMutation({
    onError: err => {
      let errorMessage = err.message.substring(15);

      if (errorMessage) {
        setOpenSnackBar(true);
        setSnackBarMsg(errorMessage);
      }
    },
    onCompleted: data => {
      setSnackBarMsg(SystemMsgs.updateRecord());
      setOpenSnackBar(true);
      history.push(`/company-settings/${CompanyID}/approval-policy/po`);
    },
  });

  const onSubmit = data => {
    const createUpdate =
      mode1 === 'new'
        ? createApprovalPolicyAssignment
        : updateApprovalPolicyAssignment;

    const approvalList = data?.approvalList;

    createUpdate({
      variables: {
        input: {
          ...(mode1 === 'new'
            ? null
            : {
                ApprovalPolicyAssignmentID:
                  getApprovalList?.ApprovalPolicyAssignmentID,
              }),
          lowerLimit: data?.lowerLimit,
          upperLimit: data?.upperLimit,
          Category: refName,
          CompanyID: CompanyID,
          ApprovalList: approvalList?.map((x, index) => {
            return {
              Category: refName,
              StepNo: index + 1,
              RoleID: x?.roleID,
              RoleName: getRoleWithAccount?.filter(
                role => role?.ID === x?.roleID,
              )[0]?.name,
            };
          }),
        },
      },
      refetchQueries: [
        {
          query: GetApprovalPolicyAssignmentDocument,
          variables: { Category: refName },
        },
      ],
    });
    //
  };

  return (
    <>
      <MainHeader
        mainBtn="close"
        onClick={() => {
          history.push({
            pathname: `/company-settings/${CompanyID}/approval-policy/po`,
          });
        }}
        smTitle={smTitle.COMPANY_SETTINGS}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Approval Policy' },
          { name: 'Approval Policy Listing' },
          { name: 'Approval Policy Flow', current: true },
        ]}
        rightRouteSegments={[{ name: 'Edit', current: true }]}
      />

      <DynamicSubHeader
        title={getCompanyEntityWithAccount[0]?.Name}
        infoLine="Purchase Order"
      />

      <ContentWrapper multiDynamicInfo footer>
        <CardContents
          section={{
            header: {
              title: `PO Amount Approval Policy`,
            },
          }}
          contentStyle={{
            display: 'block',
          }}
        >
          <div>
            <span className="mdDesc">Approval Limit</span>

            <Grid container style={{ padding: '5px' }}>
              <Grid
                item
                xs={5}
                style={{
                  textAlign: 'center',
                }}
              >
                <Controller
                  render={({ onChange, value }) => (
                    <NumberFormat
                      customInput={TextField}
                      allowNegative={false}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      isAllowed={({ floatValue }) => {
                        return (
                          floatValue >=
                          (getApprovalList?.latestLimit?.latestUpperLimit ??
                            getApprovalList?.latestLimitAssignment
                              ?.latestUpperLimit)
                        );
                      }}
                      disabled={getApprovalPolicyAssignment?.length > 0}
                      decimalScale={2}
                      fixedDecimalScale
                      className="price-input-new"
                      label="Lower Limit"
                      thousandSeparator
                      required
                      value={value}
                      helperText={
                        errors?.lowerLimit ? errors?.lowerLimit?.message : null
                      }
                      error={!!errors?.lowerLimit ? true : false}
                      onValueChange={({ floatValue }) => {
                        onChange(floatValue);
                      }}
                    />
                  )}
                  name="lowerLimit"
                  control={control}
                  defaultValue={
                    ApprovalPolicyAssignmentID !== undefined
                      ? getApprovalList?.lowerLimit ?? 0
                      : 0
                  }
                  disabled={getApprovalPolicyAssignment?.length > 0}
                />
              </Grid>
              <Grid
                item
                xs={2}
                className="desc"
                style={{
                  textAlign: 'center',
                  justifyContent: 'center',
                  marginBottom: '10px',
                  marginTop: '10px',
                  fontSize: 'medium',
                }}
              >
                -
              </Grid>
              <Grid item xs={5} style={{ textAlign: 'center' }}>
                <Controller
                  render={({ onChange, value }) => (
                    <NumberFormat
                      customInput={TextField}
                      allowNegative={false}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                      decimalScale={2}
                      fixedDecimalScale
                      className="price-input-new"
                      label="Upper Limit"
                      thousandSeparator
                      required
                      value={value}
                      onValueChange={({ floatValue, value }) => {
                        onChange(floatValue);
                      }}
                      helperText={
                        errors?.upperLimit ? errors?.upperLimit?.message : null
                      }
                      error={!!errors?.upperLimit ? true : false}
                    />
                  )}
                  name="upperLimit"
                  control={control}
                  defaultValue={
                    ApprovalPolicyAssignmentID !== undefined
                      ? getApprovalList?.upperLimit ?? 0
                      : 0
                  }
                />
              </Grid>
            </Grid>
          </div>
        </CardContents>

        <CardContents
          section={{
            header: {
              title: 'Approval List',
              onClickAction: () => {
                append({
                  stepNo: fields?.length + 1,
                  role: '',
                  roleName: '',
                });
              },
              icon: (
                <AddIcon
                  htmlColor="white"
                  fontSize="default"
                  style={{
                    width: '1.2rem',
                    height: '1.2rem',
                    margin: '10px 5px 0px 0px',
                    background: theme.palette.primary.main,
                    boxShadow: `0px 2px 5px 0px ${theme.palette.primary.main}`,
                    borderRadius: '3px',
                    color: 'rgba(224,234,254,100)',
                  }}
                />
              ),
            },
          }}
        >
          {fields.length == undefined ||
          fields.length == 0 ||
          fields.length == 0 ? (
            <>
              <List className="avatar-rounding">
                <ListItem>
                  <IconItem
                    image={`${Empty}`}
                    imageType="svg"
                    badgeType="default"
                  />

                  <ListItemText
                    primary={
                      <>
                        <span className="desc  ">
                          <span className="xsTitle">No Records</span>
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        <span className="desc subheader-set wrap">
                          <span>{`Click add button to create a format.`} </span>
                        </span>
                      </>
                    }
                  />
                </ListItem>
              </List>
            </>
          ) : (
            <>
              <div className="rm-padding">
                <List
                  style={{ width: '100%', paddingBottom: '0px' }}
                  className={classesVaria.list}
                >
                  {fields?.map((x, index) => {
                    const approvalList = `approvalList[${index}]`;
                    return (
                      <>
                        <div key={x?.id}>
                          <ListItem
                            key={`${approvalList}_ListItem`}
                            role={undefined}
                            classes={{
                              root:
                                index !== fields?.length - 1
                                  ? classesVaria.item
                                  : classesVaria.lastItem,
                            }}
                          >
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  textAlign: 'center',
                                  paddingTop: '15px',
                                }}
                              >{`${+index + 1}.`}</Grid>
                              <Grid container item xs={10}>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingRight: '10px' }}
                                >
                                  <Controller
                                    ref={register}
                                    name={`${approvalList}.roleID`}
                                    required
                                    control={control}
                                    defaultValue={x?.RoleID}
                                    style={{
                                      marginTop: '5px',
                                    }}
                                    render={({ onChange, onBlur, value }) => (
                                      <TextField
                                        select
                                        variant="outlined"
                                        className="round-input"
                                        name={`${approvalList}.roleID`}
                                        label={`Level ${index + 1} Approval`}
                                        required
                                        defaultValue={x?.RoleID}
                                        margin="dense"
                                        helperText={
                                          errors?.approvalList?.[index]?.roleID
                                            ?.message
                                        }
                                        error={
                                          errors?.approvalList?.[index]?.roleID
                                            ? true
                                            : false
                                        }
                                        onChange={e => {
                                          onChange(
                                            e.target.value,
                                            register(`${approvalList}.Role`),
                                            setValue(
                                              `${approvalList[index]}.Role`,
                                              e.target.value,
                                            ),
                                          );
                                        }}
                                        value={value}
                                        disabled={
                                          index !== 0 &&
                                          watch(
                                            `approvalList[${index - 1}].Role`,
                                          ) === ''
                                        }
                                      >
                                        {checkRemainingRole(index).map(role => (
                                          <MenuItem
                                            key={role?.ID}
                                            value={role?.ID}
                                          >
                                            {role?.name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                className={classesVaria.deleteIcon}
                                style={{ justifyContent: 'center' }}
                              >
                                <IconButton
                                  className="delete-icon"
                                  onClick={() => {
                                    let temp = fields
                                      .map((v, i) => {
                                        if (i !== 0 && i > index) {
                                          v.StepNo = i;
                                        }

                                        return v;
                                      })
                                      .filter((k, ii) => {
                                        return ii !== index;
                                      });
                                    setValue('approvalList', [...temp]);
                                  }}
                                  style={{ scale: 0.7 }}
                                  question-uid={`${approvalList}.uid`}
                                >
                                  <Delete
                                    htmlColor="white"
                                    fontSize="default"
                                    style={{
                                      width: '1.4rem',
                                      height: '1.4rem',
                                      margin: '0',
                                      background: theme.palette.error.main,
                                      boxShadow: `0px 2px 5px 0px ${theme.palette.error.main}`,
                                      borderRadius: '3px',
                                      color: 'white',
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>

                            <ListItemSecondaryAction></ListItemSecondaryAction>
                          </ListItem>
                        </div>
                      </>
                    );
                  })}
                </List>
              </div>
            </>
          )}
        </CardContents>
      </ContentWrapper>
      <ErrorDialog
        errorDia={errorDialog}
        setErrorDia={setErrorDialog}
        errorMsg={errorMsg}
        errorHeaderMsg={'Missing Main Approval Policy'}
        isApprovalPolicy
      />
      <Footer
        options={[
          {
            disabled: fields?.length === 0,
            name: 'Save',
            onClick: () => {
              handleSubmit(onSubmit)();
            },
            color: 'primary',
          },
        ]}
      />
    </>
  );
};
