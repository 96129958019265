import templateRoutes from 'components/Template/RoutesTemplate';
import authenticationRoutes from 'containers/AccountModule/AccountRoutes';
import generalPurchaseRoutes from 'containers/GeneralPurchaseModule/GeneralPurchaseRoutes';
import generalSettingRoutes from 'containers/GeneralSettingModule/GeneralSettingRoutes';
import homeRoutes from 'containers/HomeModule/HomeRoutes';
import systemAdminRoutes from 'containers/SystemAdminModule/SystemAdminRoutes';
import companyRoutes from 'containers/CompanyModule/CompanyRoutes';
import React from 'react';
import { Redirect } from 'react-router-dom';

const redirectRoute = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/" />,
  },
];

const errorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
];

//error route always at the bottom//
const routes = [
  ...homeRoutes,
  ...templateRoutes,
  ...systemAdminRoutes,
  ...generalSettingRoutes,
  ...companyRoutes,
  ...generalPurchaseRoutes,
  ...authenticationRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
