import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import {
  useGetApprovalPolicyAssignmentQuery,
  useGetCompanyNameQuery,
} from 'generated/graphql';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import Supplier from '../../../assets/icons/HomeApp/supplier-account.svg';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { formatDate } from 'helpers/StringNumberFunction/formatDate';

export const ApprovalPolicySubmenu = (props: any) => {
  const { CompanyID }: any = useParams();
  let history = useHistory();
  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  const fetchDate = category => {
    let temp =
      formatDate(
        getApprovalPolicyAssignment?.find(v => v?.Category === category)?.modTs,
      ) ?? 'N/A';

    return temp;
  };

  const {
    loading: loadingApprovalAssignment,
    error: errorApprovalAssignment,
    data: { getApprovalPolicyAssignment } = { getApprovalPolicyAssignment: [] },
  } = useGetApprovalPolicyAssignmentQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  });

  const {
    loading,
    error,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  });

  const purchaseClass = {
    path: `/company-settings/${CompanyID}/approval-policy/pr`,
    icon: Supplier,
    disabled: false,
    deployed: true,
    title: 'PR',
    label1: `Approval Level`,
    date: fetchDate('GP_PurchaseRequisition'),
    refName: 'GP_PurchaseRequisition',
  };
  const purchaseItem = {
    path: `/company-settings/${CompanyID}/approval-policy/rfq`,
    icon: Supplier,
    disabled: false,
    deployed: true,
    title: 'Request for Quotation (RFQ)',
    label1: `Last Updated Order On `,
    date: fetchDate('GP_RFQ'),
    refName: 'GP_RFQ',
  };
  const approvalPolicy = {
    path: `/company-settings/${CompanyID}/approval-policy/po`,
    icon: Supplier,
    disabled: false,
    deployed: true,
    title: 'PO',
    label1: `Last Updated Order On `,
    date: fetchDate('GP_PurchaseOrder'),
    refName: 'GP_PurchaseOrder',
  };

  const supplierType = {
    path: `/company-settings/${CompanyID}/approval-policy/supplier-type`,
    icon: Supplier,
    disabled: false,
    deployed: true,
    title: 'Supplier Type',
    label1: `Last Updated Order On`,
    date: '23 Nov 2022',
    refName: 'pr',
  };

  const mainMenuList = [purchaseClass, purchaseItem, approvalPolicy];

  return (
    <>
      {loading && <Loading />}
      {loadingApprovalAssignment && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/company-settings/${CompanyID}`)}
        smTitle={smTitle.GENERAL_PURCHASES}
        title={user?.companyName}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Approval Policy', current: true },
        ]}
      />

      <DynamicSubHeader title={getCompany[0]?.Name} />

      <ContentWrapper singleDynamicInfo>
        <List className="core-list">
          {mainMenuList?.map((el, index) => {
            return (
              <ListItem
                key={index}
                onClick={() =>
                  history.push({
                    pathname: el?.path,
                    state: {
                      refName: el?.refName,
                    },
                  })
                }
              >
                <ListItemText
                  primary={
                    <>
                      <span className="xsTitle flex-space">{el.title} </span>
                    </>
                  }
                  secondary={
                    <span className="desc">
                      {`${el.label1} `}
                      <span className="fw-medium">{el.date}</span>
                    </span>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => history.push({ pathname: el?.path })}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </ContentWrapper>
    </>
  );
};
