import { useState, useEffect } from 'react';
import localForage from 'localforage';
/**
 * @param handlePermDisabled : {companyID: '' | companyID , permEnum: accountPermission.HomeContractView}
 * @param handlePermHidden : {companyID: '' | companyID , permEnum: accountPermission.HomeContractView}
 * @param permission : general permission list
 * @param accountPermission : contract permission list
 */
interface PermProps {
  companyID?: string;
  permEnum: string;
}
export const usePermissionChecker = () => {
  const [permission, setPermMatch] = useState({});
  const [accountPermission, setAccountPerm] = useState({});
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  useEffect(() => {
    localForage?.getItem('permission', function(err, value) {
      setPermMatch(value);
    });
  }, [setPermMatch]);

  useEffect(() => {
    localForage?.getItem('accountPermission', function(err, value) {
      setAccountPerm(value);
    });
  }, [setAccountPerm]);

  const handlePermDisabled = ({ companyID, permEnum }: PermProps) => {
    return !!companyID && !!accountPermission
      ? accountPermission[companyID]?.hasOwnProperty(permEnum) === false &&
          !user?.superUser
      : permission?.hasOwnProperty(permEnum) === false && !user?.superUser;
  };

  const handlePermHidden = ({ companyID, permEnum }: PermProps) => {
    if (permEnum === null || permEnum === undefined) {
      return true;
    }
    return companyID
      ? accountPermission[companyID]?.hasOwnProperty(permEnum) ||
          user?.superUser
      : permission?.hasOwnProperty(permEnum) || user?.superUser;
  };
  return {
    permission,
    accountPermission,
    handlePermDisabled,
    handlePermHidden,
  };
};
