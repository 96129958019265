interface PolicyListProps {
  accounts: any;
  getPolicyGuideLine: any;
  getRoleWithAccount: any;
  pageMode: string;
  contractSetting: boolean;
  contractID?: string;
}

export const filterPolicy = (
  data: any,
  type: string,
  roles: any,
  lengthOnly?: boolean,
) => {
  let dataToReturn: string = '';
  let filteredData = data?.filter(v => v?.Category === type)[0];

  let policyRoles = filteredData?.ApprovalList;
  if (lengthOnly) {
    return filteredData?.length ?? 0;
  } else if (policyRoles) {
    for (const v of policyRoles) {
      if (dataToReturn !== '') {
        dataToReturn =
          dataToReturn +
          ' > ' +
          roles?.filter(x => x?.ApprovalPolicyAssignmentID === v?.RoleID)[0]?.RoleName;
      } else {
        dataToReturn =
          dataToReturn + roles?.filter(x => x?.ApprovalPolicyAssignmentID === v?.RoleID)[0]?.RoleName;
      }
    }

    return dataToReturn ?? 'N/A';
  }
};

export const filterRangePolicy = (data: any, roles: any) => {
    let dataToReturn: string = '';
  
    let policyRoles = data;
    if (policyRoles) {
      for (const v of policyRoles) {
        if (dataToReturn !== '') {
          dataToReturn =
            dataToReturn +
            ' > ' +
            roles?.filter(x => x?.ID === v?.RoleID)[0]?.name;
        } else {
          dataToReturn =
            dataToReturn + roles?.filter(x => x?.ID === v?.RoleID)[0]?.name;
        }
      }
      return dataToReturn ?? 'N/A';
    }
  };
