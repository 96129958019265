import React, { lazy } from 'react';

export const SCHEDULE = 'scheduled-purchase';
export const COMPANY_ID = ':CompanyID';
export const GENERAL_PURCHASE = 'general-purchase';
export const GP_SCHEDULE_PATH = `/${COMPANY_ID}/${SCHEDULE}`;

const ScheduledPurchasesListing = lazy(() =>
  import('./ScheduledPurchasesListing').then(module => ({
    default: module.ScheduledPurchasesListing,
  })),
);

const ScheduledPurchasesForm = lazy(() =>
  import('./SchedulePurchasesForm').then(module => ({
    default: module.ScheduledPurchasesForm,
  })),
);

const ScheduledPurchasesItemForm = lazy(() =>
  import('./ScheduledPurchasesItemForm').then(module => ({
    default: module.ScheduledPurchasesItemForm,
  })),
);

const ScheduledPurchasesPreparePOForm = lazy(() =>
  import('./ScheduledPurchasesPreparePOForm').then(module => ({
    default: module.ScheduledPurchasesPreparePOForm,
  })),
);

const scheduledPurchasesRoutes = [
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Listing - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}`,
    },
    component: <ScheduledPurchasesListing />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Prepare PO - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/prepare-po`,
    },
    component: <ScheduledPurchasesPreparePOForm mode="view" />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Add - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:formMode`,
    },
    component: <ScheduledPurchasesForm detailMode={'add'} />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Edit - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/:formMode`,
    },
    component: <ScheduledPurchasesForm detailMode={'edit'} />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Add && SPItem Add - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:formMode/sp-item/:detailMode`,
    },
    component: <ScheduledPurchasesItemForm />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Add && SPItem Edit & Add - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/:formMode/sp-item/:detailMode`,
    },
    component: <ScheduledPurchasesItemForm />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Add && SPItem Edit - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:formMode/sp-item/:ScheduledPurchasesItemID/:detailMode`,
    },
    component: <ScheduledPurchasesItemForm />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Edit && SPItem Edit - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //

  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/:formMode/sp-item/:ScheduledPurchasesItemID/:detailMode`,
    },
    component: <ScheduledPurchasesItemForm />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Edit && SPItem has been edited - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/:formMode`,
    },
    component: <ScheduledPurchasesForm detailMode={'edit'} />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - Schedule Purchase Add && SPItem has been edited - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${GP_SCHEDULE_PATH}/:ScheduledPurchasesID/:formMode`,
    },
    component: <ScheduledPurchasesForm detailMode={'add'} />,
  },
];

export default scheduledPurchasesRoutes;
