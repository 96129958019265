import React, { lazy } from 'react';

const HomeMenu = lazy(() =>
  import('./HomeMenu').then(module => ({
    default: module.HomeMenu,
  })),
);

const PurchaseHomeMenu = lazy(() =>
  import('./PurchaseHomeMenu').then(module => ({
    default: module.PurchaseHomeMenu,
  })),
);

//General Settings//
const GeneralSettingListing = lazy(() =>
  import('../GeneralSettingModule/GeneralSettingListing').then(module => ({
    default: module.GeneralSettingListing,
  })),
);
//Company Settings//
const CompanyListing = lazy(() =>
  import('../CompanyModule/CompanyListing').then(module => ({
    default: module.CompanyListing,
  })),
);

const homeRoutes = [
  {
    props: { exact: true, path: '/' },
    component: <PurchaseHomeMenu />,
  },
  {
    props: { exact: true, path: '/general-setting' },
    component: <GeneralSettingListing />,
  },
  {
    props: { exact: true, path: '/company-settings' },
    component: <CompanyListing createAccess={true} />,
  },
  {
    props: { exact: true, path: '/:CompanyID' },
    component: <PurchaseHomeMenu />,
  },
  {
    props: { exact: true, path: '/home' },
    component: <HomeMenu />,
  },
];

export default homeRoutes;
