import { gql } from 'apollo-boost';

export const GET_COMPANY = gql`
  query getCompanyEntityWithAccount(
    $AccountID: String!
    $userID: String!
    $isSuperUser: Boolean!
    $orderByDesc: String
    $orderByAsc: String
    $take: Float
    $CompanyID: String
  ) {
    getCompanyEntityWithAccount(
      AccountID: $AccountID
      userID: $userID
      isSuperUser: $isSuperUser
      orderByDesc: $orderByDesc
      orderByAsc: $orderByAsc
      take: $take
      CompanyID: $CompanyID
    ) {
      CompanyID
      ParentCompanyID
      Name
      Code
      Address
      ContactNo
      CompanyRegNo
      BaseCurrencyID
      CompanyTax
      SSTNo
      GSTNo
      GSTExpiryDate
    }
  }
`;

export const GET_COMPANY_NAME = gql`
  query getCompanyEntityWithAccount(
    $AccountID: String!
    $userID: String!
    $isSuperUser: Boolean!
    $orderByDesc: String
    $orderByAsc: String
    $take: Float
    $CompanyID: String
  ) {
    getCompanyEntityWithAccount(
      AccountID: $AccountID
      userID: $userID
      isSuperUser: $isSuperUser
      orderByDesc: $orderByDesc
      orderByAsc: $orderByAsc
      take: $take
      CompanyID: $CompanyID
    ) {
      CompanyID
      Name
      Code
      Address
    }
  }
`;

export const GET_COMPANY_ACCOUNT = gql`
  query getCompanyWithAccount(
    $take: Float
    $orderByDesc: String
    $orderByAsc: String
    $customerID: String
    $CompanyID: String
    $isSuperUser: Boolean!
    $userID: String!
    $accountID: String!
  ) {
    getCompanyWithAccount(
      take: $take
      orderByDesc: $orderByDesc
      orderByAsc: $orderByAsc
      customerID: $customerID
      CompanyID: $CompanyID
      isSuperUser: $isSuperUser
      userID: $userID
      accountID: $accountID
    )
  }
`;

export const GET_COMPANY_ACCOUNT_NAME = gql`
  query getCompanyEntityWithAccount(
    $take: Float
    $orderByDesc: String
    $orderByAsc: String
    $customerID: String
    $CompanyID: String
    $isSuperUser: Boolean!
    $userID: String!
    $accountID: String!
  ) {
    getCompanyEntityWithAccount(
      take: $take
      orderByDesc: $orderByDesc
      orderByAsc: $orderByAsc
      customerID: $customerID
      CompanyID: $CompanyID
      isSuperUser: $isSuperUser
      userID: $userID
      accountID: $accountID
    )
  }
`;

export const GET_COST_CENTRE = gql`
  query getCostCentreEntityWithAccount(
    $take: Float
    $orderByDesc: String
    $orderByAsc: String
    $CompanyID: String
    # $customerID: String
    # $CostCentreID: String
    # $isSuperUser: Boolean!
    # $userID: String!
    $AccountID: String!
  ) {
    getCostCentreEntityWithAccount(
      take: $take
      orderByDesc: $orderByDesc
      orderByAsc: $orderByAsc
      CompanyID: $CompanyID

      # customerID: $customerID
      # CostCentreID: $CostCentreID
      # isSuperUser: $isSuperUser
      # userID: $userID
      AccountID: $AccountID
    ) {
      CostCentreID
      Name
      CompanyID
      Code
    }
  }
`;

// export const GET_APPROVAL_POLICY = gql`
//   query getApprovalPolicy(
//     $AccountID: String
//     $Category: String
//   ) {
//     getApprovalPolicy(
//       AccountID: $AccountID
//       Category: $Category
//     ) {
//       AccountID
//       ApprovalPoilicyID
//       Category
//       ApprovalList
//     }
//   }
// `

// export const GET_UOM_ACCOUNT = gql`
//   query getUOMEntityWithAccount(
//     $take: Float
//     $orderByDesc: String
//     $orderByAsc: String
//     # $CompanyID: String
//     # $customerID: String
//     # $CostCentreID: String
//     # $isSuperUser: Boolean!
//     # $userID: String!
//     $AccountID: String!
//   ) {
//     getUOMEntityWithAccount(
//       take: $take
//       orderByDesc: $orderByDesc
//       orderByAsc: $orderByAsc
//       # CompanyID: $CompanyID

//       # customerID: $customerID
//       # CostCentreID: $CostCentreID
//       # isSuperUser: $isSuperUser
//       # userID: $userID
//       AccountID: $AccountID
//     ) {
//       UOMID
//       Name
//       CompanyID
//       Name
//       Code
//     }
//   }
// `;

export const GET_UOM = gql`
  query getUOMWithAccount(
    $accountID: String!
    $orderByAsc: String
    $orderByDesc: String
  ) {
    getUOMWithAccount(
      accountID: $accountID
      orderByAsc: $orderByAsc
      orderByDesc: $orderByDesc
    ) {
      UOMID
      Name
      Code
      RecordStatus
    }
  }
`;

export const GET_TAXSCHEME = gql`
  query getTaxSchemeWithAccount(
    $accountID: String!
    $orderByAsc: String
    $orderByDesc: String
  ) {
    getTaxSchemeWithAccount(
      accountID: $accountID
      orderByAsc: $orderByAsc
      orderByDesc: $orderByDesc
    ) {
      Code
      Description
      AccTaxCategory
      TaxTypeID
      AccTaxClass
      TaxSchemeID
      MasterCOAID
      createdTs
      RecordStatus
      LatestTax {
        Rate
        Date
      }
      TaxEffective {
        Date
        Rate
        createdTs
        createdBy
      }
      TaxType {
        Code
      }
      MasterCOA {
        Name
        Code
      }
    }
  }
`;

export const GET_CREDITOR = gql`
  query getCreditorProfileWithAccount($accountID: String!) {
    getCreditorProfileWithAccount(accountID: $accountID) {
      CreditorAccountID
      CompanyName
      CreditorTypeEnum
      CreditorStatus
    }
  }
`;

export const GET_BANK_PROFILE = gql`
  query getBankProfileWithAccount($accountID: String!) {
    getBankProfileWithAccount(accountID: $accountID) {
      BankProfileID
      Name
    }
  }
`;

export const GET_CREDITOR_TYPE = gql`
  query getCreditorTypeWithAccount(
    $accountID: String!
    # $companyID: String!
    $orderByAsc: String
    $orderByDesc: String
  ) {
    getCreditorTypeWithAccount(
      accountID: $accountID
      # companyID:  $companyID
      orderByAsc: $orderByAsc
      orderByDesc: $orderByDesc
    ) {
      CreditorTypeID
      MasterCOAID
      # Code
      Description
      CreditorType
      Code
      CreditorAccount {
        CreditorAccountID
        CreditorTypeID
        CompanyRegNo
        # CreditorNo
        CompanyName
        ContactNo
      }
      MasterCOA {
        Name
        Code
      }
    }
  }
`;

export const GET_CREDITOR_PROFILE = gql`
  query getCreditorProfileWithAccount(
    $accountID: String!
    $orderByAsc: String
    $orderByDesc: String
  ) {
    getCreditorProfileWithAccount(
      accountID: $accountID
      # companyID:  $companyID
      orderByAsc: $orderByAsc
      orderByDesc: $orderByDesc
    ) {
      CreditorAccountID
      CreditorTypeID
      CompanyRegNo
      ContactNo
      AccountID
      Email
      CreditTerm
      TaxSchemeID
      Region
      GSTRegNo
      Remark
      BankName
      BankAccountNo
      CreditorTypeEnum
      CompanyName
      modTs
      Address
      CreditorStatus
      CreditorContact {
        CreditorContactID
        CreditorAccountID
        Name
        ContactNo
        Designation
        Email
      }
      CreditorDirector {
        CreditorDirectorID
        CreditorAccountID
        Name
        ContactNo
        Email
        IdentityNo
        IdentityType
      }
    }
  }
`;

export const CREATE_CREDITOR = gql`
  mutation CreateCreditorAccountWithAccount(
    $accountID: String!
    $input: CreditorAccountInput!
    $contactItem: [CreditorContactInput!]
    $directorItem: [CreditorDirectorInput!]
  ) {
    CreateCreditorAccount(
      accountID: $accountID
      input: $input
      contactItem: $contactItem
      directorItem: $directorItem
    )
  }
`;

export const GET_MASTER_COA = gql`
  query getMasterCOAWithAccount(
    $take: Float
    $orderByDesc: String
    $orderByAsc: String
    $accountID: String!
  ) {
    getMasterCOAWithAccount(
      take: $take
      orderByDesc: $orderByDesc
      orderByAsc: $orderByAsc
      accountID: $accountID
    ) {
      AccountID
      MasterCOAID
      ParentMasterCOAID
      Name
      Code
      IsControl
      AccountType
      IsLastNode
      Level
      EntityCOA {
        EntityCOAID
        CompanyID
      }
    }
  }
`;

export const GET_ROLE = gql`
  query getRoleWithAccount($accountID: String) {
    getRoleWithAccount(accountID: $accountID) {
      ID
      name
    }
  }
`;

export const GET_CREDITOR_TYPE_DOCUMENT = gql`
  query getCreditorType($CreditorTypeID: String, $CreditorType: CreditorType) {
    getCreditorType(
      CreditorTypeID: $CreditorTypeID
      CreditorType: $CreditorType
    ) {
      CreditorTypeID
      MasterCOAID
      Description
      CreditorType
      Code
      CreditorAccount {
        CreditorAccountID
        CreditorTypeID
        CompanyRegNo
        CompanyName
        ContactNo
      }
      MasterCOA {
        Name
        Code
      }
    }
  }
`;

export const GET_ENTITY_COA = gql`
  query getEntityCOAWithAccount(
    $accountID: String!
    $orderByAsc: String
    $orderByDesc: String
  ) {
    getEntityCOAWithAccount(
      accountID: $accountID
      orderByAsc: $orderByAsc
      orderByDesc: $orderByDesc
    ) {
      EntityCOAID
      CompanyID
      MasterCOAID
      MasterCOA {
        MasterCOAID
        ParentMasterCOAID
        Name
        Code
        IsControl
        AccountType
        IsLastNode
        Level
      }
    }
  }
`;

// export const CREATE_CREDITOR_TYPE_SUPPLIER = gql`
//   mutation CreateCreditorTypeWithAccount(
//     $accountID: String!
//     $userID: String!
//     $input: CreditorTypeInput!
//   ) {
//     CreateCreditorType(accountID: $accountID, userID: $userID, input: $input)
//   }
// `;

export const CREATE_CREDITOR_TYPE_SUPPLIER = gql`
  mutation CreateCreditorTypeWithAccount(
    $accountID: String!
    $userID: String!
    $input: CreditorTypeInput!
  ) {
    CreateCreditorTypeWithAccount(
      accountID: $accountID
      userID: $userID
      input: $input
    )
  }
`;
