import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import Info from 'assets/icons/journal-process.svg';
import {
  useGetCompanyNameQuery,
  useGetDocumentNumberHeaderQuery,
} from 'generated/graphql';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

export const NumberStructureSubmenu = (props: any) => {
  useEffect(() => {
    console.log('NumberingStructureSubmenu');
  }, []);
  let history = useHistory();
  const { CompanyID }: any = useParams();

  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  // ACCOUNTX API CALLS
  const {
    loading,
    error,
    data: { getCompany } = { getCompany: [] },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  });

  const {
    loading: docNumHeaderLoading,
    error: docNumHeaderError,
    data: { getDocumentNumberHeader } = { getDocumentNumberHeader: [] },
  } = useGetDocumentNumberHeaderQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID: CompanyID },
  });

  //submenu numbering structure general purchase
  let submenuList = [
    {
      path: `/company-settings/${CompanyID}/numbering-structure/general-purchases/purchase-requisition`,
      icon: Info,
      title: `Purchase Requisition`,
      label1:
        getDocumentNumberHeader.find(x => x?.RefTable === 'GP_PRHeader')
          ?.SampleOutput || '-',
      data1: ``,
      // formatDate(CashBookSummaryData?.PaymentDate),
      data2: ``,
      data3: ``,
      // permission:
    },
    {
      path: `/company-settings/${CompanyID}/numbering-structure/general-purchases/request-quotation`,
      icon: Info,
      title: `Request for Quotation`,
      label1:
        getDocumentNumberHeader.find(x => x?.RefTable === 'GP_RFQHeader')
          ?.SampleOutput || '-',
      data1: ``,
      data2: ``,
      data3: ``,
      // permission:
    },
    {
      path: `/company-settings/${CompanyID}/numbering-structure/general-purchases/purchase-order`,
      icon: Info,
      title: `Purchase Order`,
      label1:
        getDocumentNumberHeader.find(x => x?.RefTable === 'GP_POHeader')
          ?.SampleOutput || '-',
      data1: ``,
      data2: ``,
      data3: ``,
      // permission:
    },
    {
      path: `/company-settings/${CompanyID}/numbering-structure/general-purchases/delivery-order`,
      icon: Info,
      title: `Goods Receive Note`,
      label1:
        getDocumentNumberHeader.find(x => x?.RefTable === 'GP_DOHeader')
          ?.SampleOutput || '-',
      data1: ``,
      data2: ``,
      data3: ``,
      // permission:
    },
    {
      path: `/company-settings/${CompanyID}/numbering-structure/general-purchases/good-return-note`,
      icon: Info,
      title: `Goods Return Note`,
      label1:
        getDocumentNumberHeader.find(x => x?.RefTable === 'GP_GRTNHeader')
          ?.SampleOutput || '-',
      data1: ``,
      data2: ``,
      data3: ``,
      // permission:
    },
  ];

  return (
    <>
      {loading && <Loading />}
      {docNumHeaderLoading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => history.push(`/company-settings/${CompanyID}`)}
        smTitle={smTitle.COMPANY_SETTINGS}
        title={user?.companyName}
        routeSegments={[
          { name: 'Company Setting' },
          { name: 'Submenu' },
          { name: 'Document Numbering', current: true },
        ]}
        // rightRouteSegments={[{ name: 'Current Path', current: true }]}
      />
      <DynamicSubHeader title={getCompany[0]?.Name} />
      <ContentWrapper singleDynamicInfo>
        <List className="core-list">
          {submenuList.map((el, index) => {
            return (
              <ListItem
                key={index}
                onClick={() =>
                  history.push({
                    pathname: el.path,
                  })
                }
              >
                <IconItem image={el.icon} imageType="svg" badgeType="number" />
                <ListItemText
                  primary={
                    <>
                      <span className="desc">
                        <span className="xsTitle">{el.title}</span>
                      </span>
                    </>
                  }
                  secondary={
                    <span className="desc">
                      {el.label1 && <span className="desc">{el.label1}</span>}
                      <span className="fw-medium">{' ' + el.data1}</span>
                      {el.data1 && el.data2 ? <TextSeparator /> : ''}
                    </span>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete">
                    <KeyboardArrowRight
                      onClick={() =>
                        history.push({
                          pathname: el.path,
                        })
                      }
                    />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </ContentWrapper>
    </>
  );
};
