import * as yup from 'yup';
import { SystemMsgs } from 'helpers/Messages/SystemMsg';
import { LandLineValidatorPattern } from 'helpers/Validation/RegexValidation';

export const CommonYupValidation = {
  email: () => {
    return yup
      .string()
      .email()
      .required(SystemMsgs.email())
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Invalid email format',
      );
  },

  emailNotRequired: () => {
    return yup
      .string()
      .email()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Invalid email format',
      );
  },
  // this is to check only for valid email, the value itself can be empty
  validEmail: () => {
    return yup.lazy(value => (!value ? yup.string() : yup.string().email()));
    // .string()
    // .transform("email", "Invalid email format", value => { return !value || /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)})
    // .matches(
    //   /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    //   'Invalid email format',
    // );
  },
  password: () => {
    return yup
      .string()
      .required('New password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /(?=.*[A-Z])/,
        'Password must contain at least one uppercase character',
      )
      .matches(
        /(?=.*[a-z])/,
        'Password must contain at least one lowercase character',
      )
      .matches(/(?=.*\d)/, 'Password must contain at least one number')
      .matches(
        /(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})/,
        'Password must contain at least one symbol',
      );
  },
  confirmPassword: () => {
    return yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null], 'Confirm password does not match');
  },
  contactNo: () => {
    return yup
      .string()
      .required(SystemMsgs.contactNo())
      .matches(/^[+]6?0\d{8}(?:\d{1})?$/, 'Contact No is not valid');
  },
  mobileNo: () => {
    return yup
      .string()
      .required(SystemMsgs.mobileNo())
      .matches(/^[+]6?01\d{8}(?:\d{1})?$/, 'Mobile No is not valid');
  },
  requireNumberField: (msg?: string) => {
    return yup.number() && yup.string().required(msg);
  },
  requireField: (msg?: string) => {
    return yup.string().required(msg);
  },
};

export const FormSchema = yup.object().shape({
  companyName: yup.string().required(SystemMsgs.companyName()),
  regNo: yup.string().required(SystemMsgs.regNo()),
  address: yup.string().required(SystemMsgs.address()),
  city: yup.string().required(SystemMsgs.city()),
  state: yup.string().required(SystemMsgs.state()),
  country: yup.string().required(SystemMsgs.country()),
  postCode: yup.string().required(SystemMsgs.postCode()),
  contactNo: yup
    .string()
    .required(SystemMsgs.contactNo())
    .matches(LandLineValidatorPattern(), SystemMsgs.contactValidation()),
});

export const ProductSchema = yup.object().shape({
  name: yup.string().required(SystemMsgs.name()),
  code: yup.string().required(),
  supportEmail: yup.string().required(),
  serviceHostUrl: yup.string().required(SystemMsgs.address()),
});

export const AccountSchema = yup.object().shape({
  name: yup.string().required(SystemMsgs.name()),
  code: yup.string().required(),
  email: yup.string().required(SystemMsgs.email()),
  regNo: yup.string().required(SystemMsgs.regNo()),
  address1: yup.string().required(SystemMsgs.address()),
  address2: yup.string().required(SystemMsgs.address()),
  city: yup.string().required(SystemMsgs.city()),
  state: yup.string().required(SystemMsgs.state()),
  country: yup.string().required(SystemMsgs.country()),
  postcode: yup.string().required(SystemMsgs.postCode()),
  currencyID: yup.string().required(SystemMsgs.selectCurrency()),
  contactNo: yup
    .string()
    .required(SystemMsgs.contactNo())
    .matches(LandLineValidatorPattern(), SystemMsgs.contactValidation()),
});

export const SubscribedSchema = yup.object().shape({
  softwareID: yup.string().required(SystemMsgs.name()),
  salesOrderNo: yup.string().required(),
  remark: yup.string().required(SystemMsgs.remark()),
  amount: yup.string().required(),
  noOfExtraEntity: yup.string().required(),
  noOfUser: yup.string().required(),
});

export const DocNumSchema = yup.object().shape({
  prefix: yup.string().required(),
  // figureCount: yup.string().required(),
  // fomratPlaceholder: yup.string().required(),
  preview: yup.string().required(),
});

export const UOMSchema = yup.object().shape({
  name: yup.string().required(),
  code: yup.string().required(),
});

export const Currencychema = yup.object().shape({
  name: yup.string().required('Name is required'),
  code: yup.string().required('Code is required'),
  symbol: yup
    .string()
    .max(5, 'max length is 5')
    .required('Symbol is required'),
});
