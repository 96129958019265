import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';
import { TooltipAmtFooter } from 'components/Footer/TooltipAmtFooter';
import {
  useGetCompanyNameQuery,
  useGetGrtnHeaderQuery,
  useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql';
import { addressFormat } from 'helpers/AddressHandler/AddressFormat';
import { useColorStatusList } from 'helpers/Hooks/useColorStatusList';
import { formatDate } from 'helpers/StringNumberFunction/formatDate';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { GRTNContent } from './Components/GRTNContent';

export const GeneralGRTNDetail = () => {
  let history = useHistory();
  let location = useLocation();
  const { CompanyID, GRTNHeaderID }: any = useParams();

  let locationData = location.state as any;
  // const { globalState, dispatch } = useContext(AppContext as any);
  const { handleStatusColor, handleStatusRename } = useColorStatusList();

  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  const {
    loading: grtnLoading,
    error: grtnError,
    data: { getGRTNHeader } = { getGRTNHeader: [] },
  } = useGetGrtnHeaderQuery({
    fetchPolicy: 'network-only',
    variables: {
      GRTNHeaderID: GRTNHeaderID,
      CompanyID: CompanyID,
      orderByDesc: 'DocDate',
    },
  });

  const acceptedAmtArray =
    getGRTNHeader[0]?.GRTNHeaderItem?.map(x => x?.ReturnedAmt) || [];

  const TotalAmt = acceptedAmtArray.reduce(
    (accumulator, currentValue) => (accumulator || 0) + (currentValue || 0),
    0,
  );

  const [labelState, setLabelState] = useState<string>();

  const {
    loading: getCompanyLoading,
    error: getCompanyError,
    data: { getCompany } = {
      getCompany: [],
    },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  });

  useEffect(() => {
    if (!!getCompany) {
      setLabelState(getCompany[0]?.Name);
    }
  }, [getCompany]);

  const {
    loading: UserLoading,
    data: { getUsersByAccountAndSoftware } = {
      getUsersByAccountAndSoftware: [],
    },
  } = useGetUsersByAccountAndSoftwareQuery({
    fetchPolicy: 'network-only',
  });

  return (
    <>
      {UserLoading && <Loading />}
      {getCompanyLoading && <Loading />}
      {grtnLoading && <Loading />}
      <MainHeader
        onClick={() => history.push(`/${CompanyID}/good-return-note`)}
        mainBtn="back"
        smTitle={smTitle.GENERAL_PURCHASES}
        title={labelState}
        // title={user?.companyName}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Goods Return Note', current: true },
        ]}
        rightRouteSegments={[{ name: `Details`, current: true }]}
      />

      <ContentWrapper float>
        <CardContents
          section={{
            header: {
              title: 'GRTN',
            },
          }}
        >
          <div className="content-wrap">
            <div className="desc">Doc Date</div>
            <div className="xxTitle">
              {formatDate(getGRTNHeader[0]?.DocDate)}
            </div>
          </div>
          <div className="content-wrap">
            <div className="desc">Trx Date</div>
            <div className="xxTitle">
              {formatDate(getGRTNHeader[0]?.TransactionDate)}
            </div>
          </div>
          <div className="content-wrap">
            <div className="desc">Document No.</div>
            <div className="xxTitle">{getGRTNHeader[0]?.DocNo}</div>
          </div>
          <div className="content-wrap">
            <div className="desc">Do No.</div>
            <div className="xxTitle">{getGRTNHeader[0]?.RefNo}</div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Supplier</div>
            <div className="xxTitle">
              {getGRTNHeader[0]?.Supplier?.CompanyName}
            </div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Site Address</div>
            <div className="xxTitle text-noflow">
              {getGRTNHeader[0]?.DeliveryLocation?.Name} -{' '}
              {addressFormat(getGRTNHeader[0]?.DeliveryLocation?.Address)}
            </div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Descriptions</div>
            <div className="xxTitle text-noflow">
              {getGRTNHeader[0]?.Description}
            </div>
          </div>

          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(getGRTNHeader[0]?.submittedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Submitted By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                getUsersByAccountAndSoftware?.filter(
                  user => user.ID == getGRTNHeader[0]?.submittedBy,
                )[0]?.name
              }
            </div>
          </div>
          <div className="content-wrap left">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved Date'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {formatDate(getGRTNHeader[0]?.approvedTs)}
            </div>
          </div>
          <div className="content-wrap right">
            <div className="desc" style={{ color: 'grey' }}>
              {'Approved By'}
            </div>
            <div className="mdDesc text-noflow" style={{ fontSize: '11px' }}>
              {
                getUsersByAccountAndSoftware?.filter(
                  user => user.ID == getGRTNHeader[0]?.approvedBy,
                )[0]?.name
              }
            </div>
          </div>
          <div className="content-wrap full">
            <div className="desc">Attachment(s)</div>
            {/* {attachmentDetailView(DocumentListing)} */}
            <div className="desc">N/A</div>
          </div>
        </CardContents>

        {getGRTNHeader[0]?.GRTNHeaderItem?.length > 0 && (
          <GRTNContent mode="view" dataDetail={getGRTNHeader[0]} />
        )}

        <TooltipAmtFooter
          data={TotalAmt}
          module={`grtn-approved`}
          detail={true}
        />
      </ContentWrapper>
    </>
  );
};
