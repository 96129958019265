import React, { lazy } from 'react';

const SupplierCategoryListing = lazy(() =>
  import('./SupplierCategoryListing').then(module => ({
    default: module.SupplierCategoryListing,
  })),
);

const supplierCategoryRoutes = [
  {
    props: {
      exact: true,
      path: '/general-setting/supplier-category',
    },
    component: <SupplierCategoryListing type="Supplier" />,
  },
];

export default supplierCategoryRoutes;
