import generalPORoutes from './GeneralPOModule/PurchaseOrderRoutes';
import generalRFQRoutes from './GeneralRFQModule/GeneralRFQRoutes';
import generalDORoutes from './GeneralDOModule/GeneralDORoutes';
import generalGRTNRoutes from './GeneralGRTNModule/GeneralGRTNRoutes';
import generalDirectPORoutes from './DirectPOModule/DirectPORoutes';
import scheduledPurchasesRoutes from './ScheduledPurchases/ScheduledPurchasesRoutes';
import generalPRRoutes from './GeneralPRModule/GeneralPRRoutes';
import generalCentralisedPORoutes from './CentralisedPOModule/CentralisedPORoutes';

// Supplier Submenu
// export const GeneralPurchaseSubmenu = lazy(() =>
//   import('./GeneralPurchaseSubmenu').then(module => ({
//     default: module.GeneralPurchaseSubmenu,
//   })),
// );

const generalPurchaseRoutes = [
  ...generalPORoutes,
  ...generalDirectPORoutes,
  ...generalPRRoutes,
  ...generalDORoutes,
  ...generalRFQRoutes,
  ...generalGRTNRoutes,
  ...scheduledPurchasesRoutes,
  ...generalCentralisedPORoutes,
  // {
  //   props: { exact: true, path: '/general-purchase/:CompanyID' },
  //   component: <GeneralPurchaseSubmenu />,
  // },
  // {
  //   props: { exact: true, path: '/general-purchase/:companyID' },
  //   component: <GeneralPurchaseSubmenu />,
  // },
];

export default generalPurchaseRoutes;
