import { yupResolver } from '@hookform/resolvers';
import Empty from '@ifca-root/react-component/src/assets/hrIcons/no-records.svg';
import theme from '@ifca-root/react-component/src/assets/theme';
import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import { MainHeader } from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  MenuItem,
  TextField,
  useMediaQuery,
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { AccountClient } from 'IFCAXClient';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { SystemMsgs } from 'helpers/Messages/SystemMsg';
import { CommonYupValidation } from 'helpers/YupSchema/yup';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router';
import * as yup from 'yup';
import {
  GetApprovalPolicyAssignmentDocument,
  useGetApprovalPolicyAssignmentLazyQuery,
  useGetRoleWithAccountQuery,
  useCreateApprovalPolicyAssignmentMutation,
  useUpdateApprovalPolicyAssignmentMutation,
  useGetCompanyNameQuery,
} from 'generated/graphql';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';
export interface ApprovalPolicyProps {
  approvalList: any[];
  lowerLimit?: number;
  upperLimit?: number;
}
const styles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    padding: '0',
    paddingRight: '10px',
    borderBottom: '2px solid rgb(224,224,224)',
  },
  lastItem: {
    padding: '0',
    paddingRight: '10px',
  },
  deleteIcon: {
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  list: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '80%',
      margin: 'Auto',
    },
  },
}));

export const RFQForm = (props: any) => {
  let history = useHistory();
  let location: any = useLocation();
  const user = JSON.parse(localStorage.getItem('loggedInUser'));
  const { CompanyID, mode }: any = useParams();
  const classesVaria = styles();
  let refName = 'GP_RFQ';
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any,
  );
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [getApprovalList, setApprovalList] = useState(null);
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  //QUERY

  const {
    loading: getCompanyLoading,
    error: getCompanyError,
    data: { getCompany } = {
      getCompany: [],
    },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  });

  const [
    loadApprovalPolicyAssignment,
    {
      loading: loadingApprovalAssignment,
      error: errorApprovalAssignment,
      data: { getApprovalPolicyAssignment } = {
        getApprovalPolicyAssignment: [],
      },
    },
  ] = useGetApprovalPolicyAssignmentLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      Category: refName,
    },
    onCompleted: ({ getApprovalPolicyAssignment }: any) => {
      setApprovalList(getApprovalPolicyAssignment[0]);
    },
  });

  const formValidation = yup.object().shape({
    approvalList: yup.array().of(
      yup.object().shape({
        roleID:
          CommonYupValidation?.requireField(`Role is required`).nullable(),
      }),
    ),
  });

  const { register, handleSubmit, errors, control, setValue, watch } =
    useForm<ApprovalPolicyProps>({
      shouldUnregister: false, // unmount input state will be remained
      resolver: yupResolver(formValidation),
      // defaultValues: {
      //   lowerLimit: getApprovalPolicyAssignment?.lowerLimit,
      //   upperLimit: getApprovalPolicyAssignment?.upperLimit,
      // },
    });

  const { fields, append, remove, prepend } = useFieldArray({
    control,
    name: 'approvalList',
  });

  const { data: { getRoleWithAccount } = { getRoleWithAccount: [] } } =
    useGetRoleWithAccountQuery({
      fetchPolicy: 'network-only',
      variables: {
        DifferentConnection: 'default',
      },
    });

  //////////////////
  //// MUTATION ////
  //////////////////

  const [
    createApprovalPolicyAssignment,
    { loading: loadingCreateApprovalPolicyAssignment },
  ] = useCreateApprovalPolicyAssignmentMutation({
    onError: err => {
      let errorMessage = err.message.substring(15);

      if (errorMessage) {
        setOpenSnackBar(true);
        setSnackBarMsg(errorMessage);
      }
    },
    onCompleted: data => {
      setSnackBarMsg(SystemMsgs.createNewRecord());
      setOpenSnackBar(true);
      history.push(`/company-settings/${CompanyID}/approval-policy/rfq`);
    },
  });

  const [
    updateApprovalPolicyAssignment,
    { loading: loadingUpdateApprovalAssignment },
  ] = useUpdateApprovalPolicyAssignmentMutation({
    onError: err => {
      let errorMessage = err.message.substring(15);

      if (errorMessage) {
        setOpenSnackBar(true);
        setSnackBarMsg(errorMessage);
      }
    },
    onCompleted: data => {
      setSnackBarMsg(SystemMsgs.updateRecord());
      setOpenSnackBar(true);
      history.push(`/company-settings/${CompanyID}/approval-policy/rfq`);
    },
  });

  //// LOAD QUERY ////
  useEffect(() => {
    loadApprovalPolicyAssignment({
      variables: {
        Category: refName,
        CompanyID: CompanyID,
      },
    });
  }, []);

  const mode1 = !!!getApprovalList ? 'new' : 'edit';

  //// IF APPROVAL FLOW EXIST ADD TO LIST ////
  useEffect(() => {
    if (!!getApprovalList) {
      getApprovalList?.ApprovalList?.map((v, index) => {
        append({
          stepNo: v?.StepNo,
          RoleID: v?.RoleID,
          Category: v?.Category,
        });
        setValue(`approvalList[${index}]roleID`, v?.RoleID);
      });
    }
  }, [getApprovalList]);

  /////////////////////
  //// SUBMIT FORM ////
  ////////////////////

  const onSubmit = data => {
    const createUpdate =
      mode1 === 'new'
        ? createApprovalPolicyAssignment
        : updateApprovalPolicyAssignment;

    const approvalList = data?.approvalList;

    createUpdate({
      variables: {
        input: {
          ...(mode1 === 'new'
            ? null
            : {
                ApprovalPolicyAssignmentID:
                  getApprovalList?.ApprovalPolicyAssignmentID,
              }),
          Category: refName,
          CompanyID: CompanyID,
          ApprovalList: approvalList?.map((x, index) => {
            return {
              Category: refName,
              StepNo: index + 1,
              RoleID: x?.roleID,
              RoleName: getRoleWithAccount?.filter(
                role => role?.ID === x?.roleID,
              )[0]?.name,
            };
          }),
        },
      },
      refetchQueries: [
        {
          query: GetApprovalPolicyAssignmentDocument,
          variables: { Category: refName },
        },
      ],
    });
  };

  const checkRemainingRole = index => {
    let selectedRole = [];
    if (watch(`approvalList[${0}].roleID`) !== undefined) {
      for (var i = index - 1; i >= 0; i--) {
        selectedRole.push(watch(`approvalList[${i}].roleID`));
      }
    }
    return getRoleWithAccount?.filter(x => !selectedRole.includes(x?.ID));
  };

  return (
    <>
      {loadingApprovalAssignment && <Loading />}
      {getCompanyLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() =>
          history.push(`/company-settings/${CompanyID}/approval-policy`)
        }
        smTitle={smTitle.GENERAL_PURCHASES}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Approval Policy' },
          { name: 'Approval Policy Flow', current: true },
        ]}
        rightRouteSegments={[{ name: 'Edit', current: true }]}
      />

      <DynamicSubHeader title={getCompany[0]?.Name} infoLine="RFQ" />

      <ContentWrapper dynamicInfoSearch footer>
        <CardContents
          style={{ marginTop: isDesktop ? '40px' : '128px' }}
          section={{
            header: {
              title: 'Approval List',
              onClickAction: () => {
                append({
                  stepNo: fields?.length + 1,
                  role: '',
                  roleName: '',
                });
              },
              icon: (
                <AddIcon
                  htmlColor="white"
                  fontSize="default"
                  style={{
                    width: '1.2rem',
                    height: '1.2rem',
                    margin: '10px 5px 0px 0px',
                    background: theme.palette.primary.main,
                    boxShadow: `0px 2px 5px 0px ${theme.palette.primary.main}`,
                    borderRadius: '3px',
                    color: 'rgba(224,234,254,100)',
                  }}
                />
              ),
            },
          }}
        >
          {fields.length == undefined ||
          fields.length == 0 ||
          fields.length == 0 ? (
            <>
              <List className="avatar-rounding">
                <ListItem>
                  <IconItem
                    image={`${Empty}`}
                    imageType="svg"
                    badgeType="default"
                  />

                  <ListItemText
                    primary={
                      <>
                        <span className="desc  ">
                          <span className="xsTitle">No Records</span>
                        </span>
                      </>
                    }
                    secondary={
                      <>
                        <span className="desc subheader-set wrap">
                          <span>{`Click add button to create a format.`} </span>
                        </span>
                      </>
                    }
                  />
                </ListItem>
              </List>
            </>
          ) : (
            <>
              <div className="rm-padding">
                <List
                  style={{ width: '100%', paddingBottom: '0px' }}
                  className={classesVaria.list}
                >
                  {fields?.map((x, index) => {
                    const approvalList = `approvalList[${index}]`;
                    return (
                      <>
                        <div key={x?.id}>
                          <ListItem
                            key={`${approvalList}_ListItem`}
                            role={undefined}
                            classes={{
                              root:
                                index !== fields?.length - 1
                                  ? classesVaria.item
                                  : classesVaria.lastItem,
                            }}
                          >
                            <Grid container>
                              <Grid
                                item
                                xs={1}
                                style={{
                                  textAlign: 'center',
                                  paddingTop: '15px',
                                }}
                              >{`${+index + 1}.`}</Grid>
                              <Grid container item xs={10}>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingRight: '10px' }}
                                >
                                  <Controller
                                    ref={register}
                                    name={`${approvalList}.roleID`}
                                    required
                                    control={control}
                                    defaultValue={x?.RoleID}
                                    style={{
                                      marginTop: '5px',
                                    }}
                                    // value={getValues(`${approvalList}.roleID`)}
                                    render={({ onChange, onBlur, value }) => (
                                      <TextField
                                        select
                                        variant="outlined"
                                        className="round-input"
                                        name={`${approvalList}.roleID`}
                                        label={`Level ${index + 1} Approval`}
                                        required
                                        defaultValue={x?.RoleID}
                                        margin="dense"
                                        helperText={
                                          errors?.approvalList?.[index]?.roleID
                                            ?.message
                                        }
                                        error={
                                          errors?.approvalList?.[index]?.roleID
                                            ? true
                                            : false
                                        }
                                        onChange={e => {
                                          onChange(
                                            e.target.value,
                                            register(`${approvalList}.Role`),
                                            setValue(
                                              `${approvalList[index]}.Role`,
                                              e.target.value,
                                            ),
                                          );
                                        }}
                                        value={value}
                                        disabled={
                                          index !== 0 &&
                                          watch(
                                            `approvalList[${index - 1}].Role`,
                                          ) === ''
                                        }
                                      >
                                        {checkRemainingRole(index).map(role => (
                                          <MenuItem
                                            key={role?.ID}
                                            value={role?.ID}
                                          >
                                            {role?.name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                    )}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                className={classesVaria.deleteIcon}
                                style={{ justifyContent: 'center' }}
                              >
                                <IconButton
                                  className="delete-icon"
                                  onClick={() => {
                                    let temp = fields
                                      .map((v, i) => {
                                        if (i !== 0 && i > index) {
                                          v.StepNo = i;
                                        }

                                        return v;
                                      })
                                      .filter((k, ii) => {
                                        return ii !== index;
                                      });

                                    // remove(index);
                                    setValue('approvalList', [...temp]);
                                  }}
                                  style={{ scale: 0.7 }}
                                  question-uid={`${approvalList}.uid`}
                                >
                                  <Delete
                                    htmlColor="white"
                                    fontSize="default"
                                    style={{
                                      width: '1.4rem',
                                      height: '1.4rem',
                                      margin: '0',
                                      background: theme.palette.error.main,
                                      boxShadow: `0px 2px 5px 0px ${theme.palette.error.main}`,
                                      borderRadius: '3px',
                                      color: 'white',
                                    }}
                                  />
                                </IconButton>
                              </Grid>
                            </Grid>

                            <ListItemSecondaryAction></ListItemSecondaryAction>
                          </ListItem>
                        </div>
                      </>
                    );
                  })}
                </List>
              </div>
            </>
          )}
        </CardContents>
      </ContentWrapper>
      {/* <ErrorDialog
        errorDia={errorDialog}
        setErrorDia={setErrorDialog}
        errorMsg={errorMsg}
        errorHeaderMsg={'Missing Main Approval Policy'}
        isApprovalPolicy
        onclick={() => {
          if (!contractSetting) {
            history.push(
              `/${pageMode}/general-setting/approval-policy/${policyType}`,
            );
          } else {
            history.push(
              `/${pageMode}/contract-settings/${contractID}/approval-policy/${policyType}`,
            );
          }
        }}
      /> */}
      <Footer
        options={[
          {
            disabled: fields?.length === 0,
            name: 'Save',
            onClick: () => {
              handleSubmit(onSubmit)();
            },
            color: 'primary',
          },
        ]}
      />
    </>
  );
};
