import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { AccountClient } from 'IFCAXClient';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { GET_COMPANY_NAME } from 'containers/ExternalAPIModule/AccountXQuery';
import {
  GetApprovalPolicyAssignmentDocument,
  RecordStatus,
  useDeleteApprovalPolicyAssignmentMutation,
  useGetApprovalPolicyAssignmentLazyQuery,
  useGetCompanyNameQuery,
  useGetRoleWithAccountQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/Hooks/useMenuOption';
import { SystemMsgs } from 'helpers/Messages/SystemMsg';
import { amtStr } from 'helpers/StringNumberFunction/numFormatter';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { filterRangePolicy } from './FilterRangePolicy';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';

export const POListing = (props: any) => {
  const { CompanyID, mode }: any = useParams();
  let user = JSON.parse(localStorage.getItem('loggedInUser'));
  let location: any = useLocation();
  let history = useHistory();
  let refName = 'GP_PurchaseOrder';
  const [approvalListing, setApprovalListing] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any,
  );
  const { anchorEl, handleClick, handleClose, menu } = useMenuOption();

  //QUERY
  const {
    loading,
    error,
    data: { getRoleWithAccount } = { getRoleWithAccount: [] },
  } = useGetRoleWithAccountQuery({
    fetchPolicy: 'network-only',
    variables: {
      DifferentConnection: 'default',
    },
  });

  const [
    loadApprovalPolicyAssignment,
    {
      loading: approvalAssignmentLoading,
      error: approvalAssignmentError,
      data: { getApprovalPolicyAssignment } = {
        getApprovalPolicyAssignment: [],
      },
    },
  ] = useGetApprovalPolicyAssignmentLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      Category: refName,
      CompanyID: CompanyID,
    },
    onCompleted: ({ getApprovalPolicyAssignment }: any) => {
      setApprovalListing(getApprovalPolicyAssignment[0]);
    },
  });

  const {
    called,
    loading: CompanyLoading,
    error: CompanyError,
    data: { getCompany } = {
      getCompany: [],
    },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  });

  const [deleteApprovalPolicyAssignment, { loading: deleteLoading }] =
    useDeleteApprovalPolicyAssignmentMutation({
      onError: err => {
        let errorMessage = err.message.substring(15);
        if (errorMessage) {
          setOpenSnackBar(true);
          setSnackBarMsg(errorMessage);
        }
      },
      onCompleted: data => {
        setSnackBarMsg(SystemMsgs.deleteRecord());
        setOpenSnackBar(true);
        setRefresh(true);
      },
    });

  useEffect(() => {
    loadApprovalPolicyAssignment({
      variables: {
        Category: refName,
        CompanyID: CompanyID,
      },
    });
  }, []);

  return (
    <>
      {loading && <Loading />}
      {deleteLoading && <Loading />}
      {CompanyLoading && <Loading />}
      {approvalAssignmentLoading && <Loading />}
      <MainHeader
        mainBtn="close"
        onClick={() => {
          history.push({
            pathname: `/company-settings/${CompanyID}/approval-policy/`,
          });
        }}
        smTitle={smTitle.COMPANY_SETTINGS}
        title={user?.companyName}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Submenu' },
          { name: 'Approval Policy' },
          { name: 'Approval Policy Listing', current: true },
        ]}
        rightRouteSegments={[{ name: 'Edit', current: true }]}
      />
      <DynamicSubHeader title={getCompany[0]?.Name} infoLine="Purchase Order" />

      <ContentWrapper multiDynamicInfo float>
        {getApprovalPolicyAssignment?.length === 0 ? (
          <EmptyList
            title="No Approval Policy"
            subtitle="Please create a new approval policy"
          />
        ) : (
          <List className="core-list">
            {getApprovalPolicyAssignment
              ?.sort((a, b) => b?.lowerLimit - a?.lowerLimit)
              ?.map((v, i) => (
                <ListItem>
                  <ListItemText
                    primary={
                      <div className="xsTitle">
                        <span className="">Approval Limit : </span>
                        &nbsp;
                        <span className="highlight-text">
                          {amtStr(v?.lowerLimit)} - {amtStr(v?.upperLimit)}
                        </span>
                      </div>
                    }
                    secondary={
                      <>
                        <span className="desc">
                          Approval Level :{' '}
                          <span className="highlight-text">
                            {filterRangePolicy(
                              v?.ApprovalList,
                              getRoleWithAccount,
                            )}
                          </span>
                        </span>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={e => {
                        handleClick(e, v?.ApprovalPolicyAssignmentID, i, v);
                      }}
                    >
                      <MoreVert />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        )}
      </ContentWrapper>

      <Menu
        id="menu-list"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
      >
        <MenuItem
          onClick={() => {
            history.push({
              pathname: `/company-settings/${CompanyID}/approval-policy/po/edit/${menu?.obj?.ApprovalPolicyAssignmentID}`,
              state: {
                refName: refName,
                subheader: location?.state?.subheader,
                getRole: getRoleWithAccount,
                approvalList:
                  approvalListing[menu?.obj?.ApprovalPolicyAssignmentID],
              },
            });
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            deleteApprovalPolicyAssignment({
              variables: {
                input: {
                  ApprovalPolicyAssignmentID: menu?.ID,
                },
              },
              refetchQueries: [
                {
                  query: GetApprovalPolicyAssignmentDocument,
                  variables: {
                    Category: refName,
                    CompanyID: CompanyID,
                  },
                },
              ],
            });
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      <FloatButton
        onClick={() =>
          history.push({
            pathname: `/company-settings/${CompanyID}/approval-policy/po/add`,
            state: {
              // refName: refName,
              // subheader: location?.state?.subheader,
              // approvalList: approvalListing[menu?.index],
            },
          })
        }
      />
    </>
  );
};
