import React, { lazy } from 'react';
import { PDFPreview } from '../../../components/PDFTemplate/PDFPreview';

export const DIRECT_PO = 'direct-po';
export const PURCHASE_ORDER = 'purchase-order';
export const COMPANY_ID = ':CompanyID';
export const DETAIL = 'detail';
export const GENERAL_PURCHASE = 'general-purchase';
export const DIRECT_PO_PATH = `/${COMPANY_ID}/${DIRECT_PO}`;
export const PURCHASE_ORDER_PATH = `/${COMPANY_ID}/${PURCHASE_ORDER}`;

const PurchaseOrderListing = lazy(() =>
  import('../GeneralPOModule/PurchaseOrderListing').then(module => ({
    default: module.PurchaseOrderListing,
  })),
);

const DirectPoForm = lazy(() =>
  import('./DirectPOForm').then(module => ({
    default: module.DirectPoForm,
  })),
);

const POForm = lazy(() =>
  import('./POForm').then(module => ({
    default: module.PurchaseOrderForm,
  })),
);

const POItemForm = lazy(() =>
  import('./POItemForm').then(module => ({
    default: module.PurchaseOrderItemForm,
  })),
);

const PurchaseOrderDetail = lazy(() =>
  import('../GeneralPOModule/PurchaseOrderDetail').then(module => ({
    default: module.PurchaseOrderDetail,
  })),
);

const generalDirectPORoutes = [
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}`,
    },
    component: <PurchaseOrderListing type="direct-po" />,
  },
  {
    props: {
      exact: true,
      path: `/:CompanyID/direct-po/preview-po`,
    },
    component: (
      <PDFPreview accountType="/" docType="preview-po" backPath="direct-po" />
    ),
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - POHeader Add - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:formMode`,
    },
    component: <POForm detailMode={'add'} type="direct-po" />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - POHeader Edit - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:dpoID/:formMode`,
    },
    component: <POForm detailMode={'edit'} type="direct-po" />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - POHeader Add && POItem Add - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:formMode/po-item/:detailMode`,
    },
    component: <POItemForm type="direct-po" />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - POHeader Add && POItem Edit & Add - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:dpoID/:formMode/po-item/:detailMode`,
    },
    component: <POItemForm type="direct-po" />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - POHeader Add && POItem Edit - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:formMode/po-item/:dpoItemID/:detailMode`,
    },
    component: <POItemForm type="direct-po" />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - POHeader Edit && POItem Edit - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //

  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:dpoID/:formMode/po-item/:dpoItemID/:detailMode`,
    },
    component: <POItemForm type="direct-po" />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - POHeader Edit && POItem has been edited - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:dpoID/:formMode/`,
    },
    component: <POForm detailMode={'edit'} />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - POHeader Add && POItem has been edited - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/:dpoID/:formMode/`,
    },
    component: <POForm detailMode={'add'} />,
  },
  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ - PO Detail - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ //
  {
    props: {
      exact: true,
      path: `${DIRECT_PO_PATH}/detail/:dpoID/view`,
    },
    component: <PurchaseOrderDetail type="direct-po" />,
  },
];
export default generalDirectPORoutes;
