import theme from '@ifca-root/react-component/src/assets/theme';
import { useMediaQuery } from '@material-ui/core';
import { amtStr } from '@ifca-root/react-component/src/helpers/StringNumberFunction/numFormatter';
import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import AppContext from 'containers/App/Store/AppContext';

export const Table2PR = (props: any) => {
  const { data } = props;

  const { globalState, dispatch }: any = useContext(AppContext as any);
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

  const thleft = {
    borderBottom: '0.5px  solid',
    textAlign: 'left' as 'left',
    className: 'click-text',
  };

  const thright = {
    width: '15%',
    textAlign: 'center' as 'center',
  };

  const thcenter = {
    width: '15%',
    borderBottom: '0.5px solid',
    textAlign: 'center' as 'center',
  };

  const tdright = {
    width: '15%',
    borderBottom: '0.5px  solid',
    borderTop: '0.5px  solid',
    textAlign: 'center' as 'center',
    fontWeight: 'bold' as 'bold',
  };

  return (
    <>
      <div>
        <table
          style={{
            width: '100%',
            fontSize: isDesktop ? '11px' : '7px',
            borderCollapse: 'collapse',
          }}
        >
          <Grid container item xs={12}>
            <React.Fragment>
              <Grid
                item
                xs={2}
                lg={1}
                style={{
                  paddingLeft: isDesktop ? '20px' : '22px',
                  fontWeight: 'bold',
                }}
              >
                <span>No.</span>
              </Grid>
              <Grid
                item
                xs={4}
                lg={5}
                style={{
                  fontWeight: 'bold',
                }}
              >
                <span>Description</span>
              </Grid>
              <Grid
                item
                xs={3}
                style={{ textAlign: 'right', fontWeight: 'bold' }}
              >
                <span>UOM</span>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  textAlign: 'right',
                  fontWeight: 'bold',
                  paddingRight: isDesktop ? '20px' : '23px',
                }}
              >
                <span>Qty</span>
              </Grid>
            </React.Fragment>
          </Grid>
          <Grid
            container
            style={{
              border: '0.5px solid black',
              width: isDesktop ? '97%' : '85%',
              marginLeft: isDesktop ? '20px' : '23px',
              paddingRight: isDesktop ? '20px' : '23px',
            }}
          ></Grid>
          {data?.PRItem?.map((v, i) => (
            <>
              <Grid
                container
                style={{
                  marginBottom: '10px',
                  marginRight:
                    isDesktop && globalState.drawerOpen
                      ? 'calc(100% - 48px)'
                      : isDesktop && !globalState.drawerOpen
                      ? 'calc(100% - 46px)'
                      : '100%',
                }}
              >
                <React.Fragment>
                  <Grid
                    item
                    xs={2}
                    lg={1}
                    style={{
                      marginTop: '10px',
                      paddingLeft: isDesktop ? '20px' : '22px',
                    }}
                  >
                    {i + 1}
                    {`.  `}
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    lg={5}
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <span>
                      {/* {v?.Item?.Description}
                      <br /> */}

                      {v?.Item?.Name}
                      <br />
                      {v?.Remark}
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{ textAlign: 'right', marginTop: '10px' }}
                  >
                    <span>{v?.UOM?.Name}</span>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    style={{
                      textAlign: 'right',
                      marginTop: '10px',
                      paddingRight: isDesktop ? '20px' : '23px',
                    }}
                  >
                    <span>{v?.Quantity}</span>
                  </Grid>
                </React.Fragment>
              </Grid>
            </>
          ))}
        </table>
      </div>
    </>
  );
};

export default Table2PR;
