import { getAccessToken } from 'AccessToken';
import React from 'react';
import { Redirect, Route, useHistory, useParams } from 'react-router-dom';

export const PrivateRoute = ({ children, ...rest }) => {
  let history = useHistory();
  const AccessToken = getAccessToken();
  const { token }: any = useParams();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        AccessToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
