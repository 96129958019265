import React from 'react';
import { GeneralGRTNDetail } from './GeneralGRTNDetail';
import { GeneralGRTNForm } from './GeneralGRTNForm';
import { GeneralGRTNListing } from './GeneralGRTNListing';
import { GeneralGRTNMain } from './GeneralGRTNMain';

const generalGRTNRoutes = [
  {
    props: {
      exact: true,
      // path: '/general-purchase/:CompanyID/good-return-note',
      path: !'/general-purchase/:CompanyID/good-return-note'
        ? '/general-purchase/:CompanyID/good-return-note'
        : '/:CompanyID/good-return-note',
    },
    component: <GeneralGRTNMain />,
  },
  {
    props: {
      exact: true,
      // path: '/general-purchase/:CompanyID/good-return-note/add',
      path: '/:CompanyID/good-return-note/add',
    },
    component: <GeneralGRTNForm mode="add" />,
  },
  {
    props: {
      exact: true,
      // path: '/general-purchase/:CompanyID/good-return-note/add',
      path: '/:CompanyID/good-return-note/edit/:GRTNHeaderID',
    },
    component: <GeneralGRTNForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/good-return-note/:GRTNHeaderID/detail',
    },
    component: <GeneralGRTNDetail />,
  },
];

export default generalGRTNRoutes;
