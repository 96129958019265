import React, { lazy } from 'react';

const DeliveryLocationListing = lazy(() =>
  import('./DeliveryLocationListing').then(module => ({
    default: module.DeliveryLocationListing,
  })),
);

const deliveryLocationRoutes = [
  {
    props: {
      exact: true,
      path: '/company-settings/:CompanyID/delivery-location',
    },
    component: <DeliveryLocationListing />,
  },
];

export default deliveryLocationRoutes;
