import React, { lazy } from 'react';

const GeneralApprovalPolicySubmenu = lazy(() =>
  import('./GeneralApprovalPolicySubmenu').then(module => ({
    default: module.GeneralApprovalPolicySubmenu,
  })),
);

const PRForm = lazy(() =>
  import('./ApprovalPolicy/PRForm').then(module => ({
    default: module.PRForm,
  })),
);

const RFQForm = lazy(() =>
  import('./ApprovalPolicy/RFQForm').then(module => ({
    default: module.PRForm,
  })),
);

const POListing = lazy(() =>
  import('./ApprovalPolicy/POListing').then(module => ({
    default: module.POListing,
  })),
);

const POForm = lazy(() =>
  import('./ApprovalPolicy/POForm').then(module => ({
    default: module.POForm,
  })),
);

const generalApprovalRoutes = [
  {
    props: { exact: true, path: '/general-setting/approval-policy' },
    component: <GeneralApprovalPolicySubmenu />,
  },
  {
    props: { exact: true, path: '/general-setting/approval-policy/pr' },
    component: <PRForm />,
  },
  {
    props: { exact: true, path: `/general-setting/approval-policy/rfq` },
    component: <RFQForm />,
  },
  {
    props: { exact: true, path: `/general-setting/approval-policy/po` },
    component: <POListing />,
  },
  {
    props: {
      exact: true,
      path: `/general-setting/approval-policy/po/add`,
    },
    component: <POForm mode="new" />,
  },
  {
    props: {
      exact: true,
      path: `/:mode/general-setting/approval-policy/:ApprovalPolicyID/po-form/edit`,
    },
    component: <POForm mode="edit" />,
  },
];

export default generalApprovalRoutes;
