import React, { lazy } from 'react';
import bulkPurchasePriceRoutes from './BulkPurchasePrice/BulkPurchasePriceRoutes';
import generalApprovalRoutes from './GeneralApprovalPolicy/GeneralApprovalPolicyRoutes';
import itemRoutes from './Item/ItemRoutes';
import itemCategoryRoutes from './ItemCategory/ItemCategoryRoutes';
import supplierProfileRoutes from './SupplierProfile/SupplierProfileRoutes';
import supplierCategoryRoutes from './SupplierCategory/SupplierCategoryRoutes';
import uomRateRoutes from './UOMExchange/ConversionRateRoutes';
import uomRoutes from './UOMSetup/UOMRoutes';
import purchaseVarianceRoutes from './PurchaseItemVariancePolicy/PurchaseItemVariancePolicyRoutes';

//General Settings//
const GeneralSettingListing = lazy(() =>
  import('./GeneralSettingListing').then(module => ({
    default: module.GeneralSettingListing,
  })),
);

const generalSettingRoutes = [
  {
    props: { exact: true, path: '/general-setting/' },
    component: <GeneralSettingListing />,
  },
  ...bulkPurchasePriceRoutes,
  ...itemRoutes,
  ...supplierCategoryRoutes,
  ...generalApprovalRoutes,
  ...uomRoutes,
  ...uomRateRoutes,
  ...itemCategoryRoutes,
  ...supplierProfileRoutes,
  ...purchaseVarianceRoutes,
];

export default generalSettingRoutes;
