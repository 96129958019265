import React, { lazy } from 'react';
// import { PDFPreview } from './PDFPreview/PDFPreview';

const GeneralPRMain = lazy(() =>
  import('./GeneralPRMain').then(module => ({
    default: module.GeneralPRMain,
  })),
);

const PDFPreview = lazy(() =>
  import('../../../components/PDFTemplate/PDFPreview').then(module => ({
    default: module.PDFPreview,
  })),
);

const GeneralPRListing = lazy(() =>
  import('./GeneralPRListing').then(module => ({
    default: module.GeneralPRListing,
  })),
);

const GeneralPRForm = lazy(() =>
  import('./GeneralPRFormv2').then(module => ({
    default: module.PurchaseRequisitionForm,
  })),
);

const GeneralPRItemForm = lazy(() =>
  import('./GeneralPRItemForm').then(module => ({
    default: module.PurchaseRequisitionItemForm,
  })),
);

const GeneralPRDetail = lazy(() =>
  import('./GeneralPRDetail').then(module => ({
    default: module.GeneralPRDetail,
  })),
);

const generalPRRoutes = [
  {
    props: {
      exact: true,
      path: '/:CompanyID/purchase-requisition',
    },
    component: <GeneralPRMain />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/purchase-requisition/preview-pr',
    },
    component: (
      <PDFPreview
        accountType="/"
        docType="preview-pr"
        backPath="purchase-requisition"
      />
    ),
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/purchase-requisition',
    },
    component: <GeneralPRListing />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/purchase-requisition/add',
    },
    component: <GeneralPRForm formMode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/purchase-requisition/:PRHeaderID/edit',
    },
    component: <GeneralPRForm formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/purchase-requisition/:PRHeaderID/submit',
    },
    component: <GeneralPRForm formMode="submit" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/purchase-requisition/add/preview',
    },
    component: <GeneralPRForm formMode="submit" />,
  },
  {
    props: {
      exact: true,
      path: '/:CompanyID/purchase-requisition/:PRHeaderID',
    },
    component: <GeneralPRDetail />,
  },

  // {
  //   props: {
  //     exact: true,
  //     path: '/:CompanyID/purchase-requisition/add/purchase-req-item/add',
  //   },
  //   component: <GeneralPRItemForm detailMode="add" />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: '/:CompanyID/purchase-requisition/:PRHeaderID/edit',
  //   },
  //   component: <GeneralPRItemForm detailMode="edit" />,
  // },

  {
    props: {
      exact: true,
      path:
        '/:CompanyID/purchase-requisition/:formMode/purchase-requisition-item/:detailMode',
    },
    component: <GeneralPRItemForm />,
  },
  //payment = add , paymentitem = edit & add
  {
    props: {
      exact: true,
      path:
        '/:CompanyID/purchase-requisition/:PRHeaderID/:formMode/purchase-requisition-item/:detailMode',
    },
    component: <GeneralPRItemForm />,
  },
  //payment = add, paymentitem = edit
  {
    props: {
      exact: true,
      path:
        '/:CompanyID/purchase-requisition/:formMode/purchase-requisition-item/:PRItemID/:detailMode',
    },
    component: <GeneralPRItemForm />,
  },
  //payment = edit, paymentitem = edit
  {
    props: {
      exact: true,
      path:
        '/:CompanyID/purchase-requisition/:PRHeaderID/:formMode/purchase-requisition-item/:PRItemID/:detailMode',
    },
    component: <GeneralPRItemForm />,
  },
];

export default generalPRRoutes;
