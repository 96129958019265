import React, { lazy } from 'react';

const BulkPurchasePriceListing = lazy(() =>
  import('./BulkPurchasePriceListing').then(module => ({
    default: module.BulkPurchasePriceListing,
  })),
);

const BulkPurchasePriceForm = lazy(() =>
  import('./BulkPurchasePriceForm').then(module => ({
    default: module.BulkPurchasePriceForm,
  })),
);

const bulkPurchasePriceRoutes = [
  {
    props: { exact: true, path: '/general-setting/bulk-purchase' },
    component: <BulkPurchasePriceListing />,
  },
  {
    props: { exact: true, path: '/general-setting/bulk-purchase/add' },
    component: <BulkPurchasePriceForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/general-setting/bulk-purchase/:bulkPurchaseID/edit',
    },
    component: <BulkPurchasePriceForm mode="edit" />,
  },
];

export default bulkPurchasePriceRoutes;
