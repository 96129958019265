import { useMediaQuery } from '@ifca-root/react-component/src/helpers/PDFFormatter/mediaSize';
import React from 'react';
import { useLocation } from 'react-router';

export const HeaderPR = (props: any) => {
  const location: any = useLocation();
  const previewDetail: any = location?.state;

  const [width] = useMediaQuery();

  let fontSize;
  if (width < 769) {
    fontSize = 8;
  } else {
    fontSize = 12;
  }

  let logoStyle, containerStyle, fontStyle;
  fontStyle = {
    padding: '10px',
    fontSize: fontSize,
    textAlign: 'center',
  };

  return (
    <>
      <div style={containerStyle}>
        <div style={fontStyle}>
          <b>
            {previewDetail?.Company?.Name}
            {` (${previewDetail?.Company?.CompanyRegNo})`}
          </b>
          <br />
          {previewDetail?.Company?.Address.address}
          <br />
          {previewDetail?.Company?.Address?.country == 'Singapore'
            ? `${previewDetail?.Company?.Address?.country} ${previewDetail?.Company?.Address?.postCode}`
            : `${previewDetail?.Company?.Address?.postCode} ${previewDetail?.Company?.Address?.city}, ${previewDetail?.Company?.Address?.state}, ${previewDetail?.Company?.Address?.country}`}
          <br />
          Tel No. {previewDetail?.Company?.ContactNo ?? `N/A`}
          {previewDetail?.Company?.Address?.CompanyTax === 'GST_NO' &&
          previewDetail?.Company?.GSTNo != null ? (
            <>
              <br />
              GST Registration No. : {previewDetail?.Address?.GSTNo}
            </>
          ) : previewDetail?.Company?.Address?.CompanyTax === 'SST_NO' &&
            previewDetail?.Company?.SSTNo != null ? (
            <>
              <br />
              SST Registration No. : {previewDetail?.Address?.SSTNo}
            </>
          ) : (
            <>
              <br />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderPR;
