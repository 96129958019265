import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ApAgingEntity = AuditEntity & {
  __typename?: 'APAgingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AgingID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  Period1: Scalars['Float'];
  Period2: Scalars['Float'];
  Period3: Scalars['Float'];
  Period4: Scalars['Float'];
  Period5: Scalars['Float'];
  Period6: Scalars['Float'];
  AccountType?: Maybe<Scalars['String']>;
};

export type ApglExportEntity = AuditEntity & {
  __typename?: 'APGLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  GLExportID: Scalars['String'];
  BatchNo?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  GLDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  AccountCode?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['Float']>;
  Credit?: Maybe<Scalars['Float']>;
};

export type ArAgingEntity = AuditEntity & {
  __typename?: 'ARAgingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AgingID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  Period1: Scalars['Float'];
  Period2: Scalars['Float'];
  Period3: Scalars['Float'];
  Period4: Scalars['Float'];
  Period5: Scalars['Float'];
  Period6: Scalars['Float'];
  AccountType?: Maybe<Scalars['String']>;
};

export type ArglExportEntity = AuditEntity & {
  __typename?: 'ARGLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  GLExportID: Scalars['String'];
  BatchNo?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  GLDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  AccountCode?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['Float']>;
  Credit?: Maybe<Scalars['Float']>;
};

export type AccApAdvanceEntity = AuditEntity & {
  __typename?: 'AccAPAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  AdvanceID: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  IsAutoDocNo: Scalars['Boolean'];
  IsCBPosted: Scalars['Boolean'];
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
};

export type AccApAllocationEntity = AuditEntity & {
  __typename?: 'AccAPAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
  AllocationAmt: Scalars['Float'];
  AllocationID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  APAllocationItem?: Maybe<Array<AccApAllocationItemEntity>>;
};

export type AccApAllocationItemEntity = AuditEntity & {
  __typename?: 'AccAPAllocationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
  AllocationItemAmt: Scalars['Float'];
  AllocationItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  AllocationID: Scalars['String'];
  Allocation?: Maybe<AccApAllocationEntity>;
};

export type AccApAllocationItemObj = {
  __typename?: 'AccAPAllocationItemObj';
  AllocationItemID: Scalars['String'];
  AllocationID: Scalars['String'];
  AllocationItemAmt?: Maybe<Scalars['Float']>;
  CreditOrDebit: Scalars['String'];
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
};

export type AccApAllocationObj = {
  __typename?: 'AccAPAllocationObj';
  AllocationID: Scalars['String'];
  AllocationAmt?: Maybe<Scalars['Float']>;
  CreditOrDebit: Scalars['String'];
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DebitDocNo?: Maybe<Scalars['String']>;
  DebitDocDate?: Maybe<Scalars['DateTime']>;
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditDocNo?: Maybe<Scalars['String']>;
  CreditDocDate?: Maybe<Scalars['DateTime']>;
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
};

export type AccApBatchPaymentEntity = AuditEntity & {
  __typename?: 'AccAPBatchPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  BatchPaymentID: Scalars['String'];
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  Remark?: Maybe<Scalars['String']>;
  GLGenerated: Scalars['Boolean'];
  CBGenerated: Scalars['Boolean'];
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
};

export type AccApCreditNoteEntity = AuditEntity & {
  __typename?: 'AccAPCreditNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CreditNoteID: Scalars['String'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  IsCBPosted: Scalars['Boolean'];
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
};

export type AccApCreditNoteItemEntity = AuditEntity & {
  __typename?: 'AccAPCreditNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  CreditNoteItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  ItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  CreditNoteID: Scalars['String'];
  APCreditNote?: Maybe<AccApCreditNoteEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
};

export type AccApCreditorCreditNoteEntity = AuditEntity & {
  __typename?: 'AccAPCreditorCreditNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CreditorCreditNoteID: Scalars['String'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  IsCBPosted: Scalars['Boolean'];
  APCreditorCreditNoteItem?: Maybe<Array<AccApCreditorCreditNoteItemEntity>>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
};

export type AccApCreditorCreditNoteItemEntity = AuditEntity & {
  __typename?: 'AccAPCreditorCreditNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  CreditorCreditNoteItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  GRTNItemID?: Maybe<Scalars['String']>;
  CreditorCreditNoteID: Scalars['String'];
  APCreditorCreditNote?: Maybe<AccApCreditorCreditNoteEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  ItemID?: Maybe<Scalars['String']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
};

export type AccApDebitNoteEntity = AuditEntity & {
  __typename?: 'AccAPDebitNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DebitNoteID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  IsAutoDocNo: Scalars['Boolean'];
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  IsCBPosted: Scalars['Boolean'];
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
};

export type AccApDebitNoteItemEntity = AuditEntity & {
  __typename?: 'AccAPDebitNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DebitNoteItemID: Scalars['String'];
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  DebitNoteID: Scalars['String'];
  APDebitNote?: Maybe<AccApDebitNoteEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type AccApFavoriteInvoiceEntity = AuditEntity & {
  __typename?: 'AccAPFavoriteInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  FavoriteInvoiceID: Scalars['String'];
  UserID: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  Name?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  APFavoriteInvoiceItem?: Maybe<Array<AccApFavoriteInvoiceItemEntity>>;
};

export type AccApFavoriteInvoiceItemEntity = AuditEntity & {
  __typename?: 'AccAPFavoriteInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  FavoriteInvoiceItemID: Scalars['String'];
  UnitPrice?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  FavoriteInvoiceID: Scalars['String'];
  APFavoriteInvoice?: Maybe<AccApFavoriteInvoiceEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  ExpenseItemID: Scalars['String'];
  ExpenseItem?: Maybe<ExpenseItemEntity>;
};

export type AccApInvoiceEntity = AuditEntity & {
  __typename?: 'AccAPInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  InvoiceID: Scalars['String'];
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DiscountRate?: Maybe<Scalars['Float']>;
  DiscountAmt?: Maybe<Scalars['Float']>;
  OtherCharges?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  IsCBPosted: Scalars['Boolean'];
  CompanyID: Scalars['String'];
  invoice?: Maybe<CompanyEntity>;
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
};

export type AccApInvoiceItemEntity = AuditEntity & {
  __typename?: 'AccAPInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  InvoiceItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  InvoiceID: Scalars['String'];
  APInvoice?: Maybe<AccApInvoiceEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  DOItemID?: Maybe<Scalars['String']>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
};

export type AccApPaymentEntity = AuditEntity & {
  __typename?: 'AccAPPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  PaymentID: Scalars['String'];
  IsCBPosted: Scalars['Boolean'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  BatchPaymentID?: Maybe<Scalars['String']>;
  APBatchPayment?: Maybe<AccApBatchPaymentEntity>;
};

export type AccApRefundEntity = AuditEntity & {
  __typename?: 'AccAPRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  RefundID: Scalars['String'];
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  IsCBPosted: Scalars['Boolean'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  CreditorAccountID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
};

export type AccArAdvanceEntity = AuditEntity & {
  __typename?: 'AccARAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  AdvanceID: Scalars['String'];
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  TaxInvoiceNo: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  IsCBPosted: Scalars['Boolean'];
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
};

export type AccArAllocationEntity = AuditEntity & {
  __typename?: 'AccARAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
  AllocationAmt: Scalars['Float'];
  AllocationID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  ARAllocationItem?: Maybe<Array<AccArAllocationItemEntity>>;
};

export type AccArAllocationItemEntity = AuditEntity & {
  __typename?: 'AccARAllocationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
  AllocationItemAmt: Scalars['Float'];
  AllocationItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  AllocationID: Scalars['String'];
  Allocation?: Maybe<AccArAllocationEntity>;
};

export type AccArAllocationItemObj = {
  __typename?: 'AccARAllocationItemObj';
  AllocationItemID: Scalars['String'];
  AllocationID: Scalars['String'];
  AllocationItemAmt?: Maybe<Scalars['Float']>;
  CreditOrDebit: Scalars['String'];
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DebitItemDocNo?: Maybe<Scalars['String']>;
  DebitItemDocDate?: Maybe<Scalars['DateTime']>;
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemDocNo?: Maybe<Scalars['String']>;
  CreditItemDocDate?: Maybe<Scalars['DateTime']>;
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
};

export type AccArAllocationObj = {
  __typename?: 'AccARAllocationObj';
  AllocationID: Scalars['String'];
  AllocationAmt?: Maybe<Scalars['Float']>;
  CreditOrDebit: Scalars['String'];
  CompanyID: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DebitDocNo?: Maybe<Scalars['String']>;
  DebitDocDate?: Maybe<Scalars['DateTime']>;
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditDocNo?: Maybe<Scalars['String']>;
  CreditDocDate?: Maybe<Scalars['DateTime']>;
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
};

export type AccArCollectionEntity = AuditEntity & {
  __typename?: 'AccARCollectionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  CollectionActivityID: Scalars['String'];
  ActionDate?: Maybe<Scalars['DateTime']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
};

export type AccArCreditNoteEntity = AuditEntity & {
  __typename?: 'AccARCreditNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CreditNoteID: Scalars['String'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  IsCBPosted: Scalars['Boolean'];
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  DocAmt: Scalars['Float'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  Remark?: Maybe<Scalars['String']>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
};

export type AccArCreditNoteItemEntity = AuditEntity & {
  __typename?: 'AccARCreditNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  CreditNoteItemID: Scalars['String'];
  CreditNoteID: Scalars['String'];
  ARCreditNote?: Maybe<AccArCreditNoteEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  BillItemID?: Maybe<Scalars['String']>;
  BillItem?: Maybe<BillItemEntity>;
};

export type AccArDebitNoteEntity = AuditEntity & {
  __typename?: 'AccARDebitNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DebitNoteID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  CreditTerm?: Maybe<Scalars['Float']>;
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  InterestRate?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  IsCBPosted: Scalars['Boolean'];
};

export type AccArDebitNoteItemEntity = AuditEntity & {
  __typename?: 'AccARDebitNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  DebitNoteItemID: Scalars['String'];
  DebitNoteID: Scalars['String'];
  ARDebitNote?: Maybe<AccArDebitNoteEntity>;
  BillItemID?: Maybe<Scalars['String']>;
  BillItem?: Maybe<BillItemEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseItem?: Maybe<ExpenseItemEntity>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type AccArFavoriteInvoiceEntity = AuditEntity & {
  __typename?: 'AccARFavoriteInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  FavoriteInvoiceID: Scalars['String'];
  UserID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  Name?: Maybe<Scalars['String']>;
  InterestRate?: Maybe<Scalars['Float']>;
  FavoriteInvoiceItem?: Maybe<Array<AccArFavoriteInvoiceItemEntity>>;
  Remark: Scalars['String'];
};

export type AccArFavoriteInvoiceItemEntity = AuditEntity & {
  __typename?: 'AccARFavoriteInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  FavoriteInvoiceItemID: Scalars['String'];
  FavoriteInvoiceID: Scalars['String'];
  FavoriteInvoice?: Maybe<AccArFavoriteInvoiceEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
};

export type AccArInvoiceEntity = AuditEntity & {
  __typename?: 'AccARInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  InvoiceID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  CreditTerm?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  ARInvoiceItem?: Maybe<Array<AccArInvoiceItemEntity>>;
  CompanyID: Scalars['String'];
  company?: Maybe<CompanyEntity>;
  IsCBPosted: Scalars['Boolean'];
};

export type AccArInvoiceItemEntity = AuditEntity & {
  __typename?: 'AccARInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  InvoiceItemID: Scalars['String'];
  DocAmtBeforeTax?: Maybe<Scalars['Float']>;
  InvoiceID: Scalars['String'];
  ARInvoice?: Maybe<AccArInvoiceEntity>;
  BillItemID?: Maybe<Scalars['String']>;
  BillItem?: Maybe<BillItemEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
};

export type AccArOfficialReceiptEntity = AuditEntity & {
  __typename?: 'AccAROfficialReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  ReceiptID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  Amount?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  IsCBPosted: Scalars['Boolean'];
};

export type AccArRefundEntity = AuditEntity & {
  __typename?: 'AccARRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  RefundID: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  DebtorAccountID: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  BankAccountID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  Amount?: Maybe<Scalars['Float']>;
  IsCBPosted: Scalars['Boolean'];
};

export type AccApprovalPolicyAssignmentEntity = AuditEntity & {
  __typename?: 'AccApprovalPolicyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalPolicyAssignmentID: Scalars['String'];
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  ApprovalPolicy: AccApprovalPolicyEntity;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
  Category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type AccApprovalPolicyEntity = AuditEntity & {
  __typename?: 'AccApprovalPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalPolicyID: Scalars['String'];
  Category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
  ApprovalPolicyAssignment: Array<AccApprovalPolicyAssignmentEntity>;
};

export type AccBankAccountEntity = AuditEntity & {
  __typename?: 'AccBankAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  BankProfileID: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  BankProfile?: Maybe<BankProfileEntity>;
  Code: Scalars['String'];
  AccountNo: Scalars['String'];
  Type: BankAccountType;
  SWIFTCode: Scalars['String'];
  EffectiveDate: Scalars['String'];
  CurrencyID?: Maybe<Scalars['String']>;
  Currency?: Maybe<CurrencyEntity>;
  BankReconciliation?: Maybe<Array<BankReconciliationEntity>>;
  Ledger?: Maybe<Array<LedgerEntity>>;
  ExternalLedger?: Maybe<Array<ExternalLedgerEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  Receipt?: Maybe<Array<ReceiptEntity>>;
  BankTransfer?: Maybe<Array<BankTransferEntity>>;
  APRefund?: Maybe<Array<AccApRefundEntity>>;
  ARRefund?: Maybe<Array<AccArRefundEntity>>;
  APAdvance?: Maybe<Array<AccApAdvanceEntity>>;
  ARAdvance?: Maybe<Array<AccArAdvanceEntity>>;
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
  AROfficialReceipt?: Maybe<Array<AccArOfficialReceiptEntity>>;
  APBatchPayment?: Maybe<Array<AccApBatchPaymentEntity>>;
};

export type AccContactEntity = AuditEntity & {
  __typename?: 'AccContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ContactID: Scalars['String'];
  AssociateID: Scalars['String'];
  Name: Scalars['String'];
  Designation?: Maybe<Scalars['String']>;
  Department?: Maybe<Scalars['String']>;
  JobTitle?: Maybe<Scalars['String']>;
  ContactNo: Scalars['String'];
  Email: Scalars['String'];
  Relationship?: Maybe<Scalars['String']>;
  DocRefTable: Scalars['String'];
};

export type AccCurrencyExchangeEntity = AuditEntity & {
  __typename?: 'AccCurrencyExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CurrencyExchangeID: Scalars['String'];
  FromCurrencyID: Scalars['String'];
  ToCurrencyID: Scalars['String'];
  FromCurrency?: Maybe<CurrencyEntity>;
  ToCurrency?: Maybe<CurrencyEntity>;
  EffectiveDate?: Maybe<Scalars['String']>;
  FromAmt: Scalars['Float'];
  ToAmt: Scalars['Float'];
  ExchangeRate: Scalars['Float'];
  CurrencyLog: Scalars['JSON'];
};

export type AccReportDevEntity = {
  __typename?: 'AccReportDevEntity';
  ID: Scalars['String'];
  ParentID: Scalars['String'];
  ReportName: Scalars['String'];
  FilePath?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Module?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type AccRoleEntity = AuditEntity & {
  __typename?: 'AccRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  rolePerm: Array<AccRolePermissionAssignmentEntity>;
  ModuleType: ModuleType;
};

export type AccRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'AccRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  permName?: Maybe<Scalars['String']>;
};

export type AccRoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'AccRoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type AccRoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type AccTax = {
  __typename?: 'AccTax';
  Rate?: Maybe<Scalars['Float']>;
  Date?: Maybe<Scalars['DateTime']>;
};

export enum AccTaxCategory {
  Inclusive = 'INCLUSIVE',
  Exclusive = 'EXCLUSIVE'
}

export enum AccTaxClass {
  Input = 'INPUT',
  Output = 'OUTPUT'
}

export type AccUomEntity = AuditEntity & {
  __typename?: 'AccUOMEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  UOMID: Scalars['String'];
  ExpenseItem?: Maybe<Array<ExpenseItemEntity>>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  APFavoriteInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  StockItem?: Maybe<Array<StockItemEntity>>;
  StockReceiptItem?: Maybe<Array<StockReceiptItemEntity>>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
  StockTransferItem?: Maybe<Array<StockTransferItemEntity>>;
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  StockAdjusmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
  StockWeightedAvg?: Maybe<Array<StockWeightedAvgEntity>>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  APCreditorCreditNoteItem?: Maybe<Array<AccApCreditorCreditNoteItemEntity>>;
};

export type AccUomExchangeEntity = AuditEntity & {
  __typename?: 'AccUOMExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  AccountID?: Maybe<Scalars['String']>;
  FromUomID: Scalars['String'];
  ToUomID: Scalars['String'];
  FromAmt: Scalars['Float'];
  ToAmt: Scalars['Float'];
  ExchangeRate: Scalars['Float'];
  UomLog?: Maybe<Array<Scalars['JSON']>>;
  FromUOM: AccUomEntity;
  ToUOM: AccUomEntity;
};

export type AccWorkFlowApprovedStepEntity = AuditEntity & {
  __typename?: 'AccWorkFlowApprovedStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowApprovedStepID: Scalars['String'];
  WorkFlowStepID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlowStep: AccWorkFlowStepEntity;
};

export type AccWorkFlowEntity = AuditEntity & {
  __typename?: 'AccWorkFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowID: Scalars['String'];
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['String']>;
  WorkFlowStatus?: Maybe<ApprovalStatus>;
  WorkFlowStep?: Maybe<Array<AccWorkFlowStepEntity>>;
};

export type AccWorkFlowStepEntity = AuditEntity & {
  __typename?: 'AccWorkFlowStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowStepID: Scalars['String'];
  WorkFlowID?: Maybe<Scalars['String']>;
  ApproverRoleID?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['String']>;
  StepNo?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlow: AccWorkFlowEntity;
  WorkFlowApprovedStep?: Maybe<Array<AccWorkFlowApprovedStepEntity>>;
};

export type AccountCodeSummary = {
  __typename?: 'AccountCodeSummary';
  CodeLength?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  AllowControl?: Maybe<Scalars['Boolean']>;
};

export type AccountCoreAllocationEntity = AuditEntity & {
  __typename?: 'AccountCoreAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitRefTable: Scalars['String'];
  DebitID: Scalars['String'];
  CreditRefTable: Scalars['String'];
  CreditID: Scalars['String'];
  AllocationAmt: Scalars['Float'];
};

export type AccountCoreAllocationItemEntity = AuditEntity & {
  __typename?: 'AccountCoreAllocationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DebitItemRefTable: Scalars['String'];
  DebitItemID: Scalars['String'];
  CreditItemRefTable: Scalars['String'];
  CreditItemID: Scalars['String'];
  AllocationItemAmt: Scalars['Float'];
};

export type AccountCoreApprovalEntity = AuditEntity & {
  __typename?: 'AccountCoreApprovalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
};

export type AccountCoreDocItemEntity = AuditEntity & {
  __typename?: 'AccountCoreDocItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export type AccountCoreEntity = AuditEntity & {
  __typename?: 'AccountCoreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
};

export type AccountCoreLedgerItemEntity = AuditEntity & {
  __typename?: 'AccountCoreLedgerItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
};

export type AccountCoreReceiptPaymentEntity = AuditEntity & {
  __typename?: 'AccountCoreReceiptPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
};

export type AccountCoreTaxItemEntity = AuditEntity & {
  __typename?: 'AccountCoreTaxItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
};

export type AccountDocDate = AuditEntity & {
  __typename?: 'AccountDocDate';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
};

export type AccountDocRef = AuditEntity & {
  __typename?: 'AccountDocRef';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
};

export type AccountDocRefNoApproval = AuditEntity & {
  __typename?: 'AccountDocRefNoApproval';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
};

export type AccountForex = AuditEntity & {
  __typename?: 'AccountForex';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
};

export type AccountLedgerEntity = AuditEntity & {
  __typename?: 'AccountLedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
};

export type AccountPeriodCheck = {
  __typename?: 'AccountPeriodCheck';
  YearClosed?: Maybe<Scalars['Boolean']>;
  IsEditable?: Maybe<Scalars['Boolean']>;
  LatestUsedPeriod?: Maybe<Scalars['Float']>;
  MinStartDate?: Maybe<Scalars['DateTime']>;
  MaxEndDate?: Maybe<Scalars['DateTime']>;
};

export type AccountPeriodDateRange = {
  __typename?: 'AccountPeriodDateRange';
  StartDate?: Maybe<Scalars['DateTime']>;
  EndDate?: Maybe<Scalars['DateTime']>;
};

export type AccountPeriodDeleteInput = {
  AccountPeriodID: Scalars['String'];
};

export type AccountPeriodEntity = AuditEntity & {
  __typename?: 'AccountPeriodEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AccountPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
  MonthEndClose: Scalars['Boolean'];
  YearEndClose: Scalars['Boolean'];
  BudgetItemPeriod?: Maybe<Array<BudgetItemPeriodEntity>>;
  RecurringJournal?: Maybe<Array<RecurringJournalEntity>>;
};

export type AccountPeriodInput = {
  AccountPeriodID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  FYear?: Maybe<Scalars['Float']>;
  FPeriod?: Maybe<Scalars['Float']>;
  StartDate: Scalars['String'];
  EndDate: Scalars['String'];
  MonthEndClose?: Maybe<Scalars['Boolean']>;
  YearEndClose?: Maybe<Scalars['Boolean']>;
};

export type AccountPeriodYearAndPeriod = {
  __typename?: 'AccountPeriodYearAndPeriod';
  StartYear?: Maybe<Scalars['Float']>;
  StartPeriod?: Maybe<Scalars['Float']>;
};

export enum AccountPermission {
  HomeGeneralLedgerView = 'HOME_GENERAL_LEDGER_______VIEW',
  HomeCashBookView = 'HOME_CASH_BOOK_______VIEW',
  HomeAccountReceivableView = 'HOME_ACCOUNT_RECEIVABLE_______VIEW',
  HomeAccountPayableView = 'HOME_ACCOUNT_PAYABLE_______VIEW',
  HomeAccessSecurityView = 'HOME_ACCESS_SECURITY_______VIEW',
  HomeAccessSecurityUserView = 'HOME_ACCESS_SECURITY_USER____VIEW',
  HomeAccessSecurityUserCreate = 'HOME_ACCESS_SECURITY_USER____CREATE',
  HomeAccessSecurityUserUpdate = 'HOME_ACCESS_SECURITY_USER____UPDATE',
  HomeAccessSecurityRoleView = 'HOME_ACCESS_SECURITY_ROLE____VIEW',
  HomeAccessSecurityRoleCreate = 'HOME_ACCESS_SECURITY_ROLE____CREATE',
  HomeAccessSecurityRoleUpdate = 'HOME_ACCESS_SECURITY_ROLE____UPDATE',
  HomeAccessSecurityRoleDelete = 'HOME_ACCESS_SECURITY_ROLE____DELETE',
  HomeGeneralSettingsCurrencySetupView = 'HOME_GENERAL_SETTINGS_CURRENCY_SETUP____VIEW',
  HomeGeneralSettingsCurrencySetupCreate = 'HOME_GENERAL_SETTINGS_CURRENCY_SETUP____CREATE',
  HomeGeneralSettingsCurrencySetupUpdate = 'HOME_GENERAL_SETTINGS_CURRENCY_SETUP____UPDATE',
  HomeGeneralSettingsCurrencySetupDelete = 'HOME_GENERAL_SETTINGS_CURRENCY_SETUP____DELETE',
  HomeGeneralSettingsCurrencyExchangeView = 'HOME_GENERAL_SETTINGS_CURRENCY_EXCHANGE____VIEW',
  HomeGeneralSettingsCurrencyExchangeCreate = 'HOME_GENERAL_SETTINGS_CURRENCY_EXCHANGE____CREATE',
  HomeGeneralSettingsCurrencyExchangeUpdate = 'HOME_GENERAL_SETTINGS_CURRENCY_EXCHANGE____UPDATE',
  HomeGeneralSettingsCurrencyExchangeDelete = 'HOME_GENERAL_SETTINGS_CURRENCY_EXCHANGE____DELETE',
  HomeGeneralSettingsTaxSetupView = 'HOME_GENERAL_SETTINGS_TAX_SETUP____VIEW',
  HomeGeneralSettingsTaxSetupCreate = 'HOME_GENERAL_SETTINGS_TAX_SETUP____CREATE',
  HomeGeneralSettingsTaxSetupUpdate = 'HOME_GENERAL_SETTINGS_TAX_SETUP____UPDATE',
  HomeGeneralSettingsTaxSetupActive = 'HOME_GENERAL_SETTINGS_TAX_SETUP____ACTIVE',
  HomeGeneralSettingsTaxSetupInactive = 'HOME_GENERAL_SETTINGS_TAX_SETUP____INACTIVE',
  HomeGeneralSettingsTaxSetupDelete = 'HOME_GENERAL_SETTINGS_TAX_SETUP____DELETE',
  HomeGeneralSettingsCostCentreDefinitionView = 'HOME_GENERAL_SETTINGS_COST_CENTRE_DEFINITION____VIEW',
  HomeGeneralSettingsCostCentreDefinitionCreate = 'HOME_GENERAL_SETTINGS_COST_CENTRE_DEFINITION____CREATE',
  HomeGeneralSettingsCostCentreDefinitionUpdate = 'HOME_GENERAL_SETTINGS_COST_CENTRE_DEFINITION____UPDATE',
  HomeCompanySettingsView = 'HOME_COMPANY_SETTINGS_______VIEW',
  HomeCompanySettingsCompanyProfileView = 'HOME_COMPANY_SETTINGS_COMPANY_PROFILE____VIEW',
  HomeCompanySettingsCompanyProfileUpdate = 'HOME_COMPANY_SETTINGS_COMPANY_PROFILE____UPDATE',
  HomeCompanySettingsCostCentreAssignmentView = 'HOME_COMPANY_SETTINGS_COST_CENTRE_ASSIGNMENT____VIEW',
  HomeCompanySettingsCostCentreAssignmentCreate = 'HOME_COMPANY_SETTINGS_COST_CENTRE_ASSIGNMENT____CREATE',
  HomeCompanySettingsCostCentreAssignmentUpdate = 'HOME_COMPANY_SETTINGS_COST_CENTRE_ASSIGNMENT____UPDATE',
  HomeCompanySettingsCostCentreView = 'HOME_COMPANY_SETTINGS_COST_CENTRE____VIEW',
  HomeCompanySettingsCostCentreCreate = 'HOME_COMPANY_SETTINGS_COST_CENTRE____CREATE',
  HomeCompanySettingsCostCentreUpdate = 'HOME_COMPANY_SETTINGS_COST_CENTRE____UPDATE',
  HomeCompanySettingsCostCentreDelete = 'HOME_COMPANY_SETTINGS_COST_CENTRE____DELETE',
  HomeCompanySettingsCompanyAuthorisationView = 'HOME_COMPANY_SETTINGS_COMPANY_AUTHORISATION____VIEW',
  HomeCompanySettingsCompanyAuthorisationCreate = 'HOME_COMPANY_SETTINGS_COMPANY_AUTHORISATION____CREATE',
  HomeCompanySettingsCompanyAuthorisationUpdate = 'HOME_COMPANY_SETTINGS_COMPANY_AUTHORISATION____UPDATE',
  GeneralLedgerView = 'GENERAL_LEDGER__________VIEW',
  GeneralLedgerRoutineJournalView = 'GENERAL_LEDGER_ROUTINE_JOURNAL_______VIEW',
  GeneralLedgerRoutineJournalCreate = 'GENERAL_LEDGER_ROUTINE_JOURNAL_______CREATE',
  GeneralLedgerRoutineJournalUpdate = 'GENERAL_LEDGER_ROUTINE_JOURNAL_______UPDATE',
  GeneralLedgerRecurringJournalView = 'GENERAL_LEDGER_RECURRING_JOURNAL_______VIEW',
  GeneralLedgerRecurringJournalCreate = 'GENERAL_LEDGER_RECURRING_JOURNAL_______CREATE',
  GeneralLedgerRecurringJournalUpdate = 'GENERAL_LEDGER_RECURRING_JOURNAL_______UPDATE',
  GeneralLedgerJournalProcessingView = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______VIEW',
  GeneralLedgerJournalProcessingCreate = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______CREATE',
  GeneralLedgerJournalProcessingUpdate = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______UPDATE',
  GeneralLedgerJournalPostingView = 'GENERAL_LEDGER_JOURNAL_POSTING_______VIEW',
  GeneralLedgerJournalPostingApproveReject = 'GENERAL_LEDGER_JOURNAL_POSTING_______APPROVE_REJECT',
  GeneralLedgerAuditAdjustmentView = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______VIEW',
  GeneralLedgerAuditAdjustmentCreate = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______CREATE',
  GeneralLedgerAuditAdjustmentUpdate = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______UPDATE',
  GeneralLedgerAuditAdjustmentDelete = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______DELETE',
  GeneralLedgerClosingMonthEndYearEndView = 'GENERAL_LEDGER_CLOSING_MONTH_END_YEAR_END_______VIEW',
  GeneralLedgerGeneralSettingsView = 'GENERAL_LEDGER_GENERAL_SETTINGS_______VIEW',
  GeneralLedgerGeneralSettingsMasterCoaView = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____VIEW',
  GeneralLedgerGeneralSettingsMasterCoaCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____CREATE',
  GeneralLedgerGeneralSettingsMasterCoaUpdate = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____UPDATE',
  GeneralLedgerGeneralSettingsApprovalPolicyView = 'GENERAL_LEDGER_GENERAL_SETTINGS_APPROVAL_POLICY____VIEW',
  GeneralLedgerGeneralSettingsApprovalPolicyRecurringJournalView = 'GENERAL_LEDGER_GENERAL_SETTINGS_APPROVAL_POLICY_RECURRING_JOURNAL_VIEW',
  GeneralLedgerGeneralSettingsApprovalPolicyRecurringJournalCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_APPROVAL_POLICY_RECURRING_JOURNAL_CREATE',
  GeneralLedgerGeneralSettingsApprovalPolicyRecurringJournalUpdate = 'GENERAL_LEDGER_GENERAL_SETTINGS_APPROVAL_POLICY_RECURRING_JOURNAL_UPDATE',
  GeneralLedgerGeneralSettingsApprovalPolicyRecurringJournalDelete = 'GENERAL_LEDGER_GENERAL_SETTINGS_APPROVAL_POLICY_RECURRING_JOURNAL_DELETE',
  GeneralLedgerGeneralSettingsApprovalPolicyJournalProcessingView = 'GENERAL_LEDGER_GENERAL_SETTINGS_APPROVAL_POLICY_JOURNAL_PROCESSING_VIEW',
  GeneralLedgerGeneralSettingsApprovalPolicyJournalProcessingCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_APPROVAL_POLICY_JOURNAL_PROCESSING_CREATE',
  GeneralLedgerGeneralSettingsApprovalPolicyJournalProcessingUpdate = 'GENERAL_LEDGER_GENERAL_SETTINGS_APPROVAL_POLICY_JOURNAL_PROCESSING_UPDATE',
  GeneralLedgerGeneralSettingsApprovalPolicyAuditAdjustmentView = 'GENERAL_LEDGER_GENERAL_SETTINGS_APPROVAL_POLICY_AUDIT_ADJUSTMENT_VIEW',
  GeneralLedgerGeneralSettingsApprovalPolicyAuditAdjustmentCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_APPROVAL_POLICY_AUDIT_ADJUSTMENT_CREATE',
  GeneralLedgerGeneralSettingsApprovalPolicyAuditAdjustmentUpdate = 'GENERAL_LEDGER_GENERAL_SETTINGS_APPROVAL_POLICY_AUDIT_ADJUSTMENT_UPDATE',
  GeneralLedgerGeneralSettingsApprovalPolicyAuditAdjustmentDelete = 'GENERAL_LEDGER_GENERAL_SETTINGS_APPROVAL_POLICY_AUDIT_ADJUSTMENT_DELETE',
  GeneralLedgerCompanySettingsView = 'GENERAL_LEDGER_COMPANY_SETTINGS_______VIEW',
  GeneralLedgerCompanySettingsEntityCoaView = 'GENERAL_LEDGER_COMPANY_SETTINGS_ENTITY_COA____VIEW',
  GeneralLedgerCompanySettingsEntityCoaAssign = 'GENERAL_LEDGER_COMPANY_SETTINGS_ENTITY_COA____ASSIGN',
  GeneralLedgerCompanySettingsEntityCoaUnassign = 'GENERAL_LEDGER_COMPANY_SETTINGS_ENTITY_COA____UNASSIGN',
  GeneralLedgerCompanySettingsNumberingStructureView = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE____VIEW',
  GeneralLedgerCompanySettingsNumberingStructureRoutineJournalView = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE_ROUTINE_JOURNAL_VIEW',
  GeneralLedgerCompanySettingsNumberingStructureRoutineJournalCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE_ROUTINE_JOURNAL_CREATE',
  GeneralLedgerCompanySettingsNumberingStructureRoutineJournalUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE_ROUTINE_JOURNAL_UPDATE',
  GeneralLedgerCompanySettingsNumberingStructureRoutineJournalDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE_ROUTINE_JOURNAL_DELETE',
  GeneralLedgerCompanySettingsNumberingStructureRecurringJournalView = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE_RECURRING_JOURNAL_VIEW',
  GeneralLedgerCompanySettingsNumberingStructureJournalProcessingView = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE_JOURNAL_PROCESSING_VIEW',
  GeneralLedgerCompanySettingsNumberingStructureAuditAdjustmentView = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE_AUDIT_ADJUSTMENT_VIEW',
  GeneralLedgerCompanySettingsAccountingPeriodAuditAdjustmentView = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD_AUDIT_ADJUSTMENT_VIEW',
  GeneralLedgerCompanySettingsAccountingPeriodAuditAdjustmentCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD_AUDIT_ADJUSTMENT_CREATE',
  GeneralLedgerCompanySettingsAccountingPeriodAuditAdjustmentUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD_AUDIT_ADJUSTMENT_UPDATE',
  GeneralLedgerCompanySettingsAccountingPeriodAuditAdjustmentDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD_AUDIT_ADJUSTMENT_DELETE',
  GeneralLedgerCompanySettingsJournalTypeAuditAdjustmentView = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE_AUDIT_ADJUSTMENT_VIEW',
  GeneralLedgerCompanySettingsJournalTypeAuditAdjustmentCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE_AUDIT_ADJUSTMENT_CREATE',
  GeneralLedgerCompanySettingsJournalTypeAuditAdjustmentUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE_AUDIT_ADJUSTMENT_UPDATE',
  GeneralLedgerCompanySettingsJournalTypeAuditAdjustmentDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE_AUDIT_ADJUSTMENT_DELETE',
  GeneralLedgerCompanySettingsRetainEarningAccAuditAdjustmentView = 'GENERAL_LEDGER_COMPANY_SETTINGS_RETAIN_EARNING_ACC_AUDIT_ADJUSTMENT_VIEW',
  GeneralLedgerCompanySettingsRetainEarningAccAuditAdjustmentCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_RETAIN_EARNING_ACC_AUDIT_ADJUSTMENT_CREATE',
  GeneralLedgerCompanySettingsRetainEarningAccAuditAdjustmentUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_RETAIN_EARNING_ACC_AUDIT_ADJUSTMENT_UPDATE',
  GeneralLedgerCompanySettingsBudgetAuditAdjustmentView = 'GENERAL_LEDGER_COMPANY_SETTINGS_BUDGET_AUDIT_ADJUSTMENT_VIEW',
  GeneralLedgerCompanySettingsApprovalPolicyAssignmentRecurringJournalView = 'GENERAL_LEDGER_COMPANY_SETTINGS_APPROVAL_POLICY_ASSIGNMENT_RECURRING_JOURNAL_VIEW',
  GeneralLedgerCompanySettingsApprovalPolicyAssignmentRecurringJournalCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_APPROVAL_POLICY_ASSIGNMENT_RECURRING_JOURNAL_CREATE',
  GeneralLedgerCompanySettingsApprovalPolicyAssignmentRecurringJournalUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_APPROVAL_POLICY_ASSIGNMENT_RECURRING_JOURNAL_UPDATE',
  GeneralLedgerCompanySettingsApprovalPolicyAssignmentJournalProcessingView = 'GENERAL_LEDGER_COMPANY_SETTINGS_APPROVAL_POLICY_ASSIGNMENT_JOURNAL_PROCESSING_VIEW',
  GeneralLedgerCompanySettingsApprovalPolicyAssignmentJournalProcessingCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_APPROVAL_POLICY_ASSIGNMENT_JOURNAL_PROCESSING_CREATE',
  GeneralLedgerCompanySettingsApprovalPolicyAssignmentJournalProcessingUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_APPROVAL_POLICY_ASSIGNMENT_JOURNAL_PROCESSING_UPDATE',
  GeneralLedgerCompanySettingsApprovalPolicyAssignmentAuditAdjustmentView = 'GENERAL_LEDGER_COMPANY_SETTINGS_APPROVAL_POLICY_ASSIGNMENT_AUDIT_ADJUSTMENT_VIEW',
  GeneralLedgerCompanySettingsApprovalPolicyAssignmentAuditAdjustmentCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_APPROVAL_POLICY_ASSIGNMENT_AUDIT_ADJUSTMENT_CREATE',
  GeneralLedgerCompanySettingsApprovalPolicyAssignmentAuditAdjustmentUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_APPROVAL_POLICY_ASSIGNMENT_AUDIT_ADJUSTMENT_UPDATE',
  CashBookView = 'CASH_BOOK__________VIEW',
  CashBookPaymentView = 'CASH_BOOK_PAYMENT_______VIEW',
  CashBookPaymentCreate = 'CASH_BOOK_PAYMENT_______CREATE',
  CashBookPaymentUpdate = 'CASH_BOOK_PAYMENT_______UPDATE',
  CashBookPaymentDelete = 'CASH_BOOK_PAYMENT_______DELETE',
  CashBookReceiptView = 'CASH_BOOK_RECEIPT_______VIEW',
  CashBookReceiptCreate = 'CASH_BOOK_RECEIPT_______CREATE',
  CashBookReceiptUpdate = 'CASH_BOOK_RECEIPT_______UPDATE',
  CashBookReceiptDelete = 'CASH_BOOK_RECEIPT_______DELETE',
  CashBookBankTransferView = 'CASH_BOOK_BANK_TRANSFER_______VIEW',
  CashBookBankTransferCreate = 'CASH_BOOK_BANK_TRANSFER_______CREATE',
  CashBookBankTransferUpdate = 'CASH_BOOK_BANK_TRANSFER_______UPDATE',
  CashBookBankTransferDelete = 'CASH_BOOK_BANK_TRANSFER_______DELETE',
  CashBookForexRevaluationView = 'CASH_BOOK_FOREX_REVALUATION_______VIEW',
  CashBookForexRevaluationCreate = 'CASH_BOOK_FOREX_REVALUATION_______CREATE',
  CashBookForexRevaluationUpdate = 'CASH_BOOK_FOREX_REVALUATION_______UPDATE',
  CashBookForexRevaluationDelete = 'CASH_BOOK_FOREX_REVALUATION_______DELETE',
  CashBookBankReconcilationView = 'CASH_BOOK_BANK_RECONCILATION_______VIEW',
  CashBookBankReconcilationCreate = 'CASH_BOOK_BANK_RECONCILATION_______CREATE',
  CashBookBankReconcilationMatch = 'CASH_BOOK_BANK_RECONCILATION_______MATCH',
  CashBookGeneralSettingsBankProfileView = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____VIEW',
  CashBookGeneralSettingsBankProfileCreate = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____CREATE',
  CashBookGeneralSettingsBankProfileUpdate = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____UPDATE',
  CashBookGeneralSettingsBankProfileDelete = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____DELETE',
  CashBookGeneralSettingsApprovalPolicyPaymentView = 'CASH_BOOK_GENERAL_SETTINGS_APPROVAL_POLICY_PAYMENT_VIEW',
  CashBookGeneralSettingsApprovalPolicyPaymentCreate = 'CASH_BOOK_GENERAL_SETTINGS_APPROVAL_POLICY_PAYMENT_CREATE',
  CashBookGeneralSettingsApprovalPolicyPaymentUpdate = 'CASH_BOOK_GENERAL_SETTINGS_APPROVAL_POLICY_PAYMENT_UPDATE',
  CashBookGeneralSettingsApprovalPolicyPaymentDelete = 'CASH_BOOK_GENERAL_SETTINGS_APPROVAL_POLICY_PAYMENT_DELETE',
  CashBookGeneralSettingsApprovalPolicyReceiptView = 'CASH_BOOK_GENERAL_SETTINGS_APPROVAL_POLICY_RECEIPT_VIEW',
  CashBookGeneralSettingsApprovalPolicyReceiptCreate = 'CASH_BOOK_GENERAL_SETTINGS_APPROVAL_POLICY_RECEIPT_CREATE',
  CashBookGeneralSettingsApprovalPolicyReceiptUpdate = 'CASH_BOOK_GENERAL_SETTINGS_APPROVAL_POLICY_RECEIPT_UPDATE',
  CashBookGeneralSettingsApprovalPolicyReceiptDelete = 'CASH_BOOK_GENERAL_SETTINGS_APPROVAL_POLICY_RECEIPT_DELETE',
  CashBookGeneralSettingsApprovalPolicyBankTransferView = 'CASH_BOOK_GENERAL_SETTINGS_APPROVAL_POLICY_BANK_TRANSFER_VIEW',
  CashBookGeneralSettingsApprovalPolicyBankTransferCreate = 'CASH_BOOK_GENERAL_SETTINGS_APPROVAL_POLICY_BANK_TRANSFER_CREATE',
  CashBookGeneralSettingsApprovalPolicyBankTransferUpdate = 'CASH_BOOK_GENERAL_SETTINGS_APPROVAL_POLICY_BANK_TRANSFER_UPDATE',
  CashBookGeneralSettingsApprovalPolicyBankTransferDelete = 'CASH_BOOK_GENERAL_SETTINGS_APPROVAL_POLICY_BANK_TRANSFER_DELETE',
  AccReceivableView = 'ACC_RECEIVABLE_________VIEW',
  AccReceivableDebtorAccView = 'ACC_RECEIVABLE_DEBTOR_ACC_______VIEW',
  AccReceivableDebtorAccCreate = 'ACC_RECEIVABLE_DEBTOR_ACC_______CREATE',
  AccReceivableDebtorAccUpdate = 'ACC_RECEIVABLE_DEBTOR_ACC_______UPDATE',
  AccReceivableInvoicesView = 'ACC_RECEIVABLE_INVOICES_______VIEW',
  AccReceivableInvoicesCreate = 'ACC_RECEIVABLE_INVOICES_______CREATE',
  AccReceivableInvoicesUpdate = 'ACC_RECEIVABLE_INVOICES_______UPDATE',
  AccReceivableInvoicesCancel = 'ACC_RECEIVABLE_INVOICES_______CANCEL',
  AccReceivableInvoicesApproveReject = 'ACC_RECEIVABLE_INVOICES_______APPROVE_REJECT',
  AccReceivableCreditNotesView = 'ACC_RECEIVABLE_CREDIT_NOTES_______VIEW',
  AccReceivableCreditNotesCreate = 'ACC_RECEIVABLE_CREDIT_NOTES_______CREATE',
  AccReceivableCreditNotesUpdate = 'ACC_RECEIVABLE_CREDIT_NOTES_______UPDATE',
  AccReceivableCreditNotesCancel = 'ACC_RECEIVABLE_CREDIT_NOTES_______CANCEL',
  AccReceivableCreditNotesApproveReject = 'ACC_RECEIVABLE_CREDIT_NOTES_______APPROVE_REJECT',
  AccReceivableDebitNotesView = 'ACC_RECEIVABLE_DEBIT_NOTES_______VIEW',
  AccReceivableDebitNotesCreate = 'ACC_RECEIVABLE_DEBIT_NOTES_______CREATE',
  AccReceivableDebitNotesUpdate = 'ACC_RECEIVABLE_DEBIT_NOTES_______UPDATE',
  AccReceivableDebitNotesCancel = 'ACC_RECEIVABLE_DEBIT_NOTES_______CANCEL',
  AccReceivableDebitNotesApproveReject = 'ACC_RECEIVABLE_DEBIT_NOTES_______APPROVE_REJECT',
  AccReceivableReceiptsView = 'ACC_RECEIVABLE_RECEIPTS_______VIEW',
  AccReceivableReceiptsCreate = 'ACC_RECEIVABLE_RECEIPTS_______CREATE',
  AccReceivableReceiptsUpdate = 'ACC_RECEIVABLE_RECEIPTS_______UPDATE',
  AccReceivableReceiptsCancel = 'ACC_RECEIVABLE_RECEIPTS_______CANCEL',
  AccReceivableReceiptsApproveReject = 'ACC_RECEIVABLE_RECEIPTS_______APPROVE_REJECT',
  AccReceivableAdvancesView = 'ACC_RECEIVABLE_ADVANCES_______VIEW',
  AccReceivableAdvancesCreate = 'ACC_RECEIVABLE_ADVANCES_______CREATE',
  AccReceivableAdvancesUpdate = 'ACC_RECEIVABLE_ADVANCES_______UPDATE',
  AccReceivableAdvancesCancel = 'ACC_RECEIVABLE_ADVANCES_______CANCEL',
  AccReceivableAdvancesApproveReject = 'ACC_RECEIVABLE_ADVANCES_______APPROVE_REJECT',
  AccReceivableRefundView = 'ACC_RECEIVABLE_REFUND_______VIEW',
  AccReceivableRefundCreate = 'ACC_RECEIVABLE_REFUND_______CREATE',
  AccReceivableRefundUpdate = 'ACC_RECEIVABLE_REFUND_______UPDATE',
  AccReceivableRefundCancel = 'ACC_RECEIVABLE_REFUND_______CANCEL',
  AccReceivableRefundApproveReject = 'ACC_RECEIVABLE_REFUND_______APPROVE_REJECT',
  AccReceivableInterestProcessingView = 'ACC_RECEIVABLE_INTEREST_PROCESSING_______VIEW',
  AccReceivableInterestProcessingCreate = 'ACC_RECEIVABLE_INTEREST_PROCESSING_______CREATE',
  AccReceivableInterestProcessingUpdate = 'ACC_RECEIVABLE_INTEREST_PROCESSING_______UPDATE',
  AccReceivableInterestProcessingShare = 'ACC_RECEIVABLE_INTEREST_PROCESSING_______SHARE',
  AccReceivablePaymentReminderView = 'ACC_RECEIVABLE_PAYMENT_REMINDER_______VIEW',
  AccReceivablePaymentReminderCreate = 'ACC_RECEIVABLE_PAYMENT_REMINDER_______CREATE',
  AccReceivablePaymentReminderShare = 'ACC_RECEIVABLE_PAYMENT_REMINDER_______SHARE',
  AccReceivableCollectionControlCollectionActivitiesView = 'ACC_RECEIVABLE_COLLECTION_CONTROL_COLLECTION_ACTIVITIES____VIEW',
  AccReceivableCollectionControlCollectionActivitiesCreate = 'ACC_RECEIVABLE_COLLECTION_CONTROL_COLLECTION_ACTIVITIES____CREATE',
  AccReceivableOverduedAccView = 'ACC_RECEIVABLE_OVERDUED_ACC_______VIEW',
  AccPayableView = 'ACC_PAYABLE__________VIEW',
  AccPayableCreate = 'ACC_PAYABLE__________CREATE',
  AccPayableCreditorProfileView = 'ACC_PAYABLE_CREDITOR_PROFILE_______VIEW',
  AccPayableCreditorProfileUpdate = 'ACC_PAYABLE_CREDITOR_PROFILE_______UPDATE',
  AccPayableAdvancesView = 'ACC_PAYABLE_ADVANCES_______VIEW',
  AccPayableAdvancesCreate = 'ACC_PAYABLE_ADVANCES_______CREATE',
  AccPayableAdvancesUpdate = 'ACC_PAYABLE_ADVANCES_______UPDATE',
  AccPayableAdvancesApproveReject = 'ACC_PAYABLE_ADVANCES_______APPROVE_REJECT',
  AccPayableAdvancesCancel = 'ACC_PAYABLE_ADVANCES_______CANCEL',
  AccPayableInvoicesView = 'ACC_PAYABLE_INVOICES_______VIEW',
  AccPayableInvoicesCreate = 'ACC_PAYABLE_INVOICES_______CREATE',
  AccPayableInvoicesUpdate = 'ACC_PAYABLE_INVOICES_______UPDATE',
  AccPayableInvoicesApproveReject = 'ACC_PAYABLE_INVOICES_______APPROVE_REJECT',
  AccPayableInvoicesCancel = 'ACC_PAYABLE_INVOICES_______CANCEL',
  AccPayableCreditNotesView = 'ACC_PAYABLE_CREDIT_NOTES_______VIEW',
  AccPayableCreditNotesCreate = 'ACC_PAYABLE_CREDIT_NOTES_______CREATE',
  AccPayableCreditNotesUpdate = 'ACC_PAYABLE_CREDIT_NOTES_______UPDATE',
  AccPayableCreditNotesApproveReject = 'ACC_PAYABLE_CREDIT_NOTES_______APPROVE_REJECT',
  AccPayableCreditNotesCancel = 'ACC_PAYABLE_CREDIT_NOTES_______CANCEL',
  AccPayableDebitNotesView = 'ACC_PAYABLE_DEBIT_NOTES_______VIEW',
  AccPayableDebitNotesCreate = 'ACC_PAYABLE_DEBIT_NOTES_______CREATE',
  AccPayableDebitNotesUpdate = 'ACC_PAYABLE_DEBIT_NOTES_______UPDATE',
  AccPayableDebitNotesApproveReject = 'ACC_PAYABLE_DEBIT_NOTES_______APPROVE_REJECT',
  AccPayableDebitNotesCancel = 'ACC_PAYABLE_DEBIT_NOTES_______CANCEL',
  AccPayablePaymentView = 'ACC_PAYABLE_PAYMENT_______VIEW',
  AccPayablePaymentCreate = 'ACC_PAYABLE_PAYMENT_______CREATE',
  AccPayablePaymentShare = 'ACC_PAYABLE_PAYMENT_______SHARE',
  AccPayableRefundView = 'ACC_PAYABLE_REFUND_______VIEW',
  AccPayableRefundCreate = 'ACC_PAYABLE_REFUND_______CREATE',
  AccPayableRefundUpdate = 'ACC_PAYABLE_REFUND_______UPDATE',
  AccPayableRefundCancel = 'ACC_PAYABLE_REFUND_______CANCEL',
  AccPayableRefundApproveReject = 'ACC_PAYABLE_REFUND_______APPROVE_REJECT',
  AccPayableAgingView = 'ACC_PAYABLE_AGING_______VIEW',
  GeneralSettingView = 'GENERAL_SETTING__________VIEW',
  GeneralSettingUserCreate = 'GENERAL_SETTING_USER_____________CREATE',
  GeneralSettingUserView = 'GENERAL_SETTING_USER_____________VIEW',
  GeneralSettingUserUpdate = 'GENERAL_SETTING_USER_____________UPDATE',
  GeneralSettingUserDelete = 'GENERAL_SETTING_USER_____________DELETE',
  GeneralSettingRoleCreate = 'GENERAL_SETTING_ROLE_____________CREATE',
  GeneralSettingRoleView = 'GENERAL_SETTING_ROLE_____________VIEW',
  GeneralSettingRoleUpdate = 'GENERAL_SETTING_ROLE_____________UPDATE',
  GeneralSettingRoleDelete = 'GENERAL_SETTING_ROLE_____________DELETE',
  GeneralSettingMasterCoaCreate = 'GENERAL_SETTING_MASTER_COA_____________CREATE',
  GeneralSettingMasterCoaView = 'GENERAL_SETTING_MASTER_COA_____________VIEW',
  GeneralSettingMasterCoaUpdate = 'GENERAL_SETTING_MASTER_COA_____________UPDATE',
  GeneralSettingMasterCoaDelete = 'GENERAL_SETTING_MASTER_COA_____________DELETE',
  GeneralSettingBankProfileCreate = 'GENERAL_SETTING_BANK_PROFILE_______CREATE',
  GeneralSettingBankProfileView = 'GENERAL_SETTING_BANK_PROFILE_______VIEW',
  GeneralSettingBankProfileUpdate = 'GENERAL_SETTING_BANK_PROFILE_______UPDATE',
  GeneralSettingBankProfileDelete = 'GENERAL_SETTING_BANK_PROFILE_______DELETE',
  GeneralSettingCurrencySetupCreate = 'GENERAL_SETTING_CURRENCY_SETUP_____________CREATE',
  GeneralSettingCurrencySetupView = 'GENERAL_SETTING_CURRENCY_SETUP_____________VIEW',
  GeneralSettingCurrencySetupUpdate = 'GENERAL_SETTING_CURRENCY_SETUP_____________UPDATE',
  GeneralSettingCurrencySetupDelete = 'GENERAL_SETTING_CURRENCY_SETUP_____________DELETE',
  GeneralSettingCurrencyExchangeCreate = 'GENERAL_SETTING_CURRENCY_EXCHANGE_____________CREATE',
  GeneralSettingCurrencyExchangeView = 'GENERAL_SETTING_CURRENCY_EXCHANGE_____________VIEW',
  GeneralSettingCurrencyExchangeUpdate = 'GENERAL_SETTING_CURRENCY_EXCHANGE_____________UPDATE',
  GeneralSettingCurrencyExchangeDelete = 'GENERAL_SETTING_CURRENCY_EXCHANGE_____________DELETE',
  CompanySettingCompanyProfileCreate = 'COMPANY_SETTING_COMPANY_PROFILE_______CREATE',
  CompanySettingCompanyProfileView = 'COMPANY_SETTING_COMPANY_PROFILE_______VIEW',
  CompanySettingCompanyProfileUpdate = 'COMPANY_SETTING_COMPANY_PROFILE_______UPDATE',
  CompanySettingCompanyProfileDelete = 'COMPANY_SETTING_COMPANY_PROFILE_______DELETE',
  CompanySettingCostCentreDefinitionCreate = 'COMPANY_SETTING_COST_CENTRE_DEFINITION_______CREATE',
  CompanySettingCostCentreDefinitionView = 'COMPANY_SETTING_COST_CENTRE_DEFINITION_______VIEW',
  CompanySettingCostCentreDefinitionUpdate = 'COMPANY_SETTING_COST_CENTRE_DEFINITION_______UPDATE',
  CompanySettingCostCentreDefinitionDelete = 'COMPANY_SETTING_COST_CENTRE_DEFINITION_______DELETE',
  CompanySettingCostCentreCreate = 'COMPANY_SETTING_COST_CENTRE_______CREATE',
  CompanySettingCostCentreView = 'COMPANY_SETTING_COST_CENTRE_______VIEW',
  CompanySettingCostCentreUpdate = 'COMPANY_SETTING_COST_CENTRE_______UPDATE',
  CompanySettingCostCentreDelete = 'COMPANY_SETTING_COST_CENTRE_______DELETE',
  CompanySettingNumberingStructureCreate = 'COMPANY_SETTING_NUMBERING_STRUCTURE_______CREATE',
  CompanySettingNumberingStructureView = 'COMPANY_SETTING_NUMBERING_STRUCTURE_______VIEW',
  CompanySettingNumberingStructureUpdate = 'COMPANY_SETTING_NUMBERING_STRUCTURE_______UPDATE',
  CompanySettingNumberingStructureDelete = 'COMPANY_SETTING_NUMBERING_STRUCTURE_______DELETE',
  CompanySettingEntityCoaCreate = 'COMPANY_SETTING_ENTITY_COA_______CREATE',
  CompanySettingEntityCoaView = 'COMPANY_SETTING_ENTITY_COA_______VIEW',
  CompanySettingEntityCoaUpdate = 'COMPANY_SETTING_ENTITY_COA_______UPDATE',
  CompanySettingEntityCoaDelete = 'COMPANY_SETTING_ENTITY_COA_______DELETE',
  CompanySettingAccountingPeriodCreate = 'COMPANY_SETTING_ACCOUNTING_PERIOD_______CREATE',
  CompanySettingAccountingPeriodView = 'COMPANY_SETTING_ACCOUNTING_PERIOD_______VIEW',
  CompanySettingAccountingPeriodUpdate = 'COMPANY_SETTING_ACCOUNTING_PERIOD_______UPDATE',
  CompanySettingAccountingPeriodDelete = 'COMPANY_SETTING_ACCOUNTING_PERIOD_______DELETE',
  CompanySettingJournalTypeCreate = 'COMPANY_SETTING_JOURNAL_TYPE_______CREATE',
  CompanySettingJournalTypeView = 'COMPANY_SETTING_JOURNAL_TYPE_______VIEW',
  CompanySettingJournalTypeUpdate = 'COMPANY_SETTING_JOURNAL_TYPE_______UPDATE',
  CompanySettingJournalTypeDelete = 'COMPANY_SETTING_JOURNAL_TYPE_______DELETE',
  CompanySettingRetainEarningAccountSettingCreate = 'COMPANY_SETTING_RETAIN_EARNING_ACCOUNT_SETTING_______CREATE',
  CompanySettingRetainEarningAccountSettingView = 'COMPANY_SETTING_RETAIN_EARNING_ACCOUNT_SETTING_______VIEW',
  CompanySettingRetainEarningAccountSettingUpdate = 'COMPANY_SETTING_RETAIN_EARNING_ACCOUNT_SETTING_______UPDATE',
  CompanySettingRetainEarningAccountSettingDelete = 'COMPANY_SETTING_RETAIN_EARNING_ACCOUNT_SETTING_______DELETE',
  CompanySettingBudgetCreate = 'COMPANY_SETTING_BUDGET_______CREATE',
  CompanySettingBudgetView = 'COMPANY_SETTING_BUDGET_______VIEW',
  CompanySettingBudgetUpdate = 'COMPANY_SETTING_BUDGET_______UPDATE',
  CompanySettingBudgetDelete = 'COMPANY_SETTING_BUDGET_______DELETE',
  CompanySettingBankAccountCreate = 'COMPANY_SETTING_BANK_ACCOUNT_______CREATE',
  CompanySettingBankAccountView = 'COMPANY_SETTING_BANK_ACCOUNT_______VIEW',
  CompanySettingBankAccountUpdate = 'COMPANY_SETTING_BANK_ACCOUNT_______UPDATE',
  CompanySettingBankAccountDelete = 'COMPANY_SETTING_BANK_ACCOUNT_______DELETE',
  CompanySettingCompanyAuthorizationCreate = 'COMPANY_SETTING_COMPANY_AUTHORIZATION_______CREATE',
  CompanySettingCompanyAuthorizationView = 'COMPANY_SETTING_COMPANY_AUTHORIZATION_______VIEW',
  CompanySettingCompanyAuthorizationUpdate = 'COMPANY_SETTING_COMPANY_AUTHORIZATION_______UPDATE',
  CompanySettingCompanyAuthorizationDelete = 'COMPANY_SETTING_COMPANY_AUTHORIZATION_______DELETE'
}

export type AccountTaxLedgerEntity = AuditEntity & {
  __typename?: 'AccountTaxLedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
};

export enum AccountType {
  Asset = 'ASSET',
  Liability = 'LIABILITY',
  Equity = 'EQUITY',
  Revenue = 'REVENUE',
  Expense = 'EXPENSE'
}

export enum AcctPermission {
  AccessSecurityIcon = 'ACCESS_SECURITY_______ICON',
  AccessSecurityUserView = 'ACCESS_SECURITY_USER_______VIEW',
  AccessSecurityUserCreate = 'ACCESS_SECURITY_USER_______CREATE',
  AccessSecurityUserUpdate = 'ACCESS_SECURITY_USER_______UPDATE',
  AccessSecurityRoleView = 'ACCESS_SECURITY_ROLE_______VIEW',
  AccessSecurityRoleCreate = 'ACCESS_SECURITY_ROLE_______CREATE',
  AccessSecurityRoleUpdate = 'ACCESS_SECURITY_ROLE_______UPDATE',
  AccessSecurityRoleDelete = 'ACCESS_SECURITY_ROLE_______DELETE',
  GeneralLedgerIcon = 'GENERAL_LEDGER__________ICON',
  GeneralLedgerOriginalBudgetView = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______VIEW',
  GeneralLedgerOriginalBudgetCreate = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______CREATE',
  GeneralLedgerOriginalBudgetUpdate = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______UPDATE',
  GeneralLedgerOriginalBudgetDraft = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______DRAFT',
  GeneralLedgerOriginalBudgetVerified = 'GENERAL_LEDGER_ORIGINAL_BUDGET_______VERIFIED',
  GeneralLedgerBudgetRevisionView = 'GENERAL_LEDGER_BUDGET_REVISION_______VIEW',
  GeneralLedgerBudgetRevisionCreate = 'GENERAL_LEDGER_BUDGET_REVISION_______CREATE',
  GeneralLedgerBudgetRevisionUpdate = 'GENERAL_LEDGER_BUDGET_REVISION_______UPDATE',
  GeneralLedgerBudgetRevisionDraft = 'GENERAL_LEDGER_BUDGET_REVISION_______DRAFT',
  GeneralLedgerBudgetRevisionVerified = 'GENERAL_LEDGER_BUDGET_REVISION_______VERIFIED',
  GeneralLedgerJournalProcessingView = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______VIEW',
  GeneralLedgerJournalProcessingCreate = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______CREATE',
  GeneralLedgerJournalProcessingUpdate = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______UPDATE',
  GeneralLedgerJournalProcessingDraft = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______DRAFT',
  GeneralLedgerJournalProcessingDelete = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______DELETE',
  GeneralLedgerJournalProcessingApproveReject = 'GENERAL_LEDGER_JOURNAL_PROCESSING_______APPROVE_REJECT',
  GeneralLedgerExternalJournalView = 'GENERAL_LEDGER_EXTERNAL_JOURNAL_______VIEW',
  GeneralLedgerExternalJournalImport = 'GENERAL_LEDGER_EXTERNAL_JOURNAL_______IMPORT',
  GeneralLedgerRecurringJournalView = 'GENERAL_LEDGER_RECURRING_JOURNAL_______VIEW',
  GeneralLedgerRecurringJournalCreate = 'GENERAL_LEDGER_RECURRING_JOURNAL_______CREATE',
  GeneralLedgerRecurringJournalUpdate = 'GENERAL_LEDGER_RECURRING_JOURNAL_______UPDATE',
  GeneralLedgerRecurringJournalDraft = 'GENERAL_LEDGER_RECURRING_JOURNAL_______DRAFT',
  GeneralLedgerRecurringJournalDelete = 'GENERAL_LEDGER_RECURRING_JOURNAL_______DELETE',
  GeneralLedgerRecurringJournalCancel = 'GENERAL_LEDGER_RECURRING_JOURNAL_______CANCEL',
  GeneralLedgerRecurringJournalApproveReject = 'GENERAL_LEDGER_RECURRING_JOURNAL_______APPROVE_REJECT',
  GeneralLedgerAuditAdjustmentView = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______VIEW',
  GeneralLedgerAuditAdjustmentCreate = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______CREATE',
  GeneralLedgerAuditAdjustmentUpdate = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______UPDATE',
  GeneralLedgerAuditAdjustmentDraft = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______DRAFT',
  GeneralLedgerAuditAdjustmentDelete = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______DELETE',
  GeneralLedgerAuditAdjustmentApproveReject = 'GENERAL_LEDGER_AUDIT_ADJUSTMENT_______APPROVE_REJECT',
  GeneralLedgerClosingMonthYearEndView = 'GENERAL_LEDGER_CLOSING_MONTH_YEAR_END_______VIEW',
  GeneralLedgerDigitalReportsView = 'GENERAL_LEDGER_DIGITAL_REPORTS_______VIEW',
  GeneralLedgerDigitalReportsGlJournalView = 'GENERAL_LEDGER_DIGITAL_REPORTS_GL_JOURNAL____VIEW',
  GeneralLedgerDigitalReportsTrialBalanceView = 'GENERAL_LEDGER_DIGITAL_REPORTS_TRIAL_BALANCE____VIEW',
  GeneralLedgerDigitalReportsTrialBalanceCostCentreView = 'GENERAL_LEDGER_DIGITAL_REPORTS_TRIAL_BALANCE_COST_CENTRE____VIEW',
  GeneralLedgerDigitalReportsGlJournalCostCentreView = 'GENERAL_LEDGER_DIGITAL_REPORTS_GL_JOURNAL_COST_CENTRE____VIEW',
  GeneralLedgerGeneralSettingsView = 'GENERAL_LEDGER_GENERAL_SETTINGS_______VIEW',
  GeneralLedgerGeneralSettingsAccCodeStructureView = 'GENERAL_LEDGER_GENERAL_SETTINGS_ACC_CODE_STRUCTURE____VIEW',
  GeneralLedgerGeneralSettingsAccCodeStructureCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_ACC_CODE_STRUCTURE____CREATE',
  GeneralLedgerGeneralSettingsMasterCoaView = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____VIEW',
  GeneralLedgerGeneralSettingsMasterCoaCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____CREATE',
  GeneralLedgerGeneralSettingsMasterCoaUpdate = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____UPDATE',
  GeneralLedgerGeneralSettingsMasterCoaAssign = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____ASSIGN',
  GeneralLedgerGeneralSettingsMasterCoaDelete = 'GENERAL_LEDGER_GENERAL_SETTINGS_MASTER_COA____DELETE',
  GeneralLedgerGeneralSettingsCurrencySetupView = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_SETUP____VIEW',
  GeneralLedgerGeneralSettingsCurrencySetupCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_SETUP____CREATE',
  GeneralLedgerGeneralSettingsCurrencySetupUpdate = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_SETUP____UPDATE',
  GeneralLedgerGeneralSettingsCurrencySetupDelete = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_SETUP____DELETE',
  GeneralLedgerGeneralSettingsCurrencyExchangeView = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_EXCHANGE____VIEW',
  GeneralLedgerGeneralSettingsCurrencyExchangeCreate = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_EXCHANGE____CREATE',
  GeneralLedgerGeneralSettingsCurrencyExchangeUpdate = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_EXCHANGE____UPDATE',
  GeneralLedgerGeneralSettingsCurrencyExchangeDelete = 'GENERAL_LEDGER_GENERAL_SETTINGS_CURRENCY_EXCHANGE____DELETE',
  GeneralLedgerCompanySettingsView = 'GENERAL_LEDGER_COMPANY_SETTINGS_______VIEW',
  GeneralLedgerCompanySettingsCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_______CREATE',
  GeneralLedgerCompanySettingsCompanyProfileView = 'GENERAL_LEDGER_COMPANY_SETTINGS_COMPANY_PROFILE____VIEW',
  GeneralLedgerCompanySettingsCompanyProfileUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_COMPANY_PROFILE____UPDATE',
  GeneralLedgerCompanySettingsCostCentreView = 'GENERAL_LEDGER_COMPANY_SETTINGS_COST_CENTRE____VIEW',
  GeneralLedgerCompanySettingsCostCentreCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_COST_CENTRE____CREATE',
  GeneralLedgerCompanySettingsCostCentreUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_COST_CENTRE____UPDATE',
  GeneralLedgerCompanySettingsCostCentreDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_COST_CENTRE____DELETE',
  GeneralLedgerCompanySettingsEntityCoaView = 'GENERAL_LEDGER_COMPANY_SETTINGS_ENTITY_COA____VIEW',
  GeneralLedgerCompanySettingsEntityCoaUnassign = 'GENERAL_LEDGER_COMPANY_SETTINGS_ENTITY_COA____UNASSIGN',
  GeneralLedgerCompanySettingsNumberingStructureView = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE____VIEW',
  GeneralLedgerCompanySettingsNumberingStructureCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE____CREATE',
  GeneralLedgerCompanySettingsNumberingStructureUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE____UPDATE',
  GeneralLedgerCompanySettingsNumberingStructureDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_NUMBERING_STRUCTURE____DELETE',
  GeneralLedgerCompanySettingsAccountingPeriodView = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD____VIEW',
  GeneralLedgerCompanySettingsAccountingPeriodCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD____CREATE',
  GeneralLedgerCompanySettingsAccountingPeriodUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD____UPDATE',
  GeneralLedgerCompanySettingsAccountingPeriodDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_ACCOUNTING_PERIOD____DELETE',
  GeneralLedgerCompanySettingsJournalTypeView = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE____VIEW',
  GeneralLedgerCompanySettingsJournalTypeCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE____CREATE',
  GeneralLedgerCompanySettingsJournalTypeUpdate = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE____UPDATE',
  GeneralLedgerCompanySettingsJournalTypeDelete = 'GENERAL_LEDGER_COMPANY_SETTINGS_JOURNAL_TYPE____DELETE',
  GeneralLedgerCompanySettingsRetainEarningAccView = 'GENERAL_LEDGER_COMPANY_SETTINGS_RETAIN_EARNING_ACC____VIEW',
  GeneralLedgerCompanySettingsRetainEarningAccCreate = 'GENERAL_LEDGER_COMPANY_SETTINGS_RETAIN_EARNING_ACC____CREATE',
  GeneralLedgerCompanySettingsCompanyAuthorizationView = 'GENERAL_LEDGER_COMPANY_SETTINGS_COMPANY_AUTHORIZATION____VIEW',
  CashBookIcon = 'CASH_BOOK__________ICON',
  CashBookPaymentView = 'CASH_BOOK_PAYMENT_______VIEW',
  CashBookPaymentCreate = 'CASH_BOOK_PAYMENT_______CREATE',
  CashBookPaymentUpdate = 'CASH_BOOK_PAYMENT_______UPDATE',
  CashBookPaymentDraft = 'CASH_BOOK_PAYMENT_______DRAFT',
  CashBookPaymentApproveReject = 'CASH_BOOK_PAYMENT_______APPROVE_REJECT',
  CashBookPaymentDelete = 'CASH_BOOK_PAYMENT_______DELETE',
  CashBookReceiptView = 'CASH_BOOK_RECEIPT_______VIEW',
  CashBookReceiptCreate = 'CASH_BOOK_RECEIPT_______CREATE',
  CashBookReceiptUpdate = 'CASH_BOOK_RECEIPT_______UPDATE',
  CashBookReceiptApproveReject = 'CASH_BOOK_RECEIPT_____APPROVE_REJECT',
  CashBookReceiptDraft = 'CASH_BOOK_RECEIPT_____DRAFT',
  CashBookReceiptDelete = 'CASH_BOOK_RECEIPT_____DELETE',
  CashBookBankTransferView = 'CASH_BOOK_BANK_TRANSFER_______VIEW',
  CashBookBankTransferCreate = 'CASH_BOOK_BANK_TRANSFER_______CREATE',
  CashBookBankTransferUpdate = 'CASH_BOOK_BANK_TRANSFER_______UPDATE',
  CashBookBankTransferDraft = 'CASH_BOOK_BANK_TRANSFER_______DRAFT',
  CashBookBankTransferDelete = 'CASH_BOOK_BANK_TRANSFER_______DELETE',
  CashBookBankTransferApproveReject = 'CASH_BOOK_BANK_TRANSFER_______APPROVE_REJECT',
  CashBookExternalLedgerView = 'CASH_BOOK_EXTERNAL_LEDGER_______VIEW',
  CashBookExternalLedgerImport = 'CASH_BOOK_EXTERNAL_LEDGER_______IMPORT',
  CashBookDigitalReportView = 'CASH_BOOK_DIGITAL_REPORT_______VIEW',
  CashBookDigitalReportCashLedgerView = 'CASH_BOOK_DIGITAL_REPORT_CASH_LEDGER____VIEW',
  CashBookDigitalReportCashInOutFlowView = 'CASH_BOOK_DIGITAL_REPORT_CASH_IN_OUT_FLOW____VIEW',
  CashBookGeneralSettingsView = 'CASH_BOOK_GENERAL_SETTINGS_______VIEW',
  CashBookGeneralSettingsBankProfileView = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____VIEW',
  CashBookGeneralSettingsBankProfileCreate = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____CREATE',
  CashBookGeneralSettingsBankProfileUpdate = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____UPDATE',
  CashBookGeneralSettingsBankProfileDelete = 'CASH_BOOK_GENERAL_SETTINGS_BANK_PROFILE____DELETE',
  CashBookGeneralSettingsPolicySettingsView = 'CASH_BOOK_GENERAL_SETTINGS_POLICY_SETTINGS____VIEW',
  CashBookGeneralSettingsPolicySettingsCreate = 'CASH_BOOK_GENERAL_SETTINGS_POLICY_SETTINGS____CREATE',
  CashBookGeneralSettingsTaxSetupView = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____VIEW',
  CashBookGeneralSettingsTaxSetupCreate = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____CREATE',
  CashBookGeneralSettingsTaxSetupUpdate = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____UPDATE',
  CashBookGeneralSettingsTaxSetupInactive = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____INACTIVE',
  CashBookGeneralSettingsTaxSetupDelete = 'CASH_BOOK_GENERAL_SETTINGS_TAX_SETUP____DELETE',
  CashBookGeneralSettingsPaymentMethodView = 'CASH_BOOK_GENERAL_SETTINGS_PAYMENT_METHOD____VIEW',
  CashBookGeneralSettingsPaymentMethodCreate = 'CASH_BOOK_GENERAL_SETTINGS_PAYMENT_METHOD____CREATE',
  CashBookGeneralSettingsPaymentMethodUpdate = 'CASH_BOOK_GENERAL_SETTINGS_PAYMENT_METHOD____UPDATE',
  CashBookGeneralSettingsPaymentMethodDelete = 'CASH_BOOK_GENERAL_SETTINGS_PAYMENT_METHOD____DELETE',
  CashBookGeneralSettingsCreditCardTypeView = 'CASH_BOOK_GENERAL_SETTINGS_CREDIT_CARD_TYPE____VIEW',
  CashBookGeneralSettingsCreditCardTypeCreate = 'CASH_BOOK_GENERAL_SETTINGS_CREDIT_CARD_TYPE____CREATE',
  CashBookGeneralSettingsCreditCardTypeUpdate = 'CASH_BOOK_GENERAL_SETTINGS_CREDIT_CARD_TYPE____UPDATE',
  CashBookGeneralSettingsCreditCardTypeDelete = 'CASH_BOOK_GENERAL_SETTINGS_CREDIT_CARD_TYPE____DELETE',
  CashBookCompanySettingsView = 'CASH_BOOK_COMPANY_SETTINGS_____VIEW',
  CashBookCompanySettingsNumberingStructuresView = 'CASH_BOOK_COMPANY_SETTINGS_NUMBERING_STRUCTURES____VIEW',
  CashBookCompanySettingsNumberingStructuresCreate = 'CASH_BOOK_COMPANY_SETTINGS_NUMBERING_STRUCTURES____CREATE',
  CashBookCompanySettingsNumberingStructuresUpdate = 'CASH_BOOK_COMPANY_SETTINGS_NUMBERING_STRUCTURES____UPDATE',
  CashBookCompanySettingsNumberingStructuresDelete = 'CASH_BOOK_COMPANY_SETTINGS_NUMBERING_STRUCTURES____DELETE',
  CashBookCompanySettingsBankAccountView = 'CASH_BOOK_COMPANY_SETTINGS_BANK_ACCOUNT____VIEW',
  CashBookCompanySettingsBankAccountCreate = 'CASH_BOOK_COMPANY_SETTINGS_BANK_ACCOUNT____CREATE',
  CashBookCompanySettingsBankAccountUpdate = 'CASH_BOOK_COMPANY_SETTINGS_BANK_ACCOUNT____UPDATE',
  CashBookCompanySettingsBankAccountDelete = 'CASH_BOOK_COMPANY_SETTINGS_BANK_ACCOUNT____DELETE',
  AccPayableIcon = 'ACC_PAYABLE__________ICON',
  AccPayableCreditorProfileView = 'ACC_PAYABLE_CREDITOR_PROFILE_______VIEW',
  AccPayableCreditorProfileUpdate = 'ACC_PAYABLE_CREDITOR_PROFILE_______UPDATE',
  AccPayableAdvancesView = 'ACC_PAYABLE_ADVANCES_______VIEW',
  AccPayableAdvancesCreate = 'ACC_PAYABLE_ADVANCES_______CREATE',
  AccPayableAdvancesUpdate = 'ACC_PAYABLE_ADVANCES_______UPDATE',
  AccPayableAdvancesDraft = 'ACC_PAYABLE_ADVANCES_______DRAFT',
  AccPayableAdvancesDelete = 'ACC_PAYABLE_ADVANCES_______DELETE',
  AccPayableAdvancesApproveReject = 'ACC_PAYABLE_ADVANCES_______APPROVE_REJECT',
  AccPayableAdvancesAllocation = 'ACC_PAYABLE_ADVANCES_______ALLOCATION',
  AccPayableInvoicesView = 'ACC_PAYABLE_INVOICES_______VIEW',
  AccPayableInvoicesCreate = 'ACC_PAYABLE_INVOICES_______CREATE',
  AccPayableInvoicesUpdate = 'ACC_PAYABLE_INVOICES_______UPDATE',
  AccPayableInvoicesDraft = 'ACC_PAYABLE_INVOICES_______DRAFT',
  AccPayableInvoicesDelete = 'ACC_PAYABLE_INVOICES_______DELETE',
  AccPayableInvoicesApproveReject = 'ACC_PAYABLE_INVOICES_______APPROVE_REJECT',
  AccPayableCreditNotesView = 'ACC_PAYABLE_CREDIT_NOTES_______VIEW',
  AccPayableCreditNotesCreate = 'ACC_PAYABLE_CREDIT_NOTES_______CREATE',
  AccPayableCreditNotesUpdate = 'ACC_PAYABLE_CREDIT_NOTES_______UPDATE',
  AccPayableCreditNotesAllocation = 'ACC_PAYABLE_CREDIT_NOTES_______ALLOCATION',
  AccPayableCreditNotesDraft = 'ACC_PAYABLE_CREDIT_NOTES_______DRAFT',
  AccPayableCreditNotesDelete = 'ACC_PAYABLE_CREDIT_NOTES_______DELETE',
  AccPayableCreditNotesApproveReject = 'ACC_PAYABLE_CREDIT_NOTES_______APPROVE_REJECT',
  AccPayableCreditorCreditNotesView = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______VIEW',
  AccPayableCreditorCreditNotesCreate = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______CREATE',
  AccPayableCreditorCreditNotesUpdate = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______UPDATE',
  AccPayableCreditorCreditNotesAllocation = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______ALLOCATION',
  AccPayableCreditorCreditNotesDraft = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______DRAFT',
  AccPayableCreditorCreditNotesDelete = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______DELETE',
  AccPayableCreditorCreditNotesApproveReject = 'ACC_PAYABLE_CREDITOR_CREDIT_NOTES_______APPROVE_REJECT',
  AccPayableDebitNotesView = 'ACC_PAYABLE_DEBIT_NOTES_______VIEW',
  AccPayableDebitNotesCreate = 'ACC_PAYABLE_DEBIT_NOTES_______CREATE',
  AccPayableDebitNotesUpdate = 'ACC_PAYABLE_DEBIT_NOTES_______UPDATE',
  AccPayableDebitNotesDraft = 'ACC_PAYABLE_DEBIT_NOTES_______DRAFT',
  AccPayableDebitNotesDelete = 'ACC_PAYABLE_DEBIT_NOTES_______DELETE',
  AccPayableDebitNotesApproveReject = 'ACC_PAYABLE_DEBIT_NOTES_______APPROVE_REJECT',
  AccPayablePaymentView = 'ACC_PAYABLE_PAYMENT_______VIEW',
  AccPayablePaymentCreate = 'ACC_PAYABLE_PAYMENT_______CREATE',
  AccPayablePaymentUpdate = 'ACC_PAYABLE_PAYMENT_______UPDATE',
  AccPayablePaymentAllocation = 'ACC_PAYABLE_PAYMENT_______ALLOCATION',
  AccPayablePaymentDraft = 'ACC_PAYABLE_PAYMENT_______DRAFT',
  AccPayablePaymentDelete = 'ACC_PAYABLE_PAYMENT_______DELETE',
  AccPayablePaymentApproveReject = 'ACC_PAYABLE_PAYMENT_______APPROVE_REJECT',
  AccPayableBatchPaymentView = 'ACC_PAYABLE_BATCH_PAYMENT_______VIEW',
  AccPayableBatchPaymentCreate = 'ACC_PAYABLE_BATCH_PAYMENT_______CREATE',
  AccPayableBatchPaymentUpdate = 'ACC_PAYABLE_BATCH_PAYMENT_______UPDATE',
  AccPayableBatchPaymentDraft = 'ACC_PAYABLE_BATCH_PAYMENT_______DRAFT',
  AccPayableBatchPaymentDelete = 'ACC_PAYABLE_BATCH_PAYMENT_______DELETE',
  AccPayableBatchPaymentPaid = 'ACC_PAYABLE_BATCH_PAYMENT_______PAID',
  AccPayableBatchPaymentApproveReject = 'ACC_PAYABLE_BATCH_PAYMENT_______APPROVE_REJECT',
  AccPayableRefundView = 'ACC_PAYABLE_REFUND_______VIEW',
  AccPayableRefundCreate = 'ACC_PAYABLE_REFUND_______CREATE',
  AccPayableRefundUpdate = 'ACC_PAYABLE_REFUND_______UPDATE',
  AccPayableRefundDraft = 'ACC_PAYABLE_REFUND_______DRAFT',
  AccPayableRefundDelete = 'ACC_PAYABLE_REFUND_______DELETE',
  AccPayableRefundApproveReject = 'ACC_PAYABLE_REFUND_______APPROVE_REJECT',
  AccPayableDigitalReportView = 'ACC_PAYABLE_DIGITAL_REPORT_______VIEW',
  AccPayableDigitalReportOverduePayableView = 'ACC_PAYABLE_DIGITAL_REPORT_OVERDUE_PAYABLE______VIEW',
  AccPayableDigitalReportCreditorLedgerByDocDateView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_LEDGER_BY_DOC_DATE______VIEW',
  AccPayableDigitalReportCreditorAgingByDocDateView = 'ACC_PAYABLE_DIGITAL_REPORT_CREDITOR_AGING_BY_DOC_DATE______VIEW',
  AccPayableGeneralSettingsView = 'ACC_PAYABLE_GENERAL_SETTINGS_____VIEW',
  AccPayableGeneralSettingsCreditorTypeView = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_TYPE____VIEW',
  AccPayableGeneralSettingsCreditorTypeCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_TYPE____CREATE',
  AccPayableGeneralSettingsCreditorTypeUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_TYPE____UPDATE',
  AccPayableGeneralSettingsCreditorTypeDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_TYPE____DELETE',
  AccPayableGeneralSettingsCreditorProfileView = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____VIEW',
  AccPayableGeneralSettingsCreditorProfileCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____CREATE',
  AccPayableGeneralSettingsCreditorProfileAssign = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____ASSIGN',
  AccPayableGeneralSettingsCreditorProfileUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____UPDATE',
  AccPayableGeneralSettingsCreditorProfileInactive = 'ACC_PAYABLE_GENERAL_SETTINGS_CREDITOR_PROFILE____INACTIVE',
  AccPayableGeneralSettingsExpenseClassView = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_CLASS____VIEW',
  AccPayableGeneralSettingsExpenseClassCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_CLASS____CREATE',
  AccPayableGeneralSettingsExpenseClassDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_CLASS____DELETE',
  AccPayableGeneralSettingsExpenseItemView = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_ITEM____VIEW',
  AccPayableGeneralSettingsExpenseItemCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_ITEM____CREATE',
  AccPayableGeneralSettingsExpenseItemUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_ITEM____UPDATE',
  AccPayableGeneralSettingsExpenseItemDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_EXPENSE_ITEM____DELETE',
  AccPayableGeneralSettingsUomSetupView = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_SETUP____VIEW',
  AccPayableGeneralSettingsUomSetupCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_SETUP____CREATE',
  AccPayableGeneralSettingsUomSetupUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_SETUP____UPDATE',
  AccPayableGeneralSettingsUomSetupDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_SETUP____DELETE',
  AccPayableGeneralSettingsUomConversionView = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_CONVERSION____VIEW',
  AccPayableGeneralSettingsUomConversionCreate = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_CONVERSION____CREATE',
  AccPayableGeneralSettingsUomConversionUpdate = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_CONVERSION____UPDATE',
  AccPayableGeneralSettingsUomConversionDelete = 'ACC_PAYABLE_GENERAL_SETTINGS_UOM_CONVERSION____DELETE',
  AccPayableCompanySettingsView = 'ACC_PAYABLE_COMPANY_SETTINGS____VIEW',
  AccPayableCompanySettingsNumberingStructuresView = 'ACC_PAYABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____VIEW',
  AccPayableCompanySettingsNumberingStructuresCreate = 'ACC_PAYABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____CREATE',
  AccPayableCompanySettingsNumberingStructuresUpdate = 'ACC_PAYABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____UPDATE',
  AccPayableCompanySettingsNumberingStructuresDelete = 'ACC_PAYABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____DELETE',
  AccPayableCompanySettingsAgingView = 'ACC_PAYABLE_COMPANY_SETTINGS_AGING____VIEW',
  AccPayableCompanySettingsAgingCreate = 'ACC_PAYABLE_COMPANY_SETTINGS_AGING____CREATE',
  AccReceivableIcon = 'ACC_RECEIVABLE__________ICON',
  AccReceivableDebtorAccView = 'ACC_RECEIVABLE_DEBTOR_ACC_______VIEW',
  AccReceivableDebtorAccCreate = 'ACC_RECEIVABLE_DEBTOR_ACC_______CREATE',
  AccReceivableDebtorAccUpdate = 'ACC_RECEIVABLE_DEBTOR_ACC_______UPDATE',
  AccReceivableInvoicesView = 'ACC_RECEIVABLE_INVOICES_______VIEW',
  AccReceivableInvoicesCreate = 'ACC_RECEIVABLE_INVOICES_______CREATE',
  AccReceivableInvoicesUpdate = 'ACC_RECEIVABLE_INVOICES_______UPDATE',
  AccReceivableInvoicesDraft = 'ACC_RECEIVABLE_INVOICES_______DRAFT',
  AccReceivableInvoicesDelete = 'ACC_RECEIVABLE_INVOICES_______DELETE',
  AccReceivableInvoicesApproveReject = 'ACC_RECEIVABLE_INVOICES_______APPROVE_REJECT',
  AccReceivableCreditNotesView = 'ACC_RECEIVABLE_CREDIT_NOTES_______VIEW',
  AccReceivableCreditNotesCreate = 'ACC_RECEIVABLE_CREDIT_NOTES_______CREATE',
  AccReceivableCreditNotesUpdate = 'ACC_RECEIVABLE_CREDIT_NOTES_______UPDATE',
  AccReceivableCreditNotesDraft = 'ACC_RECEIVABLE_CREDIT_NOTES_______DRAFT',
  AccReceivableCreditNotesDelete = 'ACC_RECEIVABLE_CREDIT_NOTES_______DELETE',
  AccReceivableCreditNotesAllocation = 'ACC_RECEIVABLE_CREDIT_NOTES_______ALLOCATION',
  AccReceivableCreditNotesApproveReject = 'ACC_RECEIVABLE_CREDIT_NOTES_______APPROVE_REJECT',
  AccReceivableDebitNotesView = 'ACC_RECEIVABLE_DEBIT_NOTES_______VIEW',
  AccReceivableDebitNotesCreate = 'ACC_RECEIVABLE_DEBIT_NOTES_______CREATE',
  AccReceivableDebitNotesUpdate = 'ACC_RECEIVABLE_DEBIT_NOTES_______UPDATE',
  AccReceivableDebitNotesDraft = 'ACC_RECEIVABLE_DEBIT_NOTES_______DRAFT',
  AccReceivableDebitNotesDelete = 'ACC_RECEIVABLE_DEBIT_NOTES_______DELETE',
  AccReceivableDebitNotesApproveReject = 'ACC_RECEIVABLE_DEBIT_NOTES_______APPROVE_REJECT',
  AccReceivableReceiptsView = 'ACC_RECEIVABLE_RECEIPTS_______VIEW',
  AccReceivableReceiptsCreate = 'ACC_RECEIVABLE_RECEIPTS_______CREATE',
  AccReceivableReceiptsUpdate = 'ACC_RECEIVABLE_RECEIPTS_______UPDATE',
  AccReceivableReceiptsDraft = 'ACC_RECEIVABLE_RECEIPTS_______DRAFT',
  AccReceivableReceiptsDelete = 'ACC_RECEIVABLE_RECEIPTS_______DELETE',
  AccReceivableReceiptsAllocation = 'ACC_RECEIVABLE_RECEIPTS_______ALLOCATION',
  AccReceivableReceiptsApproveReject = 'ACC_RECEIVABLE_RECEIPTS_______APPROVE_REJECT',
  AccReceivableAdvancesView = 'ACC_RECEIVABLE_ADVANCES_______VIEW',
  AccReceivableAdvancesCreate = 'ACC_RECEIVABLE_ADVANCES_______CREATE',
  AccReceivableAdvancesUpdate = 'ACC_RECEIVABLE_ADVANCES_______UPDATE',
  AccReceivableAdvancesDraft = 'ACC_RECEIVABLE_ADVANCES_______DRAFT',
  AccReceivableAdvancesDelete = 'ACC_RECEIVABLE_ADVANCES_______DELETE',
  AccReceivableAdvancesAllocation = 'ACC_RECEIVABLE_ADVANCES_______ALLOCATION',
  AccReceivableAdvancesApproveReject = 'ACC_RECEIVABLE_ADVANCES_______APPROVE_REJECT',
  AccReceivableRefundView = 'ACC_RECEIVABLE_REFUND_______VIEW',
  AccReceivableRefundCreate = 'ACC_RECEIVABLE_REFUND_______CREATE',
  AccReceivableRefundUpdate = 'ACC_RECEIVABLE_REFUND_______UPDATE',
  AccReceivableRefundDraft = 'ACC_RECEIVABLE_REFUND_______DRAFT',
  AccReceivableRefundDelete = 'ACC_RECEIVABLE_REFUND_______DELETE',
  AccReceivableRefundApproveReject = 'ACC_RECEIVABLE_REFUND_______APPROVE_REJECT',
  AccReceivableDigitalReportsView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_______VIEW',
  AccReceivableDigitalReportsDebtorLedgerByDocDateView = 'ACC_RECEIVABLE_DIGITAL_REPORTS_DEBTOR_LEDGER_BY_DOC_DATE______VIEW',
  AccReceivableGeneralSettingsView = 'ACC_RECEIVABLE_GENERAL_SETTINGS______VIEW',
  AccReceivableGeneralSettingsDebtorTypeView = 'ACC_RECEIVABLE_GENERAL_SETTINGS_DEBTOR_TYPE___VIEW',
  AccReceivableGeneralSettingsDebtorTypeCreate = 'ACC_RECEIVABLE_GENERAL_SETTINGS_DEBTOR_TYPE___CREATE',
  AccReceivableGeneralSettingsDebtorTypeDelete = 'ACC_RECEIVABLE_GENERAL_SETTINGS_DEBTOR_TYPE___DELETE',
  AccReceivableCompanySettingsView = 'ACC_RECEIVABLE_COMPANY_SETTINGS____VIEW',
  AccReceivableCompanySettingsNumberingStructuresView = 'ACC_RECEIVABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES___VIEW',
  AccReceivableCompanySettingsNumberingStructuresCreate = 'ACC_RECEIVABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES___CREATE',
  AccReceivableCompanySettingsNumberingStructuresUpdate = 'ACC_RECEIVABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES___UPDATE',
  AccReceivableCompanySettingsNumberingStructuresDelete = 'ACC_RECEIVABLE_COMPANY_SETTINGS_NUMBERING_STRUCTURES___DELETE',
  AccReceivableCompanySettingsAgingView = 'ACC_RECEIVABLE_COMPANY_SETTINGS_AGING___VIEW',
  AccReceivableCompanySettingsAgingCreate = 'ACC_RECEIVABLE_COMPANY_SETTINGS_AGING___CREATE',
  InventoryControlIcon = 'INVENTORY_CONTROL__________ICON',
  InventoryControlStockReceiptView = 'INVENTORY_CONTROL_STOCK_RECEIPT_______VIEW',
  InventoryControlStockReceiptCreate = 'INVENTORY_CONTROL_STOCK_RECEIPT_______CREATE',
  InventoryControlStockReceiptUpdate = 'INVENTORY_CONTROL_STOCK_RECEIPT_______UPDATE',
  InventoryControlStockReceiptDraft = 'INVENTORY_CONTROL_STOCK_RECEIPT_______DRAFT',
  InventoryControlStockReceiptDelete = 'INVENTORY_CONTROL_STOCK_RECEIPT_______DELETE',
  InventoryControlStockReceiptApproveReject = 'INVENTORY_CONTROL_STOCK_RECEIPT_______APPROVE_REJECT',
  InventoryControlStockIssueView = 'INVENTORY_CONTROL_STOCK_ISSUE_______VIEW',
  InventoryControlStockIssueCreate = 'INVENTORY_CONTROL_STOCK_ISSUE_______CREATE',
  InventoryControlStockIssueUpdate = 'INVENTORY_CONTROL_STOCK_ISSUE_______UPDATE',
  InventoryControlStockIssueDraft = 'INVENTORY_CONTROL_STOCK_ISSUE_______DRAFT',
  InventoryControlStockIssueDelete = 'INVENTORY_CONTROL_STOCK_ISSUE_______DELETE',
  InventoryControlStockIssueApproveReject = 'INVENTORY_CONTROL_STOCK_ISSUE_______APPROVE_REJECT',
  InventoryControlStockTransferView = 'INVENTORY_CONTROL_STOCK_TRANSFER_______VIEW',
  InventoryControlStockTransferCreate = 'INVENTORY_CONTROL_STOCK_TRANSFER_______CREATE',
  InventoryControlStockTransferUpdate = 'INVENTORY_CONTROL_STOCK_TRANSFER_______UPDATE',
  InventoryControlStockTransferDraft = 'INVENTORY_CONTROL_STOCK_TRANSFER_______DRAFT',
  InventoryControlStockTransferDelete = 'INVENTORY_CONTROL_STOCK_TRANSFER_______DELETE',
  InventoryControlStockTransferApproveReject = 'INVENTORY_CONTROL_STOCK_TRANSFER_______APPROVE_REJECT',
  InventoryControlStockAdjustmentView = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______VIEW',
  InventoryControlStockAdjustmentCreate = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______CREATE',
  InventoryControlStockAdjustmentUpdate = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______UPDATE',
  InventoryControlStockAdjustmentDraft = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______DRAFT',
  InventoryControlStockAdjustmentDelete = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______DELETE',
  InventoryControlStockAdjustmentApproveReject = 'INVENTORY_CONTROL_STOCK_ADJUSTMENT_______APPROVE_REJECT',
  InventoryControlStockCostAdjustmentView = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______VIEW',
  InventoryControlStockCostAdjustmentCreate = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______CREATE',
  InventoryControlStockCostAdjustmentUpdate = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______UPDATE',
  InventoryControlStockCostAdjustmentDraft = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______DRAFT',
  InventoryControlStockCostAdjustmentDelete = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______DELETE',
  InventoryControlStockCostAdjustmentApproveReject = 'INVENTORY_CONTROL_STOCK_COST_ADJUSTMENT_______APPROVE_REJECT',
  InventoryControlStockRequisitionView = 'INVENTORY_CONTROL_STOCK_REQUISITION_______VIEW',
  InventoryControlStockRequisitionCreate = 'INVENTORY_CONTROL_STOCK_REQUISITION_______CREATE',
  InventoryControlStockRequisitionUpdate = 'INVENTORY_CONTROL_STOCK_REQUISITION_______UPDATE',
  InventoryControlStockRequisitionDraft = 'INVENTORY_CONTROL_STOCK_REQUISITION_______DRAFT',
  InventoryControlStockRequisitionCancel = 'INVENTORY_CONTROL_STOCK_REQUISITION_______CANCEL',
  InventoryControlStockRequisitionDelete = 'INVENTORY_CONTROL_STOCK_REQUISITION_______DELETE',
  InventoryControlStockRequisitionApproveReject = 'INVENTORY_CONTROL_STOCK_REQUISITION_______APPROVE_REJECT',
  InventoryControlCycleCountingView = 'INVENTORY_CONTROL_CYCLE_COUNTING_______VIEW',
  InventoryControlCycleCountingCreate = 'INVENTORY_CONTROL_CYCLE_COUNTING_______CREATE',
  InventoryControlCycleCountingUpdate = 'INVENTORY_CONTROL_CYCLE_COUNTING_______UPDATE',
  InventoryControlCycleCountingDraft = 'INVENTORY_CONTROL_CYCLE_COUNTING_______DRAFT',
  InventoryControlCycleCountingDelete = 'INVENTORY_CONTROL_CYCLE_COUNTING_______DELETE',
  InventoryControlStockEnquiryView = 'INVENTORY_CONTROL_STOCK_ENQUIRY_______VIEW',
  InventoryControlDigitalReportsView = 'INVENTORY_CONTROL_DIGITAL_REPORTS_____VIEW',
  InventoryControlDigitalReportsStockLedgerByLocationView = 'INVENTORY_CONTROL_DIGITAL_REPORTS_STOCK_LEDGER_BY_LOCATION____VIEW',
  InventoryControlDigitalReportsStockBalanceByLocationView = 'INVENTORY_CONTROL_DIGITAL_REPORTS_STOCK_BALANCE_BY_LOCATION____VIEW',
  GeneralPurchaseIcon = 'GENERAL_PURCHASE__________ICON',
  GeneralPurchaseScheduledPurchasesView = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______VIEW',
  GeneralPurchaseScheduledPurchasesCreate = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______CREATE',
  GeneralPurchaseScheduledPurchasesUpdate = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______UPDATE',
  GeneralPurchaseScheduledPurchasesDelete = 'GENERAL_PURCHASE_SCHEDULED_PURCHASES_______DELETE',
  GeneralPurchaseDirectPoView = 'GENERAL_PURCHASE_DIRECT_PO_______VIEW',
  GeneralPurchaseDirectPoCreate = 'GENERAL_PURCHASE_DIRECT_PO_______CREATE',
  GeneralPurchaseDirectPoUpdate = 'GENERAL_PURCHASE_DIRECT_PO_______UPDATE',
  GeneralPurchaseDirectPoDelete = 'GENERAL_PURCHASE_DIRECT_PO_______DELETE',
  GeneralPurchaseDirectPoCancel = 'GENERAL_PURCHASE_DIRECT_PO_______CANCEL',
  GeneralPurchaseDirectPoApproveReject = 'GENERAL_PURCHASE_DIRECT_PO_______APPROVE_REJECT',
  GeneralPurchasePurchaseRequisitionView = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______VIEW',
  GeneralPurchasePurchaseRequisitionCreate = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______CREATE',
  GeneralPurchasePurchaseRequisitionUpdate = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______UPDATE',
  GeneralPurchasePurchaseRequisitionDelete = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______DELETE',
  GeneralPurchasePurchaseRequisitionCancel = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______CANCEL',
  GeneralPurchasePurchaseRequisitionApproveReject = 'GENERAL_PURCHASE_PURCHASE_REQUISITION_______APPROVE_REJECT',
  GeneralPurchaseRequestForQuotationView = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______VIEW',
  GeneralPurchaseRequestForQuotationCreate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______CREATE',
  GeneralPurchaseRequestForQuotationUpdate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______UPDATE',
  GeneralPurchaseRequestForQuotationDelete = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______DELETE',
  GeneralPurchaseRequestForQuotationApproveReject = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_______APPROVE_REJECT',
  GeneralPurchaseRequestForQuotationRfqInviteesView = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____VIEW',
  GeneralPurchaseRequestForQuotationRfqInviteesCreate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____CREATE',
  GeneralPurchaseRequestForQuotationRfqInviteesUninvite = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____UNINVITE',
  GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqView = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_VIEW',
  GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqCreate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_CREATE',
  GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqUpdate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_UPDATE',
  GeneralPurchaseRequestForQuotationRfqInviteesQuotedRfqDelete = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_DELETE',
  GeneralPurchaseRequestForQuotationEvaluationSelectionView = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_SELECTION____VIEW',
  GeneralPurchaseRequestForQuotationEvaluationSelectionCreate = 'GENERAL_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_SELECTION____CREATE',
  GeneralPurchaseGeneratePoFromPrView = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______VIEW',
  GeneralPurchaseGeneratePoFromPrCreate = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______CREATE',
  GeneralPurchaseGeneratePoFromPrUpdate = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______UPDATE',
  GeneralPurchaseGeneratePoFromPrDelete = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______DELETE',
  GeneralPurchaseGeneratePoFromPrApproveReject = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______APPROVE_REJECT',
  GeneralPurchaseGeneratePoFromPrCancel = 'GENERAL_PURCHASE_GENERATE_PO_FROM_PR_______CANCEL',
  GeneralPurchaseGoodReceiveNoteView = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______VIEW',
  GeneralPurchaseGoodReceiveNoteCreate = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______CREATE',
  GeneralPurchaseGoodReceiveNoteUpdate = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______UPDATE',
  GeneralPurchaseGoodReceiveNoteDelete = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______DELETE',
  GeneralPurchaseGoodReceiveNoteApproveReject = 'GENERAL_PURCHASE_GOOD_RECEIVE_NOTE_______APPROVE_REJECT',
  GeneralPurchaseGoodReturnNoteView = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______VIEW',
  GeneralPurchaseGoodReturnNoteCreate = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______CREATE',
  GeneralPurchaseGoodReturnNoteUpdate = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______UPDATE',
  GeneralPurchaseGoodReturnNoteDelete = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______DELETE',
  GeneralPurchaseGoodReturnNoteApproveReject = 'GENERAL_PURCHASE_GOOD_RETURN_NOTE_______APPROVE_REJECT',
  GeneralPurchaseDigitalReportsView = 'GENERAL_PURCHASE_DIGITAL_REPORTS_______VIEW',
  GeneralPurchaseGeneralSettingsSupplierCategoryView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_CATEGORY____VIEW',
  GeneralPurchaseGeneralSettingsSupplierCategoryCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_CATEGORY____CREATE',
  GeneralPurchaseGeneralSettingsSupplierCategoryUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_CATEGORY____UPDATE',
  GeneralPurchaseGeneralSettingsSupplierCategoryDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_CATEGORY____DELETE',
  GeneralPurchaseGeneralSettingsSupplierProfileView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____VIEW',
  GeneralPurchaseGeneralSettingsSupplierProfileCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____CREATE',
  GeneralPurchaseGeneralSettingsSupplierProfileUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____UPDATE',
  GeneralPurchaseGeneralSettingsSupplierProfileInactive = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____INACTIVE',
  GeneralPurchaseGeneralSettingsSupplierProfileAssign = 'GENERAL_PURCHASE_GENERAL_SETTINGS_SUPPLIER_PROFILE____ASSIGN',
  GeneralPurchaseGeneralSettingsUomSetupView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_SETUP____VIEW',
  GeneralPurchaseGeneralSettingsUomSetupCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_SETUP____CREATE',
  GeneralPurchaseGeneralSettingsUomSetupUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_SETUP____UPDATE',
  GeneralPurchaseGeneralSettingsUomSetupDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_SETUP____DELETE',
  GeneralPurchaseGeneralSettingsUomConversionView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_CONVERSION____VIEW',
  GeneralPurchaseGeneralSettingsUomConversionCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_CONVERSION____CREATE',
  GeneralPurchaseGeneralSettingsUomConversionUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_CONVERSION____UPDATE',
  GeneralPurchaseGeneralSettingsUomConversionDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_UOM_CONVERSION____DELETE',
  GeneralPurchaseGeneralSettingsPurchaseItemCategoryView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM_CATEGORY____VIEW',
  GeneralPurchaseGeneralSettingsPurchaseItemCategoryCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM_CATEGORY____CREATE',
  GeneralPurchaseGeneralSettingsPurchaseItemCategoryUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM_CATEGORY____UPDATE',
  GeneralPurchaseGeneralSettingsPurchaseItemCategoryDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM_CATEGORY____DELETE',
  GeneralPurchaseGeneralSettingsPurchaseItemView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____VIEW',
  GeneralPurchaseGeneralSettingsPurchaseItemCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____CREATE',
  GeneralPurchaseGeneralSettingsPurchaseItemUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____UPDATE',
  GeneralPurchaseGeneralSettingsPurchaseItemDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_PURCHASE_ITEM____DELETE',
  GeneralPurchaseGeneralSettingsBulkPurchasePricingView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_BULK_PURCHASE_PRICING____VIEW',
  GeneralPurchaseGeneralSettingsBulkPurchasePricingCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_BULK_PURCHASE_PRICING____CREATE',
  GeneralPurchaseGeneralSettingsBulkPurchasePricingUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_BULK_PURCHASE_PRICING____UPDATE',
  GeneralPurchaseGeneralSettingsBulkPurchasePricingDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_BULK_PURCHASE_PRICING____DELETE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyView = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____VIEW',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyCreate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____CREATE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyUpdate = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____UPDATE',
  GeneralPurchaseGeneralSettingsStandardApprovalPolicyDelete = 'GENERAL_PURCHASE_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY____DELETE',
  GeneralPurchaseCompanySettingsApprovalPolicyView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY____VIEW',
  GeneralPurchaseCompanySettingsApprovalPolicyCreate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY____CREATE',
  GeneralPurchaseCompanySettingsApprovalPolicyUpdate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_APPROVAL_POLICY____UPDATE',
  GeneralPurchaseCompanySettingsNumberingStructuresView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____VIEW',
  GeneralPurchaseCompanySettingsNumberingStructuresCreate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____CREATE',
  GeneralPurchaseCompanySettingsNumberingStructuresUpdate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____UPDATE',
  GeneralPurchaseCompanySettingsNumberingStructuresDelete = 'GENERAL_PURCHASE_COMPANY_SETTINGS_NUMBERING_STRUCTURES____DELETE',
  GeneralPurchaseCompanySettingsDeliveryLocationView = 'GENERAL_PURCHASE_COMPANY_SETTINGS_DELIVERY_LOCATION____VIEW',
  GeneralPurchaseCompanySettingsDeliveryLocationCreate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_DELIVERY_LOCATION____CREATE',
  GeneralPurchaseCompanySettingsDeliveryLocationUpdate = 'GENERAL_PURCHASE_COMPANY_SETTINGS_DELIVERY_LOCATION____UPDATE',
  GeneralPurchaseCompanySettingsDeliveryLocationDelete = 'GENERAL_PURCHASE_COMPANY_SETTINGS_DELIVERY_LOCATION____DELETE'
}

export type AccuserRoleId = {
  __typename?: 'AccuserRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

/** JsonType */
export type Address = {
  __typename?: 'Address';
  siteName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput = {
  siteName?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AgingDeleteInput = {
  AgingID: Scalars['String'];
};

export type AgingInput = {
  AgingID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Period1: Scalars['Float'];
  Period2: Scalars['Float'];
  Period3: Scalars['Float'];
  Period4: Scalars['Float'];
  Period5: Scalars['Float'];
  Period6: Scalars['Float'];
  AccountType: Scalars['String'];
};

export enum AmountType {
  Opening = 'OPENING',
  YearOpening = 'YEAR_OPENING',
  Closing = 'CLOSING',
  YearClosing = 'YEAR_CLOSING',
  Transaction = 'TRANSACTION'
}

export type ApprovalListJson = {
  __typename?: 'ApprovalListJson';
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  RoleName?: Maybe<Scalars['String']>;
};

export type ApprovalListJsonInput = {
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  RoleName?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyAssignmentDeleteInput = {
  ApprovalPolicyAssignmentID: Scalars['String'];
};

export type ApprovalPolicyAssignmentInput = {
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  upperLimit?: Maybe<Scalars['Float']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  ApprovalList?: Maybe<Array<ApprovalListJsonInput>>;
};

export type ApprovalPolicyDeleteInput = {
  ApprovalPolicyID: Scalars['String'];
};

export type ApprovalPolicyInput = {
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  upperLimit?: Maybe<Scalars['Float']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  ApprovalList?: Maybe<Array<ApprovalListJsonInput>>;
};

export enum ApprovalStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Completed = 'COMPLETED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION',
  PendingClose = 'PENDING_CLOSE',
  PendingCancel = 'PENDING_CANCEL',
  SubmitCancel = 'SUBMIT_CANCEL',
  ApprovedCancel = 'APPROVED_CANCEL',
  RejectedCancel = 'REJECTED_CANCEL',
  CompletedCancel = 'COMPLETED_CANCEL',
  Closed = 'CLOSED',
  New = 'NEW',
  Packed = 'PACKED'
}

export type AttachmentEntity = AuditEntity & {
  __typename?: 'AttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AttachmentID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: AttachmentRefTable;
  refID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
};

/** JsonType */
export type AttachmentInfo = {
  __typename?: 'AttachmentInfo';
  fileURL?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum AttachmentRefTable {
  Contact = 'CONTACT',
  Company = 'COMPANY',
  Ledger = 'LEDGER',
  Project = 'PROJECT'
}

export type AuditAdjustmentEntity = AuditEntity & {
  __typename?: 'AuditAdjustmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  AuditAdjustmentID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  AdjYearOrPeriod: Scalars['String'];
  JournalID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  AuditAdjustmentItem?: Maybe<Array<AuditAdjustmentItemEntity>>;
};

export type AuditAdjustmentItemEntity = AuditEntity & {
  __typename?: 'AuditAdjustmentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  AuditAdjustmentItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  AuditAdjustmentID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  DocAmt?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  Company?: Maybe<CompanyEntity>;
  AuditAdjustment?: Maybe<AuditAdjustmentEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
};

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AzureStorageOutput = {
  __typename?: 'AzureStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type BankAccountDeleteInput = {
  BankAccountID: Scalars['String'];
};

export type BankAccountInput = {
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  BankProfileID: Scalars['String'];
  Code: Scalars['String'];
  AccountNo: Scalars['String'];
  SWIFTCode: Scalars['String'];
  EffectiveDate: Scalars['String'];
  CurrencyID?: Maybe<Scalars['String']>;
  MasterCOAID: Scalars['String'];
  CostCentreID: Scalars['String'];
  Type?: Maybe<BankAccountType>;
};

export enum BankAccountType {
  Cash = 'CASH',
  Bank = 'BANK'
}

export type BankDetails = {
  __typename?: 'BankDetails';
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BankDetailsInput = {
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BankProfileDeleteInput = {
  BankProfileID: Scalars['String'];
};

export type BankProfileEntity = AuditEntity & {
  __typename?: 'BankProfileEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankProfileID: Scalars['String'];
  Name: Scalars['String'];
  Address: Scalars['JSON'];
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  BankAccount?: Maybe<Array<AccBankAccountEntity>>;
};

export type BankProfileInput = {
  BankProfileID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Address: Scalars['JSON'];
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
};

export type BankReconciliationEntity = AuditEntity & {
  __typename?: 'BankReconciliationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankReconciliationID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  BankAccountID: Scalars['String'];
  BankReconciliationMatchID?: Maybe<Scalars['String']>;
  BankAccount?: Maybe<AccBankAccountEntity>;
  DocumentDate?: Maybe<Scalars['String']>;
  Remarks: Scalars['String'];
  IsExcelMatch: Scalars['Boolean'];
  StatementBalance: Scalars['Float'];
  BankReconciliationItem?: Maybe<Array<BankReconciliationItemEntity>>;
  BankReconciliationMatch?: Maybe<BankReconciliationMatchEntity>;
};

export type BankReconciliationItemEntity = AuditEntity & {
  __typename?: 'BankReconciliationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankReconciliationItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  BankReconciliationID: Scalars['String'];
  BankReconciliation?: Maybe<BankReconciliationEntity>;
  BankReconciliationMatchID?: Maybe<Scalars['String']>;
  BankReconciliationMatch?: Maybe<BankReconciliationMatchEntity>;
  DocumentDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  DocNo: Scalars['String'];
  Remarks: Scalars['String'];
  IsMatch: Scalars['Boolean'];
};

export type BankReconciliationMatchEntity = AuditEntity & {
  __typename?: 'BankReconciliationMatchEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BankReconciliationMatchID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  BankReconciliationID?: Maybe<Scalars['String']>;
  Ledger?: Maybe<Array<LedgerEntity>>;
  BankReconciliation?: Maybe<Array<BankReconciliationEntity>>;
  BankReconciliationItem?: Maybe<Array<BankReconciliationItemEntity>>;
};

export type BankTransferEntity = AuditEntity & {
  __typename?: 'BankTransferEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  BankTransferID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  FromBankAccountID?: Maybe<Scalars['String']>;
  ToBankAccountID?: Maybe<Scalars['String']>;
  BankAccount?: Maybe<AccBankAccountEntity>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  TransferDate: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  Amount: Scalars['Float'];
  LedgerID?: Maybe<Scalars['String']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BillItemDeleteInput = {
  BillItemID: Scalars['String'];
};

export type BillItemEntity = AuditEntity & {
  __typename?: 'BillItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BillItemID: Scalars['String'];
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  TaxRate?: Maybe<Scalars['Float']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  ARInvoiceItem?: Maybe<Array<AccArInvoiceItemEntity>>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
};

export type BillItemInput = {
  BillItemID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Description: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  TaxRate?: Maybe<Scalars['Float']>;
  RecordStatus?: Maybe<RecordStatus>;
};

export type BudgetAmt = {
  __typename?: 'BudgetAmt';
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BudgetAmtInput = {
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BudgetEntity = AuditEntity & {
  __typename?: 'BudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  BudgetID: Scalars['String'];
  CompanyID: Scalars['String'];
  Fyear?: Maybe<Scalars['Float']>;
  Version: Scalars['String'];
  IsDefault: Scalars['Boolean'];
  BudgetItem?: Maybe<Array<BudgetItemEntity>>;
  Company?: Maybe<CompanyEntity>;
};

export type BudgetItemCostCentreEntity = AuditEntity & {
  __typename?: 'BudgetItemCostCentreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BudgetItemCostCentreID: Scalars['String'];
  BudgetItemID: Scalars['String'];
  BudgetAmt: Scalars['Float'];
  CostCentreID?: Maybe<Scalars['String']>;
  BudgetItemPeriod?: Maybe<Array<BudgetItemPeriodEntity>>;
  BudgetItem?: Maybe<BudgetItemEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
};

export type BudgetItemEntity = AuditEntity & {
  __typename?: 'BudgetItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BudgetItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  BudgetID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  BudgetAmt: Scalars['Float'];
  BudgetItemCostCentre?: Maybe<Array<BudgetItemCostCentreEntity>>;
  Company?: Maybe<CompanyEntity>;
  Budget?: Maybe<BudgetEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
};

export type BudgetItemPeriodEntity = AuditEntity & {
  __typename?: 'BudgetItemPeriodEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BudgetItemPeriodID: Scalars['String'];
  BudgetItemCostCentreID: Scalars['String'];
  BudgetAmt: Scalars['Float'];
  AccountPeriodID?: Maybe<Scalars['String']>;
  AccountPeriod?: Maybe<AccountPeriodEntity>;
  BudgetItemCostCentre?: Maybe<BudgetItemCostCentreEntity>;
};

export type BulkPurchasePriceDetailEntity = AuditEntity & {
  __typename?: 'BulkPurchasePriceDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BulkPurchasePriceDetailID: Scalars['String'];
  BulkPurchasePriceItemID: Scalars['String'];
  BulkPurchasePriceItem?: Maybe<BulkPurchasePriceItemEntity>;
  LowerLimit: Scalars['Float'];
  UpperLimit: Scalars['Float'];
  UnitPrice: Scalars['Float'];
};

export type BulkPurchasePriceDetailInput = {
  BulkPurchasePriceDetailID?: Maybe<Scalars['String']>;
  BulkPurchasePriceItemID?: Maybe<Scalars['String']>;
  LowerLimit?: Maybe<Scalars['Float']>;
  UpperLimit?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
};

export type BulkPurchasePriceHeaderEntity = AuditEntity & {
  __typename?: 'BulkPurchasePriceHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BulkPurchasePriceHeaderID: Scalars['String'];
  SupplierID: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  EffectiveDate: Scalars['String'];
  ExpiryDate: Scalars['String'];
  BulkPurchasePriceItem?: Maybe<Array<BulkPurchasePriceItemEntity>>;
  Supplier?: Maybe<CreditorAccountEntity>;
};

export type BulkPurchasePriceHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  BulkPurchasePriceHeaderID?: Maybe<Scalars['String']>;
  SupplierID: Scalars['String'];
  EffectiveDate: Scalars['String'];
  ExpiryDate?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type BulkPurchasePriceItemEntity = AuditEntity & {
  __typename?: 'BulkPurchasePriceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  BulkPurchasePriceItemID: Scalars['String'];
  BulkPurchasePriceHeaderID: Scalars['String'];
  BulkPurchasePriceHeader?: Maybe<BulkPurchasePriceHeaderEntity>;
  ItemID: Scalars['String'];
  Item?: Maybe<ItemEntity>;
  UOMID: Scalars['String'];
  BulkPurchasePriceDetail?: Maybe<Array<BulkPurchasePriceDetailEntity>>;
  UOM?: Maybe<AccUomEntity>;
};

export type BulkPurchasePriceItemInput = {
  BulkPurchasePriceItemID?: Maybe<Scalars['String']>;
  BulkPurchasePriceHeaderID?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  ItemID: Scalars['String'];
  BulkPurchaseDetail: Array<BulkPurchasePriceDetailInput>;
};

export type BusinessTypeEntity = {
  __typename?: 'BusinessTypeEntity';
  business_type_id: Scalars['String'];
  name: Scalars['String'];
  account_id: Scalars['String'];
  record_status: RecordStatus;
};

export type CbglExportEntity = AuditEntity & {
  __typename?: 'CBGLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  GLExportID: Scalars['String'];
  BatchNo?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  SourceID?: Maybe<Scalars['String']>;
  Source?: Maybe<Scalars['String']>;
  GLDate: Scalars['String'];
  TransactionDate: Scalars['String'];
  AccountCode?: Maybe<Scalars['String']>;
  Debit?: Maybe<Scalars['Float']>;
  Credit?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type CoaImport = {
  __typename?: 'COAImport';
  createdBy?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  Code?: Maybe<Scalars['String']>;
  ParentCode?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
  IsControl?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['String']>;
  ErrorList?: Maybe<Array<Scalars['String']>>;
};

/** JsonType */
export type CoaImportFinal = {
  __typename?: 'COAImportFinal';
  ErrorData?: Maybe<Array<CoaImport>>;
  ValidatedData?: Maybe<Array<CoaImport>>;
};

export type CoaStructureDeleteInput = {
  COAStructureID: Scalars['String'];
};

export type CoaStructureEntity = AuditEntity & {
  __typename?: 'COAStructureEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  COAStructureID: Scalars['String'];
  Level: Scalars['Float'];
  CodeLength: Scalars['String'];
};

export type CoaStructureInput = {
  COAStructureID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  CodeLength: Scalars['String'];
};

export type CellValuesInput = {
  Coordinate: Scalars['String'];
  Value: Scalars['String'];
};

export type CellValuesJson = {
  __typename?: 'CellValuesJson';
  Coordinate: Scalars['String'];
  Value: Scalars['String'];
};

export type CheckExistingAccountPeriod = {
  __typename?: 'CheckExistingAccountPeriod';
  LastYear?: Maybe<Scalars['Boolean']>;
  LastMonth?: Maybe<Scalars['Boolean']>;
};

export enum ColumnSource {
  Actual = 'ACTUAL',
  Budget = 'BUDGET'
}

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type CompanyDeleteInput = {
  CompanyID: Scalars['String'];
};

export type CompanyEntity = AuditEntity & {
  __typename?: 'CompanyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CompanyID: Scalars['String'];
  ParentCompanyID?: Maybe<Scalars['String']>;
  BaseCurrencyID: Scalars['String'];
  Currency?: Maybe<CurrencyEntity>;
  Name: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['JSON']>;
  ContactNo?: Maybe<Scalars['String']>;
  CompanyRegNo: Scalars['String'];
  CompanyTax: CompanyTax;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  BankTransfer?: Maybe<Array<BankTransferEntity>>;
  CostCentre?: Maybe<Array<CostCentreEntity>>;
  Budget?: Maybe<Array<BudgetEntity>>;
  BudgetItem?: Maybe<Array<BudgetItemEntity>>;
  ExternalJournal?: Maybe<Array<ExternalJournalEntity>>;
  Journal?: Maybe<Array<JournalEntity>>;
  JournalItem?: Maybe<Array<JournalItemEntity>>;
  LedgerItem?: Maybe<Array<LedgerItemEntity>>;
  ReceiptItem?: Maybe<Array<ReceiptItemEntity>>;
  PaymentItem?: Maybe<Array<PaymentItemEntity>>;
  RoutineJournal?: Maybe<Array<RoutineJournalEntity>>;
  RoutineJournalItem?: Maybe<Array<RoutineJournalItemEntity>>;
  RecurringJournal?: Maybe<Array<RecurringJournalEntity>>;
  RecurringJournalItem?: Maybe<Array<RecurringJournalItemEntity>>;
  BankAccount?: Maybe<Array<AccBankAccountEntity>>;
  BankReconciliation?: Maybe<Array<BankReconciliationEntity>>;
  BankReconciliationItem?: Maybe<Array<BankReconciliationItemEntity>>;
  BankReconciliationMatch?: Maybe<Array<BankReconciliationMatchEntity>>;
  Ledger?: Maybe<Array<LedgerEntity>>;
  ExternalLedger?: Maybe<Array<ExternalLedgerEntity>>;
  EntityCOA?: Maybe<Array<EntityCoaEntity>>;
  AccountPeriod?: Maybe<Array<AccountPeriodEntity>>;
  JournalType?: Maybe<Array<JournalTypeEntity>>;
  RetainEarningAccountDefID?: Maybe<Array<RetainEarningAccountDefEntity>>;
  AuditAdjustment?: Maybe<Array<AuditAdjustmentEntity>>;
  AuditAdjustmentItem?: Maybe<Array<AuditAdjustmentItemEntity>>;
  Receipt?: Maybe<Array<ReceiptEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  CostCentreDefinitionAsgmt?: Maybe<Array<CostCentreDefinitionAsgmtEntity>>;
  ApprovalPolicyAssignment?: Maybe<Array<AccApprovalPolicyAssignmentEntity>>;
  JournalProcessing?: Maybe<Array<JournalProcessingEntity>>;
  Aging?: Maybe<Array<ApAgingEntity>>;
  APInvoice?: Maybe<Array<AccApInvoiceEntity>>;
  ARInvoice?: Maybe<Array<AccArInvoiceEntity>>;
  APAdvance?: Maybe<Array<AccApAdvanceEntity>>;
  ARAdvance?: Maybe<Array<AccArAdvanceEntity>>;
  APCreditNote?: Maybe<Array<AccApCreditNoteEntity>>;
  APCreditorCreditNote?: Maybe<Array<AccApCreditorCreditNoteEntity>>;
  ARCreditNote?: Maybe<Array<AccArCreditNoteEntity>>;
  APDebitNote?: Maybe<Array<AccApDebitNoteEntity>>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  ARDebitNote?: Maybe<Array<AccArDebitNoteEntity>>;
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
  APRefund?: Maybe<Array<AccApRefundEntity>>;
  ARRefund?: Maybe<Array<AccArRefundEntity>>;
  APAllocation?: Maybe<Array<AccApAllocationEntity>>;
  APAllocationItem?: Maybe<Array<AccApAllocationItemEntity>>;
  ARCollection?: Maybe<Array<AccArCollectionEntity>>;
  ARReceipt?: Maybe<Array<AccArOfficialReceiptEntity>>;
  ARAllocation?: Maybe<Array<AccArAllocationEntity>>;
  ARAllocationItem?: Maybe<Array<AccArAllocationItemEntity>>;
  APBatchPayment?: Maybe<Array<AccApBatchPaymentEntity>>;
  StockReceipt?: Maybe<Array<StockReceiptEntity>>;
  StockReceiptItem?: Maybe<Array<StockReceiptItemEntity>>;
  StockIssue?: Maybe<Array<StockIssueEntity>>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
  StockTransfer?: Maybe<Array<StockTransferEntity>>;
  StockTransferItem?: Maybe<Array<StockTransferItemEntity>>;
  CycleCount?: Maybe<Array<CycleCountEntity>>;
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  StockAdjustment?: Maybe<Array<StockAdjustmentEntity>>;
  StockAdjustmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
  StockCostAdjustment?: Maybe<Array<StockCostAdjustmentEntity>>;
  StockCostAdjustmentItem?: Maybe<Array<StockCostAdjustmentItemEntity>>;
  StockWeightedAvg?: Maybe<Array<StockWeightedAvgEntity>>;
  Warehouse?: Maybe<Array<WarehouseEntity>>;
  InventoryPolicy?: Maybe<Array<InventoryPolicyEntity>>;
  Location?: Maybe<Array<DeliveryLocationEntity>>;
  DebtorAccount?: Maybe<Array<DebtorAccountEntity>>;
  StockRequisition?: Maybe<Array<StockRequisitionEntity>>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
};

export type CompanyInput = {
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Address?: Maybe<Scalars['JSON']>;
  ContactNo?: Maybe<Scalars['String']>;
  CompanyRegNo: Scalars['String'];
  CompanyTax?: Maybe<CompanyTax>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  BaseCurrencyID: Scalars['String'];
  RecordStatus: Scalars['String'];
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  LogoFiles?: Maybe<Array<Scalars['Upload']>>;
};

export enum CompanyTax {
  NoTax = 'NO_TAX',
  SstNo = 'SST_NO',
  GstNo = 'GST_NO'
}

export enum ContPermission {
  ContractMgmtIcon = 'CONTRACT_MGMT__________ICON',
  ContractMgmtView = 'CONTRACT_MGMT__________VIEW',
  ContractMgmtCreate = 'CONTRACT_MGMT__________CREATE',
  ContractMgmtContractInfoView = 'CONTRACT_MGMT_CONTRACT_INFO_______VIEW',
  ContractMgmtContractInfoUpdate = 'CONTRACT_MGMT_CONTRACT_INFO_______UPDATE',
  ContractMgmtContractGuaranteeView = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______VIEW',
  ContractMgmtContractGuaranteeCreate = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______CREATE',
  ContractMgmtContractGuaranteeUpdate = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______UPDATE',
  ContractMgmtContractGuaranteeCancel = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______CANCEL',
  ContractMgmtContractInsuranceView = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______VIEW',
  ContractMgmtContractInsuranceCreate = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______CREATE',
  ContractMgmtContractInsuranceUpdate = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______UPDATE',
  ContractMgmtContractInsuranceCancel = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______CANCEL',
  ContractMgmtContractVoView = 'CONTRACT_MGMT_CONTRACT_VO_______VIEW',
  ContractMgmtContractVoCreate = 'CONTRACT_MGMT_CONTRACT_VO_______CREATE',
  ContractMgmtContractVoUpdate = 'CONTRACT_MGMT_CONTRACT_VO_______UPDATE',
  ContractMgmtContractVoApproveReject = 'CONTRACT_MGMT_CONTRACT_VO_______APPROVE_REJECT',
  ContractMgmtContractVoHealthIndexView = 'CONTRACT_MGMT_CONTRACT_VO_HEALTH_INDEX_______VIEW',
  ContractMgmtContractAccStatusView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_______VIEW',
  ContractMgmtContractAccStatusClientProfileView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_PROFILE____VIEW',
  ContractMgmtContractAccStatusAdvancesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_ADVANCES____VIEW',
  ContractMgmtContractAccStatusProgressClaimView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_PROGRESS_CLAIM____VIEW',
  ContractMgmtContractAccStatusRetentionClaimsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_RETENTION_CLAIMS____VIEW',
  ContractMgmtContractAccStatusMiscellaneousInvoiceView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_MISCELLANEOUS_INVOICE____VIEW',
  ContractMgmtContractAccStatusDebitNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_DEBIT_NOTES____VIEW',
  ContractMgmtContractAccStatusCreditNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CREDIT_NOTES____VIEW',
  ContractMgmtContractAccStatusReceiptsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_RECEIPTS____VIEW',
  ContractMgmtContractAccStatusRefundsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_REFUNDS____VIEW',
  ContractMgmtContractAccStatusClientDebitNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_DEBIT_NOTES____VIEW',
  ContractMgmtContractAccStatusClientCreditNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_CREDIT_NOTES____VIEW',
  ContractMgmtContractAccStatusOsReceivableView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_OS_RECEIVABLE____VIEW',
  ContractMgmtMiscellaneousExpenseView = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______VIEW',
  ContractMgmtMiscellaneousExpenseCreate = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______CREATE',
  ContractMgmtMiscellaneousExpenseUpdate = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______UPDATE',
  ContractMgmtMiscellaneousExpenseCancel = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______CANCEL',
  ContractMgmtRetentionView = 'CONTRACT_MGMT_RETENTION_______VIEW',
  ContractMgmtRetentionCreate = 'CONTRACT_MGMT_RETENTION_______CREATE',
  ContractMgmtRetentionApproveReject = 'CONTRACT_MGMT_RETENTION_______APPROVE_REJECT',
  ContractMgmtRetentionDelete = 'CONTRACT_MGMT_RETENTION_______DELETE',
  ContractMgmtRetentionUpdate = 'CONTRACT_MGMT_RETENTION_______UPDATE',
  ContractMgmtExtensionOfTimeView = 'CONTRACT_MGMT_EXTENSION_OF_TIME_______VIEW',
  ContractMgmtExtensionOfTimeCreate = 'CONTRACT_MGMT_EXTENSION_OF_TIME_______CREATE',
  ContractMgmtContractClosureCreate = 'CONTRACT_MGMT_CONTRACT_CLOSURE_______CREATE',
  ContractMgmtContractTerminationCreate = 'CONTRACT_MGMT_CONTRACT_TERMINATION_______CREATE',
  ContractMgmtDigitalDocumentsView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_______VIEW',
  ContractMgmtDigitalDocumentsContractInfoView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______VIEW',
  ContractMgmtDigitalDocumentsContractInfoUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______UPLOAD',
  ContractMgmtDigitalDocumentsContractInfoDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractInfoDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______DELETE',
  ContractMgmtDigitalDocumentsContractGuaranteeView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______VIEW',
  ContractMgmtDigitalDocumentsContractGuaranteeUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractGuaranteeDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractGuaranteeDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______DELETE',
  ContractMgmtDigitalDocumentsContractInsuranceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______VIEW',
  ContractMgmtDigitalDocumentsContractInsuranceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractInsuranceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractInsuranceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______DELETE',
  ContractMgmtDigitalDocumentsContractVoView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______VIEW',
  ContractMgmtDigitalDocumentsContractVoUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______UPLOAD',
  ContractMgmtDigitalDocumentsContractVoDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractVoDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______DELETE',
  ContractMgmtDigitalDocumentsContractAdvanceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______VIEW',
  ContractMgmtDigitalDocumentsContractAdvanceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractAdvanceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractAdvanceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______DELETE',
  ContractMgmtDigitalDocumentsContractClaimView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______VIEW',
  ContractMgmtDigitalDocumentsContractClaimUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______UPLOAD',
  ContractMgmtDigitalDocumentsContractClaimDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractClaimDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______DELETE',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______VIEW',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______UPLOAD',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______DELETE',
  ContractMgmtDigitalDocumentsContractDnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______VIEW',
  ContractMgmtDigitalDocumentsContractDnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______UPLOAD',
  ContractMgmtDigitalDocumentsContractDnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractDnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______DELETE',
  ContractMgmtDigitalDocumentsContractCnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______VIEW',
  ContractMgmtDigitalDocumentsContractCnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______UPLOAD',
  ContractMgmtDigitalDocumentsContractCnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractCnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______DELETE',
  ContractMgmtDigitalDocumentsContractReceiptView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______VIEW',
  ContractMgmtDigitalDocumentsContractReceiptUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______UPLOAD',
  ContractMgmtDigitalDocumentsContractReceiptDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractReceiptDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______DELETE',
  ContractMgmtDigitalDocumentsContractRefundView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______VIEW',
  ContractMgmtDigitalDocumentsContractRefundUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRefundDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRefundDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______DELETE',
  ContractMgmtDigitalDocumentsClientDnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______VIEW',
  ContractMgmtDigitalDocumentsClientDnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______UPLOAD',
  ContractMgmtDigitalDocumentsClientDnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsClientDnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______DELETE',
  ContractMgmtDigitalDocumentsClientCnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______VIEW',
  ContractMgmtDigitalDocumentsClientCnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______UPLOAD',
  ContractMgmtDigitalDocumentsClientCnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsClientCnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionCpcView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionCpcUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCpcDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCpcDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionDlpView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionDlpUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionDlpDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionDlpDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionCmgView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionCmgUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCmgDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCmgDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______DELETE',
  ContractMgmtDigitalDocumentsContractExtensionView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______VIEW',
  ContractMgmtDigitalDocumentsContractExtensionUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______UPLOAD',
  ContractMgmtDigitalDocumentsContractExtensionDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractExtensionDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______DELETE',
  ContractMgmtDigitalDocumentsContractClosureView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______VIEW',
  ContractMgmtDigitalDocumentsContractClosureUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractClosureDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractClosureDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______DELETE',
  ContractMgmtDigitalDocumentsContractTerminationView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______VIEW',
  ContractMgmtDigitalDocumentsContractTerminationUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______UPLOAD',
  ContractMgmtDigitalDocumentsContractTerminationDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractTerminationDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______DELETE',
  ContractMgmtGeneralSettingsIcon = 'CONTRACT_MGMT_GENERAL_SETTINGS_______ICON',
  ContractMgmtGeneralSettingsSubcontractTradeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  ContractMgmtGeneralSettingsSubcontractTradeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  ContractMgmtGeneralSettingsSubcontractTradeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  ContractMgmtGeneralSettingsSubcontractTradeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  ContractMgmtGeneralSettingsSubcontractTradeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  ContractMgmtGeneralSettingsSubcontractTradeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ContractMgmtGeneralSettingsSubcontractTradeDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  ContractMgmtGeneralSettingsSubcontractTradeUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  ContractMgmtGeneralSettingsLabourCategoryView = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ContractMgmtGeneralSettingsLabourCategoryCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ContractMgmtGeneralSettingsLabourCategoryUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ContractMgmtGeneralSettingsLabourCategoryDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ContractMgmtGeneralSettingsLabourCategoryInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ContractMgmtGeneralSettingsLabourCategoryActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ContractMgmtGeneralSettingsLabourCategoryDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ContractMgmtGeneralSettingsLabourCategoryUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialView = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ContractMgmtGeneralSettingsBuildingMaterialCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ContractMgmtGeneralSettingsBuildingMaterialUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ContractMgmtGeneralSettingsBuildingMaterialDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ContractMgmtGeneralSettingsBuildingMaterialInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemView = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ContractMgmtGeneralSettingsPmCategoryView = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____VIEW',
  ContractMgmtGeneralSettingsPmCategoryCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____CREATE',
  ContractMgmtGeneralSettingsPmCategoryUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____UPDATE',
  ContractMgmtGeneralSettingsPmCategoryDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____DELETE',
  ContractMgmtGeneralSettingsPmCategoryInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____INACTIVE',
  ContractMgmtGeneralSettingsPmCategoryActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____ACTIVE',
  ContractMgmtGeneralSettingsPmCategoryDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____DOWNLOAD',
  ContractMgmtGeneralSettingsPmCategoryUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____UPLOAD',
  ContractMgmtGeneralSettingsMiscellaneousExpenseView = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  ContractMgmtGeneralSettingsMiscellaneousExpenseCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  ContractMgmtGeneralSettingsMiscellaneousExpenseUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  ContractMgmtGeneralSettingsGuaranteeTypeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____VIEW',
  ContractMgmtGeneralSettingsGuaranteeTypeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____CREATE',
  ContractMgmtGeneralSettingsGuaranteeTypeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____UPDATE',
  ContractMgmtGeneralSettingsGuaranteeTypeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____ACTIVE',
  ContractMgmtGeneralSettingsGuaranteeTypeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____INACTIVE',
  ContractMgmtGeneralSettingsGuaranteeTypeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____DELETE',
  ContractMgmtGeneralSettingsInsuranceTypeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____VIEW',
  ContractMgmtGeneralSettingsInsuranceTypeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____CREATE',
  ContractMgmtGeneralSettingsInsuranceTypeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____UPDATE',
  ContractMgmtGeneralSettingsInsuranceTypeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____DELETE',
  ContractMgmtGeneralSettingsInsuranceTypeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____ACTIVE',
  ContractMgmtGeneralSettingsInsuranceTypeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____INACTIVE',
  ProfitRecognitionIcon = 'PROFIT_RECOGNITION__________ICON',
  ProfitRecognitionView = 'PROFIT_RECOGNITION__________VIEW',
  ProfitRecognitionProfitRecognitionAssignmentView = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_ASSIGNMENT__________VIEW',
  ProfitRecognitionProfitRecognitionAssignmentUpdate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_ASSIGNMENT__________UPDATE',
  ProfitRecognitionNumberingStructureView = 'PROFIT_RECOGNITION_NUMBERING_STRUCTURE__________VIEW',
  ProfitRecognitionNumberingStructureUpdate = 'PROFIT_RECOGNITION_NUMBERING_STRUCTURE__________UPDATE',
  ProfitRecognitionProfitRecognitionGenerationView = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________VIEW',
  ProfitRecognitionProfitRecognitionGenerationCreate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________CREATE',
  ProfitRecognitionProfitRecognitionGenerationUpdate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________UPDATE',
  ProfitRecognitionGeneralSettingsIcon = 'PROFIT_RECOGNITION_GENERAL_SETTINGS__________ICON',
  ClientAcctIcon = 'CLIENT_ACCT__________ICON',
  ClientAcctView = 'CLIENT_ACCT__________VIEW',
  ClientAcctClientProfileView = 'CLIENT_ACCT_CLIENT_PROFILE_______VIEW',
  ClientAcctClientProfileCreate = 'CLIENT_ACCT_CLIENT_PROFILE_______CREATE',
  ClientAcctClientProfileUpdate = 'CLIENT_ACCT_CLIENT_PROFILE_______UPDATE',
  ClientAcctAccTransactionsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_______VIEW',
  ClientAcctAccTransactionsAdvancesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____VIEW',
  ClientAcctAccTransactionsAdvancesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____CREATE',
  ClientAcctAccTransactionsAdvancesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____UPDATE',
  ClientAcctAccTransactionsAdvancesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____APPROVE_REJECT',
  ClientAcctAccTransactionsAdvancesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____CANCEL',
  ClientAcctAccTransactionsAdvancesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____DELETE',
  ClientAcctAccTransactionsProgressClaimsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____VIEW',
  ClientAcctAccTransactionsProgressClaimsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____CREATE',
  ClientAcctAccTransactionsProgressClaimsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____UPDATE',
  ClientAcctAccTransactionsProgressClaimsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____APPROVE_REJECT',
  ClientAcctAccTransactionsProgressClaimsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____CANCEL',
  ClientAcctAccTransactionsProgressClaimsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____DELETE',
  ClientAcctAccTransactionsRetentionClaimsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____VIEW',
  ClientAcctAccTransactionsRetentionClaimsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____CANCEL',
  ClientAcctAccTransactionsRetentionClaimsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____UPDATE',
  ClientAcctAccTransactionsRetentionClaimsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____DELETE',
  ClientAcctAccTransactionsRetentionClaimsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____APPROVE_REJECT',
  ClientAcctAccTransactionsMiscellaneousInvoicesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____VIEW',
  ClientAcctAccTransactionsMiscellaneousInvoicesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____CREATE',
  ClientAcctAccTransactionsMiscellaneousInvoicesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____CANCEL',
  ClientAcctAccTransactionsMiscellaneousInvoicesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____APPROVE_REJECT',
  ClientAcctAccTransactionsMiscellaneousInvoicesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____UPDATE',
  ClientAcctAccTransactionsMiscellaneousInvoicesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____DELETE',
  ClientAcctAccTransactionsDebitNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____VIEW',
  ClientAcctAccTransactionsDebitNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CREATE',
  ClientAcctAccTransactionsDebitNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsDebitNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____UPDATE',
  ClientAcctAccTransactionsDebitNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CANCEL',
  ClientAcctAccTransactionsDebitNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____DELETE',
  ClientAcctAccTransactionsCreditNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____VIEW',
  ClientAcctAccTransactionsCreditNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CREATE',
  ClientAcctAccTransactionsCreditNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____UPDATE',
  ClientAcctAccTransactionsCreditNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsCreditNotesAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____ALLOCATION',
  ClientAcctAccTransactionsCreditNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CANCEL',
  ClientAcctAccTransactionsCreditNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____DELETE',
  ClientAcctAccTransactionsReceiptsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____VIEW',
  ClientAcctAccTransactionsReceiptsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____CREATE',
  ClientAcctAccTransactionsReceiptsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____APPROVE_REJECT',
  ClientAcctAccTransactionsReceiptsAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____ALLOCATION',
  ClientAcctAccTransactionsReceiptsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____UPDATE',
  ClientAcctAccTransactionsReceiptsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____CANCEL',
  ClientAcctAccTransactionsReceiptsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____DELETE',
  ClientAcctAccTransactionsRefundsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____VIEW',
  ClientAcctAccTransactionsRefundsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____CREATE',
  ClientAcctAccTransactionsRefundsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____APPROVE_REJECT',
  ClientAcctAccTransactionsRefundsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____CANCEL',
  ClientAcctAccTransactionsRefundsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____UPDATE',
  ClientAcctAccTransactionsRefundsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____DELETE',
  ClientAcctAccTransactionsClientDebitNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____VIEW',
  ClientAcctAccTransactionsClientDebitNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____CREATE',
  ClientAcctAccTransactionsClientDebitNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsClientDebitNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____UPDATE',
  ClientAcctAccTransactionsClientDebitNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____DELETE',
  ClientAcctAccTransactionsClientDebitNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____CANCEL',
  ClientAcctAccTransactionsClientDebitNotesAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____ALLOCATION',
  ClientAcctAccTransactionsClientCreditNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____VIEW',
  ClientAcctAccTransactionsClientCreditNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____CREATE',
  ClientAcctAccTransactionsClientCreditNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsClientCreditNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____CANCEL',
  ClientAcctAccTransactionsClientCreditNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____UPDATE',
  ClientAcctAccTransactionsClientCreditNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____DELETE',
  ClientAcctAccTransactionsOsReceivableView = 'CLIENT_ACCT_ACC_TRANSACTIONS_OS_RECEIVABLE____VIEW',
  ClientAcctCreditControlView = 'CLIENT_ACCT_CREDIT_CONTROL_______VIEW',
  ClientAcctCreditControlOutstandingCollectionView = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION____VIEW',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityView = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_VIEW',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityCreate = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_CREATE',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityUpdate = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_UPDATE',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityReschedule = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_RESCHEDULE',
  ClientAcctCreditControlCollectionActivityView = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____VIEW',
  ClientAcctCreditControlCollectionActivityUpdate = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____UPDATE',
  ClientAcctCreditControlCollectionActivityReschedule = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____RESCHEDULE',
  ClientAcctCreditControlCollectionActivityCreate = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____CREATE',
  ClientAcctCreditControlPaymentReminderView = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____VIEW',
  ClientAcctCreditControlPaymentReminderDownload = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____DOWNLOAD',
  ClientAcctCreditControlPaymentReminderCreate = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____CREATE',
  ClientAcctStatementAccountsView = 'CLIENT_ACCT_STATEMENT_ACCOUNTS_______VIEW',
  ClientAcctPaymentReminderView = 'CLIENT_ACCT_PAYMENT_REMINDER_______VIEW',
  ClientAcctPaymentReminderPaymentReminderSettingView = 'CLIENT_ACCT_PAYMENT_REMINDER_PAYMENT_REMINDER_SETTING_______VIEW',
  ClientAcctPaymentReminderPaymentReminderSettingCreate = 'CLIENT_ACCT_PAYMENT_REMINDER_PAYMENT_REMINDER_SETTING_______CREATE',
  ClientAcctDigitalReportingView = 'CLIENT_ACCT_DIGITAL_REPORTING_______VIEW',
  ClientAcctDigitalReportingClientLedgerReportView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_LEDGER_REPORT____VIEW',
  ClientAcctDigitalReportingClientAgingDetailByContractView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_CONTRACT____VIEW',
  ClientAcctDigitalReportingClientAgingSummaryView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_SUMMARY____VIEW',
  ClientAcctDigitalReportingClientAgingDetailView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL____VIEW',
  ClientAcctDigitalReportingStatementOfAccountView = 'CLIENT_ACCT_DIGITAL_REPORTING_STATEMENT_OF_ACCOUNT____VIEW',
  ClientAcctGeneralSettingsIcon = 'CLIENT_ACCT_GENERAL_SETTINGS_____ICON',
  SubconTenderIcon = 'SUBCON_TENDER__________ICON',
  SubconTenderView = 'SUBCON_TENDER__________VIEW',
  SubconTenderCreate = 'SUBCON_TENDER__________CREATE',
  SubconTenderUpdate = 'SUBCON_TENDER__________UPDATE',
  SubconTenderDelete = 'SUBCON_TENDER__________DELETE',
  SubconTenderTenderInviteesView = 'SUBCON_TENDER_TENDER_INVITEES_______VIEW',
  SubconTenderTenderInviteesCreate = 'SUBCON_TENDER_TENDER_INVITEES_______CREATE',
  SubconTenderTenderInviteesUninvite = 'SUBCON_TENDER_TENDER_INVITEES_______UNINVITE',
  SubconTenderTenderInviteesTenderSubmissionView = 'SUBCON_TENDER_TENDER_INVITEES_TENDER_SUBMISSION____VIEW',
  SubconTenderTenderInviteesTenderSubmissionCreate = 'SUBCON_TENDER_TENDER_INVITEES_TENDER_SUBMISSION____CREATE',
  SubconTenderEvaluationSelectionCreate = 'SUBCON_TENDER_EVALUATION_SELECTION_______CREATE',
  SubconTenderGeneralSettingsIcon = 'SUBCON_TENDER_GENERAL_SETTINGS_____ICON',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeView = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_VIEW',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeCreate = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_CREATE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeUpdate = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_UPDATE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeDownload = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_DOWNLOAD',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeUpload = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_UPLOAD',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeDelete = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_DELETE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeActive = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_ACTIVE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeInactive = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_INACTIVE',
  SubcontractsIcon = 'SUBCONTRACTS__________ICON',
  SubcontractsView = 'SUBCONTRACTS__________VIEW',
  SubcontractsCreate = 'SUBCONTRACTS__________CREATE',
  SubcontractsSubcontractInfoView = 'SUBCONTRACTS_SUBCONTRACT_INFO_______VIEW',
  SubcontractsSubcontractInfoUpdate = 'SUBCONTRACTS_SUBCONTRACT_INFO_______UPDATE',
  SubcontractsSubcontractGuaranteeView = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______VIEW',
  SubcontractsSubcontractGuaranteeCreate = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______CREATE',
  SubcontractsSubcontractGuaranteeApproveReject = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______APPROVE_REJECT',
  SubcontractsSubcontractGuaranteeUpdate = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______UPDATE',
  SubcontractsSubcontractGuaranteeCancel = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______CANCEL',
  SubcontractsSubcontractInsuranceView = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______VIEW',
  SubcontractsSubcontractInsuranceCreate = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______CREATE',
  SubcontractsSubcontractInsuranceUpdate = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______UPDATE',
  SubcontractsSubcontractInsuranceCancel = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______CANCEL',
  SubcontractsSubcontractInsuranceApproveReject = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______APPROVE_REJECT',
  SubcontractsSubcontractVoView = 'SUBCONTRACTS_SUBCONTRACT_VO_______VIEW',
  SubcontractsSubcontractVoCreate = 'SUBCONTRACTS_SUBCONTRACT_VO_______CREATE',
  SubcontractsSubcontractVoApproveReject = 'SUBCONTRACTS_SUBCONTRACT_VO_______APPROVE_REJECT',
  SubcontractsSubcontractVoUpdate = 'SUBCONTRACTS_SUBCONTRACT_VO_______UPDATE',
  SubcontractsSubcontractVoDelete = 'SUBCONTRACTS_SUBCONTRACT_VO_______DELETE',
  SubcontractsSubcontractAccStatusView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_______VIEW',
  SubcontractsSubcontractAccStatusSubcontractorProfileView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_SUBCONTRACTOR_PROFILE___VIEW',
  SubcontractsSubcontractAccStatusAdvancesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_ADVANCES____VIEW',
  SubcontractsSubcontractAccStatusProgressClaimsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_PROGRESS_CLAIMS____VIEW',
  SubcontractsSubcontractAccStatusRetentionClaimsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_RETENTION_CLAIMS____VIEW',
  SubcontractsSubcontractAccStatusMiscellaneousInvoiceView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_MISCELLANEOUS_INVOICE____VIEW',
  SubcontractsSubcontractAccStatusDebitNotesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_DEBIT_NOTES____VIEW',
  SubcontractsSubcontractAccStatusCreditNotesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_CREDIT_NOTES____VIEW',
  SubcontractsSubcontractAccStatusPaymentsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_PAYMENTS____VIEW',
  SubcontractsSubcontractAccStatusRefundsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_REFUNDS____VIEW',
  SubcontractsSubcontractAccStatusDebitNotesSubconView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_DEBIT_NOTES_SUBCON____VIEW',
  SubcontractsSubcontractAccStatusCreditNotesSubconView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_CREDIT_NOTES_SUBCON____VIEW',
  SubcontractsSubcontractAccStatusOsReceivableView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_OS_RECEIVABLE____VIEW',
  SubcontractsRetentionView = 'SUBCONTRACTS_RETENTION_______VIEW',
  SubcontractsRetentionCreate = 'SUBCONTRACTS_RETENTION_______CREATE',
  SubcontractsRetentionUpdate = 'SUBCONTRACTS_RETENTION_______UPDATE',
  SubcontractsRetentionApproveReject = 'SUBCONTRACTS_RETENTION_______APPROVE_REJECT',
  SubcontractsRetentionDelete = 'SUBCONTRACTS_RETENTION_______DELETE',
  SubcontractsExtensionOfTimeView = 'SUBCONTRACTS_EXTENSION_OF_TIME_______VIEW',
  SubcontractsExtensionOfTimeCreate = 'SUBCONTRACTS_EXTENSION_OF_TIME_______CREATE',
  SubcontractsSubcontractClosureCreate = 'SUBCONTRACTS_SUBCONTRACT_CLOSURE_______CREATE',
  SubcontractsSubcontractTerminationCreate = 'SUBCONTRACTS_SUBCONTRACT_TERMINATION_______CREATE',
  SubcontractsDigitalDocumentView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_______VIEW',
  SubcontractsDigitalDocumentSubcontractInfoView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______VIEW',
  SubcontractsDigitalDocumentSubcontractInfoUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractInfoDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractInfoDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______DELETE',
  SubcontractsDigitalDocumentSubcontractGuaranteeView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______VIEW',
  SubcontractsDigitalDocumentSubcontractGuaranteeUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractGuaranteeDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractGuaranteeDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______DELETE',
  SubcontractsDigitalDocumentSubcontractInsuranceView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______VIEW',
  SubcontractsDigitalDocumentSubcontractInsuranceUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractInsuranceDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractInsuranceDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______DELETE',
  SubcontractsDigitalDocumentSubcontractVoView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______VIEW',
  SubcontractsDigitalDocumentSubcontractVoUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractVoDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractVoDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______DELETE',
  SubcontractsDigitalDocumentSubcontractAdvanceView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______VIEW',
  SubcontractsDigitalDocumentSubcontractAdvanceUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractAdvanceDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractAdvanceDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______DELETE',
  SubcontractsDigitalDocumentSubcontractClaimView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______VIEW',
  SubcontractsDigitalDocumentSubcontractClaimUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractClaimDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractClaimDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______DELETE',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______VIEW',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______DELETE',
  SubcontractsDigitalDocumentSubcontractDnView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______VIEW',
  SubcontractsDigitalDocumentSubcontractDnUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractDnDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractDnDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______DELETE',
  SubcontractsDigitalDocumentSubcontractCnView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______VIEW',
  SubcontractsDigitalDocumentSubcontractCnUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractCnDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractCnDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______DELETE',
  SubcontractsDigitalDocumentSubcontractPaymentView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______VIEW',
  SubcontractsDigitalDocumentSubcontractPaymentUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractPaymentDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractPaymentDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______DELETE',
  SubcontractsDigitalDocumentSubcontractRefundView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______VIEW',
  SubcontractsDigitalDocumentSubcontractRefundUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRefundDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRefundDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______DELETE',
  SubcontractsDigitalDocumentDnToSubconView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______VIEW',
  SubcontractsDigitalDocumentDnToSubconUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______UPLOAD',
  SubcontractsDigitalDocumentDnToSubconDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______DOWNLOAD',
  SubcontractsDigitalDocumentDnToSubconDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______DELETE',
  SubcontractsDigitalDocumentCnToSubconView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______VIEW',
  SubcontractsDigitalDocumentCnToSubconUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______UPLOAD',
  SubcontractsDigitalDocumentCnToSubconDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______DOWNLOAD',
  SubcontractsDigitalDocumentCnToSubconDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionCpcView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionCpcUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCpcDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCpcDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionDlpView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionDlpUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionDlpDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionDlpDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionCmgView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionCmgUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCmgDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCmgDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______DELETE',
  SubcontractsDigitalDocumentSubcontractExtensionView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______VIEW',
  SubcontractsDigitalDocumentSubcontractExtensionUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractExtensionDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractExtensionDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______DELETE',
  SubcontractsDigitalDocumentSubcontractClosureView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______VIEW',
  SubcontractsDigitalDocumentSubcontractClosureUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractClosureDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractClosureDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______DELETE',
  SubcontractsDigitalDocumentSubcontractTerminationView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______VIEW',
  SubcontractsDigitalDocumentSubcontractTerminationUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractTerminationDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractTerminationDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______DELETE',
  SubcontractsGeneralSettingsIcon = 'SUBCONTRACTS_GENERAL_SETTINGS_______ICON',
  SubcontractsGeneralSettingsSubcontractTradeView = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  SubcontractsGeneralSettingsSubcontractTradeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  SubcontractsGeneralSettingsSubcontractTradeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  SubcontractsGeneralSettingsSubcontractTradeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  SubcontractsGeneralSettingsSubcontractTradeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  SubcontractsGeneralSettingsSubcontractTradeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  SubcontractsGeneralSettingsSubcontractTradeDownload = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  SubcontractsGeneralSettingsSubcontractTradeUpload = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  SubcontractsGeneralSettingsGuaranteeTypeView = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____VIEW',
  SubcontractsGeneralSettingsGuaranteeTypeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____CREATE',
  SubcontractsGeneralSettingsGuaranteeTypeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____UPDATE',
  SubcontractsGeneralSettingsGuaranteeTypeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____ACTIVE',
  SubcontractsGeneralSettingsGuaranteeTypeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____INACTIVE',
  SubcontractsGeneralSettingsGuaranteeTypeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____DELETE',
  SubcontractsGeneralSettingsInsuranceTypeView = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____VIEW',
  SubcontractsGeneralSettingsInsuranceTypeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____CREATE',
  SubcontractsGeneralSettingsInsuranceTypeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____UPDATE',
  SubcontractsGeneralSettingsInsuranceTypeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____ACTIVE',
  SubcontractsGeneralSettingsInsuranceTypeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____INACTIVE',
  SubcontractsGeneralSettingsInsuranceTypeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____DELETE',
  SubconAccountIcon = 'SUBCON_ACCOUNT__________ICON',
  SubconAccountView = 'SUBCON_ACCOUNT__________VIEW',
  SubconAccountCreate = 'SUBCON_ACCOUNT__________CREATE',
  SubconAccountSubcontractorProfileView = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______VIEW',
  SubconAccountSubcontractorProfileCreate = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______CREATE',
  SubconAccountSubcontractorProfileUpdate = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______UPDATE',
  SubconAccountAccountTransactionView = 'SUBCON_ACCOUNT_ACCOUNT_TRANSACTION_______VIEW',
  SubconAccountAdvancesView = 'SUBCON_ACCOUNT_ADVANCES_______VIEW',
  SubconAccountAdvancesCreate = 'SUBCON_ACCOUNT_ADVANCES_______CREATE',
  SubconAccountAdvancesCancel = 'SUBCON_ACCOUNT_ADVANCES_______CANCEL',
  SubconAccountAdvancesApproveReject = 'SUBCON_ACCOUNT_ADVANCES_______APPROVE_REJECT',
  SubconAccountAdvancesUpdate = 'SUBCON_ACCOUNT_ADVANCES_______UPDATE',
  SubconAccountAdvancesDelete = 'SUBCON_ACCOUNT_ADVANCES_______DELETE',
  SubconAccountProgressClaimsView = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______VIEW',
  SubconAccountProgressClaimsCreate = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______CREATE',
  SubconAccountProgressClaimsUpdate = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______UPDATE',
  SubconAccountProgressClaimsApproveReject = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______APPROVE_REJECT',
  SubconAccountProgressClaimsCancel = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______CANCEL',
  SubconAccountProgressClaimsDelete = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______DELETE',
  SubconAccountRetentionClaimsView = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______VIEW',
  SubconAccountRetentionClaimsCreate = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______CREATE',
  SubconAccountRetentionClaimsUpdate = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______UPDATE',
  SubconAccountRetentionClaimsCancel = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______CANCEL',
  SubconAccountRetentionClaimsApproveReject = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______APPROVE_REJECT',
  SubconAccountRetentionClaimsDelete = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______DELETE',
  SubconAccountMiscellaneousInvoiceView = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______VIEW',
  SubconAccountMiscellaneousInvoiceCreate = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______CREATE',
  SubconAccountMiscellaneousInvoiceUpdate = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______UPDATE',
  SubconAccountMiscellaneousInvoiceCancel = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______CANCEL',
  SubconAccountMiscellaneousInvoiceApproveReject = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______APPROVE_REJECT',
  SubconAccountMiscellaneousInvoiceDelete = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______DELETE',
  SubconAccountDebitNotesView = 'SUBCON_ACCOUNT_DEBIT_NOTES_______VIEW',
  SubconAccountDebitNotesCreate = 'SUBCON_ACCOUNT_DEBIT_NOTES_______CREATE',
  SubconAccountDebitNotesUpdate = 'SUBCON_ACCOUNT_DEBIT_NOTES_______UPDATE',
  SubconAccountDebitNotesDelete = 'SUBCON_ACCOUNT_DEBIT_NOTES_______DELETE',
  SubconAccountDebitNotesCancel = 'SUBCON_ACCOUNT_DEBIT_NOTES_______CANCEL',
  SubconAccountDebitNotesApproveReject = 'SUBCON_ACCOUNT_DEBIT_NOTES_______APPROVE_REJECT',
  SubconAccountCreditNotesView = 'SUBCON_ACCOUNT_CREDIT_NOTES_______VIEW',
  SubconAccountCreditNotesCreate = 'SUBCON_ACCOUNT_CREDIT_NOTES_______CREATE',
  SubconAccountCreditNotesUpdate = 'SUBCON_ACCOUNT_CREDIT_NOTES_______UPDATE',
  SubconAccountCreditNotesApproveReject = 'SUBCON_ACCOUNT_CREDIT_NOTES_______APPROVE_REJECT',
  SubconAccountCreditNotesAllocation = 'SUBCON_ACCOUNT_CREDIT_NOTES_______ALLOCATION',
  SubconAccountCreditNotesCancel = 'SUBCON_ACCOUNT_CREDIT_NOTES_______CANCEL',
  SubconAccountCreditNotesDelete = 'SUBCON_ACCOUNT_CREDIT_NOTES_______DELETE',
  SubconAccountPaymentView = 'SUBCON_ACCOUNT_PAYMENT_______VIEW',
  SubconAccountPaymentCreate = 'SUBCON_ACCOUNT_PAYMENT_______CREATE',
  SubconAccountPaymentCancel = 'SUBCON_ACCOUNT_PAYMENT_______CANCEL',
  SubconAccountPaymentUpdate = 'SUBCON_ACCOUNT_PAYMENT_______UPDATE',
  SubconAccountPaymentApproveReject = 'SUBCON_ACCOUNT_PAYMENT_______APPROVE_REJECT',
  SubconAccountPaymentDelete = 'SUBCON_ACCOUNT_PAYMENT_______DELETE',
  SubconAccountRefundsView = 'SUBCON_ACCOUNT_REFUNDS_______VIEW',
  SubconAccountRefundsCreate = 'SUBCON_ACCOUNT_REFUNDS_______CREATE',
  SubconAccountRefundsApproveReject = 'SUBCON_ACCOUNT_REFUNDS_______APPROVE_REJECT',
  SubconAccountRefundsCancel = 'SUBCON_ACCOUNT_REFUNDS_______CANCEL',
  SubconAccountRefundsUpdate = 'SUBCON_ACCOUNT_REFUNDS_______UPDATE',
  SubconAccountRefundsDelete = 'SUBCON_ACCOUNT_REFUNDS_______DELETE',
  SubconAccountRefundsAllocation = 'SUBCON_ACCOUNT_REFUNDS_______ALLOCATION',
  SubconAccountCnSubconView = 'SUBCON_ACCOUNT_CN_SUBCON_______VIEW',
  SubconAccountCnSubconCreate = 'SUBCON_ACCOUNT_CN_SUBCON_______CREATE',
  SubconAccountCnSubconCancel = 'SUBCON_ACCOUNT_CN_SUBCON_______CANCEL',
  SubconAccountCnSubconUpdate = 'SUBCON_ACCOUNT_CN_SUBCON______UPDATE',
  SubconAccountCnSubconApproveReject = 'SUBCON_ACCOUNT_CN_SUBCON______APPROVE_REJECT',
  SubconAccountCnSubconDelete = 'SUBCON_ACCOUNT_CN_SUBCON______DELETE',
  SubconAccountDnSubconView = 'SUBCON_ACCOUNT_DN_SUBCON_______VIEW',
  SubconAccountDnSubconCreate = 'SUBCON_ACCOUNT_DN_SUBCON_______CREATE',
  SubconAccountDnSubconCancel = 'SUBCON_ACCOUNT_DN_SUBCON_______CANCEL',
  SubconAccountDnSubconUpdate = 'SUBCON_ACCOUNT_DN_SUBCON______UPDATE',
  SubconAccountDnSubconApproveReject = 'SUBCON_ACCOUNT_DN_SUBCON______APPROVE_REJECT',
  SubconAccountDnSubconDelete = 'SUBCON_ACCOUNT_DN_SUBCON______DELETE',
  SubconAccountDnSubconAllocation = 'SUBCON_ACCOUNT_DN_SUBCON______ALLOCATION',
  SubconAccountDigitalReportingView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING______VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerReportView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_REPORT______VIEW',
  SubconAccountDigitalReportingSubcontractorAgingDetailByProjectView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_BY_PROJECT______VIEW',
  SubconAccountDigitalReportingSubcontractorAgingDetailReportByTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_REPORT_BY_TRX_DATE______VIEW',
  SubconAccountDigitalReportingSubcontractorAgingSummaryReportByTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_SUMMARY_REPORT_BY_TRX_DATE______VIEW',
  SubconAccountDigitalReportingSubcontractorDetailBreakdownReportView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_DETAIL_BREAKDOWN_REPORT______VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerReportByProjectView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_REPORT_BY_PROJECT______VIEW',
  SubconAccountDigitalReportingSubcontractorControlSummaryReportView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_CONTROL_SUMMARY_REPORT______VIEW',
  SubconAccountOsReceivableView = 'SUBCON_ACCOUNT_OS_RECEIVABLE_______VIEW',
  SubconAccountGeneralSettingsIcon = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_______ICON',
  SubconAccountGeneralSettingsSubcontractTradeView = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  SubconAccountGeneralSettingsSubcontractTradeUpdate = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  SubconAccountGeneralSettingsSubcontractTradeCreate = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  SubconAccountGeneralSettingsSubcontractTradeDownload = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  SubconAccountGeneralSettingsSubcontractTradeUpload = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  SubconAccountGeneralSettingsSubcontractTradeDelete = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  SubconAccountGeneralSettingsSubcontractTradeInactive = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  SubconAccountGeneralSettingsSubcontractTradeActive = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ProjectPurchaseIcon = 'PROJECT_PURCHASE__________ICON',
  ProjectPurchaseContractView = 'PROJECT_PURCHASE_CONTRACT_______VIEW',
  ProjectPurchaseNegotiatedSupplyView = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______VIEW',
  ProjectPurchaseNegotiatedSupplyCreate = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______CREATE',
  ProjectPurchaseNegotiatedSupplyUpdate = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______UPDATE',
  ProjectPurchaseNegotiatedSupplyCancel = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______CANCEL',
  ProjectPurchasePurchaseRequisitionView = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______VIEW',
  ProjectPurchasePurchaseRequisitionCreate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______CREATE',
  ProjectPurchasePurchaseRequisitionUpdate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______UPDATE',
  ProjectPurchasePurchaseRequisitionCancel = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______CANCEL',
  ProjectPurchasePurchaseRequisitionApproveReject = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______APPROVE_REJECT',
  ProjectPurchasePurchaseRequisitionDraft = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______DRAFT',
  ProjectPurchaseRequestForQuotationView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______VIEW',
  ProjectPurchaseRequestForQuotationCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______CREATE',
  ProjectPurchaseRequestForQuotationUpdate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______UPDATE',
  ProjectPurchaseRequestForQuotationApproveReject = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______APPROVE_REJECT',
  ProjectPurchaseRequestForQuotationDelete = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______DELETE',
  ProjectPurchaseRequestForQuotationRfqInviteesView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____VIEW',
  ProjectPurchaseRequestForQuotationRfqInviteesCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____CREATE',
  ProjectPurchaseRequestForQuotationRfqInviteesUninvite = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____UNINVITE',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_VIEW',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_CREATE',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqUpdate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_UPDATE',
  ProjectPurchaseRequestForQuotationEvaluationAndSelectionView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_AND_SELECTION____VIEW',
  ProjectPurchaseRequestForQuotationEvaluationAndSelectionCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_AND_SELECTION____CREATE',
  ProjectPurchasePurchaseOrderView = 'PROJECT_PURCHASE_PURCHASE_ORDER_______VIEW',
  ProjectPurchasePurchaseOrderCreate = 'PROJECT_PURCHASE_PURCHASE_ORDER_______CREATE',
  ProjectPurchasePurchaseOrderUpdate = 'PROJECT_PURCHASE_PURCHASE_ORDER_______UPDATE',
  ProjectPurchasePurchaseOrderApproveReject = 'PROJECT_PURCHASE_PURCHASE_ORDER_______APPROVE_REJECT',
  ProjectPurchasePurchaseOrderClose = 'PROJECT_PURCHASE_PURCHASE_ORDER_______CLOSE',
  ProjectPurchasePurchaseRequisitionBehalfView = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______VIEW',
  ProjectPurchasePurchaseRequisitionBehalfCreate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______CREATE',
  ProjectPurchasePurchaseRequisitionBehalfDraft = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______DRAFT',
  ProjectPurchasePurchaseRequisitionBehalfUpdate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______UPDATE',
  ProjectPurchasePurchaseRequisitionBehalfCancel = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______CANCEL',
  ProjectPurchasePurchaseRequisitionBehalfApproveReject = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______APPROVE_REJECT',
  ProjectPurchasePurchaseOrderBehalfView = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______VIEW',
  ProjectPurchasePurchaseOrderBehalfUpdate = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______UPDATE',
  ProjectPurchasePurchaseOrderBehalfCreate = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______CREATE',
  ProjectPurchasePurchaseOrderBehalfApproveReject = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______APPROVE_REJECT',
  ProjectPurchasePurchaseOrderBehalfClose = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______CLOSE',
  ProjectPurchaseDeliveryOrderView = 'PROJECT_PURCHASE_DELIVERY_ORDER_______VIEW',
  ProjectPurchaseDeliveryOrderCreate = 'PROJECT_PURCHASE_DELIVERY_ORDER_______CREATE',
  ProjectPurchaseDeliveryOrderUpdate = 'PROJECT_PURCHASE_DELIVERY_ORDER_______UPDATE',
  ProjectPurchaseDeliveryOrderDelete = 'PROJECT_PURCHASE_DELIVERY_ORDER_______DELETE',
  ProjectPurchaseDeliveryOrderApproveReject = 'PROJECT_PURCHASE_DELIVERY_ORDER_______APPROVE_REJECT',
  ProjectPurchaseGoodReturnNoteView = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______VIEW',
  ProjectPurchaseGoodReturnNoteCreate = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______CREATE',
  ProjectPurchaseGoodReturnNoteUpdate = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______UPDATE',
  ProjectPurchaseGoodReturnNoteDelete = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______DELETE',
  ProjectPurchaseGoodReturnNoteApproveReject = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______APPROVE_REJECT',
  ProjectPurchaseDigitalReportingView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_______VIEW',
  ProjectPurchaseDigitalReportingFiveWaysMatchReportingView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_FIVE_WAYS_MATCH_REPORTING_______VIEW',
  ProjectPurchaseDigitalReportingLateDeliveryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_LATE_DELIVERY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingOutstandingDeliveryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_OUTSTANDING_DELIVERY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingPricingHistoryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PRICING_HISTORY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingProjectPurchaseAnalysisReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PROJECT_PURCHASE_ANALYSIS_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseAnalysisByItemView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_ITEM_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseAnalysisBySupplierView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_SUPPLIER_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportByProjectView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_BY_PROJECT_______VIEW',
  ProjectPurchaseGeneralSettingsIcon = 'PROJECT_PURCHASE_GENERAL_SETTINGS_______ICON',
  ProjectPurchaseGeneralSettingsBuildingMaterialView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ProjectPurchaseGeneralSettingsBuildingMaterialCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ProjectPurchaseGeneralSettingsBuildingMaterialDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ProjectPurchaseGeneralSettingsBuildingMaterialUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ProjectPurchaseGeneralSettingsBuildingMaterialInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ProjectPurchaseGeneralSettingsLabourCategoryView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ProjectPurchaseGeneralSettingsLabourCategoryCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ProjectPurchaseGeneralSettingsLabourCategoryUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ProjectPurchaseGeneralSettingsLabourCategoryDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ProjectPurchaseGeneralSettingsLabourCategoryUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ProjectPurchaseGeneralSettingsLabourCategoryDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ProjectPurchaseGeneralSettingsLabourCategoryInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ProjectPurchaseGeneralSettingsLabourCategoryActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  ProjectPurchaseGeneralSettingsPMCategoryView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____VIEW',
  ProjectPurchaseGeneralSettingsPMCategoryCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____CREATE',
  ProjectPurchaseGeneralSettingsPMCategoryUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____UPDATE',
  ProjectPurchaseGeneralSettingsPMCategoryDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____DELETE',
  ProjectPurchaseGeneralSettingsPMCategoryDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____DOWNLOAD',
  ProjectPurchaseGeneralSettingsPMCategoryUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____UPLOAD',
  ProjectPurchaseGeneralSettingsPMCategoryActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____ACTIVE',
  ProjectPurchaseGeneralSettingsPMCategoryInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____INACTIVE',
  SupplierAcctIcon = 'SUPPLIER_ACCT__________ICON',
  SupplierAcctView = 'SUPPLIER_ACCT__________VIEW',
  SupplierAcctCreate = 'SUPPLIER_ACCT__________CREATE',
  SupplierAcctSupplierProfileView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______VIEW',
  SupplierAcctSupplierProfileCreate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______CREATE',
  SupplierAcctSupplierProfileUpdate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______UPDATE',
  SupplierAcctSupplierProfileDelete = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______DELETE',
  SupplierAcctSupplierProfilePreferredSupplierProductsCreate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______CREATE',
  SupplierAcctSupplierProfilePreferredSupplierProductsView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______VIEW',
  SupplierAcctSupplierProfilePreferredSupplierProductsUpdate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______UPDATE',
  SupplierAcctSupplierProfileOsPayableView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_OS_PAYABLE_______VIEW',
  SupplierAcctPreferredSupplierProductsCreate = 'SUPPLIER_ACCT_PREFERRED_SUPPLIER_PRODUCTS_______CREATE',
  SupplierAcctPreferredSupplierProductsUpdate = 'SUPPLIER_ACCT_PREFERRED_SUPPLIER_PRODUCTS_______UPDATE',
  SupplierAcctAccTransactionsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_______VIEW',
  SupplierAcctAdvanceDepositsView = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____VIEW',
  SupplierAcctAdvanceDepositsCreate = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____CREATE',
  SupplierAcctAdvanceDepositsUpdate = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____UPDATE',
  SupplierAcctAdvanceDepositsCancel = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____CANCEL',
  SupplierAcctAdvanceDepositsApproveReject = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____APPROVE_REJECT',
  SupplierAcctAdvanceDepositsDelete = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____DELETE',
  SupplierAcctAdvanceDepositsAllocation = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____ALLOCATION',
  SupplierAcctAccTransactionsDepositsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____VIEW',
  SupplierAcctAccTransactionsDepositsCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____CREATE',
  SupplierAcctAccTransactionsDepositsUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____UPDATE',
  SupplierAcctAccTransactionsDepositsApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____APPROVE_REJECT',
  SupplierAcctAccTransactionsDepositsCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____CANCEL',
  SupplierAcctAccTransactionsDepositsAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____ALLOCATION',
  SupplierAcctAccTransactionsDepositsDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____DELETE',
  SupplierAcctInvoicesView = 'SUPPLIER_ACCT_INVOICES____VIEW',
  SupplierAcctInvoicesCreate = 'SUPPLIER_ACCT_INVOICES____CREATE',
  SupplierAcctInvoicesUpdate = 'SUPPLIER_ACCT_INVOICES____UPDATE',
  SupplierAcctInvoicesApproveReject = 'SUPPLIER_ACCT_INVOICES____APPROVE_REJECT',
  SupplierAcctInvoicesCancel = 'SUPPLIER_ACCT_INVOICES____CANCEL',
  SupplierAcctInvoicesDelete = 'SUPPLIER_ACCT_INVOICES____DELETE',
  SupplierAcctAccTransactionsInvoicesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____VIEW',
  SupplierAcctAccTransactionsInvoicesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____CREATE',
  SupplierAcctAccTransactionsInvoicesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____UPDATE',
  SupplierAcctAccTransactionsInvoicesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____APPROVE_REJECT',
  SupplierAcctAccTransactionsInvoicesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____CANCEL',
  SupplierAcctAccTransactionsInvoicesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____DELETE',
  SupplierAcctDebitNotesView = 'SUPPLIER_ACCT_DEBIT_NOTES____VIEW',
  SupplierAcctDebitNotesCreate = 'SUPPLIER_ACCT_DEBIT_NOTES____CREATE',
  SupplierAcctDebitNotesUpdate = 'SUPPLIER_ACCT_DEBIT_NOTES____UPDATE',
  SupplierAcctDebitNotesApproveReject = 'SUPPLIER_ACCT_DEBIT_NOTES____APPROVE_REJECT',
  SupplierAcctDebitNotesCancel = 'SUPPLIER_ACCT_DEBIT_NOTES____CANCEL',
  SupplierAcctDebitNotesDelete = 'SUPPLIER_ACCT_DEBIT_NOTES____DELETE',
  SupplierAcctAccTransactionsDebitNotesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____VIEW',
  SupplierAcctAccTransactionsDebitNotesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CREATE',
  SupplierAcctAccTransactionsDebitNotesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____UPDATE',
  SupplierAcctAccTransactionsDebitNotesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____APPROVE_REJECT',
  SupplierAcctAccTransactionsDebitNotesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CANCEL',
  SupplierAcctAccTransactionsDebitNotesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____DELETE',
  SupplierAcctCreditNotesView = 'SUPPLIER_ACCT_CREDIT_NOTES____VIEW',
  SupplierAcctCreditNotesCreate = 'SUPPLIER_ACCT_CREDIT_NOTES____CREATE',
  SupplierAcctCreditNotesUpdate = 'SUPPLIER_ACCT_CREDIT_NOTES____UPDATE',
  SupplierAcctCreditNotesApproveReject = 'SUPPLIER_ACCT_CREDIT_NOTES____APPROVE_REJECT',
  SupplierAcctCreditNotesCancel = 'SUPPLIER_ACCT_CREDIT_NOTES____CANCEL',
  SupplierAcctCreditNotesDelete = 'SUPPLIER_ACCT_CREDIT_NOTES____DELETE',
  SupplierAcctCreditNotesAllocation = 'SUPPLIER_ACCT_CREDIT_NOTES____ALLOCATION',
  SupplierAcctAccTransactionsCreditNotesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____VIEW',
  SupplierAcctAccTransactionsCreditNotesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CREATE',
  SupplierAcctAccTransactionsCreditNotesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____UPDATE',
  SupplierAcctAccTransactionsCreditNotesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____APPROVE_REJECT',
  SupplierAcctAccTransactionsCreditNotesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CANCEL',
  SupplierAcctAccTransactionsCreditNotesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____DELETE',
  SupplierAcctAccTransactionsCreditNotesAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____ALLOCATION',
  SupplierAcctPaymentsView = 'SUPPLIER_ACCT_PAYMENTS____VIEW',
  SupplierAcctPaymentsCreate = 'SUPPLIER_ACCT_PAYMENTS____CREATE',
  SupplierAcctPaymentsUpdate = 'SUPPLIER_ACCT_PAYMENTS____UPDATE',
  SupplierAcctPaymentsApproveReject = 'SUPPLIER_ACCT_PAYMENTS____APPROVE_REJECT',
  SupplierAcctPaymentsCancel = 'SUPPLIER_ACCT_PAYMENTS____CANCEL',
  SupplierAcctPaymentsDelete = 'SUPPLIER_ACCT_PAYMENTS____DELETE',
  SupplierAcctAccTransactionsPaymentsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____VIEW',
  SupplierAcctAccTransactionsPaymentsCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____CREATE',
  SupplierAcctAccTransactionsPaymentsUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____UPDATE',
  SupplierAcctAccTransactionsPaymentsApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____APPROVE_REJECT',
  SupplierAcctAccTransactionsPaymentsCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____CANCEL',
  SupplierAcctAccTransactionsPaymentsDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____DELETE',
  SupplierAcctDnSupplierCreate = 'SUPPLIER_ACCT_DN_SUPPLIER____CREATE',
  SupplierAcctDnSupplierView = 'SUPPLIER_ACCT_DN_SUPPLIER____VIEW',
  SupplierAcctDnSupplierUpdate = 'SUPPLIER_ACCT_DN_SUPPLIER____UPDATE',
  SupplierAcctDnSupplierApproveReject = 'SUPPLIER_ACCT_DN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctDnSupplierCancel = 'SUPPLIER_ACCT_DN_SUPPLIER____CANCEL',
  SupplierAcctDnSupplierDelete = 'SUPPLIER_ACCT_DN_SUPPLIER____DELETE',
  SupplierAcctDnSupplierAllocation = 'SUPPLIER_ACCT_DN_SUPPLIER____ALLOCATION',
  SupplierAcctAccTransactionsDnSupplierCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____CREATE',
  SupplierAcctAccTransactionsDnSupplierView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____VIEW',
  SupplierAcctAccTransactionsDnSupplierUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____UPDATE',
  SupplierAcctAccTransactionsDnSupplierApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctAccTransactionsDnSupplierCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____CANCEL',
  SupplierAcctAccTransactionsDnSupplierDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsDnSupplierAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____ALLOCATION',
  SupplierAcctCnSupplierCreate = 'SUPPLIER_ACCT_CN_SUPPLIER____CREATE',
  SupplierAcctCnSupplierView = 'SUPPLIER_ACCT_CN_SUPPLIER____VIEW',
  SupplierAcctCnSupplierUpdate = 'SUPPLIER_ACCT_CN_SUPPLIER____UPDATE',
  SupplierAcctCnSupplierCancel = 'SUPPLIER_ACCT_CN_SUPPLIER____CANCEL',
  SupplierAcctCnSupplierApproveReject = 'SUPPLIER_ACCT_CN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctCnSupplierDelete = 'SUPPLIER_ACCT_CN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsCnSupplierCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____CREATE',
  SupplierAcctAccTransactionsCnSupplierView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____VIEW',
  SupplierAcctAccTransactionsCnSupplierUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____UPDATE',
  SupplierAcctAccTransactionsCnSupplierCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____CANCEL',
  SupplierAcctAccTransactionsCnSupplierApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctAccTransactionsCnSupplierDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsOsPayableView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_OS_PAYABLE____VIEW',
  SupplierAcctDigitalReportingView = 'SUPPLIER_ACCT_DIGITAL_REPORTING____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierAgingDetailByProjectView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_BY_PROJECT____VIEW',
  SupplierAcctDigitalReportingSupplierAgingDetailReportByTrxDateView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_REPORT_BY_TRX_DATE____VIEW',
  SupplierAcctDigitalReportingSupplierAgingSummaryReportByTrxDateView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_SUMMARY_REPORT_BY_TRX_DATE____VIEW',
  SupplierAcctDigitalReportingSupplierControlSummaryReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_CONTROL_SUMMARY_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierDetailBreakdownReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_DETAIL_BREAKDOWN_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportByProjectView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT_BY_PROJECT____VIEW',
  SupplierAcctGeneralSettingsIcon = 'SUPPLIER_ACCT_GENERAL_SETTINGS_______ICON',
  SupplierAcctGeneralSettingsBuildingMaterialView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  SupplierAcctGeneralSettingsBuildingMaterialCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  SupplierAcctGeneralSettingsBuildingMaterialUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  SupplierAcctGeneralSettingsBuildingMaterialDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  SupplierAcctGeneralSettingsBuildingMaterialDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  SupplierAcctGeneralSettingsBuildingMaterialUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  SupplierAcctGeneralSettingsBuildingMaterialInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  SupplierAcctGeneralSettingsPAndMCategoryView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____VIEW',
  SupplierAcctGeneralSettingsPAndMCategoryCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____CREATE',
  SupplierAcctGeneralSettingsPAndMCategoryUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPDATE',
  SupplierAcctGeneralSettingsPAndMCategoryDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____DELETE',
  SupplierAcctGeneralSettingsPAndMCategoryDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____DOWNLOAD',
  SupplierAcctGeneralSettingsPAndMCategoryUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPLOAD',
  SupplierAcctGeneralSettingsPAndMCategoryInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____INACTIVE',
  SupplierAcctGeneralSettingsPAndMCategoryActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____ACTIVE',
  SupplierAcctGeneralSettingsLabourCategoryView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  SupplierAcctGeneralSettingsLabourCategoryCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  SupplierAcctGeneralSettingsLabourCategoryUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  SupplierAcctGeneralSettingsLabourCategoryDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  SupplierAcctGeneralSettingsLabourCategoryDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  SupplierAcctGeneralSettingsLabourCategoryUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  SupplierAcctGeneralSettingsLabourCategoryInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  SupplierAcctGeneralSettingsLabourCategoryActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  SupplierAcctGeneralSettingsMiscellaneousExpenseCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  SupplierAcctGeneralSettingsMiscellaneousExpenseUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  SupplierAcctGeneralSettingsMiscellaneousExpenseInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  SiteMgmtIcon = 'SITE_MGMT__________ICON',
  ProjectCostingIcon = 'PROJECT_COSTING__________ICON',
  ProjectCostingContractView = 'PROJECT_COSTING_CONTRACT_______VIEW',
  ProjectCostingBudgetingView = 'PROJECT_COSTING_BUDGETING_______VIEW',
  ProjectCostingBudgetingCostCategoryView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY____VIEW',
  ProjectCostingBudgetingCostCategoryOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingCostCategoryOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingCostCategoryOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingCostCategoryOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingCostCategoryReviseBudgetView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingCostCategoryReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingSubcontractTradeCostView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST____VIEW',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingSubcontractTradeCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingSubcontractTradeCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST____VIEW',
  ProjectCostingBudgetingMaterialCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingMaterialCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingMaterialCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingMaterialCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST____VIEW',
  ProjectCostingBudgetingPAndMCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingPAndMCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingPAndMCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingPAndMCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST____VIEW',
  ProjectCostingBudgetingLabourCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingLabourCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingLabourCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingLabourCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES____VIEW',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingMiscExpensesReviseBudgetView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_REVISE_BUDGET_UPDATE',
  ProjectCostingMiscellaneousExpenseView = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____VIEW',
  ProjectCostingMiscellaneousExpenseCreate = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____CREATE',
  ProjectCostingMiscellaneousExpenseUpdate = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____UPDATE',
  ProjectCostingMiscellaneousExpenseCancel = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____CANCEL',
  ProjectCostingProjectCostingView = 'PROJECT_COSTING_PROJECT_COSTING_______VIEW',
  ProjectCostingProjectCostingExport = 'PROJECT_COSTING_PROJECT_COSTING_______EXPORT',
  ProjectCostingProjectCostingShare = 'PROJECT_COSTING_PROJECT_COSTING_______SHARE',
  ProjectCostingProjectCostingSubcontractCostView = 'PROJECT_COSTING_PROJECT_COSTING_SUBCONTRACT_COST____VIEW',
  ProjectCostingProjectCostingSubcontractCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_SUBCONTRACT_COST____DOWNLOAD',
  ProjectCostingProjectCostingMaterialCostView = 'PROJECT_COSTING_PROJECT_COSTING_MATERIAL_COST____VIEW',
  ProjectCostingProjectCostingMaterialCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_MATERIAL_COST____DOWNLOAD',
  ProjectCostingProjectCostingPAndMCostView = 'PROJECT_COSTING_PROJECT_COSTING_P_AND_M_COST____VIEW',
  ProjectCostingProjectCostingPAndMCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_P_AND_M_COST____DOWNLOAD',
  ProjectCostingProjectCostingLabourCostView = 'PROJECT_COSTING_PROJECT_COSTING_LABOUR_COST____VIEW',
  ProjectCostingProjectCostingLabourCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_LABOUR_COST____DOWNLOAD',
  ProjectCostingProjectCostingMiscellaneousExpensesView = 'PROJECT_COSTING_PROJECT_COSTING_MISCELLANEOUS_EXPENSES____VIEW',
  ProjectCostingProjectCostingMiscellaneousExpensesDownload = 'PROJECT_COSTING_PROJECT_COSTING_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingProjectPlView = 'PROJECT_COSTING_PROJECT_PL_______VIEW',
  ProjectCostingProjectPlShare = 'PROJECT_COSTING_PROJECT_PL_______SHARE',
  ProjectCostingProjectPlDownload = 'PROJECT_COSTING_PROJECT_PL____DOWNLOAD',
  ProjectCostingProjectPlExport = 'PROJECT_COSTING_PROJECT_PL____EXPORT',
  ProjectCostingProjectPlRevisedContractSumsView = 'PROJECT_COSTING_PROJECT_PL_REVISED_CONTRACT_SUMS_____VIEW',
  ProjectCostingProjectPlProgressIncomeView = 'PROJECT_COSTING_PROJECT_PL_PROGRESS_INCOME_____VIEW',
  ProjectCostingProjectPlProgressIncomeDownload = 'PROJECT_COSTING_PROJECT_PL_PROGRESS_INCOME____DOWNLOAD',
  ProjectCostingProjectPlAdjustmentClientView = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_CLIENT_____VIEW',
  ProjectCostingProjectPlAdjustmentClientDownload = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_CLIENT____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousInvoicesArView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICES_AR_____VIEW',
  ProjectCostingProjectPlMiscellaneousInvoicesArDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICES_AR____DOWNLOAD',
  ProjectCostingProjectPlSubcontractCostView = 'PROJECT_COSTING_PROJECT_PL_SUBCONTRACT_COST_____VIEW',
  ProjectCostingProjectPlSubcontractCostDownload = 'PROJECT_COSTING_PROJECT_PL_SUBCONTRACT_COST____DOWNLOAD',
  ProjectCostingProjectPlMaterialCostView = 'PROJECT_COSTING_PROJECT_PL_MATERIAL_COST_____VIEW',
  ProjectCostingProjectPlMaterialCostDownload = 'PROJECT_COSTING_PROJECT_PL_MATERIAL_COST____DOWNLOAD',
  ProjectCostingProjectPlPAndMCostView = 'PROJECT_COSTING_PROJECT_PL_P_AND_M_COST_____VIEW',
  ProjectCostingProjectPlPAndMCostDownload = 'PROJECT_COSTING_PROJECT_PL_P_AND_M_COST____DOWNLOAD',
  ProjectCostingProjectPlLabourCostView = 'PROJECT_COSTING_PROJECT_PL_LABOUR_COST_____VIEW',
  ProjectCostingProjectPlLabourCostDownload = 'PROJECT_COSTING_PROJECT_PL_LABOUR_COST____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousInvoiceApView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICE_AP_____VIEW',
  ProjectCostingProjectPlMiscellaneousInvoiceApDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICE_AP____DOWNLOAD',
  ProjectCostingProjectPlAdjustmentSupplierSubconView = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_SUPPLIER_SUBCON_____VIEW',
  ProjectCostingProjectPlAdjustmentSupplierSubconDownload = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_SUPPLIER_SUBCON____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousExpensesView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_EXPENSES_____VIEW',
  ProjectCostingProjectPlMiscellaneousExpensesDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingProjectCashFlowView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_______VIEW',
  ProjectCostingProjectCashFlowShare = 'PROJECT_COSTING_PROJECT_CASH_FLOW_______SHARE',
  ProjectCostingProjectCashFlowReceivedFromClientView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____VIEW',
  ProjectCostingProjectCashFlowReceivedFromClientDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____DOWNLOAD',
  ProjectCostingProjectCashFlowPaidToSubcontractorView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____VIEW',
  ProjectCostingProjectCashFlowPaidToSubcontractorDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____DOWNLOAD',
  ProjectCostingProjectCashFlowPaidToSupplierView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____VIEW',
  ProjectCostingProjectCashFlowPaidToSupplierDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____DOWNLOAD',
  ProjectCostingProjectCashFlowOtherExpensesView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OTHER_EXPENSES____VIEW',
  ProjectCostingProjectCashFlowOsReceivableFromClientView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_RECEIVABLE_FROM_CLIENT____VIEW',
  ProjectCostingProjectCashFlowOsReceivableFromClientDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_RECEIVABLE_FROM_CLIENT____DOWNLOAD',
  ProjectCostingProjectCashFlowOsPayableToSubcontractorView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUBCONTRACTOR____VIEW',
  ProjectCostingProjectCashFlowOsPayableToSubcontractorDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUBCONTRACTOR____DOWNLOAD',
  ProjectCostingProjectCashFlowOsPayableToSupplierView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUPPLIER____VIEW',
  ProjectCostingProjectCashFlowOsPayableToSupplierDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUPPLIER____DOWNLOAD',
  ProjectCostingProjectCostPerformanceView = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_______VIEW',
  ProjectCostingProjectCostPerformanceShare = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_______SHARE',
  ProjectCostingProjectCostPerformancePercentageOfCompletionUpdate = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_PERCENTAGE_OF_COMPLETION____UPDATE',
  ProjectCostingProjectFinancialStatusView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_______VIEW',
  ProjectCostingProjectFinancialStatusShare = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_______SHARE',
  ProjectCostingProjectFinancialStatusPercentageOfCompletionUpdate = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PERCENTAGE_OF_COMPLETION____UPDATE',
  ProjectCostingProjectFinancialStatusBillingToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_BILLING_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusCollectionToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_COLLECTION_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusPayableToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PAYABLE_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusPaymentToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PAYMENT_TO_DATE____VIEW',
  ProjectCostingProjectBudgetAnalysisShare = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_______SHARE',
  ProjectCostingProjectBudgetAnalysisView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_______VIEW',
  ProjectCostingProjectBudgetAnalysisOriginalBudgetView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_ORIGINAL_BUDGET_______VIEW',
  ProjectCostingProjectBudgetAnalysisRevisedBudgetView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_REVISED_BUDGET_______VIEW',
  ProjectCostingProjectBudgetVsActualAnalysisView = 'PROJECT_COSTING_PROJECT_BUDGET_VS_ACTUAL_ANALYSIS_______VIEW',
  ProjectCostingProjectBudgetVsActualAnalysisShare = 'PROJECT_COSTING_PROJECT_BUDGET_VS_ACTUAL_ANALYSIS_______SHARE',
  ProjectCostingDigitalReportView = 'PROJECT_COSTING_DIGITAL_REPORT_______VIEW',
  ProjectCostingDigitalReportExecutiveSummaryReportView = 'PROJECT_COSTING_DIGITAL_REPORT_EXECUTIVE_SUMMARY_REPORT_______VIEW',
  ProjectCostingDigitalReportProjectCostAnalysisReportView = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_COST_ANALYSIS_REPORT_______VIEW',
  ProjectCostingDigitalReportProjectLedgerReportView = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_LEDGER_REPORT_______VIEW',
  ProjectCostingGeneralSettingsIcon = 'PROJECT_COSTING_GENERAL_SETTINGS_______ICON',
  ProjectCostingGeneralSettingsSubcontractTradeCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  ProjectCostingGeneralSettingsSubcontractTradeView = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  ProjectCostingGeneralSettingsSubcontractTradeUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  ProjectCostingGeneralSettingsSubcontractTradeDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  ProjectCostingGeneralSettingsSubcontractTradeDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  ProjectCostingGeneralSettingsSubcontractTradeUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  ProjectCostingGeneralSettingsSubcontractTradeInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  ProjectCostingGeneralSettingsSubcontractTradeActive = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ProjectCostingGeneralSettingsLabourCategoryView = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ProjectCostingGeneralSettingsLabourCategoryCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ProjectCostingGeneralSettingsLabourCategoryUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ProjectCostingGeneralSettingsLabourCategoryDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ProjectCostingGeneralSettingsLabourCategoryDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ProjectCostingGeneralSettingsLabourCategoryUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ProjectCostingGeneralSettingsLabourCategoryInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ProjectCostingGeneralSettingsLabourCategoryActive = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialView = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ProjectCostingGeneralSettingsBuildingMaterialCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ProjectCostingGeneralSettingsBuildingMaterialUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ProjectCostingGeneralSettingsBuildingMaterialDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ProjectCostingGeneralSettingsBuildingMaterialUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ProjectCostingGeneralSettingsBuildingMaterialDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ProjectCostingGeneralSettingsBuildingMaterialInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialActive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemView = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemActive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ProjectCostingGeneralSettingsPAndMCategoryView = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____VIEW',
  ProjectCostingGeneralSettingsPAndMCategoryCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____CREATE',
  ProjectCostingGeneralSettingsPAndMCategoryUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPDATE',
  ProjectCostingGeneralSettingsPAndMCategoryDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____DOWNLOAD',
  ProjectCostingGeneralSettingsPAndMCategoryUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPLOAD',
  ProjectCostingGeneralSettingsPAndMCategoryDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____DELETE',
  ProjectCostingGeneralSettingsPAndMCategoryInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____INACTIVE',
  ProjectCostingGeneralSettingsPAndMCategoryActive = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____ACTIVE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesView = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____VIEW',
  ProjectCostingGeneralSettingsMiscellaneousExpensesCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____CREATE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____UPDATE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingGeneralSettingsMiscellaneousExpensesUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____UPLOAD',
  ProjectCostingGeneralSettingsMiscellaneousExpensesDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____DELETE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____INACTIVE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesActive = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____ACTIVE',
  PlantMachineryIcon = 'PLANT_MACHINERY__________ICON',
  PlantMachineryView = 'PLANT_MACHINERY__________VIEW',
  PlantMachineryOwnedPMView = 'PLANT_MACHINERY_OWNED_P_M_______VIEW',
  PlantMachineryOwnedPMCreate = 'PLANT_MACHINERY_OWNED_P_M_______CREATE',
  PlantMachineryOwnedPMUpdate = 'PLANT_MACHINERY_OWNED_P_M_______UPDATE',
  PlantMachineryOwnedPMAssignmentHistoryView = 'PLANT_MACHINERY_OWNED_P_M_ASSIGNMENT_HISTORY____VIEW',
  PlantMachineryOwnedPMMaintenanceHistoryView = 'PLANT_MACHINERY_OWNED_P_M_MAINTENANCE_HISTORY____VIEW',
  PlantMachineryRentedPMView = 'PLANT_MACHINERY_RENTED_P_M_______VIEW',
  PlantMachineryRentedPMCreate = 'PLANT_MACHINERY_RENTED_P_M_______CREATE',
  PlantMachineryRentedPMUpdate = 'PLANT_MACHINERY_RENTED_P_M_______UPDATE',
  PlantMachineryRentedPMReturn = 'PLANT_MACHINERY_RENTED_P_M_______RETURN',
  PlantMachineryAssignmentRecordsView = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______VIEW',
  PlantMachineryAssignmentRecordsCreate = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______CREATE',
  PlantMachineryAssignmentRecordsUpdate = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______UPDATE',
  PlantMachineryAssignmentRecordsReturn = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______RETURN',
  PlantMachineryAssignmentRecordsCancel = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______CANCEL',
  PlantMachineryAssignmentRecordsSendMaintenance = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______SEND_MAINTENANCE',
  PlantMachineryMaintanenceRecordsView = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______VIEW',
  PlantMachineryMaintanenceRecordsCreate = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______CREATE',
  PlantMachineryMaintanenceRecordsReturn = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______RETURN',
  PlantMachineryMaintanenceRecordsCancel = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______CANCEL',
  PlantMachineryGeneralSettingsIcon = 'PLANT_MACHINERY_GENERAL_SETTINGS_______ICON',
  PlantMachineryGeneralSettingsPMCategoryView = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____VIEW',
  PlantMachineryGeneralSettingsPMCategoryCreate = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____CREATE',
  PlantMachineryGeneralSettingsPMCategoryUpdate = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____UPDATE',
  PlantMachineryGeneralSettingsPMCategoryDownload = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____DOWNLOAD',
  PlantMachineryGeneralSettingsPMCategoryUpload = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____UPLOAD',
  PlantMachineryGeneralSettingsPMCategoryInactive = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____INACTIVE',
  PlantMachineryGeneralSettingsPMCategoryActive = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____ACTIVE',
  PlantMachineryGeneralSettingsPMCategoryDelete = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____DELETE',
  PlantMachineryGeneralSettingsWorkshopMaintenanceView = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____VIEW',
  PlantMachineryGeneralSettingsWorkshopMaintenanceCreate = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____CREATE',
  PlantMachineryGeneralSettingsWorkshopMaintenanceUpdate = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____UPDATE',
  GlInterfaceIcon = 'GL_INTERFACE__________ICON',
  GlInterfaceView = 'GL_INTERFACE_______VIEW',
  GlInterfaceGeneralLedgerExportView = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____VIEW',
  GlInterfaceGeneralLedgerExportCreate = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____CREATE',
  GlInterfaceGeneralLedgerExportExport = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____EXPORT',
  GlInterfaceCashBookView = 'GL_INTERFACE_CASH_BOOK____VIEW',
  GlInterfaceCashBookCreate = 'GL_INTERFACE_CASH_BOOK____CREATE',
  GlInterfaceCashBookExport = 'GL_INTERFACE_CASH_BOOK____EXPORT',
  GlInterfaceGeneralSettingsIcon = 'GL_INTERFACE_GENERAL_SETTINGS_______ICON',
  SystemAdminIcon = 'SYSTEM_ADMIN__________ICON',
  SystemAdminAccessSecurityView = 'SYSTEM_ADMIN_ACCESS_SECURITY_______VIEW',
  SystemAdminAccessSecurityUserView = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____VIEW',
  SystemAdminAccessSecurityUserCreate = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____CREATE',
  SystemAdminAccessSecurityUserUpdate = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____UPDATE',
  SystemAdminAccessSecurityUserChangePassword = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____CHANGE_PASSWORD',
  SystemAdminAccessSecurityUserUnblock = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____UNBLOCK',
  SystemAdminAccessSecurityRolesView = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____VIEW',
  SystemAdminAccessSecurityRolesCreate = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____CREATE',
  SystemAdminAccessSecurityRolesUpdate = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____UPDATE',
  SystemAdminAccessSecurityRolesDelete = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____DELETE',
  SystemAdminAccessSecurityProjectRoleAuthView = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____VIEW',
  SystemAdminAccessSecurityProjectRoleAuthCreate = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____CREATE',
  SystemAdminAccessSecurityProjectRoleAuthUpdate = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____UPDATE',
  SystemAdminDocumentNumberingSetupView = 'SYSTEM_ADMIN_DOCUMENT_NUMBERING_SETUP_______VIEW',
  SystemAdminDocumentNumberingSetupAccountReceivableView = 'SYSTEM_ADMIN_DOCUMENT_NUMBERING_SETUP_ACCOUNT_RECEIVABLE____VIEW',
  SystemAdminDocumentNumberingSetupAccountPayableView = 'SYSTEM_ADMIN_DOCUMENT_NUMBERING_SETUP_ACCOUNT_PAYABLE____VIEW',
  SystemAdminDocumentNumberingSetupProjectPurchaseView = 'SYSTEM_ADMIN_DOCUMENT_NUMBERING_SETUP_PROJECT_PURCHASE____VIEW',
  SystemAdminDocumentNumberingSetupSiteManagementView = 'SYSTEM_ADMIN_DOCUMENT_NUMBERING_SETUP_SITE_MANAGEMENT____VIEW',
  SystemAdminDocumentNumberingSetupDefectManagementView = 'SYSTEM_ADMIN_DOCUMENT_NUMBERING_SETUP_DEFECT_MANAGEMENT____VIEW',
  SystemAdminBankAccountView = 'SYSTEM_ADMIN_BANK_ACCOUNT_______VIEW',
  SystemAdminBankAccountCreate = 'SYSTEM_ADMIN_BANK_ACCOUNT_______CREATE',
  SystemAdminBankAccountUpdate = 'SYSTEM_ADMIN_BANK_ACCOUNT_______UPDATE',
  SystemAdminBankAccountDelete = 'SYSTEM_ADMIN_BANK_ACCOUNT_______DELETE',
  SystemAdminBankAccountActive = 'SYSTEM_ADMIN_BANK_ACCOUNT_______ACTIVE',
  SystemAdminBankAccountInactive = 'SYSTEM_ADMIN_BANK_ACCOUNT_______INACTIVE',
  SystemAdminTaxSetupView = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW',
  SystemAdminTaxSetupCreate = 'SYSTEM_ADMIN_TAX_SETUP_______CREATE',
  SystemAdminTaxSetupUpdate = 'SYSTEM_ADMIN_TAX_SETUP_______UPDATE',
  SystemAdminTaxSetupDelete = 'SYSTEM_ADMIN_TAX_SETUP_______DELETE',
  SystemAdminTaxSetupInactive = 'SYSTEM_ADMIN_TAX_SETUP_______INACTIVE',
  SystemAdminTaxSetupActive = 'SYSTEM_ADMIN_TAX_SETUP_______ACTIVE',
  SystemAdminTaxSetupViewHistory = 'SYSTEM_ADMIN_TAX_SETUP_______VIEW_HISTORY',
  SystemAdminUomSetupView = 'SYSTEM_ADMIN_UOM_SETUP_______VIEW',
  SystemAdminUomSetupCreate = 'SYSTEM_ADMIN_UOM_SETUP_______CREATE',
  SystemAdminUomSetupUpdate = 'SYSTEM_ADMIN_UOM_SETUP_______UPDATE',
  SystemAdminUomSetupDelete = 'SYSTEM_ADMIN_UOM_SETUP_______DELETE',
  SystemAdminUomSetupDownload = 'SYSTEM_ADMIN_UOM_SETUP_______DOWNLOAD',
  SystemAdminUomSetupUpload = 'SYSTEM_ADMIN_UOM_SETUP_______UPLOAD',
  SystemAdminUomSetupInactive = 'SYSTEM_ADMIN_UOM_SETUP_______INACTIVE',
  SystemAdminUomSetupActive = 'SYSTEM_ADMIN_UOM_SETUP_______ACTIVE',
  SystemAdminUomConversionSetupView = 'SYSTEM_ADMIN_UOM_CONVERSION_SETUP_______VIEW',
  SystemAdminUomConversionSetupUpdate = 'SYSTEM_ADMIN_UOM_CONVERSION_SETUP_______UPDATE',
  SystemAdminUomConversionSetupCreate = 'SYSTEM_ADMIN_UOM_CONVERSION_SETUP_______CREATE',
  SystemAdminUomConversionSetupDelete = 'SYSTEM_ADMIN_UOM_CONVERSION_SETUP_______DELETE',
  SystemAdminCompanySetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_______VIEW',
  SystemAdminCompanySetupUpdate = 'SYSTEM_ADMIN_COMPANY_SETUP_______UPDATE',
  SystemAdminCompanySetupWatermarkLogoSetupCreate = 'SYSTEM_ADMIN_COMPANY_SETUP_WATERMARK_LOGO_SETUP____CREATE',
  SystemAdminGeneralSettingsIcon = 'SYSTEM_ADMIN_GENERAL_SETTINGS_______ICON',
  SystemAdminGeneralSettingsSubcontractTradeView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______VIEW',
  SystemAdminGeneralSettingsSubcontractTradeCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______CREATE',
  SystemAdminGeneralSettingsSubcontractTradeUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______UPDATE',
  SystemAdminGeneralSettingsSubcontractTradeActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______ACTIVE',
  SystemAdminGeneralSettingsSubcontractTradeInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______INACTIVE',
  SystemAdminGeneralSettingsSubcontractTradeDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______DELETE',
  SystemAdminGeneralSettingsSubcontractTradeUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______UPLOAD',
  SystemAdminGeneralSettingsSubcontractTradeDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______DOWNLOAD',
  SystemAdminGeneralSettingsLabourCategoryView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______VIEW',
  SystemAdminGeneralSettingsLabourCategoryCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______CREATE',
  SystemAdminGeneralSettingsLabourCategoryUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______UPDATE',
  SystemAdminGeneralSettingsLabourCategoryActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______ACTIVE',
  SystemAdminGeneralSettingsLabourCategoryInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______INACTIVE',
  SystemAdminGeneralSettingsLabourCategoryDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______DELETE',
  SystemAdminGeneralSettingsLabourCategoryUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______UPLOAD',
  SystemAdminGeneralSettingsLabourCategoryDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______DOWNLOAD',
  SystemAdminPolicyGuidelineCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_______CREATE',
  SystemAdminGeneralSettingsBuildingMaterialView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______VIEW',
  SystemAdminGeneralSettingsBuildingMaterialCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______CREATE',
  SystemAdminGeneralSettingsBuildingMaterialUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______UPDATE',
  SystemAdminGeneralSettingsBuildingMaterialActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______ACTIVE',
  SystemAdminGeneralSettingsBuildingMaterialInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______INACTIVE',
  SystemAdminGeneralSettingsBuildingMaterialDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______DELETE',
  SystemAdminGeneralSettingsBuildingMaterialUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______UPLOAD',
  SystemAdminGeneralSettingsBuildingMaterialDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______DOWNLOAD',
  SystemAdminGeneralSettingsMiscellaneousExpenseView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______VIEW',
  SystemAdminGeneralSettingsMiscellaneousExpenseCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______CREATE',
  SystemAdminGeneralSettingsMiscellaneousExpenseUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______UPDATE',
  SystemAdminGeneralSettingsMiscellaneousExpenseActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______ACTIVE',
  SystemAdminGeneralSettingsMiscellaneousExpenseInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______INACTIVE',
  SystemAdminGeneralSettingsMiscellaneousExpenseDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______DELETE',
  SystemAdminGeneralSettingsMiscellaneousExpenseUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______UPLOAD',
  SystemAdminGeneralSettingsMiscellaneousExpenseDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______DOWNLOAD',
  SystemAdminGeneralSettingsGuaranteeTypeView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______VIEW',
  SystemAdminGeneralSettingsGuaranteeTypeCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______CREATE',
  SystemAdminGeneralSettingsGuaranteeTypeUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______UPDATE',
  SystemAdminGeneralSettingsGuaranteeTypeInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______INACTIVE',
  SystemAdminGeneralSettingsGuaranteeTypeDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______DELETE',
  SystemAdminGeneralSettingsInsuranceTypeView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______VIEW',
  SystemAdminGeneralSettingsInsuranceTypeCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______CREATE',
  SystemAdminGeneralSettingsInsuranceTypeUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______UPDATE',
  SystemAdminGeneralSettingsInsuranceTypeInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______INACTIVE',
  SystemAdminGeneralSettingsInsuranceTypeDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______DELETE',
  SystemAdminGeneralSettingsPmCategoryView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______VIEW',
  SystemAdminGeneralSettingsPmCategoryCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______CREATE',
  SystemAdminGeneralSettingsPmCategoryUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______UPDATE',
  SystemAdminGeneralSettingsPmCategoryActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______ACTIVE',
  SystemAdminGeneralSettingsPmCategoryInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______INACTIVE',
  SystemAdminGeneralSettingsPmCategoryDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______DELETE',
  SystemAdminGeneralSettingsPmCategoryUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______UPLOAD',
  SystemAdminGeneralSettingsPmCategoryDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______DOWNLOAD',
  SystemAdminGeneralSettingsWorkshopMaintenanceView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______VIEW',
  SystemAdminGeneralSettingsWorkshopMaintenanceCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______CREATE',
  SystemAdminGeneralSettingsWorkshopMaintenanceUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______UPDATE',
  SystemAdminGeneralSettingsClientAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsSubcontractorAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsSupplierAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsPurchaseAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PURCHASE_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsPobAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_POB_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsBankAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BANK_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsTaxAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_TAX_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsTradeAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_TRADE_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsMaterialCategorySetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MATERIAL_CATEGORY_SETUP_______UPDATE',
  SystemAdminGeneralSettingsPmCategorySetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_SETUP_______UPDATE',
  SystemAdminGeneralSettingsLabourCategorySetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_SETUP_______UPDATE',
  SystemAdminGeneralSettingsMiscCategorySetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISC_CATEGORY_SETUP_______UPDATE',
  SystemAdminGeneralSettingsJournalTypeSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOURNAL_TYPE_SETUP_______UPDATE',
  SystemAdminGeneralSettingsGlAccountCodeSetupCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GL_ACCOUNT_CODE_SETUP_______CREATE',
  SystemAdminPolicyGuidelineUpdate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_______UPDATE',
  SystemAdminPolicyGuidelineDelete = 'SYSTEM_ADMIN_POLICY_GUIDELINE_______DELETE',
  SystemAdminContractSettingsView = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______VIEW',
  SystemAdminContractSettingsCreate = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______CREATE',
  SystemAdminContractSettingsUpdate = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______UPDATE',
  SystemAdminPolicyGuidelineView = 'SYSTEM_ADMIN_POLICY_GUIDELINE_______VIEW',
  SystemAdminPolicyGuidelineContractManagementCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CONTRACT_MANAGEMENT_______CREATE',
  SystemAdminPolicyGuidelineClientAccountAdvanceReceiptApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_ADVANCE_RECEIPT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountClaimApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_CLAIM_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountMiscInvoiceApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_MISC_INVOICE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountDebitNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_DEBIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountCreditNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_CREDIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountOfficialReceiptApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_OFFICIAL_RECEIPT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountRefundApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_REFUND_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountClientDnApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_CLIENT_DN_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountClientCnApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_CLIENT_CN_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractMgmtSubcontractVoApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACT_MGMT_SUBCONTRACT_VO_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountAdvancePaymentApprovalPolicyView = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_APPROVAL_POLICY_______VIEW',
  SystemAdminPolicyGuidelineSubcontractorAccountAdvancePaymentApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountAdvancePaymentApprovalPolicyUpdate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_APPROVAL_POLICY_______UPDATE',
  SystemAdminPolicyGuidelineSubcontractorAccountAdvancePaymentApprovalPolicyDelete = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_APPROVAL_POLICY_______DELETE',
  SystemAdminPolicyGuidelineSubcontractorAccountMiscInvoiceApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_MISC_INVOICE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountDebitNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_DEBIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountCreditNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_CREDIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountPaymentApprovalPolicyView = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_PAYMENT_APPROVAL_POLICY_______VIEW',
  SystemAdminPolicyGuidelineSubcontractorAccountPaymentApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_PAYMENT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountRefundReceiptApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_REFUND_RECEIPT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountCnToSubconApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_CN_TO_SUBCON_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountDnToSubconApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_DN_TO_SUBCON_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountAdvanceDepositApprovalPolicyView = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_ADVANCE_DEPOSIT_APPROVAL_POLICY_______VIEW',
  SystemAdminPolicyGuidelineSupplierAccountAdvanceDepositApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_ADVANCE_DEPOSIT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountAdvanceDepositApprovalPolicyUpdate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_ADVANCE_DEPOSIT_APPROVAL_POLICY_______UPDATE',
  SystemAdminPolicyGuidelineSupplierAccountAdvanceDepositApprovalPolicyDelete = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_ADVANCE_DEPOSIT_APPROVAL_POLICY_______DELETE',
  SystemAdminPolicyGuidelineSupplierAccountInvoiceApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_INVOICE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountCreditNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_CREDIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountDebitNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_DEBIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountPaymentApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_PAYMENT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountCnToSupplierApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_CN_TO_SUPPLIER_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountDnToSupplierApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_DN_TO_SUPPLIER_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineProjectPurchasePrApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_PROJECT_PURCHASE_PR_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineProjectPurchasePoApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_PROJECT_PURCHASE_PO_APPROVAL_POLICY_______CREATE',
  SystemAdminNotificationPolicyCreate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_______CREATE',
  SystemAdminNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyDelete = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_______DELETE',
  SystemAdminNotificationPolicyContractManagementContractVoNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CONTRACT_MANAGEMENT_CONTRACT_VO_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyContractManagementContractVoNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CONTRACT_MANAGEMENT_CONTRACT_VO_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountAdvanceReceiptNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_ADVANCE_RECEIPT_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountAdvanceReceiptNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_ADVANCE_RECEIPT_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountClaimNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CLAIM_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountClaimNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CLAIM_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountCreditNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountCreditNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountDebitNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountDebitNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountPaymentVoucherNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountPaymentVoucherNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountClientAdjustmentNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CLIENT_ADJUSTMENT_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountClientAdjustmentNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CLIENT_ADJUSTMENT_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractMgmtSubcontractVoNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACT_MGMT_SUBCONTRACT_VO_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractMgmtSubcontractVoNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACT_MGMT_SUBCONTRACT_VO_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountAdvancePaymentNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountAdvancePaymentNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountClaimNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_CLAIM_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountClaimNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_CLAIM_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountCreditNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountCreditNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountDebitNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountDebitNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountPaymentVoucherNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountPaymentVoucherNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountOfficialReceiptNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_OFFICIAL_RECEIPT_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountOfficialReceiptNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_OFFICIAL_RECEIPT_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountSubconAdjustmentsNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_SUBCON_ADJUSTMENTS_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountSubconAdjustmentsNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_SUBCON_ADJUSTMENTS_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySupplierAccountSupplierInvoiceNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_SUPPLIER_INVOICE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySupplierAccountSupplierInvoiceNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_SUPPLIER_INVOICE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySupplierAccountCreditNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySupplierAccountCreditNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySupplierAccountDebitNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySupplierAccountDebitNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySupplierAccountPaymentVoucherNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySupplierAccountPaymentVoucherNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyProjectPurchasePrNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_PROJECT_PURCHASE_PR_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyProjectPurchasePrNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_PROJECT_PURCHASE_PR_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyProjectPurchasePoNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_PROJECT_PURCHASE_PO_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyProjectPurchasePoNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_PROJECT_PURCHASE_PO_NOTIFICATION_POLICY_______UPDATE',
  DefectMgmtIcon = 'DEFECT_MGMT__________ICON',
  DefectMgmtView = 'DEFECT_MGMT__________VIEW',
  DefectMgmtCreate = 'DEFECT_MGMT__________CREATE',
  DefectMgmtUpdate = 'DEFECT_MGMT__________UPDATE',
  DefectMgmtActivityLogCreate = 'DEFECT_MGMT_ACTIVITY_LOG_______CREATE',
  DefectMgmtActivityLogView = 'DEFECT_MGMT_ACTIVITY_LOG_______VIEW',
  DefectMgmtActivityLogUpdate = 'DEFECT_MGMT_ACTIVITY_LOG_______UPDATE',
  DefectMgmtDefectAssignmentView = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______VIEW',
  DefectMgmtDefectAssignmentCreate = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______CREATE',
  DefectMgmtGeneralSettingsIcon = 'DEFECT_MGMT_GENERAL_SETTINGS_______ICON',
  DefectMgmtGeneralSettingsSubcontractPackageView = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____VIEW',
  DefectMgmtGeneralSettingsSubcontractPackageCreate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____CREATE',
  DefectMgmtGeneralSettingsSubcontractPackageUpdate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPDATE',
  DefectMgmtGeneralSettingsSubcontractPackageDelete = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DELETE',
  DefectMgmtGeneralSettingsSubcontractPackageDownload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DOWNLOAD',
  DefectMgmtGeneralSettingsSubcontractPackageUpload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPLOAD',
  DefectMgmtContractSettingsIcon = 'DEFECT_MGMT_CONTRACT_SETTINGS_______ICON',
  SiteAttendanceIcon = 'SITE_ATTENDANCE__________ICON',
  SiteAttendanceView = 'SITE_ATTENDANCE__________VIEW',
  SiteAttendanceWorkerProfileCreate = 'SITE_ATTENDANCE_WORKER_PROFILE_______CREATE',
  SiteAttendanceWorkerProfileView = 'SITE_ATTENDANCE_WORKER_PROFILE_______VIEW',
  SiteAttendanceWorkerProfileUpdate = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPDATE',
  SiteAttendanceWorkerProfileDelete = 'SITE_ATTENDANCE_WORKER_PROFILE_______DELETE',
  SiteAttendanceWorkerProfileDownload = 'SITE_ATTENDANCE_WORKER_PROFILE_______DOWNLOAD',
  SiteAttendanceWorkerProfileUpload = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPLOAD',
  SiteAttendanceWorkerAttendanceCreate = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______CREATE',
  SiteAttendanceWorkerAttendanceView = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______VIEW',
  SiteAttendanceWorkerAttendanceExport = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______EXPORT',
  SiteAttendanceGeneralSettingsIcon = 'SITE_ATTENDANCE_GENERAL_SETTINGS_______ICON',
  SiteAttendanceGeneralSettingsPublicHolidayCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteAttendanceGeneralSettingsPublicHolidayView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteAttendanceGeneralSettingsPublicHolidayUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteAttendanceGeneralSettingsPublicHolidayDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteAttendanceGeneralSettingsPublicHolidayCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteAttendanceGeneralSettingsPublicHolidayActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteAttendanceGeneralSettingsCalendarPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteAttendanceGeneralSettingsCalendarPolicyView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteAttendanceGeneralSettingsCalendarPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CREATE',
  SiteAttendanceGeneralSettingsJobTypeView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____VIEW',
  SiteAttendanceGeneralSettingsJobTypeUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____DELETE',
  SiteAttendanceGeneralSettingsJobTypeCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  SiteAttendanceGeneralSettingsJobTypeActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  SiteAttendanceContractSettingsIcon = 'SITE_ATTENDANCE_CONTRACT_SETTINGS_______ICON',
  SiteWagesIcon = 'SITE_WAGES__________ICON',
  SiteWagesView = 'SITE_WAGES__________VIEW',
  SiteWagesDailyRegisteredWorkHoursCreate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______CREATE',
  SiteWagesDailyRegisteredWorkHoursView = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______VIEW',
  SiteWagesDailyRegisteredWorkHoursUpdate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______UPDATE',
  SiteWagesDailyRegisteredWorkHoursDelete = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______DELETE',
  SiteWagesDailyWagesVerificationCreate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______CREATE',
  SiteWagesDailyWagesVerificationView = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______VIEW',
  SiteWagesDailyWagesVerificationUpdate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______UPDATE',
  SiteWagesDailyWagesVerificationDelete = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______DELETE',
  SiteWagesWagesBookView = 'SITE_WAGES_WAGES_BOOK_______VIEW',
  SiteWagesWagesBookCreate = 'SITE_WAGES_WAGES_BOOK_______CREATE',
  SiteWagesWagesBookUpdate = 'SITE_WAGES_WAGES_BOOK_______UPDATE',
  SiteWagesWagesBookDelete = 'SITE_WAGES_WAGES_BOOK_______DELETE',
  SiteWagesWageCycleClosureCreate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______CREATE',
  SiteWagesWageCycleClosureView = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______VIEW',
  SiteWagesWageCycleClosureUpdate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______UPDATE',
  SiteWagesWageCycleClosureDelete = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______DELETE',
  SiteWagesGeneralSettingsIcon = 'SITE_WAGES_GENERAL_SETTINGS_______ICON',
  SiteWagesGeneralSettingsPublicHolidayCreate = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteWagesGeneralSettingsPublicHolidayView = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteWagesGeneralSettingsPublicHolidayUpdate = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteWagesGeneralSettingsPublicHolidayDelete = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteWagesGeneralSettingsPublicHolidayCancel = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteWagesGeneralSettingsPublicHolidayActive = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteWagesGeneralSettingsCalendarPolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteWagesGeneralSettingsCalendarPolicyView = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteWagesGeneralSettingsCalendarPolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteWagesGeneralSettingsWorkingHoursPolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteWagesGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteWagesGeneralSettingsWagesRatePolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____CREATE',
  SiteWagesGeneralSettingsWagesRatePolicyView = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____VIEW',
  SiteWagesGeneralSettingsWagesRatePolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  SiteWagesContractSettingsIcon = 'SITE_WAGES_CONTRACT_SETTINGS_______ICON',
  SiteInventoryIcon = 'SITE_INVENTORY__________ICON',
  SiteInventoryView = 'SITE_INVENTORY__________VIEW',
  SiteInventoryGoodReceiveNoteCreate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CREATE',
  SiteInventoryGoodReceiveNoteView = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______VIEW',
  SiteInventoryGoodReceiveNoteUpdate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______UPDATE',
  SiteInventoryGoodReceiveNoteApproveReject = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______APPROVE_REJECT',
  SiteInventoryGoodReceiveNoteCancel = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CANCEL',
  SiteInventoryGoodReceiveNoteDelete = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______DELETE',
  SiteInventoryStockIssueCreate = 'SITE_INVENTORY_STOCK_ISSUE_______CREATE',
  SiteInventoryStockIssueView = 'SITE_INVENTORY_STOCK_ISSUE_______VIEW',
  SiteInventoryStockIssueUpdate = 'SITE_INVENTORY_STOCK_ISSUE_______UPDATE',
  SiteInventoryStockIssueDelete = 'SITE_INVENTORY_STOCK_ISSUE_______DELETE',
  SiteInventoryStockIssueDownload = 'SITE_INVENTORY_STOCK_ISSUE_______DOWNLOAD',
  SiteInventoryStockTransferInCreate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CREATE',
  SiteInventoryStockTransferInView = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______VIEW',
  SiteInventoryStockTransferInUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______UPDATE',
  SiteInventoryStockTransferInDelete = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DELETE',
  SiteInventoryStockTransferInDownload = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DOWNLOAD',
  SiteInventoryStockTransferOutCreate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CREATE',
  SiteInventoryStockTransferOutView = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______VIEW',
  SiteInventoryStockTransferOutUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______UPDATE',
  SiteInventoryStockTransferOutDelete = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DELETE',
  SiteInventoryStockTransferOutDownload = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DOWNLOAD',
  SiteInventoryStockAdjustmentCreate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CREATE',
  SiteInventoryStockAdjustmentView = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______VIEW',
  SiteInventoryStockAdjustmentUpdate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______UPDATE',
  SiteInventoryStockAdjustmentDelete = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DELETE',
  SiteInventoryStockAdjustmentDownload = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DOWNLOAD',
  SiteInventoryStockMovementCreate = 'SITE_INVENTORY_STOCK_MOVEMENT_______CREATE',
  SiteInventoryStockMovementView = 'SITE_INVENTORY_STOCK_MOVEMENT_______VIEW',
  SiteInventoryStockMovementUpdate = 'SITE_INVENTORY_STOCK_MOVEMENT_______UPDATE',
  SiteInventoryStockMovementDelete = 'SITE_INVENTORY_STOCK_MOVEMENT_______DELETE',
  SiteInventoryStockUsageAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______CREATE',
  SiteInventoryStockUsageAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______VIEW',
  SiteInventoryStockUsageAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______DELETE',
  SiteInventoryStockUsageAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryStockUsageDailyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageDailyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageDailyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageDailyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageDailyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______EXPORT',
  SiteInventoryStockUsageMonthlyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageMonthlyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageMonthlyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageMonthlyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageMonthlyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______EXPORT',
  SiteInventoryStockMoveInOutAnalysisCreate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______CREATE',
  SiteInventoryStockMoveInOutAnalysisView = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______VIEW',
  SiteInventoryStockMoveInOutAnalysisUpdate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______UPDATE',
  SiteInventoryStockMoveInOutAnalysisDelete = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______DELETE',
  SiteInventoryStockMoveInOutAnalysisExport = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______EXPORT',
  SiteInventorySiteUsageAnalysisCreate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______CREATE',
  SiteInventorySiteUsageAnalysisView = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______VIEW',
  SiteInventorySiteUsageAnalysisUpdate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______UPDATE',
  SiteInventorySiteUsageAnalysisDelete = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______DELETE',
  SiteInventorySiteUsageAnalysisExport = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryDigitalReportingView = 'SITE_INVENTORY_DIGITAL_REPORTING_______VIEW',
  SiteInventoryDigitalReportingStockMovementLedgerReportView = 'SITE_INVENTORY_DIGITAL_REPORTING_STOCK_MOVEMENT_LEDGER_REPORT____VIEW',
  SiteInventoryGeneralSettingsIcon = 'SITE_INVENTORY_GENERAL_SETTINGS_______ICON',
  SiteInventoryGeneralSettingsSiteMaterialCreate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CREATE',
  SiteInventoryGeneralSettingsSiteMaterialView = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____VIEW',
  SiteInventoryGeneralSettingsSiteMaterialUpdate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPDATE',
  SiteInventoryGeneralSettingsSiteMaterialDelete = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DELETE',
  SiteInventoryGeneralSettingsSiteMaterialCancel = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CANCEL',
  SiteInventoryGeneralSettingsSiteMaterialActive = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____ACTIVE',
  SiteInventoryGeneralSettingsSiteMaterialDownload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DOWNLOAD',
  SiteInventoryGeneralSettingsSiteMaterialUpload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPLOAD',
  SiteInventoryContractSettingsIcon = 'SITE_INVENTORY_CONTRACT_SETTINGS_______ICON',
  SiteSurveillanceIcon = 'SITE_SURVEILLANCE__________ICON',
  SiteSurveillanceView = 'SITE_SURVEILLANCE__________VIEW',
  SiteSurveillanceSiteImagesCreate = 'SITE_SURVEILLANCE_SITE_IMAGES_______CREATE',
  SiteSurveillanceSiteImagesView = 'SITE_SURVEILLANCE_SITE_IMAGES_______VIEW',
  SiteSurveillanceSiteImagesUpdate = 'SITE_SURVEILLANCE_SITE_IMAGES_______UPDATE',
  SiteSurveillanceSiteImagesDelete = 'SITE_SURVEILLANCE_SITE_IMAGES_______DELETE',
  SiteSurveillanceLiveVideocamView = 'SITE_SURVEILLANCE_LIVE_VIDEOCAM_______VIEW',
  SiteSurveillanceGeneralSettingsIcon = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_______ICON',
  SiteSurveillanceGeneralSettingsVideoCamSetupCreate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupView = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  SiteSurveillanceGeneralSettingsVideoCamSetupUpdate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupDelete = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  SiteSurveillanceContractSettingsIcon = 'SITE_SURVEILLANCE_CONTRACT_SETTINGS_______ICON',
  BusinessInsightIcon = 'BUSINESS_INSIGHT__________ICON',
  BusinessInsightView = 'BUSINESS_INSIGHT__________VIEW',
  BusinessInsightDefectStatusAnalysisView = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________VIEW',
  BusinessInsightDefectStatusAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________EXPORT',
  BusinessInsightDefectTradeAnalysisView = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________VIEW',
  BusinessInsightDefectTradeAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________EXPORT',
  BusinessInsightDefectSubconAnalysisView = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________VIEW',
  BusinessInsightDefectSubconAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________EXPORT',
  BusinessInsightDefectsLodgedOverTimeView = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________VIEW',
  BusinessInsightDefectsLodgedOverTimeExport = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________EXPORT',
  BusinessInsightGeneralSettingsIcon = 'BUSINESS_INSIGHT_GENERAL_SETTINGS__________ICON',
  BusinessInsightContractSettingsIcon = 'BUSINESS_INSIGHT_CONTRACT_SETTINGS__________ICON',
  DigitalDocumentIcon = 'DIGITAL_DOCUMENT__________ICON',
  DigitalDocumentView = 'DIGITAL_DOCUMENT__________VIEW',
  DigitalDocumentCreate = 'DIGITAL_DOCUMENT__________CREATE',
  DigitalDocumentDelete = 'DIGITAL_DOCUMENT__________DELETE',
  DigitalDocumentGeneralSettingsIcon = 'DIGITAL_DOCUMENT_GENERAL_SETTINGS__________ICON',
  DigitalDocumentContractSettingsIcon = 'DIGITAL_DOCUMENT_CONTRACT_SETTINGS__________ICON'
}

export type ContactEntity = {
  __typename?: 'ContactEntity';
  contact_id: Scalars['String'];
  associate_id: Scalars['String'];
  name: Scalars['String'];
  phone_no?: Maybe<Scalars['String']>;
  office_phone_no?: Maybe<Scalars['String']>;
  mobile_no?: Maybe<Scalars['String']>;
  salutation: Scalars['String'];
  email: Scalars['String'];
  designation: Scalars['String'];
  doc_ref_table: Scalars['String'];
  account_id: Scalars['String'];
  record_status: RecordStatus;
};

export type ContactInput = {
  ContactID?: Maybe<Scalars['String']>;
  AssociateID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  DocRefTable?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export enum ContractPermission {
  HomeExecSummaryView = 'HOME_EXEC_SUMMARY_____________VIEW',
  HomeContractView = 'HOME_CONTRACT_____________VIEW',
  HomeContractCreate = 'HOME_CONTRACT_____________CREATE',
  HomeContractExecSummaryView = 'HOME_CONTRACT_EXEC_SUMMARY__________VIEW',
  HomeContractExecSummaryContractBudgetView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_BUDGET_______VIEW',
  HomeContractExecSummaryContractInfoView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INFO_______VIEW',
  HomeContractExecSummaryContractInfoUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INFO_______UPDATE',
  HomeContractExecSummaryContractGuaranteeView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______VIEW',
  HomeContractExecSummaryContractGuaranteeCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______CREATE',
  HomeContractExecSummaryContractGuaranteeUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______UPDATE',
  HomeContractExecSummaryContractGuaranteeCancel = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______CANCEL',
  HomeContractExecSummaryContractInsuranceView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______VIEW',
  HomeContractExecSummaryContractInsuranceCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______CREATE',
  HomeContractExecSummaryContractInsuranceUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______UPDATE',
  HomeContractExecSummaryContractInsuranceCancel = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______CANCEL',
  HomeContractExecSummaryContractVoView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______VIEW',
  HomeContractExecSummaryContractVoCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______CREATE',
  HomeContractExecSummaryContractVoDraft = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______DRAFT',
  HomeContractExecSummaryContractVoUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______UPDATE',
  HomeContractExecSummaryContractVoApprove = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______APPROVE',
  HomeContractExecSummaryContractVoReject = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______REJECT',
  HomeContractExecSummaryContractVoHealthIndexView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______VIEW',
  HomeContractExecSummaryContractVoHealthIndexDownload = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______DOWNLOAD',
  HomeContractExecSummaryContractVoHealthIndexExport = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______EXPORT',
  HomeContractExecSummaryContractAccountStatusView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS____VIEW',
  HomeContractExecSummaryContractAccountStatusClientProfileView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CLIENT_PROFILE____VIEW',
  HomeContractExecSummaryContractAccountStatusAdvancesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_ADVANCES____VIEW',
  HomeContractExecSummaryContractAccountStatusProgressClaimView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_PROGRESS_CLAIM____VIEW',
  HomeContractExecSummaryContractAccountStatusRetentionClaimView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_RETENTION_CLAIM____VIEW',
  HomeContractExecSummaryContractAccountStatusDebitNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_DEBIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusCreditNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CREDIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusReceiptsView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_RECEIPTS____VIEW',
  HomeContractExecSummaryContractAccountStatusRefundsView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_REFUNDS____VIEW',
  HomeContractExecSummaryContractAccountStatusClientDebitNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CLIENT_DEBIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusOsReceivableView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_OS_RECEIVABLE____VIEW',
  HomeContractExecSummaryMiscExpensesView = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______VIEW',
  HomeContractExecSummaryMiscExpensesCreate = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______CREATE',
  HomeContractExecSummaryMiscExpensesUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______UPDATE',
  HomeContractExecSummaryMiscExpensesCancel = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______CANCEL',
  HomeContractExecSummaryRetentionView = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______VIEW',
  HomeContractExecSummaryRetentionCreate = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______CREATE',
  HomeContractExecSummaryRetentionUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______UPDATE',
  HomeContractExecSummaryRetentionAttachmentDownload = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_ATTACHMENT____DOWNLOAD',
  HomeContractExecSummaryExtensionOfTimeView = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______VIEW',
  HomeContractExecSummaryExtensionOfTimeCreate = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______CREATE',
  HomeContractExecSummaryExtensionOfTimeAttachmentDownload = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_ATTACHMENT____DOWNLOAD',
  HomeContractExecSummaryContractClosureView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_CLOSURE_______VIEW',
  HomeContractExecSummaryContractTerminationView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_TERMINATION_______VIEW',
  HomeCustomerView = 'HOME_CUSTOMER_____________VIEW',
  HomeCustomerExecSummaryView = 'HOME_CUSTOMER_EXEC_SUMMARY__________VIEW',
  HomeCustomerExecSummaryCustomerProfileView = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______VIEW',
  HomeCustomerExecSummaryCustomerProfileUpdate = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______UPDATE',
  HomeCustomerExecSummaryCustomerProfileCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______CREATE',
  HomeCustomerExecSummaryAccountTransactionAdvancesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____CREATE',
  HomeCustomerExecSummaryAccountTransactionAdvancesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____VIEW',
  HomeCustomerExecSummaryAccountTransactionAdvancesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionProgressClaimView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____VIEW',
  HomeCustomerExecSummaryAccountTransactionProgressClaimApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____APPROVE',
  HomeCustomerExecSummaryAccountTransactionProgressClaimReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____REJECT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____CANCEL',
  HomeCustomerExecSummaryAccountTransactionProgressClaimCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____CREATE',
  HomeCustomerExecSummaryAccountTransactionProgressClaimResubmit = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____RESUBMIT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____DRAFT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____VIEW',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____APPROVE',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____REJECT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____CANCEL',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____CREATE',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimResubmit = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____RESUBMIT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____DRAFT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionDebitNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionDebitNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionDebitNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionDebitNotesDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionCreditNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionCreditNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionCreditNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionCreditNotesDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionCreditNotesAllocate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____ALLOCATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____VIEW',
  HomeCustomerExecSummaryAccountTransactionReceiptsAllocate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____ALLOCATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____CANCEL',
  HomeCustomerExecSummaryAccountTransactionReceiptsCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____CREATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionRefundsView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____VIEW',
  HomeCustomerExecSummaryAccountTransactionRefundsDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____DRAFT',
  HomeCustomerExecSummaryAccountTransactionRefundsCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____CANCEL',
  HomeCustomerExecSummaryAccountTransactionRefundsCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____CREATE',
  HomeCustomerExecSummaryAccountTransactionRefundsApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____APPROVE',
  HomeCustomerExecSummaryAccountTransactionRefundsReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____REJECT',
  HomeCustomerExecSummaryAccountTransactionRefundsDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionOsReceivableView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_OS_RECEIVABLE____VIEW',
  HomeCustomerExecSummaryCreditControlCustomerListingView = 'HOME_CUSTOMER_EXEC_SUMMARY_CREDIT_CONTROL_CUSTOMER_LISTING____VIEW',
  HomeCustomerExecSummaryStatementOfAccountCustomerAccountListingView = 'HOME_CUSTOMER_EXEC_SUMMARY_STATEMENT_OF_ACCOUNT_CUSTOMER_ACCOUNT_LISTING____VIEW',
  HomeSubcontractView = 'HOME_SUBCONTRACT_____________VIEW',
  HomeSubcontractCreate = 'HOME_SUBCONTRACT_____________CREATE',
  HomeSubcontractExecSummaryView = 'HOME_SUBCONTRACT_EXEC_SUMMARY__________VIEW',
  HomeSubcontractExecSummarySubcontractInfoView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INFO_______VIEW',
  HomeSubcontractExecSummarySubcontractInfoUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INFO_______UPDATE',
  HomeSubcontractExecSummarySubcontractGuaranteeCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______CREATE',
  HomeSubcontractExecSummarySubcontractGuaranteeView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______VIEW',
  HomeSubcontractExecSummarySubcontractGuaranteeUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______UPDATE',
  HomeSubcontractExecSummarySubcontractGuaranteeCancel = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______CANCEL',
  HomeSubcontractExecSummarySubcontractInsuracneCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______CREATE',
  HomeSubcontractExecSummarySubcontractInsuracneView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______VIEW',
  HomeSubcontractExecSummarySubcontractInsuracneCancel = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______CANCEL',
  HomeSubcontractExecSummarySubcontractInsuracneUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______UPDATE',
  HomeSubcontractExecSummarySubcontractVoCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______CREATE',
  HomeSubcontractExecSummarySubcontractVoUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______UPDATE',
  HomeSubcontractExecSummarySubcontractVoApprove = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______APPROVE',
  HomeSubcontractExecSummarySubcontractVoReject = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______REJECT',
  HomeSubcontractExecSummarySubcontractVoDraft = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______DRAFT',
  HomeSubcontractExecSummarySubcontractVoView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusSubcontractorProfileView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_SUBCONTRACTOR_PROFILE____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusAdvancesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_ADVANCES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusProgressClaimView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_PROGRESS_CLAIM____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusRetentionClaimView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_RETENTION_CLAIM____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusDebitNotesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_DEBIT_NOTES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusCreditNotesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_CREDIT_NOTES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusPaymentsView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_PAYMENTS____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusRefundsView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_REFUNDS____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusDnToSubconView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_DN_TO_SUBCON____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusOsPayableView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_OS_PAYABLE____VIEW',
  HomeSubcontractExecSummaryRetentionView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______VIEW',
  HomeSubcontractExecSummaryRetentionCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______CREATE',
  HomeSubcontractExecSummaryRetentionUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______UPDATE',
  HomeSubcontractExecSummaryRetentionAttachmentDownload = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_ATTACHMENT____DOWNLOAD',
  HomeSubcontractExecSummaryExtensionOfTimeView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______VIEW',
  HomeSubcontractExecSummaryExtensionOfTimeCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______CREATE',
  HomeSubcontractExecSummaryExtensionOfTimeAttachmentDownload = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_ATTACHMENT____DOWNLOAD',
  HomeSubcontractExecSummarySubcontractClosureCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_CLOSURE_______CREATE',
  HomeSubcontractExecSummarySubcontractTerminationCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_TERMINATION_______CREATE',
  HomeSubcontractorAccountCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_____________CREATE',
  HomeSubcontractorAccountView = 'HOME_SUBCONTRACTOR_ACCOUNT_____________VIEW',
  HomeSubcontractorAccountExecSummaryView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY__________VIEW',
  HomeSubcontractorAccountExecSummarySubcontractorProfileView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_SUBCONTRACTOR_PROFILE_______VIEW',
  HomeSubcontractorAccountExecSummarySubcontractorProfileUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_SUBCONTRACTOR_PROFILE_______UPDATE',
  HomeSubcontractorAccountExecSummaryAdvancesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______CREATE',
  HomeSubcontractorAccountExecSummaryAdvancesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______VIEW',
  HomeSubcontractorAccountExecSummaryAdvancesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______UPDATE',
  HomeSubcontractorAccountExecSummaryAdvancesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______CANCEL',
  HomeSubcontractorAccountExecSummaryProgressClaimView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______VIEW',
  HomeSubcontractorAccountExecSummaryProgressClaimApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______APPROVE',
  HomeSubcontractorAccountExecSummaryProgressClaimReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______REJECT',
  HomeSubcontractorAccountExecSummaryProgressClaimCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______CANCEL',
  HomeSubcontractorAccountExecSummaryProgressClaimCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______CREATE',
  HomeSubcontractorAccountExecSummaryProgressClaimResubmit = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______RESUBMIT',
  HomeSubcontractorAccountExecSummaryProgressClaimDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______DRAFT',
  HomeSubcontractorAccountExecSummaryProgressClaimDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______DOWNLOAD',
  HomeSubcontractorAccountExecSummaryRetentionClaimView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______VIEW',
  HomeSubcontractorAccountExecSummaryRetentionClaimApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______APPROVE',
  HomeSubcontractorAccountExecSummaryRetentionClaimReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______REJECT',
  HomeSubcontractorAccountExecSummaryRetentionClaimCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______CANCEL',
  HomeSubcontractorAccountExecSummaryRetentionClaimCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______CREATE',
  HomeSubcontractorAccountExecSummaryRetentionClaimResubmit = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______RESUBMIT',
  HomeSubcontractorAccountExecSummaryRetentionClaimDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______DRAFT',
  HomeSubcontractorAccountExecSummaryRetentionClaimDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______DOWNLOAD',
  HomeSubcontractorAccountExecSummaryDebitNotesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______CREATE',
  HomeSubcontractorAccountExecSummaryDebitNotesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______VIEW',
  HomeSubcontractorAccountExecSummaryDebitNotesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______UPDATE',
  HomeSubcontractorAccountExecSummaryDebitNotesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______CANCEL',
  HomeSubcontractorAccountExecSummaryCreditNotesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______CREATE',
  HomeSubcontractorAccountExecSummaryCreditNotesAllocate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______ALLOCATE',
  HomeSubcontractorAccountExecSummaryCreditNotesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______UPDATE',
  HomeSubcontractorAccountExecSummaryCreditNotesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______VIEW',
  HomeSubcontractorAccountExecSummaryCreditNotesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______CANCEL',
  HomeSubcontractorAccountExecSummaryPaymentsCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______CREATE',
  HomeSubcontractorAccountExecSummaryPaymentsAttachmentDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_ATTACHMENT____DOWNLOAD',
  HomeSubcontractorAccountExecSummaryPaymentsView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______VIEW',
  HomeSubcontractorAccountExecSummaryPaymentsDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______DRAFT',
  HomeSubcontractorAccountExecSummaryPaymentsApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______APPROVE',
  HomeSubcontractorAccountExecSummaryPaymentsReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______REJECT',
  HomeSubcontractorAccountExecSummaryPaymentsCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______CANCEL',
  HomeSubcontractorAccountExecSummaryDnToSubconView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______VIEW',
  HomeSubcontractorAccountExecSummaryDnToSubconUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______UPDATE',
  HomeSubcontractorAccountExecSummaryDnToSubconCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______CANCEL',
  HomeSubcontractorAccountExecSummaryDnToSubconAttachmentDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_ATTACHMENT____DOWNLOAD',
  HomeSubcontractorAccountExecSummaryOsPayableView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_OS_PAYABLE_______VIEW',
  HomeProjectPurchasesContractListingView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING__________VIEW',
  HomeProjectPurchasesContractListingExecSummaryView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_______VIEW',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____CREATE',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____VIEW',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____REJECT',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____CREATE',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____VIEW',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____UPDATE',
  HomeSupplierAccountView = 'HOME_SUPPLIER_ACCOUNT_____________VIEW',
  HomeSupplierAccountCreate = 'HOME_SUPPLIER_ACCOUNT_____________CREATE',
  HomeSupplierAccountExecSummaryView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY__________VIEW',
  HomeSupplierAccountExecSummarySupplierProfileView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_PROFILE_______VIEW',
  HomeSupplierAccountExecSummarySupplierProfileUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_PROFILE_______UPDATE',
  HomeSupplierAccountExecSummaryPrefSupplierProductsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______VIEW',
  HomeSupplierAccountExecSummaryPrefSupplierProductsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______CREATE',
  HomeSupplierAccountExecSummaryPrefSupplierProductsDelete = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______DELETE',
  HomeSupplierAccountExecSummarySupplierAccountsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_______VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsAllocate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____ALLOCATE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsAttachmentDownload = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS_ATTACHMENT_DOWNLOAD',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsApprove = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____APPROVE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsReject = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____REJECT',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesAllocate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____ALLOCATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsOsPayableView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_OS_PAYABLE____VIEW',
  HomePlantAndMachineryExecSummaryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY__________VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______CREATE',
  HomePlantAndMachineryExecSummaryOwnedPnmUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______UPDATE',
  HomePlantAndMachineryExecSummaryOwnedPnmAssignmentHistoryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_ASSIGNMENT_HISTORY____VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmMaintenanceHistoryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_MAINTENANCE_HISTORY____VIEW',
  HomePlantAndMachineryExecSummaryRentedPnmCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______CREATE',
  HomePlantAndMachineryExecSummaryRentedPnmView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______VIEW',
  HomePlantAndMachineryExecSummaryRentedPnmUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______UPDATE',
  HomePlantAndMachineryExecSummaryRentedPnmReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______RETURN',
  HomePlantAndMachineryExecSummaryAssignmentRecordsCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______CREATE',
  HomePlantAndMachineryExecSummaryAssignmentRecordsView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______VIEW',
  HomePlantAndMachineryExecSummaryAssignmentRecordsUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______UPDATE',
  HomePlantAndMachineryExecSummaryAssignmentRecordsCancel = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______CANCEL',
  HomePlantAndMachineryExecSummaryAssignmentRecordsReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______RETURN',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______CREATE',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______VIEW',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______UPDATE',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsCancel = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______CANCEL',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______RETURN',
  HomeBusinessInsightView = 'HOME_BUSINESS_INSIGHT_____________VIEW',
  HomeBusinessInsightContractListingProjectCostingView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_______VIEW',
  HomeBusinessInsightContractListingProjectCostingAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCostingAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCostingSubcontractCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_SUBCONTRACT_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingMaterialCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_MATERIAL_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingPmCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_PM_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingLabourCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_LABOUR_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingMiscCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_MISC_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLAttachmentView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT____VIEW',
  HomeBusinessInsightContractListingProjectPLAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectPLAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectPLRevisedContractSumView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_REVISED_CONTRACT_SUM____VIEW',
  HomeBusinessInsightContractListingProjectPLProgressIncomeView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_PROGRESS_INCOME____VIEW',
  HomeBusinessInsightContractListingProjectPLSubcontractCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_SUBCONTRACT_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLMaterialCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_MATERIAL_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLPmCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_PM_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLLabourCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_LABOUR_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLMiscCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_MISC_COST____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCashFlowAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCashFlowView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_______VIEW',
  HomeBusinessInsightContractListingProjectCashFlowReceivedFromClientView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowPaidToSubcontractorView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowPaidToSupplierView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowOtherExpensesView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_OTHER_EXPENSES____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowNetCashFlowView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_NET_CASH_FLOW____VIEW',
  HomeBusinessInsightContractListingProjectCostPerformanceView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_______VIEW',
  HomeBusinessInsightContractListingProjectCostPerformanceAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCostPerformanceAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCostPerformanceCompletionPercCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_COMPLETION_PERC____CREATE',
  HomeBusinessInsightContractListingProjectFinancialStatusView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_______VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectFinancialStatusAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectFinancialStatusCompletionPercCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COMPLETION_PERC____CREATE',
  HomeBusinessInsightContractListingProjectFinancialStatusBillingToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_BILLING_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusCollectionToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COLLECTION_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusCostToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COST_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusPaymentToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_PAYMENT_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectBudgetAnalysisAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectBudgetAnalysisOriginalBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ORIGINAL_BUDGET____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisRevisedBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_REVISED_BUDGET____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisBudgetVarianceView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_BUDGET_VARIANCE____VIEW',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_______VIEW',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingContractBudgetOriginalBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_ORIGINAL_BUDGET____VIEW',
  HomeBusinessInsightContractListingContractBudgetOriginalBudgetCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_ORIGINAL_BUDGET____CREATE',
  HomeBusinessInsightContractListingContractBudgetRevisedBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_REVISED_BUDGET____VIEW',
  HomeBusinessInsightContractListingContractBudgetRevisedBudgetCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_REVISED_BUDGET____CREATE',
  HomeSiteManagementSiteListingExecSummaryView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_______VIEW',
  HomeSiteManagementSiteListingExecSummarySiteWorkerAttendanceView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_WORKER_ATTENDANCE____VIEW',
  HomeSiteManagementSiteListingExecSummaryGrnView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____VIEW',
  HomeSiteManagementSiteListingExecSummaryGrnCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____CREATE',
  HomeSiteManagementSiteListingExecSummaryGrnUpdate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____UPDATE',
  HomeSiteManagementSiteListingExecSummarySiteStockTrackingView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_STOCK_TRACKING____VIEW',
  HomeSiteManagementSiteListingExecSummarySiteStockTrackingCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_STOCK_TRACKING____CREATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____CREATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____VIEW',
  HomeSiteManagementSiteListingExecSummarySiteProgressUpdate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____UPDATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressDelete = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____DELETE',
  HomeSiteManagementSiteListingExecSummaryLiveVideoCamView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_LIVE_VIDEO_CAM____VIEW',
  HomeSiteManagementSiteListingExecSummaryLiveVideoCamRecord = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_LIVE_VIDEO_CAM____RECORD',
  HomeDefectManagementContractListingView = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING__________VIEW',
  HomeDefectManagementContractListingDefectListingCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______CREATE',
  HomeDefectManagementContractListingDefectListingUpdate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______UPDATE',
  HomeDefectManagementContractListingDefectListingView = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______VIEW',
  HomeDefectManagementContractListingDefectListingReAssignDefectCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_RE_ASSIGN_DEFECT____CREATE',
  HomeDefectManagementContractListingDefectListingActivityLogCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_ACTIVITY_LOG____CREATE',
  HomeGlInterfaceView = 'HOME_GL_INTERFACE_____________VIEW',
  HomeGlInterfaceAttachmentDownload = 'HOME_GL_INTERFACE__________ATTACHMENT_DOWNLOAD',
  HomeGlInterfaceContractView = 'HOME_GL_INTERFACE_CONTRACT__________VIEW',
  HomeGlInterfaceSubcontractView = 'HOME_GL_INTERFACE_SUBCONTRACT__________VIEW',
  HomeGlInterfaceSupplierView = 'HOME_GL_INTERFACE_SUPPLIER__________VIEW',
  HomeWorkDeskToDoView = 'HOME_WORK_DESK_TO_DO__________VIEW',
  HomeWorkDeskToDoApprove = 'HOME_WORK_DESK_TO_DO__________APPROVE',
  HomeWorkDeskToDoReject = 'HOME_WORK_DESK_TO_DO__________REJECT',
  HomeWorkDeskNotificationView = 'HOME_WORK_DESK_NOTIFICATION__________VIEW',
  GeneralSettingExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_____________VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES__________VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____CREATE',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryEdit = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____EDIT',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____CREATE',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____UPDATE',
  GeneralSettingExecSummarySubcontractPackagesView = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________VIEW',
  GeneralSettingExecSummarySubcontractPackagesCreate = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________CREATE',
  GeneralSettingExecSummarySubcontractPackagesUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________UPDATE',
  GeneralSettingExecSummarySubcontractPackagesDelete = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________DELETE',
  GeneralSettingExecSummaryPmCategoryView = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________VIEW',
  GeneralSettingExecSummaryPmCategoryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________CREATE',
  GeneralSettingExecSummaryPmCategoryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________UPDATE',
  GeneralSettingExecSummaryPmCategoryDelete = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________DELETE',
  GeneralSettingExecSummaryBuildingMaterialsCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________CREATE',
  GeneralSettingExecSummaryBuildingMaterialsView = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________VIEW',
  GeneralSettingExecSummaryBuildingMaterialsUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________UPDATE',
  GeneralSettingExecSummaryBuildingMaterialsDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________DELETE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______CREATE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingView = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______VIEW',
  GeneralSettingExecSummaryBuildingMaterialsItemListingUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______UPDATE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______DELETE',
  GeneralSettingExecSummaryLabourCategoryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________CREATE',
  GeneralSettingExecSummaryLabourCategoryView = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________VIEW',
  GeneralSettingExecSummaryLabourCategoryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________UPDATE',
  GeneralSettingExecSummaryLabourCategoryDelete = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________DELETE',
  GeneralSettingExecSummaryMiscExpenseCreate = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________CREATE',
  GeneralSettingExecSummaryMiscExpenseView = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________VIEW',
  GeneralSettingExecSummaryMiscExpenseUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________UPDATE',
  GeneralSettingExecSummaryMiscExpenseDelete = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________DELETE',
  GeneralSettingExecSummaryDocumentNumberingView = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________VIEW',
  GeneralSettingExecSummaryDocumentNumberingCreate = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________CREATE',
  GeneralSettingExecSummaryDocumentNumberingUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________UPDATE',
  GeneralSettingExecSummaryBankAccountView = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________VIEW',
  GeneralSettingExecSummaryBankAccountCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________CREATE',
  GeneralSettingExecSummaryBankAccountUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________UPDATE',
  GeneralSettingExecSummaryBankAccountDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________DELETE',
  GeneralSettingPlatformCostCenterView = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________VIEW',
  GeneralSettingPlatformCostCenterCreate = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________CREATE',
  GeneralSettingPlatformCostCenterUpdate = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________UPDATE',
  GeneralSettingPlatformCostCenterDelete = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________DELETE',
  GeneralSettingExecSummaryUomView = 'GENERAL_SETTING_EXEC_SUMMARY_UOM__________VIEW',
  GeneralSettingPlatformGuaranteeTypeView = 'GENERAL_SETTING_PLATFORM_GUARANTEE_TYPE__________VIEW',
  GeneralSettingPlatformInsuranceTypeView = 'GENERAL_SETTING_PLATFORM_INSURANCE_TYPE__________VIEW',
  GeneralSettingExecSummaryTaxSetupView = 'GENERAL_SETTING_EXEC_SUMMARY_TAX_SETUP__________VIEW'
}

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreDocTypeEntity = AuditEntity & {
  __typename?: 'CoreDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CoreNumberFormatEntity = AuditEntity & {
  __typename?: 'CoreNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CoreRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'CoreRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
};

export type CostCentreCoaDeleteInput = {
  CostCentreCOAID: Scalars['String'];
};

export type CostCentreCoaEntity = AuditEntity & {
  __typename?: 'CostCentreCOAEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreCOAID: Scalars['String'];
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
};

export type CostCentreCoaInput = {
  CostCentreCOAID?: Maybe<Scalars['String']>;
  CostCentreID: Scalars['String'];
  MasterCOAID: Scalars['String'];
};

export type CostCentreDefSummary = {
  __typename?: 'CostCentreDefSummary';
  CostCentreDefinitionHeader?: Maybe<CostCentreDefinitionHeaderEntity>;
  CostCentreDefinitionDetail?: Maybe<Array<CostCentreDefinitionDetailEntity>>;
};

export type CostCentreDefinitionAsgmtEntity = AuditEntity & {
  __typename?: 'CostCentreDefinitionAsgmtEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreDefinitionAsgmtID: Scalars['String'];
  CompanyID: Scalars['String'];
  CostCentreDefinitionHeaderID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  CostCentreDefinitionHeader?: Maybe<CostCentreDefinitionHeaderEntity>;
};

export type CostCentreDefinitionAsgmtInput = {
  CostCentreDefinitionAsgmtID?: Maybe<Scalars['String']>;
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};

export type CostCentreDefinitionDetailDeleteInput = {
  CostCentreDefinitionDetailID: Scalars['String'];
};

export type CostCentreDefinitionDetailEntity = AuditEntity & {
  __typename?: 'CostCentreDefinitionDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreDefinitionDetailID: Scalars['String'];
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  ParentCostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreDefinitionHeader?: Maybe<CostCentreDefinitionHeaderEntity>;
};

export type CostCentreDefinitionDetailInput = {
  Name: Scalars['String'];
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  CostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  ParentCostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};

export type CostCentreDefinitionDetailV2Entity = AuditEntity & {
  __typename?: 'CostCentreDefinitionDetailV2Entity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreDefinitionDetailID: Scalars['String'];
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  ParentCostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreDefinitionHeader?: Maybe<CostCentreDefinitionHeaderEntity>;
  CostCentreDefinitionDetailV2ID: Scalars['String'];
  ParentCostCentreDefinitionDetailV2ID: Scalars['String'];
};

export type CostCentreDefinitionHeaderDeleteInput = {
  CostCentreDefinitionHeaderID: Scalars['String'];
};

export type CostCentreDefinitionHeaderEntity = AuditEntity & {
  __typename?: 'CostCentreDefinitionHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreDefinitionHeaderID: Scalars['String'];
  Title: Scalars['String'];
  CostCentreDefConnection?: Maybe<Array<CostCentreDefinitionAsgmtEntity>>;
  CostCentreDefinitionDetail?: Maybe<Array<CostCentreDefinitionDetailEntity>>;
};

export type CostCentreDefinitionHeaderInput = {
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  Title: Scalars['String'];
};

export type CostCentreDeleteInput = {
  CostCentreID: Scalars['String'];
};

export type CostCentreEntity = AuditEntity & {
  __typename?: 'CostCentreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreID: Scalars['String'];
  ParentCostCentreID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  Name: Scalars['String'];
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  IsDefault: Scalars['Boolean'];
  Code?: Maybe<Scalars['String']>;
  MasterCode?: Maybe<Scalars['String']>;
  CostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  BudgetItemCostCentre?: Maybe<Array<BudgetItemCostCentreEntity>>;
  JournalItem?: Maybe<Array<JournalItemEntity>>;
  LedgerItem?: Maybe<Array<LedgerItemEntity>>;
  ExternalJournalItem?: Maybe<Array<ExternalJournalItemEntity>>;
  JournalProcessingItem?: Maybe<Array<JournalProcessingItemEntity>>;
  RecurringJournalGenerationItem?: Maybe<Array<RecurringJournalGenerationItemEntity>>;
  RecurringJournalItem?: Maybe<Array<RecurringJournalItemEntity>>;
  FavoriteJournalProcessingItem?: Maybe<Array<FavoriteJournalProcessingItemEntity>>;
  PaymentItem?: Maybe<Array<PaymentItemEntity>>;
  ReceiptItem?: Maybe<Array<ReceiptItemEntity>>;
  FavoritePaymentItem?: Maybe<Array<FavoritePaymentItemEntity>>;
  FavoriteReceiptItem?: Maybe<Array<FavoriteReceiptItemEntity>>;
  FavoriteInvoiceItem?: Maybe<Array<AccArFavoriteInvoiceItemEntity>>;
  APFavoriteInvoiceItem?: Maybe<Array<AccApFavoriteInvoiceItemEntity>>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  ARInvoiceItem?: Maybe<Array<AccArInvoiceItemEntity>>;
  AuditAdjustmentItem?: Maybe<Array<AuditAdjustmentItemEntity>>;
  ARCreditNote?: Maybe<Array<AccArCreditNoteEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  APCreditNote?: Maybe<Array<AccApCreditNoteEntity>>;
  APCreditorCreditNote?: Maybe<Array<AccApCreditorCreditNoteEntity>>;
  APCreditorCreditNoteItem?: Maybe<Array<AccApCreditorCreditNoteItemEntity>>;
  Warehouse?: Maybe<Array<WarehouseEntity>>;
  BankAccount?: Maybe<Array<AccBankAccountEntity>>;
  StockReceipt?: Maybe<Array<StockReceiptEntity>>;
  StockIssue?: Maybe<Array<StockIssueEntity>>;
  StockAdjustment?: Maybe<Array<StockAdjustmentEntity>>;
  StockTransfer?: Maybe<Array<StockTransferEntity>>;
  CycleCount?: Maybe<Array<CycleCountEntity>>;
  StockCostAdjustment?: Maybe<Array<StockCostAdjustmentEntity>>;
  StockRequisition?: Maybe<Array<StockRequisitionEntity>>;
  CostCentreCOA?: Maybe<Array<CostCentreCoaEntity>>;
};

export type CostCentreInput = {
  CostCentreID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  ParentCostCentreID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Level?: Maybe<Scalars['Float']>;
  Code?: Maybe<Scalars['String']>;
  MasterCode?: Maybe<Scalars['String']>;
  IsLastNode: Scalars['Boolean'];
};

export type CostCentreTypeDeleteInput = {
  CostCentreTypeID: Scalars['String'];
};

export type CostCentreTypeEntity = AuditEntity & {
  __typename?: 'CostCentreTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CostCentreTypeID: Scalars['String'];
  Name: Scalars['String'];
};

export type CostCentreTypeInput = {
  CostCentreTypeID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export type CreditCardTypeDeleteInput = {
  CreditCardID: Scalars['String'];
};

export type CreditCardTypeEntity = AuditEntity & {
  __typename?: 'CreditCardTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditCardID: Scalars['String'];
  Name: Scalars['String'];
  Description: Scalars['String'];
  Sequence: Scalars['Float'];
  IsSystem: Scalars['Boolean'];
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
  APRefund?: Maybe<Array<AccApRefundEntity>>;
  AROfficialReceipt?: Maybe<Array<AccArOfficialReceiptEntity>>;
  ARRefund?: Maybe<Array<AccArRefundEntity>>;
  Payment?: Maybe<Array<PaymentEntity>>;
  Receipt?: Maybe<Array<ReceiptEntity>>;
  BankTransfer?: Maybe<Array<BankTransferEntity>>;
  FavoritePayment?: Maybe<Array<FavoritePaymentEntity>>;
  FavoriteReceipt?: Maybe<Array<FavoriteReceiptEntity>>;
  ARAdvance?: Maybe<Array<AccArAdvanceEntity>>;
  APAdvance?: Maybe<Array<AccArAdvanceEntity>>;
};

export type CreditCardTypeInput = {
  CreditCardID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Description: Scalars['String'];
  Sequence: Scalars['Float'];
  IsSystem: Scalars['Boolean'];
  IsUsed?: Maybe<Scalars['Boolean']>;
};

export type CreditorAccountCompanyAssignmentDeleteInput = {
  CreditorAccountCompanyAssignmentID: Scalars['String'];
};

export type CreditorAccountCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'CreditorAccountCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorAccountCompanyAssignmentID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  CompanyID: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
};

export type CreditorAccountCompanyAssignmentInput = {
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  CreditorAccountID: Scalars['String'];
  companyID: Array<Scalars['String']>;
};

export type CreditorAccountDeleteInput = {
  CreditorAccountID: Scalars['String'];
};

export type CreditorAccountEntity = AuditEntity & {
  __typename?: 'CreditorAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorAccountID: Scalars['String'];
  CreditorTypeID: Scalars['String'];
  CreditorType?: Maybe<CreditorTypeEntity>;
  CompanyRegNo: Scalars['String'];
  CompanyName: Scalars['String'];
  TaxIdentificationNo?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  CreditTerm: Scalars['Float'];
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  Region?: Maybe<Scalars['String']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['JSON']>;
  CreditorTypeEnum: CreditorType;
  CreditorStatus: CreditorStatus;
  Advance?: Maybe<Array<AccApAdvanceEntity>>;
  Invoice?: Maybe<Array<AccApInvoiceEntity>>;
  CreditNote?: Maybe<Array<AccApCreditNoteEntity>>;
  CreditorCreditNote?: Maybe<Array<AccApCreditorCreditNoteEntity>>;
  DebitNote?: Maybe<Array<AccApDebitNoteEntity>>;
  Refund?: Maybe<Array<AccApRefundEntity>>;
  Payment?: Maybe<Array<AccApPaymentEntity>>;
  FavInvoice?: Maybe<Array<AccApInvoiceEntity>>;
  CreditorContact?: Maybe<Array<CreditorContactEntity>>;
  CreditorDirector?: Maybe<Array<CreditorDirectorEntity>>;
  CreditorAccountCompanyAssignment?: Maybe<Array<CreditorAccountCompanyAssignmentEntity>>;
};

export type CreditorAccountInput = {
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeID: Scalars['String'];
  CompanyRegNo: Scalars['String'];
  CompanyName: Scalars['String'];
  ContactNo?: Maybe<Scalars['String']>;
  TaxIdentificationNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  CreditTerm: Scalars['Float'];
  TaxSchemeID?: Maybe<Scalars['String']>;
  Region?: Maybe<Scalars['String']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  BankName?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  SWIFTCode?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  CreditorTypeEnum: CreditorType;
  CreditorStatus: CreditorStatus;
  Address?: Maybe<Scalars['JSON']>;
};

export type CreditorContactDeleteInput = {
  CreditorContactID: Scalars['String'];
};

export type CreditorContactEntity = AuditEntity & {
  __typename?: 'CreditorContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorContactID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  Name: Scalars['String'];
  ContactNo: Scalars['String'];
  Designation: Scalars['String'];
  Email: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
};

export type CreditorContactInput = {
  CreditorContactID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  ContactNo: Scalars['String'];
  Designation: Scalars['String'];
  Email: Scalars['String'];
};

/** JsonType */
export type CreditorDebtorSubInput = {
  __typename?: 'CreditorDebtorSubInput';
  AdvanceTotalDocAmt?: Maybe<Scalars['Float']>;
  CreditNoteTotalDocAmt?: Maybe<Scalars['Float']>;
  DebitNoteTotalDocAmt?: Maybe<Scalars['Float']>;
  InvoiceTotalDocAmt?: Maybe<Scalars['Float']>;
};

export type CreditorDirectorDeleteInput = {
  CreditorDirectorID: Scalars['String'];
};

export type CreditorDirectorEntity = AuditEntity & {
  __typename?: 'CreditorDirectorEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorDirectorID: Scalars['String'];
  CreditorAccountID: Scalars['String'];
  IdentityNo: Scalars['String'];
  IdentityType?: Maybe<IdentityType>;
  Name: Scalars['String'];
  ContactNo: Scalars['String'];
  Email: Scalars['String'];
  CreditorAccount?: Maybe<CreditorAccountEntity>;
};

export type CreditorDirectorInput = {
  CreditorDirectorID?: Maybe<Scalars['String']>;
  IdentityNo: Scalars['String'];
  IdentityType?: Maybe<IdentityType>;
  Name?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
};

/** JsonType */
export type CreditorInput = {
  __typename?: 'CreditorInput';
  CreditorAccountID?: Maybe<Scalars['String']>;
  TotalDocAmount?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type CreditorLatestUpdatedDateInput = {
  __typename?: 'CreditorLatestUpdatedDateInput';
  CreditorAccountID?: Maybe<Scalars['String']>;
  LatestUpdatedDate?: Maybe<Scalars['DateTime']>;
};

export enum CreditorStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export enum CreditorType {
  Supplier = 'SUPPLIER',
  Contractor = 'CONTRACTOR',
  Consultant = 'CONSULTANT',
  Agent = 'AGENT'
}

export type CreditorTypeDeleteInput = {
  CreditorTypeID: Scalars['String'];
};

export type CreditorTypeEntity = AuditEntity & {
  __typename?: 'CreditorTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CreditorTypeID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  CreditorType: CreditorType;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  AccrualCOAID?: Maybe<Scalars['String']>;
  AccrualCOA?: Maybe<MasterCoaEntity>;
  CreditorAccount?: Maybe<Array<CreditorAccountEntity>>;
};

export type CreditorTypeInput = {
  CreditorTypeID?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  MasterCOAID: Scalars['String'];
  AccrualCOAID: Scalars['String'];
  CreditorType: CreditorType;
};

export type CurrencyDeleteInput = {
  CurrencyID: Scalars['String'];
};

export type CurrencyEntity = AuditEntity & {
  __typename?: 'CurrencyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CurrencyID: Scalars['String'];
  Code: Scalars['String'];
  Name: Scalars['String'];
  Symbol?: Maybe<Scalars['String']>;
  Company?: Maybe<Array<CompanyEntity>>;
  FromCurrencyExchange?: Maybe<Array<AccCurrencyExchangeEntity>>;
  ToCurrencyExchangeTo?: Maybe<Array<AccCurrencyExchangeEntity>>;
  BankAccount?: Maybe<Array<AccBankAccountEntity>>;
  Ledger?: Maybe<Array<LedgerEntity>>;
  ExternalLedger?: Maybe<Array<ExternalLedgerEntity>>;
};

export type CurrencyExchangeDeleteInput = {
  CurrencyExchangeID: Scalars['String'];
};

export type CurrencyExchangeInput = {
  CurrencyExchangeID?: Maybe<Scalars['String']>;
  FromCurrencyID: Scalars['String'];
  ToCurrencyID: Scalars['String'];
  EffectiveDate?: Maybe<Scalars['String']>;
  FromAmt: Scalars['Float'];
  ToAmt: Scalars['Float'];
  ExchangeRate: Scalars['Float'];
  Symbol?: Maybe<Scalars['String']>;
  CurrencyLog?: Maybe<Array<CurrencyLogInput>>;
};

export type CurrencyInput = {
  CurrencyID?: Maybe<Scalars['String']>;
  Code: Scalars['String'];
  Name: Scalars['String'];
  Symbol?: Maybe<Scalars['String']>;
};

export type CurrencyLog = {
  __typename?: 'CurrencyLog';
  PrevFromAmt: Scalars['Float'];
  PrevToAmt: Scalars['Float'];
  ModTs: Scalars['DateTime'];
  ModBy: Scalars['DateTime'];
};

export type CurrencyLogInput = {
  PrevFromAmt: Scalars['Float'];
  PrevToAmt: Scalars['Float'];
  ModTs: Scalars['DateTime'];
  ModBy: Scalars['DateTime'];
};

export type CycleCountEntity = AuditEntity & {
  __typename?: 'CycleCountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  CycleCountID: Scalars['String'];
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
};

export type CycleCountItemEntity = AuditEntity & {
  __typename?: 'CycleCountItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  CycleCountItemID: Scalars['String'];
  OnHandQty?: Maybe<Scalars['Float']>;
  CountQty?: Maybe<Scalars['Float']>;
  AdjustQty?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  IsReconciled: Scalars['Boolean'];
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
  CycleCountID: Scalars['String'];
  CycleCount?: Maybe<CycleCountEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
};

export type DoHeaderEntity = AuditEntity & {
  __typename?: 'DOHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DOHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  SupplierID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DoNo?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DOItem?: Maybe<Array<DoItemEntity>>;
  /** CustomFieldResolver */
  Company: CompanyEntity;
  Supplier?: Maybe<CreditorAccountEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  UpToDateAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  PurchaseOrder?: Maybe<Array<Scalars['JSON']>>;
};


export type DoHeaderEntityUpToDateAmtArgs = {
  TaxInclusive?: Maybe<Scalars['Boolean']>;
};

export type DoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  DOHeaderID?: Maybe<Scalars['String']>;
  SupplierID: Scalars['String'];
  DoNo: Scalars['String'];
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus: ApprovalStatus;
  CompanyID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  ContactPerson?: Maybe<ContactPersonInput>;
  DeliveryAddress?: Maybe<AddressInput>;
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type DoItemEntity = AuditEntity & {
  __typename?: 'DOItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DOItemID: Scalars['String'];
  DOHeaderID: Scalars['String'];
  DOHeader?: Maybe<DoHeaderEntity>;
  POItemID?: Maybe<Scalars['String']>;
  POItem?: Maybe<PoItemEntity>;
  GRTNItemID?: Maybe<Scalars['String']>;
  ReplacedGRTNItem?: Maybe<GrtnItemEntity>;
  AcceptedQty: Scalars['Float'];
  DeliveredQty?: Maybe<Scalars['Float']>;
  AcceptedAmt?: Maybe<Scalars['Float']>;
  DeliveredAmt?: Maybe<Scalars['Float']>;
  GRTNItem?: Maybe<Array<GrtnItemEntity>>;
  /** CustomFieldResolver */
  UpToDateQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  ReturnedQty?: Maybe<Scalars['Float']>;
};

export type DoItemInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  GRTNHeaderID?: Maybe<Scalars['String']>;
  POItemsInput: Array<PoItemsInput>;
};

export type DoRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type DOsInput = {
  PurchaseOrderID: Scalars['String'];
  POItemID: Scalars['String'];
  ReceivedQty: Scalars['Float'];
};

export type DataSourceSettingsEntity = AuditEntity & {
  __typename?: 'DataSourceSettingsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DataSourceSettingsID: Scalars['String'];
  Name: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ColumnSource: ColumnSource;
  ColumnValue: AmountType;
  YearType: YearPeriodType;
  YearValue: Scalars['Float'];
  PeriodType?: Maybe<YearPeriodType>;
  PeriodValue?: Maybe<Scalars['Float']>;
  Duration: DurationType;
};


export type DebtorAccountDeleteInput = {
  DebtorAccountID: Scalars['String'];
};

export type DebtorAccountEntity = AuditEntity & {
  __typename?: 'DebtorAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DebtorAccountID: Scalars['String'];
  DebtorTypeID: Scalars['String'];
  DebtorType?: Maybe<DebtorTypeEntity>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CompanyRegNo: Scalars['String'];
  DebtorName: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  CreditTerm: Scalars['Float'];
  Amount?: Maybe<Scalars['Float']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  GSTStartDate?: Maybe<Scalars['String']>;
  IsVIP: Scalars['Boolean'];
  IsCompany: Scalars['Boolean'];
  Remark?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['JSON']>;
  DebtorContact?: Maybe<Array<DebtorContactEntity>>;
  Advance?: Maybe<Array<AccArAdvanceEntity>>;
  Invoice?: Maybe<Array<AccArInvoiceEntity>>;
  FavInvoice?: Maybe<Array<AccArFavoriteInvoiceEntity>>;
  CreditNote?: Maybe<Array<AccArCreditNoteEntity>>;
  DebitNote?: Maybe<Array<AccArDebitNoteEntity>>;
  Receipt?: Maybe<Array<AccArOfficialReceiptEntity>>;
  Refund?: Maybe<Array<AccArRefundEntity>>;
};

export type DebtorAccountInput = {
  DebtorAccountID?: Maybe<Scalars['String']>;
  DebtorTypeID: Scalars['String'];
  CompanyRegNo: Scalars['String'];
  DebtorName: Scalars['String'];
  MobileNo?: Maybe<Scalars['String']>;
  OfficeNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  CreditTerm: Scalars['Float'];
  TaxSchemeID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsVIP?: Maybe<Scalars['Boolean']>;
  IsCompany?: Maybe<Scalars['Boolean']>;
  GSTRegNo?: Maybe<Scalars['String']>;
  GSTStartDate?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['JSON']>;
};

export type DebtorContactDeleteInput = {
  DebtorContactID: Scalars['String'];
};

export type DebtorContactEntity = AuditEntity & {
  __typename?: 'DebtorContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DebtorContactID: Scalars['String'];
  DebtorAccountID: Scalars['String'];
  Name: Scalars['String'];
  ContactNo: Scalars['String'];
  Designation: Scalars['String'];
  Email: Scalars['String'];
  DebtorAccount?: Maybe<DebtorAccountEntity>;
};

export type DebtorContactInput = {
  DebtorContactID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  ContactNo: Scalars['String'];
  Designation: Scalars['String'];
  Email: Scalars['String'];
};

/** JsonType */
export type DebtorInput = {
  __typename?: 'DebtorInput';
  DebtorAccountID?: Maybe<Scalars['String']>;
  TotalDocAmount?: Maybe<Scalars['Float']>;
};

/** JsonType */
export type DebtorLatestUpdatedDateInput = {
  __typename?: 'DebtorLatestUpdatedDateInput';
  DebtorAccountID?: Maybe<Scalars['String']>;
  LatestUpdatedDate?: Maybe<Scalars['DateTime']>;
};

export type DebtorTypeDeleteInput = {
  DebtorTypeID: Scalars['String'];
};

export type DebtorTypeEntity = AuditEntity & {
  __typename?: 'DebtorTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DebtorTypeID: Scalars['String'];
  Name: Scalars['String'];
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  DebtorAccount?: Maybe<Array<DebtorAccountEntity>>;
};

export type DebtorTypeInput = {
  DebtorTypeID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  MasterCOAID: Scalars['String'];
};

export type DefaultUomEntity = AuditEntity & {
  __typename?: 'DefaultUomEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
};

export type DeliveryLocationDeleteInput = {
  DeliveryLocationID: Scalars['String'];
};

export type DeliveryLocationEntity = AuditEntity & {
  __typename?: 'DeliveryLocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DeliveryLocationID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  Name: Scalars['String'];
  Address: Scalars['JSON'];
  ContactNo?: Maybe<Scalars['String']>;
  Latitude?: Maybe<Scalars['String']>;
  Longitude?: Maybe<Scalars['String']>;
  MainLocation: Scalars['Boolean'];
};

export type DeliveryLocationInput = {
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Address?: Maybe<Scalars['JSON']>;
  ContactNo?: Maybe<Scalars['String']>;
  Latitude?: Maybe<Scalars['String']>;
  Longitude?: Maybe<Scalars['String']>;
  MainLocation?: Maybe<Scalars['Boolean']>;
};

export type DirectPoInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  POType: PoType;
  Instruction?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['JSON']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  File?: Maybe<Array<Scalars['Upload']>>;
  POItems: Array<PoItemInput>;
};

export type DocumentDateValidationDeleteInput = {
  DocumentDateValidationID: Scalars['String'];
};

export type DocumentDateValidationEntity = AuditEntity & {
  __typename?: 'DocumentDateValidationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentDateValidationID: Scalars['String'];
  MonthsBefore: Scalars['Float'];
  MonthsAfter: Scalars['Float'];
};

export type DocumentDateValidationInput = {
  DocumentDateValidationID?: Maybe<Scalars['String']>;
  MonthsBefore?: Maybe<Scalars['Float']>;
  MonthsAfter?: Maybe<Scalars['Float']>;
};

export type DocumentEntity = AuditEntity & {
  __typename?: 'DocumentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: RefTable;
  DocumentFolderID?: Maybe<Scalars['String']>;
  DocumentFolder?: Maybe<DocumentFolderEntity>;
  associateID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
};

export type DocumentFolderEntity = AuditEntity & {
  __typename?: 'DocumentFolderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  DocumentFolderID: Scalars['String'];
  AccountID: Scalars['String'];
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Document?: Maybe<Array<DocumentEntity>>;
};

export type DocumentFolderInput = {
  DocumentFolderID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
};

export type DocumentNumberDetailDeleteInput = {
  DocumentNumberDetailID: Scalars['String'];
};

export type DocumentNumberDetailEntity = AuditEntity & {
  __typename?: 'DocumentNumberDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentNumberDetailID: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
  Sequence: Scalars['Float'];
  FormatType: Scalars['String'];
  FormatValue: Scalars['String'];
  NextNumber?: Maybe<Scalars['Float']>;
  StartFrom?: Maybe<Scalars['Float']>;
  ResetBy?: Maybe<Scalars['String']>;
  DocumentNumberHeader?: Maybe<DocumentNumberHeaderEntity>;
};

export type DocumentNumberDetailInput = {
  DocumentNumberDetailID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  Sequence: Scalars['Float'];
  FormatType: Scalars['String'];
  FormatValue: Scalars['String'];
  NextNumber?: Maybe<Scalars['Float']>;
  StartFrom?: Maybe<Scalars['Float']>;
  ResetBy?: Maybe<Scalars['String']>;
};

export type DocumentNumberHeaderDeleteInput = {
  DocumentNumberHeaderID: Scalars['String'];
};

export type DocumentNumberHeaderEntity = AuditEntity & {
  __typename?: 'DocumentNumberHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentNumberHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  Type: DocumentType;
  SampleOutput: Scalars['String'];
  RefTable: Scalars['String'];
  RefRecordID: Scalars['String'];
  IsAutoDocNo?: Maybe<Scalars['Boolean']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  DocumentNumberDetail?: Maybe<Array<DocumentNumberDetailEntity>>;
  DocumentNumberResetBy?: Maybe<Array<DocumentNumberResetByEntity>>;
};

export type DocumentNumberHeaderInput = {
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Type?: Maybe<DocumentType>;
  SampleOutput: Scalars['String'];
  RefTable: Scalars['String'];
  RefRecordID: Scalars['String'];
};

export type DocumentNumberResetByEntity = AuditEntity & {
  __typename?: 'DocumentNumberResetByEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DocumentNumberResetByID: Scalars['String'];
  DocumentNumberHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  Year?: Maybe<Scalars['Float']>;
  Month?: Maybe<Scalars['Float']>;
  NextNumber?: Maybe<Scalars['Float']>;
  DocumentNumberHeader?: Maybe<DocumentNumberHeaderEntity>;
};

export enum DocumentType {
  JournalProcessing = 'JOURNAL_PROCESSING',
  ExternalJournal = 'EXTERNAL_JOURNAL',
  RecurringJournal = 'RECURRING_JOURNAL',
  AuditAdjustment = 'AUDIT_ADJUSTMENT',
  Payment = 'PAYMENT',
  PaymentAp = 'PAYMENT_AP',
  PaymentCb = 'PAYMENT_CB',
  ReceiptCb = 'RECEIPT_CB',
  Receipt = 'RECEIPT',
  BankTransfer = 'BANK_TRANSFER',
  Advance = 'ADVANCE',
  Invoice = 'INVOICE',
  CreditNote = 'CREDIT_NOTE',
  DebitNote = 'DEBIT_NOTE',
  Refund = 'REFUND',
  OfficialReceipt = 'OFFICIAL_RECEIPT',
  Interest = 'INTEREST',
  StockReceipt = 'STOCK_RECEIPT',
  StockIssue = 'STOCK_ISSUE',
  StockTransfer = 'STOCK_TRANSFER',
  CycleCounting = 'CYCLE_COUNTING',
  StockAdjustment = 'STOCK_ADJUSTMENT',
  StockCostAdjustment = 'STOCK_COST_ADJUSTMENT',
  StockRequisition = 'STOCK_REQUISITION',
  PurchaseRequisition = 'PURCHASE_REQUISITION',
  RequestForQuotation = 'REQUEST_FOR_QUOTATION',
  PurchaseOrder = 'PURCHASE_ORDER',
  GoodReceiveNote = 'GOOD_RECEIVE_NOTE',
  GoodReturnNote = 'GOOD_RETURN_NOTE',
  Employee = 'EMPLOYEE'
}

export enum DurationType {
  Year = 'YEAR',
  Month = 'MONTH'
}

export type EntityCoaDeleteInput = {
  EntityCOAID: Scalars['String'];
};

export type EntityCoaEntity = AuditEntity & {
  __typename?: 'EntityCOAEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  EntityCOAID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
};

export type EntityCoaInput = {
  EntityCOAID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
};

export type EntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  userID: Scalars['String'];
  roleID: Scalars['String'];
  companyID: Array<Scalars['String']>;
};

export type ExchangeLog = {
  __typename?: 'ExchangeLog';
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ExchangeLogInput = {
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ExpenseClassDeleteInput = {
  ExpenseClassID: Scalars['String'];
};

export type ExpenseClassEntity = AuditEntity & {
  __typename?: 'ExpenseClassEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ExpenseClassID: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  Name: Scalars['String'];
  ParentExpenseClassID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  ExpenseItem?: Maybe<Array<ExpenseItemEntity>>;
  ItemClassCompanyAssignment?: Maybe<Array<ItemClassCompanyAssignmentEntity>>;
};

export type ExpenseClassInput = {
  ExpenseClassID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  ParentExpenseClassID?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  MasterCOAID?: Maybe<Scalars['String']>;
};

export type ExpenseItemDeleteInput = {
  ExpenseItemID: Scalars['String'];
};

export type ExpenseItemEntity = AuditEntity & {
  __typename?: 'ExpenseItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ExpenseItemID: Scalars['String'];
  ItemName: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  TaxRate?: Maybe<Scalars['Float']>;
  ExpenseClassID: Scalars['String'];
  ExpenseClass?: Maybe<ExpenseClassEntity>;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  APFavoriteInvoiceItem?: Maybe<Array<AccApFavoriteInvoiceItemEntity>>;
  APDebitNote?: Maybe<Array<AccApDebitNoteEntity>>;
  APCreditNote?: Maybe<Array<AccApCreditNoteEntity>>;
};

export type ExpenseItemInput = {
  ExpenseItemID?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
  ItemName: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxRate?: Maybe<Scalars['Float']>;
  RecordStatus?: Maybe<RecordStatus>;
};

export type ExternalJournalEntity = AuditEntity & {
  __typename?: 'ExternalJournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  ExternalJournalID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  JournalDate: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  JournalTypeID: Scalars['String'];
  JournalType?: Maybe<JournalTypeEntity>;
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  IsManual: Scalars['Boolean'];
  RefNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  DocRefName: Scalars['String'];
  ExternalJournalItem?: Maybe<Array<ExternalJournalItemEntity>>;
};

export type ExternalJournalItemEntity = AuditEntity & {
  __typename?: 'ExternalJournalItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ExternalJournalItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  ExternalJournalID: Scalars['String'];
  ExternalJournal?: Maybe<ExternalJournalEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  Remark?: Maybe<Scalars['String']>;
};

export type ExternalLedgerEntity = AuditEntity & {
  __typename?: 'ExternalLedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
  ExternalLedgerID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  Currency?: Maybe<CurrencyEntity>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  TrxType?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  RefTable: Scalars['String'];
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  ReceivedFrom?: Maybe<Scalars['String']>;
  IsManual: Scalars['Boolean'];
  IsMatch: Scalars['Boolean'];
};

export type FavoriteJournalProcessingEntity = AuditEntity & {
  __typename?: 'FavoriteJournalProcessingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteJournalProcessingID: Scalars['String'];
  UserID: Scalars['String'];
  JournalTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FavoriteJournalProcessingItem?: Maybe<Array<FavoriteJournalProcessingItemEntity>>;
  JournalType?: Maybe<JournalTypeEntity>;
};

export type FavoriteJournalProcessingItemEntity = AuditEntity & {
  __typename?: 'FavoriteJournalProcessingItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteJournalProcessingItemID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  FavoriteJournalProcessingID: Scalars['String'];
  DocAmt?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  FavoriteJournalProcessing?: Maybe<FavoriteJournalProcessingEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
};

export type FavoritePaymentEntity = AuditEntity & {
  __typename?: 'FavoritePaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoritePaymentID: Scalars['String'];
  UserID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  FavoritePaymentItem?: Maybe<Array<FavoritePaymentItemEntity>>;
};

export type FavoritePaymentItemEntity = AuditEntity & {
  __typename?: 'FavoritePaymentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  FavoritePaymentItemID: Scalars['String'];
  FavoritePaymentID: Scalars['String'];
  FavoritePayment?: Maybe<FavoritePaymentEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
};

export type FavoriteReceiptEntity = AuditEntity & {
  __typename?: 'FavoriteReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  FavoriteReceiptID: Scalars['String'];
  UserID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ReceivedFrom?: Maybe<Scalars['String']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  FavoriteReceiptItem?: Maybe<Array<FavoriteReceiptItemEntity>>;
};

export type FavoriteReceiptItemEntity = AuditEntity & {
  __typename?: 'FavoriteReceiptItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark: Scalars['String'];
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  FavoriteReceiptItemID: Scalars['String'];
  FavoriteReceiptID: Scalars['String'];
  FavoriteReceipt?: Maybe<FavoriteReceiptEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export enum FileType {
  Image = 'IMAGE',
  Logo = 'LOGO',
  Pdf = 'PDF'
}

export type GdoActionInput = {
  DOHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  ApprovalStatus: ApprovalStatus;
};

export enum GpApprovalStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Completed = 'COMPLETED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION',
  PendingClose = 'PENDING_CLOSE',
  PendingCancel = 'PENDING_CANCEL',
  SubmitCancel = 'SUBMIT_CANCEL',
  ApprovedCancel = 'APPROVED_CANCEL',
  RejectedCancel = 'REJECTED_CANCEL',
  CompletedCancel = 'COMPLETED_CANCEL',
  Closed = 'CLOSED',
  New = 'NEW',
  Packed = 'PACKED'
}

export type GprActionInput = {
  PRHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type GrtnDeleteInput = {
  ID: Scalars['String'];
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type GrtnHeaderEntity = AuditEntity & {
  __typename?: 'GRTNHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  GRTNHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  SupplierID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  GRTNHeaderItem?: Maybe<Array<GrtnItemEntity>>;
  /** CustomFieldResolver */
  Company: CompanyEntity;
  Supplier?: Maybe<CreditorAccountEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  Warehouse?: Maybe<WarehouseEntity>;
};

export type GrtnItemEntity = AuditEntity & {
  __typename?: 'GRTNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  GRTNItemID: Scalars['String'];
  GRTNHeaderID: Scalars['String'];
  GRTNHeader?: Maybe<GrtnHeaderEntity>;
  DOItemID: Scalars['String'];
  DOItem?: Maybe<DoItemEntity>;
  OutstandingQty?: Maybe<Scalars['Float']>;
  ReturnedQty?: Maybe<Scalars['Float']>;
  ReturnedAmt?: Maybe<Scalars['Float']>;
  Replacement: Scalars['Boolean'];
  ReturnedDoItem?: Maybe<Array<DoItemEntity>>;
};

export type GeneralGrtnInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  SupplierID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  WarehouseID?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  TransactionDate?: Maybe<Scalars['String']>;
  RefNo: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type GeneralGrtnItemDeleteInput = {
  ID: Scalars['String'];
};

export type GeneralGrtnItemInput = {
  GRTNHeaderID?: Maybe<Scalars['String']>;
  DoItemID: Scalars['String'];
  ReturnedQty: Scalars['Float'];
  ReturnedAmt: Scalars['Float'];
  Replacement: Scalars['Boolean'];
};

export type GeneralGrtnItemInputGrn = {
  DOItemID: Scalars['String'];
  DOItemInput: Array<DoItemInput>;
};

export type GeneralGrtnRejectInput = {
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type GeneralScheduleItemInput = {
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TotalAmt?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
};

export type HomeCalendarOutput = {
  __typename?: 'HomeCalendarOutput';
  description?: Maybe<Scalars['String']>;
  voEndDate?: Maybe<Scalars['DateTime']>;
  publicHolidayOffDayDate?: Maybe<Scalars['DateTime']>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export enum IdentityType {
  IdentityCard = 'IDENTITY_CARD',
  MilitaryCard = 'MILITARY_CARD',
  Passport = 'PASSPORT',
  BusinessLicense = 'BUSINESS_LICENSE'
}

export type IndustryEntity = {
  __typename?: 'IndustryEntity';
  industry_id: Scalars['String'];
  name: Scalars['String'];
  account_id: Scalars['String'];
  record_status: RecordStatus;
};

export type InventoryPolicyEntity = AuditEntity & {
  __typename?: 'InventoryPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  InventoryPolicyID: Scalars['String'];
  PaymentReminder1: Scalars['Float'];
  PaymentReminder2: Scalars['Float'];
  PaymentReminder3: Scalars['Float'];
  PaymentReminder4: Scalars['Float'];
  PaymentReminder5: Scalars['Float'];
  PaymentReminder6: Scalars['Float'];
  StockCostingType: StockCostingType;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
};

export type ItemCategoryCompanyAssignmentDeleteInput = {
  ItemCategoryCompanyAssignmentID: Scalars['String'];
};

export type ItemCategoryCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'ItemCategoryCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ItemCategoryCompanyAssignmentID: Scalars['String'];
  ItemCategoryID: Scalars['String'];
  CompanyID: Scalars['String'];
  ItemCategory?: Maybe<ItemCategoryEntity>;
};

export type ItemCategoryCompanyAssignmentInput = {
  ItemCategoryCompanyAssignmentID?: Maybe<Scalars['String']>;
  ItemCategoryID: Scalars['String'];
  companyID: Array<Scalars['String']>;
};

export type ItemCategoryDeleteInput = {
  ID: Scalars['String'];
};

export type ItemCategoryEntity = AuditEntity & {
  __typename?: 'ItemCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ItemCategoryID: Scalars['String'];
  Name: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  ParentItemCategoryID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  Item?: Maybe<Array<ItemEntity>>;
  ItemCategoryCompanyAssignment?: Maybe<Array<ItemCategoryCompanyAssignmentEntity>>;
  MasterCOA?: Maybe<MasterCoaEntity>;
};

export type ItemCategoryInput = {
  ItemCategoryID?: Maybe<Scalars['String']>;
  ParentItemCategoryID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  MasterCOAID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
};

export type ItemClassCompanyAssignmentDeleteInput = {
  ItemClassCompanyAssignmentID: Scalars['String'];
};

export type ItemClassCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'ItemClassCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ItemClassCompanyAssignmentID: Scalars['String'];
  ExpenseClassID: Scalars['String'];
  CompanyID: Scalars['String'];
  ExpenseClass?: Maybe<ExpenseClassEntity>;
};

export type ItemClassCompanyAssignmentInput = {
  ItemClassCompanyAssignmentID?: Maybe<Scalars['String']>;
  ExpenseClassID: Scalars['String'];
  companyID: Array<Scalars['String']>;
};

export type ItemEntity = AuditEntity & {
  __typename?: 'ItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ItemID: Scalars['String'];
  ItemCategoryID: Scalars['String'];
  ItemCategory?: Maybe<ItemCategoryEntity>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  DefaultSupplierID?: Maybe<Scalars['String']>;
  MarketPrice: Scalars['Float'];
  IsCentralised: Scalars['Boolean'];
  IsInventory: Scalars['Boolean'];
  VariancePerc?: Maybe<Scalars['Float']>;
  BulkPurchasePriceItem?: Maybe<Array<BulkPurchasePriceItemEntity>>;
  PRItem?: Maybe<Array<PrItemEntity>>;
  POItem?: Maybe<Array<PoItemEntity>>;
  ScheduledPurchasesItem?: Maybe<Array<ScheduledPurchasesItemEntity>>;
  ParentItem: Array<Scalars['JSON']>;
  UOM?: Maybe<AccUomEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  DefaultSupplier?: Maybe<CreditorAccountEntity>;
  /** CustomFieldResolver */
  LatestPurchase?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  ExpenseItemDetail?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  IsUse?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  UsedInIC?: Maybe<Scalars['Boolean']>;
};

export type ItemExcelInput = {
  ID?: Maybe<Scalars['String']>;
  ParentName?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  UOM?: Maybe<Scalars['String']>;
  Tax?: Maybe<Scalars['String']>;
  RowNo?: Maybe<Scalars['Float']>;
};

export type ItemInput = {
  ItemID?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  DefaultSupplierID?: Maybe<Scalars['String']>;
  IsCentralised?: Maybe<Scalars['Boolean']>;
  IsInventory?: Maybe<Scalars['Boolean']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  ParentName?: Maybe<Scalars['String']>;
  VariancePerc?: Maybe<Scalars['Float']>;
  RecordStatus?: Maybe<RecordStatus>;
};

export type ItemObject = {
  __typename?: 'ItemObject';
  ItemID: Scalars['String'];
  ParentItemID?: Maybe<Scalars['String']>;
};


export type JournalEntity = AuditEntity & {
  __typename?: 'JournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  JournalID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  JournalDate?: Maybe<Scalars['String']>;
  JournalTypeID: Scalars['String'];
  JournalType?: Maybe<JournalTypeEntity>;
  JournalNo?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  IsManual: Scalars['Boolean'];
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  RefRecordID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  JournalItem?: Maybe<Array<JournalItemEntity>>;
};

export type JournalItemEntity = AuditEntity & {
  __typename?: 'JournalItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  JournalItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  Remark?: Maybe<Scalars['String']>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  Company?: Maybe<CompanyEntity>;
  Journal?: Maybe<JournalEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
};

export type JournalProcessingEntity = AuditEntity & {
  __typename?: 'JournalProcessingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  JournalProcessingID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalTypeID: Scalars['String'];
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  JournalID?: Maybe<Scalars['String']>;
  JournalProcessingItem?: Maybe<Array<JournalProcessingItemEntity>>;
  JournalType?: Maybe<JournalTypeEntity>;
  Company?: Maybe<CompanyEntity>;
};

export type JournalProcessingItemEntity = AuditEntity & {
  __typename?: 'JournalProcessingItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  JournalProcessingItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalProcessingID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  CostCentreID: Scalars['String'];
  DocAmt: Scalars['Float'];
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
  Company?: Maybe<CompanyEntity>;
  JournalProcessing?: Maybe<JournalProcessingEntity>;
};

export type JournalTypeDeleteInput = {
  JournalTypeID: Scalars['String'];
};

export type JournalTypeEntity = AuditEntity & {
  __typename?: 'JournalTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  JournalTypeID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  JournalType: Scalars['String'];
  Description: Scalars['String'];
  IsSystem: Scalars['Boolean'];
  Journal?: Maybe<Array<JournalEntity>>;
  RoutineJournal?: Maybe<Array<RoutineJournalEntity>>;
  RecurringJournal?: Maybe<Array<RecurringJournalEntity>>;
  RecurringJournalGeneration?: Maybe<Array<RecurringJournalGenerationEntity>>;
  FavoriteJournalProcessing?: Maybe<Array<FavoriteJournalProcessingEntity>>;
  JournalProcessing?: Maybe<Array<JournalProcessingEntity>>;
  ExternalExternalJournal?: Maybe<Array<ExternalJournalEntity>>;
};

export type JournalTypeInput = {
  JournalTypeID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  JournalType: Scalars['String'];
  Description: Scalars['String'];
  IsSystem?: Maybe<Scalars['Boolean']>;
};

export type LedgerEntity = AuditEntity & {
  __typename?: 'LedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
  LedgerID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  Currency?: Maybe<CurrencyEntity>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  BankReconciliationMatchID?: Maybe<Scalars['String']>;
  BankReconciliationMatch?: Maybe<BankReconciliationMatchEntity>;
  TrxType?: Maybe<Scalars['String']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  RefTable: Scalars['String'];
  RefRecordID: Scalars['String'];
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  ReceivedFrom?: Maybe<Scalars['String']>;
  IsManual: Scalars['Boolean'];
  IsMatch: Scalars['Boolean'];
  LedgerItem?: Maybe<Array<LedgerItemEntity>>;
};

export type LedgerItemEntity = AuditEntity & {
  __typename?: 'LedgerItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  LedgerItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  LedgerID: Scalars['String'];
  Ledger?: Maybe<LedgerEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
};

export type ListEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  companyID: Scalars['String'];
  roleUserIDs: Array<AccRoleUserInput>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export type MainTradeEntity = {
  __typename?: 'MainTradeEntity';
  main_trade_id: Scalars['String'];
  name: Scalars['String'];
  account_id: Scalars['String'];
  record_status: RecordStatus;
};

export type MasterCoaDeleteInput = {
  MasterCOAID: Scalars['String'];
};

export type MasterCoaEntity = AuditEntity & {
  __typename?: 'MasterCOAEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  MasterCOAID: Scalars['String'];
  ParentMasterCOAID?: Maybe<Scalars['String']>;
  Code: Scalars['String'];
  Name: Scalars['String'];
  Level: Scalars['Float'];
  AccountType: AccountType;
  IsControl: Scalars['Boolean'];
  IsLastNode: Scalars['Boolean'];
  BudgetItem?: Maybe<Array<BudgetItemEntity>>;
  JournalItem?: Maybe<Array<JournalItemEntity>>;
  LedgerItem?: Maybe<Array<LedgerItemEntity>>;
  ExternalJournalItem?: Maybe<Array<ExternalJournalItemEntity>>;
  CreditorType?: Maybe<Array<CreditorTypeEntity>>;
  AccrualCreditorType?: Maybe<Array<CreditorTypeEntity>>;
  TaxScheme?: Maybe<Array<TaxSchemeEntity>>;
  BankAccount?: Maybe<Array<AccBankAccountEntity>>;
  JournalProcessingItem?: Maybe<Array<JournalProcessingItemEntity>>;
  FavoriteJournalProcessingItem?: Maybe<Array<FavoriteJournalProcessingItemEntity>>;
  RoutineJournalItem?: Maybe<Array<RoutineJournalItemEntity>>;
  RecurringJournalItem?: Maybe<Array<RecurringJournalItemEntity>>;
  RecurringJournalGenerationItem?: Maybe<Array<RecurringJournalGenerationItemEntity>>;
  EntityCOA?: Maybe<Array<EntityCoaEntity>>;
  RetainEarningAccountDefID?: Maybe<Array<RetainEarningAccountDefEntity>>;
  AuditAdjustmentItem?: Maybe<Array<AuditAdjustmentItemEntity>>;
  PaymentItem?: Maybe<Array<PaymentItemEntity>>;
  ReceiptItem?: Maybe<Array<ReceiptItemEntity>>;
  FavoritePaymentItem?: Maybe<Array<FavoritePaymentItemEntity>>;
  FavoriteReceiptItem?: Maybe<Array<FavoriteReceiptItemEntity>>;
  FavoriteInvoiceItem?: Maybe<Array<AccArFavoriteInvoiceItemEntity>>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  DebtorType?: Maybe<Array<DebtorTypeEntity>>;
  ExpenseClass?: Maybe<Array<ExpenseClassEntity>>;
  ExpenseItem?: Maybe<Array<ExpenseItemEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
  SCStockAccount?: Maybe<Array<StockCategoryEntity>>;
  BillItem?: Maybe<Array<BillItemEntity>>;
  TransactionType?: Maybe<Array<TransactionTypeEntity>>;
  CostCentreCOA?: Maybe<Array<CostCentreCoaEntity>>;
};

export type MasterCoaInput = {
  MasterCOAID?: Maybe<Scalars['String']>;
  ParentMasterCOAID?: Maybe<Scalars['String']>;
  ParentCode?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  IsControl?: Maybe<Scalars['Boolean']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  AccountType: AccountType;
  RowNo?: Maybe<Scalars['Float']>;
};

export enum ModuleType {
  AllModule = 'ALL_MODULE',
  Gl = 'GL',
  Cb = 'CB',
  Ar = 'AR',
  Ap = 'AP',
  Ic = 'IC',
  Gp = 'GP'
}

export type MonthPeriod = {
  __typename?: 'MonthPeriod';
  month?: Maybe<Scalars['DateTime']>;
  outstandingAmt?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  createDocumentFolder: Scalars['Boolean'];
  updateDocumentFolder: Scalars['Boolean'];
  deleteDocumentFolder: Scalars['Boolean'];
  accountSubscriptionInfo: Scalars['Boolean'];
  createUser: UserEntity;
  createUserEmail: UserEntity;
  resendInvitation: UserEntity;
  updateUser: UserEntity;
  updateUserWithPassword: UserEntity;
  createPassword: Scalars['Boolean'];
  createPasswordTest: Scalars['Boolean'];
  activateUser: Scalars['Boolean'];
  unblockUser: Scalars['Boolean'];
  changePasswordSU: Scalars['Boolean'];
  changePassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  uploadProfilePic: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  createProcurementApprovalPolicy: ProcurementApprovalPolicyEntity;
  updateProcurementApprovalPolicy: ProcurementApprovalPolicyEntity;
  cancelProcurementApprovalPolicy: Scalars['Boolean'];
  createProcurementApprovalPolicyAssignment: ProcurementApprovalPolicyAssignmentEntity;
  updateProcurementApprovalPolicyAssignment: ProcurementApprovalPolicyAssignmentEntity;
  cancelProcurementApprovalPolicyAssignment: Scalars['Boolean'];
  deleteProcurementApprovalPolicy: Scalars['Boolean'];
  createBulkPurchasePrice: BulkPurchasePriceHeaderEntity;
  updateBulkPurchasePrice: Scalars['JSON'];
  deleteBulkPurchasePrice: Scalars['Boolean'];
  submitGeneralDO: Scalars['Boolean'];
  deleteGeneralDO: Scalars['Boolean'];
  rejectGeneralDO: Scalars['Boolean'];
  actionOnGDOStatus: Scalars['Boolean'];
  createUpdateGRTN: Scalars['Boolean'];
  CreateGRTN: Scalars['Boolean'];
  UpdateGRTN: Scalars['Boolean'];
  deleteGRTN: Scalars['Boolean'];
  actionOnGRTNStatus: Scalars['Boolean'];
  createGeneralPO: Scalars['Boolean'];
  updateGeneralPO: Scalars['Boolean'];
  createDirectPO: Scalars['JSON'];
  updateDirectPO: Scalars['Boolean'];
  deletePO: Scalars['Boolean'];
  actionOnGPOStatus: Scalars['Boolean'];
  cancelClosePO: Scalars['Boolean'];
  createGeneralPR: PrHeaderEntity;
  updateGeneralPR: Scalars['JSON'];
  actionOnGPRStatus: Scalars['Boolean'];
  deleteGeneralPR: Scalars['Boolean'];
  cancelGeneralPR: Scalars['Boolean'];
  cancelClosePR: Scalars['Boolean'];
  patchPRItemOutstandingQtyData: Scalars['Boolean'];
  createGeneralRFQ: Scalars['Boolean'];
  updateGeneralRFQ: Scalars['Boolean'];
  deleteGeneralRFQ: Scalars['Boolean'];
  submitEvaluationSelection: Scalars['Boolean'];
  actionOnRFQStatus: Scalars['Boolean'];
  inviteSupplier: Scalars['Boolean'];
  uninviteSupplier: Scalars['Boolean'];
  submitGeneralRFQQuote: Scalars['Boolean'];
  deleteRFQSupplierSubmission: Scalars['Boolean'];
  createScheduledPurchases: Scalars['JSON'];
  updateScheduledPurchases: Scalars['JSON'];
  deleteScheduledPurchases: Scalars['Boolean'];
  deleteScheduledPurchasesItem: Scalars['Boolean'];
  createPreparePO: Scalars['JSON'];
  createItem: ItemEntity;
  updateItem: Scalars['Boolean'];
  cancelItem: Scalars['Boolean'];
  deleteItem: Scalars['Boolean'];
  createBatchItem: Scalars['Boolean'];
  createItemCategory: ItemCategoryEntity;
  updateItemCategory: Scalars['Boolean'];
  deleteItemCategory: Scalars['Boolean'];
  AssignItemCategoryCompany: Scalars['Boolean'];
  UnassignItemCategoryCompany: Scalars['Boolean'];
  createPurchaseItemVariancePolicy: Scalars['Boolean'];
  updatePurchaseItemVariancePolicy: Scalars['Boolean'];
  procurementLogin: LoginResponse;
  DuplicateRolePermission: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  AssignEntityRoleUser: Scalars['Boolean'];
  UnassignEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  resetRolePermission: Scalars['Boolean'];
  deleteAccountPeriod: Scalars['Boolean'];
  cancelAccountPeriod: Scalars['Boolean'];
  createAccountPeriod: Scalars['Boolean'];
  updateAccountPeriod: Scalars['Boolean'];
  closeAccountPeriod: Scalars['Boolean'];
  updateARAging: ArAgingEntity;
  deleteARAging: Scalars['Boolean'];
  cancelARAging: Scalars['Boolean'];
  updateAPAging: ApAgingEntity;
  deleteAPAging: Scalars['Boolean'];
  cancelAPAging: Scalars['Boolean'];
  createARAging: Scalars['Boolean'];
  createAPAging: Scalars['Boolean'];
  cancelApprovalPolicy: Scalars['Boolean'];
  deleteApprovalPolicyAssignment: Scalars['Boolean'];
  cancelApprovalPolicyAssignment: Scalars['Boolean'];
  createApprovalPolicy: AccApprovalPolicyEntity;
  updateApprovalPolicy: AccApprovalPolicyEntity;
  deleteApprovalPolicy: Scalars['Boolean'];
  createApprovalPolicyAssignment: AccApprovalPolicyAssignmentEntity;
  updateApprovalPolicyAssignment: AccApprovalPolicyAssignmentEntity;
  createBankAccount: AccBankAccountEntity;
  updateBankAccount: AccBankAccountEntity;
  deleteBankAccount: Scalars['Boolean'];
  cancelBankAccount: Scalars['Boolean'];
  createBankProfile: BankProfileEntity;
  updateBankProfile: BankProfileEntity;
  deleteBankProfile: Scalars['Boolean'];
  cancelBankProfile: Scalars['Boolean'];
  createBillItem: BillItemEntity;
  updateBillItem: BillItemEntity;
  deleteBillItem: Scalars['Boolean'];
  cancelBillItem: Scalars['Boolean'];
  updateCOAStructure: CoaStructureEntity;
  deleteCOAStructure: Scalars['Boolean'];
  cancelCOAStructure: Scalars['Boolean'];
  createCOAStructure: Scalars['Boolean'];
  deleteCompany: Scalars['Boolean'];
  cancelCompany: Scalars['Boolean'];
  createCompany: CompanyEntity;
  updateCompany: Scalars['Boolean'];
  deleteCompanyProfile: Scalars['Boolean'];
  updateContact: AccContactEntity;
  updateCostCentre: CostCentreEntity;
  cancelCostCentre: Scalars['Boolean'];
  createCostCentreDefinitionAsgmt: Scalars['Boolean'];
  deleteCostCentre: Scalars['Boolean'];
  createCostCentre: Scalars['Boolean'];
  setDefaultCostCentre: Scalars['Boolean'];
  createCostCentreCOA: Scalars['Boolean'];
  deleteCostcentreCOA: Scalars['Boolean'];
  assignCostCentreCOA: Scalars['Boolean'];
  unassignCostCentreCOA: Scalars['Boolean'];
  updateCostCentreDefinitionHeader: CostCentreDefinitionHeaderEntity;
  cancelCostCentreDefinitionHeader: Scalars['Boolean'];
  updateCostCentreDefinitionDetail: CostCentreDefinitionDetailEntity;
  deleteCostCentreDefinitionDetail: Scalars['Boolean'];
  cancelCostCentreDefinitionDetail: Scalars['Boolean'];
  createCostCentreDefinition: Scalars['Boolean'];
  createUpdateCostCentreDefinition: Scalars['Boolean'];
  deleteCostCentreDefinition: Scalars['Boolean'];
  createCostCentreType: CostCentreTypeEntity;
  updateCostCentreType: CostCentreTypeEntity;
  deleteCostCentreType: Scalars['Boolean'];
  cancelCostCentreType: Scalars['Boolean'];
  createUpdateCostCentreType: Scalars['Boolean'];
  updateCreditCardType: CreditCardTypeEntity;
  deleteCreditCardType: Scalars['Boolean'];
  cancelCreditCardType: Scalars['Boolean'];
  createCreditCardType: Scalars['Boolean'];
  updateCreditorAccount: CreditorAccountEntity;
  deleteCreditorAccount: Scalars['Boolean'];
  cancelCreditorAccount: Scalars['Boolean'];
  CreateCreditorAccount: Scalars['Boolean'];
  UpdateCreditorAccountWithItem: Scalars['Boolean'];
  CreateCreditorAccountWithAccount: Scalars['Boolean'];
  updateCreditorAccountCompanyAssignment: CreditorAccountCompanyAssignmentEntity;
  deleteCreditorAccountCompanyAssignment: Scalars['Boolean'];
  cancelCreditorAccountCompanyAssignment: Scalars['Boolean'];
  AssignCreditorAccountComapany: Scalars['Boolean'];
  UnassignCreditorAccountComapany: Scalars['Boolean'];
  createCreditorContact: CreditorContactEntity;
  updateCreditorContact: CreditorContactEntity;
  deleteCreditorContact: Scalars['Boolean'];
  cancelCreditorContact: Scalars['Boolean'];
  createCreditorDirector: CreditorDirectorEntity;
  updateCreditorDirector: CreditorDirectorEntity;
  deleteCreditorDirector: Scalars['Boolean'];
  cancelCreditorDirector: Scalars['Boolean'];
  createCreditorType: CreditorTypeEntity;
  updateCreditorType: CreditorTypeEntity;
  deleteCreditorType: Scalars['Boolean'];
  cancelCreditorType: Scalars['Boolean'];
  CreateCreditorTypeWithAccount: Scalars['Boolean'];
  createCurrency: CurrencyEntity;
  updateCurrency: CurrencyEntity;
  deleteCurrency: Scalars['Boolean'];
  cancelCurrency: Scalars['Boolean'];
  cancelCurrencyExchange: Scalars['Boolean'];
  createCurrencyExchange: Scalars['Boolean'];
  updateCurrencyExchange: Scalars['Boolean'];
  deleteCurrencyExchange: Scalars['Boolean'];
  updateDebtorAccount: DebtorAccountEntity;
  deleteDebtorAccount: Scalars['Boolean'];
  cancelDebtorAccount: Scalars['Boolean'];
  CreateDebtorAccount: Scalars['Boolean'];
  UpdateDebtorAccountWithItem: Scalars['Boolean'];
  createDebtorContact: DebtorContactEntity;
  updateDebtorContact: DebtorContactEntity;
  deleteDebtorContact: Scalars['Boolean'];
  cancelDebtorContact: Scalars['Boolean'];
  createDebtorType: DebtorTypeEntity;
  updateDebtorType: DebtorTypeEntity;
  deleteDebtorType: Scalars['Boolean'];
  cancelDebtorType: Scalars['Boolean'];
  deleteDeliveryLocation: Scalars['Boolean'];
  cancelDeliveryLocation: Scalars['Boolean'];
  createDeliveryLoc: Scalars['Boolean'];
  updateDeliveryLoc: Scalars['Boolean'];
  updateDocumentDateValidation: DocumentDateValidationEntity;
  deleteDocumentDateValidation: Scalars['Boolean'];
  cancelDocumentDateValidation: Scalars['Boolean'];
  createDocumentDateValidation: Scalars['Boolean'];
  createDocumentNumberDetail: DocumentNumberDetailEntity;
  updateDocumentNumberDetail: DocumentNumberDetailEntity;
  deleteDocumentNumberDetail: Scalars['Boolean'];
  cancelDocumentNumberDetail: Scalars['Boolean'];
  deleteDocumentNumberHeader: Scalars['Boolean'];
  cancelDocumentNumberHeader: Scalars['Boolean'];
  createDocumentNumberHeader: Scalars['Boolean'];
  updateDocumentNumberHeader: Scalars['Boolean'];
  updateIsAutoDocNo: Scalars['Boolean'];
  createEntityCOA: EntityCoaEntity;
  updateEntityCOA: EntityCoaEntity;
  deleteEntityCOA: Scalars['Boolean'];
  cancelEntityCOA: Scalars['Boolean'];
  assignEntityCOA: Scalars['Boolean'];
  deleteAssignedEntityCOA: Scalars['Boolean'];
  cancelExpenseClass: Scalars['Boolean'];
  deleteExpenseClass: Scalars['Boolean'];
  createExpenseClass: Scalars['Boolean'];
  updateExpenseClass: Scalars['Boolean'];
  cancelExpenseItem: Scalars['Boolean'];
  createExpenseItem: ExpenseItemEntity;
  updateExpenseItem: Scalars['Boolean'];
  deleteExpenseItem: Scalars['Boolean'];
  testDeleteExpenseItem: Scalars['Boolean'];
  initialDatabaseSetup: Scalars['Boolean'];
  initialMasterFileSetup: Scalars['Boolean'];
  initialTaxSetup: Scalars['Boolean'];
  extraDBSetup: Scalars['Boolean'];
  extraSystemAdminSetup: Scalars['Boolean'];
  insertAdjustmentReason: Scalars['Boolean'];
  createSystemJournalType: Scalars['Boolean'];
  createSuperUser: UserEntity;
  updateItemClassCompanyAssignment: ItemClassCompanyAssignmentEntity;
  deleteItemClassCompanyAssignment: Scalars['Boolean'];
  cancelItemClassCompanyAssignment: Scalars['Boolean'];
  AssignItemClassCompany: Scalars['Boolean'];
  UnassignItemClassCompany: Scalars['Boolean'];
  createJournalType: JournalTypeEntity;
  updateJournalType: JournalTypeEntity;
  deleteJournalType: Scalars['Boolean'];
  cancelJournalType: Scalars['Boolean'];
  updateMasterCOA: MasterCoaEntity;
  cancelMasterCOA: Scalars['Boolean'];
  createMasterCOA: Scalars['Boolean'];
  deleteMasterCOA: Scalars['Boolean'];
  createExcelMasterCOA: Scalars['Boolean'];
  createNumberFormat: NumberFormatEntity;
  updateNumberFormat: NumberFormatEntity;
  deleteNumberFormat: Scalars['Boolean'];
  cancelNumberFormat: Scalars['Boolean'];
  updatePaymentMethod: PaymentMethodEntity;
  deletePaymentMethod: Scalars['Boolean'];
  cancelPaymentMethod: Scalars['Boolean'];
  createPaymentMethod: Scalars['Boolean'];
  updateRetainEarningAccountDef: RetainEarningAccountDefEntity;
  deleteRetainEarningAccountDef: Scalars['Boolean'];
  cancelRetainEarningAccountDef: Scalars['Boolean'];
  createRetainEarningAccountDef: Scalars['Boolean'];
  cancelStockItem: Scalars['Boolean'];
  createStockItem: Scalars['Boolean'];
  UpdateStockItemWithWarehouse: Scalars['Boolean'];
  updateStockItem: Scalars['Boolean'];
  deleteStockItem: Scalars['Boolean'];
  createStockItemWarehouse: StockItemWarehouseEntity;
  updateStockItemWarehouse: StockItemWarehouseEntity;
  deleteStockItemWarehouse: Scalars['Boolean'];
  cancelStockItemWarehouse: Scalars['Boolean'];
  createTaxType: TaxTypeEntity;
  updateTaxType: TaxTypeEntity;
  deleteTaxType: Scalars['Boolean'];
  createTaxScheme: TaxSchemeEntity;
  updateTaxScheme: TaxSchemeEntity;
  createTaxEffectiveDate: TaxEffectiveDateEntity;
  updateTaxEffectiveDate: TaxEffectiveDateEntity;
  deleteTaxEffectiveDate: Scalars['Boolean'];
  insertTax: TaxSchemeEntity;
  updateTax: Scalars['Boolean'];
  deleteTaxScheme: Scalars['Boolean'];
  createTransactionType: TransactionTypeEntity;
  updateTransactionType: TransactionTypeEntity;
  deleteTransactionType: Scalars['Boolean'];
  cancelTransactionType: Scalars['Boolean'];
  createUOM: AccUomEntity;
  updateUOM: AccUomEntity;
  deleteUOM: Scalars['Boolean'];
  createUOMWithAccount: Scalars['Boolean'];
  updateUOMWithAccount: Scalars['Boolean'];
  createUOMExchange: Scalars['Boolean'];
  updateUOMExchange: Scalars['Boolean'];
  deleteUOMExchange: Scalars['Boolean'];
  updateWarehouse: Scalars['Boolean'];
  deleteWarehouse: Scalars['Boolean'];
  cancelWarehouse: Scalars['Boolean'];
  createWarehouse: Scalars['Boolean'];
  approveRejectWorkDesk: Scalars['Boolean'];
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<AcctPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<AcctPermission>;
  input: RoleInput;
};


export type MutationCreateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationUpdateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationDeleteDocumentFolderArgs = {
  ID: Scalars['String'];
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreateUserEmailArgs = {
  input: UserInput;
};


export type MutationResendInvitationArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationUpdateUserWithPasswordArgs = {
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  input: PasswordInfo;
};


export type MutationCreatePasswordTestArgs = {
  input: PasswordInfo;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationUnblockUserArgs = {
  input: PasswordInfo;
};


export type MutationChangePasswordSuArgs = {
  input: PasswordInfo;
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationLogoutArgs = {
  entityID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationUploadProfilePicArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCreateProcurementApprovalPolicyArgs = {
  input: ProcurementApprovalPolicyInput;
};


export type MutationUpdateProcurementApprovalPolicyArgs = {
  input: ProcurementApprovalPolicyInput;
};


export type MutationCancelProcurementApprovalPolicyArgs = {
  input: ProcurementApprovalPolicyDeleteInput;
};


export type MutationCreateProcurementApprovalPolicyAssignmentArgs = {
  input: ProcurementApprovalPolicyAssignmentInput;
};


export type MutationUpdateProcurementApprovalPolicyAssignmentArgs = {
  input: ProcurementApprovalPolicyAssignmentInput;
};


export type MutationCancelProcurementApprovalPolicyAssignmentArgs = {
  input: ProcurementApprovalPolicyAssignmentDeleteInput;
};


export type MutationDeleteProcurementApprovalPolicyArgs = {
  ID: Scalars['String'];
};


export type MutationCreateBulkPurchasePriceArgs = {
  bulkPurchaseItemInput: Array<BulkPurchasePriceItemInput>;
  input: BulkPurchasePriceHeaderInput;
};


export type MutationUpdateBulkPurchasePriceArgs = {
  bulkPurchaseItemInput: Array<BulkPurchasePriceItemInput>;
  input: BulkPurchasePriceHeaderInput;
  BulkPurchasePriceHeaderID: Scalars['String'];
};


export type MutationDeleteBulkPurchasePriceArgs = {
  BulkPurchasePriceHeaderID: Scalars['String'];
};


export type MutationSubmitGeneralDoArgs = {
  doItemInput: Array<DoItemInput>;
  doInput: DoInput;
  DOHeaderID?: Maybe<Scalars['String']>;
};


export type MutationDeleteGeneralDoArgs = {
  DOHeaderID: Scalars['String'];
};


export type MutationRejectGeneralDoArgs = {
  rejectInput?: Maybe<DoRejectInput>;
  ID: Scalars['String'];
};


export type MutationActionOnGdoStatusArgs = {
  input: GdoActionInput;
  DOHeaderID: Array<Scalars['String']>;
};


export type MutationCreateUpdateGrtnArgs = {
  grtnItemInput: Array<GeneralGrtnItemInput>;
  grtnInput: GeneralGrtnInput;
  GRTNHeaderID?: Maybe<Scalars['String']>;
};


export type MutationCreateGrtnArgs = {
  grtnItemInput: Array<GeneralGrtnItemInput>;
  grtnInput: GeneralGrtnInput;
};


export type MutationUpdateGrtnArgs = {
  grtnItemInput: Array<GeneralGrtnItemInput>;
  grtnInput: GeneralGrtnInput;
  GRTNHeaderID?: Maybe<Scalars['String']>;
};


export type MutationDeleteGrtnArgs = {
  GRTNHeaderID: Scalars['String'];
};


export type MutationActionOnGrtnStatusArgs = {
  CompanyID: Scalars['String'];
  input: GeneralGrtnRejectInput;
  ApprovalStatus: ApprovalStatus;
  GRTNHeaderID: Array<Scalars['String']>;
};


export type MutationCreateGeneralPoArgs = {
  generalPOInput: Array<PoInput>;
};


export type MutationUpdateGeneralPoArgs = {
  generalPOInput: Array<PoInput>;
  POHeaderID: Scalars['String'];
};


export type MutationCreateDirectPoArgs = {
  input: DirectPoInput;
};


export type MutationUpdateDirectPoArgs = {
  input: DirectPoInput;
  POHeaderID?: Maybe<Scalars['String']>;
};


export type MutationDeletePoArgs = {
  POHeaderID: Scalars['String'];
};


export type MutationActionOnGpoStatusArgs = {
  CompanyID: Scalars['String'];
  input: PoRejectInput;
  ApprovalStatus: ApprovalStatus;
  POHeaderID: Array<Scalars['String']>;
};


export type MutationCancelClosePoArgs = {
  input: PurchaseActionInput;
  POHeaderID: Scalars['String'];
};


export type MutationCreateGeneralPrArgs = {
  generalPRItemInput?: Maybe<Array<PrItemInput>>;
  generalPRInput: PrInput;
};


export type MutationUpdateGeneralPrArgs = {
  generalPRItemInput: Array<PrItemInput>;
  generalPRInput: PrInput;
  PRHeaderID: Scalars['String'];
};


export type MutationActionOnGprStatusArgs = {
  input: GprActionInput;
  GeneralPRIDs: Array<Scalars['String']>;
};


export type MutationDeleteGeneralPrArgs = {
  PRHeaderID: Scalars['String'];
};


export type MutationCancelGeneralPrArgs = {
  PRHeaderID: Array<Scalars['String']>;
};


export type MutationCancelClosePrArgs = {
  input: GprActionInput;
  PRHeaderID: Scalars['String'];
};


export type MutationCreateGeneralRfqArgs = {
  RFQItemInput: Array<RfqItemInput>;
  RFQInput: RfqInput;
};


export type MutationUpdateGeneralRfqArgs = {
  generalRFQItemInput: Array<RfqItemInput>;
  generalRFQInput: RfqInput;
  RFQHeaderID: Scalars['String'];
};


export type MutationDeleteGeneralRfqArgs = {
  RFQHeaderID: Scalars['String'];
};


export type MutationSubmitEvaluationSelectionArgs = {
  RFQEvalSelectInput: Array<RfqEvalSelectInput>;
  RFQInput: RfqEvalInput;
};


export type MutationActionOnRfqStatusArgs = {
  CompanyID: Scalars['String'];
  input: RfqRejectInput;
  RFQHeaderID: Array<Scalars['String']>;
};


export type MutationInviteSupplierArgs = {
  SupplierIDs: Array<Scalars['String']>;
  RFQHeaderID: Scalars['String'];
};


export type MutationUninviteSupplierArgs = {
  SupplierID: Scalars['String'];
  RFQHeaderID: Scalars['String'];
};


export type MutationSubmitGeneralRfqQuoteArgs = {
  input: RfqSupplierSubmissionInput;
};


export type MutationDeleteRfqSupplierSubmissionArgs = {
  RFQSupplierSubmissionID: Scalars['String'];
};


export type MutationCreateScheduledPurchasesArgs = {
  input: ScheduledPurchasesInput;
};


export type MutationUpdateScheduledPurchasesArgs = {
  input: ScheduledPurchasesInput;
  ScheduledPurchasesID: Scalars['String'];
};


export type MutationDeleteScheduledPurchasesArgs = {
  ScheduledPurchasesID: Scalars['String'];
};


export type MutationDeleteScheduledPurchasesItemArgs = {
  ScheduledPurchasesID: Scalars['String'];
};


export type MutationCreatePreparePoArgs = {
  input: Array<PreparePoInput>;
};


export type MutationCreateItemArgs = {
  input: ItemInput;
};


export type MutationUpdateItemArgs = {
  input: ItemInput;
};


export type MutationCancelItemArgs = {
  ItemID: Scalars['String'];
};


export type MutationDeleteItemArgs = {
  ItemID: Scalars['String'];
};


export type MutationCreateBatchItemArgs = {
  input: Array<ItemExcelInput>;
};


export type MutationCreateItemCategoryArgs = {
  ItemCategoryInput: ItemCategoryInput;
};


export type MutationUpdateItemCategoryArgs = {
  ItemCategoryInput: ItemCategoryInput;
};


export type MutationDeleteItemCategoryArgs = {
  ItemCategoryID: Scalars['String'];
};


export type MutationAssignItemCategoryCompanyArgs = {
  input: ItemCategoryCompanyAssignmentInput;
};


export type MutationUnassignItemCategoryCompanyArgs = {
  input: ItemCategoryCompanyAssignmentInput;
};


export type MutationCreatePurchaseItemVariancePolicyArgs = {
  PurchaseItemVariancePolicyInput: PurchaseItemVariancePolicyInput;
};


export type MutationUpdatePurchaseItemVariancePolicyArgs = {
  PurchaseItemVariancePolicyID: Scalars['String'];
  PurchaseItemVariancePolicyInput: PurchaseItemVariancePolicyInput;
};


export type MutationProcurementLoginArgs = {
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationDuplicateRolePermissionArgs = {
  name: Scalars['String'];
  roleID: Scalars['String'];
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationAssignEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationUnassignEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<AccRoleUserInput>;
  companyID: Scalars['String'];
};


export type MutationResetRolePermissionArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteAccountPeriodArgs = {
  input: AccountPeriodDeleteInput;
};


export type MutationCancelAccountPeriodArgs = {
  input: AccountPeriodDeleteInput;
};


export type MutationCreateAccountPeriodArgs = {
  input?: Maybe<Array<AccountPeriodInput>>;
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
};


export type MutationUpdateAccountPeriodArgs = {
  input: Array<AccountPeriodInput>;
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
};


export type MutationCloseAccountPeriodArgs = {
  input: Array<AccountPeriodInput>;
  AccountPeriodID: Scalars['String'];
  CompanyID: Scalars['String'];
  FYear: Scalars['Float'];
};


export type MutationUpdateArAgingArgs = {
  input: AgingInput;
};


export type MutationDeleteArAgingArgs = {
  input: AgingDeleteInput;
};


export type MutationCancelArAgingArgs = {
  input: AgingDeleteInput;
};


export type MutationUpdateApAgingArgs = {
  input: AgingInput;
};


export type MutationDeleteApAgingArgs = {
  input: AgingDeleteInput;
};


export type MutationCancelApAgingArgs = {
  input: AgingDeleteInput;
};


export type MutationCreateArAgingArgs = {
  input: AgingInput;
};


export type MutationCreateApAgingArgs = {
  input: AgingInput;
};


export type MutationCancelApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationDeleteApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationCancelApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationCreateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationUpdateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationDeleteApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationCreateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationUpdateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationCreateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationUpdateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationDeleteBankAccountArgs = {
  input: BankAccountDeleteInput;
};


export type MutationCancelBankAccountArgs = {
  input: BankAccountDeleteInput;
};


export type MutationCreateBankProfileArgs = {
  input: BankProfileInput;
};


export type MutationUpdateBankProfileArgs = {
  input: BankProfileInput;
};


export type MutationDeleteBankProfileArgs = {
  input: BankProfileDeleteInput;
};


export type MutationCancelBankProfileArgs = {
  input: BankProfileDeleteInput;
};


export type MutationCreateBillItemArgs = {
  input: BillItemInput;
};


export type MutationUpdateBillItemArgs = {
  input: BillItemInput;
};


export type MutationDeleteBillItemArgs = {
  input: BillItemDeleteInput;
};


export type MutationCancelBillItemArgs = {
  input: BillItemDeleteInput;
};


export type MutationUpdateCoaStructureArgs = {
  input: CoaStructureInput;
};


export type MutationDeleteCoaStructureArgs = {
  input: CoaStructureDeleteInput;
};


export type MutationCancelCoaStructureArgs = {
  input: CoaStructureDeleteInput;
};


export type MutationCreateCoaStructureArgs = {
  COAStructureInput: Array<CoaStructureInput>;
};


export type MutationDeleteCompanyArgs = {
  input: CompanyDeleteInput;
};


export type MutationCancelCompanyArgs = {
  input: CompanyDeleteInput;
};


export type MutationCreateCompanyArgs = {
  input: CompanyInput;
};


export type MutationUpdateCompanyArgs = {
  input: CompanyInput;
};


export type MutationDeleteCompanyProfileArgs = {
  CompanyID: Scalars['String'];
};


export type MutationUpdateContactArgs = {
  input: ContactInput;
};


export type MutationUpdateCostCentreArgs = {
  input: CostCentreInput;
};


export type MutationCancelCostCentreArgs = {
  input: CostCentreDeleteInput;
};


export type MutationCreateCostCentreDefinitionAsgmtArgs = {
  input: CostCentreDefinitionAsgmtInput;
};


export type MutationDeleteCostCentreArgs = {
  CompanyID: Scalars['String'];
  CostCentreID: Scalars['String'];
};


export type MutationCreateCostCentreArgs = {
  input: CostCentreInput;
};


export type MutationSetDefaultCostCentreArgs = {
  CompanyID: Scalars['String'];
  CostCentreID: Scalars['String'];
};


export type MutationCreateCostCentreCoaArgs = {
  CostCentreCOAInput: Array<CostCentreCoaInput>;
};


export type MutationDeleteCostcentreCoaArgs = {
  CostCentreCOADeleteInput: Array<CostCentreCoaDeleteInput>;
};


export type MutationAssignCostCentreCoaArgs = {
  CostCentreArr: Array<Scalars['String']>;
  MasterCOAID: Scalars['String'];
};


export type MutationUnassignCostCentreCoaArgs = {
  CostCentreCOAArr: Array<Scalars['String']>;
  MasterCOAID: Scalars['String'];
};


export type MutationUpdateCostCentreDefinitionHeaderArgs = {
  input: CostCentreDefinitionHeaderInput;
};


export type MutationCancelCostCentreDefinitionHeaderArgs = {
  input: CostCentreDefinitionHeaderDeleteInput;
};


export type MutationUpdateCostCentreDefinitionDetailArgs = {
  input: CostCentreDefinitionDetailInput;
};


export type MutationDeleteCostCentreDefinitionDetailArgs = {
  input: CostCentreDefinitionDetailDeleteInput;
};


export type MutationCancelCostCentreDefinitionDetailArgs = {
  input: CostCentreDefinitionDetailDeleteInput;
};


export type MutationCreateCostCentreDefinitionArgs = {
  CostCentreDefinitionDetailInput: Array<CostCentreDefinitionDetailInput>;
  CostCentreDefinitionHeaderInput: CostCentreDefinitionHeaderInput;
};


export type MutationCreateUpdateCostCentreDefinitionArgs = {
  CostCentreDefinitionDetailInput: Array<CostCentreDefinitionDetailInput>;
  CompanyID: Scalars['String'];
};


export type MutationDeleteCostCentreDefinitionArgs = {
  CostCentreDefinitionHeaderID: Scalars['String'];
};


export type MutationCreateCostCentreTypeArgs = {
  input: CostCentreTypeInput;
};


export type MutationUpdateCostCentreTypeArgs = {
  input: CostCentreTypeInput;
};


export type MutationDeleteCostCentreTypeArgs = {
  input: CostCentreTypeDeleteInput;
};


export type MutationCancelCostCentreTypeArgs = {
  input: CostCentreTypeDeleteInput;
};


export type MutationCreateUpdateCostCentreTypeArgs = {
  CostCentreTypeArr: Array<CostCentreTypeInput>;
};


export type MutationUpdateCreditCardTypeArgs = {
  input: CreditCardTypeInput;
};


export type MutationDeleteCreditCardTypeArgs = {
  input: CreditCardTypeDeleteInput;
};


export type MutationCancelCreditCardTypeArgs = {
  input: CreditCardTypeDeleteInput;
};


export type MutationCreateCreditCardTypeArgs = {
  CreditCardTypeInput: Array<CreditCardTypeInput>;
};


export type MutationUpdateCreditorAccountArgs = {
  input: CreditorAccountInput;
};


export type MutationDeleteCreditorAccountArgs = {
  input: CreditorAccountDeleteInput;
};


export type MutationCancelCreditorAccountArgs = {
  input: CreditorAccountDeleteInput;
};


export type MutationCreateCreditorAccountArgs = {
  directorItem?: Maybe<Array<CreditorDirectorInput>>;
  contactItem?: Maybe<Array<CreditorContactInput>>;
  input: CreditorAccountInput;
};


export type MutationUpdateCreditorAccountWithItemArgs = {
  directorItem?: Maybe<Array<CreditorDirectorInput>>;
  contactItem?: Maybe<Array<CreditorContactInput>>;
  input: CreditorAccountInput;
};


export type MutationCreateCreditorAccountWithAccountArgs = {
  directorItem?: Maybe<Array<CreditorDirectorInput>>;
  contactItem?: Maybe<Array<CreditorContactInput>>;
  input: CreditorAccountInput;
  userID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateCreditorAccountCompanyAssignmentArgs = {
  input: CreditorAccountCompanyAssignmentInput;
};


export type MutationDeleteCreditorAccountCompanyAssignmentArgs = {
  input: CreditorAccountCompanyAssignmentDeleteInput;
};


export type MutationCancelCreditorAccountCompanyAssignmentArgs = {
  input: CreditorAccountCompanyAssignmentDeleteInput;
};


export type MutationAssignCreditorAccountComapanyArgs = {
  input: CreditorAccountCompanyAssignmentInput;
};


export type MutationUnassignCreditorAccountComapanyArgs = {
  input: CreditorAccountCompanyAssignmentInput;
};


export type MutationCreateCreditorContactArgs = {
  input: CreditorContactInput;
};


export type MutationUpdateCreditorContactArgs = {
  input: CreditorContactInput;
};


export type MutationDeleteCreditorContactArgs = {
  input: CreditorContactDeleteInput;
};


export type MutationCancelCreditorContactArgs = {
  input: CreditorContactDeleteInput;
};


export type MutationCreateCreditorDirectorArgs = {
  input: CreditorDirectorInput;
};


export type MutationUpdateCreditorDirectorArgs = {
  input: CreditorDirectorInput;
};


export type MutationDeleteCreditorDirectorArgs = {
  input: CreditorDirectorDeleteInput;
};


export type MutationCancelCreditorDirectorArgs = {
  input: CreditorDirectorDeleteInput;
};


export type MutationCreateCreditorTypeArgs = {
  input: CreditorTypeInput;
};


export type MutationUpdateCreditorTypeArgs = {
  input: CreditorTypeInput;
};


export type MutationDeleteCreditorTypeArgs = {
  input: CreditorTypeDeleteInput;
};


export type MutationCancelCreditorTypeArgs = {
  input: CreditorTypeDeleteInput;
};


export type MutationCreateCreditorTypeWithAccountArgs = {
  input: CreditorTypeInput;
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationCreateCurrencyArgs = {
  input: CurrencyInput;
};


export type MutationUpdateCurrencyArgs = {
  input: CurrencyInput;
};


export type MutationDeleteCurrencyArgs = {
  input: CurrencyDeleteInput;
};


export type MutationCancelCurrencyArgs = {
  input: CurrencyDeleteInput;
};


export type MutationCancelCurrencyExchangeArgs = {
  input: CurrencyExchangeDeleteInput;
};


export type MutationCreateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationUpdateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationDeleteCurrencyExchangeArgs = {
  CurrencyExchangeID: Scalars['String'];
};


export type MutationUpdateDebtorAccountArgs = {
  input: DebtorAccountInput;
};


export type MutationDeleteDebtorAccountArgs = {
  input: DebtorAccountDeleteInput;
};


export type MutationCancelDebtorAccountArgs = {
  input: DebtorAccountDeleteInput;
};


export type MutationCreateDebtorAccountArgs = {
  input: DebtorAccountInput;
  contactItem?: Maybe<Array<DebtorContactInput>>;
};


export type MutationUpdateDebtorAccountWithItemArgs = {
  contactItem?: Maybe<Array<DebtorContactInput>>;
  input: DebtorAccountInput;
};


export type MutationCreateDebtorContactArgs = {
  input: DebtorContactInput;
};


export type MutationUpdateDebtorContactArgs = {
  input: DebtorContactInput;
};


export type MutationDeleteDebtorContactArgs = {
  input: DebtorContactDeleteInput;
};


export type MutationCancelDebtorContactArgs = {
  input: DebtorContactDeleteInput;
};


export type MutationCreateDebtorTypeArgs = {
  input: DebtorTypeInput;
};


export type MutationUpdateDebtorTypeArgs = {
  input: DebtorTypeInput;
};


export type MutationDeleteDebtorTypeArgs = {
  input: DebtorTypeDeleteInput;
};


export type MutationCancelDebtorTypeArgs = {
  input: DebtorTypeDeleteInput;
};


export type MutationDeleteDeliveryLocationArgs = {
  input: DeliveryLocationDeleteInput;
};


export type MutationCancelDeliveryLocationArgs = {
  input: DeliveryLocationDeleteInput;
};


export type MutationCreateDeliveryLocArgs = {
  input: DeliveryLocationInput;
};


export type MutationUpdateDeliveryLocArgs = {
  input: DeliveryLocationInput;
};


export type MutationUpdateDocumentDateValidationArgs = {
  input: DocumentDateValidationInput;
};


export type MutationDeleteDocumentDateValidationArgs = {
  input: DocumentDateValidationDeleteInput;
};


export type MutationCancelDocumentDateValidationArgs = {
  input: DocumentDateValidationDeleteInput;
};


export type MutationCreateDocumentDateValidationArgs = {
  DocumentDateValidationInput: Array<DocumentDateValidationInput>;
};


export type MutationCreateDocumentNumberDetailArgs = {
  input: DocumentNumberDetailInput;
};


export type MutationUpdateDocumentNumberDetailArgs = {
  input: DocumentNumberDetailInput;
};


export type MutationDeleteDocumentNumberDetailArgs = {
  input: DocumentNumberDetailDeleteInput;
};


export type MutationCancelDocumentNumberDetailArgs = {
  input: DocumentNumberDetailDeleteInput;
};


export type MutationDeleteDocumentNumberHeaderArgs = {
  input: DocumentNumberHeaderDeleteInput;
};


export type MutationCancelDocumentNumberHeaderArgs = {
  input: DocumentNumberHeaderDeleteInput;
};


export type MutationCreateDocumentNumberHeaderArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput>;
  DocumentNumberHeaderInput: DocumentNumberHeaderInput;
};


export type MutationUpdateDocumentNumberHeaderArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput>;
  DocumentNumberHeaderInput: DocumentNumberHeaderInput;
};


export type MutationUpdateIsAutoDocNoArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  input: DocumentNumberHeaderInput;
  IsAutoDocNo: Scalars['Boolean'];
  RefTable: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type MutationCreateEntityCoaArgs = {
  input: EntityCoaInput;
};


export type MutationUpdateEntityCoaArgs = {
  input: EntityCoaInput;
};


export type MutationDeleteEntityCoaArgs = {
  input: EntityCoaDeleteInput;
};


export type MutationCancelEntityCoaArgs = {
  input: EntityCoaDeleteInput;
};


export type MutationAssignEntityCoaArgs = {
  CompanyArr: Array<Scalars['String']>;
  MasterCOAID: Scalars['String'];
};


export type MutationDeleteAssignedEntityCoaArgs = {
  CompanyArr: Array<Scalars['String']>;
  MasterCOAID: Scalars['String'];
};


export type MutationCancelExpenseClassArgs = {
  input: ExpenseClassDeleteInput;
};


export type MutationDeleteExpenseClassArgs = {
  ExpenseClassID: Scalars['String'];
};


export type MutationCreateExpenseClassArgs = {
  input: ExpenseClassInput;
};


export type MutationUpdateExpenseClassArgs = {
  input: ExpenseClassInput;
};


export type MutationCancelExpenseItemArgs = {
  input: ExpenseItemDeleteInput;
};


export type MutationCreateExpenseItemArgs = {
  input: ExpenseItemInput;
};


export type MutationUpdateExpenseItemArgs = {
  input: ExpenseItemInput;
};


export type MutationDeleteExpenseItemArgs = {
  ExpenseItemID: Scalars['String'];
};


export type MutationTestDeleteExpenseItemArgs = {
  ExpenseItemID: Scalars['String'];
};


export type MutationInitialMasterFileSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialTaxSetupArgs = {
  AccountID?: Maybe<Scalars['String']>;
};


export type MutationExtraSystemAdminSetupArgs = {
  AccountID: Scalars['String'];
};


export type MutationInsertAdjustmentReasonArgs = {
  AccountID: Scalars['String'];
};


export type MutationCreateSystemJournalTypeArgs = {
  CompanyIDs: Array<Scalars['String']>;
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationUpdateItemClassCompanyAssignmentArgs = {
  input: ItemClassCompanyAssignmentInput;
};


export type MutationDeleteItemClassCompanyAssignmentArgs = {
  input: ItemClassCompanyAssignmentDeleteInput;
};


export type MutationCancelItemClassCompanyAssignmentArgs = {
  input: ItemClassCompanyAssignmentDeleteInput;
};


export type MutationAssignItemClassCompanyArgs = {
  input: ItemClassCompanyAssignmentInput;
};


export type MutationUnassignItemClassCompanyArgs = {
  input: ItemClassCompanyAssignmentInput;
};


export type MutationCreateJournalTypeArgs = {
  input: JournalTypeInput;
};


export type MutationUpdateJournalTypeArgs = {
  input: JournalTypeInput;
};


export type MutationDeleteJournalTypeArgs = {
  input: JournalTypeDeleteInput;
};


export type MutationCancelJournalTypeArgs = {
  input: JournalTypeDeleteInput;
};


export type MutationUpdateMasterCoaArgs = {
  input: MasterCoaInput;
};


export type MutationCancelMasterCoaArgs = {
  input: MasterCoaDeleteInput;
};


export type MutationCreateMasterCoaArgs = {
  input: Array<MasterCoaInput>;
};


export type MutationDeleteMasterCoaArgs = {
  MasterCOAID: Scalars['String'];
};


export type MutationCreateExcelMasterCoaArgs = {
  MasterCOAExcelInput: Array<MasterCoaInput>;
};


export type MutationCreateNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationUpdateNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationDeleteNumberFormatArgs = {
  input: NumberFormatDeleteInput;
};


export type MutationCancelNumberFormatArgs = {
  input: NumberFormatDeleteInput;
};


export type MutationUpdatePaymentMethodArgs = {
  input: PaymentMethodInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: PaymentMethodDeleteInput;
};


export type MutationCancelPaymentMethodArgs = {
  input: PaymentMethodDeleteInput;
};


export type MutationCreatePaymentMethodArgs = {
  PaymentMethodInput: Array<PaymentMethodInput>;
};


export type MutationUpdateRetainEarningAccountDefArgs = {
  input: RetainEarningAccountDefInput;
};


export type MutationDeleteRetainEarningAccountDefArgs = {
  input: RetainEarningAccountDefDeleteInput;
};


export type MutationCancelRetainEarningAccountDefArgs = {
  input: RetainEarningAccountDefDeleteInput;
};


export type MutationCreateRetainEarningAccountDefArgs = {
  input: RetainEarningAccountDefInput;
};


export type MutationCancelStockItemArgs = {
  input: StockItemDeleteInput;
};


export type MutationCreateStockItemArgs = {
  input: StockItemInput;
  warehouseInput?: Maybe<Array<StockItemWarehouseInput>>;
};


export type MutationUpdateStockItemWithWarehouseArgs = {
  warehouse?: Maybe<Array<StockItemWarehouseInput>>;
  StockItemID: Scalars['String'];
  input: StockItemInput;
};


export type MutationUpdateStockItemArgs = {
  input: StockItemInput;
};


export type MutationDeleteStockItemArgs = {
  input: StockItemDeleteInput;
};


export type MutationCreateStockItemWarehouseArgs = {
  input: StockItemWarehouseInput;
};


export type MutationUpdateStockItemWarehouseArgs = {
  input: StockItemWarehouseInput;
};


export type MutationDeleteStockItemWarehouseArgs = {
  input: StockItemWarehouseDeleteInput;
};


export type MutationCancelStockItemWarehouseArgs = {
  input: StockItemWarehouseDeleteInput;
};


export type MutationCreateTaxTypeArgs = {
  input: TaxTypeInput;
};


export type MutationUpdateTaxTypeArgs = {
  input: TaxTypeInput;
};


export type MutationDeleteTaxTypeArgs = {
  input: TaxTypeInput;
};


export type MutationCreateTaxSchemeArgs = {
  input: TaxSchemeInput;
};


export type MutationUpdateTaxSchemeArgs = {
  input: TaxSchemeInput;
};


export type MutationCreateTaxEffectiveDateArgs = {
  input: TaxEffectiveDateInput;
};


export type MutationUpdateTaxEffectiveDateArgs = {
  input: TaxEffectiveDateInput;
};


export type MutationDeleteTaxEffectiveDateArgs = {
  input: TaxEffectiveDateInput;
};


export type MutationInsertTaxArgs = {
  TaxEffectiveInput: Array<TaxEffectiveInput>;
  TaxSchemeInput: TaxSchemeInput;
};


export type MutationUpdateTaxArgs = {
  TaxEffectiveInput: Array<TaxEffectiveInput>;
  TaxSchemeInput: TaxSchemeInput;
};


export type MutationDeleteTaxSchemeArgs = {
  TaxSchemeID: Scalars['String'];
};


export type MutationCreateTransactionTypeArgs = {
  input: TransactionTypeInput;
};


export type MutationUpdateTransactionTypeArgs = {
  input: TransactionTypeInput;
};


export type MutationDeleteTransactionTypeArgs = {
  input: TransactionTypeDeleteInput;
};


export type MutationCancelTransactionTypeArgs = {
  input: TransactionTypeDeleteInput;
};


export type MutationCreateUomArgs = {
  input: UomInput;
};


export type MutationUpdateUomArgs = {
  input: UomInput;
};


export type MutationDeleteUomArgs = {
  input: UomDeleteInput;
};


export type MutationCreateUomWithAccountArgs = {
  input: UomInput;
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationUpdateUomWithAccountArgs = {
  input: UomInput;
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationCreateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationUpdateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationDeleteUomExchangeArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateWarehouseArgs = {
  WarehouseID: Scalars['String'];
  WarehouseInput: WarehouseInput;
};


export type MutationDeleteWarehouseArgs = {
  input: WarehouseDeleteInput;
};


export type MutationCancelWarehouseArgs = {
  input: WarehouseDeleteInput;
};


export type MutationCreateWarehouseArgs = {
  WarehouseInput: WarehouseInput;
};


export type MutationApproveRejectWorkDeskArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  WorkDeskInput: Array<WorkDeskInput>;
};

export type NumberFormatDeleteInput = {
  NumberFormatID: Scalars['String'];
};

export type NumberFormatEntity = AuditEntity & {
  __typename?: 'NumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  NumberFormatID: Scalars['String'];
  Format: Scalars['String'];
  Constant: Scalars['String'];
  Day: Scalars['Float'];
  Month: Scalars['Float'];
  Year: Scalars['Float'];
  CompanyCode: Scalars['String'];
  SerialNo: Scalars['String'];
};

export type NumberFormatInput = {
  NumberFormatID?: Maybe<Scalars['String']>;
  Format: Scalars['String'];
  Constant: Scalars['String'];
  Day: Scalars['Float'];
  Month: Scalars['Float'];
  Year: Scalars['Float'];
  CompanyCode: Scalars['String'];
  SerialNo: Scalars['String'];
  CompanyID: Scalars['String'];
};

export type PoHeaderEntity = AuditEntity & {
  __typename?: 'POHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  POHeaderID: Scalars['String'];
  SupplierID: Scalars['String'];
  CompanyID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  ContactPerson?: Maybe<Scalars['JSON']>;
  ExpectedDate: Scalars['String'];
  Instruction?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  POType: PoType;
  Remarks?: Maybe<Scalars['String']>;
  POItem?: Maybe<Array<PoItemEntity>>;
  POItemPricing?: Maybe<Array<PoItemPricingEntity>>;
  /** CustomFieldResolver */
  TotalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  Company: CompanyEntity;
  Supplier?: Maybe<CreditorAccountEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
};

export type PoInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  Instruction?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['JSON']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  POItems: Array<PoItemInput>;
  POType: PoType;
};

export type PoItemEntity = AuditEntity & {
  __typename?: 'POItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  POItemID: Scalars['String'];
  POHeaderID: Scalars['String'];
  POHeader?: Maybe<PoHeaderEntity>;
  PRItemID?: Maybe<Scalars['String']>;
  PRItem?: Maybe<PrItemEntity>;
  ItemID: Scalars['String'];
  Item?: Maybe<ItemEntity>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
  OrderedQty: Scalars['Float'];
  OutstandingQty: Scalars['Float'];
  UnitPrice: Scalars['Float'];
  BaseAmt: Scalars['Float'];
  TaxSchemeID: Scalars['String'];
  TaxAmt: Scalars['Float'];
  TotalAmt: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  DOItem?: Maybe<Array<DoItemEntity>>;
  ScheduledPurchasesItemID?: Maybe<Scalars['String']>;
  PriceRefTable?: Maybe<Scalars['String']>;
  PriceRefID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  UOMExchange?: Maybe<Array<PoUomExchange>>;
  /** CustomFieldResolver */
  UOM?: Maybe<AccUomEntity>;
  /** CustomFieldResolver */
  TaxScheme?: Maybe<TaxSchemeEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  Warehouse?: Maybe<WarehouseEntity>;
  /** CustomFieldResolver */
  ScheduledPurchaseDetail?: Maybe<Scalars['String']>;
};

export type PoItemInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  PRItemID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  DeliveryTo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  OutstandingQty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TotalAmt?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  OrderedQty?: Maybe<Scalars['Float']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ScheduledPurchasesItemID?: Maybe<Scalars['String']>;
};

export type PoItemPricingEntity = AuditEntity & {
  __typename?: 'POItemPricingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  POItemPricingID: Scalars['String'];
  SupplierID: Scalars['String'];
  ItemID: Scalars['String'];
  UOMID?: Maybe<Scalars['String']>;
  TotalQuantity: Scalars['Float'];
  UnitPrice: Scalars['Float'];
  PriceType: PriceType;
  POHeaderID: Scalars['String'];
  POHeader?: Maybe<PoHeaderEntity>;
  PRItem?: Maybe<Array<PrItemEntity>>;
};

export type PoItemsInput = {
  DOItemID?: Maybe<Scalars['String']>;
  POItemID?: Maybe<Scalars['String']>;
  GRTNItemID?: Maybe<Scalars['String']>;
  AcceptedQty?: Maybe<Scalars['Float']>;
  AcceptedAmt?: Maybe<Scalars['Float']>;
  DeliveredQty?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
};

export type PoRejectInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};

export enum PoType {
  PoFromPr = 'PO_FROM_PR',
  DirectPo = 'DIRECT_PO',
  CentralisedPo = 'CENTRALISED_PO'
}

export type PrHeaderEntity = AuditEntity & {
  __typename?: 'PRHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  PRHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  RequestedBy?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Instruction?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  PRItem?: Maybe<Array<PrItemEntity>>;
  /** CustomFieldResolver */
  Company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
};

export type PrInput = {
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  RequestedBy?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['String']>;
  Instruction: Scalars['String'];
  ContactNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  PRHeaderID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<AccountType>;
  ApprovalStatus: ApprovalStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PrItemEntity = AuditEntity & {
  __typename?: 'PRItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  PRItemID: Scalars['String'];
  PRHeaderID: Scalars['String'];
  PRHeader?: Maybe<PrHeaderEntity>;
  ItemID: Scalars['String'];
  Item?: Maybe<ItemEntity>;
  CostCentreID: Scalars['String'];
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  UOMID: Scalars['String'];
  Quantity: Scalars['Float'];
  OutstandingQty?: Maybe<Scalars['Float']>;
  POItemPricingID?: Maybe<Scalars['String']>;
  POItemPricing?: Maybe<PoItemPricingEntity>;
  Remark?: Maybe<Scalars['String']>;
  IsPOGenerated: Scalars['Boolean'];
  RFQItem?: Maybe<Array<RfqItemEntity>>;
  POItem?: Maybe<Array<PoItemEntity>>;
  /** CustomFieldResolver */
  CostCentre?: Maybe<CostCentreEntity>;
  /** CustomFieldResolver */
  UOM?: Maybe<AccUomEntity>;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
  /** CustomFieldResolver */
  Warehouse?: Maybe<WarehouseEntity>;
  /** CustomFieldResolver */
  rfqSubmissionSelected?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  bulkPurchaseSelected?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  masterFileSelected?: Maybe<Scalars['JSON']>;
};

export type PrItemInput = {
  ItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  PRHeaderID?: Maybe<Scalars['String']>;
};

export type PaymentEntity = AuditEntity & {
  __typename?: 'PaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
  PaymentID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  LedgerID?: Maybe<Scalars['String']>;
  PayeeName?: Maybe<Scalars['String']>;
  PaymentItem?: Maybe<Array<PaymentItemEntity>>;
};

export type PaymentItemEntity = AuditEntity & {
  __typename?: 'PaymentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  PaymentItemID: Scalars['String'];
  DocDate?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  PaymentID: Scalars['String'];
  Payment?: Maybe<PaymentEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  CompanyID?: Maybe<Scalars['String']>;
  Company?: Maybe<CompanyEntity>;
};

export type PaymentMethodDeleteInput = {
  PaymentMethodID: Scalars['String'];
};

export type PaymentMethodEntity = AuditEntity & {
  __typename?: 'PaymentMethodEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  PaymentMethodID: Scalars['String'];
  Name: Scalars['String'];
  Description: Scalars['String'];
  Sequence: Scalars['Float'];
  IsSystem: Scalars['Boolean'];
  Payment?: Maybe<Array<PaymentEntity>>;
  Receipt?: Maybe<Array<ReceiptEntity>>;
  FavoritePayment?: Maybe<Array<FavoritePaymentEntity>>;
  FavoriteReceipt?: Maybe<Array<FavoriteReceiptEntity>>;
  APAdvance?: Maybe<Array<AccApAdvanceEntity>>;
  BankTransfer?: Maybe<Array<BankTransferEntity>>;
  ARAdvance?: Maybe<Array<AccArAdvanceEntity>>;
  APRefund?: Maybe<Array<AccApRefundEntity>>;
  APPayment?: Maybe<Array<AccApPaymentEntity>>;
  AROfficialReceipt?: Maybe<Array<AccArOfficialReceiptEntity>>;
  ARRefund?: Maybe<Array<AccArRefundEntity>>;
};

export type PaymentMethodInput = {
  PaymentMethodID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Description: Scalars['String'];
  Sequence: Scalars['Float'];
  IsSystem: Scalars['Boolean'];
  IsUsed?: Maybe<Scalars['Boolean']>;
};

/** JsonType */
export type PendingToDo = {
  __typename?: 'PendingToDo';
  PendingGL?: Maybe<Scalars['Float']>;
  PendingCB?: Maybe<Scalars['Float']>;
  PendingAR?: Maybe<Scalars['Float']>;
  PendingAP?: Maybe<Scalars['Float']>;
};

export type PreparePoEntry = {
  input: Array<PreparePoInput>;
};

export type PreparePoHeaderInput = {
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  Instruction?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  POType: PoType;
  ContactPerson?: Maybe<Scalars['JSON']>;
  Remarks?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type PreparePoInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  POType: PoType;
  Instruction?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  ContactPerson?: Maybe<Scalars['JSON']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  POItems: Array<PreparePoItemInput>;
};

export type PreparePoItemBySupplierInput = {
  SupplierID?: Maybe<Scalars['String']>;
  POItems: Array<PreparePoItemInput>;
};

export type PreparePoItemInput = {
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  PRItemID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  DeliveryTo?: Maybe<Scalars['String']>;
  Remarks?: Maybe<Scalars['String']>;
  OrderQty?: Maybe<Scalars['Float']>;
  OutstandingQty?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TotalAmt?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  OrderedQty?: Maybe<Scalars['Float']>;
  BaseAmt?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  ScheduledPurchasesItemID?: Maybe<Scalars['String']>;
  IsCentralised?: Maybe<Scalars['Boolean']>;
  StockItemID?: Maybe<Scalars['String']>;
};

export enum PriceType {
  BulkPurchase = 'BULK_PURCHASE',
  MarketPrice = 'MARKET_PRICE',
  Rfq = 'RFQ'
}

export type ProcurementApprovalListJson = {
  __typename?: 'ProcurementApprovalListJson';
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  RoleName?: Maybe<Scalars['String']>;
};

export type ProcurementApprovalListJsonInput = {
  Category: Scalars['String'];
  StepNo: Scalars['Float'];
  RoleID: Scalars['String'];
  Predicate?: Maybe<Scalars['String']>;
  Amount?: Maybe<Scalars['Float']>;
  RoleName?: Maybe<Scalars['String']>;
};

export type ProcurementApprovalPolicyAssignmentDeleteInput = {
  ApprovalPolicyAssignmentID: Scalars['String'];
};

export type ProcurementApprovalPolicyAssignmentEntity = AuditEntity & {
  __typename?: 'ProcurementApprovalPolicyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalPolicyAssignmentID: Scalars['String'];
  ApprovalPolicyID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
  Category: Scalars['String'];
  LowerLimit?: Maybe<Scalars['Float']>;
  UpperLimit?: Maybe<Scalars['Float']>;
  Company?: Maybe<CompanyEntity>;
};

export type ProcurementApprovalPolicyAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  ApprovalList: Array<ProcurementApprovalListJsonInput>;
};

export type ProcurementApprovalPolicyDeleteInput = {
  ApprovalPolicyID: Scalars['String'];
};

export type ProcurementApprovalPolicyEntity = AuditEntity & {
  __typename?: 'ProcurementApprovalPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalPolicyID: Scalars['String'];
  Category: Scalars['String'];
  ApprovalList?: Maybe<Array<Scalars['JSON']>>;
};

export type ProcurementApprovalPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  ApprovalList: Array<ProcurementApprovalListJsonInput>;
};

export type ProcurementConDocTypeEntity = AuditEntity & {
  __typename?: 'ProcurementConDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
  docCode: PurchaserDocNum;
  code?: Maybe<Scalars['String']>;
  numberFormat: Array<ProcurementConNumberFormatEntity>;
};

export type ProcurementConNumberFormatEntity = AuditEntity & {
  __typename?: 'ProcurementConNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
  softwareCode: Scalars['String'];
  documentDivider: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  prefixExtra?: Maybe<Scalars['String']>;
  docType: ProcurementConDocTypeEntity;
  runningNum: Array<ProcurementConRunningNumberSetupEntity>;
};

export type ProcurementConRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'ProcurementConRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
  accountID: Scalars['String'];
  numberFormat: ProcurementConNumberFormatEntity;
};

export type ProcurementCoreApprovalEntity = AuditEntity & {
  __typename?: 'ProcurementCoreApprovalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
};

export type ProcurementCoreEntity = AuditEntity & {
  __typename?: 'ProcurementCoreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
};

export type ProcurementCreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export type ProcurementDocumentsInput = {
  fileType?: Maybe<FileType>;
  refTable?: Maybe<RefTable>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  RecordStatus?: Maybe<RecordStatus>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
};

export type ProcurementEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type ProcurementRoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type ProcurementWorkFlowApprovedStepEntity = AuditEntity & {
  __typename?: 'ProcurementWorkFlowApprovedStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowApprovedStepID: Scalars['String'];
  WorkFlowStepID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  ApprovalDT?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<GpApprovalStatus>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlowStep: ProcurementWorkFlowStepEntity;
};

export type ProcurementWorkFlowEntity = AuditEntity & {
  __typename?: 'ProcurementWorkFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowID: Scalars['String'];
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['String']>;
  WorkFlowStatus?: Maybe<GpApprovalStatus>;
  WorkFlowStep?: Maybe<Array<ProcurementWorkFlowStepEntity>>;
};

export type ProcurementWorkFlowStepEntity = AuditEntity & {
  __typename?: 'ProcurementWorkFlowStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WorkFlowStepID: Scalars['String'];
  WorkFlowID?: Maybe<Scalars['String']>;
  ApproverRoleID?: Maybe<Scalars['String']>;
  NotifiedDT?: Maybe<Scalars['String']>;
  StepNo?: Maybe<Scalars['Float']>;
  ApprovalStatus?: Maybe<GpApprovalStatus>;
  StatusRemarks?: Maybe<Scalars['String']>;
  WorkFlow: ProcurementWorkFlowEntity;
  WorkFlowApprovedStep?: Maybe<Array<ProcurementWorkFlowApprovedStepEntity>>;
};

export type PurchaseActionInput = {
  CancelCloseRemark?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseBudgetInput = {
  Remarks: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseItemVariancePolicyEntity = AuditEntity & {
  __typename?: 'PurchaseItemVariancePolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  PurchaseItemVariancePolicyID: Scalars['String'];
  VariancePerc?: Maybe<Scalars['Float']>;
};

export type PurchaseItemVariancePolicyInput = {
  PurchaseItemVariancePolicyID?: Maybe<Scalars['String']>;
  VariancePerc?: Maybe<Scalars['Float']>;
};

export type PurchaserCoreAssociateEntity = AuditEntity & {
  __typename?: 'PurchaserCoreAssociateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
};

export enum PurchaserDocNum {
  GPr = 'G_PR',
  GRfq = 'G_RFQ',
  GPo = 'G_PO',
  ArClaim = 'AR_CLAIM',
  ArMiscIv = 'AR_MISC_IV',
  ArCn = 'AR_CN',
  ArDn = 'AR_DN',
  ArAdvanceRecoup = 'AR_ADVANCE_RECOUP',
  ArPayment = 'AR_PAYMENT',
  ArReceipt = 'AR_RECEIPT',
  ArInvoice = 'AR_INVOICE',
  ApClaim = 'AP_CLAIM',
  ApMiscIv = 'AP_MISC_IV',
  ApCn = 'AP_CN',
  ApDn = 'AP_DN',
  ApAdvanceRecoup = 'AP_ADVANCE_RECOUP',
  ApPayment = 'AP_PAYMENT',
  ApReceipt = 'AP_RECEIPT',
  ApInvoice = 'AP_INVOICE',
  Default = 'DEFAULT',
  Df = 'DF',
  StockMvmt = 'STOCK_MVMT',
  Grn = 'GRN',
  Grtn = 'GRTN',
  Po = 'PO',
  Pr = 'PR',
  Rfq = 'RFQ',
  Vo = 'VO',
  Svo = 'SVO'
}

export type PurchaserRoleEntity = AuditEntity & {
  __typename?: 'PurchaserRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  softwareCode: SoftwareCode;
  rolePerm: Array<PurchaserRolePermissionAssignmentEntity>;
  conRolePerm: Array<PurchaserRolePermissionAssignmentEntity>;
  siteRolePerm: Array<PurchaserRolePermissionAssignmentEntity>;
};

export type PurchaserRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'PurchaserRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  accountID?: Maybe<Scalars['String']>;
  permName?: Maybe<Scalars['String']>;
};

export type PurchaserRoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'PurchaserRoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  Check: Scalars['Boolean'];
  UserEntities: Array<Scalars['String']>;
  testPermission: Scalars['String'];
  testConPermission: Scalars['String'];
  getAdjustmentType: Scalars['Boolean'];
  getDocumentFolder?: Maybe<Array<DocumentFolderEntity>>;
  lastApprovalPolicy?: Maybe<Scalars['DateTime']>;
  lastNumberingStructure?: Maybe<Scalars['DateTime']>;
  activeDeliveryLocationCount: Scalars['Float'];
  scheduledPurchaseInfoCount: Scalars['Float'];
  prInfoCount: Scalars['JSON'];
  rfqCount: Scalars['Float'];
  poInfoCount: Scalars['JSON'];
  doInfoCount: Scalars['Float'];
  lastDODate?: Maybe<Scalars['String']>;
  grtnInfoCount: Scalars['Float'];
  lastGRTNDate?: Maybe<Scalars['String']>;
  activeSupplierCategoryCount: Scalars['Float'];
  supplierCategoryCount: Scalars['JSON'];
  activeSupplierProfileCount: Scalars['Float'];
  supplierProfileCount: Scalars['JSON'];
  activeUomCount: Scalars['Float'];
  lastUomExchange?: Maybe<Scalars['DateTime']>;
  activePurchaseCategoryCount: Scalars['Float'];
  activeItemCount: Scalars['Float'];
  activeBulkPurchaseCount: Scalars['Float'];
  lastPurchaseVariance?: Maybe<Scalars['DateTime']>;
  purchaseVariancePercenetage?: Maybe<Scalars['Float']>;
  testImagePath: Scalars['JSON'];
  submittedByLedger: Scalars['String'];
  loggedInUserProfile: UserEntity;
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getUsersByAccountAndSoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  getProcurementApprovalPolicy: Array<ProcurementApprovalPolicyEntity>;
  getProcurementApprovalPolicyAssignment: Array<ProcurementApprovalPolicyAssignmentEntity>;
  getAllApprovalPolicy?: Maybe<Array<AccApprovalPolicyEntity>>;
  getApprovalPolicyAssignment: Array<AccApprovalPolicyAssignmentEntity>;
  getAllApprovalPolicyAssignment?: Maybe<Array<AccApprovalPolicyAssignmentEntity>>;
  DocumentListing?: Maybe<Array<AttachmentEntity>>;
  viewLogo: AttachmentEntity;
  attachmentListing?: Maybe<Array<AttachmentEntity>>;
  getBulkPurchasePrice: Array<BulkPurchasePriceHeaderEntity>;
  getSupplierAccountByCompany: Scalars['JSON'];
  getGeneralDO: Array<DoHeaderEntity>;
  getGeneralDOItem: Array<DoItemEntity>;
  getSupplierByDOStatus: Array<CreditorAccountEntity>;
  getWarehouseDeliveryLocation?: Maybe<Scalars['JSON']>;
  getGeneralPOs: Array<PoHeaderEntity>;
  getOldGeneralPOs?: Maybe<Scalars['JSON']>;
  getGeneralGRTNs: Array<GrtnHeaderEntity>;
  getGRTNHeader: Array<GrtnHeaderEntity>;
  getGeneralPRItem: Array<PrItemEntity>;
  getCentralisedPRItems: Array<PrItemEntity>;
  getPOHeader: Array<PoHeaderEntity>;
  getSupplierByPOStatus: Array<CreditorAccountEntity>;
  checkingCancelClosePO: Scalars['Boolean'];
  getPRHeader: Array<PrHeaderEntity>;
  getPRNoList: Array<Scalars['JSON']>;
  getOutstandingGeneralPRItems: Array<PrItemEntity>;
  getOSGeneralPR: Array<PrHeaderEntity>;
  checkingCancelClosePR: Scalars['Boolean'];
  getRFQHeader: Array<RfqHeaderEntity>;
  getRFQItem: Array<RfqItemEntity>;
  getRFQbyStatus: Array<RfqHeaderEntity>;
  getInvitedSupplier?: Maybe<Scalars['JSON']>;
  getRFQSupplierInvitation: Array<RfqSupplierInvitationEntity>;
  getRFQSupplierSubmission: Array<RfqSupplierSubmissionEntity>;
  getPRItemByPRIDForRFQ: Array<Scalars['JSON']>;
  getScheduledPurchases: Array<ScheduledPurchasesEntity>;
  getItem: Array<ItemEntity>;
  getCheckingValidationItem: Scalars['JSON'];
  getLatestTaxByTaxSchemeIDs: Scalars['JSON'];
  getWarehouseByStockItem: Scalars['JSON'];
  getItemByCompanyAssignment: Array<ItemEntity>;
  getItemCategory: Array<ItemCategoryEntity>;
  getItemCategoryCompanyAssignment: Array<ItemCategoryCompanyAssignmentEntity>;
  getPurchaseItemVariancePolicy: Array<PurchaseItemVariancePolicyEntity>;
  getSiteWithAccount: Scalars['JSON'];
  procurementLoggedInUserProfile: UserEntity;
  exampleQuery: Scalars['JSON'];
  getEntityRoleUserAssignment: Array<AccRoleUserEntAssignmentEntity>;
  getRole: Array<AccRoleEntity>;
  getRoleWithAccount: Array<AccRoleEntity>;
  AccuserRoleIDs: Array<AccuserRoleId>;
  getAccountPermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleCompany: Scalars['JSON'];
  testACLPermission: Scalars['String'];
  testAccPermission: Scalars['String'];
  getAccountPeriod: Array<AccountPeriodEntity>;
  getAccountYear: Scalars['JSON'];
  getNextAccPeriod: Scalars['JSON'];
  getSelectedAccountPeriod: AccountPeriodEntity;
  accountPeriodChecking: AccountPeriodCheck;
  getOpenAccPeriodDateRange: AccountPeriodDateRange;
  CheckExistingAccountPeriod: CheckExistingAccountPeriod;
  getPreviousPeriodOfYear: AccountPeriodEntity;
  latestOpenPeriodCheckingDate?: Maybe<Scalars['JSON']>;
  getARAging: Array<ArAgingEntity>;
  getAPAging: Array<ApAgingEntity>;
  getApprovalPolicy: Array<AccApprovalPolicyEntity>;
  getBankAccount: Array<AccBankAccountEntity>;
  getBankProfile: Array<BankProfileEntity>;
  getBankProfileWithAccount: Array<BankProfileEntity>;
  getBillItem: Array<BillItemEntity>;
  getCOAStructure: Array<CoaStructureEntity>;
  getCompany: Array<CompanyEntity>;
  getParentCompany: Array<CompanyEntity>;
  getAssignedCompanyByUser: Scalars['JSON'];
  getAssignedWarehouseCompanyByUser: Scalars['JSON'];
  getActiveCompanyCount: Scalars['Float'];
  getCompanyWithAccount: Scalars['JSON'];
  getCompanyNameWithAccount: Scalars['JSON'];
  getCompanyEntityWithAccount: Array<CompanyEntity>;
  getCompanyAssignedUsersByRole: Scalars['JSON'];
  getUserCompanyAssignedUsersByRole: Scalars['JSON'];
  getContact: Array<AccContactEntity>;
  getAllCostCentre: Array<CostCentreEntity>;
  getCostCentreDefinitionAsgmt: Array<CostCentreDefinitionAsgmtEntity>;
  getCostCentre: Array<CostCentreEntity>;
  getCostCentreListing: Scalars['JSON'];
  getNextLevelCCDefinition: Scalars['JSON'];
  getParentCostCentreForTree: Scalars['JSON'];
  getCostCentreWithAccount: CostCentreEntity;
  getCostCentreEntityWithAccount: Array<CostCentreEntity>;
  getCostCentreCOA: Array<CostCentreCoaEntity>;
  getCostCentreDefinitionHeader: Array<CostCentreDefinitionHeaderEntity>;
  getCostCentreDefinitionDetail: Array<CostCentreDefinitionDetailEntity>;
  getCostCentreDefinitionListing: Array<CostCentreDefinitionHeaderEntity>;
  getCostCentreDefinition: CostCentreDefSummary;
  getCostCentreDefinitionHeaderDetails: Array<CostCentreDefinitionDetailEntity>;
  getCostCentreType: Array<CostCentreTypeEntity>;
  getCreditCardType: Array<CreditCardTypeEntity>;
  getCreditorAccount: Array<CreditorAccountEntity>;
  getCreditorProfileWithAccount: Array<CreditorAccountEntity>;
  getCreditorAccountCompanyAssignment: Array<CreditorAccountCompanyAssignmentEntity>;
  CreditorAccountTotalDue: Scalars['JSON'];
  getCreditorContact: Array<CreditorContactEntity>;
  getCreditorDirector: Array<CreditorDirectorEntity>;
  getCreditorType: Array<CreditorTypeEntity>;
  getCreditorTypeWithAccount: Array<CreditorTypeEntity>;
  getCurrency: Array<CurrencyEntity>;
  getCurrencyExchange: Array<AccCurrencyExchangeEntity>;
  createCurrencyExchange: Scalars['JSON'];
  getCurrencyExchangeData: Scalars['JSON'];
  getDebtorAccount: Array<DebtorAccountEntity>;
  DebtorAccountTotalDue: Scalars['JSON'];
  getDebtorContact: Array<DebtorContactEntity>;
  getDebtorType: Array<DebtorTypeEntity>;
  getDeliveryLocation: Array<DeliveryLocationEntity>;
  getDocumentDateValidation: Array<DocumentDateValidationEntity>;
  getDocumentNumberDetail: Array<DocumentNumberDetailEntity>;
  getDocumentNumberHeader: Array<DocumentNumberHeaderEntity>;
  getDocumentNumberHeaderWithPerm: Array<DocumentNumberHeaderEntity>;
  getDocumentNumberHeaderAndDetail: Array<DocumentNumberDetailEntity>;
  getEntityCOA: Array<EntityCoaEntity>;
  getAssignedMasterCOA: Array<MasterCoaEntity>;
  getEntityCOAWithAccount: Array<EntityCoaEntity>;
  getEntityCOAFiltered: Array<EntityCoaEntity>;
  getExpenseClass: Array<ExpenseClassEntity>;
  getExpenseItem: Array<ExpenseItemEntity>;
  getSelectedItem: Scalars['JSON'];
  getItemByCompany: Array<ExpenseItemEntity>;
  testDocNoOutput: Scalars['JSON'];
  getItemClassCompanyAssignment: Array<ItemClassCompanyAssignmentEntity>;
  getJournalType: Array<JournalTypeEntity>;
  getMasterCOA: Array<MasterCoaEntity>;
  getMasterCOAAccountType: Array<MasterCoaEntity>;
  getMasterCOAListing: Array<MasterCoaEntity>;
  getParentMasterCOA: Array<MasterCoaEntity>;
  accountCodeChecking: Scalars['JSON'];
  getFirstMasterCOA: Scalars['Boolean'];
  getMasterCOAWithAccount: Array<MasterCoaEntity>;
  getMasterCOAFiltered: Array<MasterCoaEntity>;
  getCheckingValidationCOA: Scalars['JSON'];
  getNumberFormat: Array<NumberFormatEntity>;
  getPaymentMethod: Array<PaymentMethodEntity>;
  getRetainEarningAccountDef: Array<RetainEarningAccountDefEntity>;
  getStockItem: Array<StockItemEntity>;
  getStockLedger: Scalars['JSON'];
  stockItemWarehouseSummary: Scalars['JSON'];
  getStockItemWarehouse: Array<StockItemWarehouseEntity>;
  getStockItemWarehouseByCostCentre: Array<StockItemWarehouseEntity>;
  getTaxScheme: Array<TaxSchemeEntity>;
  getTaxEffectiveDate: Array<TaxEffectiveDateEntity>;
  getTaxType: Array<TaxTypeEntity>;
  getTaxSchemeWithAccount: Array<TaxSchemeEntity>;
  getTransactionType: Array<TransactionTypeEntity>;
  getUOM: Array<AccUomEntity>;
  getUOMWithAccount: Array<AccUomEntity>;
  getUOMExchange: Array<AccUomExchangeEntity>;
  getUomExchangeData: Scalars['JSON'];
  getUOMExchangeWithAccount: Scalars['JSON'];
  getUOMExchangeList: Scalars['JSON'];
  getWarehouse: Array<WarehouseEntity>;
  getCompanyWarehouse?: Maybe<Scalars['JSON']>;
  approvableChecking: Scalars['Boolean'];
  getToDoSummary: PendingToDo;
  getWorkDeskHistory: Array<ToDo>;
  getWorkDeskToDo: Array<ToDo>;
};


export type QueryCheckArgs = {
  permIds: Array<Scalars['Float']>;
  userID: Scalars['String'];
};


export type QueryUserEntitiesArgs = {
  userID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
};


export type QueryTestPermissionArgs = {
  conPerm: ContractPermission;
};


export type QueryTestConPermissionArgs = {
  contPerm: ContPermission;
};


export type QueryGetAdjustmentTypeArgs = {
  optField?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryGetDocumentFolderArgs = {
  siteID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryLastApprovalPolicyArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryLastNumberingStructureArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryActiveDeliveryLocationCountArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryScheduledPurchaseInfoCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPrInfoCountArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryRfqCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryPoInfoCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryDoInfoCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryLastDoDateArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGrtnInfoCountArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryLastGrtnDateArgs = {
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedByLedgerArgs = {
  docRefID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};


export type QueryGetUsersByAccountArgs = {
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetUsersByAccountAndSoftwareArgs = {
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
};


export type QueryGetProcurementApprovalPolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
};


export type QueryGetProcurementApprovalPolicyAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
};


export type QueryGetAllApprovalPolicyArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  Category: Scalars['String'];
};


export type QueryGetApprovalPolicyAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
};


export type QueryGetAllApprovalPolicyAssignmentArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryDocumentListingArgs = {
  hasDescription?: Maybe<Scalars['Boolean']>;
  notDescription?: Maybe<Scalars['String']>;
  documentFolderID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryViewLogoArgs = {
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryAttachmentListingArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type QueryGetBulkPurchasePriceArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SupplierID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  BulkPurchasePriceHeaderID?: Maybe<Scalars['String']>;
  EffectiveDate?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierAccountByCompanyArgs = {
  CompanyID: Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralDoArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DOHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['DateTime']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetGeneralDoItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DOItemID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierByDoStatusArgs = {
  ApprovalStatus: ApprovalStatus;
  CompanyID: Scalars['String'];
};


export type QueryGetWarehouseDeliveryLocationArgs = {
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralPOsArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  DOHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetOldGeneralPOsArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetGeneralGrtNsArgs = {
  WarehouseID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
};


export type QueryGetGrtnHeaderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SupplierID?: Maybe<Scalars['String']>;
  GRTNHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetGeneralPrItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetPoHeaderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  POType?: Maybe<PoType>;
};


export type QueryGetSupplierByPoStatusArgs = {
  ApprovalStatus: ApprovalStatus;
  CompanyID: Scalars['String'];
};


export type QueryCheckingCancelClosePoArgs = {
  POHeaderID: Scalars['String'];
};


export type QueryGetPrHeaderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PRHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<AccountType>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetPrNoListArgs = {
  PRHeaderID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetOutstandingGeneralPrItemsArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetOsGeneralPrArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCheckingCancelClosePrArgs = {
  PRHeaderID: Scalars['String'];
};


export type QueryGetRfqHeaderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};


export type QueryGetRfqItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  PRItemID?: Maybe<Scalars['String']>;
};


export type QueryGetRfQbyStatusArgs = {
  StatusArr: Array<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetInvitedSupplierArgs = {
  companyID: Scalars['String'];
  RFQHeaderID: Scalars['String'];
};


export type QueryGetRfqSupplierInvitationArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetRfqSupplierSubmissionArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RFQSupplierSubmissionID?: Maybe<Scalars['String']>;
  RFQSupplierInvitationID?: Maybe<Scalars['String']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
};


export type QueryGetPrItemByPridForRfqArgs = {
  CompanyID: Scalars['String'];
  PRHeaderID: Scalars['String'];
};


export type QueryGetScheduledPurchasesArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
};


export type QueryGetItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  SupplierID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetCheckingValidationItemArgs = {
  ItemExcelInput: Array<ItemExcelInput>;
};


export type QueryGetLatestTaxByTaxSchemeIDsArgs = {
  taxSchemeIDs: Array<Scalars['String']>;
};


export type QueryGetWarehouseByStockItemArgs = {
  StockItemID: Scalars['String'];
};


export type QueryGetItemByCompanyAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetItemCategoryArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetItemCategoryCompanyAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ItemCategoryCompanyAssignmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetPurchaseItemVariancePolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PurchaseItemVariancePolicyID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteWithAccountArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetEntityRoleUserAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetRoleWithAccountArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
};


export type QueryAccuserRoleIDsArgs = {
  companyID: Scalars['String'];
};


export type QueryGetUsersRoleCompanyArgs = {
  companyID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryTestAclPermissionArgs = {
  accountPerm: AccountPermission;
};


export type QueryTestAccPermissionArgs = {
  acctPerm: AcctPermission;
};


export type QueryGetAccountPeriodArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountPeriodID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  FYear?: Maybe<Scalars['Float']>;
};


export type QueryGetAccountYearArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetNextAccPeriodArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetSelectedAccountPeriodArgs = {
  selectedDate: Scalars['DateTime'];
  CompanyID: Scalars['String'];
};


export type QueryAccountPeriodCheckingArgs = {
  FYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryGetOpenAccPeriodDateRangeArgs = {
  CompanyID: Scalars['String'];
};


export type QueryCheckExistingAccountPeriodArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetPreviousPeriodOfYearArgs = {
  FYear: Scalars['Float'];
  CompanyID: Scalars['String'];
};


export type QueryLatestOpenPeriodCheckingDateArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetArAgingArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AgingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
};


export type QueryGetApAgingArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AgingID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ApprovalPolicyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
};


export type QueryGetBankAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankAccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetBankProfileArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BankProfileID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetBankProfileWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetBillItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  BillItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetCoaStructureArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  COAStructureID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  CodeLength?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetCompanyWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetCompanyNameWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetCompanyEntityWithAccountArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  AccountID: Scalars['String'];
};


export type QueryGetCompanyAssignedUsersByRoleArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetUserCompanyAssignedUsersByRoleArgs = {
  ID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetContactArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ContactID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetAllCostCentreArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCostCentreDefinitionAsgmtArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreDefinitionAsgmtID?: Maybe<Scalars['String']>;
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreArgs = {
  IsDefault?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  AccountID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreListingArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetNextLevelCcDefinitionArgs = {
  CostCentreID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetParentCostCentreForTreeArgs = {
  CostCentreArr?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetCostCentreWithAccountArgs = {
  CompanyID?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreEntityWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
};


export type QueryGetCostCentreCoaArgs = {
  MasterCOAID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreDefinitionHeaderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreDefinitionHeaderID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreDefinitionDetailArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  ParentCostCentreDefinitionDetailID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Level?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCentreDefinitionArgs = {
  CostCentreDefinitionHeaderID: Scalars['String'];
};


export type QueryGetCostCentreDefinitionHeaderDetailsArgs = {
  CostCentreDefinitionHeaderID: Scalars['String'];
};


export type QueryGetCostCentreTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CostCentreTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
};


export type QueryGetCreditCardTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditCardID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  CreditorTypeEnum?: Maybe<CreditorType>;
};


export type QueryGetCreditorProfileWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetCreditorAccountCompanyAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryCreditorAccountTotalDueArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetCreditorContactArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorContactID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorDirectorArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorDirectorID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCreditorTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorType?: Maybe<CreditorType>;
};


export type QueryGetCreditorTypeWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetCurrencyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CurrencyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetCurrencyExchangeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  CurrencyExchangeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryCreateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type QueryGetDebtorAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebtorAccountID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryDebtorAccountTotalDueArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetDebtorContactArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebtorContactID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetDebtorTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DebtorTypeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetDeliveryLocationArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentDateValidationArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DocumentDateValidationID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentNumberDetailArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DocumentNumberDetailID?: Maybe<Scalars['String']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentNumberHeaderArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Type?: Maybe<DocumentType>;
};


export type QueryGetDocumentNumberHeaderWithPermArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  DocumentNumberHeaderID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  Type?: Maybe<DocumentType>;
};


export type QueryGetDocumentNumberHeaderAndDetailArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  DocumentType: DocumentType;
};


export type QueryGetEntityCoaArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  EntityCOAID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
};


export type QueryGetAssignedMasterCoaArgs = {
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountType: AccountType;
  CompanyID: Scalars['String'];
};


export type QueryGetEntityCoaWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetEntityCoaFilteredArgs = {
  IsControl?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
};


export type QueryGetExpenseClassArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetExpenseItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ExpenseItemID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
};


export type QueryGetSelectedItemArgs = {
  creditorDebtorID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  refTable: Scalars['String'];
  prefix: Scalars['String'];
};


export type QueryGetItemByCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type QueryGetItemClassCompanyAssignmentArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ItemClassCompanyAssignmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ExpenseClassID?: Maybe<Scalars['String']>;
};


export type QueryGetJournalTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  JournalTypeID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
};


export type QueryGetMasterCoaArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  AccountType?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  IsControl?: Maybe<Scalars['Boolean']>;
};


export type QueryGetMasterCoaAccountTypeArgs = {
  AccountType: AccountType;
  CompanyID: Scalars['String'];
};


export type QueryGetMasterCoaListingArgs = {
  AccountType: Scalars['String'];
};


export type QueryAccountCodeCheckingArgs = {
  MasterCOAID?: Maybe<Scalars['String']>;
};


export type QueryGetMasterCoaWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetMasterCoaFilteredArgs = {
  IsControl?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
};


export type QueryGetCheckingValidationCoaArgs = {
  MasterCOAInput: Array<MasterCoaInput>;
};


export type QueryGetNumberFormatArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  NumberFormatID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentMethodArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  PaymentMethodID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
};


export type QueryGetRetainEarningAccountDefArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  RetainEarningAccountDefIDID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryGetStockItemArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockItemID?: Maybe<Scalars['String']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
};


export type QueryGetStockLedgerArgs = {
  WarehouseID: Scalars['String'];
  StockItemID: Scalars['String'];
};


export type QueryStockItemWarehouseSummaryArgs = {
  WarehouseIDs: Array<Scalars['String']>;
  StockItemID: Scalars['String'];
  CompanyID: Scalars['String'];
};


export type QueryGetStockItemWarehouseArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
};


export type QueryGetStockItemWarehouseByCostCentreArgs = {
  StockItemID?: Maybe<Scalars['String']>;
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};


export type QueryGetTaxSchemeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxTypeID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  AccTaxClass?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetTaxEffectiveDateArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TaxEffectiveDateID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  Claimable?: Maybe<Scalars['Boolean']>;
};


export type QueryGetTaxTypeArgs = {
  RecordStatus?: Maybe<RecordStatus>;
  TaxTypeID?: Maybe<Scalars['String']>;
};


export type QueryGetTaxSchemeWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetTransactionTypeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  RecordStatus?: Maybe<Scalars['String']>;
};


export type QueryGetUomArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  UOMID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
};


export type QueryGetUomWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type QueryGetUomExchangeArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  FromUomID?: Maybe<Scalars['String']>;
  ToUomID?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangeWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetUomExchangeListArgs = {
  UOMID: Scalars['String'];
};


export type QueryGetWarehouseArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
};


export type QueryApprovableCheckingArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  UserID: Scalars['String'];
  CompanyID: Scalars['String'];
  RefID: Scalars['String'];
};


export type QueryGetToDoSummaryArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  RoleID: Scalars['String'];
};


export type QueryGetWorkDeskHistoryArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  tableIdentifier: Scalars['String'];
};


export type QueryGetWorkDeskToDoArgs = {
  DifferentConnection?: Maybe<Scalars['String']>;
  tableIdentifier: Scalars['String'];
};

export type RfqEvalInput = {
  RFQHeaderID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type RfqEvalSelectInput = {
  RFQItemID?: Maybe<Scalars['String']>;
  RFQSubmissionItemID?: Maybe<Scalars['String']>;
};

export type RfqHeaderEntity = AuditEntity & {
  __typename?: 'RFQHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  RFQHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  ExpectedDate?: Maybe<Scalars['String']>;
  CancelCloseDate?: Maybe<Scalars['String']>;
  CancelCloseRemark?: Maybe<Scalars['String']>;
  RFQItem?: Maybe<Array<RfqItemEntity>>;
  RFQSupplierInvitation?: Maybe<Array<RfqSupplierInvitationEntity>>;
  /** CustomFieldResolver */
  ProfileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  PRHeaderInfo?: Maybe<Scalars['JSON']>;
};

export type RfqInput = {
  PRHeaderID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  ExpectedDate?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RecordStatus?: Maybe<RecordStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type RfqItemEntity = AuditEntity & {
  __typename?: 'RFQItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RFQItemID: Scalars['String'];
  RFQHeaderID: Scalars['String'];
  RFQHeader?: Maybe<RfqHeaderEntity>;
  PRItemID: Scalars['String'];
  PRItem?: Maybe<PrItemEntity>;
  RFQSubmissionItem?: Maybe<Array<RfqSubmissionItemEntity>>;
  UOM?: Maybe<AccUomEntity>;
};

export type RfqItemInput = {
  PRItemID?: Maybe<Scalars['String']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
};

export type RfqRejectInput = {
  RFQHeaderID?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
};

export type RfqSubmissionItemEntity = AuditEntity & {
  __typename?: 'RFQSubmissionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RFQSubmissionItemID: Scalars['String'];
  RFQSupplierSubmissionID: Scalars['String'];
  RFQSupplierSubmission?: Maybe<RfqSupplierSubmissionEntity>;
  RFQItemID: Scalars['String'];
  RFQItem?: Maybe<RfqItemEntity>;
  UnitPrice: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  IsSelected?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  Cheapest?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  TotalPrice?: Maybe<Scalars['JSON']>;
};


export type RfqSubmissionItemEntityCheapestArgs = {
  RFQHeaderID?: Maybe<Scalars['String']>;
};


export type RfqSubmissionItemEntityTotalPriceArgs = {
  RFQHeaderID?: Maybe<Scalars['String']>;
};

export type RfqSubmissionItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  RFQItemID: Scalars['String'];
  UnitPrice: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  isSelected?: Maybe<Scalars['Boolean']>;
};

export type RfqSupplierInvitationEntity = AuditEntity & {
  __typename?: 'RFQSupplierInvitationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RFQSupplierInvitationID: Scalars['String'];
  SupplierID: Scalars['String'];
  RFQHeaderID: Scalars['String'];
  RFQHeader?: Maybe<RfqHeaderEntity>;
  RFQSupplierSubmission?: Maybe<Array<RfqSupplierSubmissionEntity>>;
  Supplier?: Maybe<CreditorAccountEntity>;
  LatestSubmittedQuotation?: Maybe<RfqSupplierSubmissionEntity>;
};

export type RfqSupplierSubmissionEntity = AuditEntity & {
  __typename?: 'RFQSupplierSubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RFQSupplierSubmissionID: Scalars['String'];
  RFQSupplierInvitationID: Scalars['String'];
  RFQSupplierInvitation?: Maybe<RfqSupplierInvitationEntity>;
  RefNo: Scalars['String'];
  CreditTerm: Scalars['Float'];
  SubmittedDate: Scalars['String'];
  EffectiveDate: Scalars['String'];
  ExpiryDate: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  RFQSubmissionItem?: Maybe<Array<RfqSubmissionItemEntity>>;
  Supplier?: Maybe<CreditorAccountEntity>;
};

export type RfqSupplierSubmissionInput = {
  RFQSupplierSubmissionID?: Maybe<Scalars['String']>;
  RFQSupplierInvitationID: Scalars['String'];
  RefNo: Scalars['String'];
  CreditTerm: Scalars['Float'];
  SubmittedDate: Scalars['String'];
  EffectiveDate: Scalars['String'];
  ExpiryDate: Scalars['String'];
  Remarks?: Maybe<Scalars['String']>;
  RecordStatus: RecordStatus;
  RFQSubmissionItem: Array<RfqSubmissionItemInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ReceiptEntity = AuditEntity & {
  __typename?: 'ReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  DocAmt: Scalars['Float'];
  CurrencyID?: Maybe<Scalars['String']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  LocalDocAmt?: Maybe<Scalars['Float']>;
  LocalTaxAmt?: Maybe<Scalars['Float']>;
  BankAccountID: Scalars['String'];
  ReceiptID: Scalars['String'];
  BankAccount?: Maybe<AccBankAccountEntity>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  ReceivedFrom?: Maybe<Scalars['String']>;
  PaymentMethodID: Scalars['String'];
  PaymentMethod?: Maybe<PaymentMethodEntity>;
  CreditCardID?: Maybe<Scalars['String']>;
  CreditCardType?: Maybe<CreditCardTypeEntity>;
  ChequeNo?: Maybe<Scalars['String']>;
  ChequeDate?: Maybe<Scalars['String']>;
  ChequeExpiryDate?: Maybe<Scalars['String']>;
  LedgerID?: Maybe<Scalars['String']>;
  ReceiptItem?: Maybe<Array<ReceiptItemEntity>>;
};

export type ReceiptItemEntity = AuditEntity & {
  __typename?: 'ReceiptItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
  TaxSchemeID: Scalars['String'];
  TaxRate: Scalars['Float'];
  TaxAmt?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  DocAmt?: Maybe<Scalars['Float']>;
  MasterCOAID: Scalars['String'];
  ReceiptItemID: Scalars['String'];
  ReceiptID: Scalars['String'];
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID: Scalars['String'];
  Receipt?: Maybe<ReceiptEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  CostCentre?: Maybe<CostCentreEntity>;
  Company?: Maybe<CompanyEntity>;
};

export enum RecordStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export type RecurringJournalEntity = AuditEntity & {
  __typename?: 'RecurringJournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  RecurringJournalID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalTypeID: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  StartPeriod: Scalars['Float'];
  Interval: Scalars['Float'];
  NoOfRecurrance: Scalars['Float'];
  StartYear?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  JournalID?: Maybe<Scalars['String']>;
  AccountPeriodID?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  TransactionDate?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  RecurringJournalItem?: Maybe<Array<RecurringJournalItemEntity>>;
  AccountPeriod?: Maybe<AccountPeriodEntity>;
  Company?: Maybe<CompanyEntity>;
  JournalType?: Maybe<JournalTypeEntity>;
};

export type RecurringJournalGenerationEntity = AuditEntity & {
  __typename?: 'RecurringJournalGenerationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  RecurringJournalGenerationID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalDate: Scalars['String'];
  JournalTypeID: Scalars['String'];
  JournalNo?: Maybe<Scalars['String']>;
  FYear: Scalars['Float'];
  FPeriod: Scalars['Float'];
  IsManual: Scalars['Boolean'];
  RefNo: Scalars['String'];
  Description: Scalars['String'];
  RefRecordID: Scalars['String'];
  RefTable: Scalars['String'];
  IsGenerated: Scalars['Boolean'];
  RecurringJournalGenerationItem?: Maybe<Array<RecurringJournalGenerationItemEntity>>;
  JournalType?: Maybe<JournalTypeEntity>;
};

export type RecurringJournalGenerationItemEntity = AuditEntity & {
  __typename?: 'RecurringJournalGenerationItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RecurringJournalGenerationItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  JournalID: Scalars['String'];
  RecurringJournalGeneration?: Maybe<RecurringJournalGenerationEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  DocAmt?: Maybe<Scalars['Float']>;
  TaxInvoiceNo?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
};

export type RecurringJournalItemEntity = AuditEntity & {
  __typename?: 'RecurringJournalItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RecurringJournalItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  RecurringJournalID: Scalars['String'];
  RecurringJournal?: Maybe<RecurringJournalEntity>;
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  DocAmt?: Maybe<Scalars['Float']>;
  Remark?: Maybe<Scalars['String']>;
  Sequence?: Maybe<Scalars['Float']>;
};

export enum RefTable {
  GeneralLedger = 'GENERAL_LEDGER',
  Cashbook = 'CASHBOOK',
  FixedAsset = 'FIXED_ASSET'
}

export type RegisterVendorEntity = {
  __typename?: 'RegisterVendorEntity';
  reg_vendor_id: Scalars['String'];
  reg_no: Scalars['String'];
  supplier_name: Scalars['String'];
  vendor_type: CreditorType;
  company_id: Scalars['String'];
  industry_id: Scalars['String'];
  business_type_id: Scalars['String'];
  main_trade_id: Scalars['String'];
  legal_representative?: Maybe<Scalars['String']>;
  registration_year: Scalars['Float'];
  registration_place?: Maybe<Scalars['String']>;
  year_experience?: Maybe<Scalars['Float']>;
  address?: Maybe<Scalars['JSON']>;
  is_blacklist?: Maybe<Scalars['Boolean']>;
  account_id: Scalars['String'];
  record_status: RecordStatus;
};

export type ReportSettingsEntity = AuditEntity & {
  __typename?: 'ReportSettingsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ReportSettingsID: Scalars['String'];
  Name?: Maybe<Scalars['String']>;
  DataSourceCodes?: Maybe<Array<Scalars['JSON']>>;
  AccountCodes?: Maybe<Array<Scalars['JSON']>>;
  CostCentreCodes?: Maybe<Array<Scalars['JSON']>>;
  CompanyCodes?: Maybe<Array<Scalars['JSON']>>;
  Description?: Maybe<Scalars['String']>;
  FileName?: Maybe<Scalars['String']>;
  FileURL?: Maybe<Scalars['String']>;
  CellValues?: Maybe<Array<Scalars['JSON']>>;
  BucketFileName?: Maybe<Scalars['String']>;
  MediaType?: Maybe<Scalars['String']>;
  Opacity?: Maybe<Scalars['String']>;
  IsPrivate: Scalars['Boolean'];
};

export type RetainEarningAccountDefDeleteInput = {
  RetainEarningAccountDefID: Scalars['String'];
};

export type RetainEarningAccountDefEntity = AuditEntity & {
  __typename?: 'RetainEarningAccountDefEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RetainEarningAccountDefID: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  MasterCOAID: Scalars['String'];
  MasterCOA?: Maybe<MasterCoaEntity>;
};

export type RetainEarningAccountDefInput = {
  RetainEarningAccountDefID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  Code: Scalars['String'];
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type RoleUser = {
  __typename?: 'RoleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RoutineJournalEntity = AuditEntity & {
  __typename?: 'RoutineJournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ApprovalStatus: ApprovalStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  RoutineJournalID: Scalars['String'];
  CompanyID: Scalars['String'];
  JournalTypeID: Scalars['String'];
  RefNo?: Maybe<Scalars['String']>;
  Description: Scalars['String'];
  Remark: Scalars['String'];
  JournalID?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  RoutineJournalItem?: Maybe<Array<RoutineJournalItemEntity>>;
  Company?: Maybe<CompanyEntity>;
  JournalType?: Maybe<JournalTypeEntity>;
};

export type RoutineJournalItemEntity = AuditEntity & {
  __typename?: 'RoutineJournalItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RoutineJournalItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  RoutineJournalID: Scalars['String'];
  MasterCOAID: Scalars['String'];
  DocAmt: Scalars['Float'];
  RoutineJournal?: Maybe<RoutineJournalEntity>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  Company?: Maybe<CompanyEntity>;
};

export type RunningNumberEntity = AuditEntity & {
  __typename?: 'RunningNumberEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  RunningNumberID: Scalars['String'];
  NumberFormatID: Scalars['String'];
  NextNumber: Scalars['Float'];
};

export type SampleField = {
  __typename?: 'SampleField';
  testID: Scalars['String'];
  arrayID: Array<Scalars['String']>;
};

export type SampleFieldInput = {
  testID: Scalars['String'];
  arrayID: Array<Scalars['String']>;
};

export enum ScheduledFrequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Quarterly = 'QUARTERLY',
  HalfYearly = 'HALF_YEARLY',
  Yearly = 'YEARLY',
  SpecificDate = 'SPECIFIC_DATE'
}

export type ScheduledPurchasesEntity = AuditEntity & {
  __typename?: 'ScheduledPurchasesEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ScheduledPurchasesID: Scalars['String'];
  CompanyID: Scalars['String'];
  Title: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  DeliveryLocationID: Scalars['String'];
  ScheduledFrequency: ScheduledFrequency;
  ScheduledDate?: Maybe<Scalars['String']>;
  ScheduledPurchasesItem?: Maybe<Array<ScheduledPurchasesItemEntity>>;
  /** CustomFieldResolver */
  Company: CompanyEntity;
  /** CustomFieldResolver */
  DeliveryLocation?: Maybe<DeliveryLocationEntity>;
};

export type ScheduledPurchasesInput = {
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  Title?: Maybe<Scalars['String']>;
  ScheduledFrequency?: Maybe<ScheduledFrequency>;
  ScheduledDate?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ScheduledPurchasesItems: Array<ScheduledPurchasesItemInput>;
};

export type ScheduledPurchasesItemEntity = AuditEntity & {
  __typename?: 'ScheduledPurchasesItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  ScheduledPurchasesItemID: Scalars['String'];
  ScheduledPurchasesID: Scalars['String'];
  ScheduledPurchases?: Maybe<ScheduledPurchasesEntity>;
  ItemID: Scalars['String'];
  Item?: Maybe<ItemEntity>;
  SupplierID: Scalars['String'];
  UOMID: Scalars['String'];
  BaseUOMID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Quantity: Scalars['Float'];
  UnitPrice: Scalars['Float'];
  TaxSchemeID: Scalars['String'];
  TaxAmt: Scalars['Float'];
  TotalAmt: Scalars['Float'];
  Remarks?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
  Supplier?: Maybe<CreditorAccountEntity>;
  UOM?: Maybe<AccUomEntity>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  /** CustomFieldResolver */
  UOMExchangeList?: Maybe<Scalars['JSON']>;
};

export type ScheduledPurchasesItemInput = {
  createdBy?: Maybe<Scalars['String']>;
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  ItemID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  TotalAmt?: Maybe<Scalars['Float']>;
  TaxAmt?: Maybe<Scalars['Float']>;
  Remarks?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  StockItemID?: Maybe<Scalars['String']>;
};

export enum SoftwareCode {
  Contract = 'CONTRACT',
  Site = 'SITE',
  Client = 'CLIENT',
  Subcontractor = 'SUBCONTRACTOR',
  Supplier = 'SUPPLIER',
  Hr = 'HR',
  Property = 'PROPERTY',
  Rent = 'RENT',
  Hotel = 'HOTEL',
  Account = 'ACCOUNT',
  Ifca = 'IFCA',
  Subscription = 'SUBSCRIPTION',
  User = 'USER',
  Tenant = 'TENANT',
  LeaseTodo = 'LEASE_TODO',
  LeaseOperation = 'LEASE_OPERATION',
  LeaseSales = 'LEASE_SALES',
  Menu = 'MENU',
  PosCustomer = 'POS_CUSTOMER',
  Probation = 'PROBATION',
  ReportDesigner = 'REPORT_DESIGNER'
}

export type StandardProcurementCoreEntity = AuditEntity & {
  __typename?: 'StandardProcurementCoreEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
};

export type StockAdjustmentEntity = AuditEntity & {
  __typename?: 'StockAdjustmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockAdjustmentID: Scalars['String'];
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  StockAdjustmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
};

export type StockAdjustmentItemEntity = AuditEntity & {
  __typename?: 'StockAdjustmentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockAdjustmentItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  CycleCountItemID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  WarehouseID?: Maybe<Scalars['String']>;
  StockAdjustmentID: Scalars['String'];
  StockAdjustment?: Maybe<StockAdjustmentEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID: Scalars['String'];
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
};

export type StockAdjustmentReasonEntity = AuditEntity & {
  __typename?: 'StockAdjustmentReasonEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockAdjustmentReasonID: Scalars['String'];
  Reason: Scalars['String'];
  IsSystem: Scalars['Boolean'];
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  StockAdjusmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
  StockTransferItem?: Maybe<Array<StockTransferItemEntity>>;
};

export type StockCategoryEntity = AuditEntity & {
  __typename?: 'StockCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockCategoryID: Scalars['String'];
  Name: Scalars['String'];
  ParentStockCategoryID?: Maybe<Scalars['String']>;
  Level: Scalars['Float'];
  IsLastNode: Scalars['Boolean'];
  StockAccountCodeID?: Maybe<Scalars['String']>;
  StockAccountCode?: Maybe<MasterCoaEntity>;
  StockItem?: Maybe<Array<StockItemEntity>>;
};

export type StockCostAdjustmentEntity = AuditEntity & {
  __typename?: 'StockCostAdjustmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockCostAdjustmentID: Scalars['String'];
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  StockCostAdjustmentItem?: Maybe<Array<StockCostAdjustmentItemEntity>>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
};

export type StockCostAdjustmentItemEntity = AuditEntity & {
  __typename?: 'StockCostAdjustmentItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockCostAdjustmentItemID: Scalars['String'];
  Cost?: Maybe<Scalars['Float']>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  WarehouseID?: Maybe<Scalars['String']>;
  StockCostAdjustmentID: Scalars['String'];
  Remark?: Maybe<Scalars['String']>;
  StockCostAdjustment?: Maybe<StockCostAdjustmentEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID: Scalars['String'];
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
};

export enum StockCostingType {
  Weighted = 'WEIGHTED',
  Fifo = 'FIFO',
  Lifo = 'LIFO'
}

export type StockIssueEntity = AuditEntity & {
  __typename?: 'StockIssueEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockIssueID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  StockRequisitionID?: Maybe<Scalars['String']>;
  StockRequisition?: Maybe<StockRequisitionEntity>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
};

export type StockIssueItemEntity = AuditEntity & {
  __typename?: 'StockIssueItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockIssueItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  StockIssueID: Scalars['String'];
  StockIssue?: Maybe<StockIssueEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  WarehouseID?: Maybe<Scalars['String']>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
  StockRequisitionItemID?: Maybe<Scalars['String']>;
  StockRequisitionItem?: Maybe<StockRequisitionItemEntity>;
};

export type StockItemDeleteInput = {
  StockItemID: Scalars['String'];
};

export type StockItemEntity = AuditEntity & {
  __typename?: 'StockItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockItemID: Scalars['String'];
  Name: Scalars['String'];
  ABCClass?: Maybe<Scalars['String']>;
  ShelfLife?: Maybe<Scalars['Float']>;
  StandardCost?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxScheme?: Maybe<TaxSchemeEntity>;
  StockCategoryID: Scalars['String'];
  StockCategory?: Maybe<StockCategoryEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemWarehouse?: Maybe<Array<StockItemWarehouseEntity>>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
  StockTransferItem?: Maybe<Array<StockTransferItemEntity>>;
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  StockAdjusmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
  StockReceiptItem?: Maybe<Array<StockReceiptItemEntity>>;
  StockWeightedAvg?: Maybe<Array<StockWeightedAvgEntity>>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
};

export type StockItemInput = {
  StockItemID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ABCClass?: Maybe<Scalars['String']>;
  ShelfLife?: Maybe<Scalars['Float']>;
  StandardCost?: Maybe<Scalars['Float']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  TaxSchemeID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
  RecordStatus?: Maybe<RecordStatus>;
};

export type StockItemWarehouseDeleteInput = {
  StockItemWarehouseID: Scalars['String'];
};

export type StockItemWarehouseEntity = AuditEntity & {
  __typename?: 'StockItemWarehouseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockItemWarehouseID: Scalars['String'];
  MaximumQty: Scalars['Float'];
  MinimumQty: Scalars['Float'];
  ReorderLevel: Scalars['Float'];
  ReorderQty: Scalars['Float'];
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
};

export type StockItemWarehouseInput = {
  StockItemWarehouseID?: Maybe<Scalars['String']>;
  MaximumQty?: Maybe<Scalars['Float']>;
  MinimumQty?: Maybe<Scalars['Float']>;
  ReorderLevel?: Maybe<Scalars['Float']>;
  ReorderQty?: Maybe<Scalars['Float']>;
  StockItemID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
};

export type StockReceiptEntity = AuditEntity & {
  __typename?: 'StockReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockReceiptID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  StockReceiptItem?: Maybe<Array<StockReceiptItemEntity>>;
};

export type StockReceiptItemEntity = AuditEntity & {
  __typename?: 'StockReceiptItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockReceiptItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  BalanceQty?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  WarehouseID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  StockReceiptID: Scalars['String'];
  StockReceipt?: Maybe<StockReceiptEntity>;
  UOMID?: Maybe<Scalars['String']>;
  UOM?: Maybe<AccUomEntity>;
  StockItemID?: Maybe<Scalars['String']>;
  StockItem?: Maybe<StockItemEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
};

export type StockRequisitionEntity = AuditEntity & {
  __typename?: 'StockRequisitionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  StockRequisitionID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  RequestDate: Scalars['String'];
  ExpectedDate?: Maybe<Scalars['String']>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
  StockIssue?: Maybe<Array<StockIssueEntity>>;
};

export type StockRequisitionItemEntity = AuditEntity & {
  __typename?: 'StockRequisitionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockRequisitionItemID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  StockRequisitionID: Scalars['String'];
  StockRequisition?: Maybe<StockRequisitionEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  WarehouseID?: Maybe<Scalars['String']>;
  Warehouse?: Maybe<WarehouseEntity>;
  TransactionTypeID?: Maybe<Scalars['String']>;
  TransactionType?: Maybe<TransactionTypeEntity>;
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
  Remark?: Maybe<Scalars['String']>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
};

export type StockTransferEntity = AuditEntity & {
  __typename?: 'StockTransferEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  DocNo?: Maybe<Scalars['String']>;
  DocNumGenerated?: Maybe<Scalars['Boolean']>;
  RefNo?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  RejectionRemark?: Maybe<Scalars['String']>;
  RejectionDate?: Maybe<Scalars['String']>;
  IsGLPosted: Scalars['Boolean'];
  ApprovalStatus: ApprovalStatus;
  RecordStatus: RecordStatus;
  submittedTs?: Maybe<Scalars['DateTime']>;
  submittedBy?: Maybe<Scalars['String']>;
  approvedTs?: Maybe<Scalars['DateTime']>;
  approvedBy?: Maybe<Scalars['String']>;
  DocDate: Scalars['String'];
  DueDate?: Maybe<Scalars['String']>;
  TransactionDate?: Maybe<Scalars['String']>;
  StockTransferID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  FromWarehouseID: Scalars['String'];
  FromWarehouse?: Maybe<WarehouseEntity>;
  ToWarehouseID: Scalars['String'];
  ToWarehouse?: Maybe<WarehouseEntity>;
  CostCentreID?: Maybe<Scalars['String']>;
  CostCentre?: Maybe<CostCentreEntity>;
  StockTransferItem?: Maybe<Array<StockTransferItemEntity>>;
};

export type StockTransferItemEntity = AuditEntity & {
  __typename?: 'StockTransferItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  DefaultUOMID?: Maybe<Scalars['String']>;
  DefaultUOMQty?: Maybe<Scalars['Float']>;
  DefaultUOMUnitCost?: Maybe<Scalars['Float']>;
  DefaultUOMTotalCost?: Maybe<Scalars['Float']>;
  StockTransferItemID: Scalars['String'];
  Quantity?: Maybe<Scalars['Float']>;
  UnitCost?: Maybe<Scalars['Float']>;
  TotalCost?: Maybe<Scalars['Float']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  StockTransferID: Scalars['String'];
  StockTransfer?: Maybe<StockTransferEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  ReasonID?: Maybe<Scalars['String']>;
  Reason?: Maybe<StockAdjustmentReasonEntity>;
  FromWarehouseID?: Maybe<Scalars['String']>;
  ToWarehouseID?: Maybe<Scalars['String']>;
  BaseUOMID?: Maybe<Scalars['String']>;
};

export type StockWeightedAvgEntity = AuditEntity & {
  __typename?: 'StockWeightedAvgEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  StockWeightedAvgID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  WarehouseID: Scalars['String'];
  Warehouse?: Maybe<WarehouseEntity>;
  StockItemID: Scalars['String'];
  StockItem?: Maybe<StockItemEntity>;
  UOMID: Scalars['String'];
  UOM?: Maybe<AccUomEntity>;
  ReserveQty: Scalars['Float'];
  BalanceQty: Scalars['Float'];
  UnitCost: Scalars['Float'];
  TotalValue: Scalars['Float'];
  SubmitQty: Scalars['Float'];
};

export type TaxDetails = {
  __typename?: 'TaxDetails';
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type TaxDetailsInput = {
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type TaxEffective = {
  __typename?: 'TaxEffective';
  Date?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
};

export type TaxEffectiveDateEntity = AuditEntity & {
  __typename?: 'TaxEffectiveDateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  TaxEffectiveDateID: Scalars['String'];
  TaxSchemeID: Scalars['String'];
  Date: Scalars['String'];
  Rate: Scalars['Float'];
  TaxScheme?: Maybe<TaxSchemeEntity>;
};

export type TaxEffectiveDateInput = {
  TaxSchemeID: Scalars['String'];
  Date: Scalars['DateTime'];
  Description: Scalars['String'];
};

export type TaxEffectiveDateRateInput = {
  EffectiveDate?: Maybe<Scalars['DateTime']>;
  TaxRate?: Maybe<Scalars['Float']>;
};

export type TaxEffectiveInput = {
  Date?: Maybe<Scalars['String']>;
  Rate?: Maybe<Scalars['Float']>;
};

export type TaxSchemeEffectiveInput = {
  TaxSchemeID: Scalars['String'];
  TaxTypeID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MasterCOAID: Scalars['String'];
  AccTaxCategory?: Maybe<AccTaxCategory>;
  AccTaxClass?: Maybe<AccTaxClass>;
  RecordStatus?: Maybe<RecordStatus>;
  EffectiveDateRate: Array<TaxEffectiveDateRateInput>;
};

export type TaxSchemeEntity = AuditEntity & {
  __typename?: 'TaxSchemeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  TaxSchemeID: Scalars['String'];
  TaxTypeID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  AccTaxCategory: AccTaxCategory;
  AccTaxClass: AccTaxClass;
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  TaxType?: Maybe<TaxTypeEntity>;
  TaxEffective?: Maybe<Array<TaxEffectiveDateEntity>>;
  /** CustomFieldResolver */
  LatestTax?: Maybe<AccTax>;
  PaymentItem?: Maybe<Array<PaymentItemEntity>>;
  LedgerItem?: Maybe<Array<LedgerItemEntity>>;
  ReceiptItem?: Maybe<Array<ReceiptItemEntity>>;
  FavoritePaymentItem?: Maybe<Array<FavoritePaymentItemEntity>>;
  FavoriteReceiptItem?: Maybe<Array<FavoriteReceiptItemEntity>>;
  FavoriteInvoiceItem?: Maybe<Array<AccArFavoriteInvoiceItemEntity>>;
  CreditorAccount?: Maybe<Array<CreditorAccountEntity>>;
  ARAdvance?: Maybe<Array<AccArAdvanceEntity>>;
  APInvoiceItem?: Maybe<Array<AccApInvoiceItemEntity>>;
  ARInvoiceItem?: Maybe<Array<AccArInvoiceItemEntity>>;
  APCreditNoteItem?: Maybe<Array<AccApCreditNoteItemEntity>>;
  APCreditorCreditNoteItem?: Maybe<Array<AccApCreditorCreditNoteItemEntity>>;
  APDebitNoteItem?: Maybe<Array<AccApDebitNoteItemEntity>>;
  ARCreditNoteItem?: Maybe<Array<AccArCreditNoteItemEntity>>;
  ARDebitNoteItem?: Maybe<Array<AccArDebitNoteItemEntity>>;
  StockItem?: Maybe<Array<StockItemEntity>>;
  ExpenseItem?: Maybe<Array<ExpenseItemEntity>>;
  BillItem?: Maybe<Array<BillItemEntity>>;
  DebtorAccount?: Maybe<Array<DebtorAccountEntity>>;
};

export type TaxSchemeInput = {
  TaxSchemeID?: Maybe<Scalars['String']>;
  TaxTypeID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  MasterCOAID: Scalars['String'];
  AccTaxCategory?: Maybe<AccTaxCategory>;
  AccTaxClass?: Maybe<AccTaxClass>;
  RecordStatus?: Maybe<RecordStatus>;
};

export type TaxTypeEntity = AuditEntity & {
  __typename?: 'TaxTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  TaxTypeID: Scalars['String'];
  Code: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  RecordStatus: RecordStatus;
  TaxScheme?: Maybe<Array<TaxSchemeEntity>>;
};

export type TaxTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  Code?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
};

export type TestPermissionEntity = AuditEntity & {
  __typename?: 'TestPermissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

/** JsonType */
export type ToDo = {
  __typename?: 'ToDo';
  createdBy?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
  RefID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CompanyName?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['DateTime']>;
  RefTable?: Maybe<Scalars['String']>;
  DocNo?: Maybe<Scalars['String']>;
  RefNo?: Maybe<Scalars['String']>;
  DocAmt?: Maybe<Scalars['Float']>;
  Description?: Maybe<Scalars['String']>;
  BankAccountID?: Maybe<Scalars['String']>;
  BankCode?: Maybe<Scalars['String']>;
  BankAccountNo?: Maybe<Scalars['String']>;
  FromBankAccountID?: Maybe<Scalars['String']>;
  FromBankCode?: Maybe<Scalars['String']>;
  FromBankAccountNo?: Maybe<Scalars['String']>;
  ToBankAccountID?: Maybe<Scalars['String']>;
  ToBankCode?: Maybe<Scalars['String']>;
  ToBankAccountNo?: Maybe<Scalars['String']>;
  WorkFlow?: Maybe<Array<AccWorkFlowEntity>>;
  JournalType?: Maybe<Scalars['String']>;
};

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export enum TransactionType {
  CcDebit = 'CC_DEBIT',
  CcCredit = 'CC_CREDIT',
  Receipt = 'RECEIPT',
  Issue = 'ISSUE',
  Grn = 'GRN',
  Grtn = 'GRTN'
}

export type TransactionTypeDeleteInput = {
  TransactionTypeID: Scalars['String'];
};

export type TransactionTypeEntity = AuditEntity & {
  __typename?: 'TransactionTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  TransactionTypeID: Scalars['String'];
  Name: Scalars['String'];
  Type: TransactionType;
  IsSystem: Scalars['Boolean'];
  MasterCOAID?: Maybe<Scalars['String']>;
  MasterCOA?: Maybe<MasterCoaEntity>;
  StockReceiptItem?: Maybe<Array<StockReceiptItemEntity>>;
  StockAdjustmentItem?: Maybe<Array<StockAdjustmentItemEntity>>;
  StockIssueItem?: Maybe<Array<StockIssueItemEntity>>;
  CycleCountItem?: Maybe<Array<CycleCountItemEntity>>;
  StockCostAdjustmentItem?: Maybe<Array<StockCostAdjustmentItemEntity>>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
};

export type TransactionTypeInput = {
  TransactionTypeID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['Boolean']>;
  RecordStatus?: Maybe<Scalars['String']>;
};

export type UomDeleteInput = {
  UOMID: Scalars['String'];
};

export type UomInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  UOMID?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UomExchangeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  FromUomID?: Maybe<Scalars['String']>;
  ToUomID?: Maybe<Scalars['String']>;
  FromAmt?: Maybe<Scalars['Float']>;
  ToAmt?: Maybe<Scalars['Float']>;
  ExchangeRate?: Maybe<Scalars['Float']>;
  UomLog?: Maybe<Array<ExchangeLogInput>>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};

export type UpdateDoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  ContactPerson?: Maybe<ContactPersonInput>;
  SupplierID?: Maybe<Scalars['String']>;
  DocDate?: Maybe<Scalars['String']>;
  DoNo: Scalars['String'];
  CompanyID: Scalars['String'];
  File?: Maybe<Array<Scalars['Upload']>>;
};

export type UpdateUserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
  confirmedPassword: Scalars['String'];
};


export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
};

export type UserListInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  password?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
};

export type UserRoleEntity = AuditEntity & {
  __typename?: 'UserRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  UserRoleID: Scalars['String'];
  UserID: Scalars['String'];
  RoleID?: Maybe<Scalars['String']>;
};

export enum UserSelection {
  All = 'ALL',
  Superuser = 'SUPERUSER',
  Nonsuper = 'NONSUPER'
}

export type WarehouseDeleteInput = {
  WarehouseID: Scalars['String'];
};

export type WarehouseEntity = AuditEntity & {
  __typename?: 'WarehouseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  AccountID: Scalars['String'];
  RecordStatus: RecordStatus;
  WarehouseID: Scalars['String'];
  CompanyID: Scalars['String'];
  Company?: Maybe<CompanyEntity>;
  CostCentreID: Scalars['String'];
  CostCentre?: Maybe<CostCentreEntity>;
  Code?: Maybe<Scalars['String']>;
  Name: Scalars['String'];
  Address?: Maybe<Scalars['JSON']>;
  ContactNo: Scalars['String'];
  Designation?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  StockItemWarehouse?: Maybe<Array<StockItemWarehouseEntity>>;
  StockReceipt?: Maybe<Array<StockReceiptEntity>>;
  StockIssue?: Maybe<Array<StockIssueEntity>>;
  FromStockTransfer?: Maybe<Array<StockTransferEntity>>;
  ToStockTransfer?: Maybe<Array<StockTransferEntity>>;
  CycleCount?: Maybe<Array<CycleCountEntity>>;
  StockAdjustment?: Maybe<Array<StockAdjustmentEntity>>;
  StockCostAdjustment?: Maybe<Array<StockCostAdjustmentEntity>>;
  StockWeightedAvg?: Maybe<Array<StockWeightedAvgEntity>>;
  StockRequisition?: Maybe<Array<StockRequisitionEntity>>;
  StockRequisitionItem?: Maybe<Array<StockRequisitionItemEntity>>;
};

export type WarehouseInput = {
  WarehouseID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  Address?: Maybe<Scalars['JSON']>;
  ContactNo?: Maybe<Scalars['String']>;
  Designation?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  Attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type Weekend = {
  __typename?: 'Weekend';
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type WeekendInput = {
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type WorkDeskInput = {
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  RejectionRemark?: Maybe<Scalars['String']>;
};

export type WorkFlowInput = {
  AccountID?: Maybe<Scalars['String']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  SubmitterID?: Maybe<Scalars['String']>;
  SubmitDate?: Maybe<Scalars['String']>;
  RefID?: Maybe<Scalars['String']>;
  RefTable?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  WorkFlowStatus?: Maybe<ApprovalStatus>;
  CreatedDT?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
};

export type WorkFlowStepInput = {
  WorkFlowStepID?: Maybe<Scalars['String']>;
  ApproverID?: Maybe<Scalars['String']>;
  RoleID?: Maybe<Scalars['String']>;
  StepNo?: Maybe<Scalars['Float']>;
  Amount?: Maybe<Scalars['Float']>;
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  SubscriptionAccountID?: Maybe<Scalars['String']>;
  UserList?: Maybe<Array<UserListInput>>;
  Predicate?: Maybe<Scalars['String']>;
  RoleName?: Maybe<Scalars['String']>;
};

export enum YearPeriodType {
  Relative = 'RELATIVE',
  Fixed = 'FIXED'
}

export type GeneralPrItemInfo = {
  __typename?: 'generalPRItemInfo';
  generalPRItemID?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  generalPRID?: Maybe<Scalars['String']>;
  purchaseItemID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  uomCode?: Maybe<Scalars['String']>;
  generalRFQItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  purchaseItemName?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['JSON']>;
  docDate?: Maybe<Scalars['DateTime']>;
  expectedDate?: Maybe<Scalars['DateTime']>;
};

export type PasswordInfo = {
  ID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  confirmedPassword: Scalars['String'];
};

export type PoUomExchange = {
  __typename?: 'poUOMExchange';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type PurchaserUserRoleId = {
  __typename?: 'purchaserUserRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type LoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'avatar' | 'name' | 'email' | 'contactNo' | 'department' | 'superUser' | 'userName' | 'jobTitle'>
  ) }
);

export type ProcurementLoggedInUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProcurementLoggedInUserProfileQuery = (
  { __typename?: 'Query' }
  & { procurementLoggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'avatar' | 'name' | 'email' | 'contactNo' | 'department' | 'superUser' | 'userName' | 'jobTitle' | 'lastestAccessedEntity'>
  ) }
);

export type ProcurementLoginMutationVariables = Exact<{
  loginId: Scalars['String'];
  password: Scalars['String'];
}>;


export type ProcurementLoginMutation = (
  { __typename?: 'Mutation' }
  & { procurementLogin: (
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'UserResponse' }
      & Pick<UserResponse, 'ID' | 'name' | 'userName' | 'email' | 'contactNo' | 'superUser' | 'department' | 'jobTitle' | 'avatar' | 'lastestAccessedEntity'>
      & { address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'address' | 'country' | 'city' | 'district' | 'state' | 'postCode'>
      )> }
    ) }
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type LogoutMutationVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateUser'>
);

export type CreatePasswordMutationVariables = Exact<{
  input: PasswordInfo;
}>;


export type CreatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPassword'>
);

export type GetCompanySettingSummaryQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetCompanySettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'lastApprovalPolicy' | 'lastNumberingStructure' | 'activeDeliveryLocationCount'>
);

export type GetDeliveryLocationQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetDeliveryLocationQuery = (
  { __typename?: 'Query' }
  & { getDeliveryLocation: Array<(
    { __typename?: 'DeliveryLocationEntity' }
    & Pick<DeliveryLocationEntity, 'DeliveryLocationID' | 'Name' | 'Address' | 'ContactNo' | 'MainLocation' | 'CompanyID'>
  )> }
);

export type CreateDeliveryLocationMutationVariables = Exact<{
  input: DeliveryLocationInput;
}>;


export type CreateDeliveryLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDeliveryLoc'>
);

export type UpdateDeliveryLocationMutationVariables = Exact<{
  input: DeliveryLocationInput;
}>;


export type UpdateDeliveryLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDeliveryLoc'>
);

export type DeleteDeliveryLocationMutationVariables = Exact<{
  input: DeliveryLocationDeleteInput;
}>;


export type DeleteDeliveryLocationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDeliveryLocation'>
);

export type GetBankProfileQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
}>;


export type GetBankProfileQuery = (
  { __typename?: 'Query' }
  & { getBankProfile: Array<(
    { __typename?: 'BankProfileEntity' }
    & Pick<BankProfileEntity, 'BankProfileID' | 'Name' | 'Address' | 'ContactNo' | 'Email'>
  )> }
);

export type GetCompanyQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetCompanyQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'ParentCompanyID' | 'Name' | 'Code' | 'CompanyRegNo' | 'CompanyTax' | 'SSTNo' | 'GSTNo' | 'GSTExpiryDate' | 'Address' | 'ContactNo' | 'BaseCurrencyID'>
    & { Location?: Maybe<Array<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'DeliveryLocationID' | 'Name'>
    )>> }
  )> }
);

export type GetParentCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetParentCompanyQuery = (
  { __typename?: 'Query' }
  & { getParentCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'Name'>
  )> }
);

export type GetCompanyNameQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetCompanyNameQuery = (
  { __typename?: 'Query' }
  & { getCompany: Array<(
    { __typename?: 'CompanyEntity' }
    & Pick<CompanyEntity, 'CompanyID' | 'ParentCompanyID' | 'Name' | 'Code' | 'CompanyRegNo' | 'Address' | 'CompanyTax' | 'GSTNo' | 'SSTNo' | 'RecordStatus'>
  )> }
);

export type GetAssignedCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAssignedCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAssignedCompanyByUser'>
);

export type GetActiveCompanyCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActiveCompanyCountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getActiveCompanyCount'>
);

export type GetCompanyNameWithAccountQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCompanyNameWithAccountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCompanyNameWithAccount'>
);

export type GetCostCentreQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  CostCentreID?: Maybe<Scalars['String']>;
  MasterCOAID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  IsDefault?: Maybe<Scalars['Boolean']>;
}>;


export type GetCostCentreQuery = (
  { __typename?: 'Query' }
  & { getCostCentre: Array<(
    { __typename?: 'CostCentreEntity' }
    & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'CompanyID' | 'ParentCostCentreID' | 'Level' | 'Code' | 'MasterCode' | 'IsLastNode' | 'IsDefault'>
  )> }
);

export type GetCostCentreListingQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetCostCentreListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostCentreListing'>
);

export type GetCostCentreDefinitionAsgmtQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetCostCentreDefinitionAsgmtQuery = (
  { __typename?: 'Query' }
  & { getCostCentreDefinitionAsgmt: Array<(
    { __typename?: 'CostCentreDefinitionAsgmtEntity' }
    & Pick<CostCentreDefinitionAsgmtEntity, 'CostCentreDefinitionAsgmtID' | 'CostCentreDefinitionHeaderID' | 'CompanyID'>
    & { CostCentreDefinitionHeader?: Maybe<(
      { __typename?: 'CostCentreDefinitionHeaderEntity' }
      & Pick<CostCentreDefinitionHeaderEntity, 'Title'>
      & { CostCentreDefinitionDetail?: Maybe<Array<(
        { __typename?: 'CostCentreDefinitionDetailEntity' }
        & Pick<CostCentreDefinitionDetailEntity, 'Name' | 'Level' | 'IsLastNode'>
      )>> }
    )> }
  )> }
);

export type GetCostCentreDefinitionDetailQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetCostCentreDefinitionDetailQuery = (
  { __typename?: 'Query' }
  & { getCostCentreDefinitionDetail: Array<(
    { __typename?: 'CostCentreDefinitionDetailEntity' }
    & Pick<CostCentreDefinitionDetailEntity, 'CostCentreDefinitionDetailID' | 'ParentCostCentreDefinitionDetailID' | 'CostCentreDefinitionHeaderID' | 'Name' | 'Level'>
  )> }
);

export type GetNextLevelCcDefinitionQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CostCentreID?: Maybe<Scalars['String']>;
}>;


export type GetNextLevelCcDefinitionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getNextLevelCCDefinition'>
);

export type GetParentCostCentreForTreeQueryVariables = Exact<{
  CostCentreArr?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetParentCostCentreForTreeQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getParentCostCentreForTree'>
);

export type SetDefaultCostCentreMutationVariables = Exact<{
  CostCentreID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type SetDefaultCostCentreMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setDefaultCostCentre'>
);

export type GetMasterCoaQueryVariables = Exact<{
  AccountType?: Maybe<Scalars['String']>;
  IsLastNode?: Maybe<Scalars['Boolean']>;
  orderByAsc?: Maybe<Scalars['String']>;
  IsControl?: Maybe<Scalars['Boolean']>;
  AccountID?: Maybe<Scalars['String']>;
}>;


export type GetMasterCoaQuery = (
  { __typename?: 'Query' }
  & { getMasterCOA: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'ParentMasterCOAID' | 'Name' | 'Code' | 'IsControl' | 'AccountType' | 'IsLastNode' | 'Level'>
  )> }
);

export type GetStockItemQueryVariables = Exact<{
  StockItemID?: Maybe<Scalars['String']>;
  StockCategoryID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetStockItemQuery = (
  { __typename?: 'Query' }
  & { getStockItem: Array<(
    { __typename?: 'StockItemEntity' }
    & Pick<StockItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'AccountID' | 'StockItemID' | 'StockCategoryID' | 'RecordStatus' | 'Name' | 'ABCClass' | 'ShelfLife' | 'StandardCost' | 'UOMID'>
  )> }
);

export type GetCreditorProfileWithAccountQueryVariables = Exact<{
  accountID: Scalars['String'];
}>;


export type GetCreditorProfileWithAccountQuery = (
  { __typename?: 'Query' }
  & { getCreditorProfileWithAccount: Array<(
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'CompanyRegNo' | 'ContactNo' | 'AccountID' | 'Email' | 'CreditTerm' | 'TaxSchemeID' | 'Region' | 'GSTRegNo' | 'TaxIdentificationNo' | 'Remark' | 'BankName' | 'BankAccountNo' | 'CreditorTypeEnum' | 'CompanyName' | 'modTs' | 'Address' | 'CreditorStatus'>
    & { CreditorContact?: Maybe<Array<(
      { __typename?: 'CreditorContactEntity' }
      & Pick<CreditorContactEntity, 'CreditorContactID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Designation' | 'Email'>
    )>>, CreditorDirector?: Maybe<Array<(
      { __typename?: 'CreditorDirectorEntity' }
      & Pick<CreditorDirectorEntity, 'CreditorDirectorID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Email' | 'IdentityNo' | 'IdentityType'>
    )>> }
  )> }
);

export type GetCreditorAccountQueryVariables = Exact<{
  orderByAsc?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  CreditorTypeEnum?: Maybe<CreditorType>;
}>;


export type GetCreditorAccountQuery = (
  { __typename?: 'Query' }
  & { getCreditorAccount: Array<(
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'AccountID' | 'CompanyRegNo' | 'ContactNo' | 'Email' | 'CreditTerm' | 'TaxSchemeID' | 'TaxIdentificationNo' | 'Region' | 'GSTRegNo' | 'Remark' | 'BankName' | 'BankAccountNo' | 'CreditorTypeEnum' | 'CompanyName' | 'modTs' | 'Address' | 'CreditorStatus'>
  )> }
);

export type GetCreditorAccountDetailQueryVariables = Exact<{
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeID?: Maybe<Scalars['String']>;
  CreditorTypeEnum?: Maybe<CreditorType>;
}>;


export type GetCreditorAccountDetailQuery = (
  { __typename?: 'Query' }
  & { getCreditorAccount: Array<(
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'AccountID' | 'CompanyRegNo' | 'ContactNo' | 'Email' | 'CreditTerm' | 'TaxSchemeID' | 'TaxIdentificationNo' | 'Region' | 'GSTRegNo' | 'Remark' | 'BankName' | 'BankAccountNo' | 'CreditorTypeEnum' | 'CompanyName' | 'modTs' | 'Address' | 'CreditorStatus'>
  )>, getCreditorContact: Array<(
    { __typename?: 'CreditorContactEntity' }
    & Pick<CreditorContactEntity, 'CreditorContactID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Designation' | 'Email'>
  )>, getCreditorDirector: Array<(
    { __typename?: 'CreditorDirectorEntity' }
    & Pick<CreditorDirectorEntity, 'CreditorDirectorID' | 'CreditorAccountID' | 'Name' | 'ContactNo' | 'Email' | 'IdentityNo' | 'IdentityType'>
  )> }
);

export type CreateCreditorAccountMutationVariables = Exact<{
  input: CreditorAccountInput;
  contactItem?: Maybe<Array<CreditorContactInput> | CreditorContactInput>;
  directorItem?: Maybe<Array<CreditorDirectorInput> | CreditorDirectorInput>;
}>;


export type CreateCreditorAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateCreditorAccount'>
);

export type UpdateCreditorAccountMutationVariables = Exact<{
  input: CreditorAccountInput;
}>;


export type UpdateCreditorAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateCreditorAccount: (
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID'>
  ) }
);

export type UpdateCreditorAccountWithItemMutationVariables = Exact<{
  input: CreditorAccountInput;
  contactItem?: Maybe<Array<CreditorContactInput> | CreditorContactInput>;
  directorItem?: Maybe<Array<CreditorDirectorInput> | CreditorDirectorInput>;
}>;


export type UpdateCreditorAccountWithItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateCreditorAccountWithItem'>
);

export type GetCreditorAccountCompanyAssignmentQueryVariables = Exact<{
  CreditorAccountCompanyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetCreditorAccountCompanyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getCreditorAccountCompanyAssignment: Array<(
    { __typename?: 'CreditorAccountCompanyAssignmentEntity' }
    & Pick<CreditorAccountCompanyAssignmentEntity, 'CreditorAccountCompanyAssignmentID' | 'CreditorAccountID' | 'CompanyID'>
  )> }
);

export type AssignCreditorAccountCompanyMutationVariables = Exact<{
  input: CreditorAccountCompanyAssignmentInput;
}>;


export type AssignCreditorAccountCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AssignCreditorAccountComapany'>
);

export type UnassignCreditorAccountCompanyMutationVariables = Exact<{
  input: CreditorAccountCompanyAssignmentInput;
}>;


export type UnassignCreditorAccountCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnassignCreditorAccountComapany'>
);

export type CreditorAccountTotalDueQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type CreditorAccountTotalDueQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'CreditorAccountTotalDue'>
);

export type GetCreditorTypeQueryVariables = Exact<{
  CreditorTypeID?: Maybe<Scalars['String']>;
  CreditorType?: Maybe<CreditorType>;
}>;


export type GetCreditorTypeQuery = (
  { __typename?: 'Query' }
  & { getCreditorType: Array<(
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID' | 'MasterCOAID' | 'Description' | 'CreditorType' | 'AccrualCOAID' | 'Code'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Name' | 'Code'>
    )> }
  )> }
);

export type GetCreditorTypeWithAccountQueryVariables = Exact<{
  accountID: Scalars['String'];
  CompanyID: Scalars['String'];
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetCreditorTypeWithAccountQuery = (
  { __typename?: 'Query' }
  & { getCreditorTypeWithAccount: Array<(
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID' | 'MasterCOAID' | 'Description' | 'CreditorType' | 'Code'>
    & { CreditorAccount?: Maybe<Array<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'CompanyRegNo' | 'CompanyName' | 'ContactNo'>
    )>>, MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'Name' | 'Code'>
    )> }
  )> }
);

export type GetMasterCoaWithAccountQueryVariables = Exact<{
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
}>;


export type GetMasterCoaWithAccountQuery = (
  { __typename?: 'Query' }
  & { getMasterCOAWithAccount: Array<(
    { __typename?: 'MasterCOAEntity' }
    & Pick<MasterCoaEntity, 'MasterCOAID' | 'ParentMasterCOAID' | 'Name' | 'Code' | 'IsControl' | 'AccountType' | 'IsLastNode' | 'Level'>
    & { EntityCOA?: Maybe<Array<(
      { __typename?: 'EntityCOAEntity' }
      & Pick<EntityCoaEntity, 'EntityCOAID' | 'CompanyID'>
    )>> }
  )> }
);

export type CreateCreditorTypeWithAccountMutationVariables = Exact<{
  input: CreditorTypeInput;
}>;


export type CreateCreditorTypeWithAccountMutation = (
  { __typename?: 'Mutation' }
  & { createCreditorType: (
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID'>
  ) }
);

export type UpdateCreditorTypeWithAccountMutationVariables = Exact<{
  input: CreditorTypeInput;
}>;


export type UpdateCreditorTypeWithAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateCreditorType: (
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID'>
  ) }
);

export type DeleteCreditorTypeWithAccountMutationVariables = Exact<{
  input: CreditorTypeDeleteInput;
}>;


export type DeleteCreditorTypeWithAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCreditorType'>
);

export type CreateCreditorTypeMutationVariables = Exact<{
  input: CreditorTypeInput;
}>;


export type CreateCreditorTypeMutation = (
  { __typename?: 'Mutation' }
  & { createCreditorType: (
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID'>
  ) }
);

export type UpdateCreditorTypeMutationVariables = Exact<{
  input: CreditorTypeInput;
}>;


export type UpdateCreditorTypeMutation = (
  { __typename?: 'Mutation' }
  & { updateCreditorType: (
    { __typename?: 'CreditorTypeEntity' }
    & Pick<CreditorTypeEntity, 'CreditorTypeID'>
  ) }
);

export type DeleteCreditorTypeMutationVariables = Exact<{
  input: CreditorTypeDeleteInput;
}>;


export type DeleteCreditorTypeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCreditorType'>
);

export type GetTaxSchemeQueryVariables = Exact<{
  TaxSchemeID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  AccTaxClass?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetTaxSchemeQuery = (
  { __typename?: 'Query' }
  & { getTaxScheme: Array<(
    { __typename?: 'TaxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'RecordStatus' | 'Code' | 'Description' | 'AccTaxCategory' | 'AccTaxClass' | 'MasterCOAID'>
  )> }
);

export type GetTaxSchemeCodeQueryVariables = Exact<{
  TaxSchemeID?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  AccTaxClass?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetTaxSchemeCodeQuery = (
  { __typename?: 'Query' }
  & { getTaxScheme: Array<(
    { __typename?: 'TaxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'RecordStatus' | 'Code' | 'Description' | 'AccTaxCategory' | 'AccTaxClass' | 'MasterCOAID'>
  )> }
);

export type GetUomQueryVariables = Exact<{
  UOMID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<RecordStatus>;
}>;


export type GetUomQuery = (
  { __typename?: 'Query' }
  & { getUOM: Array<(
    { __typename?: 'AccUOMEntity' }
    & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code' | 'RecordStatus'>
  )> }
);

export type CreateUomMutationVariables = Exact<{
  input: UomInput;
}>;


export type CreateUomMutation = (
  { __typename?: 'Mutation' }
  & { createUOM: (
    { __typename?: 'AccUOMEntity' }
    & Pick<AccUomEntity, 'UOMID'>
  ) }
);

export type UpdateUomMutationVariables = Exact<{
  input: UomInput;
}>;


export type UpdateUomMutation = (
  { __typename?: 'Mutation' }
  & { updateUOM: (
    { __typename?: 'AccUOMEntity' }
    & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
  ) }
);

export type DeleteUomMutationVariables = Exact<{
  input: UomDeleteInput;
}>;


export type DeleteUomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUOM'>
);

export type GetUomExchangeQueryVariables = Exact<{
  FromUomID?: Maybe<Scalars['String']>;
  ToUomID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetUomExchangeQuery = (
  { __typename?: 'Query' }
  & { getUOMExchange: Array<(
    { __typename?: 'AccUOMExchangeEntity' }
    & Pick<AccUomExchangeEntity, 'ID' | 'FromUomID' | 'ToUomID' | 'FromAmt' | 'ToAmt' | 'ExchangeRate' | 'UomLog'>
  )> }
);

export type GetUomExchangeDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUomExchangeDataQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUomExchangeData'>
);

export type GetUomExchangeListQueryVariables = Exact<{
  UOMID: Scalars['String'];
}>;


export type GetUomExchangeListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUOMExchangeList'>
);

export type CreateUomExchangeMutationVariables = Exact<{
  input: UomExchangeInput;
}>;


export type CreateUomExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUOMExchange'>
);

export type UpdateUomExchangeMutationVariables = Exact<{
  input: UomExchangeInput;
}>;


export type UpdateUomExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUOMExchange'>
);

export type DeleteUomExchangeMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteUomExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUOMExchange'>
);

export type DocumentListingQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type DocumentListingQuery = (
  { __typename?: 'Query' }
  & { DocumentListing?: Maybe<Array<(
    { __typename?: 'AttachmentEntity' }
    & Pick<AttachmentEntity, 'AttachmentID' | 'fileName' | 'fileURL' | 'mediaType' | 'createdTs' | 'description' | 'refID'>
  )>> }
);

export type GetGeneralGrtNsQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
}>;


export type GetGeneralGrtNsQuery = (
  { __typename?: 'Query' }
  & { getGeneralGRTNs: Array<(
    { __typename?: 'GRTNHeaderEntity' }
    & Pick<GrtnHeaderEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'GRTNHeaderID' | 'AccountID' | 'SupplierID' | 'DeliveryLocationID' | 'CompanyID' | 'WarehouseID' | 'ApprovalStatus' | 'RecordStatus' | 'DocDate' | 'DocNo' | 'RefNo' | 'Description' | 'TransactionDate'>
    & { Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CompanyRegNo'>
    )>, DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'Name' | 'Address' | 'ContactNo' | 'Latitude' | 'Longitude' | 'MainLocation'>
    )>, Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name' | 'Code' | 'Address'>
    )>, GRTNHeaderItem?: Maybe<Array<(
      { __typename?: 'GRTNItemEntity' }
      & Pick<GrtnItemEntity, 'GRTNItemID' | 'DOItemID' | 'OutstandingQty' | 'ReturnedQty' | 'ReturnedAmt' | 'Replacement' | 'GRTNHeaderID'>
      & { DOItem?: Maybe<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'DOItemID' | 'DOHeaderID' | 'POItemID' | 'UpToDateQty' | 'AcceptedQty' | 'ReturnedQty'>
        & { POItem?: Maybe<(
          { __typename?: 'POItemEntity' }
          & Pick<PoItemEntity, 'POHeaderID' | 'POItemID' | 'PRItemID' | 'UnitPrice' | 'BaseAmt' | 'TaxAmt' | 'TotalAmt' | 'Remarks' | 'OrderedQty' | 'OutstandingQty' | 'CostCentreID'>
          & { POHeader?: Maybe<(
            { __typename?: 'POHeaderEntity' }
            & Pick<PoHeaderEntity, 'DocNo'>
          )>, CostCentre?: Maybe<(
            { __typename?: 'CostCentreEntity' }
            & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
          )>, Item?: Maybe<(
            { __typename?: 'ItemEntity' }
            & Pick<ItemEntity, 'ItemID' | 'Name'>
            & { UOM?: Maybe<(
              { __typename?: 'AccUOMEntity' }
              & Pick<AccUomEntity, 'Name' | 'Code'>
            )> }
          )> }
        )>, DOHeader?: Maybe<(
          { __typename?: 'DOHeaderEntity' }
          & Pick<DoHeaderEntity, 'DocNo' | 'DOHeaderID' | 'DoNo' | 'Description'>
        )> }
      )>, GRTNHeader?: Maybe<(
        { __typename?: 'GRTNHeaderEntity' }
        & Pick<GrtnHeaderEntity, 'DocNo' | 'DocDate' | 'RefNo' | 'Description'>
      )> }
    )>> }
  )> }
);

export type GetGeneralPOsQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  DOHeaderID?: Maybe<Scalars['String']>;
}>;


export type GetGeneralPOsQuery = (
  { __typename?: 'Query' }
  & { getGeneralPOs: Array<(
    { __typename?: 'POHeaderEntity' }
    & Pick<PoHeaderEntity, 'POHeaderID' | 'AccountID' | 'SupplierID' | 'DeliveryLocationID' | 'ContactPerson' | 'CompanyID' | 'DocNo' | 'DocDate' | 'ExpectedDate' | 'RecordStatus' | 'ApprovalStatus' | 'Instruction'>
    & { Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CompanyRegNo'>
    )>, Company: (
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'Name' | 'CompanyRegNo' | 'CompanyTax' | 'ContactNo' | 'Address' | 'SSTNo' | 'GSTNo'>
    ), DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'Name' | 'Address' | 'ContactNo' | 'Latitude' | 'Longitude' | 'MainLocation'>
    )>, POItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'POItemID' | 'AccountID' | 'WarehouseID' | 'PRItemID' | 'POHeaderID' | 'TotalAmt' | 'TaxAmt' | 'BaseAmt' | 'UOMID' | 'DeliveryLocationID' | 'OrderedQty' | 'ItemID' | 'UnitPrice' | 'OutstandingQty' | 'TaxSchemeID' | 'Remarks'>
      & { CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name'>
      )>, DOItem?: Maybe<Array<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'DOItemID' | 'UpToDateQty' | 'AcceptedQty' | 'POItemID'>
      )>>, PRItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'PRItemID' | 'CostCentreID' | 'Quantity' | 'DeliveryLocationID' | 'Remark'>
        & { PRHeader?: Maybe<(
          { __typename?: 'PRHeaderEntity' }
          & Pick<PrHeaderEntity, 'PRHeaderID' | 'DocDate' | 'DocNo' | 'Instruction' | 'ContactNo' | 'ContactPerson' | 'DeliveryLocationID'>
        )>, Item?: Maybe<(
          { __typename?: 'ItemEntity' }
          & Pick<ItemEntity, 'Name'>
        )> }
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'UOMID' | 'Code' | 'Name'>
      )>, UOMExchange?: Maybe<Array<(
        { __typename?: 'poUOMExchange' }
        & Pick<PoUomExchange, 'uomid' | 'uomcode' | 'uomname'>
      )>>, Item?: Maybe<(
        { __typename?: 'ItemEntity' }
        & Pick<ItemEntity, 'Name' | 'Description'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code' | 'Description'>
      )> }
    )>> }
  )> }
);

export type GetGeneralDoQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  SupplierID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
  WarehouseID?: Maybe<Scalars['String']>;
  DOHeaderID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetGeneralDoQuery = (
  { __typename?: 'Query' }
  & { getGeneralDO: Array<(
    { __typename?: 'DOHeaderEntity' }
    & Pick<DoHeaderEntity, 'DOHeaderID' | 'AccountID' | 'SupplierID' | 'CompanyID' | 'DeliveryLocationID' | 'WarehouseID' | 'DocDate' | 'DoNo' | 'DocNo' | 'Description' | 'TransactionDate' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'RecordStatus' | 'ApprovalStatus' | 'RejectionDate' | 'RejectionRemark' | 'PurchaseOrder' | 'UpToDateAmt'>
    & { Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CreditorAccountID'>
    )>, DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'DeliveryLocationID' | 'Name' | 'Address'>
    )>, DOItem?: Maybe<Array<(
      { __typename?: 'DOItemEntity' }
      & Pick<DoItemEntity, 'DOItemID' | 'AcceptedQty' | 'AcceptedAmt' | 'DOHeaderID' | 'POItemID' | 'GRTNItemID' | 'RecordStatus' | 'UpToDateQty' | 'ReturnedQty'>
      & { DOHeader?: Maybe<(
        { __typename?: 'DOHeaderEntity' }
        & Pick<DoHeaderEntity, 'DOHeaderID' | 'Description'>
      )>, POItem?: Maybe<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'POItemID' | 'OutstandingQty' | 'UnitPrice' | 'OrderedQty' | 'CostCentreID' | 'Remarks' | 'RecordStatus'>
        & { POHeader?: Maybe<(
          { __typename?: 'POHeaderEntity' }
          & Pick<PoHeaderEntity, 'POHeaderID' | 'DocNo' | 'DocDate' | 'ApprovalStatus' | 'RecordStatus'>
        )>, CostCentre?: Maybe<(
          { __typename?: 'CostCentreEntity' }
          & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
        )>, Item?: Maybe<(
          { __typename?: 'ItemEntity' }
          & Pick<ItemEntity, 'ItemID' | 'Description' | 'Name'>
        )>, UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
        )> }
      )>, GRTNItem?: Maybe<Array<(
        { __typename?: 'GRTNItemEntity' }
        & Pick<GrtnItemEntity, 'GRTNHeaderID' | 'GRTNItemID' | 'ReturnedQty' | 'ReturnedAmt' | 'Replacement'>
      )>>, ReplacedGRTNItem?: Maybe<(
        { __typename?: 'GRTNItemEntity' }
        & Pick<GrtnItemEntity, 'GRTNHeaderID' | 'GRTNItemID' | 'ReturnedQty' | 'ReturnedAmt' | 'Replacement'>
      )> }
    )>> }
  )> }
);

export type SubmitGeneralDoMutationVariables = Exact<{
  DOHeaderID?: Maybe<Scalars['String']>;
  doInput: DoInput;
  doItemInput: Array<DoItemInput> | DoItemInput;
}>;


export type SubmitGeneralDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitGeneralDO'>
);

export type GetSupplierByDoStatusQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  ApprovalStatus: ApprovalStatus;
}>;


export type GetSupplierByDoStatusQuery = (
  { __typename?: 'Query' }
  & { getSupplierByDOStatus: Array<(
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'AccountID' | 'CompanyRegNo' | 'CreditorTypeID' | 'TaxSchemeID' | 'ContactNo' | 'Email' | 'CreditTerm' | 'GSTRegNo' | 'Remark' | 'BankName' | 'BankAccountNo' | 'Address' | 'CompanyName' | 'CreditorStatus'>
  )> }
);

export type GetWarehouseDeliveryLocationQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetWarehouseDeliveryLocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWarehouseDeliveryLocation'>
);

export type DeleteGeneralDoMutationVariables = Exact<{
  DOHeaderID: Scalars['String'];
}>;


export type DeleteGeneralDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGeneralDO'>
);

export type ActionOnGdoStatusMutationVariables = Exact<{
  input: GdoActionInput;
  DOHeaderID: Array<Scalars['String']> | Scalars['String'];
}>;


export type ActionOnGdoStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnGDOStatus'>
);

export type GetGrtnHeaderQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  GRTNHeaderID?: Maybe<Scalars['String']>;
}>;


export type GetGrtnHeaderQuery = (
  { __typename?: 'Query' }
  & { getGRTNHeader: Array<(
    { __typename?: 'GRTNHeaderEntity' }
    & Pick<GrtnHeaderEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'GRTNHeaderID' | 'AccountID' | 'SupplierID' | 'CompanyID' | 'DeliveryLocationID' | 'WarehouseID' | 'DocDate' | 'TransactionDate' | 'DocNo' | 'RefNo' | 'Description' | 'RecordStatus' | 'ApprovalStatus'>
    & { DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'Name' | 'Address'>
    )>, Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'WarehouseID' | 'Name' | 'Code' | 'Address'>
    )>, Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName'>
    )>, GRTNHeaderItem?: Maybe<Array<(
      { __typename?: 'GRTNItemEntity' }
      & Pick<GrtnItemEntity, 'GRTNItemID' | 'DOItemID' | 'OutstandingQty' | 'ReturnedQty' | 'ReturnedAmt' | 'Replacement'>
      & { DOItem?: Maybe<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'DOItemID' | 'DOHeaderID' | 'POItemID' | 'UpToDateQty' | 'AcceptedQty' | 'ReturnedQty'>
        & { POItem?: Maybe<(
          { __typename?: 'POItemEntity' }
          & Pick<PoItemEntity, 'PRItemID' | 'UnitPrice' | 'BaseAmt' | 'TaxAmt' | 'TotalAmt' | 'Remarks' | 'OrderedQty' | 'OutstandingQty'>
          & { Item?: Maybe<(
            { __typename?: 'ItemEntity' }
            & Pick<ItemEntity, 'Name'>
            & { UOM?: Maybe<(
              { __typename?: 'AccUOMEntity' }
              & Pick<AccUomEntity, 'Name' | 'Code'>
            )> }
          )> }
        )>, DOHeader?: Maybe<(
          { __typename?: 'DOHeaderEntity' }
          & Pick<DoHeaderEntity, 'DocNo' | 'DOHeaderID' | 'DoNo' | 'Description'>
        )> }
      )>, GRTNHeader?: Maybe<(
        { __typename?: 'GRTNHeaderEntity' }
        & Pick<GrtnHeaderEntity, 'DocNo' | 'DocDate' | 'RefNo' | 'Description'>
      )> }
    )>> }
  )> }
);

export type CreateUpdateGrtnMutationVariables = Exact<{
  GRTNHeaderID?: Maybe<Scalars['String']>;
  grtnInput: GeneralGrtnInput;
  grtnItemInput: Array<GeneralGrtnItemInput> | GeneralGrtnItemInput;
}>;


export type CreateUpdateGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUpdateGRTN'>
);

export type CreateGrtnMutationVariables = Exact<{
  grtnInput: GeneralGrtnInput;
  grtnItemInput: Array<GeneralGrtnItemInput> | GeneralGrtnItemInput;
}>;


export type CreateGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateGRTN'>
);

export type UpdateGrtnMutationVariables = Exact<{
  GRTNHeaderID?: Maybe<Scalars['String']>;
  grtnInput: GeneralGrtnInput;
  grtnItemInput: Array<GeneralGrtnItemInput> | GeneralGrtnItemInput;
}>;


export type UpdateGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateGRTN'>
);

export type GetWarehouseDeliveryQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetWarehouseDeliveryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWarehouseDeliveryLocation'>
);

export type DeleteGrtnMutationVariables = Exact<{
  GRTNHeaderID: Scalars['String'];
}>;


export type DeleteGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGRTN'>
);

export type ActionOnGrtnStatusMutationVariables = Exact<{
  GRTNHeaderID: Array<Scalars['String']> | Scalars['String'];
  ApprovalStatus: ApprovalStatus;
  input: GeneralGrtnRejectInput;
  CompanyID: Scalars['String'];
}>;


export type ActionOnGrtnStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnGRTNStatus'>
);

export type GetPoHeaderQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  POHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  POType?: Maybe<PoType>;
}>;


export type GetPoHeaderQuery = (
  { __typename?: 'Query' }
  & { getPOHeader: Array<(
    { __typename?: 'POHeaderEntity' }
    & Pick<PoHeaderEntity, 'POHeaderID' | 'AccountID' | 'modTs' | 'modBy' | 'createdTs' | 'createdBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'SupplierID' | 'POType' | 'ContactPerson' | 'DeliveryLocationID' | 'CompanyID' | 'DocNo' | 'TotalAmt' | 'DocDate' | 'ExpectedDate' | 'RecordStatus' | 'ApprovalStatus' | 'Instruction'>
    & { Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CompanyRegNo'>
    )>, Company: (
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'Name' | 'CompanyRegNo' | 'ContactNo' | 'CompanyTax' | 'Address' | 'SSTNo' | 'GSTNo'>
    ), DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'Name' | 'Address' | 'ContactNo' | 'Latitude' | 'Longitude' | 'MainLocation'>
    )>, POItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'POItemID' | 'AccountID' | 'PRItemID' | 'POHeaderID' | 'TotalAmt' | 'TaxAmt' | 'BaseAmt' | 'UOMID' | 'CostCentreID' | 'DeliveryLocationID' | 'WarehouseID' | 'OrderedQty' | 'ItemID' | 'UnitPrice' | 'OutstandingQty' | 'TaxSchemeID' | 'Remarks' | 'ScheduledPurchasesItemID' | 'ScheduledPurchaseDetail'>
      & { CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'Name' | 'Level' | 'Code'>
      )>, DeliveryLocation?: Maybe<(
        { __typename?: 'DeliveryLocationEntity' }
        & Pick<DeliveryLocationEntity, 'Name' | 'Address' | 'ContactNo' | 'Latitude' | 'Longitude' | 'MainLocation'>
      )>, Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name' | 'Code' | 'Address'>
      )>, PRItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'PRItemID' | 'CostCentreID' | 'Quantity' | 'DeliveryLocationID' | 'Remark'>
        & { PRHeader?: Maybe<(
          { __typename?: 'PRHeaderEntity' }
          & Pick<PrHeaderEntity, 'PRHeaderID' | 'DocDate' | 'DocNo' | 'Instruction' | 'ContactNo' | 'ContactPerson' | 'DeliveryLocationID'>
        )>, Item?: Maybe<(
          { __typename?: 'ItemEntity' }
          & Pick<ItemEntity, 'Name' | 'IsInventory' | 'IsCentralised'>
        )> }
      )>, DOItem?: Maybe<Array<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'DOHeaderID' | 'AcceptedQty'>
      )>>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'UOMID' | 'Code' | 'Name'>
      )>, UOMExchange?: Maybe<Array<(
        { __typename?: 'poUOMExchange' }
        & Pick<PoUomExchange, 'uomid' | 'uomcode' | 'uomname'>
      )>>, Item?: Maybe<(
        { __typename?: 'ItemEntity' }
        & Pick<ItemEntity, 'Name' | 'Description' | 'IsInventory'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'AccountID' | 'TaxSchemeID' | 'Code' | 'Description'>
      )> }
    )>> }
  )> }
);

export type GetSiteWithAccountQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetSiteWithAccountQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSiteWithAccount'>
);

export type GetOsGeneralPrQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetOsGeneralPrQuery = (
  { __typename?: 'Query' }
  & { getOSGeneralPR: Array<(
    { __typename?: 'PRHeaderEntity' }
    & Pick<PrHeaderEntity, 'PRHeaderID' | 'AccountID' | 'CompanyID' | 'DocNo' | 'DocDate' | 'ExpectedDate' | 'RequestedBy' | 'DeliveryLocationID' | 'ContactPerson' | 'ContactNo' | 'Instruction'>
    & { PRItem?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'PRItemID' | 'CostCentreID' | 'Quantity' | 'ItemID'>
      & { CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'CompanyID' | 'Name'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Code' | 'Name'>
      )>, Item?: Maybe<(
        { __typename?: 'ItemEntity' }
        & Pick<ItemEntity, 'Name'>
      )>, POItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'UOMID' | 'TaxSchemeID' | 'OutstandingQty' | 'UnitPrice'>
      )>> }
    )>> }
  )> }
);

export type GetTaxSchemeWithAccountQueryVariables = Exact<{
  accountID: Scalars['String'];
}>;


export type GetTaxSchemeWithAccountQuery = (
  { __typename?: 'Query' }
  & { getTaxSchemeWithAccount: Array<(
    { __typename?: 'TaxSchemeEntity' }
    & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code' | 'Description' | 'TaxTypeID' | 'AccTaxCategory' | 'AccTaxClass' | 'MasterCOAID'>
    & { TaxType?: Maybe<(
      { __typename?: 'TaxTypeEntity' }
      & Pick<TaxTypeEntity, 'Code' | 'Description'>
    )>, LatestTax?: Maybe<(
      { __typename?: 'AccTax' }
      & Pick<AccTax, 'Rate' | 'Date'>
    )> }
  )> }
);

export type CreateDirectPoMutationVariables = Exact<{
  input: DirectPoInput;
}>;


export type CreateDirectPoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createDirectPO'>
);

export type UpdateDirectPoMutationVariables = Exact<{
  POHeaderID?: Maybe<Scalars['String']>;
  input: DirectPoInput;
}>;


export type UpdateDirectPoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDirectPO'>
);

export type DeletePoMutationVariables = Exact<{
  POHeaderID: Scalars['String'];
}>;


export type DeletePoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deletePO'>
);

export type ActionOnGpoStatusMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  ApprovalStatus: ApprovalStatus;
  input: PoRejectInput;
  POHeaderID: Array<Scalars['String']> | Scalars['String'];
}>;


export type ActionOnGpoStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnGPOStatus'>
);

export type GetWarehouseQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetWarehouseQuery = (
  { __typename?: 'Query' }
  & { getWarehouse: Array<(
    { __typename?: 'WarehouseEntity' }
    & Pick<WarehouseEntity, 'WarehouseID' | 'Name' | 'Code' | 'Address' | 'CostCentreID'>
  )> }
);

export type GetCreditorSupplierTypeQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
  CreditorTypeEnum?: Maybe<CreditorType>;
}>;


export type GetCreditorSupplierTypeQuery = (
  { __typename?: 'Query' }
  & { getCreditorAccount: Array<(
    { __typename?: 'CreditorAccountEntity' }
    & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CreditorTypeID' | 'CompanyName' | 'ContactNo' | 'TaxSchemeID' | 'CreditorTypeEnum' | 'CreditorStatus'>
  )> }
);

export type CreateGeneralPoMutationVariables = Exact<{
  generalPOInput: Array<PoInput> | PoInput;
}>;


export type CreateGeneralPoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createGeneralPO'>
);

export type GetSupplierAccountByCompanyQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierAccountByCompanyQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierAccountByCompany'>
);

export type CheckingCancelClosePoQueryVariables = Exact<{
  POHeaderID: Scalars['String'];
}>;


export type CheckingCancelClosePoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkingCancelClosePO'>
);

export type CancelPoMutationVariables = Exact<{
  input: PurchaseActionInput;
  POHeaderID: Scalars['String'];
}>;


export type CancelPoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelClosePO'>
);

export type GetCentralisedPrItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCentralisedPrItemsQuery = (
  { __typename?: 'Query' }
  & { getCentralisedPRItems: Array<(
    { __typename?: 'PRItemEntity' }
    & Pick<PrItemEntity, 'PRItemID' | 'WarehouseID' | 'PRHeaderID' | 'Quantity' | 'ItemID' | 'UOMID' | 'CostCentreID' | 'Remark' | 'IsPOGenerated' | 'rfqSubmissionSelected' | 'bulkPurchaseSelected' | 'masterFileSelected'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'Name'>
    )>, PRHeader?: Maybe<(
      { __typename?: 'PRHeaderEntity' }
      & Pick<PrHeaderEntity, 'CompanyID' | 'ExpectedDate' | 'DocNo' | 'DocDate' | 'RequestedBy' | 'ContactPerson' | 'ContactNo' | 'Instruction' | 'DeliveryLocationID'>
      & { DeliveryLocation?: Maybe<(
        { __typename?: 'DeliveryLocationEntity' }
        & Pick<DeliveryLocationEntity, 'CompanyID' | 'Name' | 'Address' | 'ContactNo' | 'RecordStatus'>
      )> }
    )>, Item?: Maybe<(
      { __typename?: 'ItemEntity' }
      & Pick<ItemEntity, 'RecordStatus' | 'Name' | 'Description' | 'UOMID' | 'TaxSchemeID'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code' | 'RecordStatus'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code' | 'Description'>
      )> }
    )>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'Name' | 'Code' | 'RecordStatus'>
    )> }
  )> }
);

export type GetPrHeaderQueryVariables = Exact<{
  PRHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
}>;


export type GetPrHeaderQuery = (
  { __typename?: 'Query' }
  & { getPRHeader: Array<(
    { __typename?: 'PRHeaderEntity' }
    & Pick<PrHeaderEntity, 'modTs' | 'modBy' | 'createdTs' | 'createdBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'PRHeaderID' | 'AccountID' | 'DocDate' | 'ExpectedDate' | 'RequestedBy' | 'DeliveryLocationID' | 'ContactPerson' | 'Instruction' | 'ContactNo' | 'DocNo' | 'RecordStatus' | 'ApprovalStatus' | 'CancelCloseRemark' | 'CompanyID'>
    & { DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'DeliveryLocationID' | 'Name' | 'Address' | 'ContactNo'>
    )>, Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'Name' | 'CompanyTax' | 'ContactNo' | 'CompanyRegNo' | 'Address' | 'GSTNo' | 'SSTNo'>
    )>, PRItem?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'PRItemID' | 'PRHeaderID' | 'ItemID' | 'UOMID' | 'DeliveryLocationID' | 'WarehouseID' | 'CostCentreID' | 'Quantity' | 'OutstandingQty' | 'Remark'>
      & { Item?: Maybe<(
        { __typename?: 'ItemEntity' }
        & Pick<ItemEntity, 'ItemID' | 'Name' | 'Description' | 'UOMID'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
      )>, DeliveryLocation?: Maybe<(
        { __typename?: 'DeliveryLocationEntity' }
        & Pick<DeliveryLocationEntity, 'DeliveryLocationID' | 'Name' | 'Address'>
      )>, Warehouse?: Maybe<(
        { __typename?: 'WarehouseEntity' }
        & Pick<WarehouseEntity, 'Name' | 'Code' | 'Address'>
      )>, CostCentre?: Maybe<(
        { __typename?: 'CostCentreEntity' }
        & Pick<CostCentreEntity, 'CostCentreID' | 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetArAgingQueryVariables = Exact<{
  AccountType?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  AgingID?: Maybe<Scalars['String']>;
}>;


export type GetArAgingQuery = (
  { __typename?: 'Query' }
  & { getARAging: Array<(
    { __typename?: 'ARAgingEntity' }
    & Pick<ArAgingEntity, 'AccountID' | 'AgingID' | 'CompanyID' | 'Period1' | 'Period2' | 'Period3' | 'Period4' | 'AccountType'>
    & { Company?: Maybe<(
      { __typename?: 'CompanyEntity' }
      & Pick<CompanyEntity, 'CompanyID' | 'AccountID' | 'Name' | 'Code' | 'Address' | 'ContactNo'>
    )> }
  )> }
);

export type GetUserQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'jobTitle' | 'contactNo' | 'superUser' | 'commonStatus' | 'userName'>
  ) }
);

export type CreateGeneralPrMutationVariables = Exact<{
  generalPRInput: PrInput;
  generalPRItemInput: Array<PrItemInput> | PrItemInput;
}>;


export type CreateGeneralPrMutation = (
  { __typename?: 'Mutation' }
  & { createGeneralPR: (
    { __typename?: 'PRHeaderEntity' }
    & Pick<PrHeaderEntity, 'PRHeaderID' | 'DocNo'>
  ) }
);

export type UpdateGeneralPrMutationVariables = Exact<{
  generalPRInput: PrInput;
  generalPRItemInput: Array<PrItemInput> | PrItemInput;
  PRHeaderID: Scalars['String'];
}>;


export type UpdateGeneralPrMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGeneralPR'>
);

export type GetGeneralPItemQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGeneralPItemQuery = (
  { __typename?: 'Query' }
  & { getItem: Array<(
    { __typename?: 'ItemEntity' }
    & Pick<ItemEntity, 'ItemID' | 'Name' | 'UOMID'>
  )> }
);

export type GetOutstandingGeneralPrItemsQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type GetOutstandingGeneralPrItemsQuery = (
  { __typename?: 'Query' }
  & { getOutstandingGeneralPRItems: Array<(
    { __typename?: 'PRItemEntity' }
    & Pick<PrItemEntity, 'PRItemID' | 'WarehouseID' | 'PRHeaderID' | 'Quantity' | 'ItemID' | 'UOMID' | 'CostCentreID' | 'Remark' | 'IsPOGenerated' | 'rfqSubmissionSelected' | 'bulkPurchaseSelected' | 'masterFileSelected'>
    & { Warehouse?: Maybe<(
      { __typename?: 'WarehouseEntity' }
      & Pick<WarehouseEntity, 'CompanyID' | 'Name' | 'Address' | 'ContactNo' | 'RecordStatus'>
    )>, PRHeader?: Maybe<(
      { __typename?: 'PRHeaderEntity' }
      & Pick<PrHeaderEntity, 'CompanyID' | 'ExpectedDate' | 'DocNo' | 'DocDate' | 'RequestedBy' | 'ContactPerson' | 'ContactNo' | 'Instruction' | 'DeliveryLocationID'>
      & { DeliveryLocation?: Maybe<(
        { __typename?: 'DeliveryLocationEntity' }
        & Pick<DeliveryLocationEntity, 'CompanyID' | 'Name' | 'Address' | 'ContactNo' | 'RecordStatus'>
      )> }
    )>, Item?: Maybe<(
      { __typename?: 'ItemEntity' }
      & Pick<ItemEntity, 'RecordStatus' | 'Name' | 'Description' | 'UOMID' | 'TaxSchemeID'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code' | 'RecordStatus'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'Code' | 'Description'>
      )> }
    )>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'Name' | 'Code' | 'RecordStatus'>
    )> }
  )> }
);

export type ActionOnGprStatusMutationVariables = Exact<{
  input: GprActionInput;
  GeneralPRIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type ActionOnGprStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnGPRStatus'>
);

export type DeleteGeneralPrMutationVariables = Exact<{
  PRHeaderID: Scalars['String'];
}>;


export type DeleteGeneralPrMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGeneralPR'>
);

export type CancelClosePrMutationVariables = Exact<{
  PRHeaderID: Scalars['String'];
  input: GprActionInput;
}>;


export type CancelClosePrMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelClosePR'>
);

export type CheckingCancelClosePrQueryVariables = Exact<{
  PRHeaderID: Scalars['String'];
}>;


export type CheckingCancelClosePrQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'checkingCancelClosePR'>
);

export type GetRfqHeaderQueryVariables = Exact<{
  RFQHeaderID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ApprovalStatus?: Maybe<ApprovalStatus>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetRfqHeaderQuery = (
  { __typename?: 'Query' }
  & { getRFQHeader: Array<(
    { __typename?: 'RFQHeaderEntity' }
    & Pick<RfqHeaderEntity, 'modTs' | 'modBy' | 'createdTs' | 'createdBy' | 'submittedTs' | 'submittedBy' | 'approvedTs' | 'approvedBy' | 'RFQHeaderID' | 'AccountID' | 'DocNo' | 'DocDate' | 'Description' | 'ExpectedDate' | 'RejectionDate' | 'CancelCloseDate' | 'CancelCloseRemark' | 'RecordStatus' | 'ApprovalStatus' | 'ProfileInfo' | 'PRHeaderInfo'>
    & { RFQSupplierInvitation?: Maybe<Array<(
      { __typename?: 'RFQSupplierInvitationEntity' }
      & Pick<RfqSupplierInvitationEntity, 'RFQSupplierInvitationID' | 'SupplierID' | 'RecordStatus'>
      & { Supplier?: Maybe<(
        { __typename?: 'CreditorAccountEntity' }
        & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName' | 'CreditTerm' | 'ContactNo' | 'Email'>
      )>, RFQSupplierSubmission?: Maybe<Array<(
        { __typename?: 'RFQSupplierSubmissionEntity' }
        & Pick<RfqSupplierSubmissionEntity, 'RFQSupplierSubmissionID' | 'RefNo' | 'CreditTerm' | 'SubmittedDate' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks'>
      )>>, LatestSubmittedQuotation?: Maybe<(
        { __typename?: 'RFQSupplierSubmissionEntity' }
        & Pick<RfqSupplierSubmissionEntity, 'RefNo' | 'CreditTerm' | 'SubmittedDate' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks'>
        & { RFQSubmissionItem?: Maybe<Array<(
          { __typename?: 'RFQSubmissionItemEntity' }
          & Pick<RfqSubmissionItemEntity, 'RFQSubmissionItemID' | 'RFQSupplierSubmissionID' | 'RFQItemID' | 'UnitPrice' | 'Remarks' | 'IsSelected' | 'Cheapest' | 'TotalPrice'>
        )>> }
      )> }
    )>>, RFQItem?: Maybe<Array<(
      { __typename?: 'RFQItemEntity' }
      & Pick<RfqItemEntity, 'RFQHeaderID' | 'RFQItemID'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
      )>, PRItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ItemID' | 'PRItemID' | 'PRHeaderID' | 'Remark' | 'Quantity' | 'UOMID'>
        & { UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'UOMID' | 'Code' | 'Name'>
        )>, Item?: Maybe<(
          { __typename?: 'ItemEntity' }
          & Pick<ItemEntity, 'ItemID' | 'Name' | 'LatestPurchase'>
        )> }
      )>, RFQSubmissionItem?: Maybe<Array<(
        { __typename?: 'RFQSubmissionItemEntity' }
        & Pick<RfqSubmissionItemEntity, 'RFQSubmissionItemID' | 'RFQSupplierSubmissionID' | 'RFQItemID' | 'UnitPrice' | 'Remarks' | 'IsSelected' | 'Cheapest'>
      )>> }
    )>> }
  )> }
);

export type GetGeneralPrForRfqQueryVariables = Exact<{
  PRHeaderID?: Maybe<Scalars['String']>;
}>;


export type GetGeneralPrForRfqQuery = (
  { __typename?: 'Query' }
  & { getPRHeader: Array<(
    { __typename?: 'PRHeaderEntity' }
    & Pick<PrHeaderEntity, 'PRHeaderID' | 'ExpectedDate' | 'DocNo' | 'RecordStatus'>
  )> }
);

export type GetRfqSupplierInvitationQueryVariables = Exact<{
  RFQHeaderID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetRfqSupplierInvitationQuery = (
  { __typename?: 'Query' }
  & { getRFQSupplierInvitation: Array<(
    { __typename?: 'RFQSupplierInvitationEntity' }
    & Pick<RfqSupplierInvitationEntity, 'RFQSupplierInvitationID' | 'RFQHeaderID' | 'SupplierID'>
    & { RFQHeader?: Maybe<(
      { __typename?: 'RFQHeaderEntity' }
      & Pick<RfqHeaderEntity, 'RFQHeaderID'>
      & { RFQSupplierInvitation?: Maybe<Array<(
        { __typename?: 'RFQSupplierInvitationEntity' }
        & Pick<RfqSupplierInvitationEntity, 'RFQSupplierInvitationID' | 'SupplierID'>
        & { Supplier?: Maybe<(
          { __typename?: 'CreditorAccountEntity' }
          & Pick<CreditorAccountEntity, 'CompanyName' | 'CompanyRegNo' | 'ContactNo' | 'Address' | 'CreditTerm' | 'Remark'>
        )> }
      )>> }
    )>, RFQSupplierSubmission?: Maybe<Array<(
      { __typename?: 'RFQSupplierSubmissionEntity' }
      & Pick<RfqSupplierSubmissionEntity, 'RFQSupplierSubmissionID' | 'RefNo' | 'CreditTerm' | 'SubmittedDate' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks'>
    )>>, LatestSubmittedQuotation?: Maybe<(
      { __typename?: 'RFQSupplierSubmissionEntity' }
      & Pick<RfqSupplierSubmissionEntity, 'RFQSupplierSubmissionID' | 'RefNo' | 'CreditTerm' | 'SubmittedDate'>
    )>, Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName'>
    )> }
  )> }
);

export type GetRfqSupplierSubmissionQueryVariables = Exact<{
  RFQSupplierSubmissionID?: Maybe<Scalars['String']>;
  RFQHeaderID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  RFQSupplierInvitationID?: Maybe<Scalars['String']>;
}>;


export type GetRfqSupplierSubmissionQuery = (
  { __typename?: 'Query' }
  & { getRFQSupplierSubmission: Array<(
    { __typename?: 'RFQSupplierSubmissionEntity' }
    & Pick<RfqSupplierSubmissionEntity, 'RFQSupplierSubmissionID' | 'RFQSupplierInvitationID' | 'RefNo' | 'CreditTerm' | 'SubmittedDate' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks' | 'RecordStatus'>
    & { RFQSubmissionItem?: Maybe<Array<(
      { __typename?: 'RFQSubmissionItemEntity' }
      & Pick<RfqSubmissionItemEntity, 'RFQSubmissionItemID' | 'UnitPrice' | 'Remarks' | 'RFQItemID'>
      & { RFQItem?: Maybe<(
        { __typename?: 'RFQItemEntity' }
        & Pick<RfqItemEntity, 'RFQItemID' | 'PRItemID'>
        & { PRItem?: Maybe<(
          { __typename?: 'PRItemEntity' }
          & Pick<PrItemEntity, 'PRItemID' | 'Quantity' | 'ItemID'>
          & { Item?: Maybe<(
            { __typename?: 'ItemEntity' }
            & Pick<ItemEntity, 'ItemID' | 'Name' | 'UOMID'>
            & { UOM?: Maybe<(
              { __typename?: 'AccUOMEntity' }
              & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
            )> }
          )> }
        )> }
      )> }
    )>>, Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName'>
    )> }
  )> }
);

export type GetInvitedSupplierQueryVariables = Exact<{
  RFQHeaderID: Scalars['String'];
  companyID: Scalars['String'];
}>;


export type GetInvitedSupplierQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getInvitedSupplier'>
);

export type CreateGeneralRfqMutationVariables = Exact<{
  RFQInput: RfqInput;
  RFQItemInput: Array<RfqItemInput> | RfqItemInput;
}>;


export type CreateGeneralRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createGeneralRFQ'>
);

export type UpdateGeneralRfqMutationVariables = Exact<{
  generalRFQInput: RfqInput;
  generalRFQItemInput: Array<RfqItemInput> | RfqItemInput;
  RFQHeaderID: Scalars['String'];
}>;


export type UpdateGeneralRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateGeneralRFQ'>
);

export type DeleteGeneralRfqMutationVariables = Exact<{
  RFQHeaderID: Scalars['String'];
}>;


export type DeleteGeneralRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGeneralRFQ'>
);

export type GetPrItemByPridForRfqQueryVariables = Exact<{
  PRHeaderID: Scalars['String'];
  CompanyID: Scalars['String'];
}>;


export type GetPrItemByPridForRfqQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPRItemByPRIDForRFQ'>
);

export type InviteSupplierMutationVariables = Exact<{
  RFQHeaderID: Scalars['String'];
  SupplierIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type InviteSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteSupplier'>
);

export type UninviteSupplierMutationVariables = Exact<{
  RFQHeaderID: Scalars['String'];
  SupplierID: Scalars['String'];
}>;


export type UninviteSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uninviteSupplier'>
);

export type SubmitGeneralRfqQuoteMutationVariables = Exact<{
  input: RfqSupplierSubmissionInput;
}>;


export type SubmitGeneralRfqQuoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitGeneralRFQQuote'>
);

export type DeleteRfqSupplierSubmissionMutationVariables = Exact<{
  RFQSupplierSubmissionID: Scalars['String'];
}>;


export type DeleteRfqSupplierSubmissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteRFQSupplierSubmission'>
);

export type SubmitEvaluationSelectionMutationVariables = Exact<{
  RFQInput: RfqEvalInput;
  RFQEvalSelectInput: Array<RfqEvalSelectInput> | RfqEvalSelectInput;
}>;


export type SubmitEvaluationSelectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitEvaluationSelection'>
);

export type ActionOnRfqStatusMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  input: RfqRejectInput;
  RFQHeaderID: Array<Scalars['String']> | Scalars['String'];
}>;


export type ActionOnRfqStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnRFQStatus'>
);

export type GetCreditorContactQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CreditorContactID?: Maybe<Scalars['String']>;
  CreditorAccountID?: Maybe<Scalars['String']>;
}>;


export type GetCreditorContactQuery = (
  { __typename?: 'Query' }
  & { getCreditorContact: Array<(
    { __typename?: 'CreditorContactEntity' }
    & Pick<CreditorContactEntity, 'CreditorContactID' | 'Name' | 'ContactNo' | 'Designation' | 'Email'>
  )> }
);

export type GetRfQbyStatusQueryVariables = Exact<{
  RFQHeaderID?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  StatusArr: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetRfQbyStatusQuery = (
  { __typename?: 'Query' }
  & { getRFQbyStatus: Array<(
    { __typename?: 'RFQHeaderEntity' }
    & Pick<RfqHeaderEntity, 'modTs' | 'modBy' | 'createdTs' | 'createdBy' | 'RFQHeaderID' | 'AccountID' | 'DocNo' | 'DocDate' | 'Description' | 'ExpectedDate' | 'RejectionDate' | 'CancelCloseDate' | 'CancelCloseRemark' | 'RecordStatus' | 'ApprovalStatus' | 'ProfileInfo' | 'PRHeaderInfo'>
    & { RFQSupplierInvitation?: Maybe<Array<(
      { __typename?: 'RFQSupplierInvitationEntity' }
      & Pick<RfqSupplierInvitationEntity, 'RFQSupplierInvitationID' | 'SupplierID' | 'RecordStatus'>
      & { Supplier?: Maybe<(
        { __typename?: 'CreditorAccountEntity' }
        & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName' | 'CreditTerm' | 'ContactNo' | 'Email'>
      )>, RFQSupplierSubmission?: Maybe<Array<(
        { __typename?: 'RFQSupplierSubmissionEntity' }
        & Pick<RfqSupplierSubmissionEntity, 'RFQSupplierSubmissionID' | 'RefNo' | 'CreditTerm' | 'SubmittedDate' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks'>
      )>>, LatestSubmittedQuotation?: Maybe<(
        { __typename?: 'RFQSupplierSubmissionEntity' }
        & Pick<RfqSupplierSubmissionEntity, 'RefNo' | 'CreditTerm' | 'SubmittedDate' | 'EffectiveDate' | 'ExpiryDate' | 'RecordStatus' | 'Remarks'>
        & { RFQSubmissionItem?: Maybe<Array<(
          { __typename?: 'RFQSubmissionItemEntity' }
          & Pick<RfqSubmissionItemEntity, 'RFQSubmissionItemID' | 'RFQSupplierSubmissionID' | 'RFQItemID' | 'UnitPrice' | 'Remarks' | 'IsSelected' | 'Cheapest' | 'TotalPrice'>
        )>> }
      )> }
    )>>, RFQItem?: Maybe<Array<(
      { __typename?: 'RFQItemEntity' }
      & Pick<RfqItemEntity, 'RFQHeaderID' | 'RFQItemID'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
      )>, PRItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ItemID' | 'PRItemID' | 'PRHeaderID' | 'Remark' | 'Quantity' | 'UOMID'>
        & { UOM?: Maybe<(
          { __typename?: 'AccUOMEntity' }
          & Pick<AccUomEntity, 'UOMID' | 'Code' | 'Name'>
        )>, Item?: Maybe<(
          { __typename?: 'ItemEntity' }
          & Pick<ItemEntity, 'ItemID' | 'Name' | 'LatestPurchase'>
        )> }
      )>, RFQSubmissionItem?: Maybe<Array<(
        { __typename?: 'RFQSubmissionItemEntity' }
        & Pick<RfqSubmissionItemEntity, 'RFQSubmissionItemID' | 'RFQSupplierSubmissionID' | 'RFQItemID' | 'UnitPrice' | 'Remarks' | 'IsSelected' | 'Cheapest'>
      )>> }
    )>> }
  )> }
);

export type GetPrNoListQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  PRHeaderID?: Maybe<Scalars['String']>;
}>;


export type GetPrNoListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPRNoList'>
);

export type LatestOpenPeriodCheckingDateQueryVariables = Exact<{
  CompanyID: Scalars['String'];
}>;


export type LatestOpenPeriodCheckingDateQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'latestOpenPeriodCheckingDate'>
);

export type GetScheduledPurchasesQueryVariables = Exact<{
  ScheduledPurchasesID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  DeliveryLocationID?: Maybe<Scalars['String']>;
}>;


export type GetScheduledPurchasesQuery = (
  { __typename?: 'Query' }
  & { getScheduledPurchases: Array<(
    { __typename?: 'ScheduledPurchasesEntity' }
    & Pick<ScheduledPurchasesEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'RecordStatus' | 'ScheduledPurchasesID' | 'CompanyID' | 'DeliveryLocationID' | 'Title' | 'ScheduledDate' | 'ScheduledFrequency' | 'Description'>
    & { DeliveryLocation?: Maybe<(
      { __typename?: 'DeliveryLocationEntity' }
      & Pick<DeliveryLocationEntity, 'Name' | 'Address'>
    )>, ScheduledPurchasesItem?: Maybe<Array<(
      { __typename?: 'ScheduledPurchasesItemEntity' }
      & Pick<ScheduledPurchasesItemEntity, 'ScheduledPurchasesItemID' | 'ItemID' | 'SupplierID' | 'CostCentreID' | 'WarehouseID' | 'Quantity' | 'UOMID' | 'UnitPrice' | 'TotalAmt' | 'TaxAmt' | 'TaxSchemeID' | 'Remarks' | 'UOMExchangeList'>
      & { Item?: Maybe<(
        { __typename?: 'ItemEntity' }
        & Pick<ItemEntity, 'Name' | 'IsCentralised' | 'IsInventory'>
      )>, TaxScheme?: Maybe<(
        { __typename?: 'TaxSchemeEntity' }
        & Pick<TaxSchemeEntity, 'AccountID' | 'TaxSchemeID' | 'Code' | 'Description'>
      )>, Supplier?: Maybe<(
        { __typename?: 'CreditorAccountEntity' }
        & Pick<CreditorAccountEntity, 'CompanyName'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type CreateScheduledPurchasesMutationVariables = Exact<{
  input: ScheduledPurchasesInput;
}>;


export type CreateScheduledPurchasesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createScheduledPurchases'>
);

export type UpdateScheduledPurchasesMutationVariables = Exact<{
  input: ScheduledPurchasesInput;
  ScheduledPurchasesID: Scalars['String'];
}>;


export type UpdateScheduledPurchasesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateScheduledPurchases'>
);

export type CreatePreparePoMutationVariables = Exact<{
  input: Array<PreparePoInput> | PreparePoInput;
}>;


export type CreatePreparePoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPreparePO'>
);

export type DeleteScheduledPurchasesMutationVariables = Exact<{
  ScheduledPurchasesID: Scalars['String'];
}>;


export type DeleteScheduledPurchasesMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteScheduledPurchases'>
);

export type GetLatestTaxByTaxSchemeIDsQueryVariables = Exact<{
  taxSchemeIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type GetLatestTaxByTaxSchemeIDsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getLatestTaxByTaxSchemeIDs'>
);

export type GetUsersByAccountAndSoftwareQueryVariables = Exact<{
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
}>;


export type GetUsersByAccountAndSoftwareQuery = (
  { __typename?: 'Query' }
  & { getUsersByAccountAndSoftware: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'contactNo' | 'confirmed' | 'superUser' | 'commonStatus' | 'jobTitle' | 'userName' | 'blockCount'>
  )> }
);

export type GetApprovalPolicyQueryVariables = Exact<{
  Category: Scalars['String'];
  ApprovalPolicyID?: Maybe<Scalars['String']>;
}>;


export type GetApprovalPolicyQuery = (
  { __typename?: 'Query' }
  & { getApprovalPolicy: Array<(
    { __typename?: 'AccApprovalPolicyEntity' }
    & Pick<AccApprovalPolicyEntity, 'ApprovalPolicyID' | 'AccountID' | 'createdTs' | 'modTs' | 'lowerLimit' | 'upperLimit' | 'Category' | 'ApprovalList'>
  )> }
);

export type GetAllApprovalPolicyQueryVariables = Exact<{
  DifferentConnection: Scalars['String'];
  Category: Scalars['String'];
}>;


export type GetAllApprovalPolicyQuery = (
  { __typename?: 'Query' }
  & { getAllApprovalPolicy?: Maybe<Array<(
    { __typename?: 'AccApprovalPolicyEntity' }
    & Pick<AccApprovalPolicyEntity, 'ApprovalPolicyID' | 'createdTs' | 'modTs' | 'Category' | 'ApprovalList'>
  )>> }
);

export type GetApprovalPolicyAssignmentQueryVariables = Exact<{
  ApprovalPolicyAssignmentID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  Category?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetApprovalPolicyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getApprovalPolicyAssignment: Array<(
    { __typename?: 'AccApprovalPolicyAssignmentEntity' }
    & Pick<AccApprovalPolicyAssignmentEntity, 'ApprovalPolicyAssignmentID' | 'ApprovalPolicyID' | 'AccountID' | 'CompanyID' | 'Category' | 'ApprovalList' | 'createdTs' | 'modTs' | 'modBy' | 'lowerLimit' | 'upperLimit'>
  )> }
);

export type CreateApprovalPolicyMutationVariables = Exact<{
  input: ApprovalPolicyInput;
}>;


export type CreateApprovalPolicyMutation = (
  { __typename?: 'Mutation' }
  & { createApprovalPolicy: (
    { __typename?: 'AccApprovalPolicyEntity' }
    & Pick<AccApprovalPolicyEntity, 'ApprovalPolicyID'>
  ) }
);

export type CreateApprovalPolicyAssignmentMutationVariables = Exact<{
  input: ApprovalPolicyAssignmentInput;
}>;


export type CreateApprovalPolicyAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { createApprovalPolicyAssignment: (
    { __typename?: 'AccApprovalPolicyAssignmentEntity' }
    & Pick<AccApprovalPolicyAssignmentEntity, 'ApprovalPolicyAssignmentID'>
  ) }
);

export type UpdateApprovalPolicyMutationVariables = Exact<{
  input: ApprovalPolicyInput;
}>;


export type UpdateApprovalPolicyMutation = (
  { __typename?: 'Mutation' }
  & { updateApprovalPolicy: (
    { __typename?: 'AccApprovalPolicyEntity' }
    & Pick<AccApprovalPolicyEntity, 'ApprovalPolicyID'>
  ) }
);

export type UpdateApprovalPolicyAssignmentMutationVariables = Exact<{
  input: ApprovalPolicyAssignmentInput;
}>;


export type UpdateApprovalPolicyAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { updateApprovalPolicyAssignment: (
    { __typename?: 'AccApprovalPolicyAssignmentEntity' }
    & Pick<AccApprovalPolicyAssignmentEntity, 'ApprovalPolicyAssignmentID'>
  ) }
);

export type DeleteApprovalPolicyMutationVariables = Exact<{
  input: ApprovalPolicyDeleteInput;
}>;


export type DeleteApprovalPolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteApprovalPolicy'>
);

export type DeleteApprovalPolicyAssignmentMutationVariables = Exact<{
  input: ApprovalPolicyAssignmentDeleteInput;
}>;


export type DeleteApprovalPolicyAssignmentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteApprovalPolicyAssignment'>
);

export type GetBulkPurchasePriceQueryVariables = Exact<{
  ItemID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  BulkPurchasePriceHeaderID?: Maybe<Scalars['String']>;
}>;


export type GetBulkPurchasePriceQuery = (
  { __typename?: 'Query' }
  & { getBulkPurchasePrice: Array<(
    { __typename?: 'BulkPurchasePriceHeaderEntity' }
    & Pick<BulkPurchasePriceHeaderEntity, 'BulkPurchasePriceHeaderID' | 'SupplierID' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks'>
    & { Supplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CompanyName' | 'CompanyRegNo' | 'ContactNo' | 'Email' | 'CreditTerm' | 'TaxSchemeID'>
    )>, BulkPurchasePriceItem?: Maybe<Array<(
      { __typename?: 'BulkPurchasePriceItemEntity' }
      & Pick<BulkPurchasePriceItemEntity, 'BulkPurchasePriceItemID' | 'BulkPurchasePriceHeaderID' | 'ItemID' | 'UOMID'>
      & { Item?: Maybe<(
        { __typename?: 'ItemEntity' }
        & Pick<ItemEntity, 'Name' | 'Description'>
      )>, UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Code' | 'Name'>
      )>, BulkPurchasePriceDetail?: Maybe<Array<(
        { __typename?: 'BulkPurchasePriceDetailEntity' }
        & Pick<BulkPurchasePriceDetailEntity, 'LowerLimit' | 'UpperLimit' | 'UnitPrice'>
      )>> }
    )>> }
  )> }
);

export type CreateBulkPurchasePriceMutationVariables = Exact<{
  input: BulkPurchasePriceHeaderInput;
  bulkPurchaseItemInput: Array<BulkPurchasePriceItemInput> | BulkPurchasePriceItemInput;
}>;


export type CreateBulkPurchasePriceMutation = (
  { __typename?: 'Mutation' }
  & { createBulkPurchasePrice: (
    { __typename?: 'BulkPurchasePriceHeaderEntity' }
    & Pick<BulkPurchasePriceHeaderEntity, 'SupplierID' | 'EffectiveDate' | 'ExpiryDate' | 'Remarks'>
    & { BulkPurchasePriceItem?: Maybe<Array<(
      { __typename?: 'BulkPurchasePriceItemEntity' }
      & Pick<BulkPurchasePriceItemEntity, 'ItemID' | 'UOMID'>
      & { BulkPurchasePriceDetail?: Maybe<Array<(
        { __typename?: 'BulkPurchasePriceDetailEntity' }
        & Pick<BulkPurchasePriceDetailEntity, 'LowerLimit' | 'UpperLimit' | 'UnitPrice'>
      )>> }
    )>> }
  ) }
);

export type UpdateBulkPurchasePriceMutationVariables = Exact<{
  BulkPurchasePriceHeaderID: Scalars['String'];
  input: BulkPurchasePriceHeaderInput;
  bulkPurchaseItemInput: Array<BulkPurchasePriceItemInput> | BulkPurchasePriceItemInput;
}>;


export type UpdateBulkPurchasePriceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateBulkPurchasePrice'>
);

export type DeleteBulkPurchasePriceMutationVariables = Exact<{
  BulkPurchasePriceHeaderID: Scalars['String'];
}>;


export type DeleteBulkPurchasePriceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBulkPurchasePrice'>
);

export type GetGeneralSettingSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGeneralSettingSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'activeSupplierCategoryCount' | 'activeSupplierProfileCount' | 'activeUomCount' | 'lastUomExchange' | 'activePurchaseCategoryCount' | 'activeItemCount' | 'activeBulkPurchaseCount' | 'lastPurchaseVariance' | 'purchaseVariancePercenetage'>
);

export type GetRoleWithAccountQueryVariables = Exact<{
  DifferentConnection?: Maybe<Scalars['String']>;
}>;


export type GetRoleWithAccountQuery = (
  { __typename?: 'Query' }
  & { getRoleWithAccount: Array<(
    { __typename?: 'AccRoleEntity' }
    & Pick<AccRoleEntity, 'ID' | 'accountID' | 'name' | 'createdTs' | 'createdBy' | 'modTs'>
  )> }
);

export type GetItemQueryVariables = Exact<{
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ItemID?: Maybe<Scalars['String']>;
  SupplierID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetItemQuery = (
  { __typename?: 'Query' }
  & { getItem: Array<(
    { __typename?: 'ItemEntity' }
    & Pick<ItemEntity, 'ItemID' | 'ItemCategoryID' | 'AccountID' | 'Name' | 'Description' | 'IsCentralised' | 'IsInventory' | 'RecordStatus' | 'DefaultSupplierID' | 'UOMID' | 'TaxSchemeID' | 'Remark' | 'VariancePerc' | 'IsUse' | 'UsedInIC'>
    & { DefaultSupplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName' | 'CompanyRegNo'>
    )>, PRItem?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'PRItemID'>
    )>>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
    )>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code' | 'Description' | 'AccTaxCategory'>
    )>, ItemCategory?: Maybe<(
      { __typename?: 'ItemCategoryEntity' }
      & Pick<ItemCategoryEntity, 'Name'>
    )> }
  )> }
);

export type DeleteItemMutationVariables = Exact<{
  ItemID: Scalars['String'];
}>;


export type DeleteItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteItem'>
);

export type GetCheckingValidationItemQueryVariables = Exact<{
  ItemExcelInput: Array<ItemExcelInput> | ItemExcelInput;
}>;


export type GetCheckingValidationItemQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCheckingValidationItem'>
);

export type CreateItemMutationVariables = Exact<{
  input: ItemInput;
}>;


export type CreateItemMutation = (
  { __typename?: 'Mutation' }
  & { createItem: (
    { __typename?: 'ItemEntity' }
    & Pick<ItemEntity, 'ItemID' | 'createdBy'>
  ) }
);

export type UpdateItemMutationVariables = Exact<{
  input: ItemInput;
}>;


export type UpdateItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateItem'>
);

export type CreateBatchItemMutationVariables = Exact<{
  input: Array<ItemExcelInput> | ItemExcelInput;
}>;


export type CreateBatchItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBatchItem'>
);

export type CancelItemMutationVariables = Exact<{
  ItemID: Scalars['String'];
}>;


export type CancelItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelItem'>
);

export type GetApItemMappingQueryVariables = Exact<{
  ExpenseItemID?: Maybe<Scalars['String']>;
  UOMID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetApItemMappingQuery = (
  { __typename?: 'Query' }
  & { getExpenseItem: Array<(
    { __typename?: 'ExpenseItemEntity' }
    & Pick<ExpenseItemEntity, 'ExpenseItemID' | 'ExpenseClassID' | 'ItemName' | 'Description' | 'Remark' | 'TaxSchemeID' | 'TaxRate'>
  )> }
);

export type GetWarehouseByStockItemQueryVariables = Exact<{
  StockItemID: Scalars['String'];
}>;


export type GetWarehouseByStockItemQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWarehouseByStockItem'>
);

export type GetItemByCompanyAssignmentQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetItemByCompanyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getItemByCompanyAssignment: Array<(
    { __typename?: 'ItemEntity' }
    & Pick<ItemEntity, 'ItemID' | 'ItemCategoryID' | 'AccountID' | 'Name' | 'Description' | 'IsCentralised' | 'IsInventory' | 'DefaultSupplierID' | 'UOMID' | 'TaxSchemeID' | 'VariancePerc'>
    & { DefaultSupplier?: Maybe<(
      { __typename?: 'CreditorAccountEntity' }
      & Pick<CreditorAccountEntity, 'CreditorAccountID' | 'CompanyName' | 'CompanyRegNo'>
    )>, PRItem?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'PRItemID'>
    )>>, UOM?: Maybe<(
      { __typename?: 'AccUOMEntity' }
      & Pick<AccUomEntity, 'UOMID' | 'Name' | 'Code'>
    )>, TaxScheme?: Maybe<(
      { __typename?: 'TaxSchemeEntity' }
      & Pick<TaxSchemeEntity, 'TaxSchemeID' | 'Code' | 'Description' | 'AccTaxCategory'>
    )> }
  )> }
);

export type GetItemCategoryQueryVariables = Exact<{
  AccountID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetItemCategoryQuery = (
  { __typename?: 'Query' }
  & { getItemCategory: Array<(
    { __typename?: 'ItemCategoryEntity' }
    & Pick<ItemCategoryEntity, 'ItemCategoryID' | 'Name' | 'ParentItemCategoryID' | 'Level' | 'IsLastNode' | 'MasterCOAID'>
    & { MasterCOA?: Maybe<(
      { __typename?: 'MasterCOAEntity' }
      & Pick<MasterCoaEntity, 'MasterCOAID' | 'Code' | 'Name'>
    )>, Item?: Maybe<Array<(
      { __typename?: 'ItemEntity' }
      & Pick<ItemEntity, 'ItemID' | 'ItemCategoryID' | 'UOMID' | 'Name' | 'Description' | 'TaxSchemeID' | 'AccountID'>
      & { UOM?: Maybe<(
        { __typename?: 'AccUOMEntity' }
        & Pick<AccUomEntity, 'Name' | 'Code'>
      )> }
    )>> }
  )> }
);

export type GetItemCategoryCompanyAssignmentQueryVariables = Exact<{
  ItemCategoryCompanyAssignmentID?: Maybe<Scalars['String']>;
  CompanyID?: Maybe<Scalars['String']>;
  ItemCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetItemCategoryCompanyAssignmentQuery = (
  { __typename?: 'Query' }
  & { getItemCategoryCompanyAssignment: Array<(
    { __typename?: 'ItemCategoryCompanyAssignmentEntity' }
    & Pick<ItemCategoryCompanyAssignmentEntity, 'ItemCategoryCompanyAssignmentID' | 'ItemCategoryID' | 'CompanyID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'RecordStatus'>
    & { ItemCategory?: Maybe<(
      { __typename?: 'ItemCategoryEntity' }
      & Pick<ItemCategoryEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ItemCategoryID' | 'MasterCOAID' | 'RecordStatus' | 'Name'>
    )> }
  )> }
);

export type CreateItemCategoryMutationVariables = Exact<{
  ItemCategoryInput: ItemCategoryInput;
}>;


export type CreateItemCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createItemCategory: (
    { __typename?: 'ItemCategoryEntity' }
    & Pick<ItemCategoryEntity, 'ItemCategoryID' | 'createdBy' | 'Name' | 'MasterCOAID'>
  ) }
);

export type UpdateItemCategoryMutationVariables = Exact<{
  ItemCategoryInput: ItemCategoryInput;
}>;


export type UpdateItemCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateItemCategory'>
);

export type DeleteItemCategoryMutationVariables = Exact<{
  ItemCategoryID: Scalars['String'];
}>;


export type DeleteItemCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteItemCategory'>
);

export type AssignItemCategoryCompanyMutationVariables = Exact<{
  input: ItemCategoryCompanyAssignmentInput;
}>;


export type AssignItemCategoryCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'AssignItemCategoryCompany'>
);

export type UnassignItemCategoryCompanyMutationVariables = Exact<{
  input: ItemCategoryCompanyAssignmentInput;
}>;


export type UnassignItemCategoryCompanyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UnassignItemCategoryCompany'>
);

export type GetDocNumQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Type?: Maybe<DocumentType>;
  RefTable?: Maybe<Scalars['String']>;
}>;


export type GetDocNumQuery = (
  { __typename?: 'Query' }
  & { getDocumentNumberHeader: Array<(
    { __typename?: 'DocumentNumberHeaderEntity' }
    & Pick<DocumentNumberHeaderEntity, 'DocumentNumberHeaderID' | 'SampleOutput' | 'IsAutoDocNo' | 'DocNumGenerated'>
    & { DocumentNumberDetail?: Maybe<Array<(
      { __typename?: 'DocumentNumberDetailEntity' }
      & Pick<DocumentNumberDetailEntity, 'DocumentNumberDetailID' | 'Sequence' | 'FormatType' | 'FormatValue' | 'NextNumber' | 'StartFrom' | 'ResetBy'>
    )>> }
  )>, getDocumentNumberDetail: Array<(
    { __typename?: 'DocumentNumberDetailEntity' }
    & Pick<DocumentNumberDetailEntity, 'Sequence' | 'FormatType' | 'FormatValue' | 'NextNumber' | 'StartFrom' | 'ResetBy'>
  )> }
);

export type GetDocumentNumberHeaderQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Type?: Maybe<DocumentType>;
  RefTable?: Maybe<Scalars['String']>;
}>;


export type GetDocumentNumberHeaderQuery = (
  { __typename?: 'Query' }
  & { getDocumentNumberHeader: Array<(
    { __typename?: 'DocumentNumberHeaderEntity' }
    & Pick<DocumentNumberHeaderEntity, 'DocumentNumberHeaderID' | 'SampleOutput' | 'IsAutoDocNo' | 'DocNumGenerated' | 'RefTable'>
  )> }
);

export type GetDocumentNumberDetailQueryVariables = Exact<{
  DocumentNumberHeaderID: Scalars['String'];
}>;


export type GetDocumentNumberDetailQuery = (
  { __typename?: 'Query' }
  & { getDocumentNumberDetail: Array<(
    { __typename?: 'DocumentNumberDetailEntity' }
    & Pick<DocumentNumberDetailEntity, 'DocumentNumberDetailID' | 'Sequence' | 'FormatType' | 'FormatValue' | 'NextNumber' | 'StartFrom' | 'ResetBy'>
  )> }
);

export type GetDocNumTitleQueryVariables = Exact<{
  CompanyID: Scalars['String'];
  Type?: Maybe<DocumentType>;
  RefTable?: Maybe<Scalars['String']>;
}>;


export type GetDocNumTitleQuery = (
  { __typename?: 'Query' }
  & { getDocumentNumberHeader: Array<(
    { __typename?: 'DocumentNumberHeaderEntity' }
    & Pick<DocumentNumberHeaderEntity, 'SampleOutput' | 'DocumentNumberHeaderID' | 'Type' | 'RefTable' | 'IsAutoDocNo' | 'DocNumGenerated'>
  )> }
);

export type UpdateDocumentNumberHeaderMutationVariables = Exact<{
  DocumentNumberDetailInput: Array<DocumentNumberDetailInput> | DocumentNumberDetailInput;
  DocumentNumberHeaderInput: DocumentNumberHeaderInput;
  DifferentConnection?: Maybe<Scalars['String']>;
}>;


export type UpdateDocumentNumberHeaderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDocumentNumberHeader'>
);

export type UpdateIsAutoDocNoMutationVariables = Exact<{
  CompanyID: Scalars['String'];
  RefTable: Scalars['String'];
  IsAutoDocNo: Scalars['Boolean'];
  input: DocumentNumberHeaderInput;
}>;


export type UpdateIsAutoDocNoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateIsAutoDocNo'>
);

export type GetPurchaseItemVariancePolicyQueryVariables = Exact<{
  PurchaseItemVariancePolicyID?: Maybe<Scalars['String']>;
}>;


export type GetPurchaseItemVariancePolicyQuery = (
  { __typename?: 'Query' }
  & { getPurchaseItemVariancePolicy: Array<(
    { __typename?: 'PurchaseItemVariancePolicyEntity' }
    & Pick<PurchaseItemVariancePolicyEntity, 'PurchaseItemVariancePolicyID' | 'VariancePerc'>
  )> }
);

export type CreatePurchaseItemVariancePolicyMutationVariables = Exact<{
  PurchaseItemVariancePolicyInput: PurchaseItemVariancePolicyInput;
}>;


export type CreatePurchaseItemVariancePolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPurchaseItemVariancePolicy'>
);

export type UpdatePurchaseItemVariancePolicyMutationVariables = Exact<{
  PurchaseItemVariancePolicyID: Scalars['String'];
  PurchaseItemVariancePolicyInput: PurchaseItemVariancePolicyInput;
}>;


export type UpdatePurchaseItemVariancePolicyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePurchaseItemVariancePolicy'>
);

export type GetRoleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetRoleQuery = (
  { __typename?: 'Query' }
  & { getRole: Array<(
    { __typename?: 'AccRoleEntity' }
    & Pick<AccRoleEntity, 'ID' | 'name'>
  )> }
);

export type GetHomeSummaryQueryVariables = Exact<{
  CompanyID?: Maybe<Scalars['String']>;
}>;


export type GetHomeSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'scheduledPurchaseInfoCount' | 'prInfoCount' | 'rfqCount' | 'poInfoCount' | 'doInfoCount' | 'lastDODate' | 'grtnInfoCount' | 'lastGRTNDate' | 'getRolePermission' | 'getAccountPermission'>
  & { procurementLoggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'avatar' | 'name' | 'email' | 'contactNo' | 'department' | 'superUser' | 'userName' | 'jobTitle' | 'lastestAccessedEntity'>
  ) }
);


export const LoggedInUserProfileDocument = gql`
    query loggedInUserProfile {
  loggedInUserProfile {
    ID
    accountID
    avatar
    name
    email
    contactNo
    department
    superUser
    userName
    jobTitle
  }
}
    `;

/**
 * __useLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
      }
export function useLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>(LoggedInUserProfileDocument, baseOptions);
        }
export type LoggedInUserProfileQueryHookResult = ReturnType<typeof useLoggedInUserProfileQuery>;
export type LoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInUserProfileLazyQuery>;
export type LoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInUserProfileQuery, LoggedInUserProfileQueryVariables>;
export const ProcurementLoggedInUserProfileDocument = gql`
    query procurementLoggedInUserProfile {
  procurementLoggedInUserProfile {
    ID
    accountID
    avatar
    name
    email
    contactNo
    department
    superUser
    userName
    jobTitle
    lastestAccessedEntity
  }
}
    `;

/**
 * __useProcurementLoggedInUserProfileQuery__
 *
 * To run a query within a React component, call `useProcurementLoggedInUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useProcurementLoggedInUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProcurementLoggedInUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useProcurementLoggedInUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProcurementLoggedInUserProfileQuery, ProcurementLoggedInUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<ProcurementLoggedInUserProfileQuery, ProcurementLoggedInUserProfileQueryVariables>(ProcurementLoggedInUserProfileDocument, baseOptions);
      }
export function useProcurementLoggedInUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProcurementLoggedInUserProfileQuery, ProcurementLoggedInUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProcurementLoggedInUserProfileQuery, ProcurementLoggedInUserProfileQueryVariables>(ProcurementLoggedInUserProfileDocument, baseOptions);
        }
export type ProcurementLoggedInUserProfileQueryHookResult = ReturnType<typeof useProcurementLoggedInUserProfileQuery>;
export type ProcurementLoggedInUserProfileLazyQueryHookResult = ReturnType<typeof useProcurementLoggedInUserProfileLazyQuery>;
export type ProcurementLoggedInUserProfileQueryResult = ApolloReactCommon.QueryResult<ProcurementLoggedInUserProfileQuery, ProcurementLoggedInUserProfileQueryVariables>;
export const ProcurementLoginDocument = gql`
    mutation procurementLogin($loginId: String!, $password: String!) {
  procurementLogin(loginId: $loginId, password: $password) {
    accessToken
    user {
      ID
      name
      userName
      email
      contactNo
      superUser
      department
      jobTitle
      avatar
      address {
        address
        country
        city
        district
        state
        postCode
      }
      lastestAccessedEntity
    }
  }
}
    `;
export type ProcurementLoginMutationFn = ApolloReactCommon.MutationFunction<ProcurementLoginMutation, ProcurementLoginMutationVariables>;

/**
 * __useProcurementLoginMutation__
 *
 * To run a mutation, you first call `useProcurementLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcurementLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [procurementLoginMutation, { data, loading, error }] = useProcurementLoginMutation({
 *   variables: {
 *      loginId: // value for 'loginId'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useProcurementLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ProcurementLoginMutation, ProcurementLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<ProcurementLoginMutation, ProcurementLoginMutationVariables>(ProcurementLoginDocument, baseOptions);
      }
export type ProcurementLoginMutationHookResult = ReturnType<typeof useProcurementLoginMutation>;
export type ProcurementLoginMutationResult = ApolloReactCommon.MutationResult<ProcurementLoginMutation>;
export type ProcurementLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<ProcurementLoginMutation, ProcurementLoginMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($userID: String, $password: String!, $token: String!) {
  resetPassword(userID: $userID, password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout($ID: String) {
  logout(ID: $ID)
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activateUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const CreatePasswordDocument = gql`
    mutation createPassword($input: passwordInfo!) {
  createPassword(input: $input)
}
    `;
export type CreatePasswordMutationFn = ApolloReactCommon.MutationFunction<CreatePasswordMutation, CreatePasswordMutationVariables>;

/**
 * __useCreatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordMutation, { data, loading, error }] = useCreatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePasswordMutation, CreatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePasswordMutation, CreatePasswordMutationVariables>(CreatePasswordDocument, baseOptions);
      }
export type CreatePasswordMutationHookResult = ReturnType<typeof useCreatePasswordMutation>;
export type CreatePasswordMutationResult = ApolloReactCommon.MutationResult<CreatePasswordMutation>;
export type CreatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePasswordMutation, CreatePasswordMutationVariables>;
export const GetCompanySettingSummaryDocument = gql`
    query getCompanySettingSummary($CompanyID: String) {
  lastApprovalPolicy(CompanyID: $CompanyID)
  lastNumberingStructure(CompanyID: $CompanyID)
  activeDeliveryLocationCount(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetCompanySettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetCompanySettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanySettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanySettingSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCompanySettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanySettingSummaryQuery, GetCompanySettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanySettingSummaryQuery, GetCompanySettingSummaryQueryVariables>(GetCompanySettingSummaryDocument, baseOptions);
      }
export function useGetCompanySettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanySettingSummaryQuery, GetCompanySettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanySettingSummaryQuery, GetCompanySettingSummaryQueryVariables>(GetCompanySettingSummaryDocument, baseOptions);
        }
export type GetCompanySettingSummaryQueryHookResult = ReturnType<typeof useGetCompanySettingSummaryQuery>;
export type GetCompanySettingSummaryLazyQueryHookResult = ReturnType<typeof useGetCompanySettingSummaryLazyQuery>;
export type GetCompanySettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetCompanySettingSummaryQuery, GetCompanySettingSummaryQueryVariables>;
export const GetDeliveryLocationDocument = gql`
    query getDeliveryLocation($CompanyID: String, $orderByAsc: String) {
  getDeliveryLocation(CompanyID: $CompanyID, orderByAsc: $orderByAsc) {
    DeliveryLocationID
    Name
    Address
    ContactNo
    MainLocation
    CompanyID
  }
}
    `;

/**
 * __useGetDeliveryLocationQuery__
 *
 * To run a query within a React component, call `useGetDeliveryLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryLocationQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetDeliveryLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDeliveryLocationQuery, GetDeliveryLocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDeliveryLocationQuery, GetDeliveryLocationQueryVariables>(GetDeliveryLocationDocument, baseOptions);
      }
export function useGetDeliveryLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDeliveryLocationQuery, GetDeliveryLocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDeliveryLocationQuery, GetDeliveryLocationQueryVariables>(GetDeliveryLocationDocument, baseOptions);
        }
export type GetDeliveryLocationQueryHookResult = ReturnType<typeof useGetDeliveryLocationQuery>;
export type GetDeliveryLocationLazyQueryHookResult = ReturnType<typeof useGetDeliveryLocationLazyQuery>;
export type GetDeliveryLocationQueryResult = ApolloReactCommon.QueryResult<GetDeliveryLocationQuery, GetDeliveryLocationQueryVariables>;
export const CreateDeliveryLocationDocument = gql`
    mutation createDeliveryLocation($input: DeliveryLocationInput!) {
  createDeliveryLoc(input: $input)
}
    `;
export type CreateDeliveryLocationMutationFn = ApolloReactCommon.MutationFunction<CreateDeliveryLocationMutation, CreateDeliveryLocationMutationVariables>;

/**
 * __useCreateDeliveryLocationMutation__
 *
 * To run a mutation, you first call `useCreateDeliveryLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDeliveryLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDeliveryLocationMutation, { data, loading, error }] = useCreateDeliveryLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDeliveryLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDeliveryLocationMutation, CreateDeliveryLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDeliveryLocationMutation, CreateDeliveryLocationMutationVariables>(CreateDeliveryLocationDocument, baseOptions);
      }
export type CreateDeliveryLocationMutationHookResult = ReturnType<typeof useCreateDeliveryLocationMutation>;
export type CreateDeliveryLocationMutationResult = ApolloReactCommon.MutationResult<CreateDeliveryLocationMutation>;
export type CreateDeliveryLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDeliveryLocationMutation, CreateDeliveryLocationMutationVariables>;
export const UpdateDeliveryLocationDocument = gql`
    mutation updateDeliveryLocation($input: DeliveryLocationInput!) {
  updateDeliveryLoc(input: $input)
}
    `;
export type UpdateDeliveryLocationMutationFn = ApolloReactCommon.MutationFunction<UpdateDeliveryLocationMutation, UpdateDeliveryLocationMutationVariables>;

/**
 * __useUpdateDeliveryLocationMutation__
 *
 * To run a mutation, you first call `useUpdateDeliveryLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDeliveryLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDeliveryLocationMutation, { data, loading, error }] = useUpdateDeliveryLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDeliveryLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDeliveryLocationMutation, UpdateDeliveryLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDeliveryLocationMutation, UpdateDeliveryLocationMutationVariables>(UpdateDeliveryLocationDocument, baseOptions);
      }
export type UpdateDeliveryLocationMutationHookResult = ReturnType<typeof useUpdateDeliveryLocationMutation>;
export type UpdateDeliveryLocationMutationResult = ApolloReactCommon.MutationResult<UpdateDeliveryLocationMutation>;
export type UpdateDeliveryLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDeliveryLocationMutation, UpdateDeliveryLocationMutationVariables>;
export const DeleteDeliveryLocationDocument = gql`
    mutation deleteDeliveryLocation($input: DeliveryLocationDeleteInput!) {
  deleteDeliveryLocation(input: $input)
}
    `;
export type DeleteDeliveryLocationMutationFn = ApolloReactCommon.MutationFunction<DeleteDeliveryLocationMutation, DeleteDeliveryLocationMutationVariables>;

/**
 * __useDeleteDeliveryLocationMutation__
 *
 * To run a mutation, you first call `useDeleteDeliveryLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeliveryLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeliveryLocationMutation, { data, loading, error }] = useDeleteDeliveryLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteDeliveryLocationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDeliveryLocationMutation, DeleteDeliveryLocationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDeliveryLocationMutation, DeleteDeliveryLocationMutationVariables>(DeleteDeliveryLocationDocument, baseOptions);
      }
export type DeleteDeliveryLocationMutationHookResult = ReturnType<typeof useDeleteDeliveryLocationMutation>;
export type DeleteDeliveryLocationMutationResult = ApolloReactCommon.MutationResult<DeleteDeliveryLocationMutation>;
export type DeleteDeliveryLocationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDeliveryLocationMutation, DeleteDeliveryLocationMutationVariables>;
export const GetBankProfileDocument = gql`
    query getBankProfile($AccountID: String) {
  getBankProfile(AccountID: $AccountID) {
    BankProfileID
    Name
    Address
    ContactNo
    Email
  }
}
    `;

/**
 * __useGetBankProfileQuery__
 *
 * To run a query within a React component, call `useGetBankProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBankProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBankProfileQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetBankProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBankProfileQuery, GetBankProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBankProfileQuery, GetBankProfileQueryVariables>(GetBankProfileDocument, baseOptions);
      }
export function useGetBankProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBankProfileQuery, GetBankProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBankProfileQuery, GetBankProfileQueryVariables>(GetBankProfileDocument, baseOptions);
        }
export type GetBankProfileQueryHookResult = ReturnType<typeof useGetBankProfileQuery>;
export type GetBankProfileLazyQueryHookResult = ReturnType<typeof useGetBankProfileLazyQuery>;
export type GetBankProfileQueryResult = ApolloReactCommon.QueryResult<GetBankProfileQuery, GetBankProfileQueryVariables>;
export const GetCompanyDocument = gql`
    query getCompany($CompanyID: String, $ParentCompanyID: String, $orderByAsc: String, $RecordStatus: RecordStatus) {
  getCompany(CompanyID: $CompanyID, ParentCompanyID: $ParentCompanyID, orderByAsc: $orderByAsc, RecordStatus: $RecordStatus) {
    CompanyID
    ParentCompanyID
    Name
    Code
    CompanyRegNo
    CompanyTax
    SSTNo
    GSTNo
    GSTExpiryDate
    Address
    ContactNo
    BaseCurrencyID
    Location {
      DeliveryLocationID
      Name
    }
  }
}
    `;

/**
 * __useGetCompanyQuery__
 *
 * To run a query within a React component, call `useGetCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ParentCompanyID: // value for 'ParentCompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
      }
export function useGetCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyQuery, GetCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyQuery, GetCompanyQueryVariables>(GetCompanyDocument, baseOptions);
        }
export type GetCompanyQueryHookResult = ReturnType<typeof useGetCompanyQuery>;
export type GetCompanyLazyQueryHookResult = ReturnType<typeof useGetCompanyLazyQuery>;
export type GetCompanyQueryResult = ApolloReactCommon.QueryResult<GetCompanyQuery, GetCompanyQueryVariables>;
export const GetParentCompanyDocument = gql`
    query getParentCompany {
  getParentCompany {
    CompanyID
    Name
  }
}
    `;

/**
 * __useGetParentCompanyQuery__
 *
 * To run a query within a React component, call `useGetParentCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParentCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetParentCompanyQuery, GetParentCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetParentCompanyQuery, GetParentCompanyQueryVariables>(GetParentCompanyDocument, baseOptions);
      }
export function useGetParentCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParentCompanyQuery, GetParentCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetParentCompanyQuery, GetParentCompanyQueryVariables>(GetParentCompanyDocument, baseOptions);
        }
export type GetParentCompanyQueryHookResult = ReturnType<typeof useGetParentCompanyQuery>;
export type GetParentCompanyLazyQueryHookResult = ReturnType<typeof useGetParentCompanyLazyQuery>;
export type GetParentCompanyQueryResult = ApolloReactCommon.QueryResult<GetParentCompanyQuery, GetParentCompanyQueryVariables>;
export const GetCompanyNameDocument = gql`
    query getCompanyName($CompanyID: String, $orderByAsc: String, $take: Float, $RecordStatus: RecordStatus) {
  getCompany(CompanyID: $CompanyID, orderByAsc: $orderByAsc, take: $take, RecordStatus: $RecordStatus) {
    CompanyID
    ParentCompanyID
    Name
    Code
    CompanyRegNo
    Address
    CompanyTax
    GSTNo
    SSTNo
    RecordStatus
  }
}
    `;

/**
 * __useGetCompanyNameQuery__
 *
 * To run a query within a React component, call `useGetCompanyNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyNameQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      take: // value for 'take'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetCompanyNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyNameQuery, GetCompanyNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyNameQuery, GetCompanyNameQueryVariables>(GetCompanyNameDocument, baseOptions);
      }
export function useGetCompanyNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyNameQuery, GetCompanyNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyNameQuery, GetCompanyNameQueryVariables>(GetCompanyNameDocument, baseOptions);
        }
export type GetCompanyNameQueryHookResult = ReturnType<typeof useGetCompanyNameQuery>;
export type GetCompanyNameLazyQueryHookResult = ReturnType<typeof useGetCompanyNameLazyQuery>;
export type GetCompanyNameQueryResult = ApolloReactCommon.QueryResult<GetCompanyNameQuery, GetCompanyNameQueryVariables>;
export const GetAssignedCompanyDocument = gql`
    query getAssignedCompany {
  getAssignedCompanyByUser
}
    `;

/**
 * __useGetAssignedCompanyQuery__
 *
 * To run a query within a React component, call `useGetAssignedCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignedCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignedCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAssignedCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>(GetAssignedCompanyDocument, baseOptions);
      }
export function useGetAssignedCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>(GetAssignedCompanyDocument, baseOptions);
        }
export type GetAssignedCompanyQueryHookResult = ReturnType<typeof useGetAssignedCompanyQuery>;
export type GetAssignedCompanyLazyQueryHookResult = ReturnType<typeof useGetAssignedCompanyLazyQuery>;
export type GetAssignedCompanyQueryResult = ApolloReactCommon.QueryResult<GetAssignedCompanyQuery, GetAssignedCompanyQueryVariables>;
export const GetActiveCompanyCountDocument = gql`
    query getActiveCompanyCount {
  getActiveCompanyCount
}
    `;

/**
 * __useGetActiveCompanyCountQuery__
 *
 * To run a query within a React component, call `useGetActiveCompanyCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveCompanyCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveCompanyCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveCompanyCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>(GetActiveCompanyCountDocument, baseOptions);
      }
export function useGetActiveCompanyCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>(GetActiveCompanyCountDocument, baseOptions);
        }
export type GetActiveCompanyCountQueryHookResult = ReturnType<typeof useGetActiveCompanyCountQuery>;
export type GetActiveCompanyCountLazyQueryHookResult = ReturnType<typeof useGetActiveCompanyCountLazyQuery>;
export type GetActiveCompanyCountQueryResult = ApolloReactCommon.QueryResult<GetActiveCompanyCountQuery, GetActiveCompanyCountQueryVariables>;
export const GetCompanyNameWithAccountDocument = gql`
    query getCompanyNameWithAccount($CompanyID: String, $isSuperUser: Boolean!, $userID: String!, $accountID: String!, $orderByAsc: String) {
  getCompanyNameWithAccount(CompanyID: $CompanyID, isSuperUser: $isSuperUser, userID: $userID, accountID: $accountID, orderByAsc: $orderByAsc)
}
    `;

/**
 * __useGetCompanyNameWithAccountQuery__
 *
 * To run a query within a React component, call `useGetCompanyNameWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyNameWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyNameWithAccountQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      isSuperUser: // value for 'isSuperUser'
 *      userID: // value for 'userID'
 *      accountID: // value for 'accountID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCompanyNameWithAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCompanyNameWithAccountQuery, GetCompanyNameWithAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCompanyNameWithAccountQuery, GetCompanyNameWithAccountQueryVariables>(GetCompanyNameWithAccountDocument, baseOptions);
      }
export function useGetCompanyNameWithAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCompanyNameWithAccountQuery, GetCompanyNameWithAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCompanyNameWithAccountQuery, GetCompanyNameWithAccountQueryVariables>(GetCompanyNameWithAccountDocument, baseOptions);
        }
export type GetCompanyNameWithAccountQueryHookResult = ReturnType<typeof useGetCompanyNameWithAccountQuery>;
export type GetCompanyNameWithAccountLazyQueryHookResult = ReturnType<typeof useGetCompanyNameWithAccountLazyQuery>;
export type GetCompanyNameWithAccountQueryResult = ApolloReactCommon.QueryResult<GetCompanyNameWithAccountQuery, GetCompanyNameWithAccountQueryVariables>;
export const GetCostCentreDocument = gql`
    query getCostCentre($CompanyID: String, $CostCentreID: String, $MasterCOAID: String, $AccountID: String, $orderByAsc: String, $IsLastNode: Boolean, $IsDefault: Boolean) {
  getCostCentre(CompanyID: $CompanyID, CostCentreID: $CostCentreID, AccountID: $AccountID, MasterCOAID: $MasterCOAID, orderByAsc: $orderByAsc, IsLastNode: $IsLastNode, IsDefault: $IsDefault) {
    CostCentreID
    Name
    CompanyID
    ParentCostCentreID
    Level
    Code
    MasterCode
    IsLastNode
    IsDefault
  }
}
    `;

/**
 * __useGetCostCentreQuery__
 *
 * To run a query within a React component, call `useGetCostCentreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CostCentreID: // value for 'CostCentreID'
 *      MasterCOAID: // value for 'MasterCOAID'
 *      AccountID: // value for 'AccountID'
 *      orderByAsc: // value for 'orderByAsc'
 *      IsLastNode: // value for 'IsLastNode'
 *      IsDefault: // value for 'IsDefault'
 *   },
 * });
 */
export function useGetCostCentreQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreQuery, GetCostCentreQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreQuery, GetCostCentreQueryVariables>(GetCostCentreDocument, baseOptions);
      }
export function useGetCostCentreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreQuery, GetCostCentreQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreQuery, GetCostCentreQueryVariables>(GetCostCentreDocument, baseOptions);
        }
export type GetCostCentreQueryHookResult = ReturnType<typeof useGetCostCentreQuery>;
export type GetCostCentreLazyQueryHookResult = ReturnType<typeof useGetCostCentreLazyQuery>;
export type GetCostCentreQueryResult = ApolloReactCommon.QueryResult<GetCostCentreQuery, GetCostCentreQueryVariables>;
export const GetCostCentreListingDocument = gql`
    query getCostCentreListing($CompanyID: String!) {
  getCostCentreListing(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetCostCentreListingQuery__
 *
 * To run a query within a React component, call `useGetCostCentreListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreListingQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCostCentreListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>(GetCostCentreListingDocument, baseOptions);
      }
export function useGetCostCentreListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>(GetCostCentreListingDocument, baseOptions);
        }
export type GetCostCentreListingQueryHookResult = ReturnType<typeof useGetCostCentreListingQuery>;
export type GetCostCentreListingLazyQueryHookResult = ReturnType<typeof useGetCostCentreListingLazyQuery>;
export type GetCostCentreListingQueryResult = ApolloReactCommon.QueryResult<GetCostCentreListingQuery, GetCostCentreListingQueryVariables>;
export const GetCostCentreDefinitionAsgmtDocument = gql`
    query getCostCentreDefinitionAsgmt($CompanyID: String) {
  getCostCentreDefinitionAsgmt(CompanyID: $CompanyID) {
    CostCentreDefinitionAsgmtID
    CostCentreDefinitionHeaderID
    CompanyID
    CostCentreDefinitionHeader {
      Title
      CostCentreDefinitionDetail {
        Name
        Level
        IsLastNode
      }
    }
  }
}
    `;

/**
 * __useGetCostCentreDefinitionAsgmtQuery__
 *
 * To run a query within a React component, call `useGetCostCentreDefinitionAsgmtQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreDefinitionAsgmtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreDefinitionAsgmtQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCostCentreDefinitionAsgmtQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>(GetCostCentreDefinitionAsgmtDocument, baseOptions);
      }
export function useGetCostCentreDefinitionAsgmtLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>(GetCostCentreDefinitionAsgmtDocument, baseOptions);
        }
export type GetCostCentreDefinitionAsgmtQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionAsgmtQuery>;
export type GetCostCentreDefinitionAsgmtLazyQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionAsgmtLazyQuery>;
export type GetCostCentreDefinitionAsgmtQueryResult = ApolloReactCommon.QueryResult<GetCostCentreDefinitionAsgmtQuery, GetCostCentreDefinitionAsgmtQueryVariables>;
export const GetCostCentreDefinitionDetailDocument = gql`
    query getCostCentreDefinitionDetail($CompanyID: String) {
  getCostCentreDefinitionDetail(CompanyID: $CompanyID) {
    CostCentreDefinitionDetailID
    ParentCostCentreDefinitionDetailID
    CostCentreDefinitionHeaderID
    Name
    Level
  }
}
    `;

/**
 * __useGetCostCentreDefinitionDetailQuery__
 *
 * To run a query within a React component, call `useGetCostCentreDefinitionDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCentreDefinitionDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCentreDefinitionDetailQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetCostCentreDefinitionDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>(GetCostCentreDefinitionDetailDocument, baseOptions);
      }
export function useGetCostCentreDefinitionDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>(GetCostCentreDefinitionDetailDocument, baseOptions);
        }
export type GetCostCentreDefinitionDetailQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionDetailQuery>;
export type GetCostCentreDefinitionDetailLazyQueryHookResult = ReturnType<typeof useGetCostCentreDefinitionDetailLazyQuery>;
export type GetCostCentreDefinitionDetailQueryResult = ApolloReactCommon.QueryResult<GetCostCentreDefinitionDetailQuery, GetCostCentreDefinitionDetailQueryVariables>;
export const GetNextLevelCcDefinitionDocument = gql`
    query getNextLevelCCDefinition($CompanyID: String!, $CostCentreID: String) {
  getNextLevelCCDefinition(CompanyID: $CompanyID, CostCentreID: $CostCentreID)
}
    `;

/**
 * __useGetNextLevelCcDefinitionQuery__
 *
 * To run a query within a React component, call `useGetNextLevelCcDefinitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNextLevelCcDefinitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextLevelCcDefinitionQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CostCentreID: // value for 'CostCentreID'
 *   },
 * });
 */
export function useGetNextLevelCcDefinitionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>(GetNextLevelCcDefinitionDocument, baseOptions);
      }
export function useGetNextLevelCcDefinitionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>(GetNextLevelCcDefinitionDocument, baseOptions);
        }
export type GetNextLevelCcDefinitionQueryHookResult = ReturnType<typeof useGetNextLevelCcDefinitionQuery>;
export type GetNextLevelCcDefinitionLazyQueryHookResult = ReturnType<typeof useGetNextLevelCcDefinitionLazyQuery>;
export type GetNextLevelCcDefinitionQueryResult = ApolloReactCommon.QueryResult<GetNextLevelCcDefinitionQuery, GetNextLevelCcDefinitionQueryVariables>;
export const GetParentCostCentreForTreeDocument = gql`
    query getParentCostCentreForTree($CostCentreArr: [String!]) {
  getParentCostCentreForTree(CostCentreArr: $CostCentreArr)
}
    `;

/**
 * __useGetParentCostCentreForTreeQuery__
 *
 * To run a query within a React component, call `useGetParentCostCentreForTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentCostCentreForTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentCostCentreForTreeQuery({
 *   variables: {
 *      CostCentreArr: // value for 'CostCentreArr'
 *   },
 * });
 */
export function useGetParentCostCentreForTreeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>(GetParentCostCentreForTreeDocument, baseOptions);
      }
export function useGetParentCostCentreForTreeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>(GetParentCostCentreForTreeDocument, baseOptions);
        }
export type GetParentCostCentreForTreeQueryHookResult = ReturnType<typeof useGetParentCostCentreForTreeQuery>;
export type GetParentCostCentreForTreeLazyQueryHookResult = ReturnType<typeof useGetParentCostCentreForTreeLazyQuery>;
export type GetParentCostCentreForTreeQueryResult = ApolloReactCommon.QueryResult<GetParentCostCentreForTreeQuery, GetParentCostCentreForTreeQueryVariables>;
export const SetDefaultCostCentreDocument = gql`
    mutation setDefaultCostCentre($CostCentreID: String!, $CompanyID: String!) {
  setDefaultCostCentre(CostCentreID: $CostCentreID, CompanyID: $CompanyID)
}
    `;
export type SetDefaultCostCentreMutationFn = ApolloReactCommon.MutationFunction<SetDefaultCostCentreMutation, SetDefaultCostCentreMutationVariables>;

/**
 * __useSetDefaultCostCentreMutation__
 *
 * To run a mutation, you first call `useSetDefaultCostCentreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultCostCentreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultCostCentreMutation, { data, loading, error }] = useSetDefaultCostCentreMutation({
 *   variables: {
 *      CostCentreID: // value for 'CostCentreID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useSetDefaultCostCentreMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetDefaultCostCentreMutation, SetDefaultCostCentreMutationVariables>) {
        return ApolloReactHooks.useMutation<SetDefaultCostCentreMutation, SetDefaultCostCentreMutationVariables>(SetDefaultCostCentreDocument, baseOptions);
      }
export type SetDefaultCostCentreMutationHookResult = ReturnType<typeof useSetDefaultCostCentreMutation>;
export type SetDefaultCostCentreMutationResult = ApolloReactCommon.MutationResult<SetDefaultCostCentreMutation>;
export type SetDefaultCostCentreMutationOptions = ApolloReactCommon.BaseMutationOptions<SetDefaultCostCentreMutation, SetDefaultCostCentreMutationVariables>;
export const GetMasterCoaDocument = gql`
    query getMasterCOA($AccountType: String, $IsLastNode: Boolean, $orderByAsc: String, $IsControl: Boolean, $AccountID: String) {
  getMasterCOA(AccountType: $AccountType, IsLastNode: $IsLastNode, orderByAsc: $orderByAsc, IsControl: $IsControl, AccountID: $AccountID) {
    MasterCOAID
    ParentMasterCOAID
    Name
    Code
    IsControl
    AccountType
    IsLastNode
    Level
  }
}
    `;

/**
 * __useGetMasterCoaQuery__
 *
 * To run a query within a React component, call `useGetMasterCoaQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterCoaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterCoaQuery({
 *   variables: {
 *      AccountType: // value for 'AccountType'
 *      IsLastNode: // value for 'IsLastNode'
 *      orderByAsc: // value for 'orderByAsc'
 *      IsControl: // value for 'IsControl'
 *      AccountID: // value for 'AccountID'
 *   },
 * });
 */
export function useGetMasterCoaQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterCoaQuery, GetMasterCoaQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterCoaQuery, GetMasterCoaQueryVariables>(GetMasterCoaDocument, baseOptions);
      }
export function useGetMasterCoaLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterCoaQuery, GetMasterCoaQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterCoaQuery, GetMasterCoaQueryVariables>(GetMasterCoaDocument, baseOptions);
        }
export type GetMasterCoaQueryHookResult = ReturnType<typeof useGetMasterCoaQuery>;
export type GetMasterCoaLazyQueryHookResult = ReturnType<typeof useGetMasterCoaLazyQuery>;
export type GetMasterCoaQueryResult = ApolloReactCommon.QueryResult<GetMasterCoaQuery, GetMasterCoaQueryVariables>;
export const GetStockItemDocument = gql`
    query getStockItem($StockItemID: String, $StockCategoryID: String, $orderByAsc: String) {
  getStockItem(StockItemID: $StockItemID, StockCategoryID: $StockCategoryID, orderByAsc: $orderByAsc) {
    createdTs
    createdBy
    modTs
    modBy
    AccountID
    StockItemID
    StockCategoryID
    RecordStatus
    Name
    ABCClass
    ShelfLife
    StandardCost
    UOMID
  }
}
    `;

/**
 * __useGetStockItemQuery__
 *
 * To run a query within a React component, call `useGetStockItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockItemQuery({
 *   variables: {
 *      StockItemID: // value for 'StockItemID'
 *      StockCategoryID: // value for 'StockCategoryID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetStockItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStockItemQuery, GetStockItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStockItemQuery, GetStockItemQueryVariables>(GetStockItemDocument, baseOptions);
      }
export function useGetStockItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStockItemQuery, GetStockItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStockItemQuery, GetStockItemQueryVariables>(GetStockItemDocument, baseOptions);
        }
export type GetStockItemQueryHookResult = ReturnType<typeof useGetStockItemQuery>;
export type GetStockItemLazyQueryHookResult = ReturnType<typeof useGetStockItemLazyQuery>;
export type GetStockItemQueryResult = ApolloReactCommon.QueryResult<GetStockItemQuery, GetStockItemQueryVariables>;
export const GetCreditorProfileWithAccountDocument = gql`
    query getCreditorProfileWithAccount($accountID: String!) {
  getCreditorProfileWithAccount(accountID: $accountID) {
    CreditorAccountID
    CreditorTypeID
    CompanyRegNo
    ContactNo
    AccountID
    Email
    CreditTerm
    TaxSchemeID
    Region
    GSTRegNo
    TaxIdentificationNo
    Remark
    BankName
    BankAccountNo
    CreditorTypeEnum
    CompanyName
    modTs
    Address
    CreditorStatus
    CreditorContact {
      CreditorContactID
      CreditorAccountID
      Name
      ContactNo
      Designation
      Email
    }
    CreditorDirector {
      CreditorDirectorID
      CreditorAccountID
      Name
      ContactNo
      Email
      IdentityNo
      IdentityType
    }
  }
}
    `;

/**
 * __useGetCreditorProfileWithAccountQuery__
 *
 * To run a query within a React component, call `useGetCreditorProfileWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorProfileWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorProfileWithAccountQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetCreditorProfileWithAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorProfileWithAccountQuery, GetCreditorProfileWithAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorProfileWithAccountQuery, GetCreditorProfileWithAccountQueryVariables>(GetCreditorProfileWithAccountDocument, baseOptions);
      }
export function useGetCreditorProfileWithAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorProfileWithAccountQuery, GetCreditorProfileWithAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorProfileWithAccountQuery, GetCreditorProfileWithAccountQueryVariables>(GetCreditorProfileWithAccountDocument, baseOptions);
        }
export type GetCreditorProfileWithAccountQueryHookResult = ReturnType<typeof useGetCreditorProfileWithAccountQuery>;
export type GetCreditorProfileWithAccountLazyQueryHookResult = ReturnType<typeof useGetCreditorProfileWithAccountLazyQuery>;
export type GetCreditorProfileWithAccountQueryResult = ApolloReactCommon.QueryResult<GetCreditorProfileWithAccountQuery, GetCreditorProfileWithAccountQueryVariables>;
export const GetCreditorAccountDocument = gql`
    query getCreditorAccount($orderByAsc: String, $CreditorAccountID: String, $CreditorTypeID: String, $CreditorTypeEnum: CreditorType) {
  getCreditorAccount(orderByAsc: $orderByAsc, CreditorAccountID: $CreditorAccountID, CreditorTypeEnum: $CreditorTypeEnum, CreditorTypeID: $CreditorTypeID) {
    CreditorAccountID
    CreditorTypeID
    AccountID
    CompanyRegNo
    ContactNo
    Email
    CreditTerm
    TaxSchemeID
    TaxIdentificationNo
    Region
    GSTRegNo
    Remark
    BankName
    BankAccountNo
    CreditorTypeEnum
    CompanyName
    modTs
    Address
    CreditorStatus
  }
}
    `;

/**
 * __useGetCreditorAccountQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountQuery({
 *   variables: {
 *      orderByAsc: // value for 'orderByAsc'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      CreditorTypeID: // value for 'CreditorTypeID'
 *      CreditorTypeEnum: // value for 'CreditorTypeEnum'
 *   },
 * });
 */
export function useGetCreditorAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>(GetCreditorAccountDocument, baseOptions);
      }
export function useGetCreditorAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>(GetCreditorAccountDocument, baseOptions);
        }
export type GetCreditorAccountQueryHookResult = ReturnType<typeof useGetCreditorAccountQuery>;
export type GetCreditorAccountLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountLazyQuery>;
export type GetCreditorAccountQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountQuery, GetCreditorAccountQueryVariables>;
export const GetCreditorAccountDetailDocument = gql`
    query getCreditorAccountDetail($CreditorAccountID: String, $CreditorTypeID: String, $CreditorTypeEnum: CreditorType) {
  getCreditorAccount(CreditorAccountID: $CreditorAccountID, CreditorTypeEnum: $CreditorTypeEnum, CreditorTypeID: $CreditorTypeID) {
    CreditorAccountID
    CreditorTypeID
    AccountID
    CompanyRegNo
    ContactNo
    Email
    CreditTerm
    TaxSchemeID
    TaxIdentificationNo
    Region
    GSTRegNo
    Remark
    BankName
    BankAccountNo
    CreditorTypeEnum
    CompanyName
    modTs
    Address
    CreditorStatus
  }
  getCreditorContact(CreditorAccountID: $CreditorAccountID) {
    CreditorContactID
    CreditorAccountID
    Name
    ContactNo
    Designation
    Email
  }
  getCreditorDirector(CreditorAccountID: $CreditorAccountID) {
    CreditorDirectorID
    CreditorAccountID
    Name
    ContactNo
    Email
    IdentityNo
    IdentityType
  }
}
    `;

/**
 * __useGetCreditorAccountDetailQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountDetailQuery({
 *   variables: {
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      CreditorTypeID: // value for 'CreditorTypeID'
 *      CreditorTypeEnum: // value for 'CreditorTypeEnum'
 *   },
 * });
 */
export function useGetCreditorAccountDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountDetailQuery, GetCreditorAccountDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountDetailQuery, GetCreditorAccountDetailQueryVariables>(GetCreditorAccountDetailDocument, baseOptions);
      }
export function useGetCreditorAccountDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountDetailQuery, GetCreditorAccountDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountDetailQuery, GetCreditorAccountDetailQueryVariables>(GetCreditorAccountDetailDocument, baseOptions);
        }
export type GetCreditorAccountDetailQueryHookResult = ReturnType<typeof useGetCreditorAccountDetailQuery>;
export type GetCreditorAccountDetailLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountDetailLazyQuery>;
export type GetCreditorAccountDetailQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountDetailQuery, GetCreditorAccountDetailQueryVariables>;
export const CreateCreditorAccountDocument = gql`
    mutation CreateCreditorAccount($input: CreditorAccountInput!, $contactItem: [CreditorContactInput!], $directorItem: [CreditorDirectorInput!]) {
  CreateCreditorAccount(input: $input, contactItem: $contactItem, directorItem: $directorItem)
}
    `;
export type CreateCreditorAccountMutationFn = ApolloReactCommon.MutationFunction<CreateCreditorAccountMutation, CreateCreditorAccountMutationVariables>;

/**
 * __useCreateCreditorAccountMutation__
 *
 * To run a mutation, you first call `useCreateCreditorAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditorAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditorAccountMutation, { data, loading, error }] = useCreateCreditorAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactItem: // value for 'contactItem'
 *      directorItem: // value for 'directorItem'
 *   },
 * });
 */
export function useCreateCreditorAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCreditorAccountMutation, CreateCreditorAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCreditorAccountMutation, CreateCreditorAccountMutationVariables>(CreateCreditorAccountDocument, baseOptions);
      }
export type CreateCreditorAccountMutationHookResult = ReturnType<typeof useCreateCreditorAccountMutation>;
export type CreateCreditorAccountMutationResult = ApolloReactCommon.MutationResult<CreateCreditorAccountMutation>;
export type CreateCreditorAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCreditorAccountMutation, CreateCreditorAccountMutationVariables>;
export const UpdateCreditorAccountDocument = gql`
    mutation updateCreditorAccount($input: CreditorAccountInput!) {
  updateCreditorAccount(input: $input) {
    CreditorAccountID
  }
}
    `;
export type UpdateCreditorAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateCreditorAccountMutation, UpdateCreditorAccountMutationVariables>;

/**
 * __useUpdateCreditorAccountMutation__
 *
 * To run a mutation, you first call `useUpdateCreditorAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditorAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditorAccountMutation, { data, loading, error }] = useUpdateCreditorAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreditorAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCreditorAccountMutation, UpdateCreditorAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCreditorAccountMutation, UpdateCreditorAccountMutationVariables>(UpdateCreditorAccountDocument, baseOptions);
      }
export type UpdateCreditorAccountMutationHookResult = ReturnType<typeof useUpdateCreditorAccountMutation>;
export type UpdateCreditorAccountMutationResult = ApolloReactCommon.MutationResult<UpdateCreditorAccountMutation>;
export type UpdateCreditorAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCreditorAccountMutation, UpdateCreditorAccountMutationVariables>;
export const UpdateCreditorAccountWithItemDocument = gql`
    mutation UpdateCreditorAccountWithItem($input: CreditorAccountInput!, $contactItem: [CreditorContactInput!], $directorItem: [CreditorDirectorInput!]) {
  UpdateCreditorAccountWithItem(input: $input, contactItem: $contactItem, directorItem: $directorItem)
}
    `;
export type UpdateCreditorAccountWithItemMutationFn = ApolloReactCommon.MutationFunction<UpdateCreditorAccountWithItemMutation, UpdateCreditorAccountWithItemMutationVariables>;

/**
 * __useUpdateCreditorAccountWithItemMutation__
 *
 * To run a mutation, you first call `useUpdateCreditorAccountWithItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditorAccountWithItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditorAccountWithItemMutation, { data, loading, error }] = useUpdateCreditorAccountWithItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *      contactItem: // value for 'contactItem'
 *      directorItem: // value for 'directorItem'
 *   },
 * });
 */
export function useUpdateCreditorAccountWithItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCreditorAccountWithItemMutation, UpdateCreditorAccountWithItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCreditorAccountWithItemMutation, UpdateCreditorAccountWithItemMutationVariables>(UpdateCreditorAccountWithItemDocument, baseOptions);
      }
export type UpdateCreditorAccountWithItemMutationHookResult = ReturnType<typeof useUpdateCreditorAccountWithItemMutation>;
export type UpdateCreditorAccountWithItemMutationResult = ApolloReactCommon.MutationResult<UpdateCreditorAccountWithItemMutation>;
export type UpdateCreditorAccountWithItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCreditorAccountWithItemMutation, UpdateCreditorAccountWithItemMutationVariables>;
export const GetCreditorAccountCompanyAssignmentDocument = gql`
    query getCreditorAccountCompanyAssignment($CreditorAccountCompanyAssignmentID: String, $CompanyID: String, $CreditorAccountID: String) {
  getCreditorAccountCompanyAssignment(CreditorAccountCompanyAssignmentID: $CreditorAccountCompanyAssignmentID, CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID) {
    CreditorAccountCompanyAssignmentID
    CreditorAccountID
    CompanyID
  }
}
    `;

/**
 * __useGetCreditorAccountCompanyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetCreditorAccountCompanyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorAccountCompanyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorAccountCompanyAssignmentQuery({
 *   variables: {
 *      CreditorAccountCompanyAssignmentID: // value for 'CreditorAccountCompanyAssignmentID'
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetCreditorAccountCompanyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>(GetCreditorAccountCompanyAssignmentDocument, baseOptions);
      }
export function useGetCreditorAccountCompanyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>(GetCreditorAccountCompanyAssignmentDocument, baseOptions);
        }
export type GetCreditorAccountCompanyAssignmentQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyAssignmentQuery>;
export type GetCreditorAccountCompanyAssignmentLazyQueryHookResult = ReturnType<typeof useGetCreditorAccountCompanyAssignmentLazyQuery>;
export type GetCreditorAccountCompanyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetCreditorAccountCompanyAssignmentQuery, GetCreditorAccountCompanyAssignmentQueryVariables>;
export const AssignCreditorAccountCompanyDocument = gql`
    mutation assignCreditorAccountCompany($input: CreditorAccountCompanyAssignmentInput!) {
  AssignCreditorAccountComapany(input: $input)
}
    `;
export type AssignCreditorAccountCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignCreditorAccountCompanyMutation, AssignCreditorAccountCompanyMutationVariables>;

/**
 * __useAssignCreditorAccountCompanyMutation__
 *
 * To run a mutation, you first call `useAssignCreditorAccountCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCreditorAccountCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCreditorAccountCompanyMutation, { data, loading, error }] = useAssignCreditorAccountCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignCreditorAccountCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignCreditorAccountCompanyMutation, AssignCreditorAccountCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignCreditorAccountCompanyMutation, AssignCreditorAccountCompanyMutationVariables>(AssignCreditorAccountCompanyDocument, baseOptions);
      }
export type AssignCreditorAccountCompanyMutationHookResult = ReturnType<typeof useAssignCreditorAccountCompanyMutation>;
export type AssignCreditorAccountCompanyMutationResult = ApolloReactCommon.MutationResult<AssignCreditorAccountCompanyMutation>;
export type AssignCreditorAccountCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignCreditorAccountCompanyMutation, AssignCreditorAccountCompanyMutationVariables>;
export const UnassignCreditorAccountCompanyDocument = gql`
    mutation unassignCreditorAccountCompany($input: CreditorAccountCompanyAssignmentInput!) {
  UnassignCreditorAccountComapany(input: $input)
}
    `;
export type UnassignCreditorAccountCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignCreditorAccountCompanyMutation, UnassignCreditorAccountCompanyMutationVariables>;

/**
 * __useUnassignCreditorAccountCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignCreditorAccountCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignCreditorAccountCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignCreditorAccountCompanyMutation, { data, loading, error }] = useUnassignCreditorAccountCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignCreditorAccountCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignCreditorAccountCompanyMutation, UnassignCreditorAccountCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignCreditorAccountCompanyMutation, UnassignCreditorAccountCompanyMutationVariables>(UnassignCreditorAccountCompanyDocument, baseOptions);
      }
export type UnassignCreditorAccountCompanyMutationHookResult = ReturnType<typeof useUnassignCreditorAccountCompanyMutation>;
export type UnassignCreditorAccountCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignCreditorAccountCompanyMutation>;
export type UnassignCreditorAccountCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignCreditorAccountCompanyMutation, UnassignCreditorAccountCompanyMutationVariables>;
export const CreditorAccountTotalDueDocument = gql`
    query CreditorAccountTotalDue($CompanyID: String!) {
  CreditorAccountTotalDue(CompanyID: $CompanyID)
}
    `;

/**
 * __useCreditorAccountTotalDueQuery__
 *
 * To run a query within a React component, call `useCreditorAccountTotalDueQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditorAccountTotalDueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditorAccountTotalDueQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useCreditorAccountTotalDueQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>(CreditorAccountTotalDueDocument, baseOptions);
      }
export function useCreditorAccountTotalDueLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>(CreditorAccountTotalDueDocument, baseOptions);
        }
export type CreditorAccountTotalDueQueryHookResult = ReturnType<typeof useCreditorAccountTotalDueQuery>;
export type CreditorAccountTotalDueLazyQueryHookResult = ReturnType<typeof useCreditorAccountTotalDueLazyQuery>;
export type CreditorAccountTotalDueQueryResult = ApolloReactCommon.QueryResult<CreditorAccountTotalDueQuery, CreditorAccountTotalDueQueryVariables>;
export const GetCreditorTypeDocument = gql`
    query getCreditorType($CreditorTypeID: String, $CreditorType: CreditorType) {
  getCreditorType(CreditorTypeID: $CreditorTypeID, CreditorType: $CreditorType) {
    CreditorTypeID
    MasterCOAID
    Description
    CreditorType
    AccrualCOAID
    Code
    MasterCOA {
      Name
      Code
    }
  }
}
    `;

/**
 * __useGetCreditorTypeQuery__
 *
 * To run a query within a React component, call `useGetCreditorTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorTypeQuery({
 *   variables: {
 *      CreditorTypeID: // value for 'CreditorTypeID'
 *      CreditorType: // value for 'CreditorType'
 *   },
 * });
 */
export function useGetCreditorTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>(GetCreditorTypeDocument, baseOptions);
      }
export function useGetCreditorTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>(GetCreditorTypeDocument, baseOptions);
        }
export type GetCreditorTypeQueryHookResult = ReturnType<typeof useGetCreditorTypeQuery>;
export type GetCreditorTypeLazyQueryHookResult = ReturnType<typeof useGetCreditorTypeLazyQuery>;
export type GetCreditorTypeQueryResult = ApolloReactCommon.QueryResult<GetCreditorTypeQuery, GetCreditorTypeQueryVariables>;
export const GetCreditorTypeWithAccountDocument = gql`
    query getCreditorTypeWithAccount($accountID: String!, $CompanyID: String!, $orderByAsc: String, $orderByDesc: String) {
  getCreditorTypeWithAccount(accountID: $accountID, CompanyID: $CompanyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    CreditorTypeID
    MasterCOAID
    Description
    CreditorType
    Code
    CreditorAccount {
      CreditorAccountID
      CreditorTypeID
      CompanyRegNo
      CompanyName
      ContactNo
    }
    MasterCOA {
      Name
      Code
    }
  }
}
    `;

/**
 * __useGetCreditorTypeWithAccountQuery__
 *
 * To run a query within a React component, call `useGetCreditorTypeWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorTypeWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorTypeWithAccountQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetCreditorTypeWithAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorTypeWithAccountQuery, GetCreditorTypeWithAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorTypeWithAccountQuery, GetCreditorTypeWithAccountQueryVariables>(GetCreditorTypeWithAccountDocument, baseOptions);
      }
export function useGetCreditorTypeWithAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorTypeWithAccountQuery, GetCreditorTypeWithAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorTypeWithAccountQuery, GetCreditorTypeWithAccountQueryVariables>(GetCreditorTypeWithAccountDocument, baseOptions);
        }
export type GetCreditorTypeWithAccountQueryHookResult = ReturnType<typeof useGetCreditorTypeWithAccountQuery>;
export type GetCreditorTypeWithAccountLazyQueryHookResult = ReturnType<typeof useGetCreditorTypeWithAccountLazyQuery>;
export type GetCreditorTypeWithAccountQueryResult = ApolloReactCommon.QueryResult<GetCreditorTypeWithAccountQuery, GetCreditorTypeWithAccountQueryVariables>;
export const GetMasterCoaWithAccountDocument = gql`
    query getMasterCOAWithAccount($take: Float, $orderByDesc: String, $orderByAsc: String, $accountID: String!) {
  getMasterCOAWithAccount(take: $take, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, accountID: $accountID) {
    MasterCOAID
    ParentMasterCOAID
    Name
    Code
    IsControl
    AccountType
    IsLastNode
    Level
    EntityCOA {
      EntityCOAID
      CompanyID
    }
  }
}
    `;

/**
 * __useGetMasterCoaWithAccountQuery__
 *
 * To run a query within a React component, call `useGetMasterCoaWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterCoaWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterCoaWithAccountQuery({
 *   variables: {
 *      take: // value for 'take'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetMasterCoaWithAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMasterCoaWithAccountQuery, GetMasterCoaWithAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMasterCoaWithAccountQuery, GetMasterCoaWithAccountQueryVariables>(GetMasterCoaWithAccountDocument, baseOptions);
      }
export function useGetMasterCoaWithAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMasterCoaWithAccountQuery, GetMasterCoaWithAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMasterCoaWithAccountQuery, GetMasterCoaWithAccountQueryVariables>(GetMasterCoaWithAccountDocument, baseOptions);
        }
export type GetMasterCoaWithAccountQueryHookResult = ReturnType<typeof useGetMasterCoaWithAccountQuery>;
export type GetMasterCoaWithAccountLazyQueryHookResult = ReturnType<typeof useGetMasterCoaWithAccountLazyQuery>;
export type GetMasterCoaWithAccountQueryResult = ApolloReactCommon.QueryResult<GetMasterCoaWithAccountQuery, GetMasterCoaWithAccountQueryVariables>;
export const CreateCreditorTypeWithAccountDocument = gql`
    mutation createCreditorTypeWithAccount($input: CreditorTypeInput!) {
  createCreditorType(input: $input) {
    CreditorTypeID
  }
}
    `;
export type CreateCreditorTypeWithAccountMutationFn = ApolloReactCommon.MutationFunction<CreateCreditorTypeWithAccountMutation, CreateCreditorTypeWithAccountMutationVariables>;

/**
 * __useCreateCreditorTypeWithAccountMutation__
 *
 * To run a mutation, you first call `useCreateCreditorTypeWithAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditorTypeWithAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditorTypeWithAccountMutation, { data, loading, error }] = useCreateCreditorTypeWithAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreditorTypeWithAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCreditorTypeWithAccountMutation, CreateCreditorTypeWithAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCreditorTypeWithAccountMutation, CreateCreditorTypeWithAccountMutationVariables>(CreateCreditorTypeWithAccountDocument, baseOptions);
      }
export type CreateCreditorTypeWithAccountMutationHookResult = ReturnType<typeof useCreateCreditorTypeWithAccountMutation>;
export type CreateCreditorTypeWithAccountMutationResult = ApolloReactCommon.MutationResult<CreateCreditorTypeWithAccountMutation>;
export type CreateCreditorTypeWithAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCreditorTypeWithAccountMutation, CreateCreditorTypeWithAccountMutationVariables>;
export const UpdateCreditorTypeWithAccountDocument = gql`
    mutation updateCreditorTypeWithAccount($input: CreditorTypeInput!) {
  updateCreditorType(input: $input) {
    CreditorTypeID
  }
}
    `;
export type UpdateCreditorTypeWithAccountMutationFn = ApolloReactCommon.MutationFunction<UpdateCreditorTypeWithAccountMutation, UpdateCreditorTypeWithAccountMutationVariables>;

/**
 * __useUpdateCreditorTypeWithAccountMutation__
 *
 * To run a mutation, you first call `useUpdateCreditorTypeWithAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditorTypeWithAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditorTypeWithAccountMutation, { data, loading, error }] = useUpdateCreditorTypeWithAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreditorTypeWithAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCreditorTypeWithAccountMutation, UpdateCreditorTypeWithAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCreditorTypeWithAccountMutation, UpdateCreditorTypeWithAccountMutationVariables>(UpdateCreditorTypeWithAccountDocument, baseOptions);
      }
export type UpdateCreditorTypeWithAccountMutationHookResult = ReturnType<typeof useUpdateCreditorTypeWithAccountMutation>;
export type UpdateCreditorTypeWithAccountMutationResult = ApolloReactCommon.MutationResult<UpdateCreditorTypeWithAccountMutation>;
export type UpdateCreditorTypeWithAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCreditorTypeWithAccountMutation, UpdateCreditorTypeWithAccountMutationVariables>;
export const DeleteCreditorTypeWithAccountDocument = gql`
    mutation deleteCreditorTypeWithAccount($input: CreditorTypeDeleteInput!) {
  deleteCreditorType(input: $input)
}
    `;
export type DeleteCreditorTypeWithAccountMutationFn = ApolloReactCommon.MutationFunction<DeleteCreditorTypeWithAccountMutation, DeleteCreditorTypeWithAccountMutationVariables>;

/**
 * __useDeleteCreditorTypeWithAccountMutation__
 *
 * To run a mutation, you first call `useDeleteCreditorTypeWithAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreditorTypeWithAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreditorTypeWithAccountMutation, { data, loading, error }] = useDeleteCreditorTypeWithAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCreditorTypeWithAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCreditorTypeWithAccountMutation, DeleteCreditorTypeWithAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCreditorTypeWithAccountMutation, DeleteCreditorTypeWithAccountMutationVariables>(DeleteCreditorTypeWithAccountDocument, baseOptions);
      }
export type DeleteCreditorTypeWithAccountMutationHookResult = ReturnType<typeof useDeleteCreditorTypeWithAccountMutation>;
export type DeleteCreditorTypeWithAccountMutationResult = ApolloReactCommon.MutationResult<DeleteCreditorTypeWithAccountMutation>;
export type DeleteCreditorTypeWithAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCreditorTypeWithAccountMutation, DeleteCreditorTypeWithAccountMutationVariables>;
export const CreateCreditorTypeDocument = gql`
    mutation createCreditorType($input: CreditorTypeInput!) {
  createCreditorType(input: $input) {
    CreditorTypeID
  }
}
    `;
export type CreateCreditorTypeMutationFn = ApolloReactCommon.MutationFunction<CreateCreditorTypeMutation, CreateCreditorTypeMutationVariables>;

/**
 * __useCreateCreditorTypeMutation__
 *
 * To run a mutation, you first call `useCreateCreditorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCreditorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCreditorTypeMutation, { data, loading, error }] = useCreateCreditorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCreditorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCreditorTypeMutation, CreateCreditorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCreditorTypeMutation, CreateCreditorTypeMutationVariables>(CreateCreditorTypeDocument, baseOptions);
      }
export type CreateCreditorTypeMutationHookResult = ReturnType<typeof useCreateCreditorTypeMutation>;
export type CreateCreditorTypeMutationResult = ApolloReactCommon.MutationResult<CreateCreditorTypeMutation>;
export type CreateCreditorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCreditorTypeMutation, CreateCreditorTypeMutationVariables>;
export const UpdateCreditorTypeDocument = gql`
    mutation updateCreditorType($input: CreditorTypeInput!) {
  updateCreditorType(input: $input) {
    CreditorTypeID
  }
}
    `;
export type UpdateCreditorTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateCreditorTypeMutation, UpdateCreditorTypeMutationVariables>;

/**
 * __useUpdateCreditorTypeMutation__
 *
 * To run a mutation, you first call `useUpdateCreditorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCreditorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCreditorTypeMutation, { data, loading, error }] = useUpdateCreditorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCreditorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCreditorTypeMutation, UpdateCreditorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCreditorTypeMutation, UpdateCreditorTypeMutationVariables>(UpdateCreditorTypeDocument, baseOptions);
      }
export type UpdateCreditorTypeMutationHookResult = ReturnType<typeof useUpdateCreditorTypeMutation>;
export type UpdateCreditorTypeMutationResult = ApolloReactCommon.MutationResult<UpdateCreditorTypeMutation>;
export type UpdateCreditorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCreditorTypeMutation, UpdateCreditorTypeMutationVariables>;
export const DeleteCreditorTypeDocument = gql`
    mutation deleteCreditorType($input: CreditorTypeDeleteInput!) {
  deleteCreditorType(input: $input)
}
    `;
export type DeleteCreditorTypeMutationFn = ApolloReactCommon.MutationFunction<DeleteCreditorTypeMutation, DeleteCreditorTypeMutationVariables>;

/**
 * __useDeleteCreditorTypeMutation__
 *
 * To run a mutation, you first call `useDeleteCreditorTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreditorTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreditorTypeMutation, { data, loading, error }] = useDeleteCreditorTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCreditorTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCreditorTypeMutation, DeleteCreditorTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCreditorTypeMutation, DeleteCreditorTypeMutationVariables>(DeleteCreditorTypeDocument, baseOptions);
      }
export type DeleteCreditorTypeMutationHookResult = ReturnType<typeof useDeleteCreditorTypeMutation>;
export type DeleteCreditorTypeMutationResult = ApolloReactCommon.MutationResult<DeleteCreditorTypeMutation>;
export type DeleteCreditorTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCreditorTypeMutation, DeleteCreditorTypeMutationVariables>;
export const GetTaxSchemeDocument = gql`
    query getTaxScheme($TaxSchemeID: String, $Code: String, $AccTaxClass: String, $RecordStatus: RecordStatus, $orderByAsc: String) {
  getTaxScheme(TaxSchemeID: $TaxSchemeID, Code: $Code, AccTaxClass: $AccTaxClass, RecordStatus: $RecordStatus, orderByAsc: $orderByAsc) {
    TaxSchemeID
    RecordStatus
    Code
    Description
    AccTaxCategory
    AccTaxClass
    RecordStatus
    MasterCOAID
  }
}
    `;

/**
 * __useGetTaxSchemeQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeQuery({
 *   variables: {
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *      Code: // value for 'Code'
 *      AccTaxClass: // value for 'AccTaxClass'
 *      RecordStatus: // value for 'RecordStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetTaxSchemeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>(GetTaxSchemeDocument, baseOptions);
      }
export function useGetTaxSchemeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>(GetTaxSchemeDocument, baseOptions);
        }
export type GetTaxSchemeQueryHookResult = ReturnType<typeof useGetTaxSchemeQuery>;
export type GetTaxSchemeLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeLazyQuery>;
export type GetTaxSchemeQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeQuery, GetTaxSchemeQueryVariables>;
export const GetTaxSchemeCodeDocument = gql`
    query getTaxSchemeCode($TaxSchemeID: String, $Code: String, $AccTaxClass: String, $RecordStatus: RecordStatus, $orderByAsc: String) {
  getTaxScheme(TaxSchemeID: $TaxSchemeID, Code: $Code, AccTaxClass: $AccTaxClass, RecordStatus: $RecordStatus, orderByAsc: $orderByAsc) {
    TaxSchemeID
    RecordStatus
    Code
    Description
    AccTaxCategory
    AccTaxClass
    RecordStatus
    MasterCOAID
  }
}
    `;

/**
 * __useGetTaxSchemeCodeQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeCodeQuery({
 *   variables: {
 *      TaxSchemeID: // value for 'TaxSchemeID'
 *      Code: // value for 'Code'
 *      AccTaxClass: // value for 'AccTaxClass'
 *      RecordStatus: // value for 'RecordStatus'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetTaxSchemeCodeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>(GetTaxSchemeCodeDocument, baseOptions);
      }
export function useGetTaxSchemeCodeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>(GetTaxSchemeCodeDocument, baseOptions);
        }
export type GetTaxSchemeCodeQueryHookResult = ReturnType<typeof useGetTaxSchemeCodeQuery>;
export type GetTaxSchemeCodeLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeCodeLazyQuery>;
export type GetTaxSchemeCodeQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeCodeQuery, GetTaxSchemeCodeQueryVariables>;
export const GetUomDocument = gql`
    query getUOM($UOMID: String, $orderByAsc: String, $RecordStatus: RecordStatus) {
  getUOM(UOMID: $UOMID, orderByAsc: $orderByAsc, RecordStatus: $RecordStatus) {
    UOMID
    Name
    Code
    RecordStatus
  }
}
    `;

/**
 * __useGetUomQuery__
 *
 * To run a query within a React component, call `useGetUomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomQuery({
 *   variables: {
 *      UOMID: // value for 'UOMID'
 *      orderByAsc: // value for 'orderByAsc'
 *      RecordStatus: // value for 'RecordStatus'
 *   },
 * });
 */
export function useGetUomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomQuery, GetUomQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomQuery, GetUomQueryVariables>(GetUomDocument, baseOptions);
      }
export function useGetUomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomQuery, GetUomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomQuery, GetUomQueryVariables>(GetUomDocument, baseOptions);
        }
export type GetUomQueryHookResult = ReturnType<typeof useGetUomQuery>;
export type GetUomLazyQueryHookResult = ReturnType<typeof useGetUomLazyQuery>;
export type GetUomQueryResult = ApolloReactCommon.QueryResult<GetUomQuery, GetUomQueryVariables>;
export const CreateUomDocument = gql`
    mutation createUOM($input: UOMInput!) {
  createUOM(input: $input) {
    UOMID
  }
}
    `;
export type CreateUomMutationFn = ApolloReactCommon.MutationFunction<CreateUomMutation, CreateUomMutationVariables>;

/**
 * __useCreateUomMutation__
 *
 * To run a mutation, you first call `useCreateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUomMutation, { data, loading, error }] = useCreateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUomMutation, CreateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUomMutation, CreateUomMutationVariables>(CreateUomDocument, baseOptions);
      }
export type CreateUomMutationHookResult = ReturnType<typeof useCreateUomMutation>;
export type CreateUomMutationResult = ApolloReactCommon.MutationResult<CreateUomMutation>;
export type CreateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUomMutation, CreateUomMutationVariables>;
export const UpdateUomDocument = gql`
    mutation updateUOM($input: UOMInput!) {
  updateUOM(input: $input) {
    UOMID
    Name
    Code
  }
}
    `;
export type UpdateUomMutationFn = ApolloReactCommon.MutationFunction<UpdateUomMutation, UpdateUomMutationVariables>;

/**
 * __useUpdateUomMutation__
 *
 * To run a mutation, you first call `useUpdateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomMutation, { data, loading, error }] = useUpdateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUomMutation, UpdateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUomMutation, UpdateUomMutationVariables>(UpdateUomDocument, baseOptions);
      }
export type UpdateUomMutationHookResult = ReturnType<typeof useUpdateUomMutation>;
export type UpdateUomMutationResult = ApolloReactCommon.MutationResult<UpdateUomMutation>;
export type UpdateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUomMutation, UpdateUomMutationVariables>;
export const DeleteUomDocument = gql`
    mutation deleteUOM($input: UOMDeleteInput!) {
  deleteUOM(input: $input)
}
    `;
export type DeleteUomMutationFn = ApolloReactCommon.MutationFunction<DeleteUomMutation, DeleteUomMutationVariables>;

/**
 * __useDeleteUomMutation__
 *
 * To run a mutation, you first call `useDeleteUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUomMutation, { data, loading, error }] = useDeleteUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUomMutation, DeleteUomMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUomMutation, DeleteUomMutationVariables>(DeleteUomDocument, baseOptions);
      }
export type DeleteUomMutationHookResult = ReturnType<typeof useDeleteUomMutation>;
export type DeleteUomMutationResult = ApolloReactCommon.MutationResult<DeleteUomMutation>;
export type DeleteUomMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUomMutation, DeleteUomMutationVariables>;
export const GetUomExchangeDocument = gql`
    query getUOMExchange($FromUomID: String, $ToUomID: String, $orderByAsc: String, $orderByDesc: String) {
  getUOMExchange(FromUomID: $FromUomID, ToUomID: $ToUomID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    FromUomID
    ToUomID
    FromAmt
    ToAmt
    ExchangeRate
    UomLog
  }
}
    `;

/**
 * __useGetUomExchangeQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeQuery({
 *   variables: {
 *      FromUomID: // value for 'FromUomID'
 *      ToUomID: // value for 'ToUomID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetUomExchangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeQuery, GetUomExchangeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeQuery, GetUomExchangeQueryVariables>(GetUomExchangeDocument, baseOptions);
      }
export function useGetUomExchangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeQuery, GetUomExchangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeQuery, GetUomExchangeQueryVariables>(GetUomExchangeDocument, baseOptions);
        }
export type GetUomExchangeQueryHookResult = ReturnType<typeof useGetUomExchangeQuery>;
export type GetUomExchangeLazyQueryHookResult = ReturnType<typeof useGetUomExchangeLazyQuery>;
export type GetUomExchangeQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeQuery, GetUomExchangeQueryVariables>;
export const GetUomExchangeDataDocument = gql`
    query getUomExchangeData {
  getUomExchangeData
}
    `;

/**
 * __useGetUomExchangeDataQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUomExchangeDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>(GetUomExchangeDataDocument, baseOptions);
      }
export function useGetUomExchangeDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>(GetUomExchangeDataDocument, baseOptions);
        }
export type GetUomExchangeDataQueryHookResult = ReturnType<typeof useGetUomExchangeDataQuery>;
export type GetUomExchangeDataLazyQueryHookResult = ReturnType<typeof useGetUomExchangeDataLazyQuery>;
export type GetUomExchangeDataQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeDataQuery, GetUomExchangeDataQueryVariables>;
export const GetUomExchangeListDocument = gql`
    query getUomExchangeList($UOMID: String!) {
  getUOMExchangeList(UOMID: $UOMID)
}
    `;

/**
 * __useGetUomExchangeListQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeListQuery({
 *   variables: {
 *      UOMID: // value for 'UOMID'
 *   },
 * });
 */
export function useGetUomExchangeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>(GetUomExchangeListDocument, baseOptions);
      }
export function useGetUomExchangeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>(GetUomExchangeListDocument, baseOptions);
        }
export type GetUomExchangeListQueryHookResult = ReturnType<typeof useGetUomExchangeListQuery>;
export type GetUomExchangeListLazyQueryHookResult = ReturnType<typeof useGetUomExchangeListLazyQuery>;
export type GetUomExchangeListQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>;
export const CreateUomExchangeDocument = gql`
    mutation createUOMExchange($input: UomExchangeInput!) {
  createUOMExchange(input: $input)
}
    `;
export type CreateUomExchangeMutationFn = ApolloReactCommon.MutationFunction<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>;

/**
 * __useCreateUomExchangeMutation__
 *
 * To run a mutation, you first call `useCreateUomExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUomExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUomExchangeMutation, { data, loading, error }] = useCreateUomExchangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUomExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>(CreateUomExchangeDocument, baseOptions);
      }
export type CreateUomExchangeMutationHookResult = ReturnType<typeof useCreateUomExchangeMutation>;
export type CreateUomExchangeMutationResult = ApolloReactCommon.MutationResult<CreateUomExchangeMutation>;
export type CreateUomExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>;
export const UpdateUomExchangeDocument = gql`
    mutation updateUOMExchange($input: UomExchangeInput!) {
  updateUOMExchange(input: $input)
}
    `;
export type UpdateUomExchangeMutationFn = ApolloReactCommon.MutationFunction<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>;

/**
 * __useUpdateUomExchangeMutation__
 *
 * To run a mutation, you first call `useUpdateUomExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUomExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomExchangeMutation, { data, loading, error }] = useUpdateUomExchangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUomExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>(UpdateUomExchangeDocument, baseOptions);
      }
export type UpdateUomExchangeMutationHookResult = ReturnType<typeof useUpdateUomExchangeMutation>;
export type UpdateUomExchangeMutationResult = ApolloReactCommon.MutationResult<UpdateUomExchangeMutation>;
export type UpdateUomExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>;
export const DeleteUomExchangeDocument = gql`
    mutation deleteUOMExchange($ID: String!) {
  deleteUOMExchange(ID: $ID)
}
    `;
export type DeleteUomExchangeMutationFn = ApolloReactCommon.MutationFunction<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>;

/**
 * __useDeleteUomExchangeMutation__
 *
 * To run a mutation, you first call `useDeleteUomExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUomExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUomExchangeMutation, { data, loading, error }] = useDeleteUomExchangeMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteUomExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>(DeleteUomExchangeDocument, baseOptions);
      }
export type DeleteUomExchangeMutationHookResult = ReturnType<typeof useDeleteUomExchangeMutation>;
export type DeleteUomExchangeMutationResult = ApolloReactCommon.MutationResult<DeleteUomExchangeMutation>;
export type DeleteUomExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUomExchangeMutation, DeleteUomExchangeMutationVariables>;
export const DocumentListingDocument = gql`
    query DocumentListing($refID: String, $refTable: String, $description: String) {
  DocumentListing(refID: $refID, refTable: $refTable, description: $description) {
    AttachmentID
    fileName
    fileURL
    mediaType
    createdTs
    description
    refID
  }
}
    `;

/**
 * __useDocumentListingQuery__
 *
 * To run a query within a React component, call `useDocumentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentListingQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      refTable: // value for 'refTable'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useDocumentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
      }
export function useDocumentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
        }
export type DocumentListingQueryHookResult = ReturnType<typeof useDocumentListingQuery>;
export type DocumentListingLazyQueryHookResult = ReturnType<typeof useDocumentListingLazyQuery>;
export type DocumentListingQueryResult = ApolloReactCommon.QueryResult<DocumentListingQuery, DocumentListingQueryVariables>;
export const GetGeneralGrtNsDocument = gql`
    query getGeneralGRTNs($CompanyID: String, $SupplierID: String, $DeliveryLocationID: String, $WarehouseID: String) {
  getGeneralGRTNs(CompanyID: $CompanyID, SupplierID: $SupplierID, DeliveryLocationID: $DeliveryLocationID, WarehouseID: $WarehouseID) {
    createdTs
    createdBy
    modTs
    modBy
    GRTNHeaderID
    AccountID
    SupplierID
    DeliveryLocationID
    Supplier {
      CompanyName
      CompanyRegNo
    }
    DeliveryLocationID
    CompanyID
    DeliveryLocation {
      Name
      Address
      ContactNo
      Latitude
      Longitude
      MainLocation
    }
    WarehouseID
    Warehouse {
      Name
      Code
      Address
    }
    ApprovalStatus
    RecordStatus
    DocDate
    DocNo
    RefNo
    Description
    TransactionDate
    GRTNHeaderItem {
      GRTNItemID
      DOItemID
      DOItem {
        DOItemID
        DOHeaderID
        POItemID
        POItem {
          POHeader {
            DocNo
          }
          POHeaderID
          POItemID
          PRItemID
          UnitPrice
          BaseAmt
          TaxAmt
          TotalAmt
          Remarks
          OrderedQty
          OutstandingQty
          CostCentreID
          CostCentre {
            CostCentreID
            Name
            Code
          }
          Item {
            ItemID
            Name
            UOM {
              Name
              Code
            }
          }
        }
        UpToDateQty
        AcceptedQty
        ReturnedQty
        DOHeader {
          DocNo
          DOHeaderID
          DoNo
          Description
        }
      }
      OutstandingQty
      ReturnedQty
      ReturnedAmt
      Replacement
      GRTNHeaderID
      GRTNHeader {
        DocNo
        DocDate
        RefNo
        Description
      }
    }
  }
}
    `;

/**
 * __useGetGeneralGrtNsQuery__
 *
 * To run a query within a React component, call `useGetGeneralGrtNsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralGrtNsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralGrtNsQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      SupplierID: // value for 'SupplierID'
 *      DeliveryLocationID: // value for 'DeliveryLocationID'
 *      WarehouseID: // value for 'WarehouseID'
 *   },
 * });
 */
export function useGetGeneralGrtNsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralGrtNsQuery, GetGeneralGrtNsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralGrtNsQuery, GetGeneralGrtNsQueryVariables>(GetGeneralGrtNsDocument, baseOptions);
      }
export function useGetGeneralGrtNsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralGrtNsQuery, GetGeneralGrtNsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralGrtNsQuery, GetGeneralGrtNsQueryVariables>(GetGeneralGrtNsDocument, baseOptions);
        }
export type GetGeneralGrtNsQueryHookResult = ReturnType<typeof useGetGeneralGrtNsQuery>;
export type GetGeneralGrtNsLazyQueryHookResult = ReturnType<typeof useGetGeneralGrtNsLazyQuery>;
export type GetGeneralGrtNsQueryResult = ApolloReactCommon.QueryResult<GetGeneralGrtNsQuery, GetGeneralGrtNsQueryVariables>;
export const GetGeneralPOsDocument = gql`
    query getGeneralPOs($CompanyID: String, $SupplierID: String, $DeliveryLocationID: String, $DOHeaderID: String) {
  getGeneralPOs(CompanyID: $CompanyID, SupplierID: $SupplierID, DeliveryLocationID: $DeliveryLocationID, DOHeaderID: $DOHeaderID) {
    POHeaderID
    AccountID
    SupplierID
    DeliveryLocationID
    Supplier {
      CompanyName
      CompanyRegNo
    }
    ContactPerson
    Company {
      Name
      CompanyRegNo
      CompanyTax
      ContactNo
      CompanyTax
      Address
      SSTNo
      GSTNo
    }
    DeliveryLocationID
    CompanyID
    DeliveryLocation {
      Name
      Address
      ContactNo
      Latitude
      Longitude
      MainLocation
    }
    DocNo
    DocDate
    ExpectedDate
    RecordStatus
    ApprovalStatus
    ContactPerson
    Instruction
    POItem {
      POItemID
      AccountID
      WarehouseID
      CostCentre {
        Name
      }
      PRItemID
      POHeaderID
      DOItem {
        DOItemID
        UpToDateQty
        AcceptedQty
        POItemID
      }
      TotalAmt
      TaxAmt
      BaseAmt
      UOMID
      DeliveryLocationID
      OrderedQty
      PRItemID
      PRItem {
        PRItemID
        CostCentreID
        Quantity
        DeliveryLocationID
        Remark
        PRHeader {
          PRHeaderID
          DocDate
          DocNo
          Instruction
          ContactNo
          ContactPerson
          DeliveryLocationID
        }
        Item {
          Name
        }
      }
      DOItem {
        UpToDateQty
        AcceptedQty
        POItemID
        DOItemID
      }
      UOMID
      UOM {
        UOMID
        Code
        Name
      }
      UOMExchange {
        uomid
        uomcode
        uomname
      }
      POHeaderID
      ItemID
      Item {
        Name
        Description
      }
      UnitPrice
      OutstandingQty
      TaxSchemeID
      TaxScheme {
        Code
        Description
      }
      Remarks
    }
  }
}
    `;

/**
 * __useGetGeneralPOsQuery__
 *
 * To run a query within a React component, call `useGetGeneralPOsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralPOsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralPOsQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      SupplierID: // value for 'SupplierID'
 *      DeliveryLocationID: // value for 'DeliveryLocationID'
 *      DOHeaderID: // value for 'DOHeaderID'
 *   },
 * });
 */
export function useGetGeneralPOsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralPOsQuery, GetGeneralPOsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralPOsQuery, GetGeneralPOsQueryVariables>(GetGeneralPOsDocument, baseOptions);
      }
export function useGetGeneralPOsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralPOsQuery, GetGeneralPOsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralPOsQuery, GetGeneralPOsQueryVariables>(GetGeneralPOsDocument, baseOptions);
        }
export type GetGeneralPOsQueryHookResult = ReturnType<typeof useGetGeneralPOsQuery>;
export type GetGeneralPOsLazyQueryHookResult = ReturnType<typeof useGetGeneralPOsLazyQuery>;
export type GetGeneralPOsQueryResult = ApolloReactCommon.QueryResult<GetGeneralPOsQuery, GetGeneralPOsQueryVariables>;
export const GetGeneralDoDocument = gql`
    query getGeneralDO($CompanyID: String, $ApprovalStatus: ApprovalStatus, $SupplierID: String, $DeliveryLocationID: String, $WarehouseID: String, $DOHeaderID: String, $orderByDesc: String) {
  getGeneralDO(CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus, SupplierID: $SupplierID, DeliveryLocationID: $DeliveryLocationID, WarehouseID: $WarehouseID, DOHeaderID: $DOHeaderID, orderByDesc: $orderByDesc) {
    DOHeaderID
    AccountID
    SupplierID
    CompanyID
    DeliveryLocationID
    WarehouseID
    DocDate
    DoNo
    DocNo
    Description
    TransactionDate
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    RecordStatus
    ApprovalStatus
    Supplier {
      CompanyName
      CreditorAccountID
    }
    Description
    DeliveryLocationID
    DeliveryLocation {
      DeliveryLocationID
      Name
      Address
    }
    RejectionDate
    RejectionRemark
    PurchaseOrder
    DOItem {
      DOItemID
      AcceptedQty
      AcceptedAmt
      DOHeaderID
      DOHeader {
        DOHeaderID
        Description
      }
      POItemID
      POItem {
        POItemID
        OutstandingQty
        UnitPrice
        OrderedQty
        CostCentreID
        Remarks
        POHeader {
          POHeaderID
          DocNo
          DocDate
          ApprovalStatus
          RecordStatus
        }
        CostCentre {
          CostCentreID
          Name
          Code
        }
        Item {
          ItemID
          Description
          Name
        }
        UOM {
          UOMID
          Name
          Code
        }
        RecordStatus
      }
      GRTNItemID
      GRTNItem {
        GRTNHeaderID
        GRTNItemID
        ReturnedQty
        ReturnedAmt
        Replacement
      }
      ReplacedGRTNItem {
        GRTNHeaderID
        GRTNItemID
        ReturnedQty
        ReturnedAmt
        Replacement
      }
      RecordStatus
      UpToDateQty
      ReturnedQty
    }
    UpToDateAmt
  }
}
    `;

/**
 * __useGetGeneralDoQuery__
 *
 * To run a query within a React component, call `useGetGeneralDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralDoQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      SupplierID: // value for 'SupplierID'
 *      DeliveryLocationID: // value for 'DeliveryLocationID'
 *      WarehouseID: // value for 'WarehouseID'
 *      DOHeaderID: // value for 'DOHeaderID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetGeneralDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralDoQuery, GetGeneralDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralDoQuery, GetGeneralDoQueryVariables>(GetGeneralDoDocument, baseOptions);
      }
export function useGetGeneralDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralDoQuery, GetGeneralDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralDoQuery, GetGeneralDoQueryVariables>(GetGeneralDoDocument, baseOptions);
        }
export type GetGeneralDoQueryHookResult = ReturnType<typeof useGetGeneralDoQuery>;
export type GetGeneralDoLazyQueryHookResult = ReturnType<typeof useGetGeneralDoLazyQuery>;
export type GetGeneralDoQueryResult = ApolloReactCommon.QueryResult<GetGeneralDoQuery, GetGeneralDoQueryVariables>;
export const SubmitGeneralDoDocument = gql`
    mutation submitGeneralDO($DOHeaderID: String, $doInput: DOInput!, $doItemInput: [DOItemInput!]!) {
  submitGeneralDO(DOHeaderID: $DOHeaderID, doInput: $doInput, doItemInput: $doItemInput)
}
    `;
export type SubmitGeneralDoMutationFn = ApolloReactCommon.MutationFunction<SubmitGeneralDoMutation, SubmitGeneralDoMutationVariables>;

/**
 * __useSubmitGeneralDoMutation__
 *
 * To run a mutation, you first call `useSubmitGeneralDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitGeneralDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitGeneralDoMutation, { data, loading, error }] = useSubmitGeneralDoMutation({
 *   variables: {
 *      DOHeaderID: // value for 'DOHeaderID'
 *      doInput: // value for 'doInput'
 *      doItemInput: // value for 'doItemInput'
 *   },
 * });
 */
export function useSubmitGeneralDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitGeneralDoMutation, SubmitGeneralDoMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitGeneralDoMutation, SubmitGeneralDoMutationVariables>(SubmitGeneralDoDocument, baseOptions);
      }
export type SubmitGeneralDoMutationHookResult = ReturnType<typeof useSubmitGeneralDoMutation>;
export type SubmitGeneralDoMutationResult = ApolloReactCommon.MutationResult<SubmitGeneralDoMutation>;
export type SubmitGeneralDoMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitGeneralDoMutation, SubmitGeneralDoMutationVariables>;
export const GetSupplierByDoStatusDocument = gql`
    query getSupplierByDOStatus($CompanyID: String!, $ApprovalStatus: ApprovalStatus!) {
  getSupplierByDOStatus(CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus) {
    CreditorAccountID
    AccountID
    CompanyRegNo
    CreditorTypeID
    TaxSchemeID
    CompanyRegNo
    ContactNo
    Email
    CreditTerm
    GSTRegNo
    Remark
    BankName
    BankAccountNo
    Address
    CompanyName
    CreditorStatus
  }
}
    `;

/**
 * __useGetSupplierByDoStatusQuery__
 *
 * To run a query within a React component, call `useGetSupplierByDoStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierByDoStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierByDoStatusQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *   },
 * });
 */
export function useGetSupplierByDoStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>(GetSupplierByDoStatusDocument, baseOptions);
      }
export function useGetSupplierByDoStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>(GetSupplierByDoStatusDocument, baseOptions);
        }
export type GetSupplierByDoStatusQueryHookResult = ReturnType<typeof useGetSupplierByDoStatusQuery>;
export type GetSupplierByDoStatusLazyQueryHookResult = ReturnType<typeof useGetSupplierByDoStatusLazyQuery>;
export type GetSupplierByDoStatusQueryResult = ApolloReactCommon.QueryResult<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>;
export const GetWarehouseDeliveryLocationDocument = gql`
    query getWarehouseDeliveryLocation($CompanyID: String) {
  getWarehouseDeliveryLocation(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetWarehouseDeliveryLocationQuery__
 *
 * To run a query within a React component, call `useGetWarehouseDeliveryLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseDeliveryLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseDeliveryLocationQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetWarehouseDeliveryLocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWarehouseDeliveryLocationQuery, GetWarehouseDeliveryLocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWarehouseDeliveryLocationQuery, GetWarehouseDeliveryLocationQueryVariables>(GetWarehouseDeliveryLocationDocument, baseOptions);
      }
export function useGetWarehouseDeliveryLocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWarehouseDeliveryLocationQuery, GetWarehouseDeliveryLocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWarehouseDeliveryLocationQuery, GetWarehouseDeliveryLocationQueryVariables>(GetWarehouseDeliveryLocationDocument, baseOptions);
        }
export type GetWarehouseDeliveryLocationQueryHookResult = ReturnType<typeof useGetWarehouseDeliveryLocationQuery>;
export type GetWarehouseDeliveryLocationLazyQueryHookResult = ReturnType<typeof useGetWarehouseDeliveryLocationLazyQuery>;
export type GetWarehouseDeliveryLocationQueryResult = ApolloReactCommon.QueryResult<GetWarehouseDeliveryLocationQuery, GetWarehouseDeliveryLocationQueryVariables>;
export const DeleteGeneralDoDocument = gql`
    mutation deleteGeneralDO($DOHeaderID: String!) {
  deleteGeneralDO(DOHeaderID: $DOHeaderID)
}
    `;
export type DeleteGeneralDoMutationFn = ApolloReactCommon.MutationFunction<DeleteGeneralDoMutation, DeleteGeneralDoMutationVariables>;

/**
 * __useDeleteGeneralDoMutation__
 *
 * To run a mutation, you first call `useDeleteGeneralDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGeneralDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGeneralDoMutation, { data, loading, error }] = useDeleteGeneralDoMutation({
 *   variables: {
 *      DOHeaderID: // value for 'DOHeaderID'
 *   },
 * });
 */
export function useDeleteGeneralDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGeneralDoMutation, DeleteGeneralDoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGeneralDoMutation, DeleteGeneralDoMutationVariables>(DeleteGeneralDoDocument, baseOptions);
      }
export type DeleteGeneralDoMutationHookResult = ReturnType<typeof useDeleteGeneralDoMutation>;
export type DeleteGeneralDoMutationResult = ApolloReactCommon.MutationResult<DeleteGeneralDoMutation>;
export type DeleteGeneralDoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGeneralDoMutation, DeleteGeneralDoMutationVariables>;
export const ActionOnGdoStatusDocument = gql`
    mutation actionOnGDOStatus($input: GDOActionInput!, $DOHeaderID: [String!]!) {
  actionOnGDOStatus(input: $input, DOHeaderID: $DOHeaderID)
}
    `;
export type ActionOnGdoStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnGdoStatusMutation, ActionOnGdoStatusMutationVariables>;

/**
 * __useActionOnGdoStatusMutation__
 *
 * To run a mutation, you first call `useActionOnGdoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnGdoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnGdoStatusMutation, { data, loading, error }] = useActionOnGdoStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      DOHeaderID: // value for 'DOHeaderID'
 *   },
 * });
 */
export function useActionOnGdoStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnGdoStatusMutation, ActionOnGdoStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnGdoStatusMutation, ActionOnGdoStatusMutationVariables>(ActionOnGdoStatusDocument, baseOptions);
      }
export type ActionOnGdoStatusMutationHookResult = ReturnType<typeof useActionOnGdoStatusMutation>;
export type ActionOnGdoStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnGdoStatusMutation>;
export type ActionOnGdoStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnGdoStatusMutation, ActionOnGdoStatusMutationVariables>;
export const GetGrtnHeaderDocument = gql`
    query getGRTNHeader($CompanyID: String, $orderByDesc: String, $ApprovalStatus: ApprovalStatus, $GRTNHeaderID: String) {
  getGRTNHeader(CompanyID: $CompanyID, orderByDesc: $orderByDesc, ApprovalStatus: $ApprovalStatus, GRTNHeaderID: $GRTNHeaderID) {
    createdTs
    createdBy
    modTs
    modBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    GRTNHeaderID
    AccountID
    SupplierID
    CompanyID
    DeliveryLocationID
    DeliveryLocation {
      Name
      Address
    }
    WarehouseID
    Warehouse {
      WarehouseID
      Name
      Code
      Address
    }
    DocDate
    TransactionDate
    DocNo
    RefNo
    Description
    RecordStatus
    Supplier {
      CompanyName
    }
    ApprovalStatus
    GRTNHeaderItem {
      GRTNItemID
      DOItemID
      DOItem {
        DOItemID
        DOHeaderID
        POItemID
        POItem {
          PRItemID
          UnitPrice
          BaseAmt
          TaxAmt
          TotalAmt
          Remarks
          OrderedQty
          OutstandingQty
          Item {
            Name
            UOM {
              Name
              Code
            }
          }
        }
        UpToDateQty
        AcceptedQty
        ReturnedQty
        DOHeader {
          DocNo
          DOHeaderID
          DoNo
          Description
        }
      }
      OutstandingQty
      ReturnedQty
      ReturnedAmt
      Replacement
      GRTNHeader {
        DocNo
        DocDate
        RefNo
        Description
      }
    }
  }
}
    `;

/**
 * __useGetGrtnHeaderQuery__
 *
 * To run a query within a React component, call `useGetGrtnHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrtnHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrtnHeaderQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      orderByDesc: // value for 'orderByDesc'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      GRTNHeaderID: // value for 'GRTNHeaderID'
 *   },
 * });
 */
export function useGetGrtnHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGrtnHeaderQuery, GetGrtnHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGrtnHeaderQuery, GetGrtnHeaderQueryVariables>(GetGrtnHeaderDocument, baseOptions);
      }
export function useGetGrtnHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGrtnHeaderQuery, GetGrtnHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGrtnHeaderQuery, GetGrtnHeaderQueryVariables>(GetGrtnHeaderDocument, baseOptions);
        }
export type GetGrtnHeaderQueryHookResult = ReturnType<typeof useGetGrtnHeaderQuery>;
export type GetGrtnHeaderLazyQueryHookResult = ReturnType<typeof useGetGrtnHeaderLazyQuery>;
export type GetGrtnHeaderQueryResult = ApolloReactCommon.QueryResult<GetGrtnHeaderQuery, GetGrtnHeaderQueryVariables>;
export const CreateUpdateGrtnDocument = gql`
    mutation createUpdateGRTN($GRTNHeaderID: String, $grtnInput: GeneralGRTNInput!, $grtnItemInput: [GeneralGRTNItemInput!]!) {
  createUpdateGRTN(GRTNHeaderID: $GRTNHeaderID, grtnInput: $grtnInput, grtnItemInput: $grtnItemInput)
}
    `;
export type CreateUpdateGrtnMutationFn = ApolloReactCommon.MutationFunction<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>;

/**
 * __useCreateUpdateGrtnMutation__
 *
 * To run a mutation, you first call `useCreateUpdateGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateGrtnMutation, { data, loading, error }] = useCreateUpdateGrtnMutation({
 *   variables: {
 *      GRTNHeaderID: // value for 'GRTNHeaderID'
 *      grtnInput: // value for 'grtnInput'
 *      grtnItemInput: // value for 'grtnItemInput'
 *   },
 * });
 */
export function useCreateUpdateGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>(CreateUpdateGrtnDocument, baseOptions);
      }
export type CreateUpdateGrtnMutationHookResult = ReturnType<typeof useCreateUpdateGrtnMutation>;
export type CreateUpdateGrtnMutationResult = ApolloReactCommon.MutationResult<CreateUpdateGrtnMutation>;
export type CreateUpdateGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>;
export const CreateGrtnDocument = gql`
    mutation CreateGRTN($grtnInput: GeneralGRTNInput!, $grtnItemInput: [GeneralGRTNItemInput!]!) {
  CreateGRTN(grtnInput: $grtnInput, grtnItemInput: $grtnItemInput)
}
    `;
export type CreateGrtnMutationFn = ApolloReactCommon.MutationFunction<CreateGrtnMutation, CreateGrtnMutationVariables>;

/**
 * __useCreateGrtnMutation__
 *
 * To run a mutation, you first call `useCreateGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGrtnMutation, { data, loading, error }] = useCreateGrtnMutation({
 *   variables: {
 *      grtnInput: // value for 'grtnInput'
 *      grtnItemInput: // value for 'grtnItemInput'
 *   },
 * });
 */
export function useCreateGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGrtnMutation, CreateGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGrtnMutation, CreateGrtnMutationVariables>(CreateGrtnDocument, baseOptions);
      }
export type CreateGrtnMutationHookResult = ReturnType<typeof useCreateGrtnMutation>;
export type CreateGrtnMutationResult = ApolloReactCommon.MutationResult<CreateGrtnMutation>;
export type CreateGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGrtnMutation, CreateGrtnMutationVariables>;
export const UpdateGrtnDocument = gql`
    mutation UpdateGRTN($GRTNHeaderID: String, $grtnInput: GeneralGRTNInput!, $grtnItemInput: [GeneralGRTNItemInput!]!) {
  UpdateGRTN(GRTNHeaderID: $GRTNHeaderID, grtnInput: $grtnInput, grtnItemInput: $grtnItemInput)
}
    `;
export type UpdateGrtnMutationFn = ApolloReactCommon.MutationFunction<UpdateGrtnMutation, UpdateGrtnMutationVariables>;

/**
 * __useUpdateGrtnMutation__
 *
 * To run a mutation, you first call `useUpdateGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGrtnMutation, { data, loading, error }] = useUpdateGrtnMutation({
 *   variables: {
 *      GRTNHeaderID: // value for 'GRTNHeaderID'
 *      grtnInput: // value for 'grtnInput'
 *      grtnItemInput: // value for 'grtnItemInput'
 *   },
 * });
 */
export function useUpdateGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGrtnMutation, UpdateGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGrtnMutation, UpdateGrtnMutationVariables>(UpdateGrtnDocument, baseOptions);
      }
export type UpdateGrtnMutationHookResult = ReturnType<typeof useUpdateGrtnMutation>;
export type UpdateGrtnMutationResult = ApolloReactCommon.MutationResult<UpdateGrtnMutation>;
export type UpdateGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGrtnMutation, UpdateGrtnMutationVariables>;
export const GetWarehouseDeliveryDocument = gql`
    query getWarehouseDelivery($CompanyID: String) {
  getWarehouseDeliveryLocation(CompanyID: $CompanyID)
}
    `;

/**
 * __useGetWarehouseDeliveryQuery__
 *
 * To run a query within a React component, call `useGetWarehouseDeliveryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseDeliveryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseDeliveryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetWarehouseDeliveryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWarehouseDeliveryQuery, GetWarehouseDeliveryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWarehouseDeliveryQuery, GetWarehouseDeliveryQueryVariables>(GetWarehouseDeliveryDocument, baseOptions);
      }
export function useGetWarehouseDeliveryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWarehouseDeliveryQuery, GetWarehouseDeliveryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWarehouseDeliveryQuery, GetWarehouseDeliveryQueryVariables>(GetWarehouseDeliveryDocument, baseOptions);
        }
export type GetWarehouseDeliveryQueryHookResult = ReturnType<typeof useGetWarehouseDeliveryQuery>;
export type GetWarehouseDeliveryLazyQueryHookResult = ReturnType<typeof useGetWarehouseDeliveryLazyQuery>;
export type GetWarehouseDeliveryQueryResult = ApolloReactCommon.QueryResult<GetWarehouseDeliveryQuery, GetWarehouseDeliveryQueryVariables>;
export const DeleteGrtnDocument = gql`
    mutation DeleteGRTN($GRTNHeaderID: String!) {
  deleteGRTN(GRTNHeaderID: $GRTNHeaderID)
}
    `;
export type DeleteGrtnMutationFn = ApolloReactCommon.MutationFunction<DeleteGrtnMutation, DeleteGrtnMutationVariables>;

/**
 * __useDeleteGrtnMutation__
 *
 * To run a mutation, you first call `useDeleteGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGrtnMutation, { data, loading, error }] = useDeleteGrtnMutation({
 *   variables: {
 *      GRTNHeaderID: // value for 'GRTNHeaderID'
 *   },
 * });
 */
export function useDeleteGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGrtnMutation, DeleteGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGrtnMutation, DeleteGrtnMutationVariables>(DeleteGrtnDocument, baseOptions);
      }
export type DeleteGrtnMutationHookResult = ReturnType<typeof useDeleteGrtnMutation>;
export type DeleteGrtnMutationResult = ApolloReactCommon.MutationResult<DeleteGrtnMutation>;
export type DeleteGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGrtnMutation, DeleteGrtnMutationVariables>;
export const ActionOnGrtnStatusDocument = gql`
    mutation actionOnGRTNStatus($GRTNHeaderID: [String!]!, $ApprovalStatus: ApprovalStatus!, $input: GeneralGRTNRejectInput!, $CompanyID: String!) {
  actionOnGRTNStatus(GRTNHeaderID: $GRTNHeaderID, ApprovalStatus: $ApprovalStatus, input: $input, CompanyID: $CompanyID)
}
    `;
export type ActionOnGrtnStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnGrtnStatusMutation, ActionOnGrtnStatusMutationVariables>;

/**
 * __useActionOnGrtnStatusMutation__
 *
 * To run a mutation, you first call `useActionOnGrtnStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnGrtnStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnGrtnStatusMutation, { data, loading, error }] = useActionOnGrtnStatusMutation({
 *   variables: {
 *      GRTNHeaderID: // value for 'GRTNHeaderID'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      input: // value for 'input'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useActionOnGrtnStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnGrtnStatusMutation, ActionOnGrtnStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnGrtnStatusMutation, ActionOnGrtnStatusMutationVariables>(ActionOnGrtnStatusDocument, baseOptions);
      }
export type ActionOnGrtnStatusMutationHookResult = ReturnType<typeof useActionOnGrtnStatusMutation>;
export type ActionOnGrtnStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnGrtnStatusMutation>;
export type ActionOnGrtnStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnGrtnStatusMutation, ActionOnGrtnStatusMutationVariables>;
export const GetPoHeaderDocument = gql`
    query getPOHeader($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $POHeaderID: String, $SupplierID: String, $CompanyID: String, $ApprovalStatus: ApprovalStatus, $POType: POType) {
  getPOHeader(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take, POHeaderID: $POHeaderID, SupplierID: $SupplierID, CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus, POType: $POType) {
    POHeaderID
    AccountID
    modTs
    modBy
    createdTs
    createdBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    SupplierID
    POType
    Supplier {
      CompanyName
      CompanyRegNo
    }
    ContactPerson
    Company {
      Name
      CompanyRegNo
      ContactNo
      CompanyTax
      Address
      SSTNo
      GSTNo
    }
    DeliveryLocationID
    CompanyID
    DeliveryLocation {
      Name
      Address
      ContactNo
      Latitude
      Longitude
      MainLocation
    }
    DocNo
    TotalAmt
    DocDate
    ExpectedDate
    RecordStatus
    ApprovalStatus
    ContactPerson
    Instruction
    POItem {
      POItemID
      AccountID
      PRItemID
      POHeaderID
      TotalAmt
      TaxAmt
      BaseAmt
      UOMID
      CostCentreID
      CostCentre {
        Name
        Level
        Code
      }
      DeliveryLocationID
      DeliveryLocation {
        Name
        Address
        ContactNo
        Latitude
        Longitude
        MainLocation
      }
      WarehouseID
      Warehouse {
        Name
        Code
        Address
      }
      OrderedQty
      PRItemID
      PRItem {
        PRItemID
        CostCentreID
        Quantity
        DeliveryLocationID
        Remark
        PRHeader {
          PRHeaderID
          DocDate
          DocNo
          Instruction
          ContactNo
          ContactPerson
          DeliveryLocationID
        }
        Item {
          Name
          IsInventory
          IsCentralised
        }
      }
      DOItem {
        DOHeaderID
        AcceptedQty
      }
      UOMID
      UOM {
        UOMID
        Code
        Name
      }
      UOMExchange {
        uomid
        uomcode
        uomname
      }
      POHeaderID
      ItemID
      Item {
        Name
        Description
        IsInventory
      }
      UnitPrice
      OutstandingQty
      TaxSchemeID
      TaxScheme {
        AccountID
        TaxSchemeID
        Code
        Description
      }
      Remarks
      ScheduledPurchasesItemID
      ScheduledPurchaseDetail
    }
  }
}
    `;

/**
 * __useGetPoHeaderQuery__
 *
 * To run a query within a React component, call `useGetPoHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoHeaderQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      POHeaderID: // value for 'POHeaderID'
 *      SupplierID: // value for 'SupplierID'
 *      CompanyID: // value for 'CompanyID'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      POType: // value for 'POType'
 *   },
 * });
 */
export function useGetPoHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoHeaderQuery, GetPoHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoHeaderQuery, GetPoHeaderQueryVariables>(GetPoHeaderDocument, baseOptions);
      }
export function useGetPoHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoHeaderQuery, GetPoHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoHeaderQuery, GetPoHeaderQueryVariables>(GetPoHeaderDocument, baseOptions);
        }
export type GetPoHeaderQueryHookResult = ReturnType<typeof useGetPoHeaderQuery>;
export type GetPoHeaderLazyQueryHookResult = ReturnType<typeof useGetPoHeaderLazyQuery>;
export type GetPoHeaderQueryResult = ApolloReactCommon.QueryResult<GetPoHeaderQuery, GetPoHeaderQueryVariables>;
export const GetSiteWithAccountDocument = gql`
    query getSiteWithAccount($ID: String) {
  getSiteWithAccount(ID: $ID)
}
    `;

/**
 * __useGetSiteWithAccountQuery__
 *
 * To run a query within a React component, call `useGetSiteWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteWithAccountQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetSiteWithAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteWithAccountQuery, GetSiteWithAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteWithAccountQuery, GetSiteWithAccountQueryVariables>(GetSiteWithAccountDocument, baseOptions);
      }
export function useGetSiteWithAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteWithAccountQuery, GetSiteWithAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteWithAccountQuery, GetSiteWithAccountQueryVariables>(GetSiteWithAccountDocument, baseOptions);
        }
export type GetSiteWithAccountQueryHookResult = ReturnType<typeof useGetSiteWithAccountQuery>;
export type GetSiteWithAccountLazyQueryHookResult = ReturnType<typeof useGetSiteWithAccountLazyQuery>;
export type GetSiteWithAccountQueryResult = ApolloReactCommon.QueryResult<GetSiteWithAccountQuery, GetSiteWithAccountQueryVariables>;
export const GetOsGeneralPrDocument = gql`
    query getOSGeneralPR($CompanyID: String!) {
  getOSGeneralPR(CompanyID: $CompanyID) {
    PRHeaderID
    AccountID
    CompanyID
    DocNo
    DocDate
    ExpectedDate
    RequestedBy
    DeliveryLocationID
    ContactPerson
    ContactNo
    Instruction
    PRItem {
      PRItemID
      CostCentreID
      CostCentre {
        CostCentreID
        CompanyID
        Name
      }
      UOM {
        Code
        Name
      }
      Item {
        Name
      }
      Quantity
      POItem {
        UOMID
        TaxSchemeID
        OutstandingQty
        UnitPrice
      }
      Quantity
      ItemID
      POItem {
        OutstandingQty
        UnitPrice
      }
    }
  }
}
    `;

/**
 * __useGetOsGeneralPrQuery__
 *
 * To run a query within a React component, call `useGetOsGeneralPrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOsGeneralPrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOsGeneralPrQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetOsGeneralPrQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOsGeneralPrQuery, GetOsGeneralPrQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOsGeneralPrQuery, GetOsGeneralPrQueryVariables>(GetOsGeneralPrDocument, baseOptions);
      }
export function useGetOsGeneralPrLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOsGeneralPrQuery, GetOsGeneralPrQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOsGeneralPrQuery, GetOsGeneralPrQueryVariables>(GetOsGeneralPrDocument, baseOptions);
        }
export type GetOsGeneralPrQueryHookResult = ReturnType<typeof useGetOsGeneralPrQuery>;
export type GetOsGeneralPrLazyQueryHookResult = ReturnType<typeof useGetOsGeneralPrLazyQuery>;
export type GetOsGeneralPrQueryResult = ApolloReactCommon.QueryResult<GetOsGeneralPrQuery, GetOsGeneralPrQueryVariables>;
export const GetTaxSchemeWithAccountDocument = gql`
    query getTaxSchemeWithAccount($accountID: String!) {
  getTaxSchemeWithAccount(accountID: $accountID) {
    TaxSchemeID
    Code
    Description
    TaxTypeID
    TaxType {
      Code
      Description
    }
    AccTaxCategory
    AccTaxClass
    MasterCOAID
    LatestTax {
      Rate
      Date
    }
  }
}
    `;

/**
 * __useGetTaxSchemeWithAccountQuery__
 *
 * To run a query within a React component, call `useGetTaxSchemeWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaxSchemeWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaxSchemeWithAccountQuery({
 *   variables: {
 *      accountID: // value for 'accountID'
 *   },
 * });
 */
export function useGetTaxSchemeWithAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaxSchemeWithAccountQuery, GetTaxSchemeWithAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaxSchemeWithAccountQuery, GetTaxSchemeWithAccountQueryVariables>(GetTaxSchemeWithAccountDocument, baseOptions);
      }
export function useGetTaxSchemeWithAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaxSchemeWithAccountQuery, GetTaxSchemeWithAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaxSchemeWithAccountQuery, GetTaxSchemeWithAccountQueryVariables>(GetTaxSchemeWithAccountDocument, baseOptions);
        }
export type GetTaxSchemeWithAccountQueryHookResult = ReturnType<typeof useGetTaxSchemeWithAccountQuery>;
export type GetTaxSchemeWithAccountLazyQueryHookResult = ReturnType<typeof useGetTaxSchemeWithAccountLazyQuery>;
export type GetTaxSchemeWithAccountQueryResult = ApolloReactCommon.QueryResult<GetTaxSchemeWithAccountQuery, GetTaxSchemeWithAccountQueryVariables>;
export const CreateDirectPoDocument = gql`
    mutation createDirectPO($input: DirectPOInput!) {
  createDirectPO(input: $input)
}
    `;
export type CreateDirectPoMutationFn = ApolloReactCommon.MutationFunction<CreateDirectPoMutation, CreateDirectPoMutationVariables>;

/**
 * __useCreateDirectPoMutation__
 *
 * To run a mutation, you first call `useCreateDirectPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDirectPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDirectPoMutation, { data, loading, error }] = useCreateDirectPoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDirectPoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDirectPoMutation, CreateDirectPoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDirectPoMutation, CreateDirectPoMutationVariables>(CreateDirectPoDocument, baseOptions);
      }
export type CreateDirectPoMutationHookResult = ReturnType<typeof useCreateDirectPoMutation>;
export type CreateDirectPoMutationResult = ApolloReactCommon.MutationResult<CreateDirectPoMutation>;
export type CreateDirectPoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDirectPoMutation, CreateDirectPoMutationVariables>;
export const UpdateDirectPoDocument = gql`
    mutation updateDirectPO($POHeaderID: String, $input: DirectPOInput!) {
  updateDirectPO(POHeaderID: $POHeaderID, input: $input)
}
    `;
export type UpdateDirectPoMutationFn = ApolloReactCommon.MutationFunction<UpdateDirectPoMutation, UpdateDirectPoMutationVariables>;

/**
 * __useUpdateDirectPoMutation__
 *
 * To run a mutation, you first call `useUpdateDirectPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDirectPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDirectPoMutation, { data, loading, error }] = useUpdateDirectPoMutation({
 *   variables: {
 *      POHeaderID: // value for 'POHeaderID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDirectPoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDirectPoMutation, UpdateDirectPoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDirectPoMutation, UpdateDirectPoMutationVariables>(UpdateDirectPoDocument, baseOptions);
      }
export type UpdateDirectPoMutationHookResult = ReturnType<typeof useUpdateDirectPoMutation>;
export type UpdateDirectPoMutationResult = ApolloReactCommon.MutationResult<UpdateDirectPoMutation>;
export type UpdateDirectPoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDirectPoMutation, UpdateDirectPoMutationVariables>;
export const DeletePoDocument = gql`
    mutation deletePO($POHeaderID: String!) {
  deletePO(POHeaderID: $POHeaderID)
}
    `;
export type DeletePoMutationFn = ApolloReactCommon.MutationFunction<DeletePoMutation, DeletePoMutationVariables>;

/**
 * __useDeletePoMutation__
 *
 * To run a mutation, you first call `useDeletePoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePoMutation, { data, loading, error }] = useDeletePoMutation({
 *   variables: {
 *      POHeaderID: // value for 'POHeaderID'
 *   },
 * });
 */
export function useDeletePoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeletePoMutation, DeletePoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeletePoMutation, DeletePoMutationVariables>(DeletePoDocument, baseOptions);
      }
export type DeletePoMutationHookResult = ReturnType<typeof useDeletePoMutation>;
export type DeletePoMutationResult = ApolloReactCommon.MutationResult<DeletePoMutation>;
export type DeletePoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeletePoMutation, DeletePoMutationVariables>;
export const ActionOnGpoStatusDocument = gql`
    mutation actionOnGPOStatus($CompanyID: String!, $ApprovalStatus: ApprovalStatus!, $input: PORejectInput!, $POHeaderID: [String!]!) {
  actionOnGPOStatus(CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus, input: $input, POHeaderID: $POHeaderID)
}
    `;
export type ActionOnGpoStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnGpoStatusMutation, ActionOnGpoStatusMutationVariables>;

/**
 * __useActionOnGpoStatusMutation__
 *
 * To run a mutation, you first call `useActionOnGpoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnGpoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnGpoStatusMutation, { data, loading, error }] = useActionOnGpoStatusMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      input: // value for 'input'
 *      POHeaderID: // value for 'POHeaderID'
 *   },
 * });
 */
export function useActionOnGpoStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnGpoStatusMutation, ActionOnGpoStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnGpoStatusMutation, ActionOnGpoStatusMutationVariables>(ActionOnGpoStatusDocument, baseOptions);
      }
export type ActionOnGpoStatusMutationHookResult = ReturnType<typeof useActionOnGpoStatusMutation>;
export type ActionOnGpoStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnGpoStatusMutation>;
export type ActionOnGpoStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnGpoStatusMutation, ActionOnGpoStatusMutationVariables>;
export const GetWarehouseDocument = gql`
    query getWarehouse($CompanyID: String, $AccountID: String, $orderByAsc: String) {
  getWarehouse(CompanyID: $CompanyID, AccountID: $AccountID, orderByAsc: $orderByAsc) {
    WarehouseID
    Name
    Code
    Address
    CostCentreID
  }
}
    `;

/**
 * __useGetWarehouseQuery__
 *
 * To run a query within a React component, call `useGetWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      AccountID: // value for 'AccountID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetWarehouseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWarehouseQuery, GetWarehouseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWarehouseQuery, GetWarehouseQueryVariables>(GetWarehouseDocument, baseOptions);
      }
export function useGetWarehouseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWarehouseQuery, GetWarehouseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWarehouseQuery, GetWarehouseQueryVariables>(GetWarehouseDocument, baseOptions);
        }
export type GetWarehouseQueryHookResult = ReturnType<typeof useGetWarehouseQuery>;
export type GetWarehouseLazyQueryHookResult = ReturnType<typeof useGetWarehouseLazyQuery>;
export type GetWarehouseQueryResult = ApolloReactCommon.QueryResult<GetWarehouseQuery, GetWarehouseQueryVariables>;
export const GetCreditorSupplierTypeDocument = gql`
    query getCreditorSupplierType($orderByDesc: String, $orderByAsc: String, $AccountID: String, $CreditorAccountID: String, $CreditorTypeEnum: CreditorType) {
  getCreditorAccount(orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, AccountID: $AccountID, CreditorAccountID: $CreditorAccountID, CreditorTypeEnum: $CreditorTypeEnum) {
    CreditorAccountID
    CreditorTypeID
    CompanyName
    ContactNo
    TaxSchemeID
    CreditorTypeEnum
    CreditorStatus
  }
}
    `;

/**
 * __useGetCreditorSupplierTypeQuery__
 *
 * To run a query within a React component, call `useGetCreditorSupplierTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorSupplierTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorSupplierTypeQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      AccountID: // value for 'AccountID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *      CreditorTypeEnum: // value for 'CreditorTypeEnum'
 *   },
 * });
 */
export function useGetCreditorSupplierTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorSupplierTypeQuery, GetCreditorSupplierTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorSupplierTypeQuery, GetCreditorSupplierTypeQueryVariables>(GetCreditorSupplierTypeDocument, baseOptions);
      }
export function useGetCreditorSupplierTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorSupplierTypeQuery, GetCreditorSupplierTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorSupplierTypeQuery, GetCreditorSupplierTypeQueryVariables>(GetCreditorSupplierTypeDocument, baseOptions);
        }
export type GetCreditorSupplierTypeQueryHookResult = ReturnType<typeof useGetCreditorSupplierTypeQuery>;
export type GetCreditorSupplierTypeLazyQueryHookResult = ReturnType<typeof useGetCreditorSupplierTypeLazyQuery>;
export type GetCreditorSupplierTypeQueryResult = ApolloReactCommon.QueryResult<GetCreditorSupplierTypeQuery, GetCreditorSupplierTypeQueryVariables>;
export const CreateGeneralPoDocument = gql`
    mutation createGeneralPO($generalPOInput: [POInput!]!) {
  createGeneralPO(generalPOInput: $generalPOInput)
}
    `;
export type CreateGeneralPoMutationFn = ApolloReactCommon.MutationFunction<CreateGeneralPoMutation, CreateGeneralPoMutationVariables>;

/**
 * __useCreateGeneralPoMutation__
 *
 * To run a mutation, you first call `useCreateGeneralPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGeneralPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGeneralPoMutation, { data, loading, error }] = useCreateGeneralPoMutation({
 *   variables: {
 *      generalPOInput: // value for 'generalPOInput'
 *   },
 * });
 */
export function useCreateGeneralPoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGeneralPoMutation, CreateGeneralPoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGeneralPoMutation, CreateGeneralPoMutationVariables>(CreateGeneralPoDocument, baseOptions);
      }
export type CreateGeneralPoMutationHookResult = ReturnType<typeof useCreateGeneralPoMutation>;
export type CreateGeneralPoMutationResult = ApolloReactCommon.MutationResult<CreateGeneralPoMutation>;
export type CreateGeneralPoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGeneralPoMutation, CreateGeneralPoMutationVariables>;
export const GetSupplierAccountByCompanyDocument = gql`
    query getSupplierAccountByCompany($CompanyID: String!, $CreditorAccountID: String) {
  getSupplierAccountByCompany(CompanyID: $CompanyID, CreditorAccountID: $CreditorAccountID)
}
    `;

/**
 * __useGetSupplierAccountByCompanyQuery__
 *
 * To run a query within a React component, call `useGetSupplierAccountByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierAccountByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierAccountByCompanyQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetSupplierAccountByCompanyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierAccountByCompanyQuery, GetSupplierAccountByCompanyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierAccountByCompanyQuery, GetSupplierAccountByCompanyQueryVariables>(GetSupplierAccountByCompanyDocument, baseOptions);
      }
export function useGetSupplierAccountByCompanyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierAccountByCompanyQuery, GetSupplierAccountByCompanyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierAccountByCompanyQuery, GetSupplierAccountByCompanyQueryVariables>(GetSupplierAccountByCompanyDocument, baseOptions);
        }
export type GetSupplierAccountByCompanyQueryHookResult = ReturnType<typeof useGetSupplierAccountByCompanyQuery>;
export type GetSupplierAccountByCompanyLazyQueryHookResult = ReturnType<typeof useGetSupplierAccountByCompanyLazyQuery>;
export type GetSupplierAccountByCompanyQueryResult = ApolloReactCommon.QueryResult<GetSupplierAccountByCompanyQuery, GetSupplierAccountByCompanyQueryVariables>;
export const CheckingCancelClosePoDocument = gql`
    query checkingCancelClosePO($POHeaderID: String!) {
  checkingCancelClosePO(POHeaderID: $POHeaderID)
}
    `;

/**
 * __useCheckingCancelClosePoQuery__
 *
 * To run a query within a React component, call `useCheckingCancelClosePoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckingCancelClosePoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckingCancelClosePoQuery({
 *   variables: {
 *      POHeaderID: // value for 'POHeaderID'
 *   },
 * });
 */
export function useCheckingCancelClosePoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>(CheckingCancelClosePoDocument, baseOptions);
      }
export function useCheckingCancelClosePoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>(CheckingCancelClosePoDocument, baseOptions);
        }
export type CheckingCancelClosePoQueryHookResult = ReturnType<typeof useCheckingCancelClosePoQuery>;
export type CheckingCancelClosePoLazyQueryHookResult = ReturnType<typeof useCheckingCancelClosePoLazyQuery>;
export type CheckingCancelClosePoQueryResult = ApolloReactCommon.QueryResult<CheckingCancelClosePoQuery, CheckingCancelClosePoQueryVariables>;
export const CancelPoDocument = gql`
    mutation cancelPO($input: PurchaseActionInput!, $POHeaderID: String!) {
  cancelClosePO(input: $input, POHeaderID: $POHeaderID)
}
    `;
export type CancelPoMutationFn = ApolloReactCommon.MutationFunction<CancelPoMutation, CancelPoMutationVariables>;

/**
 * __useCancelPoMutation__
 *
 * To run a mutation, you first call `useCancelPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPoMutation, { data, loading, error }] = useCancelPoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      POHeaderID: // value for 'POHeaderID'
 *   },
 * });
 */
export function useCancelPoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelPoMutation, CancelPoMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelPoMutation, CancelPoMutationVariables>(CancelPoDocument, baseOptions);
      }
export type CancelPoMutationHookResult = ReturnType<typeof useCancelPoMutation>;
export type CancelPoMutationResult = ApolloReactCommon.MutationResult<CancelPoMutation>;
export type CancelPoMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelPoMutation, CancelPoMutationVariables>;
export const GetCentralisedPrItemsDocument = gql`
    query getCentralisedPRItems {
  getCentralisedPRItems {
    PRItemID
    WarehouseID
    Warehouse {
      Name
    }
    PRHeaderID
    PRHeader {
      CompanyID
      ExpectedDate
      DocNo
      DocDate
      RequestedBy
      ContactPerson
      ContactNo
      Instruction
      DeliveryLocationID
      DeliveryLocation {
        CompanyID
        Name
        Address
        ContactNo
        RecordStatus
      }
    }
    Quantity
    ItemID
    Item {
      RecordStatus
      Name
      Description
      UOMID
      UOM {
        Name
        Code
        RecordStatus
      }
      TaxSchemeID
      TaxScheme {
        Code
        Description
      }
    }
    UOMID
    UOM {
      Name
      Code
      RecordStatus
    }
    CostCentreID
    Remark
    IsPOGenerated
    rfqSubmissionSelected
    bulkPurchaseSelected
    masterFileSelected
  }
}
    `;

/**
 * __useGetCentralisedPrItemsQuery__
 *
 * To run a query within a React component, call `useGetCentralisedPrItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCentralisedPrItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCentralisedPrItemsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCentralisedPrItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCentralisedPrItemsQuery, GetCentralisedPrItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCentralisedPrItemsQuery, GetCentralisedPrItemsQueryVariables>(GetCentralisedPrItemsDocument, baseOptions);
      }
export function useGetCentralisedPrItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCentralisedPrItemsQuery, GetCentralisedPrItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCentralisedPrItemsQuery, GetCentralisedPrItemsQueryVariables>(GetCentralisedPrItemsDocument, baseOptions);
        }
export type GetCentralisedPrItemsQueryHookResult = ReturnType<typeof useGetCentralisedPrItemsQuery>;
export type GetCentralisedPrItemsLazyQueryHookResult = ReturnType<typeof useGetCentralisedPrItemsLazyQuery>;
export type GetCentralisedPrItemsQueryResult = ApolloReactCommon.QueryResult<GetCentralisedPrItemsQuery, GetCentralisedPrItemsQueryVariables>;
export const GetPrHeaderDocument = gql`
    query getPRHeader($PRHeaderID: String, $CompanyID: String, $orderByDesc: String, $ApprovalStatus: ApprovalStatus) {
  getPRHeader(PRHeaderID: $PRHeaderID, CompanyID: $CompanyID, orderByDesc: $orderByDesc, ApprovalStatus: $ApprovalStatus) {
    modTs
    modBy
    createdTs
    createdBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    PRHeaderID
    AccountID
    DocDate
    ExpectedDate
    RequestedBy
    DeliveryLocationID
    DeliveryLocation {
      DeliveryLocationID
      Name
      Address
      ContactNo
    }
    ContactPerson
    Instruction
    ContactNo
    DocNo
    DocDate
    RecordStatus
    ApprovalStatus
    CancelCloseRemark
    CancelCloseRemark
    CompanyID
    Company {
      Name
      CompanyTax
      ContactNo
      CompanyRegNo
      Address
      GSTNo
      SSTNo
    }
    PRItem {
      PRItemID
      PRHeaderID
      ItemID
      Item {
        ItemID
        Name
        Description
        UOMID
      }
      UOMID
      UOM {
        UOMID
        Name
        Code
      }
      DeliveryLocationID
      DeliveryLocation {
        DeliveryLocationID
        Name
        Address
      }
      WarehouseID
      Warehouse {
        Name
        Code
        Address
      }
      CostCentreID
      CostCentre {
        CostCentreID
        Name
        Code
      }
      Quantity
      OutstandingQty
      Remark
    }
  }
}
    `;

/**
 * __useGetPrHeaderQuery__
 *
 * To run a query within a React component, call `useGetPrHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrHeaderQuery({
 *   variables: {
 *      PRHeaderID: // value for 'PRHeaderID'
 *      CompanyID: // value for 'CompanyID'
 *      orderByDesc: // value for 'orderByDesc'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *   },
 * });
 */
export function useGetPrHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrHeaderQuery, GetPrHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrHeaderQuery, GetPrHeaderQueryVariables>(GetPrHeaderDocument, baseOptions);
      }
export function useGetPrHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrHeaderQuery, GetPrHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrHeaderQuery, GetPrHeaderQueryVariables>(GetPrHeaderDocument, baseOptions);
        }
export type GetPrHeaderQueryHookResult = ReturnType<typeof useGetPrHeaderQuery>;
export type GetPrHeaderLazyQueryHookResult = ReturnType<typeof useGetPrHeaderLazyQuery>;
export type GetPrHeaderQueryResult = ApolloReactCommon.QueryResult<GetPrHeaderQuery, GetPrHeaderQueryVariables>;
export const GetArAgingDocument = gql`
    query getARAging($AccountType: String, $CompanyID: String, $take: Float, $orderByDesc: String, $AgingID: String) {
  getARAging(AccountType: $AccountType, CompanyID: $CompanyID, take: $take, orderByDesc: $orderByDesc, AgingID: $AgingID) {
    AccountID
    AgingID
    CompanyID
    Company {
      CompanyID
      AccountID
      Name
      Code
      Address
      ContactNo
    }
    Period1
    Period2
    Period3
    Period4
    AccountType
  }
}
    `;

/**
 * __useGetArAgingQuery__
 *
 * To run a query within a React component, call `useGetArAgingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArAgingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArAgingQuery({
 *   variables: {
 *      AccountType: // value for 'AccountType'
 *      CompanyID: // value for 'CompanyID'
 *      take: // value for 'take'
 *      orderByDesc: // value for 'orderByDesc'
 *      AgingID: // value for 'AgingID'
 *   },
 * });
 */
export function useGetArAgingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetArAgingQuery, GetArAgingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetArAgingQuery, GetArAgingQueryVariables>(GetArAgingDocument, baseOptions);
      }
export function useGetArAgingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetArAgingQuery, GetArAgingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetArAgingQuery, GetArAgingQueryVariables>(GetArAgingDocument, baseOptions);
        }
export type GetArAgingQueryHookResult = ReturnType<typeof useGetArAgingQuery>;
export type GetArAgingLazyQueryHookResult = ReturnType<typeof useGetArAgingLazyQuery>;
export type GetArAgingQueryResult = ApolloReactCommon.QueryResult<GetArAgingQuery, GetArAgingQueryVariables>;
export const GetUserDocument = gql`
    query getUser($ID: String!) {
  getUser(ID: $ID) {
    ID
    name
    department
    email
    jobTitle
    contactNo
    superUser
    commonStatus
    userName
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const CreateGeneralPrDocument = gql`
    mutation createGeneralPR($generalPRInput: PRInput!, $generalPRItemInput: [PRItemInput!]!) {
  createGeneralPR(generalPRInput: $generalPRInput, generalPRItemInput: $generalPRItemInput) {
    PRHeaderID
    DocNo
  }
}
    `;
export type CreateGeneralPrMutationFn = ApolloReactCommon.MutationFunction<CreateGeneralPrMutation, CreateGeneralPrMutationVariables>;

/**
 * __useCreateGeneralPrMutation__
 *
 * To run a mutation, you first call `useCreateGeneralPrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGeneralPrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGeneralPrMutation, { data, loading, error }] = useCreateGeneralPrMutation({
 *   variables: {
 *      generalPRInput: // value for 'generalPRInput'
 *      generalPRItemInput: // value for 'generalPRItemInput'
 *   },
 * });
 */
export function useCreateGeneralPrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGeneralPrMutation, CreateGeneralPrMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGeneralPrMutation, CreateGeneralPrMutationVariables>(CreateGeneralPrDocument, baseOptions);
      }
export type CreateGeneralPrMutationHookResult = ReturnType<typeof useCreateGeneralPrMutation>;
export type CreateGeneralPrMutationResult = ApolloReactCommon.MutationResult<CreateGeneralPrMutation>;
export type CreateGeneralPrMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGeneralPrMutation, CreateGeneralPrMutationVariables>;
export const UpdateGeneralPrDocument = gql`
    mutation updateGeneralPR($generalPRInput: PRInput!, $generalPRItemInput: [PRItemInput!]!, $PRHeaderID: String!) {
  updateGeneralPR(generalPRInput: $generalPRInput, generalPRItemInput: $generalPRItemInput, PRHeaderID: $PRHeaderID)
}
    `;
export type UpdateGeneralPrMutationFn = ApolloReactCommon.MutationFunction<UpdateGeneralPrMutation, UpdateGeneralPrMutationVariables>;

/**
 * __useUpdateGeneralPrMutation__
 *
 * To run a mutation, you first call `useUpdateGeneralPrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGeneralPrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGeneralPrMutation, { data, loading, error }] = useUpdateGeneralPrMutation({
 *   variables: {
 *      generalPRInput: // value for 'generalPRInput'
 *      generalPRItemInput: // value for 'generalPRItemInput'
 *      PRHeaderID: // value for 'PRHeaderID'
 *   },
 * });
 */
export function useUpdateGeneralPrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGeneralPrMutation, UpdateGeneralPrMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGeneralPrMutation, UpdateGeneralPrMutationVariables>(UpdateGeneralPrDocument, baseOptions);
      }
export type UpdateGeneralPrMutationHookResult = ReturnType<typeof useUpdateGeneralPrMutation>;
export type UpdateGeneralPrMutationResult = ApolloReactCommon.MutationResult<UpdateGeneralPrMutation>;
export type UpdateGeneralPrMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGeneralPrMutation, UpdateGeneralPrMutationVariables>;
export const GetGeneralPItemDocument = gql`
    query getGeneralPItem {
  getItem {
    ItemID
    Name
    UOMID
  }
}
    `;

/**
 * __useGetGeneralPItemQuery__
 *
 * To run a query within a React component, call `useGetGeneralPItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralPItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralPItemQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralPItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralPItemQuery, GetGeneralPItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralPItemQuery, GetGeneralPItemQueryVariables>(GetGeneralPItemDocument, baseOptions);
      }
export function useGetGeneralPItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralPItemQuery, GetGeneralPItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralPItemQuery, GetGeneralPItemQueryVariables>(GetGeneralPItemDocument, baseOptions);
        }
export type GetGeneralPItemQueryHookResult = ReturnType<typeof useGetGeneralPItemQuery>;
export type GetGeneralPItemLazyQueryHookResult = ReturnType<typeof useGetGeneralPItemLazyQuery>;
export type GetGeneralPItemQueryResult = ApolloReactCommon.QueryResult<GetGeneralPItemQuery, GetGeneralPItemQueryVariables>;
export const GetOutstandingGeneralPrItemsDocument = gql`
    query getOutstandingGeneralPRItems($CompanyID: String!) {
  getOutstandingGeneralPRItems(CompanyID: $CompanyID) {
    PRItemID
    WarehouseID
    Warehouse {
      CompanyID
      Name
      Address
      ContactNo
      RecordStatus
    }
    PRHeaderID
    PRHeader {
      CompanyID
      ExpectedDate
      DocNo
      DocDate
      RequestedBy
      ContactPerson
      ContactNo
      Instruction
      DeliveryLocationID
      DeliveryLocation {
        CompanyID
        Name
        Address
        ContactNo
        RecordStatus
      }
    }
    Quantity
    ItemID
    Item {
      RecordStatus
      Name
      Description
      UOMID
      UOM {
        Name
        Code
        RecordStatus
      }
      TaxSchemeID
      TaxScheme {
        Code
        Description
      }
    }
    UOMID
    UOM {
      Name
      Code
      RecordStatus
    }
    CostCentreID
    Remark
    IsPOGenerated
    rfqSubmissionSelected
    bulkPurchaseSelected
    masterFileSelected
  }
}
    `;

/**
 * __useGetOutstandingGeneralPrItemsQuery__
 *
 * To run a query within a React component, call `useGetOutstandingGeneralPrItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutstandingGeneralPrItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutstandingGeneralPrItemsQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetOutstandingGeneralPrItemsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutstandingGeneralPrItemsQuery, GetOutstandingGeneralPrItemsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutstandingGeneralPrItemsQuery, GetOutstandingGeneralPrItemsQueryVariables>(GetOutstandingGeneralPrItemsDocument, baseOptions);
      }
export function useGetOutstandingGeneralPrItemsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutstandingGeneralPrItemsQuery, GetOutstandingGeneralPrItemsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutstandingGeneralPrItemsQuery, GetOutstandingGeneralPrItemsQueryVariables>(GetOutstandingGeneralPrItemsDocument, baseOptions);
        }
export type GetOutstandingGeneralPrItemsQueryHookResult = ReturnType<typeof useGetOutstandingGeneralPrItemsQuery>;
export type GetOutstandingGeneralPrItemsLazyQueryHookResult = ReturnType<typeof useGetOutstandingGeneralPrItemsLazyQuery>;
export type GetOutstandingGeneralPrItemsQueryResult = ApolloReactCommon.QueryResult<GetOutstandingGeneralPrItemsQuery, GetOutstandingGeneralPrItemsQueryVariables>;
export const ActionOnGprStatusDocument = gql`
    mutation actionOnGPRStatus($input: GPRActionInput!, $GeneralPRIDs: [String!]!) {
  actionOnGPRStatus(input: $input, GeneralPRIDs: $GeneralPRIDs)
}
    `;
export type ActionOnGprStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnGprStatusMutation, ActionOnGprStatusMutationVariables>;

/**
 * __useActionOnGprStatusMutation__
 *
 * To run a mutation, you first call `useActionOnGprStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnGprStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnGprStatusMutation, { data, loading, error }] = useActionOnGprStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      GeneralPRIDs: // value for 'GeneralPRIDs'
 *   },
 * });
 */
export function useActionOnGprStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnGprStatusMutation, ActionOnGprStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnGprStatusMutation, ActionOnGprStatusMutationVariables>(ActionOnGprStatusDocument, baseOptions);
      }
export type ActionOnGprStatusMutationHookResult = ReturnType<typeof useActionOnGprStatusMutation>;
export type ActionOnGprStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnGprStatusMutation>;
export type ActionOnGprStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnGprStatusMutation, ActionOnGprStatusMutationVariables>;
export const DeleteGeneralPrDocument = gql`
    mutation deleteGeneralPR($PRHeaderID: String!) {
  deleteGeneralPR(PRHeaderID: $PRHeaderID)
}
    `;
export type DeleteGeneralPrMutationFn = ApolloReactCommon.MutationFunction<DeleteGeneralPrMutation, DeleteGeneralPrMutationVariables>;

/**
 * __useDeleteGeneralPrMutation__
 *
 * To run a mutation, you first call `useDeleteGeneralPrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGeneralPrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGeneralPrMutation, { data, loading, error }] = useDeleteGeneralPrMutation({
 *   variables: {
 *      PRHeaderID: // value for 'PRHeaderID'
 *   },
 * });
 */
export function useDeleteGeneralPrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGeneralPrMutation, DeleteGeneralPrMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGeneralPrMutation, DeleteGeneralPrMutationVariables>(DeleteGeneralPrDocument, baseOptions);
      }
export type DeleteGeneralPrMutationHookResult = ReturnType<typeof useDeleteGeneralPrMutation>;
export type DeleteGeneralPrMutationResult = ApolloReactCommon.MutationResult<DeleteGeneralPrMutation>;
export type DeleteGeneralPrMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGeneralPrMutation, DeleteGeneralPrMutationVariables>;
export const CancelClosePrDocument = gql`
    mutation cancelClosePR($PRHeaderID: String!, $input: GPRActionInput!) {
  cancelClosePR(PRHeaderID: $PRHeaderID, input: $input)
}
    `;
export type CancelClosePrMutationFn = ApolloReactCommon.MutationFunction<CancelClosePrMutation, CancelClosePrMutationVariables>;

/**
 * __useCancelClosePrMutation__
 *
 * To run a mutation, you first call `useCancelClosePrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelClosePrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelClosePrMutation, { data, loading, error }] = useCancelClosePrMutation({
 *   variables: {
 *      PRHeaderID: // value for 'PRHeaderID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelClosePrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelClosePrMutation, CancelClosePrMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelClosePrMutation, CancelClosePrMutationVariables>(CancelClosePrDocument, baseOptions);
      }
export type CancelClosePrMutationHookResult = ReturnType<typeof useCancelClosePrMutation>;
export type CancelClosePrMutationResult = ApolloReactCommon.MutationResult<CancelClosePrMutation>;
export type CancelClosePrMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelClosePrMutation, CancelClosePrMutationVariables>;
export const CheckingCancelClosePrDocument = gql`
    query checkingCancelClosePR($PRHeaderID: String!) {
  checkingCancelClosePR(PRHeaderID: $PRHeaderID)
}
    `;

/**
 * __useCheckingCancelClosePrQuery__
 *
 * To run a query within a React component, call `useCheckingCancelClosePrQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckingCancelClosePrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckingCancelClosePrQuery({
 *   variables: {
 *      PRHeaderID: // value for 'PRHeaderID'
 *   },
 * });
 */
export function useCheckingCancelClosePrQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>(CheckingCancelClosePrDocument, baseOptions);
      }
export function useCheckingCancelClosePrLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>(CheckingCancelClosePrDocument, baseOptions);
        }
export type CheckingCancelClosePrQueryHookResult = ReturnType<typeof useCheckingCancelClosePrQuery>;
export type CheckingCancelClosePrLazyQueryHookResult = ReturnType<typeof useCheckingCancelClosePrLazyQuery>;
export type CheckingCancelClosePrQueryResult = ApolloReactCommon.QueryResult<CheckingCancelClosePrQuery, CheckingCancelClosePrQueryVariables>;
export const GetRfqHeaderDocument = gql`
    query getRFQHeader($RFQHeaderID: String, $CompanyID: String, $ApprovalStatus: ApprovalStatus, $orderByDesc: String) {
  getRFQHeader(RFQHeaderID: $RFQHeaderID, CompanyID: $CompanyID, ApprovalStatus: $ApprovalStatus, orderByDesc: $orderByDesc) {
    modTs
    modBy
    createdTs
    createdBy
    submittedTs
    submittedBy
    approvedTs
    approvedBy
    RFQHeaderID
    AccountID
    DocNo
    DocDate
    Description
    ExpectedDate
    RejectionDate
    CancelCloseDate
    CancelCloseRemark
    RecordStatus
    ApprovalStatus
    ProfileInfo
    PRHeaderInfo
    RFQSupplierInvitation {
      RFQSupplierInvitationID
      SupplierID
      Supplier {
        CreditorAccountID
        CompanyName
        CreditTerm
        ContactNo
        Email
      }
      RecordStatus
      RFQSupplierSubmission {
        RFQSupplierSubmissionID
        RefNo
        CreditTerm
        SubmittedDate
        EffectiveDate
        ExpiryDate
        Remarks
      }
      LatestSubmittedQuotation {
        RefNo
        CreditTerm
        SubmittedDate
        EffectiveDate
        ExpiryDate
        Remarks
        RFQSubmissionItem {
          RFQSubmissionItemID
          RFQSupplierSubmissionID
          RFQItemID
          UnitPrice
          Remarks
          IsSelected
          Cheapest(RFQHeaderID: $RFQHeaderID)
          TotalPrice(RFQHeaderID: $RFQHeaderID)
        }
      }
    }
    RFQItem {
      RFQHeaderID
      RFQItemID
      UOM {
        UOMID
        Name
        Code
      }
      PRItem {
        ItemID
        PRItemID
        PRHeaderID
        Remark
        Quantity
        UOMID
        UOM {
          UOMID
          Code
          Name
        }
        Item {
          ItemID
          Name
          LatestPurchase
        }
      }
      RFQSubmissionItem {
        RFQSubmissionItemID
        RFQSupplierSubmissionID
        RFQItemID
        UnitPrice
        Remarks
        IsSelected
        Cheapest
      }
    }
  }
}
    `;

/**
 * __useGetRfqHeaderQuery__
 *
 * To run a query within a React component, call `useGetRfqHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqHeaderQuery({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      CompanyID: // value for 'CompanyID'
 *      ApprovalStatus: // value for 'ApprovalStatus'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetRfqHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqHeaderQuery, GetRfqHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqHeaderQuery, GetRfqHeaderQueryVariables>(GetRfqHeaderDocument, baseOptions);
      }
export function useGetRfqHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqHeaderQuery, GetRfqHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqHeaderQuery, GetRfqHeaderQueryVariables>(GetRfqHeaderDocument, baseOptions);
        }
export type GetRfqHeaderQueryHookResult = ReturnType<typeof useGetRfqHeaderQuery>;
export type GetRfqHeaderLazyQueryHookResult = ReturnType<typeof useGetRfqHeaderLazyQuery>;
export type GetRfqHeaderQueryResult = ApolloReactCommon.QueryResult<GetRfqHeaderQuery, GetRfqHeaderQueryVariables>;
export const GetGeneralPrForRfqDocument = gql`
    query getGeneralPRForRFQ($PRHeaderID: String) {
  getPRHeader(PRHeaderID: $PRHeaderID) {
    PRHeaderID
    ExpectedDate
    DocNo
    RecordStatus
    ExpectedDate
  }
}
    `;

/**
 * __useGetGeneralPrForRfqQuery__
 *
 * To run a query within a React component, call `useGetGeneralPrForRfqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralPrForRfqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralPrForRfqQuery({
 *   variables: {
 *      PRHeaderID: // value for 'PRHeaderID'
 *   },
 * });
 */
export function useGetGeneralPrForRfqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralPrForRfqQuery, GetGeneralPrForRfqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralPrForRfqQuery, GetGeneralPrForRfqQueryVariables>(GetGeneralPrForRfqDocument, baseOptions);
      }
export function useGetGeneralPrForRfqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralPrForRfqQuery, GetGeneralPrForRfqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralPrForRfqQuery, GetGeneralPrForRfqQueryVariables>(GetGeneralPrForRfqDocument, baseOptions);
        }
export type GetGeneralPrForRfqQueryHookResult = ReturnType<typeof useGetGeneralPrForRfqQuery>;
export type GetGeneralPrForRfqLazyQueryHookResult = ReturnType<typeof useGetGeneralPrForRfqLazyQuery>;
export type GetGeneralPrForRfqQueryResult = ApolloReactCommon.QueryResult<GetGeneralPrForRfqQuery, GetGeneralPrForRfqQueryVariables>;
export const GetRfqSupplierInvitationDocument = gql`
    query getRFQSupplierInvitation($RFQHeaderID: String, $orderByDesc: String) {
  getRFQSupplierInvitation(RFQHeaderID: $RFQHeaderID, orderByDesc: $orderByDesc) {
    RFQSupplierInvitationID
    RFQHeaderID
    SupplierID
    RFQHeader {
      RFQHeaderID
      RFQSupplierInvitation {
        RFQSupplierInvitationID
        SupplierID
        Supplier {
          CompanyName
          CompanyRegNo
          ContactNo
          Address
          CreditTerm
          Remark
        }
      }
    }
    RFQSupplierSubmission {
      RFQSupplierSubmissionID
      RefNo
      CreditTerm
      SubmittedDate
      EffectiveDate
      ExpiryDate
      Remarks
    }
    LatestSubmittedQuotation {
      RFQSupplierSubmissionID
      RefNo
      CreditTerm
      SubmittedDate
    }
    Supplier {
      CreditorAccountID
      CompanyName
    }
  }
}
    `;

/**
 * __useGetRfqSupplierInvitationQuery__
 *
 * To run a query within a React component, call `useGetRfqSupplierInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqSupplierInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqSupplierInvitationQuery({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetRfqSupplierInvitationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqSupplierInvitationQuery, GetRfqSupplierInvitationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqSupplierInvitationQuery, GetRfqSupplierInvitationQueryVariables>(GetRfqSupplierInvitationDocument, baseOptions);
      }
export function useGetRfqSupplierInvitationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqSupplierInvitationQuery, GetRfqSupplierInvitationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqSupplierInvitationQuery, GetRfqSupplierInvitationQueryVariables>(GetRfqSupplierInvitationDocument, baseOptions);
        }
export type GetRfqSupplierInvitationQueryHookResult = ReturnType<typeof useGetRfqSupplierInvitationQuery>;
export type GetRfqSupplierInvitationLazyQueryHookResult = ReturnType<typeof useGetRfqSupplierInvitationLazyQuery>;
export type GetRfqSupplierInvitationQueryResult = ApolloReactCommon.QueryResult<GetRfqSupplierInvitationQuery, GetRfqSupplierInvitationQueryVariables>;
export const GetRfqSupplierSubmissionDocument = gql`
    query getRFQSupplierSubmission($RFQSupplierSubmissionID: String, $RFQHeaderID: String, $SupplierID: String, $orderByDesc: String, $RFQSupplierInvitationID: String) {
  getRFQSupplierSubmission(RFQSupplierSubmissionID: $RFQSupplierSubmissionID, RFQHeaderID: $RFQHeaderID, SupplierID: $SupplierID, orderByDesc: $orderByDesc, RFQSupplierInvitationID: $RFQSupplierInvitationID) {
    RFQSupplierSubmissionID
    RFQSupplierInvitationID
    RefNo
    CreditTerm
    SubmittedDate
    EffectiveDate
    ExpiryDate
    Remarks
    RecordStatus
    RFQSubmissionItem {
      RFQSubmissionItemID
      UnitPrice
      Remarks
      RFQItemID
      RFQItem {
        RFQItemID
        PRItemID
        PRItem {
          PRItemID
          Quantity
          ItemID
          Item {
            ItemID
            Name
            UOMID
            UOM {
              UOMID
              Name
              Code
            }
          }
        }
      }
    }
    Supplier {
      CreditorAccountID
      CompanyName
    }
  }
}
    `;

/**
 * __useGetRfqSupplierSubmissionQuery__
 *
 * To run a query within a React component, call `useGetRfqSupplierSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqSupplierSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqSupplierSubmissionQuery({
 *   variables: {
 *      RFQSupplierSubmissionID: // value for 'RFQSupplierSubmissionID'
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      SupplierID: // value for 'SupplierID'
 *      orderByDesc: // value for 'orderByDesc'
 *      RFQSupplierInvitationID: // value for 'RFQSupplierInvitationID'
 *   },
 * });
 */
export function useGetRfqSupplierSubmissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>(GetRfqSupplierSubmissionDocument, baseOptions);
      }
export function useGetRfqSupplierSubmissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>(GetRfqSupplierSubmissionDocument, baseOptions);
        }
export type GetRfqSupplierSubmissionQueryHookResult = ReturnType<typeof useGetRfqSupplierSubmissionQuery>;
export type GetRfqSupplierSubmissionLazyQueryHookResult = ReturnType<typeof useGetRfqSupplierSubmissionLazyQuery>;
export type GetRfqSupplierSubmissionQueryResult = ApolloReactCommon.QueryResult<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>;
export const GetInvitedSupplierDocument = gql`
    query getInvitedSupplier($RFQHeaderID: String!, $companyID: String!) {
  getInvitedSupplier(RFQHeaderID: $RFQHeaderID, companyID: $companyID)
}
    `;

/**
 * __useGetInvitedSupplierQuery__
 *
 * To run a query within a React component, call `useGetInvitedSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitedSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitedSupplierQuery({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      companyID: // value for 'companyID'
 *   },
 * });
 */
export function useGetInvitedSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>(GetInvitedSupplierDocument, baseOptions);
      }
export function useGetInvitedSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>(GetInvitedSupplierDocument, baseOptions);
        }
export type GetInvitedSupplierQueryHookResult = ReturnType<typeof useGetInvitedSupplierQuery>;
export type GetInvitedSupplierLazyQueryHookResult = ReturnType<typeof useGetInvitedSupplierLazyQuery>;
export type GetInvitedSupplierQueryResult = ApolloReactCommon.QueryResult<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>;
export const CreateGeneralRfqDocument = gql`
    mutation createGeneralRFQ($RFQInput: RFQInput!, $RFQItemInput: [RFQItemInput!]!) {
  createGeneralRFQ(RFQInput: $RFQInput, RFQItemInput: $RFQItemInput)
}
    `;
export type CreateGeneralRfqMutationFn = ApolloReactCommon.MutationFunction<CreateGeneralRfqMutation, CreateGeneralRfqMutationVariables>;

/**
 * __useCreateGeneralRfqMutation__
 *
 * To run a mutation, you first call `useCreateGeneralRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGeneralRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGeneralRfqMutation, { data, loading, error }] = useCreateGeneralRfqMutation({
 *   variables: {
 *      RFQInput: // value for 'RFQInput'
 *      RFQItemInput: // value for 'RFQItemInput'
 *   },
 * });
 */
export function useCreateGeneralRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGeneralRfqMutation, CreateGeneralRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGeneralRfqMutation, CreateGeneralRfqMutationVariables>(CreateGeneralRfqDocument, baseOptions);
      }
export type CreateGeneralRfqMutationHookResult = ReturnType<typeof useCreateGeneralRfqMutation>;
export type CreateGeneralRfqMutationResult = ApolloReactCommon.MutationResult<CreateGeneralRfqMutation>;
export type CreateGeneralRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGeneralRfqMutation, CreateGeneralRfqMutationVariables>;
export const UpdateGeneralRfqDocument = gql`
    mutation updateGeneralRFQ($generalRFQInput: RFQInput!, $generalRFQItemInput: [RFQItemInput!]!, $RFQHeaderID: String!) {
  updateGeneralRFQ(generalRFQInput: $generalRFQInput, generalRFQItemInput: $generalRFQItemInput, RFQHeaderID: $RFQHeaderID)
}
    `;
export type UpdateGeneralRfqMutationFn = ApolloReactCommon.MutationFunction<UpdateGeneralRfqMutation, UpdateGeneralRfqMutationVariables>;

/**
 * __useUpdateGeneralRfqMutation__
 *
 * To run a mutation, you first call `useUpdateGeneralRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGeneralRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGeneralRfqMutation, { data, loading, error }] = useUpdateGeneralRfqMutation({
 *   variables: {
 *      generalRFQInput: // value for 'generalRFQInput'
 *      generalRFQItemInput: // value for 'generalRFQItemInput'
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *   },
 * });
 */
export function useUpdateGeneralRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGeneralRfqMutation, UpdateGeneralRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGeneralRfqMutation, UpdateGeneralRfqMutationVariables>(UpdateGeneralRfqDocument, baseOptions);
      }
export type UpdateGeneralRfqMutationHookResult = ReturnType<typeof useUpdateGeneralRfqMutation>;
export type UpdateGeneralRfqMutationResult = ApolloReactCommon.MutationResult<UpdateGeneralRfqMutation>;
export type UpdateGeneralRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGeneralRfqMutation, UpdateGeneralRfqMutationVariables>;
export const DeleteGeneralRfqDocument = gql`
    mutation deleteGeneralRFQ($RFQHeaderID: String!) {
  deleteGeneralRFQ(RFQHeaderID: $RFQHeaderID)
}
    `;
export type DeleteGeneralRfqMutationFn = ApolloReactCommon.MutationFunction<DeleteGeneralRfqMutation, DeleteGeneralRfqMutationVariables>;

/**
 * __useDeleteGeneralRfqMutation__
 *
 * To run a mutation, you first call `useDeleteGeneralRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGeneralRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGeneralRfqMutation, { data, loading, error }] = useDeleteGeneralRfqMutation({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *   },
 * });
 */
export function useDeleteGeneralRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGeneralRfqMutation, DeleteGeneralRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGeneralRfqMutation, DeleteGeneralRfqMutationVariables>(DeleteGeneralRfqDocument, baseOptions);
      }
export type DeleteGeneralRfqMutationHookResult = ReturnType<typeof useDeleteGeneralRfqMutation>;
export type DeleteGeneralRfqMutationResult = ApolloReactCommon.MutationResult<DeleteGeneralRfqMutation>;
export type DeleteGeneralRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGeneralRfqMutation, DeleteGeneralRfqMutationVariables>;
export const GetPrItemByPridForRfqDocument = gql`
    query getPRItemByPRIDForRFQ($PRHeaderID: String!, $CompanyID: String!) {
  getPRItemByPRIDForRFQ(PRHeaderID: $PRHeaderID, CompanyID: $CompanyID)
}
    `;

/**
 * __useGetPrItemByPridForRfqQuery__
 *
 * To run a query within a React component, call `useGetPrItemByPridForRfqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrItemByPridForRfqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrItemByPridForRfqQuery({
 *   variables: {
 *      PRHeaderID: // value for 'PRHeaderID'
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetPrItemByPridForRfqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>(GetPrItemByPridForRfqDocument, baseOptions);
      }
export function useGetPrItemByPridForRfqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>(GetPrItemByPridForRfqDocument, baseOptions);
        }
export type GetPrItemByPridForRfqQueryHookResult = ReturnType<typeof useGetPrItemByPridForRfqQuery>;
export type GetPrItemByPridForRfqLazyQueryHookResult = ReturnType<typeof useGetPrItemByPridForRfqLazyQuery>;
export type GetPrItemByPridForRfqQueryResult = ApolloReactCommon.QueryResult<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>;
export const InviteSupplierDocument = gql`
    mutation inviteSupplier($RFQHeaderID: String!, $SupplierIDs: [String!]!) {
  inviteSupplier(RFQHeaderID: $RFQHeaderID, SupplierIDs: $SupplierIDs)
}
    `;
export type InviteSupplierMutationFn = ApolloReactCommon.MutationFunction<InviteSupplierMutation, InviteSupplierMutationVariables>;

/**
 * __useInviteSupplierMutation__
 *
 * To run a mutation, you first call `useInviteSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteSupplierMutation, { data, loading, error }] = useInviteSupplierMutation({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      SupplierIDs: // value for 'SupplierIDs'
 *   },
 * });
 */
export function useInviteSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteSupplierMutation, InviteSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteSupplierMutation, InviteSupplierMutationVariables>(InviteSupplierDocument, baseOptions);
      }
export type InviteSupplierMutationHookResult = ReturnType<typeof useInviteSupplierMutation>;
export type InviteSupplierMutationResult = ApolloReactCommon.MutationResult<InviteSupplierMutation>;
export type InviteSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteSupplierMutation, InviteSupplierMutationVariables>;
export const UninviteSupplierDocument = gql`
    mutation uninviteSupplier($RFQHeaderID: String!, $SupplierID: String!) {
  uninviteSupplier(RFQHeaderID: $RFQHeaderID, SupplierID: $SupplierID)
}
    `;
export type UninviteSupplierMutationFn = ApolloReactCommon.MutationFunction<UninviteSupplierMutation, UninviteSupplierMutationVariables>;

/**
 * __useUninviteSupplierMutation__
 *
 * To run a mutation, you first call `useUninviteSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninviteSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninviteSupplierMutation, { data, loading, error }] = useUninviteSupplierMutation({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      SupplierID: // value for 'SupplierID'
 *   },
 * });
 */
export function useUninviteSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UninviteSupplierMutation, UninviteSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<UninviteSupplierMutation, UninviteSupplierMutationVariables>(UninviteSupplierDocument, baseOptions);
      }
export type UninviteSupplierMutationHookResult = ReturnType<typeof useUninviteSupplierMutation>;
export type UninviteSupplierMutationResult = ApolloReactCommon.MutationResult<UninviteSupplierMutation>;
export type UninviteSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<UninviteSupplierMutation, UninviteSupplierMutationVariables>;
export const SubmitGeneralRfqQuoteDocument = gql`
    mutation submitGeneralRFQQuote($input: RFQSupplierSubmissionInput!) {
  submitGeneralRFQQuote(input: $input)
}
    `;
export type SubmitGeneralRfqQuoteMutationFn = ApolloReactCommon.MutationFunction<SubmitGeneralRfqQuoteMutation, SubmitGeneralRfqQuoteMutationVariables>;

/**
 * __useSubmitGeneralRfqQuoteMutation__
 *
 * To run a mutation, you first call `useSubmitGeneralRfqQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitGeneralRfqQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitGeneralRfqQuoteMutation, { data, loading, error }] = useSubmitGeneralRfqQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitGeneralRfqQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitGeneralRfqQuoteMutation, SubmitGeneralRfqQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitGeneralRfqQuoteMutation, SubmitGeneralRfqQuoteMutationVariables>(SubmitGeneralRfqQuoteDocument, baseOptions);
      }
export type SubmitGeneralRfqQuoteMutationHookResult = ReturnType<typeof useSubmitGeneralRfqQuoteMutation>;
export type SubmitGeneralRfqQuoteMutationResult = ApolloReactCommon.MutationResult<SubmitGeneralRfqQuoteMutation>;
export type SubmitGeneralRfqQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitGeneralRfqQuoteMutation, SubmitGeneralRfqQuoteMutationVariables>;
export const DeleteRfqSupplierSubmissionDocument = gql`
    mutation deleteRFQSupplierSubmission($RFQSupplierSubmissionID: String!) {
  deleteRFQSupplierSubmission(RFQSupplierSubmissionID: $RFQSupplierSubmissionID)
}
    `;
export type DeleteRfqSupplierSubmissionMutationFn = ApolloReactCommon.MutationFunction<DeleteRfqSupplierSubmissionMutation, DeleteRfqSupplierSubmissionMutationVariables>;

/**
 * __useDeleteRfqSupplierSubmissionMutation__
 *
 * To run a mutation, you first call `useDeleteRfqSupplierSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRfqSupplierSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRfqSupplierSubmissionMutation, { data, loading, error }] = useDeleteRfqSupplierSubmissionMutation({
 *   variables: {
 *      RFQSupplierSubmissionID: // value for 'RFQSupplierSubmissionID'
 *   },
 * });
 */
export function useDeleteRfqSupplierSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRfqSupplierSubmissionMutation, DeleteRfqSupplierSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRfqSupplierSubmissionMutation, DeleteRfqSupplierSubmissionMutationVariables>(DeleteRfqSupplierSubmissionDocument, baseOptions);
      }
export type DeleteRfqSupplierSubmissionMutationHookResult = ReturnType<typeof useDeleteRfqSupplierSubmissionMutation>;
export type DeleteRfqSupplierSubmissionMutationResult = ApolloReactCommon.MutationResult<DeleteRfqSupplierSubmissionMutation>;
export type DeleteRfqSupplierSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRfqSupplierSubmissionMutation, DeleteRfqSupplierSubmissionMutationVariables>;
export const SubmitEvaluationSelectionDocument = gql`
    mutation submitEvaluationSelection($RFQInput: RFQEvalInput!, $RFQEvalSelectInput: [RFQEvalSelectInput!]!) {
  submitEvaluationSelection(RFQInput: $RFQInput, RFQEvalSelectInput: $RFQEvalSelectInput)
}
    `;
export type SubmitEvaluationSelectionMutationFn = ApolloReactCommon.MutationFunction<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>;

/**
 * __useSubmitEvaluationSelectionMutation__
 *
 * To run a mutation, you first call `useSubmitEvaluationSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitEvaluationSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitEvaluationSelectionMutation, { data, loading, error }] = useSubmitEvaluationSelectionMutation({
 *   variables: {
 *      RFQInput: // value for 'RFQInput'
 *      RFQEvalSelectInput: // value for 'RFQEvalSelectInput'
 *   },
 * });
 */
export function useSubmitEvaluationSelectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>(SubmitEvaluationSelectionDocument, baseOptions);
      }
export type SubmitEvaluationSelectionMutationHookResult = ReturnType<typeof useSubmitEvaluationSelectionMutation>;
export type SubmitEvaluationSelectionMutationResult = ApolloReactCommon.MutationResult<SubmitEvaluationSelectionMutation>;
export type SubmitEvaluationSelectionMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>;
export const ActionOnRfqStatusDocument = gql`
    mutation actionOnRFQStatus($CompanyID: String!, $input: RFQRejectInput!, $RFQHeaderID: [String!]!) {
  actionOnRFQStatus(CompanyID: $CompanyID, input: $input, RFQHeaderID: $RFQHeaderID)
}
    `;
export type ActionOnRfqStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnRfqStatusMutation, ActionOnRfqStatusMutationVariables>;

/**
 * __useActionOnRfqStatusMutation__
 *
 * To run a mutation, you first call `useActionOnRfqStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnRfqStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnRfqStatusMutation, { data, loading, error }] = useActionOnRfqStatusMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      input: // value for 'input'
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *   },
 * });
 */
export function useActionOnRfqStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnRfqStatusMutation, ActionOnRfqStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnRfqStatusMutation, ActionOnRfqStatusMutationVariables>(ActionOnRfqStatusDocument, baseOptions);
      }
export type ActionOnRfqStatusMutationHookResult = ReturnType<typeof useActionOnRfqStatusMutation>;
export type ActionOnRfqStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnRfqStatusMutation>;
export type ActionOnRfqStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnRfqStatusMutation, ActionOnRfqStatusMutationVariables>;
export const GetCreditorContactDocument = gql`
    query getCreditorContact($orderByDesc: String, $AccountID: String, $CreditorContactID: String, $CreditorAccountID: String) {
  getCreditorContact(orderByDesc: $orderByDesc, AccountID: $AccountID, CreditorContactID: $CreditorContactID, CreditorAccountID: $CreditorAccountID) {
    CreditorContactID
    Name
    ContactNo
    Designation
    Email
  }
}
    `;

/**
 * __useGetCreditorContactQuery__
 *
 * To run a query within a React component, call `useGetCreditorContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCreditorContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCreditorContactQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      AccountID: // value for 'AccountID'
 *      CreditorContactID: // value for 'CreditorContactID'
 *      CreditorAccountID: // value for 'CreditorAccountID'
 *   },
 * });
 */
export function useGetCreditorContactQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCreditorContactQuery, GetCreditorContactQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCreditorContactQuery, GetCreditorContactQueryVariables>(GetCreditorContactDocument, baseOptions);
      }
export function useGetCreditorContactLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCreditorContactQuery, GetCreditorContactQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCreditorContactQuery, GetCreditorContactQueryVariables>(GetCreditorContactDocument, baseOptions);
        }
export type GetCreditorContactQueryHookResult = ReturnType<typeof useGetCreditorContactQuery>;
export type GetCreditorContactLazyQueryHookResult = ReturnType<typeof useGetCreditorContactLazyQuery>;
export type GetCreditorContactQueryResult = ApolloReactCommon.QueryResult<GetCreditorContactQuery, GetCreditorContactQueryVariables>;
export const GetRfQbyStatusDocument = gql`
    query getRFQbyStatus($RFQHeaderID: String, $CompanyID: String!, $StatusArr: [String!]!) {
  getRFQbyStatus(CompanyID: $CompanyID, StatusArr: $StatusArr) {
    modTs
    modBy
    createdTs
    createdBy
    RFQHeaderID
    AccountID
    DocNo
    DocDate
    Description
    ExpectedDate
    RejectionDate
    CancelCloseDate
    CancelCloseRemark
    RecordStatus
    ApprovalStatus
    ProfileInfo
    PRHeaderInfo
    RFQSupplierInvitation {
      RFQSupplierInvitationID
      SupplierID
      Supplier {
        CreditorAccountID
        CompanyName
        CreditTerm
        ContactNo
        Email
      }
      RecordStatus
      RFQSupplierSubmission {
        RFQSupplierSubmissionID
        RefNo
        CreditTerm
        SubmittedDate
        EffectiveDate
        ExpiryDate
        Remarks
      }
      LatestSubmittedQuotation {
        RefNo
        CreditTerm
        SubmittedDate
        EffectiveDate
        ExpiryDate
        RecordStatus
        Remarks
        RFQSubmissionItem {
          RFQSubmissionItemID
          RFQSupplierSubmissionID
          RFQItemID
          UnitPrice
          Remarks
          IsSelected
          Cheapest(RFQHeaderID: $RFQHeaderID)
          TotalPrice(RFQHeaderID: $RFQHeaderID)
        }
      }
    }
    RFQItem {
      RFQHeaderID
      RFQItemID
      UOM {
        UOMID
        Name
        Code
      }
      PRItem {
        ItemID
        PRItemID
        PRHeaderID
        Remark
        Quantity
        UOMID
        UOM {
          UOMID
          Code
          Name
        }
        Item {
          ItemID
          Name
          LatestPurchase
        }
      }
      RFQSubmissionItem {
        RFQSubmissionItemID
        RFQSupplierSubmissionID
        RFQItemID
        UnitPrice
        Remarks
        IsSelected
        Cheapest
      }
    }
  }
}
    `;

/**
 * __useGetRfQbyStatusQuery__
 *
 * To run a query within a React component, call `useGetRfQbyStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfQbyStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfQbyStatusQuery({
 *   variables: {
 *      RFQHeaderID: // value for 'RFQHeaderID'
 *      CompanyID: // value for 'CompanyID'
 *      StatusArr: // value for 'StatusArr'
 *   },
 * });
 */
export function useGetRfQbyStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfQbyStatusQuery, GetRfQbyStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfQbyStatusQuery, GetRfQbyStatusQueryVariables>(GetRfQbyStatusDocument, baseOptions);
      }
export function useGetRfQbyStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfQbyStatusQuery, GetRfQbyStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfQbyStatusQuery, GetRfQbyStatusQueryVariables>(GetRfQbyStatusDocument, baseOptions);
        }
export type GetRfQbyStatusQueryHookResult = ReturnType<typeof useGetRfQbyStatusQuery>;
export type GetRfQbyStatusLazyQueryHookResult = ReturnType<typeof useGetRfQbyStatusLazyQuery>;
export type GetRfQbyStatusQueryResult = ApolloReactCommon.QueryResult<GetRfQbyStatusQuery, GetRfQbyStatusQueryVariables>;
export const GetPrNoListDocument = gql`
    query getPRNoList($CompanyID: String!, $PRHeaderID: String) {
  getPRNoList(CompanyID: $CompanyID, PRHeaderID: $PRHeaderID)
}
    `;

/**
 * __useGetPrNoListQuery__
 *
 * To run a query within a React component, call `useGetPrNoListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrNoListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrNoListQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      PRHeaderID: // value for 'PRHeaderID'
 *   },
 * });
 */
export function useGetPrNoListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrNoListQuery, GetPrNoListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrNoListQuery, GetPrNoListQueryVariables>(GetPrNoListDocument, baseOptions);
      }
export function useGetPrNoListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrNoListQuery, GetPrNoListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrNoListQuery, GetPrNoListQueryVariables>(GetPrNoListDocument, baseOptions);
        }
export type GetPrNoListQueryHookResult = ReturnType<typeof useGetPrNoListQuery>;
export type GetPrNoListLazyQueryHookResult = ReturnType<typeof useGetPrNoListLazyQuery>;
export type GetPrNoListQueryResult = ApolloReactCommon.QueryResult<GetPrNoListQuery, GetPrNoListQueryVariables>;
export const LatestOpenPeriodCheckingDateDocument = gql`
    query latestOpenPeriodCheckingDate($CompanyID: String!) {
  latestOpenPeriodCheckingDate(CompanyID: $CompanyID)
}
    `;

/**
 * __useLatestOpenPeriodCheckingDateQuery__
 *
 * To run a query within a React component, call `useLatestOpenPeriodCheckingDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestOpenPeriodCheckingDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestOpenPeriodCheckingDateQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useLatestOpenPeriodCheckingDateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>) {
        return ApolloReactHooks.useQuery<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>(LatestOpenPeriodCheckingDateDocument, baseOptions);
      }
export function useLatestOpenPeriodCheckingDateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>(LatestOpenPeriodCheckingDateDocument, baseOptions);
        }
export type LatestOpenPeriodCheckingDateQueryHookResult = ReturnType<typeof useLatestOpenPeriodCheckingDateQuery>;
export type LatestOpenPeriodCheckingDateLazyQueryHookResult = ReturnType<typeof useLatestOpenPeriodCheckingDateLazyQuery>;
export type LatestOpenPeriodCheckingDateQueryResult = ApolloReactCommon.QueryResult<LatestOpenPeriodCheckingDateQuery, LatestOpenPeriodCheckingDateQueryVariables>;
export const GetScheduledPurchasesDocument = gql`
    query getScheduledPurchases($ScheduledPurchasesID: String, $CompanyID: String, $DeliveryLocationID: String) {
  getScheduledPurchases(ScheduledPurchasesID: $ScheduledPurchasesID, CompanyID: $CompanyID, DeliveryLocationID: $DeliveryLocationID) {
    createdTs
    createdBy
    modTs
    modBy
    RecordStatus
    ScheduledPurchasesID
    CompanyID
    DeliveryLocationID
    DeliveryLocation {
      Name
      Address
    }
    Title
    ScheduledDate
    ScheduledFrequency
    Description
    DeliveryLocationID
    ScheduledPurchasesItem {
      ScheduledPurchasesItemID
      ItemID
      Item {
        Name
        IsCentralised
        IsInventory
      }
      SupplierID
      CostCentreID
      WarehouseID
      Quantity
      UOMID
      UnitPrice
      TotalAmt
      TaxAmt
      TaxSchemeID
      TaxScheme {
        AccountID
        TaxSchemeID
        Code
        Description
      }
      Remarks
      Supplier {
        CompanyName
      }
      UOM {
        UOMID
        Name
        Code
      }
      UOMExchangeList
    }
  }
}
    `;

/**
 * __useGetScheduledPurchasesQuery__
 *
 * To run a query within a React component, call `useGetScheduledPurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetScheduledPurchasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetScheduledPurchasesQuery({
 *   variables: {
 *      ScheduledPurchasesID: // value for 'ScheduledPurchasesID'
 *      CompanyID: // value for 'CompanyID'
 *      DeliveryLocationID: // value for 'DeliveryLocationID'
 *   },
 * });
 */
export function useGetScheduledPurchasesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetScheduledPurchasesQuery, GetScheduledPurchasesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetScheduledPurchasesQuery, GetScheduledPurchasesQueryVariables>(GetScheduledPurchasesDocument, baseOptions);
      }
export function useGetScheduledPurchasesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetScheduledPurchasesQuery, GetScheduledPurchasesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetScheduledPurchasesQuery, GetScheduledPurchasesQueryVariables>(GetScheduledPurchasesDocument, baseOptions);
        }
export type GetScheduledPurchasesQueryHookResult = ReturnType<typeof useGetScheduledPurchasesQuery>;
export type GetScheduledPurchasesLazyQueryHookResult = ReturnType<typeof useGetScheduledPurchasesLazyQuery>;
export type GetScheduledPurchasesQueryResult = ApolloReactCommon.QueryResult<GetScheduledPurchasesQuery, GetScheduledPurchasesQueryVariables>;
export const CreateScheduledPurchasesDocument = gql`
    mutation createScheduledPurchases($input: ScheduledPurchasesInput!) {
  createScheduledPurchases(input: $input)
}
    `;
export type CreateScheduledPurchasesMutationFn = ApolloReactCommon.MutationFunction<CreateScheduledPurchasesMutation, CreateScheduledPurchasesMutationVariables>;

/**
 * __useCreateScheduledPurchasesMutation__
 *
 * To run a mutation, you first call `useCreateScheduledPurchasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScheduledPurchasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScheduledPurchasesMutation, { data, loading, error }] = useCreateScheduledPurchasesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateScheduledPurchasesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateScheduledPurchasesMutation, CreateScheduledPurchasesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateScheduledPurchasesMutation, CreateScheduledPurchasesMutationVariables>(CreateScheduledPurchasesDocument, baseOptions);
      }
export type CreateScheduledPurchasesMutationHookResult = ReturnType<typeof useCreateScheduledPurchasesMutation>;
export type CreateScheduledPurchasesMutationResult = ApolloReactCommon.MutationResult<CreateScheduledPurchasesMutation>;
export type CreateScheduledPurchasesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateScheduledPurchasesMutation, CreateScheduledPurchasesMutationVariables>;
export const UpdateScheduledPurchasesDocument = gql`
    mutation updateScheduledPurchases($input: ScheduledPurchasesInput!, $ScheduledPurchasesID: String!) {
  updateScheduledPurchases(input: $input, ScheduledPurchasesID: $ScheduledPurchasesID)
}
    `;
export type UpdateScheduledPurchasesMutationFn = ApolloReactCommon.MutationFunction<UpdateScheduledPurchasesMutation, UpdateScheduledPurchasesMutationVariables>;

/**
 * __useUpdateScheduledPurchasesMutation__
 *
 * To run a mutation, you first call `useUpdateScheduledPurchasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScheduledPurchasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScheduledPurchasesMutation, { data, loading, error }] = useUpdateScheduledPurchasesMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ScheduledPurchasesID: // value for 'ScheduledPurchasesID'
 *   },
 * });
 */
export function useUpdateScheduledPurchasesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateScheduledPurchasesMutation, UpdateScheduledPurchasesMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateScheduledPurchasesMutation, UpdateScheduledPurchasesMutationVariables>(UpdateScheduledPurchasesDocument, baseOptions);
      }
export type UpdateScheduledPurchasesMutationHookResult = ReturnType<typeof useUpdateScheduledPurchasesMutation>;
export type UpdateScheduledPurchasesMutationResult = ApolloReactCommon.MutationResult<UpdateScheduledPurchasesMutation>;
export type UpdateScheduledPurchasesMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateScheduledPurchasesMutation, UpdateScheduledPurchasesMutationVariables>;
export const CreatePreparePoDocument = gql`
    mutation createPreparePO($input: [PreparePOInput!]!) {
  createPreparePO(input: $input)
}
    `;
export type CreatePreparePoMutationFn = ApolloReactCommon.MutationFunction<CreatePreparePoMutation, CreatePreparePoMutationVariables>;

/**
 * __useCreatePreparePoMutation__
 *
 * To run a mutation, you first call `useCreatePreparePoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePreparePoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPreparePoMutation, { data, loading, error }] = useCreatePreparePoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePreparePoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePreparePoMutation, CreatePreparePoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePreparePoMutation, CreatePreparePoMutationVariables>(CreatePreparePoDocument, baseOptions);
      }
export type CreatePreparePoMutationHookResult = ReturnType<typeof useCreatePreparePoMutation>;
export type CreatePreparePoMutationResult = ApolloReactCommon.MutationResult<CreatePreparePoMutation>;
export type CreatePreparePoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePreparePoMutation, CreatePreparePoMutationVariables>;
export const DeleteScheduledPurchasesDocument = gql`
    mutation deleteScheduledPurchases($ScheduledPurchasesID: String!) {
  deleteScheduledPurchases(ScheduledPurchasesID: $ScheduledPurchasesID)
}
    `;
export type DeleteScheduledPurchasesMutationFn = ApolloReactCommon.MutationFunction<DeleteScheduledPurchasesMutation, DeleteScheduledPurchasesMutationVariables>;

/**
 * __useDeleteScheduledPurchasesMutation__
 *
 * To run a mutation, you first call `useDeleteScheduledPurchasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteScheduledPurchasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteScheduledPurchasesMutation, { data, loading, error }] = useDeleteScheduledPurchasesMutation({
 *   variables: {
 *      ScheduledPurchasesID: // value for 'ScheduledPurchasesID'
 *   },
 * });
 */
export function useDeleteScheduledPurchasesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteScheduledPurchasesMutation, DeleteScheduledPurchasesMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteScheduledPurchasesMutation, DeleteScheduledPurchasesMutationVariables>(DeleteScheduledPurchasesDocument, baseOptions);
      }
export type DeleteScheduledPurchasesMutationHookResult = ReturnType<typeof useDeleteScheduledPurchasesMutation>;
export type DeleteScheduledPurchasesMutationResult = ApolloReactCommon.MutationResult<DeleteScheduledPurchasesMutation>;
export type DeleteScheduledPurchasesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteScheduledPurchasesMutation, DeleteScheduledPurchasesMutationVariables>;
export const GetLatestTaxByTaxSchemeIDsDocument = gql`
    query getLatestTaxByTaxSchemeIDs($taxSchemeIDs: [String!]!) {
  getLatestTaxByTaxSchemeIDs(taxSchemeIDs: $taxSchemeIDs)
}
    `;

/**
 * __useGetLatestTaxByTaxSchemeIDsQuery__
 *
 * To run a query within a React component, call `useGetLatestTaxByTaxSchemeIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestTaxByTaxSchemeIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestTaxByTaxSchemeIDsQuery({
 *   variables: {
 *      taxSchemeIDs: // value for 'taxSchemeIDs'
 *   },
 * });
 */
export function useGetLatestTaxByTaxSchemeIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestTaxByTaxSchemeIDsQuery, GetLatestTaxByTaxSchemeIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLatestTaxByTaxSchemeIDsQuery, GetLatestTaxByTaxSchemeIDsQueryVariables>(GetLatestTaxByTaxSchemeIDsDocument, baseOptions);
      }
export function useGetLatestTaxByTaxSchemeIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestTaxByTaxSchemeIDsQuery, GetLatestTaxByTaxSchemeIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLatestTaxByTaxSchemeIDsQuery, GetLatestTaxByTaxSchemeIDsQueryVariables>(GetLatestTaxByTaxSchemeIDsDocument, baseOptions);
        }
export type GetLatestTaxByTaxSchemeIDsQueryHookResult = ReturnType<typeof useGetLatestTaxByTaxSchemeIDsQuery>;
export type GetLatestTaxByTaxSchemeIDsLazyQueryHookResult = ReturnType<typeof useGetLatestTaxByTaxSchemeIDsLazyQuery>;
export type GetLatestTaxByTaxSchemeIDsQueryResult = ApolloReactCommon.QueryResult<GetLatestTaxByTaxSchemeIDsQuery, GetLatestTaxByTaxSchemeIDsQueryVariables>;
export const GetUsersByAccountAndSoftwareDocument = gql`
    query getUsersByAccountAndSoftware($superUserBool: UserSelection, $status: CommonStatus) {
  getUsersByAccountAndSoftware(superUserBool: $superUserBool, status: $status) {
    ID
    name
    department
    email
    contactNo
    confirmed
    superUser
    commonStatus
    jobTitle
    userName
    blockCount
  }
}
    `;

/**
 * __useGetUsersByAccountAndSoftwareQuery__
 *
 * To run a query within a React component, call `useGetUsersByAccountAndSoftwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByAccountAndSoftwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByAccountAndSoftwareQuery({
 *   variables: {
 *      superUserBool: // value for 'superUserBool'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetUsersByAccountAndSoftwareQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>(GetUsersByAccountAndSoftwareDocument, baseOptions);
      }
export function useGetUsersByAccountAndSoftwareLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>(GetUsersByAccountAndSoftwareDocument, baseOptions);
        }
export type GetUsersByAccountAndSoftwareQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareQuery>;
export type GetUsersByAccountAndSoftwareLazyQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareLazyQuery>;
export type GetUsersByAccountAndSoftwareQueryResult = ApolloReactCommon.QueryResult<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>;
export const GetApprovalPolicyDocument = gql`
    query getApprovalPolicy($Category: String!, $ApprovalPolicyID: String) {
  getApprovalPolicy(Category: $Category, ApprovalPolicyID: $ApprovalPolicyID) {
    ApprovalPolicyID
    AccountID
    createdTs
    modTs
    lowerLimit
    upperLimit
    Category
    ApprovalList
  }
}
    `;

/**
 * __useGetApprovalPolicyQuery__
 *
 * To run a query within a React component, call `useGetApprovalPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPolicyQuery({
 *   variables: {
 *      Category: // value for 'Category'
 *      ApprovalPolicyID: // value for 'ApprovalPolicyID'
 *   },
 * });
 */
export function useGetApprovalPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>(GetApprovalPolicyDocument, baseOptions);
      }
export function useGetApprovalPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>(GetApprovalPolicyDocument, baseOptions);
        }
export type GetApprovalPolicyQueryHookResult = ReturnType<typeof useGetApprovalPolicyQuery>;
export type GetApprovalPolicyLazyQueryHookResult = ReturnType<typeof useGetApprovalPolicyLazyQuery>;
export type GetApprovalPolicyQueryResult = ApolloReactCommon.QueryResult<GetApprovalPolicyQuery, GetApprovalPolicyQueryVariables>;
export const GetAllApprovalPolicyDocument = gql`
    query getAllApprovalPolicy($DifferentConnection: String!, $Category: String!) {
  getAllApprovalPolicy(DifferentConnection: $DifferentConnection, Category: $Category) {
    ApprovalPolicyID
    createdTs
    modTs
    Category
    ApprovalList
  }
}
    `;

/**
 * __useGetAllApprovalPolicyQuery__
 *
 * To run a query within a React component, call `useGetAllApprovalPolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllApprovalPolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllApprovalPolicyQuery({
 *   variables: {
 *      DifferentConnection: // value for 'DifferentConnection'
 *      Category: // value for 'Category'
 *   },
 * });
 */
export function useGetAllApprovalPolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>(GetAllApprovalPolicyDocument, baseOptions);
      }
export function useGetAllApprovalPolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>(GetAllApprovalPolicyDocument, baseOptions);
        }
export type GetAllApprovalPolicyQueryHookResult = ReturnType<typeof useGetAllApprovalPolicyQuery>;
export type GetAllApprovalPolicyLazyQueryHookResult = ReturnType<typeof useGetAllApprovalPolicyLazyQuery>;
export type GetAllApprovalPolicyQueryResult = ApolloReactCommon.QueryResult<GetAllApprovalPolicyQuery, GetAllApprovalPolicyQueryVariables>;
export const GetApprovalPolicyAssignmentDocument = gql`
    query getApprovalPolicyAssignment($ApprovalPolicyAssignmentID: String, $AccountID: String, $CompanyID: String, $Category: String, $orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float) {
  getApprovalPolicyAssignment(ApprovalPolicyAssignmentID: $ApprovalPolicyAssignmentID, AccountID: $AccountID, CompanyID: $CompanyID, Category: $Category, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, skip: $skip, take: $take) {
    ApprovalPolicyAssignmentID
    ApprovalPolicyID
    AccountID
    CompanyID
    Category
    ApprovalList
    createdTs
    modTs
    modBy
    lowerLimit
    upperLimit
  }
}
    `;

/**
 * __useGetApprovalPolicyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetApprovalPolicyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalPolicyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalPolicyAssignmentQuery({
 *   variables: {
 *      ApprovalPolicyAssignmentID: // value for 'ApprovalPolicyAssignmentID'
 *      AccountID: // value for 'AccountID'
 *      CompanyID: // value for 'CompanyID'
 *      Category: // value for 'Category'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetApprovalPolicyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>(GetApprovalPolicyAssignmentDocument, baseOptions);
      }
export function useGetApprovalPolicyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>(GetApprovalPolicyAssignmentDocument, baseOptions);
        }
export type GetApprovalPolicyAssignmentQueryHookResult = ReturnType<typeof useGetApprovalPolicyAssignmentQuery>;
export type GetApprovalPolicyAssignmentLazyQueryHookResult = ReturnType<typeof useGetApprovalPolicyAssignmentLazyQuery>;
export type GetApprovalPolicyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetApprovalPolicyAssignmentQuery, GetApprovalPolicyAssignmentQueryVariables>;
export const CreateApprovalPolicyDocument = gql`
    mutation createApprovalPolicy($input: ApprovalPolicyInput!) {
  createApprovalPolicy(input: $input) {
    ApprovalPolicyID
  }
}
    `;
export type CreateApprovalPolicyMutationFn = ApolloReactCommon.MutationFunction<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>;

/**
 * __useCreateApprovalPolicyMutation__
 *
 * To run a mutation, you first call `useCreateApprovalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApprovalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApprovalPolicyMutation, { data, loading, error }] = useCreateApprovalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApprovalPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>(CreateApprovalPolicyDocument, baseOptions);
      }
export type CreateApprovalPolicyMutationHookResult = ReturnType<typeof useCreateApprovalPolicyMutation>;
export type CreateApprovalPolicyMutationResult = ApolloReactCommon.MutationResult<CreateApprovalPolicyMutation>;
export type CreateApprovalPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApprovalPolicyMutation, CreateApprovalPolicyMutationVariables>;
export const CreateApprovalPolicyAssignmentDocument = gql`
    mutation createApprovalPolicyAssignment($input: ApprovalPolicyAssignmentInput!) {
  createApprovalPolicyAssignment(input: $input) {
    ApprovalPolicyAssignmentID
  }
}
    `;
export type CreateApprovalPolicyAssignmentMutationFn = ApolloReactCommon.MutationFunction<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>;

/**
 * __useCreateApprovalPolicyAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateApprovalPolicyAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApprovalPolicyAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApprovalPolicyAssignmentMutation, { data, loading, error }] = useCreateApprovalPolicyAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApprovalPolicyAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>(CreateApprovalPolicyAssignmentDocument, baseOptions);
      }
export type CreateApprovalPolicyAssignmentMutationHookResult = ReturnType<typeof useCreateApprovalPolicyAssignmentMutation>;
export type CreateApprovalPolicyAssignmentMutationResult = ApolloReactCommon.MutationResult<CreateApprovalPolicyAssignmentMutation>;
export type CreateApprovalPolicyAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApprovalPolicyAssignmentMutation, CreateApprovalPolicyAssignmentMutationVariables>;
export const UpdateApprovalPolicyDocument = gql`
    mutation updateApprovalPolicy($input: ApprovalPolicyInput!) {
  updateApprovalPolicy(input: $input) {
    ApprovalPolicyID
  }
}
    `;
export type UpdateApprovalPolicyMutationFn = ApolloReactCommon.MutationFunction<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>;

/**
 * __useUpdateApprovalPolicyMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalPolicyMutation, { data, loading, error }] = useUpdateApprovalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApprovalPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>(UpdateApprovalPolicyDocument, baseOptions);
      }
export type UpdateApprovalPolicyMutationHookResult = ReturnType<typeof useUpdateApprovalPolicyMutation>;
export type UpdateApprovalPolicyMutationResult = ApolloReactCommon.MutationResult<UpdateApprovalPolicyMutation>;
export type UpdateApprovalPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApprovalPolicyMutation, UpdateApprovalPolicyMutationVariables>;
export const UpdateApprovalPolicyAssignmentDocument = gql`
    mutation updateApprovalPolicyAssignment($input: ApprovalPolicyAssignmentInput!) {
  updateApprovalPolicyAssignment(input: $input) {
    ApprovalPolicyAssignmentID
  }
}
    `;
export type UpdateApprovalPolicyAssignmentMutationFn = ApolloReactCommon.MutationFunction<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>;

/**
 * __useUpdateApprovalPolicyAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalPolicyAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalPolicyAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalPolicyAssignmentMutation, { data, loading, error }] = useUpdateApprovalPolicyAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApprovalPolicyAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>(UpdateApprovalPolicyAssignmentDocument, baseOptions);
      }
export type UpdateApprovalPolicyAssignmentMutationHookResult = ReturnType<typeof useUpdateApprovalPolicyAssignmentMutation>;
export type UpdateApprovalPolicyAssignmentMutationResult = ApolloReactCommon.MutationResult<UpdateApprovalPolicyAssignmentMutation>;
export type UpdateApprovalPolicyAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApprovalPolicyAssignmentMutation, UpdateApprovalPolicyAssignmentMutationVariables>;
export const DeleteApprovalPolicyDocument = gql`
    mutation deleteApprovalPolicy($input: ApprovalPolicyDeleteInput!) {
  deleteApprovalPolicy(input: $input)
}
    `;
export type DeleteApprovalPolicyMutationFn = ApolloReactCommon.MutationFunction<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>;

/**
 * __useDeleteApprovalPolicyMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalPolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalPolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalPolicyMutation, { data, loading, error }] = useDeleteApprovalPolicyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApprovalPolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>(DeleteApprovalPolicyDocument, baseOptions);
      }
export type DeleteApprovalPolicyMutationHookResult = ReturnType<typeof useDeleteApprovalPolicyMutation>;
export type DeleteApprovalPolicyMutationResult = ApolloReactCommon.MutationResult<DeleteApprovalPolicyMutation>;
export type DeleteApprovalPolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApprovalPolicyMutation, DeleteApprovalPolicyMutationVariables>;
export const DeleteApprovalPolicyAssignmentDocument = gql`
    mutation deleteApprovalPolicyAssignment($input: ApprovalPolicyAssignmentDeleteInput!) {
  deleteApprovalPolicyAssignment(input: $input)
}
    `;
export type DeleteApprovalPolicyAssignmentMutationFn = ApolloReactCommon.MutationFunction<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>;

/**
 * __useDeleteApprovalPolicyAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteApprovalPolicyAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApprovalPolicyAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApprovalPolicyAssignmentMutation, { data, loading, error }] = useDeleteApprovalPolicyAssignmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteApprovalPolicyAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>(DeleteApprovalPolicyAssignmentDocument, baseOptions);
      }
export type DeleteApprovalPolicyAssignmentMutationHookResult = ReturnType<typeof useDeleteApprovalPolicyAssignmentMutation>;
export type DeleteApprovalPolicyAssignmentMutationResult = ApolloReactCommon.MutationResult<DeleteApprovalPolicyAssignmentMutation>;
export type DeleteApprovalPolicyAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApprovalPolicyAssignmentMutation, DeleteApprovalPolicyAssignmentMutationVariables>;
export const GetBulkPurchasePriceDocument = gql`
    query getBulkPurchasePrice($ItemID: String, $SupplierID: String, $UOMID: String, $orderByAsc: String, $orderByDesc: String, $BulkPurchasePriceHeaderID: String) {
  getBulkPurchasePrice(ItemID: $ItemID, SupplierID: $SupplierID, UOMID: $UOMID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, BulkPurchasePriceHeaderID: $BulkPurchasePriceHeaderID) {
    BulkPurchasePriceHeaderID
    SupplierID
    Supplier {
      CompanyName
      CompanyRegNo
      ContactNo
      Email
      CreditTerm
      TaxSchemeID
    }
    EffectiveDate
    ExpiryDate
    Remarks
    BulkPurchasePriceItem {
      BulkPurchasePriceItemID
      BulkPurchasePriceHeaderID
      ItemID
      Item {
        Name
        Description
      }
      UOMID
      UOM {
        Code
        Name
      }
      BulkPurchasePriceDetail {
        LowerLimit
        UpperLimit
        UnitPrice
      }
    }
  }
}
    `;

/**
 * __useGetBulkPurchasePriceQuery__
 *
 * To run a query within a React component, call `useGetBulkPurchasePriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBulkPurchasePriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBulkPurchasePriceQuery({
 *   variables: {
 *      ItemID: // value for 'ItemID'
 *      SupplierID: // value for 'SupplierID'
 *      UOMID: // value for 'UOMID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      BulkPurchasePriceHeaderID: // value for 'BulkPurchasePriceHeaderID'
 *   },
 * });
 */
export function useGetBulkPurchasePriceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBulkPurchasePriceQuery, GetBulkPurchasePriceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetBulkPurchasePriceQuery, GetBulkPurchasePriceQueryVariables>(GetBulkPurchasePriceDocument, baseOptions);
      }
export function useGetBulkPurchasePriceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBulkPurchasePriceQuery, GetBulkPurchasePriceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetBulkPurchasePriceQuery, GetBulkPurchasePriceQueryVariables>(GetBulkPurchasePriceDocument, baseOptions);
        }
export type GetBulkPurchasePriceQueryHookResult = ReturnType<typeof useGetBulkPurchasePriceQuery>;
export type GetBulkPurchasePriceLazyQueryHookResult = ReturnType<typeof useGetBulkPurchasePriceLazyQuery>;
export type GetBulkPurchasePriceQueryResult = ApolloReactCommon.QueryResult<GetBulkPurchasePriceQuery, GetBulkPurchasePriceQueryVariables>;
export const CreateBulkPurchasePriceDocument = gql`
    mutation createBulkPurchasePrice($input: BulkPurchasePriceHeaderInput!, $bulkPurchaseItemInput: [BulkPurchasePriceItemInput!]!) {
  createBulkPurchasePrice(input: $input, bulkPurchaseItemInput: $bulkPurchaseItemInput) {
    SupplierID
    EffectiveDate
    ExpiryDate
    Remarks
    BulkPurchasePriceItem {
      ItemID
      UOMID
      BulkPurchasePriceDetail {
        LowerLimit
        UpperLimit
        UnitPrice
      }
    }
  }
}
    `;
export type CreateBulkPurchasePriceMutationFn = ApolloReactCommon.MutationFunction<CreateBulkPurchasePriceMutation, CreateBulkPurchasePriceMutationVariables>;

/**
 * __useCreateBulkPurchasePriceMutation__
 *
 * To run a mutation, you first call `useCreateBulkPurchasePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBulkPurchasePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBulkPurchasePriceMutation, { data, loading, error }] = useCreateBulkPurchasePriceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      bulkPurchaseItemInput: // value for 'bulkPurchaseItemInput'
 *   },
 * });
 */
export function useCreateBulkPurchasePriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBulkPurchasePriceMutation, CreateBulkPurchasePriceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBulkPurchasePriceMutation, CreateBulkPurchasePriceMutationVariables>(CreateBulkPurchasePriceDocument, baseOptions);
      }
export type CreateBulkPurchasePriceMutationHookResult = ReturnType<typeof useCreateBulkPurchasePriceMutation>;
export type CreateBulkPurchasePriceMutationResult = ApolloReactCommon.MutationResult<CreateBulkPurchasePriceMutation>;
export type CreateBulkPurchasePriceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBulkPurchasePriceMutation, CreateBulkPurchasePriceMutationVariables>;
export const UpdateBulkPurchasePriceDocument = gql`
    mutation updateBulkPurchasePrice($BulkPurchasePriceHeaderID: String!, $input: BulkPurchasePriceHeaderInput!, $bulkPurchaseItemInput: [BulkPurchasePriceItemInput!]!) {
  updateBulkPurchasePrice(BulkPurchasePriceHeaderID: $BulkPurchasePriceHeaderID, input: $input, bulkPurchaseItemInput: $bulkPurchaseItemInput)
}
    `;
export type UpdateBulkPurchasePriceMutationFn = ApolloReactCommon.MutationFunction<UpdateBulkPurchasePriceMutation, UpdateBulkPurchasePriceMutationVariables>;

/**
 * __useUpdateBulkPurchasePriceMutation__
 *
 * To run a mutation, you first call `useUpdateBulkPurchasePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBulkPurchasePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBulkPurchasePriceMutation, { data, loading, error }] = useUpdateBulkPurchasePriceMutation({
 *   variables: {
 *      BulkPurchasePriceHeaderID: // value for 'BulkPurchasePriceHeaderID'
 *      input: // value for 'input'
 *      bulkPurchaseItemInput: // value for 'bulkPurchaseItemInput'
 *   },
 * });
 */
export function useUpdateBulkPurchasePriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBulkPurchasePriceMutation, UpdateBulkPurchasePriceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBulkPurchasePriceMutation, UpdateBulkPurchasePriceMutationVariables>(UpdateBulkPurchasePriceDocument, baseOptions);
      }
export type UpdateBulkPurchasePriceMutationHookResult = ReturnType<typeof useUpdateBulkPurchasePriceMutation>;
export type UpdateBulkPurchasePriceMutationResult = ApolloReactCommon.MutationResult<UpdateBulkPurchasePriceMutation>;
export type UpdateBulkPurchasePriceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBulkPurchasePriceMutation, UpdateBulkPurchasePriceMutationVariables>;
export const DeleteBulkPurchasePriceDocument = gql`
    mutation deleteBulkPurchasePrice($BulkPurchasePriceHeaderID: String!) {
  deleteBulkPurchasePrice(BulkPurchasePriceHeaderID: $BulkPurchasePriceHeaderID)
}
    `;
export type DeleteBulkPurchasePriceMutationFn = ApolloReactCommon.MutationFunction<DeleteBulkPurchasePriceMutation, DeleteBulkPurchasePriceMutationVariables>;

/**
 * __useDeleteBulkPurchasePriceMutation__
 *
 * To run a mutation, you first call `useDeleteBulkPurchasePriceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBulkPurchasePriceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBulkPurchasePriceMutation, { data, loading, error }] = useDeleteBulkPurchasePriceMutation({
 *   variables: {
 *      BulkPurchasePriceHeaderID: // value for 'BulkPurchasePriceHeaderID'
 *   },
 * });
 */
export function useDeleteBulkPurchasePriceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteBulkPurchasePriceMutation, DeleteBulkPurchasePriceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteBulkPurchasePriceMutation, DeleteBulkPurchasePriceMutationVariables>(DeleteBulkPurchasePriceDocument, baseOptions);
      }
export type DeleteBulkPurchasePriceMutationHookResult = ReturnType<typeof useDeleteBulkPurchasePriceMutation>;
export type DeleteBulkPurchasePriceMutationResult = ApolloReactCommon.MutationResult<DeleteBulkPurchasePriceMutation>;
export type DeleteBulkPurchasePriceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteBulkPurchasePriceMutation, DeleteBulkPurchasePriceMutationVariables>;
export const GetGeneralSettingSummaryDocument = gql`
    query getGeneralSettingSummary {
  activeSupplierCategoryCount
  activeSupplierProfileCount
  activeUomCount
  lastUomExchange
  activePurchaseCategoryCount
  activeItemCount
  activeBulkPurchaseCount
  lastPurchaseVariance
  purchaseVariancePercenetage
}
    `;

/**
 * __useGetGeneralSettingSummaryQuery__
 *
 * To run a query within a React component, call `useGetGeneralSettingSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSettingSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralSettingSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralSettingSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>(GetGeneralSettingSummaryDocument, baseOptions);
      }
export function useGetGeneralSettingSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>(GetGeneralSettingSummaryDocument, baseOptions);
        }
export type GetGeneralSettingSummaryQueryHookResult = ReturnType<typeof useGetGeneralSettingSummaryQuery>;
export type GetGeneralSettingSummaryLazyQueryHookResult = ReturnType<typeof useGetGeneralSettingSummaryLazyQuery>;
export type GetGeneralSettingSummaryQueryResult = ApolloReactCommon.QueryResult<GetGeneralSettingSummaryQuery, GetGeneralSettingSummaryQueryVariables>;
export const GetRoleWithAccountDocument = gql`
    query getRoleWithAccount($DifferentConnection: String) {
  getRoleWithAccount(DifferentConnection: $DifferentConnection) {
    ID
    accountID
    name
    createdTs
    createdBy
    modTs
  }
}
    `;

/**
 * __useGetRoleWithAccountQuery__
 *
 * To run a query within a React component, call `useGetRoleWithAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleWithAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleWithAccountQuery({
 *   variables: {
 *      DifferentConnection: // value for 'DifferentConnection'
 *   },
 * });
 */
export function useGetRoleWithAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleWithAccountQuery, GetRoleWithAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleWithAccountQuery, GetRoleWithAccountQueryVariables>(GetRoleWithAccountDocument, baseOptions);
      }
export function useGetRoleWithAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleWithAccountQuery, GetRoleWithAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleWithAccountQuery, GetRoleWithAccountQueryVariables>(GetRoleWithAccountDocument, baseOptions);
        }
export type GetRoleWithAccountQueryHookResult = ReturnType<typeof useGetRoleWithAccountQuery>;
export type GetRoleWithAccountLazyQueryHookResult = ReturnType<typeof useGetRoleWithAccountLazyQuery>;
export type GetRoleWithAccountQueryResult = ApolloReactCommon.QueryResult<GetRoleWithAccountQuery, GetRoleWithAccountQueryVariables>;
export const GetItemDocument = gql`
    query getItem($skip: Float, $take: Float, $ItemID: String, $SupplierID: String, $orderByAsc: String, $orderByDesc: String, $ItemCategoryID: String) {
  getItem(skip: $skip, take: $take, SupplierID: $SupplierID, ItemID: $ItemID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, ItemCategoryID: $ItemCategoryID) {
    ItemID
    ItemCategoryID
    AccountID
    Name
    Description
    IsCentralised
    IsInventory
    RecordStatus
    DefaultSupplierID
    DefaultSupplier {
      CreditorAccountID
      CompanyName
      CompanyRegNo
    }
    PRItem {
      PRItemID
    }
    UOM {
      UOMID
      Name
      Code
    }
    UOMID
    TaxSchemeID
    TaxScheme {
      TaxSchemeID
      Code
      Description
      AccTaxCategory
    }
    ItemCategory {
      Name
    }
    Description
    Remark
    VariancePerc
    IsUse
    UsedInIC
  }
}
    `;

/**
 * __useGetItemQuery__
 *
 * To run a query within a React component, call `useGetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ItemID: // value for 'ItemID'
 *      SupplierID: // value for 'SupplierID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      ItemCategoryID: // value for 'ItemCategoryID'
 *   },
 * });
 */
export function useGetItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, baseOptions);
      }
export function useGetItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, baseOptions);
        }
export type GetItemQueryHookResult = ReturnType<typeof useGetItemQuery>;
export type GetItemLazyQueryHookResult = ReturnType<typeof useGetItemLazyQuery>;
export type GetItemQueryResult = ApolloReactCommon.QueryResult<GetItemQuery, GetItemQueryVariables>;
export const DeleteItemDocument = gql`
    mutation deleteItem($ItemID: String!) {
  deleteItem(ItemID: $ItemID)
}
    `;
export type DeleteItemMutationFn = ApolloReactCommon.MutationFunction<DeleteItemMutation, DeleteItemMutationVariables>;

/**
 * __useDeleteItemMutation__
 *
 * To run a mutation, you first call `useDeleteItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemMutation, { data, loading, error }] = useDeleteItemMutation({
 *   variables: {
 *      ItemID: // value for 'ItemID'
 *   },
 * });
 */
export function useDeleteItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteItemMutation, DeleteItemMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteItemMutation, DeleteItemMutationVariables>(DeleteItemDocument, baseOptions);
      }
export type DeleteItemMutationHookResult = ReturnType<typeof useDeleteItemMutation>;
export type DeleteItemMutationResult = ApolloReactCommon.MutationResult<DeleteItemMutation>;
export type DeleteItemMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteItemMutation, DeleteItemMutationVariables>;
export const GetCheckingValidationItemDocument = gql`
    query getCheckingValidationItem($ItemExcelInput: [ItemExcelInput!]!) {
  getCheckingValidationItem(ItemExcelInput: $ItemExcelInput)
}
    `;

/**
 * __useGetCheckingValidationItemQuery__
 *
 * To run a query within a React component, call `useGetCheckingValidationItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckingValidationItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckingValidationItemQuery({
 *   variables: {
 *      ItemExcelInput: // value for 'ItemExcelInput'
 *   },
 * });
 */
export function useGetCheckingValidationItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCheckingValidationItemQuery, GetCheckingValidationItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCheckingValidationItemQuery, GetCheckingValidationItemQueryVariables>(GetCheckingValidationItemDocument, baseOptions);
      }
export function useGetCheckingValidationItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCheckingValidationItemQuery, GetCheckingValidationItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCheckingValidationItemQuery, GetCheckingValidationItemQueryVariables>(GetCheckingValidationItemDocument, baseOptions);
        }
export type GetCheckingValidationItemQueryHookResult = ReturnType<typeof useGetCheckingValidationItemQuery>;
export type GetCheckingValidationItemLazyQueryHookResult = ReturnType<typeof useGetCheckingValidationItemLazyQuery>;
export type GetCheckingValidationItemQueryResult = ApolloReactCommon.QueryResult<GetCheckingValidationItemQuery, GetCheckingValidationItemQueryVariables>;
export const CreateItemDocument = gql`
    mutation createItem($input: ItemInput!) {
  createItem(input: $input) {
    ItemID
    createdBy
  }
}
    `;
export type CreateItemMutationFn = ApolloReactCommon.MutationFunction<CreateItemMutation, CreateItemMutationVariables>;

/**
 * __useCreateItemMutation__
 *
 * To run a mutation, you first call `useCreateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemMutation, { data, loading, error }] = useCreateItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateItemMutation, CreateItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateItemMutation, CreateItemMutationVariables>(CreateItemDocument, baseOptions);
      }
export type CreateItemMutationHookResult = ReturnType<typeof useCreateItemMutation>;
export type CreateItemMutationResult = ApolloReactCommon.MutationResult<CreateItemMutation>;
export type CreateItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateItemMutation, CreateItemMutationVariables>;
export const UpdateItemDocument = gql`
    mutation updateItem($input: ItemInput!) {
  updateItem(input: $input)
}
    `;
export type UpdateItemMutationFn = ApolloReactCommon.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, baseOptions);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = ApolloReactCommon.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
export const CreateBatchItemDocument = gql`
    mutation createBatchItem($input: [ItemExcelInput!]!) {
  createBatchItem(input: $input)
}
    `;
export type CreateBatchItemMutationFn = ApolloReactCommon.MutationFunction<CreateBatchItemMutation, CreateBatchItemMutationVariables>;

/**
 * __useCreateBatchItemMutation__
 *
 * To run a mutation, you first call `useCreateBatchItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchItemMutation, { data, loading, error }] = useCreateBatchItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBatchItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBatchItemMutation, CreateBatchItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBatchItemMutation, CreateBatchItemMutationVariables>(CreateBatchItemDocument, baseOptions);
      }
export type CreateBatchItemMutationHookResult = ReturnType<typeof useCreateBatchItemMutation>;
export type CreateBatchItemMutationResult = ApolloReactCommon.MutationResult<CreateBatchItemMutation>;
export type CreateBatchItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBatchItemMutation, CreateBatchItemMutationVariables>;
export const CancelItemDocument = gql`
    mutation cancelItem($ItemID: String!) {
  cancelItem(ItemID: $ItemID)
}
    `;
export type CancelItemMutationFn = ApolloReactCommon.MutationFunction<CancelItemMutation, CancelItemMutationVariables>;

/**
 * __useCancelItemMutation__
 *
 * To run a mutation, you first call `useCancelItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelItemMutation, { data, loading, error }] = useCancelItemMutation({
 *   variables: {
 *      ItemID: // value for 'ItemID'
 *   },
 * });
 */
export function useCancelItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelItemMutation, CancelItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelItemMutation, CancelItemMutationVariables>(CancelItemDocument, baseOptions);
      }
export type CancelItemMutationHookResult = ReturnType<typeof useCancelItemMutation>;
export type CancelItemMutationResult = ApolloReactCommon.MutationResult<CancelItemMutation>;
export type CancelItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelItemMutation, CancelItemMutationVariables>;
export const GetApItemMappingDocument = gql`
    query getAPItemMapping($ExpenseItemID: String, $UOMID: String, $orderByAsc: String, $orderByDesc: String) {
  getExpenseItem(ExpenseItemID: $ExpenseItemID, UOMID: $UOMID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ExpenseItemID
    ExpenseClassID
    ItemName
    Description
    Remark
    TaxSchemeID
    TaxRate
  }
}
    `;

/**
 * __useGetApItemMappingQuery__
 *
 * To run a query within a React component, call `useGetApItemMappingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApItemMappingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApItemMappingQuery({
 *   variables: {
 *      ExpenseItemID: // value for 'ExpenseItemID'
 *      UOMID: // value for 'UOMID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetApItemMappingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApItemMappingQuery, GetApItemMappingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApItemMappingQuery, GetApItemMappingQueryVariables>(GetApItemMappingDocument, baseOptions);
      }
export function useGetApItemMappingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApItemMappingQuery, GetApItemMappingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApItemMappingQuery, GetApItemMappingQueryVariables>(GetApItemMappingDocument, baseOptions);
        }
export type GetApItemMappingQueryHookResult = ReturnType<typeof useGetApItemMappingQuery>;
export type GetApItemMappingLazyQueryHookResult = ReturnType<typeof useGetApItemMappingLazyQuery>;
export type GetApItemMappingQueryResult = ApolloReactCommon.QueryResult<GetApItemMappingQuery, GetApItemMappingQueryVariables>;
export const GetWarehouseByStockItemDocument = gql`
    query getWarehouseByStockItem($StockItemID: String!) {
  getWarehouseByStockItem(StockItemID: $StockItemID)
}
    `;

/**
 * __useGetWarehouseByStockItemQuery__
 *
 * To run a query within a React component, call `useGetWarehouseByStockItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseByStockItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseByStockItemQuery({
 *   variables: {
 *      StockItemID: // value for 'StockItemID'
 *   },
 * });
 */
export function useGetWarehouseByStockItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWarehouseByStockItemQuery, GetWarehouseByStockItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWarehouseByStockItemQuery, GetWarehouseByStockItemQueryVariables>(GetWarehouseByStockItemDocument, baseOptions);
      }
export function useGetWarehouseByStockItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWarehouseByStockItemQuery, GetWarehouseByStockItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWarehouseByStockItemQuery, GetWarehouseByStockItemQueryVariables>(GetWarehouseByStockItemDocument, baseOptions);
        }
export type GetWarehouseByStockItemQueryHookResult = ReturnType<typeof useGetWarehouseByStockItemQuery>;
export type GetWarehouseByStockItemLazyQueryHookResult = ReturnType<typeof useGetWarehouseByStockItemLazyQuery>;
export type GetWarehouseByStockItemQueryResult = ApolloReactCommon.QueryResult<GetWarehouseByStockItemQuery, GetWarehouseByStockItemQueryVariables>;
export const GetItemByCompanyAssignmentDocument = gql`
    query getItemByCompanyAssignment($CompanyID: String!, $orderByAsc: String, $orderByDesc: String) {
  getItemByCompanyAssignment(CompanyID: $CompanyID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ItemID
    ItemCategoryID
    AccountID
    Name
    Description
    IsCentralised
    IsInventory
    DefaultSupplierID
    DefaultSupplier {
      CreditorAccountID
      CompanyName
      CompanyRegNo
    }
    PRItem {
      PRItemID
    }
    UOM {
      UOMID
      Name
      Code
    }
    UOMID
    TaxSchemeID
    TaxScheme {
      TaxSchemeID
      Code
      Description
      AccTaxCategory
    }
    Description
    VariancePerc
  }
}
    `;

/**
 * __useGetItemByCompanyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetItemByCompanyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemByCompanyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemByCompanyAssignmentQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetItemByCompanyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemByCompanyAssignmentQuery, GetItemByCompanyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemByCompanyAssignmentQuery, GetItemByCompanyAssignmentQueryVariables>(GetItemByCompanyAssignmentDocument, baseOptions);
      }
export function useGetItemByCompanyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemByCompanyAssignmentQuery, GetItemByCompanyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemByCompanyAssignmentQuery, GetItemByCompanyAssignmentQueryVariables>(GetItemByCompanyAssignmentDocument, baseOptions);
        }
export type GetItemByCompanyAssignmentQueryHookResult = ReturnType<typeof useGetItemByCompanyAssignmentQuery>;
export type GetItemByCompanyAssignmentLazyQueryHookResult = ReturnType<typeof useGetItemByCompanyAssignmentLazyQuery>;
export type GetItemByCompanyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetItemByCompanyAssignmentQuery, GetItemByCompanyAssignmentQueryVariables>;
export const GetItemCategoryDocument = gql`
    query getItemCategory($AccountID: String, $orderByDesc: String, $orderByAsc: String, $ItemCategoryID: String) {
  getItemCategory(AccountID: $AccountID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc, ItemCategoryID: $ItemCategoryID) {
    ItemCategoryID
    Name
    ParentItemCategoryID
    Level
    IsLastNode
    MasterCOAID
    MasterCOA {
      MasterCOAID
      Code
      Name
    }
    Item {
      ItemID
      ItemCategoryID
      UOMID
      UOM {
        Name
        Code
      }
      Name
      Description
      TaxSchemeID
      AccountID
    }
  }
}
    `;

/**
 * __useGetItemCategoryQuery__
 *
 * To run a query within a React component, call `useGetItemCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemCategoryQuery({
 *   variables: {
 *      AccountID: // value for 'AccountID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      ItemCategoryID: // value for 'ItemCategoryID'
 *   },
 * });
 */
export function useGetItemCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemCategoryQuery, GetItemCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemCategoryQuery, GetItemCategoryQueryVariables>(GetItemCategoryDocument, baseOptions);
      }
export function useGetItemCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemCategoryQuery, GetItemCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemCategoryQuery, GetItemCategoryQueryVariables>(GetItemCategoryDocument, baseOptions);
        }
export type GetItemCategoryQueryHookResult = ReturnType<typeof useGetItemCategoryQuery>;
export type GetItemCategoryLazyQueryHookResult = ReturnType<typeof useGetItemCategoryLazyQuery>;
export type GetItemCategoryQueryResult = ApolloReactCommon.QueryResult<GetItemCategoryQuery, GetItemCategoryQueryVariables>;
export const GetItemCategoryCompanyAssignmentDocument = gql`
    query getItemCategoryCompanyAssignment($ItemCategoryCompanyAssignmentID: String, $CompanyID: String, $ItemCategoryID: String) {
  getItemCategoryCompanyAssignment(ItemCategoryCompanyAssignmentID: $ItemCategoryCompanyAssignmentID, CompanyID: $CompanyID, ItemCategoryID: $ItemCategoryID) {
    ItemCategoryCompanyAssignmentID
    ItemCategoryID
    CompanyID
    createdTs
    createdBy
    modTs
    modBy
    RecordStatus
    ItemCategory {
      createdTs
      createdBy
      modTs
      modBy
      ItemCategoryID
      MasterCOAID
      RecordStatus
      Name
    }
  }
}
    `;

/**
 * __useGetItemCategoryCompanyAssignmentQuery__
 *
 * To run a query within a React component, call `useGetItemCategoryCompanyAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemCategoryCompanyAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemCategoryCompanyAssignmentQuery({
 *   variables: {
 *      ItemCategoryCompanyAssignmentID: // value for 'ItemCategoryCompanyAssignmentID'
 *      CompanyID: // value for 'CompanyID'
 *      ItemCategoryID: // value for 'ItemCategoryID'
 *   },
 * });
 */
export function useGetItemCategoryCompanyAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetItemCategoryCompanyAssignmentQuery, GetItemCategoryCompanyAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetItemCategoryCompanyAssignmentQuery, GetItemCategoryCompanyAssignmentQueryVariables>(GetItemCategoryCompanyAssignmentDocument, baseOptions);
      }
export function useGetItemCategoryCompanyAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetItemCategoryCompanyAssignmentQuery, GetItemCategoryCompanyAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetItemCategoryCompanyAssignmentQuery, GetItemCategoryCompanyAssignmentQueryVariables>(GetItemCategoryCompanyAssignmentDocument, baseOptions);
        }
export type GetItemCategoryCompanyAssignmentQueryHookResult = ReturnType<typeof useGetItemCategoryCompanyAssignmentQuery>;
export type GetItemCategoryCompanyAssignmentLazyQueryHookResult = ReturnType<typeof useGetItemCategoryCompanyAssignmentLazyQuery>;
export type GetItemCategoryCompanyAssignmentQueryResult = ApolloReactCommon.QueryResult<GetItemCategoryCompanyAssignmentQuery, GetItemCategoryCompanyAssignmentQueryVariables>;
export const CreateItemCategoryDocument = gql`
    mutation createItemCategory($ItemCategoryInput: ItemCategoryInput!) {
  createItemCategory(ItemCategoryInput: $ItemCategoryInput) {
    ItemCategoryID
    createdBy
    Name
    MasterCOAID
  }
}
    `;
export type CreateItemCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateItemCategoryMutation, CreateItemCategoryMutationVariables>;

/**
 * __useCreateItemCategoryMutation__
 *
 * To run a mutation, you first call `useCreateItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createItemCategoryMutation, { data, loading, error }] = useCreateItemCategoryMutation({
 *   variables: {
 *      ItemCategoryInput: // value for 'ItemCategoryInput'
 *   },
 * });
 */
export function useCreateItemCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateItemCategoryMutation, CreateItemCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateItemCategoryMutation, CreateItemCategoryMutationVariables>(CreateItemCategoryDocument, baseOptions);
      }
export type CreateItemCategoryMutationHookResult = ReturnType<typeof useCreateItemCategoryMutation>;
export type CreateItemCategoryMutationResult = ApolloReactCommon.MutationResult<CreateItemCategoryMutation>;
export type CreateItemCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateItemCategoryMutation, CreateItemCategoryMutationVariables>;
export const UpdateItemCategoryDocument = gql`
    mutation updateItemCategory($ItemCategoryInput: ItemCategoryInput!) {
  updateItemCategory(ItemCategoryInput: $ItemCategoryInput)
}
    `;
export type UpdateItemCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateItemCategoryMutation, UpdateItemCategoryMutationVariables>;

/**
 * __useUpdateItemCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemCategoryMutation, { data, loading, error }] = useUpdateItemCategoryMutation({
 *   variables: {
 *      ItemCategoryInput: // value for 'ItemCategoryInput'
 *   },
 * });
 */
export function useUpdateItemCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateItemCategoryMutation, UpdateItemCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateItemCategoryMutation, UpdateItemCategoryMutationVariables>(UpdateItemCategoryDocument, baseOptions);
      }
export type UpdateItemCategoryMutationHookResult = ReturnType<typeof useUpdateItemCategoryMutation>;
export type UpdateItemCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateItemCategoryMutation>;
export type UpdateItemCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateItemCategoryMutation, UpdateItemCategoryMutationVariables>;
export const DeleteItemCategoryDocument = gql`
    mutation deleteItemCategory($ItemCategoryID: String!) {
  deleteItemCategory(ItemCategoryID: $ItemCategoryID)
}
    `;
export type DeleteItemCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteItemCategoryMutation, DeleteItemCategoryMutationVariables>;

/**
 * __useDeleteItemCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteItemCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemCategoryMutation, { data, loading, error }] = useDeleteItemCategoryMutation({
 *   variables: {
 *      ItemCategoryID: // value for 'ItemCategoryID'
 *   },
 * });
 */
export function useDeleteItemCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteItemCategoryMutation, DeleteItemCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteItemCategoryMutation, DeleteItemCategoryMutationVariables>(DeleteItemCategoryDocument, baseOptions);
      }
export type DeleteItemCategoryMutationHookResult = ReturnType<typeof useDeleteItemCategoryMutation>;
export type DeleteItemCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteItemCategoryMutation>;
export type DeleteItemCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteItemCategoryMutation, DeleteItemCategoryMutationVariables>;
export const AssignItemCategoryCompanyDocument = gql`
    mutation AssignItemCategoryCompany($input: ItemCategoryCompanyAssignmentInput!) {
  AssignItemCategoryCompany(input: $input)
}
    `;
export type AssignItemCategoryCompanyMutationFn = ApolloReactCommon.MutationFunction<AssignItemCategoryCompanyMutation, AssignItemCategoryCompanyMutationVariables>;

/**
 * __useAssignItemCategoryCompanyMutation__
 *
 * To run a mutation, you first call `useAssignItemCategoryCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignItemCategoryCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignItemCategoryCompanyMutation, { data, loading, error }] = useAssignItemCategoryCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignItemCategoryCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignItemCategoryCompanyMutation, AssignItemCategoryCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignItemCategoryCompanyMutation, AssignItemCategoryCompanyMutationVariables>(AssignItemCategoryCompanyDocument, baseOptions);
      }
export type AssignItemCategoryCompanyMutationHookResult = ReturnType<typeof useAssignItemCategoryCompanyMutation>;
export type AssignItemCategoryCompanyMutationResult = ApolloReactCommon.MutationResult<AssignItemCategoryCompanyMutation>;
export type AssignItemCategoryCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignItemCategoryCompanyMutation, AssignItemCategoryCompanyMutationVariables>;
export const UnassignItemCategoryCompanyDocument = gql`
    mutation UnassignItemCategoryCompany($input: ItemCategoryCompanyAssignmentInput!) {
  UnassignItemCategoryCompany(input: $input)
}
    `;
export type UnassignItemCategoryCompanyMutationFn = ApolloReactCommon.MutationFunction<UnassignItemCategoryCompanyMutation, UnassignItemCategoryCompanyMutationVariables>;

/**
 * __useUnassignItemCategoryCompanyMutation__
 *
 * To run a mutation, you first call `useUnassignItemCategoryCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnassignItemCategoryCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unassignItemCategoryCompanyMutation, { data, loading, error }] = useUnassignItemCategoryCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnassignItemCategoryCompanyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnassignItemCategoryCompanyMutation, UnassignItemCategoryCompanyMutationVariables>) {
        return ApolloReactHooks.useMutation<UnassignItemCategoryCompanyMutation, UnassignItemCategoryCompanyMutationVariables>(UnassignItemCategoryCompanyDocument, baseOptions);
      }
export type UnassignItemCategoryCompanyMutationHookResult = ReturnType<typeof useUnassignItemCategoryCompanyMutation>;
export type UnassignItemCategoryCompanyMutationResult = ApolloReactCommon.MutationResult<UnassignItemCategoryCompanyMutation>;
export type UnassignItemCategoryCompanyMutationOptions = ApolloReactCommon.BaseMutationOptions<UnassignItemCategoryCompanyMutation, UnassignItemCategoryCompanyMutationVariables>;
export const GetDocNumDocument = gql`
    query getDocNum($CompanyID: String!, $Type: DocumentType, $RefTable: String) {
  getDocumentNumberHeader(CompanyID: $CompanyID, Type: $Type, RefTable: $RefTable) {
    DocumentNumberHeaderID
    SampleOutput
    IsAutoDocNo
    DocNumGenerated
    DocumentNumberDetail {
      DocumentNumberDetailID
      Sequence
      FormatType
      FormatValue
      NextNumber
      StartFrom
      ResetBy
    }
  }
  getDocumentNumberDetail {
    Sequence
    FormatType
    FormatValue
    NextNumber
    StartFrom
    ResetBy
  }
}
    `;

/**
 * __useGetDocNumQuery__
 *
 * To run a query within a React component, call `useGetDocNumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocNumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocNumQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Type: // value for 'Type'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function useGetDocNumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocNumQuery, GetDocNumQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocNumQuery, GetDocNumQueryVariables>(GetDocNumDocument, baseOptions);
      }
export function useGetDocNumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocNumQuery, GetDocNumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocNumQuery, GetDocNumQueryVariables>(GetDocNumDocument, baseOptions);
        }
export type GetDocNumQueryHookResult = ReturnType<typeof useGetDocNumQuery>;
export type GetDocNumLazyQueryHookResult = ReturnType<typeof useGetDocNumLazyQuery>;
export type GetDocNumQueryResult = ApolloReactCommon.QueryResult<GetDocNumQuery, GetDocNumQueryVariables>;
export const GetDocumentNumberHeaderDocument = gql`
    query getDocumentNumberHeader($CompanyID: String!, $Type: DocumentType, $RefTable: String) {
  getDocumentNumberHeader(CompanyID: $CompanyID, Type: $Type, RefTable: $RefTable) {
    DocumentNumberHeaderID
    SampleOutput
    IsAutoDocNo
    DocNumGenerated
    RefTable
  }
}
    `;

/**
 * __useGetDocumentNumberHeaderQuery__
 *
 * To run a query within a React component, call `useGetDocumentNumberHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentNumberHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentNumberHeaderQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Type: // value for 'Type'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function useGetDocumentNumberHeaderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocumentNumberHeaderQuery, GetDocumentNumberHeaderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocumentNumberHeaderQuery, GetDocumentNumberHeaderQueryVariables>(GetDocumentNumberHeaderDocument, baseOptions);
      }
export function useGetDocumentNumberHeaderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocumentNumberHeaderQuery, GetDocumentNumberHeaderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocumentNumberHeaderQuery, GetDocumentNumberHeaderQueryVariables>(GetDocumentNumberHeaderDocument, baseOptions);
        }
export type GetDocumentNumberHeaderQueryHookResult = ReturnType<typeof useGetDocumentNumberHeaderQuery>;
export type GetDocumentNumberHeaderLazyQueryHookResult = ReturnType<typeof useGetDocumentNumberHeaderLazyQuery>;
export type GetDocumentNumberHeaderQueryResult = ApolloReactCommon.QueryResult<GetDocumentNumberHeaderQuery, GetDocumentNumberHeaderQueryVariables>;
export const GetDocumentNumberDetailDocument = gql`
    query getDocumentNumberDetail($DocumentNumberHeaderID: String!) {
  getDocumentNumberDetail(DocumentNumberHeaderID: $DocumentNumberHeaderID) {
    DocumentNumberDetailID
    Sequence
    FormatType
    FormatValue
    NextNumber
    StartFrom
    ResetBy
  }
}
    `;

/**
 * __useGetDocumentNumberDetailQuery__
 *
 * To run a query within a React component, call `useGetDocumentNumberDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentNumberDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentNumberDetailQuery({
 *   variables: {
 *      DocumentNumberHeaderID: // value for 'DocumentNumberHeaderID'
 *   },
 * });
 */
export function useGetDocumentNumberDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocumentNumberDetailQuery, GetDocumentNumberDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocumentNumberDetailQuery, GetDocumentNumberDetailQueryVariables>(GetDocumentNumberDetailDocument, baseOptions);
      }
export function useGetDocumentNumberDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocumentNumberDetailQuery, GetDocumentNumberDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocumentNumberDetailQuery, GetDocumentNumberDetailQueryVariables>(GetDocumentNumberDetailDocument, baseOptions);
        }
export type GetDocumentNumberDetailQueryHookResult = ReturnType<typeof useGetDocumentNumberDetailQuery>;
export type GetDocumentNumberDetailLazyQueryHookResult = ReturnType<typeof useGetDocumentNumberDetailLazyQuery>;
export type GetDocumentNumberDetailQueryResult = ApolloReactCommon.QueryResult<GetDocumentNumberDetailQuery, GetDocumentNumberDetailQueryVariables>;
export const GetDocNumTitleDocument = gql`
    query getDocNumTitle($CompanyID: String!, $Type: DocumentType, $RefTable: String) {
  getDocumentNumberHeader(CompanyID: $CompanyID, Type: $Type, RefTable: $RefTable) {
    SampleOutput
    DocumentNumberHeaderID
    Type
    RefTable
    IsAutoDocNo
    DocNumGenerated
  }
}
    `;

/**
 * __useGetDocNumTitleQuery__
 *
 * To run a query within a React component, call `useGetDocNumTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocNumTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocNumTitleQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      Type: // value for 'Type'
 *      RefTable: // value for 'RefTable'
 *   },
 * });
 */
export function useGetDocNumTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>(GetDocNumTitleDocument, baseOptions);
      }
export function useGetDocNumTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>(GetDocNumTitleDocument, baseOptions);
        }
export type GetDocNumTitleQueryHookResult = ReturnType<typeof useGetDocNumTitleQuery>;
export type GetDocNumTitleLazyQueryHookResult = ReturnType<typeof useGetDocNumTitleLazyQuery>;
export type GetDocNumTitleQueryResult = ApolloReactCommon.QueryResult<GetDocNumTitleQuery, GetDocNumTitleQueryVariables>;
export const UpdateDocumentNumberHeaderDocument = gql`
    mutation updateDocumentNumberHeader($DocumentNumberDetailInput: [DocumentNumberDetailInput!]!, $DocumentNumberHeaderInput: DocumentNumberHeaderInput!, $DifferentConnection: String) {
  updateDocumentNumberHeader(DocumentNumberDetailInput: $DocumentNumberDetailInput, DocumentNumberHeaderInput: $DocumentNumberHeaderInput, DifferentConnection: $DifferentConnection)
}
    `;
export type UpdateDocumentNumberHeaderMutationFn = ApolloReactCommon.MutationFunction<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>;

/**
 * __useUpdateDocumentNumberHeaderMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentNumberHeaderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentNumberHeaderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentNumberHeaderMutation, { data, loading, error }] = useUpdateDocumentNumberHeaderMutation({
 *   variables: {
 *      DocumentNumberDetailInput: // value for 'DocumentNumberDetailInput'
 *      DocumentNumberHeaderInput: // value for 'DocumentNumberHeaderInput'
 *      DifferentConnection: // value for 'DifferentConnection'
 *   },
 * });
 */
export function useUpdateDocumentNumberHeaderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>(UpdateDocumentNumberHeaderDocument, baseOptions);
      }
export type UpdateDocumentNumberHeaderMutationHookResult = ReturnType<typeof useUpdateDocumentNumberHeaderMutation>;
export type UpdateDocumentNumberHeaderMutationResult = ApolloReactCommon.MutationResult<UpdateDocumentNumberHeaderMutation>;
export type UpdateDocumentNumberHeaderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDocumentNumberHeaderMutation, UpdateDocumentNumberHeaderMutationVariables>;
export const UpdateIsAutoDocNoDocument = gql`
    mutation updateIsAutoDocNo($CompanyID: String!, $RefTable: String!, $IsAutoDocNo: Boolean!, $input: DocumentNumberHeaderInput!) {
  updateIsAutoDocNo(CompanyID: $CompanyID, RefTable: $RefTable, IsAutoDocNo: $IsAutoDocNo, input: $input)
}
    `;
export type UpdateIsAutoDocNoMutationFn = ApolloReactCommon.MutationFunction<UpdateIsAutoDocNoMutation, UpdateIsAutoDocNoMutationVariables>;

/**
 * __useUpdateIsAutoDocNoMutation__
 *
 * To run a mutation, you first call `useUpdateIsAutoDocNoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIsAutoDocNoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIsAutoDocNoMutation, { data, loading, error }] = useUpdateIsAutoDocNoMutation({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *      RefTable: // value for 'RefTable'
 *      IsAutoDocNo: // value for 'IsAutoDocNo'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateIsAutoDocNoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateIsAutoDocNoMutation, UpdateIsAutoDocNoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateIsAutoDocNoMutation, UpdateIsAutoDocNoMutationVariables>(UpdateIsAutoDocNoDocument, baseOptions);
      }
export type UpdateIsAutoDocNoMutationHookResult = ReturnType<typeof useUpdateIsAutoDocNoMutation>;
export type UpdateIsAutoDocNoMutationResult = ApolloReactCommon.MutationResult<UpdateIsAutoDocNoMutation>;
export type UpdateIsAutoDocNoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateIsAutoDocNoMutation, UpdateIsAutoDocNoMutationVariables>;
export const GetPurchaseItemVariancePolicyDocument = gql`
    query getPurchaseItemVariancePolicy($PurchaseItemVariancePolicyID: String) {
  getPurchaseItemVariancePolicy(PurchaseItemVariancePolicyID: $PurchaseItemVariancePolicyID) {
    PurchaseItemVariancePolicyID
    VariancePerc
  }
}
    `;

/**
 * __useGetPurchaseItemVariancePolicyQuery__
 *
 * To run a query within a React component, call `useGetPurchaseItemVariancePolicyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseItemVariancePolicyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseItemVariancePolicyQuery({
 *   variables: {
 *      PurchaseItemVariancePolicyID: // value for 'PurchaseItemVariancePolicyID'
 *   },
 * });
 */
export function useGetPurchaseItemVariancePolicyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseItemVariancePolicyQuery, GetPurchaseItemVariancePolicyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseItemVariancePolicyQuery, GetPurchaseItemVariancePolicyQueryVariables>(GetPurchaseItemVariancePolicyDocument, baseOptions);
      }
export function useGetPurchaseItemVariancePolicyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseItemVariancePolicyQuery, GetPurchaseItemVariancePolicyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseItemVariancePolicyQuery, GetPurchaseItemVariancePolicyQueryVariables>(GetPurchaseItemVariancePolicyDocument, baseOptions);
        }
export type GetPurchaseItemVariancePolicyQueryHookResult = ReturnType<typeof useGetPurchaseItemVariancePolicyQuery>;
export type GetPurchaseItemVariancePolicyLazyQueryHookResult = ReturnType<typeof useGetPurchaseItemVariancePolicyLazyQuery>;
export type GetPurchaseItemVariancePolicyQueryResult = ApolloReactCommon.QueryResult<GetPurchaseItemVariancePolicyQuery, GetPurchaseItemVariancePolicyQueryVariables>;
export const CreatePurchaseItemVariancePolicyDocument = gql`
    mutation createPurchaseItemVariancePolicy($PurchaseItemVariancePolicyInput: PurchaseItemVariancePolicyInput!) {
  createPurchaseItemVariancePolicy(PurchaseItemVariancePolicyInput: $PurchaseItemVariancePolicyInput)
}
    `;
export type CreatePurchaseItemVariancePolicyMutationFn = ApolloReactCommon.MutationFunction<CreatePurchaseItemVariancePolicyMutation, CreatePurchaseItemVariancePolicyMutationVariables>;

/**
 * __useCreatePurchaseItemVariancePolicyMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseItemVariancePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseItemVariancePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseItemVariancePolicyMutation, { data, loading, error }] = useCreatePurchaseItemVariancePolicyMutation({
 *   variables: {
 *      PurchaseItemVariancePolicyInput: // value for 'PurchaseItemVariancePolicyInput'
 *   },
 * });
 */
export function useCreatePurchaseItemVariancePolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePurchaseItemVariancePolicyMutation, CreatePurchaseItemVariancePolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePurchaseItemVariancePolicyMutation, CreatePurchaseItemVariancePolicyMutationVariables>(CreatePurchaseItemVariancePolicyDocument, baseOptions);
      }
export type CreatePurchaseItemVariancePolicyMutationHookResult = ReturnType<typeof useCreatePurchaseItemVariancePolicyMutation>;
export type CreatePurchaseItemVariancePolicyMutationResult = ApolloReactCommon.MutationResult<CreatePurchaseItemVariancePolicyMutation>;
export type CreatePurchaseItemVariancePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePurchaseItemVariancePolicyMutation, CreatePurchaseItemVariancePolicyMutationVariables>;
export const UpdatePurchaseItemVariancePolicyDocument = gql`
    mutation updatePurchaseItemVariancePolicy($PurchaseItemVariancePolicyID: String!, $PurchaseItemVariancePolicyInput: PurchaseItemVariancePolicyInput!) {
  updatePurchaseItemVariancePolicy(PurchaseItemVariancePolicyID: $PurchaseItemVariancePolicyID, PurchaseItemVariancePolicyInput: $PurchaseItemVariancePolicyInput)
}
    `;
export type UpdatePurchaseItemVariancePolicyMutationFn = ApolloReactCommon.MutationFunction<UpdatePurchaseItemVariancePolicyMutation, UpdatePurchaseItemVariancePolicyMutationVariables>;

/**
 * __useUpdatePurchaseItemVariancePolicyMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseItemVariancePolicyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseItemVariancePolicyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseItemVariancePolicyMutation, { data, loading, error }] = useUpdatePurchaseItemVariancePolicyMutation({
 *   variables: {
 *      PurchaseItemVariancePolicyID: // value for 'PurchaseItemVariancePolicyID'
 *      PurchaseItemVariancePolicyInput: // value for 'PurchaseItemVariancePolicyInput'
 *   },
 * });
 */
export function useUpdatePurchaseItemVariancePolicyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePurchaseItemVariancePolicyMutation, UpdatePurchaseItemVariancePolicyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePurchaseItemVariancePolicyMutation, UpdatePurchaseItemVariancePolicyMutationVariables>(UpdatePurchaseItemVariancePolicyDocument, baseOptions);
      }
export type UpdatePurchaseItemVariancePolicyMutationHookResult = ReturnType<typeof useUpdatePurchaseItemVariancePolicyMutation>;
export type UpdatePurchaseItemVariancePolicyMutationResult = ApolloReactCommon.MutationResult<UpdatePurchaseItemVariancePolicyMutation>;
export type UpdatePurchaseItemVariancePolicyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePurchaseItemVariancePolicyMutation, UpdatePurchaseItemVariancePolicyMutationVariables>;
export const GetRoleDocument = gql`
    query getRole($ID: String) {
  getRole(ID: $ID) {
    ID
    name
  }
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
      }
export function useGetRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = ApolloReactCommon.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const GetHomeSummaryDocument = gql`
    query getHomeSummary($CompanyID: String) {
  scheduledPurchaseInfoCount(CompanyID: $CompanyID)
  prInfoCount(CompanyID: $CompanyID)
  rfqCount(CompanyID: $CompanyID)
  poInfoCount(CompanyID: $CompanyID)
  doInfoCount(CompanyID: $CompanyID)
  lastDODate(CompanyID: $CompanyID)
  grtnInfoCount(CompanyID: $CompanyID)
  lastGRTNDate(CompanyID: $CompanyID)
  procurementLoggedInUserProfile {
    ID
    accountID
    avatar
    name
    email
    contactNo
    department
    superUser
    userName
    jobTitle
    lastestAccessedEntity
  }
  getRolePermission
  getAccountPermission
}
    `;

/**
 * __useGetHomeSummaryQuery__
 *
 * To run a query within a React component, call `useGetHomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeSummaryQuery({
 *   variables: {
 *      CompanyID: // value for 'CompanyID'
 *   },
 * });
 */
export function useGetHomeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>(GetHomeSummaryDocument, baseOptions);
      }
export function useGetHomeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>(GetHomeSummaryDocument, baseOptions);
        }
export type GetHomeSummaryQueryHookResult = ReturnType<typeof useGetHomeSummaryQuery>;
export type GetHomeSummaryLazyQueryHookResult = ReturnType<typeof useGetHomeSummaryLazyQuery>;
export type GetHomeSummaryQueryResult = ApolloReactCommon.QueryResult<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>;