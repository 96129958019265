import React, { lazy } from 'react';

//System Admin//
const SystemAdminSubmenu = lazy(() =>
  import('./SystemAdminSubmenu').then(module => ({
    default: module.SystemAdminSubmenu,
  })),
);

const systemAdminRoutes = [
  {
    props: { exact: true, path: '/system-admin' },
    component: <SystemAdminSubmenu />,
  },
];

export default systemAdminRoutes;
