import React, { lazy } from 'react';

const ItemCategoryListing = lazy(() =>
  import('./ItemCategoryListing').then(module => ({
    default: module.ItemCategoryListing,
  })),
);

const ItemCategoryAssignForm = lazy(() =>
  import('./ItemCategoryAssignment').then(module => ({
    default: module.ItemCategoryAssignment,
  })),
);

const itemCategoryRoutes = [
  {
    props: {
      exact: true,
      path: '/general-setting/purchase-class',
    },
    component: <ItemCategoryListing />,
  },
  {
    props: {
      exact: true,
      path: '/general-setting/purchase-class/:ItemCategoryID/assign',
    },
    component: <ItemCategoryAssignForm />,
  },
];

export default itemCategoryRoutes;
