import React, { lazy } from 'react';

const UOMListing = lazy(() =>
  import('./UOMListing').then(module => ({
    default: module.UOMListing,
  })),
);

// const UOMExchangeListing = lazy(() =>
//   import(
//     '@ifca-root//UOMExchange/UOMRateListing'
//   ).then(module => ({
//     default: module.UOMRateListing,
//   })),
// );

const uomRoutes = [
  {
    props: { exact: true, path: '/general-setting/uom' },
    component: <UOMListing />,
  },
];

export default uomRoutes;
