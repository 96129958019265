import React from 'react';
import { NumberStructureSubmenu } from './NumberStructureSubmenu';
import autoNumberingRoutes from './AutoNumbering/AutoNumberingRoutes';

const numberingStructureRoutes = [
  {
    props: {
      exact: true,
      path: '/company-settings/:CompanyID/numbering-structure',
    },
    component: <NumberStructureSubmenu mode="supplier" />,
  },
  //Supplier
  {
    props: {
      exact: true,
      path: '/company-settings/:CompanyID/numbering-structure',
    },
    component: <NumberStructureSubmenu mode="general-purchase" />,
  },
  // General Purchase
  {
    props: {
      exact: true,
      path: '/company-settings/:CompanyID/numbering-structure',
    },
    component: <NumberStructureSubmenu mode="project-purchase" />,
  },
  ...autoNumberingRoutes,
];

export default numberingStructureRoutes;
