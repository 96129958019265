import theme from '@ifca-root/react-component/src/assets/theme'
import { useMediaQuery } from '@material-ui/core'
import React from 'react'
import { useLocation } from 'react-router'

export const HeaderPO = (props: any) => {
  const location: any = useLocation()
  const previewDetail: any = location?.state
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });

 

  let logoStyle, containerStyle, fontStyle
  fontStyle = {
    padding: '10px',
    fontSize: isDesktop ? '11px' : '8px',
    textAlign: 'center',
  }

  return (
    <>
      <div style={containerStyle}>
        <div style={fontStyle}>
        <b>
                {previewDetail?.Company?.Name}
                {` (${previewDetail?.Company?.CompanyRegNo})`}
              </b>
              <br />
              {previewDetail?.Company?.Address.address}
              <br />
              {previewDetail?.Company?.Address?.country == 'Singapore'
                ? `${previewDetail?.Company?.Address?.country} ${previewDetail?.Company?.Address?.postCode}`
                : `${previewDetail?.Company?.Address?.postCode} ${previewDetail?.Company?.Address?.city}, ${previewDetail?.Company?.Address?.state}, ${previewDetail?.Company?.Address?.country}`}
              <br />
              Tel No. {previewDetail?.Company?.Address?.contactNo}
              {previewDetail?.Company?.Address?.CompanyTax === 'GST_NO' ? (
                <>
                  <br />
                  GST Registration No. : {previewDetail?.Address?.GSTNo}
                </>
              ) : (
                <>
                  <br />
                  SST Registration No. : {previewDetail?.Address?.SSTNo}
                </>
              )}
        </div>
      </div>
    </>
  )
}

export default HeaderPO
