import { ApolloProvider } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import '@ifca-root/react-component/src/assets/styles/app.scss';
import theme from '@ifca-root/react-component/src/assets/theme';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/';
import { setAccessToken } from 'AccessToken';
import 'assets/styles/contractx.scss';
import Layout from 'components/Layout/Layout';
import { PurchaserClient, purchaserNodeRefreshUrl } from 'PurchaserClient';
import { useLogoutMutation } from 'generated/graphql';
import { useBroadcastChannel } from 'helpers/Hooks/useBroadcastChannel';
import { useServiceWorker } from 'helpers/Hooks/useServiceWorker';
import { createBrowserHistory } from 'history';
import localForage from 'localforage';
import React, { Suspense, useEffect, useReducer, useState } from 'react';
import { Router } from 'react-router-dom';
import Routes from './Router/Routes';
import AppContext from './Store/AppContext';
import { GlobalInitialState, RootReducer } from './Store/RootReducer';
import SnackBarContext from './Store/SnackBarContext';
// import { InstallBanner } from '@contract-root/admin-react/src/components/InstallBanner/InstallBanner';
// import { useInstallPrompt } from '@contract-root/admin-react/src/helpers/Hooks/useInstallPrompt';

export const history = createBrowserHistory();

const App = () => {
  const [globalState, dispatch] = useReducer(RootReducer, GlobalInitialState);
  const [loading, setLoading] = useState(true);
  const { isUpdateAvailable, updateAssets } = useServiceWorker();
  const { userChannel } = useBroadcastChannel();
  const key = sessionStorage?.getItem('tokenKey');
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMsg, setSnackBarMsg] = useState<string>('');
  const user = JSON?.parse(localStorage?.getItem('loggedInUser'));
  const routing: any = history?.location?.pathname;

  const [logout, { client }] = useLogoutMutation({
    client: PurchaserClient,
  });

  const pathBeforeLogin = [
    '/authentication/404',
    '/reset-password',
    '/user/activate',
    '/user/create-password',
    '/login',
    '/forgot-password',
  ];

  const isNotMainContent = () => {
    return pathBeforeLogin?.filter(v => routing?.includes(v))?.length > 0;
  };

  async function userLogout(userID) {
    await logout({ variables: { ID: userID } });
    setAccessToken('');
    await client!.resetStore();
  }

  useEffect(() => {
    fetch(purchaserNodeRefreshUrl, {
      method: 'POST',
      credentials: 'include',
    }).then(async x => {
      const { accessToken } = await x?.json();
      setAccessToken(accessToken);
      setLoading(false);
    });
    userChannel.onmessage = (data: any) => {
      if (data?.payload?.type === 'SIGN_OUT' && data?.userId === user?.ID) {
        userLogout(data?.userId);
        localStorage?.removeItem('loggedInUser');
        localForage?.removeItem('permission');
        sessionStorage?.removeItem('tokenKey');
        history.push('/login');
      }
    };
  }, []);

  useEffect(() => {
    if (!!!key && !isNotMainContent()) {
      // userLogout(user?.ID);
      // localStorage?.removeItem('loggedInUser');
      // localForage?.removeItem('permission');
      // sessionStorage?.removeItem('tokenKey');
      history.push('/login');
    }
    if (
      (user == null || Object?.keys(user)?.length == 0) &&
      !isNotMainContent()
    ) {
      // localStorage?.removeItem('loggedInUser');
      // localForage?.removeItem('permission');
      // sessionStorage?.removeItem('tokenKey');
      history.push('/login');
    }
  }, [key, user]);

  // const { promptable, promptToInstall, isInstalled } = useInstallPrompt();
  const [isVisible, setVisible] = useState(false);

  const hide = () => setVisible(false);

  if (loading) {
    return <Loading />;
  }

  return (
    <AppContext.Provider value={{ globalState, dispatch }}>
      <SnackBarContext.Provider value={{ setOpenSnackBar, setSnackBarMsg }}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={PurchaserClient}>
            {/* ADD MORE THAN 1 CLIENTS IF NEEDED */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Router history={history}>
                <Layout>
                  {/* LOCATION TO PUT BANNER FOR FUTURE REFERENCE */}
                  <Suspense fallback={<Loading />}>
                    <Routes />
                    {/* <ServiceWorkerWrapper /> */}
                    <Backdrop open={isUpdateAvailable}>
                      <Dialog
                        open={isUpdateAvailable}
                        // onClose={() => setReloadDia(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          {'New Update Available'}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            Please update AccountX to the latest version to
                            continue.
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={updateAssets} autoFocus>
                            Update
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </Backdrop>
                    <SnackBarMsg
                      open={openSnackBar}
                      setOpen={setOpenSnackBar}
                      message={snackBarMsg}
                    />
                  </Suspense>
                </Layout>
                {/* {promptable && !isInstalled ? (
                    <InstallBanner
                      ButtonOption={{
                        section: {
                          props: {
                            style: { display: !isVisible ? 'none' : null },
                          },
                        },
                        option1: {
                          props: {
                            onClick: () => hide(),
                          },
                        },
                        option2: {
                          props: {
                            onClick: () => promptToInstall(),
                          },
                        },
                      }}
                    />
                  ) : null} */}
              </Router>
            </MuiPickersUtilsProvider>
          </ApolloProvider>
        </ThemeProvider>
      </SnackBarContext.Provider>
    </AppContext.Provider>
  );
};

export default App;
