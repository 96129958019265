import { yupResolver } from '@hookform/resolvers';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { smTitle } from '@ifca-root/react-component/src/global/TitleVariable';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { TooltipAmtFooter } from 'components/Footer/TooltipAmtFooter';
import {
  ApprovalStatus,
  GetGeneralDoDocument,
  GetGrtnHeaderDocument,
  useCreateGrtnMutation,
  useDocumentListingLazyQuery,
  useGetCompanyNameQuery,
  useGetGeneralDoLazyQuery,
  useGetSupplierByDoStatusQuery,
  useGetWarehouseDeliveryQuery,
  useLatestOpenPeriodCheckingDateQuery,
  useUpdateGrtnMutation,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/Messages/SystemMsg';
import { CommonYupValidation } from 'helpers/YupSchema/yup';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import SnackBarContext from '../../App/Store/SnackBarContext';
import { ExitConfirmationDialog } from '../components/ExitConfirmationDialog';
import { GeneralGRTNContent } from './Components/GeneralGRTNContent';

interface GoodReturnNoteProps {
  DocDate: Date;
  TrxDate: Date;
  SupplierID: string;
  DocRef: string;
  Description: string;
  Attachment: string;
  LocationID: string;
}

export const GRTNSchema = yup.object().shape({
  SupplierID: CommonYupValidation.requireField(SystemMsgs.supplierSelection()),
  Description: CommonYupValidation.requireField(SystemMsgs.description()),
  DocRef: CommonYupValidation.requireField(SystemMsgs.docRef()),
});

export const GeneralGRTNForm = ({ mode }) => {
  let formMode = mode === 'add' ? 'New' : 'Edit';
  let history = useHistory();
  let location = useLocation();
  const editData = location?.state as any;
  const { CompanyID, GRTNHeaderID }: any = useParams();
  const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
    SnackBarContext as any,
  );
  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  const [state, setDependecies] = useState(false);
  const [isWarehouse, setIsWarehouse] = useState(false);
  const [checked, setChecked] = useState(true);
  const [noError, setNoError] = useState(true);
  const [doDate, setDoDate] = useState(new Date());
  const [itemHasValue, setItemHasValue] = useState(null);
  const [itemHasValueAmt, setItemHasValueAmt] = useState(null);
  const [exitDialog, setExitDialog] = useState(false);

  const editResubmitMode = mode => {
    switch (mode) {
      case 'edit':
        return true;
      case 'resubmit':
        return true;
      default:
        return false;
    }
  };

  const [deliveryLocationDetail, setDeliveryLocationDetail] = useState(
    editResubmitMode(mode)
      ? {
          ID: editData?.DeliveryLocationID ?? editData?.WarehouseID,
          IsWarehouse: !!editData?.WarehouseID,
        }
      : { ID: '', IsWarehouse: false },
  );
  const [suppID, setSuppID] = useState(
    editResubmitMode(mode) ? editData?.SupplierID : null,
  );
  const [LocationID, setLocationID] = useState(
    editResubmitMode(mode) ? editData?.DeliveryLocationID : null,
  );

  const [selectedDate, handleDateChange] = useState(
    editResubmitMode(mode) ? new Date(editData?.DocDate) : new Date(),
  );

  const [dummyState, setDummyChecker] = useState(null);

  //==============================================//
  //-------------------USEFORM--------------------//
  //==============================================//
  const {
    handleSubmit,
    register,
    errors,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState,
    getValues,
  } = useForm<GoodReturnNoteProps>({
    defaultValues: {},
    mode: 'onSubmit',
    resolver: yupResolver(GRTNSchema),
  });

  //============================================//
  //-------------------QUERY--------------------//
  //============================================//

  const [
    loadDOHeader,
    {
      loading: doHeaderLoading,
      error: doError,
      data: { getGeneralDO } = { getGeneralDO: [] },
    },
  ] = useGetGeneralDoLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: ({ getGeneralDO }) => {},
  });

  const {
    loading: getCompanyLoading,
    error: getCompanyError,
    data: { getCompany } = {
      getCompany: [],
    },
  } = useGetCompanyNameQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  });

  const {
    loading: getSuplierLoading,
    data: { getSupplierByDOStatus } = { getSupplierByDOStatus: [] },
  } = useGetSupplierByDoStatusQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
      ApprovalStatus: ApprovalStatus.Completed,
    },
  });

  const {
    loading: warehouseLoading,
    data: { getWarehouseDeliveryLocation } = {
      getWarehouseDeliveryLocation: [],
    },
  } = useGetWarehouseDeliveryQuery({
    fetchPolicy: 'network-only',
    variables: {
      CompanyID: CompanyID,
    },
  });

  const {
    loading: openPeriodCheckDateLoading,
    data: { latestOpenPeriodCheckingDate } = {
      latestOpenPeriodCheckingDate: {} as any,
    },
  } = useLatestOpenPeriodCheckingDateQuery({
    fetchPolicy: 'network-only',
    variables: { CompanyID },
  });

  //============================================//
  //----------------ATTACHMENT------------------//
  //============================================//
  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
    setFiles,
    setPreviewFiles,
  } = useUploadAttachment();

  const [
    fetchDocuments,
    { loading: documentLoading, error: DocError, data: DocData },
  ] = useDocumentListingLazyQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: GRTNHeaderID,
    },
    onCompleted: ({ DocumentListing }) => {
      // only applies when first opening edit form, when editData still does not have "files" but only Attachment from field resolver
      if (!editData?.files) {
        handleEditUpload(
          DocumentListing?.filter(doc => doc?.description !== 'document_pdf'),
        );
        previewFiles?.push(
          ...DocumentListing?.filter(
            doc => doc?.description !== 'document_pdf',
          )?.map(x => x?.fileURL),
        );
      }
    },
  });

  //============================================//
  //------------------MUTATION------------------//
  //============================================//
  const [CreateGRTN, { loading: createLoading }] = useCreateGrtnMutation({
    onError: error => {
      console.log('ERROR', error);
    },
    onCompleted: () => {
      setSnackBarMsg(SystemMsgs.createNewRecord());
      setOpenSnackBar(true);
      history.push(`/${CompanyID}/good-return-note`);
    },
  });

  const [UpdateGRTN, { loading: updateLoading }] = useUpdateGrtnMutation({
    onError: error => {
      console.log('ERROR', error);
    },
    onCompleted: () => {
      setSnackBarMsg(SystemMsgs.updateRecord());
      setOpenSnackBar(true);
      history.push(`/${CompanyID}/good-return-note`);
    },
  });

  // ✥✥✥✥✥✥✥✥✥✥✥✥✥✥ -  USE EFFECT  - ✥✥✥✥✥✥✥✥✥✥✥✥✥✥//
  useEffect(() => {
    if (mode === 'edit')
      if (!!!editData?.files) {
        fetchDocuments({
          variables: {
            refID: GRTNHeaderID,
          },
        });
      }
  }, [mode]);

  useEffect(() => {
    if (editData?.files) {
      setFiles(editData?.files);
      setPreviewFiles(
        editData?.files?.map(file => {
          return URL.createObjectURL(file);
        }),
      );
    }
  }, [editData]);

  useEffect(() => {
    if (!!suppID) {
      loadDOHeader({
        variables: {
          SupplierID: suppID,
          CompanyID: CompanyID,
          ApprovalStatus: ApprovalStatus.Completed,
        },
      });
    }
  }, [suppID, selectedDate, LocationID, isWarehouse]);

  useEffect(() => {
    setDoDate(watch('docDate'));
  }, [watch('docDate')]);

  //============================================//
  //------------------ONSUBMIT------------------//
  //============================================//
  const createUpdateGRTN = mode === 'add' ? CreateGRTN : UpdateGRTN;

  const onSubmit = (data, submitMode) => {
    createUpdateGRTN({
      variables: {
        GRTNHeaderID: mode === 'edit' ? GRTNHeaderID : null,
        grtnInput: {
          SupplierID: data?.SupplierID,
          CompanyID: CompanyID,
          DeliveryLocationID:
            deliveryLocationDetail?.IsWarehouse === false
              ? data?.LocationID
              : null,
          WarehouseID:
            deliveryLocationDetail?.IsWarehouse === true
              ? data?.LocationID
              : null,
          DocDate: new Date(data?.docDate).toISOString(),
          TransactionDate: new Date(data?.trxDate).toISOString(),
          RefNo: data?.DocRef,
          Description: data?.Description,
          file: files,
          ApprovalStatus:
            submitMode === 'submit'
              ? ApprovalStatus.Submit
              : ApprovalStatus.Active,
        },
        grtnItemInput: Object.values(data?.getDO)
          ?.map((x: any) => {
            return x?.DOItem.map(y => {
              return {
                DoItemID: y?.doItemID,
                ReturnedQty: parseFloat(y?.ReturnedQty ?? 0),
                ReturnedAmt: parseFloat(y?.ReturnedAmt ?? 0),
                Replacement: y?.replacement === true ? true : false,
              };
            });
          })
          .flat(),
      },
      refetchQueries: [
        {
          query: GetGrtnHeaderDocument,
          variables: {
            CompanyID: CompanyID,
            orderByDesc: 'DocDate',
          },
        },
        {
          query: GetGeneralDoDocument,
          variables: {
            CompanyID: CompanyID,
            ApprovalStatus: ApprovalStatus.Active,
          },
        },
      ],
    });
  };

  const handleCLick = handleSubmit(onSubmit);

  const checkItemQty = () => {
    const acceptedQtys = [];

    getGeneralDO?.map((el, inindex) => {
      el?.DOItem?.map((inel, initem) => {
        const acceptedAmt = watch(
          `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedAmt`,
        ) as string | undefined;

        const acceptedQty = Number(acceptedAmt);
        if (!isNaN(acceptedQty)) {
          acceptedQtys.push(acceptedQty);
        }
      });
    });

    const totalAcceptedQty = acceptedQtys.reduce((acc, qty) => acc + qty, 0);

    return totalAcceptedQty;
  };

  //============================================//
  //------------------CHECKING------------------//
  //============================================//

  const doItemsArr = getGeneralDO?.filter(
    ({ DocDate }) => new Date(DocDate) < new Date(selectedDate),
  );

  const checkItemLength = () => {
    let status = false;
    getGeneralDO?.map((el, inindex) => {
      el?.DOItem?.map((inel, initem) => {
        const ReturnedQty = watch(
          `getDO[${el?.DOHeaderID}].DOItem[${initem}].ReturnedQty`,
        ) as number;

        if (
          mode === 'add'
            ? ReturnedQty > 0
            : ReturnedQty > 0 || ReturnedQty === undefined
        ) {
          status = true;
        }
      });
    });
    return status;
  };

  return (
    <>
      {getCompanyLoading && <Loading />}
      {createLoading && <Loading />}
      {updateLoading && <Loading />}
      {documentLoading && <Loading />}
      {doHeaderLoading && <Loading />}
      {getSuplierLoading && <Loading />}
      {warehouseLoading && <Loading />}
      {openPeriodCheckDateLoading && <Loading />}

      <MainHeader
        onClick={() => {
          history.push(`/${CompanyID}/good-return-note`);
        }}
        mainBtn="back"
        smTitle={smTitle.GENERAL_PURCHASES}
        title={getCompany[0]?.Name}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Good Return Note', current: true },
        ]}
        rightRouteSegments={[{ name: `${formMode}`, current: true }]}
      />

      <ContentWrapper footer>
        <form onSubmit={handleCLick} id="submit-form">
          <CardContents
            section={{
              header: {
                title: 'Goods Return Note Details',
              },
            }}
          >
            <Controller
              control={control}
              ref={register}
              name="docDate"
              required
              className="left"
              label="DO Date"
              fullWidth
              as={KeyboardDatePicker}
              defaultValue={editData ? editData?.DocDate : new Date()}
              value={new Date()}
              format="dd/MM/yyyy"
              onChange={(value, newValue: any) => {}}
              showTodayButton
            />

            <Controller
              control={control}
              ref={register}
              name="trxDate"
              required
              className="right"
              label="Transaction  Date"
              fullWidth
              as={KeyboardDatePicker}
              defaultValue={editData ? editData?.TransactionDate : new Date()}
              value={new Date()}
              format="dd/MM/yyyy"
              onChange={(e: Date | null) => {}}
              showTodayButton
              minDate={new Date(latestOpenPeriodCheckingDate?.StartDate)}
              maxDate={new Date(latestOpenPeriodCheckingDate?.EndDate)}
            />

            {!getSuplierLoading && (
              <Controller
                render={({ value, onChange }) => {
                  const defVal = getSupplierByDOStatus?.filter(
                    x => x?.CreditorAccountID === editData?.SupplierID,
                  )[0];

                  return (
                    <Autocomplete
                      options={getSupplierByDOStatus || []}
                      getOptionLabel={option => option?.CompanyName}
                      fullWidth
                      onChange={(value, newValue: any) => {
                        setValue('SupplierID', newValue?.CreditorAccountID);
                        setSuppID(watch(`SupplierID`));
                      }}
                      renderOption={(props, option) => {
                        return <span>{props?.CompanyName}</span>;
                      }}
                      defaultValue={defVal}
                      renderInput={(params: any) => {
                        return (
                          <div>
                            <TextField
                              {...params}
                              helperText={errors?.SupplierID?.message}
                              error={errors?.SupplierID ? true : false}
                              label="Supplier"
                              style={{ width: '100%' }}
                              margin="dense"
                              required
                            />
                          </div>
                        );
                      }}
                    />
                  );
                }}
                label="Supplier"
                name="SupplierID"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                margin="dense"
                ref={register}
                helperText={errors?.SupplierID?.message}
                error={errors?.SupplierID ? true : false}
                defaultValue={editData?.SupplierID}
                required
              />
            )}

            {!warehouseLoading && (
              <Controller
                render={({ value, onChange }) => {
                  const defVal = getWarehouseDeliveryLocation?.filter(
                    x =>
                      x?.ID ===
                      (editData?.DeliveryLocationID ?? editData?.WarehouseID),
                  )[0];
                  return (
                    <Autocomplete
                      options={getWarehouseDeliveryLocation || []}
                      getOptionLabel={option => option?.Name}
                      fullWidth
                      onChange={(value, newValue: any) => {
                        setValue('LocationID', newValue?.ID);
                        setDeliveryLocationDetail({
                          ID: newValue?.ID,
                          IsWarehouse: getWarehouseDeliveryLocation.find(
                            x => x?.ID === newValue?.ID,
                          )?.Warehouse,
                        });
                      }}
                      renderOption={(props, option) => {
                        return <span>{props?.Name}</span>;
                      }}
                      defaultValue={defVal}
                      renderInput={(params: any) => {
                        return (
                          <div>
                            <TextField
                              {...params}
                              label="Location"
                              style={{ width: '100%' }}
                              margin="dense"
                            />
                          </div>
                        );
                      }}
                    />
                  );
                }}
                label="Location"
                name="LocationID"
                autoComplete="off"
                control={control}
                multiline={true}
                fullWidth
                margin="dense"
                ref={register}
                defaultValue={
                  editData?.WarehouseID ?? editData?.DeliveryLocationID
                }
                required
              />
            )}

            <Controller
              as={TextField}
              name="DocRef"
              // className="left"
              fullWidth
              required
              label="Do No"
              defaultValue={editData?.RefNo}
              control={control}
              helperText={errors?.DocRef?.message}
              error={!!errors?.DocRef}
            />

            <Controller
              as={TextField}
              name="Description"
              fullWidth
              required
              label="Description"
              defaultValue={editData?.Description}
              control={control}
              helperText={errors?.Description?.message}
              error={!!errors?.Description}
            />

            <FileUploadInput
              label="Attachment"
              files={files}
              onHandleUploadChange={handleUploadChange}
              multiple
              imagePreview={
                <>
                  {previewFiles?.map((v, i) => (
                    <UploadPreview
                      remove
                      key={v}
                      src={v}
                      onClick={() => removeFile(i)}
                    />
                  ))}
                </>
              }
            />
          </CardContents>
          {
            <GeneralGRTNContent
              state={state}
              GRTNLoading={doHeaderLoading}
              doArr={doItemsArr}
              mode={mode}
              editData={editData}
              setNoError={setNoError}
              useFormProps={{
                clearErrors,
                setError,
                errors,
                watch,
                register,
                control,
                setValue,
              }}
              itemHasValueQty={setItemHasValue}
              itemHasValueAmt={setItemHasValueAmt}
              GRTNHeaderID={GRTNHeaderID}
              CompanyID={CompanyID}
              selectedDate={doDate}
            />
          }
        </form>
        <TooltipAmtFooter data={checkItemQty()} module={'grtn-draft'} />

        <Footer
          options={
            mode !== 'edit'
              ? [
                  {
                    name: 'Save as Draft',
                    onClick: () => {
                      if (noError) {
                        handleSubmit(data => onSubmit(data, 'draft'))();
                      }
                    },
                    disabled:
                      !noError ||
                      (itemHasValue?.every(s => s === false) &&
                        itemHasValueAmt?.every(s => s === false)),
                    color: 'primary',
                  },
                  {
                    name: 'Save as Submit',
                    onClick: () => {
                      if (noError) {
                        handleSubmit(data => onSubmit(data, 'submit'))();
                      }
                    },
                    disabled:
                      !noError ||
                      (itemHasValue?.every(s => s === false) &&
                        itemHasValueAmt?.every(s => s === false)),
                    color: 'primary',
                  },
                ]
              : [
                  {
                    name: 'Save as Draft',
                    onClick: () => {
                      if (noError) {
                        handleSubmit(data => onSubmit(data, 'draft'))();
                      }
                    },
                    disabled: !noError || checkItemLength() === false,
                    color: 'primary',
                  },
                  {
                    name: 'Save as Submit',
                    onClick: () => {
                      if (noError) {
                        handleSubmit(data => onSubmit(data, 'submit'))();
                      }
                    },
                    disabled: !noError || checkItemLength() === false,
                    color: 'primary',
                  },
                ]
          }
        />
      </ContentWrapper>

      <ExitConfirmationDialog
        exitDialog={exitDialog}
        setExitDialog={setExitDialog}
        path={() => history.push(`/${CompanyID}/good-return-note`)}
      />
    </>
  );
};
